import styled from 'styled-components';

export const CategoryContainer = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
`;

export const GraphContainer = styled.section`
	flex-basis: 50%;
	flex-grow: 1;

	box-sizing: border-box;
	padding: 2em;
`;

export const GraphCard = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;

	position: relative;

	background: white;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px;

	div {
		display: flex;
		justify-content: center;
	}
`;

export const GraphHeader = styled.section`
	width: 100%;

	display: flex;
	justify-content: space-between;
	align-items: center;

	position: absolute;

	box-sizing: border-box;
	padding: 1em 2em;

	div {
		display: flex;
		flex-direction: column;
	}
`;

export const Container = styled.section`
	width: 100%;
	min-width: 1000px;
	height: 100%;

	box-sizing: border-box;
	padding: 1em;
`;

export const GlobalControls = styled.section`
	width: calc(100% - 1.75em);
	height: 3em;

	display: flex;
	justify-content: space-between;

	box-sizing: border-box;
	margin-bottom: 1em;
`;

export const BlockCentralized = styled.section`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const LeftGlobalControlsContainer = styled.section`
	display: flex;
`;

export const DeleteMode = styled.section`
	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding-left: 1em;

	.ui.toggle.checkbox input:checked ~ .box:before,
	.ui.toggle.checkbox input:checked ~ label:before {
		background-color: red !important;
	}

	.ui.toggle.checkbox input:focus:checked ~ .box:before,
	.ui.toggle.checkbox input:focus:checked ~ label:before {
		background-color: red !important;
	}
`;

export const AutoRefresh = styled.section`
	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding-right: 1em;

	// .ui.toggle.checkbox input:checked ~ .box:before,
	// .ui.toggle.checkbox input:checked ~ label:before {
	// 	background-color: ${(props) => props.theme.theme} !important;
	// }
	//
	// .ui.toggle.checkbox input:focus:checked ~ .box:before,
	// .ui.toggle.checkbox input:focus:checked ~ label:before {
	// 	background-color: ${(props) => props.theme.theme} !important;
	// }
`;

export const CheckboxContainer = styled.section`
	width: 3%;

	box-sizing: border-box;
`;

export const AccordionContainer = styled.section`
	width: 100%;
	height: calc(100% - 10em);
	overflow-y: scroll;

	box-sizing: border-box;
	padding-right: 1em;
`;

export const QuickbooksUnAuthorizeContainer = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background-color: #fff;

	h1 {
		margin-bottom: 2em;
	}
`;

export const PaginationContainer = styled.section`
	display: flex;
	align-items: center;
	justify-content: flex-end;

	padding: 0.5em;
`;

export const PageSelector = styled.section`
	height: 100%;
	width: 8%;

	margin-right: 1em;
`;

export const TableContainer = styled.section`
	width: 100%;
	height: 100%;

	background: white;

	/* Table */

	.ui.table {
		margin: 0 !important;
		border-top: none !important;
	}

	.ui.table thead tr:first-child > th {
		top: 0;

		box-sizing: border-box;
		border-top: 1px solid rgba(34, 36, 38, 0.15);
	}

	.ui.table thead tr:first-child > th:nth-child(5),
	tr:first-child > th:nth-child(7) {
		z-index: 0 !important;
	}

	.ui.dropdown {
		z-index: 10 !important;
	}

	//.ui.dropdown {
	//  min-width: 5em;
	//}

	overflow-y: auto;
`;

export const CheckBoxContainer = styled.section`
	width: ${(props) => props.width};
	height: 100%;

	display: flex;
	justify-content: space-between;
	align-items: center;

	margin-top: 10px;
`;

export const BoldLabel = styled.label`
	font-weight: bold;
`;

export const Block = styled.section`
	width: 100%;

	margin: 1em 0;
	padding: 0 1em;
`;

export const Row = styled.section`
	width: 100%;

	display: flex;
	justify-content: space-between;

	margin: 1em 0;

	form {
		width: calc(50% - 0.5em);
	}
`;

export const CalibrationTable = styled.section`
	width: 100%;
`;

export const CalibrationHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-evenly;

	padding-right: 2.8em;
	margin-top: 1em;
`;

export const CalibrationHeaderElement = styled.div`
	width: calc(100% - 1.75em);
	height: 3em;

	box-sizing: border-box;
	padding: 1em;
	margin: 0 1em;

	display: flex;
	justify-content: center;
	align-items: center;

	font-weight: bold;

	background: #efefef;
	border: 1px solid #efefef;
	border-radius: 3px;
`;

export const ExcelDataRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-evenly;

	margin: 1em 0;
`;

export const InvoiceContainer = styled.section`
	width: 100%;

	box-sizing: border-box;
	padding: 0 0.5em;

	overflow-y: auto;
`;

export const InvoiceCard = styled.section`
	width: 100%;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	box-sizing: border-box;
	padding: 0.5em;
	margin: 0.5em 0;

	background: #fff;
	box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	border-radius: 5px;
	//box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
`;

export const ForceContainer = styled.section`
	width: ${(props) => props.width};

	display: flex;
	justify-content: space-between;
	align-items: center;

	margin: 1.4em 0 0 1em;
`;

export const LabelContainer = styled.section`
	margin: 0.2em 0;
	padding: 0.5em;

	display: flex;
	flex-wrap: wrap;

	border: 1px solid #aaaa;
	border-radius: 5px;

	.ui.label {
		margin: 0.2em;
		background-color: ${(props) => props.backgroundColor};
	}
`;

export const DeactivateOrgBlock = styled.section`
	width: 100%;

	margin: 1em 0;
`;

export const ImportBox = styled.section`
	height: 20em;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border: 1px solid #ddd;
	border-radius: 5px;

	img {
		height: 8em;
	}

	.button {
		margin-top: 2em;
	}
`;

export const ImportCalibrationFile = styled.section`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	margin-bottom: 0.75em;
	padding: 2em;

	border: 1px dashed #ddd;
	border-radius: 5px;

	img {
		height: 4em;
	}

	.button {
		margin-top: 2em;
	}
`;

export const Text = styled.section`
	display: flex;
	justify-content: center;
	align-items: center;

	font-weight: bold;

	margin-bottom: 0.75em;
`;

// Sim Bills
export const MonthRangeCalender = styled.section`
	box-sizing: border-box;
	padding: 0 1em;

	display: flex;

	.form {
		width: 65%;
	}

	.button {
		margin-left: 1em;

		background: #fff !important;
		box-sizing: border-box !important;
		border: 1px solid #d8d8d8 !important;

		&:hover {
			color: dodgerblue !important;
		}
	}
`;

export const OverviewSimBills = styled.section`
	height: 12em;

	display: flex;
	justify-content: space-between;

	margin-bottom: 1em;
`;

export const SimBillsGraphContainer = styled.section`
	height: 100%;
	width: 50%;

	box-sizing: border-box;
	padding: 0 0.5em;
`;

export const SimBillsGraphCard = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	position: relative;

	background: white;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
`;

export const SimBillsGraphHeader = styled.section`
	width: 100%;

	position: absolute;
	top: 0;
	left: 0;

	box-sizing: border-box;
	padding: 1em 2em;

	h5 {
		text-align: right;
		color: ${(props) => props.theme.theme};
	}
`;

export const SimBillsAccordionContainer = styled.section`
	width: 100%;
	height: calc(100% - 23em);
	overflow-y: scroll;

	box-sizing: border-box;
	padding-right: 1em;
`;

export const DropdownForSearch = styled.section`
	display: flex;
	justify-content: center;
	align-items: center;

	.ui.dropdown {
		margin-right: 1em;
	}
`;

export const IconContainer = styled.section`
	width: 1.5em;

	display: flex;
	justify-content: flex-start;
	align-items: center;

	margin-right: 0.5em;
`;

export const TextWithIcon = styled.section`
	display: flex;
`;
