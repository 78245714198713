import Store from "../../data/store/store";
import { dispatch } from "./middleware";

// CategorySidebar
export function expandSidebar() {
	Store.dispatch({ type: 'EXPAND_SIDEBAR' });
}

export function collapseSidebar() {
	Store.dispatch({ type: 'COLLAPSE_SIDEBAR' });
}

export function switchMetricsViewMode(mode) {
	dispatch('SET_METRICS_VIEW_MODE', mode);
}

export function isProductionEnvironment() {
	return process.env.REACT_APP_BASE_URL === 'https://api.tracknerd.io/';
}
