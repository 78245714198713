import React from 'react';

import CommonModal from '../../../molecules/modal/CommonModal';
import { validateEmail, validateMobileNumber } from '../../../../../../logic/utilities/validator';

import { Button, Checkbox, Dropdown, Form, Icon, Input } from 'semantic-ui-react';
import { Expander } from '../../../../styles/place-holders/Expander';
import styled from 'styled-components';

const Container = styled.section`
	.ui.toggle.checkbox input:checked ~ .box:before,
	.ui.toggle.checkbox input:checked ~ label:before {
		background-color: ${(props) => props.theme.positive} !important;
	}

	.ui.toggle.checkbox .box:before,
	.ui.toggle.checkbox label:before {
		background-color: ${(props) => props.theme.danger} !important;
	}
`;

const AddModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			header="Add User"
			// size="medium"
			scrolling
			content={
				<Form>
					<Form.Group widths="equal">
						<Form.Field required={true}>
							<label>Name</label>
							<Input fluid onChange={props.onNameChange} />
						</Form.Field>
						<Form.Field required={true}>
							<label>User Type</label>
							<Dropdown
								fluid
								search
								selection
								clearable
								value={props.selectedUserType}
								options={props.userTypeOptions}
								onChange={props.onUserTypeChange}
							/>
						</Form.Field>
						<Form.Field required={true}>
							<label>Organisation</label>
							<Dropdown
								placeholder="Choose ..."
								fluid
								search
								clearable
								selection
								options={props.organisations}
								onChange={props.onOrganisationChange}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field
							required={true}
							control={Input}
							label="Phone"
							error={
								!validateMobileNumber(props.phone) && {
									content: 'Phone number should be valid.',
								}
							}>
							<Input fluid onChange={props.onPhoneChange} />
						</Form.Field>
						<Form.Field
							control={Input}
							label="Email"
							error={
								!validateEmail(props.email) && {
									content: 'Email should be valid.',
								}
							}>
							<Input fluid onChange={props.onEmailChange} />
						</Form.Field>

						{props.selectedUserType !== 'Parent' && (
							<Form.Field>
								<label>Vehicle Groups</label>
								<Dropdown
									placeholder="Optional"
									fluid
									search
									selection
									multiple
									clearable
									disabled={!(props.selectedOrganisation !== null)}
									value={props.selectedVehicleGroup}
									options={props.vehicleGroups}
									onChange={props.onVehicleGroupChange}
								/>
							</Form.Field>
						)}
					</Form.Group>

					<Form.Group widths="equal">
						<Form.Field required={true}>
							<label>Role</label>
							<Dropdown
								fluid
								search
								selection
								clearable
								value={props.selectedRoleFA}
								// options={props.roleOptions}
								options={
									props.selectedUserType === 'Driver' || props.selectedUserType === 'Parent'
										? props.roleOptions.filter((role) => {
												return role.text !== 'Tracknerd Super Admin';
										  })
										: props.roleOptions
								}
								onChange={props.onRoleFAChange}
							/>
						</Form.Field>
						<Form.Field>
							<section style={{ width: '100%', display: 'flex', marginTop: '2em' }}>
								<label style={{ fontWeight: 'bold' }}>Login Enabled</label>
								<Expander />
								<Container>
									<Checkbox toggle checked={props.canLoginFA} onChange={props.onCanLoginFAChange} />
								</Container>
							</section>
						</Form.Field>
						<Form.Field required={true}>
							<label>Password</label>
							<Input
								fluid
								icon={
									<Icon
										name={props.passwordVisible ? 'eye' : 'eye slash'}
										link
										onClick={props.onShowPasswordIconClick}
									/>
								}
								type={props.passwordVisible ? 'text' : 'password'}
								onChange={props.onPasswordChange}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field>
							<section style={{ width: '100%', display: 'flex', marginTop: '2em' }}>
								<label style={{ fontWeight: 'bold', flexGrow: '0.2' }}>SMS Alert</label>
								{/*<Expander />*/}
								<Container>
									<Checkbox
										toggle
										onChange={(e, data) => props.onIsEnabledSMSFAChange(e, data)}
										checked={props.isEnabledSMSFA}
									/>
								</Container>
							</section>
						</Form.Field>
						<Form.Field>
							<section style={{ width: '100%', display: 'flex', marginTop: '2em' }}>
								<label style={{ fontWeight: 'bold', flexGrow: '0.2' }}>Email Alert</label>
								{/*<Expander />*/}
								<Container>
									<Checkbox
										toggle
										onChange={(e, data) => props.onIsEnabledEmailFAChange(e, data)}
										checked={props.isEnabledEmailFA}
									/>
								</Container>
							</section>
						</Form.Field>
						<Form.Field>
							<section style={{ width: '100%', display: 'flex', marginTop: '2em' }}>
								<label style={{ fontWeight: 'bold', flexGrow: '0.2' }}>Push Alert</label>
								{/*<Expander />*/}
								<Container>
									<Checkbox
										toggle
										onChange={(e, data) => props.onIsEnabledPushFAChange(e, data)}
										checked={props.isEnabledPushFA}
									/>
								</Container>
							</section>
						</Form.Field>
					</Form.Group>
				</Form>
			}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button positive loading={props.addButtonLoading} disabled={props.disabled} onClick={props.onAddButtonClick}>
						Add
					</Button>
				</>
			}
		/>
	);
};

export default AddModal;
