import React from 'react';

import CommonModal from '../../../molecules/modal/CommonModal';
import { Expander } from '../../../../styles/place-holders/Expander';

import { DEVICES_STATUS_FILTER_OPTIONS } from '../../../../../../data/constants/dropdown-options';

import { Button, Dropdown, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment/moment';

const FilterModal = (props) => {
	// let minEndDate=moment(props.invoiceStartDate, 'DD-MM-YYYY').add(1, 'day').format('DD-MM-YYYY');

	// const todayDate = moment().format('DD-MM-YYYY');

	// const isToday = props.invoiceStartDate === todayDate;

	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="tiny"
			scrolling
			header="Device Filters"
			content={
				<div>
					<Form>
						<Form.Group widths="equal">
							<Form.Field>
								<label>Status</label>
								<Dropdown
									placeholder="Choose..."
									fluid
									search
									multiple
									selection
									// onClick={() => {
									// 	setIsDropdownExpanded((prev) => !prev);
									// }}
									// open={isDropdownExpanded}
									clearable
									closeOnEscape={true}
									value={props.statusFilters}
									options={DEVICES_STATUS_FILTER_OPTIONS}
									onChange={props.onStatusFilterChange}
								/>
							</Form.Field>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Field>
								<label>Invoice from</label>
								<DateInput
									name="startDate"
									placeholder="Start Date"
									value={props.invoiceStartDate}
									dateFormat="DD-MM-YYYY"
									maxDate={moment()}
									icon="calendar alternate outline"
									iconPosition="left"
									onChange={props.oninvoiceStartDateChange}
									closable
									clearable
								/>
							</Form.Field>
							<Form.Field>
								<label>Invoice to</label>
								<DateInput
									name="endDate"
									placeholder="End Date"
									value={props.invoiceEndDate}
									dateFormat="DD-MM-YYYY"
									maxDate={moment()}
									minDate={props.invoiceStartDate}
									// minDate={minEndDate}
									// disabled={!props.invoiceStartDate || isToday}
									disabled={!props.invoiceStartDate}
									icon="calendar alternate outline"
									iconPosition="left"
									onChange={props.oninvoiceEndDateChange}
									closable
									clearable
									onClear={() => {
										props.handleClearDate();
									}}
								/>
							</Form.Field>
						</Form.Group>
					</Form>
				</div>
			}
			actions={
				<>
					<FlexContainer>
						<Button onClick={props.onResetClick}>Reset</Button>
						<Expander />
						<Button color="black" onClick={props.onCancelClick}>
							Cancel
						</Button>
						<Button
							primary
							disabled={!props.statusFilters.length && (!props.invoiceStartDate || !props.invoiceEndDate)}
							onClick={props.onApplyClick}>
							Apply
						</Button>
					</FlexContainer>
				</>
			}
		/>
	);
};

const FlexContainer = styled.section`
	display: flex;
`;

export default FilterModal;
