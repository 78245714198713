export default function firebaseService(
	initialState = {
		timestampStatus: null,
		relayStatus: null,
		relayReferencePool: [],

		timestampReferencePool: [],

		intervalId: null,
	},
	action
) {
	switch (action.type) {
		case 'FEED_VEHICLE_DATA':
			return {
				...initialState,
				timestampStatus: action.payload.timestampStatus,
			};

		case 'FEED_VEHICLE_RELAY_DATA':
			return {
				...initialState,
				relayStatus: action.payload.relayStatus,
			};

		case 'FEED_RELAY_REFERENCE_POOL':
			return {
				...initialState,
				relayReferencePool: action.payload.relayReferencePool,
			};

		case 'FEED_REFERENCE_POOL':
			return {
				...initialState,
				timestampReferencePool: action.payload.timestampReferencePool,
			};

		case 'FEED_INTERVAL_ID':
			return {
				...initialState,
				intervalId: action.payload.intervalId,
			};

		default:
			return initialState;
	}
}
