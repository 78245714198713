import styled from 'styled-components';

export const Card = styled.section`
	margin: 0 1em;
	padding: 0 1em 1.5em 2em;

	border-left: 1px solid #eee;

	position: relative;

	:last-child {
		border-color: #fff;
	}
`;

export const Date = styled.section`
	margin-bottom: 0.4em;

	font-size: 1em;
`;

export const Description = styled.section`
	box-sizing: border-box;
	padding: 1em;
	border-radius: 10px;
	background: aquamarine;
`;

export const Circle = styled.section`
	width: 1em;
	height: 1em;

	position: absolute;

	top: 0;
	left: -0.5em;

	background-color: #555;

	border: 1px solid #555;
	border-radius: 50%;
`;
