import { FIREBASE_CONFIG } from '../../data/constants/configs';
import Store from '../../data/store/store';
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getPerformance } from 'firebase/performance';

export let database;
export let performanceRef;

export function initializeFirebase() {
	const app = initializeApp(FIREBASE_CONFIG);
	database = getDatabase(app);

	performanceRef = getPerformance(app);
}

// Realtime Database
// export function connectToFirebase(vehicles) {
// 	// Get previous data from Store.
// 	const storeIntervalId = Store.getState()['firebaseService'].intervalId;
//
// 	const storeTimestampReferencePool = Store.getState()['firebaseService'].timestampReferencePool;
//
// 	// Clear Interval
// 	if (storeIntervalId) clearInterval(storeIntervalId);
//
// 	// Unsubscribe from Firebase
// 	storeTimestampReferencePool.forEach((timestampReference) => {
// 		if (timestampReference) timestampReference.off();
// 	});
//
// 	let relayReference = null;
//
// 	let relayStatus = {};
//
// 	let shouldUpdate = false;
//
// 	for (const vehicle of vehicles) {
// 		relayReference = ref(database, `/${vehicle.id}-${vehicle.registrationNumber}/relay`);
// 		onValue(relayReference, (snapshot) => {
// 			const data = snapshot.val();
// 			if (data) {
// 				relayStatus = {
// 					...relayStatus,
// 					[vehicle.id]: snapshot.val(),
// 				};
// 				shouldUpdate = true;
// 			}
// 		});
// 	}
//
// 	Store.dispatch({
// 		type: 'FEED_RELAY_REFERENCE_POOL',
// 		payload: {
// 			relayReference,
// 		},
// 	});
//
// 	const intervalId = setInterval(() => {
// 		if (shouldUpdate) {
// 			let [mobilized, immobilized] = calculateRelayCounter(relayStatus);
// 			Store.dispatch({
// 				type: 'FEED_VEHICLE_RELAY_DATA',
// 				payload: {
// 					counter: {
// 						immobilized: immobilized,
// 						mobilized: mobilized,
// 					},
// 				},
// 			});
// 			shouldUpdate = false;
// 		}
// 	}, 5000);
//
// 	Store.dispatch({ type: 'FEED_INTERVAL_ID', payload: { intervalId } });
//
// 	Store.dispatch({
// 		type: 'FEED_VEHICLE_RELAY_DATA',
// 		payload: {
// 			relayStatus,
// 		},
// 	});
// }

// const calculateRelayCounter = (data) => {
// 	let mobilized = 0;
// 	let immobilized = 0;
// 	if (data) {
// 		Object.keys(data).map((singleVehicle) => {
// 			if (singleVehicle.status) immobilized++;
// 			else mobilized++;
// 		});
//
// 		return [mobilized, immobilized];
// 	}
// };

export function connectToFirebasePrev(vehicles) {
	// Get previous data from Store.
	const storeIntervalId = Store.getState()['firebaseService'].intervalId;

	const storeTimestampReferencePool = Store.getState()['firebaseService'].timestampReferencePool;

	// Clear Interval
	if (storeIntervalId) clearInterval(storeIntervalId);

	// Unsubscribe from Firebase
	storeTimestampReferencePool.forEach((timestampReference) => {
		if (timestampReference) timestampReference.off();
	});

	let timestampReferencePool = [];

	let timestampReference = null;

	let timestampStatus = {};

	let shouldUpdate = false;
	vehicles.forEach((vehicle) => {
		timestampReference = database().ref(`/${vehicle.id + '-' + vehicle.registrationNumber}/timestamp`);
		timestampReferencePool.push(timestampReference);
		timestampReference.on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				timestampStatus = {
					...timestampStatus,
					[vehicle.id]: snapshot.val(),
				};
				shouldUpdate = true;
			}
		});
	});

	Store.dispatch({
		type: 'FEED_REFERENCE_POOL',
		payload: {
			timestampReferencePool,
		},
	});

	const intervalId = setInterval(() => {
		if (shouldUpdate) {
			Store.dispatch({
				type: 'FEED_VEHICLE_DATA',
				payload: {
					timestampStatus,
				},
			});
			shouldUpdate = false;
		}
	}, 5000);

	Store.dispatch({ type: 'FEED_INTERVAL_ID', payload: { intervalId } });

	Store.dispatch({
		type: 'FEED_VEHICLE_DATA',
		payload: {
			timestampStatus,
		},
	});
}
