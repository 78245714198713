import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../../../data/constants/dimensions';

export const CategoryHeader = styled.section`
	width: 100%;
	height: ${HEADER_HEIGHT};

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding: 0 1em;

	background: ${(props) => props.theme.headerBackground};
	border-bottom: 1px solid ${(props) => props.theme.headerBorder};
	border-top-left-radius: ${(props) => props.borderRadius || 0};
	border-top-right-radius: ${(props) => props.borderRadius || 0};
`;
