import React from 'react';

import CommonModal from '../../../molecules/modal/CommonModal';

import moment from 'moment';

import { Button, Checkbox, Dropdown, Form } from 'semantic-ui-react';
import { Block } from '../../../../styles/Containers';
import { DateInput } from 'semantic-ui-calendar-react';

const RecalculateModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="small"
			header="Recalculation"
			scrolling={true}
			content={
				<Form>
					<Form.Field>
						<label>Organisations</label>
						<Block>
							<Checkbox label="All organisations" checked={props.allOrganisations} onChange={props.onOrgChecked} />
						</Block>
						{!props.allOrganisations && (
							<Block>
								<Form.Group widths="equal">
									<Form.Field>
										<Dropdown
											placeholder="Choose ..."
											fluid
											search
											selection
											multiple
											options={props.organisationOptions}
											value={props.selectedOrganisations}
											onChange={props.onOrgSelect}
										/>
									</Form.Field>
								</Form.Group>
							</Block>
						)}
					</Form.Field>

					<Form.Field>
						<label>Vehicles</label>
						<Block>
							<Checkbox
								label="All Vehicles"
								checked={props.allVehicles}
								disabled={!props.allOrganisations && !props.selectedOrganisations.length && props.allVehicles}
								onChange={props.onAllVehicleChecked}
							/>
						</Block>
						{/*<label>Vehicles</label>*/}
						<Block>
							{!props.allVehicles && (
								<Form.Group widths="equal">
									<Form.Field>
										<Dropdown
											placeholder="Choose ..."
											fluid
											search
											selection
											clearable
											multiple
											options={props.vehiclesFRC}
											disabled={!props.allOrganisations && !props.selectedOrganisations.length}
											value={props.selectedVehicles}
											onChange={props.onVehicleSelect}
										/>
									</Form.Field>
								</Form.Group>
							)}
						</Block>
					</Form.Field>

					<Form.Group widths="equal">
						<Form.Field>
							<label>Start Time</label>
							<Block>
								<DateInput
									name="startTime"
									placeholder="Start Day"
									value={props.startTime}
									dateFormat="DD-MM-YYYY"
									maxDate={moment()}
									icon="calendar alternate outline"
									iconPosition="left"
									onChange={props.handleChange}
									closable
									clearable
								/>
							</Block>
						</Form.Field>
						{/*<Form.Field>*/}
						{/*  <label>Force</label>*/}
						{/*  <ToggleFormField*/}
						{/*    label="Do not wait for the vehicle to stop."*/}
						{/*    checked={props.enableForce}*/}
						{/*    onChange={props.toggleEnableForce}*/}
						{/*  />*/}
						{/*</Form.Field>*/}
					</Form.Group>
				</Form>
			}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button
						primary
						loading={props.recalculateButtonLoading}
						disabled={!props.selectedVehicles.length}
						onClick={props.onRecalculateButtonClick}>
						Recalculate
					</Button>
				</>
			}
		/>
	);
};

export default RecalculateModal;
