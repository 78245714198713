import React, { useState } from 'react';
import CommonModal from '../../../molecules/modal/CommonModal';
import { USER_DOCUMENT_TYPES_OPTIONS } from '../../../../../../data/constants/dropdown-options';
import { Button, Dropdown, Form, Icon, Input } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';

const AddModal = (props) => {
	let fileInput = React.createRef();
	let isSuperAdmin = localStorage.getItem('isSuperAdmin') === 'true' ? true : false;
	const MAX_FILE_SIZE = 5 * 1024 * 1024;

	const [fileSizeError, setFileSizeError] = useState(false);
	const [formValues, setFormValues] = useState([
		{
			organisation: '',
			user: '',
			documentType: '',
			documentExpiry: '',
			documentDesc: '',
			documentFile: '',
		},
	]);

	const handleChange = (data, i) => {
		let newFormValues = [...formValues];
		newFormValues[i][data.name] = data.value;
		setFormValues([...newFormValues]);
	};

	const handleFileChange = (event, i) => {
		let value;
		if (event === null) value = '';
		else value = event.target.files[0];
		if (value && value.size > MAX_FILE_SIZE) {
			setFileSizeError(true);
		} else {
			setFileSizeError(false);
			let newFormValues = [...formValues];
			newFormValues[i].documentFile = value;
			setFormValues(newFormValues);
		}
	};

	let addFormFields = (i) => {
		setFormValues([
			...formValues,
			{
				organisation: formValues[i].organisation,
				user: formValues[i].user,
				documentType: null,
				documentExpiry: null,
				documentDesc: null,
				documentFile: null,
			},
		]);
	};

	let removeFormFields = (i) => {
		let newFormValues = [...formValues];
		newFormValues.splice(i, 1);
		setFormValues(newFormValues);
	};

	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			header="Add Document"
			subHeader="(Document upload size should not exceed 5MB)"
			scrolling={true}
			size={'fullscreen'}
			content={
				<Form style={{ minHeight: '20em' }}>
					{formValues.map((element, index) => {
						return (
							<Form.Group key={index} style={{ width: '100%' }}>
								<Form.Field style={{ width: '16%' }} required>
									<label>Organisation</label>
									<Dropdown
										placeholder="Choose ..."
										name="organisation"
										fluid
										search
										selection
										clearable
										disabled={!isSuperAdmin}
										value={isSuperAdmin ? element.organisation : props?.organisationDropdown[0]?.value}
										options={props.organisationDropdown}
										onChange={(event, data) => {
											handleChange(data, index);
											props.handleOrgChange(data);
										}}
									/>
								</Form.Field>
								<Form.Field style={{ width: '16%' }} required={true}>
									<label>User Name</label>
									<Dropdown
										placeholder="Choose ..."
										name="user"
										fluid
										search
										selection
										clearable
										disabled={index + 1 < formValues.length}
										value={element.user}
										options={props.userDropDown}
										onClick={
											!isSuperAdmin
												? () => {
														let data = { value: [props?.organisationDropdown[0]?.value], name: 'organisation' };
														props.handleOrgChange(data);
														handleChange(data, index);
												  }
												: undefined
										}
										onChange={(event, data) => handleChange(data, index)}
									/>
								</Form.Field>
								<Form.Field style={{ width: '16%' }} required>
									<label>Document Type</label>
									<Dropdown
										placeholder="Choose ..."
										name="documentType"
										fluid
										search
										selection
										clearable
										disabled={index + 1 < formValues.length}
										options={USER_DOCUMENT_TYPES_OPTIONS}
										value={element.documentType}
										onChange={(event, data) => handleChange(data, index)}
									/>
								</Form.Field>
								<Form.Field style={{ width: '16%' }}>
									<label>Document Expiry</label>
									<DateInput
										placeholder="Select"
										name="documentExpiry"
										fluid
										clearable
										disabled={
											index + 1 < formValues.length ||
											element.documentType === 'Aadhaar' ||
											element.documentType === 'Pan Card'
										}
										value={element.documentExpiry}
										onChange={(event, data) => handleChange(data, index)}
									/>
								</Form.Field>
								<Form.Field style={{ width: '16%' }}>
									<label>Document Description</label>
									<Input
										placeholder="Enter Document Description..."
										name="documentDesc"
										fluid
										disabled={index + 1 < formValues.length}
										value={element.documentDesc}
										onChange={(event, data) => handleChange(data, index)}
									/>
								</Form.Field>
								<Form.Field style={{ width: '16%' }} required>
									<label>Upload</label>
									<Form.Input
										type="file"
										error={
											fileSizeError && {
												content: 'Document upload size should not exceed 5MB',
											}
										}
										ref={(ref) => (fileInput = ref)}
										accept=".jpg, .png, .pdf"
										{...(formValues[index].documentFile && {
											icon: (
												<Icon
													name="times circle outline"
													link
													onClick={() => {
														fileInput.inputRef.current.value = '';
														handleFileChange(null, index);
													}}
												/>
											),
										})}
										fluid
										onChange={(e) => handleFileChange(e, index)}
									/>
								</Form.Field>

								<Form.Field style={{ width: '2%', alignSelf: 'center', textAlign: 'center' }}>
									<Icon
										style={{ cursor: 'pointer' }}
										type="button"
										disabled={!index}
										size={'large'}
										name="minus circle"
										onClick={() => removeFormFields(index)}
									/>
								</Form.Field>
								<Form.Field style={{ width: '2%', alignSelf: 'center', textAlign: 'center' }}>
									<Icon
										style={{ cursor: 'pointer' }}
										// disabled={
										// 	!formValues[index].documentType ||
										// 	!formValues[index].organisation ||
										// 	!formValues[index].user ||
										// 	!formValues[index].documentFile ||
										// 	index + 1 < formValues.length
										// }
										name="plus circle"
										size={'large'}
										color={'blue'}
										// loading={addButtonLoading}
										onClick={() => addFormFields(index)}
									/>
								</Form.Field>
							</Form.Group>
						);
					})}
				</Form>
			}
			actions={
				<>
					<Button color="black" onClick={props.onCancel}>
						Cancel
					</Button>
					<Button
						positive
						disabled={
							!formValues[formValues.length - 1].user ||
							!formValues[formValues.length - 1].organisation ||
							!formValues[formValues.length - 1].documentType ||
							!formValues[formValues.length - 1].documentFile
						}
						onClick={() => {
							props.handleFormSubmit(formValues);
						}}>
						Submit Documents
					</Button>
				</>
			}></CommonModal>
	);
};

export default AddModal;
