import { isoToHumanReadableDay } from '../utilities/date';

export const createUserDocumentsDTO = (data) => {
	if (data.length) {
		return data.map((document) => {
			return {
				documentDeletedDate: isoToHumanReadableDay(document.deletedDate),
				documentID: document.id,
				documentExpiry: document?.expiry ? isoToHumanReadableDay(document.expiry) : null,
				documentDescription: document.description,
				documentType: document.type,
				organisationID: document.organisation?.id,
				organisationName: document.organisation?.name,
				userID: document.user?.id,
				userName: document.user?.name,
			};
		});
	}
};
