const accessSetter = (action, setAccess) => {
	if (action === 'manage') setAccess(true, 'manageAccess');
	if (action === 'read') setAccess(true, 'readAccess');
	if (action === 'create') setAccess(true, 'createAccess');
	if (action === 'update') setAccess(true, 'updateAccess');
	if (action === 'delete') setAccess(true, 'deleteAccess');
};

export const entityAccessSetter = (action, entity, setAccess) => {
	if (action === 'manage') setAccess(true, `manage${entity}Access`);
	if (action === 'read') setAccess(true, `read${entity}Access`);
	if (action === 'create') setAccess(true, `create${entity}Access`);
	if (action === 'update') setAccess(true, `update${entity}Access`);
	if (action === 'delete') setAccess(true, `delete${entity}Access`);
};

export default accessSetter;
