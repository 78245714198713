import React from 'react';

import CommonModal from '../../../molecules/modal/CommonModal';

import { Button } from 'semantic-ui-react';

const SendVerificationModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="mini"
			header="Send Verification"
			content={<p>Do you want to send verification link ?</p>}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button positive loading={props.sendButtonLoading} onClick={props.onSendButtonClick}>
						Send
					</Button>
				</>
			}
		/>
	);
};

export default SendVerificationModal;
