import React from 'react';

import CommonModal from '../../../molecules/modal/CommonModal';

import Store from '../../../../../../data/store/store';

import { Button, Form, Icon, Input } from 'semantic-ui-react';

const PasswordModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="tiny"
			header="Reset Password"
			content={
				<Form>
					<Form.Group widths="equal">
						<Form.Field>
							<label>New Password</label>
							<Input
								fluid
								icon={
									<Icon
										name={props.passwordVisible ? 'eye' : 'eye slash'}
										link
										onClick={() => {
											props.passwordVisible
												? Store.dispatch({ type: 'USERS_PASSWORD_NOT_VISIBLE' })
												: Store.dispatch({ type: 'USERS_PASSWORD_VISIBLE' });
										}}
									/>
								}
								type={props.passwordVisible ? 'text' : 'password'}
								onBlur={props.onPasswordSubmit}
							/>
						</Form.Field>
					</Form.Group>
				</Form>
			}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button primary loading={props.submitButtonLoading} onClick={props.onSubmitButtonClick}>
						Submit
					</Button>
				</>
			}
		/>
	);
};

export default PasswordModal;
