import React, { useState } from "react";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Document, Page, pdfjs } from "react-pdf";
import { BackButtonContainer, Container, FileHeader, Header, MainContainer, PdfContainer } from "./PDFViewStyles";
import { Button, Divider } from "semantic-ui-react";
// import fileDownload from 'js-file-download';
// import * as THEME from '../../styles/colors';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function PdfView(props) {
	const [numPages, setNumPages] = useState(null);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	return (
		<MainContainer>
			<Container>
				<Header>
					<FileHeader>{props.documentFileType?.split('.')[0]}</FileHeader>
					<BackButtonContainer>
						<Divider />
						{/*<img*/}
						{/*	title={'Download File'}*/}
						{/*	// src={Download}*/}
						{/*	onClick={() => fileDownload(this.props.source, this.props.filename)}*/}
						{/*/>*/}
						{/*<div alt={'icon'} style={{ cursor: 'pointer', color: `${THEME.COLORS.theme}` }}>*/}
						{/*	{props.documentFileType}*/}
						{/*</div>*/}
						<Button circular color="black" icon="close" onClick={props.onClose} />
					</BackButtonContainer>
				</Header>
				<Divider />

				<PdfContainer>
					<Document file={props.source} onLoadSuccess={onDocumentLoadSuccess}>
						{/*<Page pageNumber={pageNumber} />*/}
						{Array.from(new Array(numPages), (el, index) => (
							<Page key={`page_${index + 1}`} pageNumber={index + 1} />
						))}
					</Document>
				</PdfContainer>
			</Container>
		</MainContainer>
	);
}
