export default function invoices(
	initialState = {
		invoices: [],
		loading: true,
		pageNumber: 1,
		count: 0,
		activeIndex: -1,
		activeSort: '',
		sortOrder: '',

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
	},
	action
) {
	switch (action.type) {
		case 'FEED_INVOICES':
			return {
				...initialState,
				invoices: action.payload,
				loading: false,
			};

		case 'INVOICES_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'INVOICES_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'INVOICES_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'INVOICES_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'INVOICES_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'INVOICES_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'INVOICES_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'INVOICES_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'INVOICES_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_INVOICES_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_INVOICES_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_INVOICES_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'INVOICES_UNMOUNT':
			return {
				...initialState,
				invoices: [],
				activeSort: '',
				sortOrder: '',
				activeIndex: -1,
				pageNumber: 1,
			};

		default:
			return initialState;
	}
}
