import React from 'react';

import { Icon } from 'semantic-ui-react';
import { Container } from './SidebarToggleIconStyles';

function SidebarToggleIcon(props) {
	return (
		<Container>
			<Icon name={props.name} title={props.title} link onClick={props.handleClick} size="big" />
		</Container>
	);
}

export default SidebarToggleIcon;
