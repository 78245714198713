import styled from 'styled-components';

export const ListItem = styled.section`
	margin: 0.5em 0.5em;
	box-sizing: border-box;
	padding: 1em;

	display: flex;
	align-items: center;
	justify-content: center;

	background: ${(props) => props.theme.listItemBackground};
	border: 1px solid ${(props) => props.theme.listItemBorder};
	border-radius: 5px;

	cursor: pointer;

	&:hover {
		box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	}

	transition: box-shadow 0.1s;

	.ui.checkbox {
		margin-right: 1em;
	}
`;
