import React from 'react';
// import { NavLink } from 'react-router-dom';
//
// import LogoMini from '../../molecules/logo/LogoMini';
import DashboardNavList from '../dashboard-nav-list/DashboardNavList';
import { Expander } from '../../../styles/place-holders/Expander';

import { Container } from './NavStyles';

class Nav extends React.Component {
	render() {
		return (
			<Container>
				{/*<NavLink to={`${this.props.urlPrefix}`} exact>*/}
				{/*	<LogoMini height="2em" />*/}
				{/*</NavLink>*/}
				<DashboardNavList urlPrefix={this.props.urlPrefix} />

				<Expander />

				{/*<SettingsMenu />*/}
			</Container>
		);
	}
}

export default Nav;
