import React, { useEffect, useState } from 'react';
import BasicLoader from '../../../blocks/molecules/loader/BasicLoader';
import { GraphCard, GraphContainerForPie } from '../pie-charts/PieChartsStyles';
import { Chart } from 'react-google-charts';
import { NavLink } from 'react-router-dom';

const RelayGraph = (props) => {
	const navLinkRef = React.createRef();

	const [vehicleState, setVehicleState] = useState({});

	useEffect(() => {
		if (navLinkRef.current && Object.keys(vehicleState).length > 0) {
			navLinkRef.current.click();
		}
	}, [
		props.relayVehiclesLoading,
		props.immobilisedVehiclesCount,
		props.mobilisedVehiclesCount,
		vehicleState.immobilizedVehicles,
		vehicleState.mobilizedVehicles,
	]);

	const clickListener = (e) => {
		if (e.targetID === 'slice#1') {
			setVehicleState({ immobilizedVehicles: true });
		}
		if (e.targetID === 'slice#0') {
			setVehicleState({ mobilizedVehicles: true });
		}
		if (e.targetID === 'slice#1' || e.targetID === 'slice#0') navLinkRef?.current?.click();
	};

	return (
		<GraphContainerForPie>
			<GraphCard>
				{props.relayVehiclesLoading && props.immobilisedVehiclesCount !== 0 && props.mobilisedVehiclesCount !== 0 ? (
					<BasicLoader />
				) : (
					<Chart
						chartType="PieChart"
						data={[
							['Mobilised', 'Count'],
							['Total Mobilised', props.mobilisedVehiclesCount],
							['Total Immobilised', props.immobilisedVehiclesCount],
						]}
						height="100%"
						width="100%"
						options={{
							title: 'Mobilised & Immobilised Vehicles',
							pieHole: 0.4,
							slices: {
								1: { offset: 0.1 },
							},
							pieStartAngle: -90,
							pieSliceText: 'value',
							sliceVisibilityThreshold: 0,
							chartArea: {
								right: 0, // set this to adjust the legend width
								left: 20, // set this eventually, to adjust the left margin
							},
							fontSize: 12,
							legend: {
								position: 'bottom',
								alignment: 'center',
							},
						}}
						chartEvents={[
							{
								eventName: 'ready',
								callback: ({ chartWrapper, google }) => {
									const chart = chartWrapper.getChart();
									google.visualization.events.addListener(chart, 'click', clickListener);
								},
							},
						]}
					/>
				)}
			</GraphCard>
			<NavLink
				ref={navLinkRef}
				to={{
					pathname: '/operations/vehicles',
					state: vehicleState,
				}}
			/>
		</GraphContainerForPie>
	);
};

export default RelayGraph;
