import { DEVICES_API, EXPORT_API } from '../configs/api';

import { DEVICES_FILE_HEADER } from '../../data/constants/general';
import { createDevicesDTO } from '../dto/devices';
import { createDeviceModelsDTO } from '../dto/deviceModels';
// import { createSimDTO } from '../dto/sims';
import Logo from '../../ui/assets/images/logo.png';

import Store from '../../data/store/store';
import { exportToExcelWithLogo } from '../utilities/excel';
import { fetchDeviceModels } from './deviceModels';
import { fetchSims } from './sims';
import { handleError } from './messaging';
import { dispatch, dispatchNoPayload } from './middleware';

// import axios from 'axios';
import { jsPDF } from 'jspdf';
import fileDownload from 'js-file-download';
import { exportApi, getData } from './services/apiRequest';
import { datadogLogs } from '@datadog/browser-logs';

export function fetchDevices(params = '') {
	return getData(DEVICES_API + params);

	// axios(DEVICES_API + params, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// });
}

export function refreshDevices(params = '', activePage = 1, resetOffset = false) {
	dispatchNoPayload('DEVICES_LOADING');
	// axios()
	// 	DEVICES_API +
	// 		'?pagecount=' +
	// 		getPageNumberFromStore().dataSizeLimit +
	// 		'&pagenumber=' +
	// 		(resetOffset ? 1 : activePage) +
	// 		params,
	// 	{
	// 		headers: {
	// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 			'Content-Type': 'application/json',
	// 		},
	// 	}
	// )
	getData(
		DEVICES_API +
			'?pagecount=' +
			getPageNumberFromStore().dataSizeLimit +
			'&pagenumber=' +
			(resetOffset ? 1 : activePage) +
			params
	)
		.then((response) => {
			dispatch('FEED_DEVICES', createDevicesDTO(response?.data?.data));
			dispatch('DEVICES_SET_PAGE_COUNT', response?.data?.count || 0);
			dispatch('DEVICES_SET_PAGE_NUMBER', activePage);
		})
		.catch((error) => {
			dispatchNoPayload('DEVICES_LOADED');
			handleError(error);
		});
}

export function searchDevices(query) {
	return `&search=${encodeURIComponent(query)
		.trim()
		.split('+')
		.map((searchTerm) => `${searchTerm}`)}`;
}

// Export
export function openExportModal() {
	dispatchNoPayload('DEVICES_EXPORT_MODAL_OPEN');
}

export function closeExportModal() {
	dispatchNoPayload('DEVICES_EXPORT_MODAL_CLOSE');
}

export function formatExcelExport(response) {
	return response.map((device) => {
		return {
			Imei: device.imei,
			'Serial No.': device.serial,
			Status: device.status,
			Manufacturer: device.manufacturer,
			Model: device.model,
			Sim1: device.sims[0]?.mobileNumber,
			'Sim Provider of 1': device.sims[0]?.serviceProvider,
			Sim2: device.sims[1]?.mobileNumber,
			'Sim Provider of 2': device.sims[1]?.serviceProvider,
			Note: device.note,
		};
	});
}

export function exportData(data, fileName, extension) {
	if (extension === 'excel') {
		exportToExcelWithLogo(formatExcelExport(data), `${!fileName ? 'Devices' : fileName}`, DEVICES_FILE_HEADER);
	} else {
		convertToPdf(formatExcelExport(data), fileName);
	}
	closeExportModal();
}

export function convertToPdf(data, fileName) {
	const doc = new jsPDF({ orientation: 'l', unit: 'mm', format: 'a4' });

	let col = [
		'Imei',
		'Serial No.',
		'Status',
		'Manufacturer',
		'Model',
		'Sim1',
		'Sim Provider of 1',
		'Sim2',
		'Sim Provider of 2',
		'Note',
	];
	let rows = [];

	data.forEach((model) => {
		let temp = [];
		Object.keys(model).forEach((key, index) => {
			if (col[index] === key) temp[index] = model[key];
		});

		rows.push(temp);
	});

	let header = function (data) {
		doc.addImage(Logo, 'png', data.settings.margin.left, 10, 40, 14);
	};

	doc.autoTable(col, rows, {
		margin: { top: 25 },
		beforePageContent: header,
		theme: 'grid',
	});

	doc.save(`${!fileName ? 'Devices' : fileName}`);
}

export function exportAll(param) {
	// dispatchNoPayload('EXPORTING_DEVICES_EXPORT_DATA');
	// axios(EXPORT_API + '/devices' + param, {
	// 	responseType: 'arraybuffer',
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 	},
	// })
	exportApi(EXPORT_API + '/devices' + param)
		.then((response) => {
			// openExportModal();
			// dispatch('SET_DEVICES_EXPORT_DATA', createDevicesDTO(response?.data?.data));
			// dispatchNoPayload('EXPORTED_DEVICES_EXPORT_DATA');
			// fileDownload(response?.data, `Devices.${fileExtension}`);
			const contentDisposition = response.headers['content-disposition'];
			const matches = /filename="([^"]+)"/.exec(contentDisposition);

			if (matches) {
				const fileName = matches[1];
				fileDownload(response?.data, fileName);
			}
		})
		.catch((error) => {
			handleError(error);
			datadogLogs.logger.error('Device export api', {}, error);
			dispatchNoPayload('EXPORTED_DEVICES_EXPORT_DATA');
		});
}

// Accordion
export function toggleAccordion(e, titleProps, current) {
	const { index } = titleProps;
	const { activeIndex } = { activeIndex: current };
	const newIndex = activeIndex === index ? -1 : index;

	dispatchNoPayload('DEVICES_EDIT_MODE_CLOSE');
	dispatch('DEVICES_TOGGLE_ACCORDION', newIndex);
}

export function openFilterModal() {
	dispatchNoPayload('DEVICES_FILTER_MODAL_OPEN');
}

export function closeFilterModal() {
	dispatchNoPayload('DEVICES_FILTER_MODAL_CLOSE');
}

// Dropdowns
export function getDeviceModelsForDropdown(params = '') {
	return fetchDeviceModels('?dropdown=true' + params)
		.then((response) => {
			let deviceModels = createDeviceModelsDTO(response?.data?.data);
			if (deviceModels) {
				return deviceModels.map((model, index) => {
					return {
						key: index,
						value: model.id,
						text: model.manufacturer + ' ' + model.model,
					};
				});
			}

			return [];
		})
		.catch((error) => {
			handleError(error);
			return [];
		});
}

export function getSimsForDropdown(params = '') {
	return fetchSims('?dropdown=true&status[]=New' + params)
		.then((response) => {
			// const sims = createSimDTO(response?.data?.data);
			let eligibleSims = [];
			response?.data?.data.forEach((sim, index) => {
				eligibleSims.push({
					key: index,
					value: sim.mobileNumber,
					text: sim.mobileNumber + ' - ' + sim.simNumber,
				});
			});

			return eligibleSims;
		})
		.catch((error) => {
			handleError(error);
			return [];
		});
}

// Connect to Store
function getPageNumberFromStore() {
	return {
		pageNumber: Store.getState().devices.pageNumber,
		dataSizeLimit: Store.getState().ui.dataSizeLimit,
	};
}
