import styled from 'styled-components';

export const CategoryContent = styled.section`
	width: ${(props) => props.width};
	height: 100%;

	flex-grow: 1;

	overflow: auto;
`;
