import { isoToDate } from '../utilities/date';

export function createOrganisationDTO(data) {
	if (data && data.length) {
		return data.map((org) => {
			return {
				id: org?.id ? org.id : null,
				registeredName: org?.registeredName ? org?.registeredName : null,
				name: org?.name ? org.name : null,
				createdDate: org?.createdDate ? org?.createdDate : null,
				email: org?.email ? org.email : null,
				emailVerified: org?.emailVerified ? org.emailVerified : null,
				fmsCount: org?.fmsCount ? org.fmsCount : null,
				fmsPotential: org?.fmsPotential ? org.fmsPotential : null,
				gpsCount: org?.gpsCount ? org.gpsCount : null,
				gpsPotential: org?.gpsPotential ? org.gpsPotential : null,
				industry: org?.industry ? org.industry : null,
				phone: org?.phone ? org.phone : null,
				deletedDate: org?.deletedDate ? isoToDate(org.deletedDate) : null,
				platformAwareness: org?.platformAwareness ? org.platformAwareness : null,
				preferredCommunicationChannel: org?.preferredCommunicationChannel ? org.preferredCommunicationChannel : null,
				phoneVerified: org?.phoneVerified ? org.phoneVerified : null,
				address: org?.address ? org.address : null,
				website: org?.website ? org.website : null,
				billingPeriod: org?.billingPeriod ? org.billingPeriod : null,
				CIN: org?.CIN ? org.CIN : null,
				GST: org?.GST ? org.GST : null,
				PAN: org?.PAN ? org.PAN : null,
				saasPlans: org?.saasPlans ? org.saasPlans : [],
				state: org?.state ? org.state : null,
				solutions: org?.solutions ? org.solutions : null,
				useCase: org?.useCase ? org.useCase : null,
				city: org?.city ? org.city : null,
				vehicles:
					org?.vehicles && org.vehicles.length !== 0
						? org.vehicles.map((vehicle) => {
								return {
									id: vehicle.id,
									registrationNumber: vehicle.registrationNumber,
								};
						  })
						: [],
			};
		});
	} else return [];
}

export function createOrganisationSaasPlanDTO(org) {
	if (!org) return null;
	return {
		id: org?.id ? org.id : null,
		name: org?.name ? org.name : null,
		email: org?.email ? org.email : null,
		phone: org?.phone ? org.phone : null,
		deletedDate: org?.deletedDate ? isoToDate(org.deletedDate) : null,
		address: org?.address ? org.address : null,
		website: org?.website ? org.website : null,
		billingPeriod: org?.billingPeriod ? org.billingPeriod : null,
		CIN: org?.CIN ? org.CIN : null,
		GST: org?.GST ? org.GST : null,
		PAN: org?.PAN ? org.PAN : null,
		saasPlans:
			org?.saasPlans && org.saasPlans.length !== 0
				? org.saasPlans.map((group) => {
						return {
							id: group.id,
							name: group.name,
						};
				  })
				: [],
	};
}
