import { DEVICE_MODELS_API, EXPORT_API, PROTOCOLS_API } from '../configs/api';
import { DEVICE_MODELS_FILE_HEADER } from '../../data/constants/general';
import { createDeviceModelsDTO } from '../dto/deviceModels';
import Logo from '../../ui/assets/images/logo.png';
import Store from '../../data/store/store';
import { exportToExcelWithLogo } from '../utilities/excel';
import { dispatch, dispatchNoPayload } from './middleware';
import { handleError } from './messaging';
// import axios from 'axios';
import { jsPDF } from 'jspdf';
import fileDownload from 'js-file-download';
import { exportApi, getData } from './services/apiRequest';

export function fetchDeviceModels(params = '') {
	return getData(DEVICE_MODELS_API, params);
	// axios(DEVICE_MODELS_API + params, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// });
}

export function fetchProtocols(params = '') {
	return getData(PROTOCOLS_API, params);
	// axios(PROTOCOLS_API + params, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// });
}

export function refreshDeviceModels(params = '', activePage = 1, resetOffset = false) {
	dispatchNoPayload('DEVICE_MODELS_LOADING');

	// axios(
	// 	DEVICE_MODELS_API +
	// 		'?pagecount=' +
	// 		getPageNumberFromStore().dataSizeLimit +
	// 		'&pagenumber=' +
	// 		(resetOffset ? 1 : activePage) +
	// 		params,
	// 	{
	// 		headers: {
	// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 			'Content-Type': 'application/json',
	// 		},
	// 	}
	// )
	getData(
		DEVICE_MODELS_API,
		'?pagecount=' + getPageNumberFromStore().dataSizeLimit + '&pagenumber=' + (resetOffset ? 1 : activePage) + params
	)
		.then((response) => {
			dispatch('FEED_DEVICE_MODELS', createDeviceModelsDTO(response?.data?.data));
			dispatch('DEVICE_MODELS_SET_PAGE_COUNT', response?.data?.count || 0);
			dispatch('DEVICE_MODELS_SET_PAGE_NUMBER', activePage);
		})
		.catch((error) => {
			handleError(error);
			dispatchNoPayload('DEVICE_MODELS_LOADED');
		});
}

export function searchDeviceModels(query) {
	return `&search=${encodeURIComponent(query)
		.trim()
		.split('+')
		.map((searchTerm) => `${searchTerm}`)}`;
}

// Export
export function openExportModal() {
	dispatchNoPayload('DEVICE_MODELS_EXPORT_MODAL_OPEN');
}

export function closeExportModal() {
	dispatchNoPayload('DEVICE_MODELS_EXPORT_MODAL_CLOSE');
}

export function formatExcelExport(response) {
	return response.map((model) => {
		return {
			Manufacturer: model.manufacturer,
			Model: model.model,
			Protocol: model.port,
			isOBD: model.isOBD,
		};
	});
}

export function exportData(data, fileName, extension) {
	if (extension === 'excel') {
		exportToExcelWithLogo(
			formatExcelExport(data),
			`${!fileName ? 'Device Models' : fileName}`,
			DEVICE_MODELS_FILE_HEADER
		);
	} else {
		convertToPdf(formatExcelExport(data), fileName);
	}

	closeExportModal();
}

export function convertToPdf(data, fileName) {
	const doc = new jsPDF({ orientation: 'l', unit: 'mm', format: 'a4' });

	let col = ['Manufacturer', 'Model', 'Protocol', 'isOBD'];
	let rows = [];

	data.forEach((model) => {
		let temp = [];
		Object.keys(model).forEach((key, index) => {
			if (col[index] === key) temp[index] = model[key];
		});

		rows.push(temp);
	});

	let header = function (data) {
		doc.addImage(Logo, 'png', data.settings.margin.left, 10, 40, 14);
	};

	doc.autoTable(col, rows, {
		margin: { top: 25 },
		beforePageContent: header,
		theme: 'grid',
	});

	doc.save(`${!fileName ? 'Device Models' : fileName}`);
}

export function exportAll(param, fileExtension) {
	dispatchNoPayload('EXPORTING_DEVICE_MODELS_EXPORT_DATA');
	// axios(EXPORT_API + '/deviceModels' + param, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// })
	exportApi(EXPORT_API + '/deviceModels' + param)
		.then((response) => {
			// openExportModal();
			// dispatch('SET_DEVICE_MODELS_EXPORT_DATA', createDeviceModelsDTO(response?.data?.data));
			// dispatchNoPayload('EXPORTED_DEVICE_MODELS_EXPORT_DATA');
			fileDownload(response?.data, fileExtension);
		})
		.catch((error) => {
			handleError(error);
			dispatchNoPayload('EXPORTED_DEVICE_MODELS_EXPORT_DATA');
		});
}

// Accordion
export function toggleAccordion(e, titleProps, current) {
	const { index } = titleProps;
	const { activeIndex } = {
		activeIndex: current,
	};
	const newIndex = activeIndex === index ? -1 : index;
	dispatchNoPayload('DEVICE_MODELS_EDIT_MODAL_CLOSE');
	dispatch('DEVICE_MODELS_TOGGLE_ACCORDION', newIndex);
}

// Dropdowns
export function getProtocolsForDropdown(params = '') {
	return fetchProtocols(params)
		.then((response) => {
			let protocols = response?.data;
			if (Object.keys(protocols).length) {
				return Object.keys(protocols).map((protocol, index) => {
					return {
						key: index,
						value: protocols[protocol],
						text: `${protocol}-${protocols[protocol]}`,
					};
				});
			}

			return [];
		})
		.catch((error) => {
			handleError(error);
			return [];
		});
}

// Connect to Store
function getPageNumberFromStore() {
	return {
		pageNumber: Store.getState().deviceModels.pageNumber,
		dataSizeLimit: Store.getState().ui.dataSizeLimit,
	};
}
