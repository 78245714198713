import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import { CategoryContentMembrane } from '../service-and-maintenance/Service';
import { CategoryHeader } from '../../../styles/containers/CategoryHeader';
import { CategoryFooter } from '../../../styles/containers/CategoryFooter';
import FooterPagination from '../../../blocks/molecules/pagination/Pagination';
// import { TableContainer } from '../../../styles/Accordion';
import SearchBox from '../../../blocks/atoms/search-box/SearchBox';
import FilterModal from '../../../blocks/organisms/modals/activity-logs/FilterModal';
import { isoToHumanReadable } from '../../../../../logic/utilities/date';
// import { COLORS } from '../../../../styles/colors';
import { refreshAuditLog } from '../../../../../logic/middleware/activityLogs';
import { getOrganisationsForDropdown, getVehiclesForDropdown } from '../../../../../logic/middleware/users';
import { getUsersForDropdown } from '../../../../../logic/middleware/alerts';
import { Chrono } from 'react-chrono';
import { ChronoContainer } from '../../../styles/Accordion';
import PlaceHolder from '../../../blocks/molecules/place-holder/PlaceHolder';
import BasicLoader from '../../../blocks/molecules/loader/BasicLoader';
import { NavLink } from 'react-router-dom';
import { dispatch } from '../../../../../logic/middleware/middleware';

const ActivityLogs = (props) => {
	let vehicleId = props.location?.state?.vehicleId;
	vehicleId = vehicleId ? [vehicleId] : [];
	const [filterModalOpen, setFilterModalOpen] = useState(false);
	// const [activeSort, setActiveSort] = useState('');
	// const [sortOrder, setSortOrder] = useState('desc');
	const [activeFilters, setActiveFilters] = useState({ vehicle: vehicleId });
	const [activeSearch, setActiveSearch] = useState(null);
	const [orgDropdownOption, setOrgDropdownOptions] = useState([]);
	const [vehicleDropdownOption, setVehicleDropdownOption] = useState([]);
	const [userDropdownOptions, setUserDropdownOptions] = useState([]);
	const [initialLoading, setInitialLoading] = useState(true);
	const [data, setData] = useState([]);

	useEffect(() => {
		getFilteredActivityLogs();
	}, [activeFilters, activeSearch]);

	useEffect(() => {
		window.history.replaceState(null, null, window.location.pathname);
	}, []);
	const getFilteredActivityLogs = async (activePage = 1) => {
		setInitialLoading(true);
		let query = '';
		// if (value) {
		// 	query = query + searchLogs(value);
		// }

		// props.pageNumber
		// let query = `?pagecount=${props.dataSizeLimit}&pagenumber=${pageNumber}`;

		// if (activeSort !== '') query += `&sortBy=${activeSort}&orderBy=${sortOrder}`;
		if (activeFilters?.organisation?.length)
			query += `&requestedOrgId=${activeFilters.organisation.join('&requestedOrgId[]=')}`;
		if (activeFilters?.userName?.length) {
			query += `&requestedUserId=${activeFilters.userName.join('&requestedUserId[]=')}`;
		}
		if (activeFilters?.vehicle?.length) {
			query += `&vehicleId=${activeFilters.vehicle.join(`&vehicleId[]=`)}`;
		}

		if (activeSearch !== null || activeSearch?.length) query += `&search=${activeSearch}`;
		let response = await refreshAuditLog(query, activePage);
		setData(response);
		setInitialLoading(false);
	};

	const handleDataSizeLimitChange = () => {
		getFilteredActivityLogs();
	};

	// const sort = (param) => {
	// 	let activeSortTemp;
	// 	let sortOrderTemp;
	// 	if (activeSort !== param) {
	// 		activeSortTemp = param;
	// 		sortOrderTemp = 'asc';
	// 	} else {
	// 		if (sortOrder === 'desc') {
	// 			activeSortTemp = param;
	// 			sortOrderTemp = 'asc';
	// 		} else {
	// 			activeSortTemp = param;
	// 			sortOrderTemp = 'desc';
	// 		}
	// 	}
	//
	// 	setActiveSort(activeSortTemp);
	// 	setSortOrder(sortOrderTemp);
	// 	getFilteredActivityLogs();
	// };

	const handlePageChange = (activePage) => {
		getFilteredActivityLogs(activePage);
	};

	const handleSearch = (event) => {
		// encodeURIComponent
		let value = event.target.value;
		if (!value) value = null;
		setActiveSearch(value);

		// if (timeoutId) clearTimeout(timeoutId);
		// timeoutId = setTimeout(() => {
		// 	getFilteredActivityLogs(value);
		// }, 500);
	};

	const userName = (name) => {
		return (
			<NavLink
				to={`/operations/users`}
				style={{ color: 'blue' }}
				onClick={() => {
					dispatch('UPDATE_SEARCH_QUERY', name);
				}}>
				{name}
			</NavLink>
		);
	};

	return (
		<CategoryContentMembrane>
			<CategoryHeader>
				<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
					<SearchBox
						searchQuery={activeSearch}
						placeholder="Search in activity"
						clearSearchIconClick={() => {
							setActiveSearch(null);
							// getFilteredActivityLogs();
						}}
						handleSearch={handleSearch}
						value={activeSearch}
					/>

					<Button
						primary={Object.values(activeFilters).every((value) => value?.length === 0)}
						color={Object.values(activeFilters).some((value) => value?.length > 0) ? 'orange' : null}
						onClick={() => {
							getOrganisationsForDropdown(`?dropdown=true`).then((response) => {
								setOrgDropdownOptions(response);
							});
							getUsersForDropdown('?dropdown=true').then((response) => {
								setUserDropdownOptions(response);
							});
							getVehiclesForDropdown('?dropdown=true').then((response) => {
								setVehicleDropdownOption(response);
							});
							setFilterModalOpen(true);
						}}
						icon>
						<Icon name="filter" />
					</Button>
				</div>
			</CategoryHeader>
			{/*<TableContainer style={{ width: '100%', height: 'calc(100% - 8em)', overflow: 'auto' }} id="Container">

				<Table striped selectable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>No.</Table.HeaderCell>
							<Table.HeaderCell singleLine style={{ width: '10%' }}>
								User Name
							</Table.HeaderCell>
							<Table.HeaderCell
								singleLine
								style={{
									cursor: 'pointer',
									marginRight: 0,
									width: '10%',
									color: activeSort === 'methodName' ? COLORS.activeSortColor : '',
								}}
								onClick={() => {
									sort('methodName');
								}}
								activeSort={activeSort === 'methodName'}>
								{activeSort === 'methodName' ? (
									sortOrder === 'asc' ? (
										<Icon name="sort alphabet down" />
									) : (
										<Icon name="sort alphabet up" />
									)
								) : (
									<Icon name="sort" />
								)}
								Activity
							</Table.HeaderCell>
							<Table.HeaderCell
								singleLine
								style={{
									cursor: 'pointer',
									marginRight: 0,
									width: '13%',
									color: activeSort === 'modelName' ? COLORS.activeSortColor : '',
								}}
								onClick={() => {
									sort('modelName');
								}}
								activeSort={activeSort === 'modelName'}>
								{activeSort === 'modelName' ? (
									sortOrder === 'asc' ? (
										<Icon name="sort alphabet down" />
									) : (
										<Icon name="sort alphabet up" />
									)
								) : (
									<Icon name="sort" />
								)}
								Model Name
							</Table.HeaderCell>
							<Table.HeaderCell style={{ width: '20%' }}>Timestamp</Table.HeaderCell>

							<Table.HeaderCell singleLine style={{ minWidth: '10%' }}>
								Changes
							</Table.HeaderCell>

							<Table.HeaderCell style={{ width: '10%' }}>Email</Table.HeaderCell>
							<Table.HeaderCell style={{ width: '10%' }}>Phone</Table.HeaderCell>
							<Table.HeaderCell style={{ width: '10%' }}>Type</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{data.map((log, idx) => {
							return (
								<Table.Row key={idx}>
									<Table.Cell collapsing>{1 + idx}</Table.Cell>
									<Table.Cell
										singleLine
										style={{
											width: '11%',
											color: log.user?.name ? 'black' : 'grey',
										}}>
										{log.user?.name || 'NA'}
									</Table.Cell>
									<Table.Cell
										singleLine
										style={{
											width: '10%',
											color: log.methodName ? 'black' : 'grey',
										}}>
										{log.methodName || 'NA'}
									</Table.Cell>

									<Table.Cell
										singleLine
										style={{
											width: '13%',
											color: log.modelName ? 'black' : 'gray',
										}}>
										{log.modelName || 'NA'}
									</Table.Cell>

									<Table.Cell
										singleLine
										style={{
											width: '20%',
											color: `${isoToHumanReadable(log.createdDate)} ? black : gray`,
										}}>
										{isoToHumanReadable(log.createdDate) || 'NA'}
									</Table.Cell>
									<Table.Cell
										collapsing
										style={{
											width: '20%',
											wordBreak: 'break-word',
											whiteSpace: 'pre-wrap',
											overflowWrap: 'break-word',
											color: log.modelChanges ? 'black' : 'grey',
										}}>
										{log.modelChanges || 'NA'}
										{log.modelChanges
											? (() => {
												try {
													const parsedChanges = JSON.parse(log.modelChanges);
													return parsedChanges.map((change, index) => (
														<div key={index}>
															<strong>Property:</strong> {change.propertyName}
															<br />
															<strong>Old Value:</strong> {change.oldValue === null ? 'null' : change.oldValue}
															<br />
															<strong>New Value:</strong> {change.newValue === null ? 'null' : change.newValue}
															<br />
														</div>
													));
												} catch (error) {
													return 'Invalid Data';
												}
											})()
											: 'NA'}
									</Table.Cell>


									<Table.Cell style={{ width: '10%', color: log.user?.email ? 'black' : 'grey' }}>
										{log.user?.email || 'NA'}
									</Table.Cell>
									<Table.Cell style={{ width: '10%', color: log.user?.phone ? 'black' : 'grey' }}>
										{log.user?.phone || 'NA'}
									</Table.Cell>
									<Table.Cell style={{ width: '10%', color: log.type ? 'black' : 'grey' }}>
										{log.type || 'NA'}
									</Table.Cell>
								</Table.Row>
							);
						})}
					</Table.Body>
				</Table>
			</TableContainer>*/}
			{initialLoading ? (
				<BasicLoader />
			) : (
				<ChronoContainer style={{ width: '100%', height: 'calc(100% - 8em)', overflow: 'auto' }} id="Container">
					{data.length !== 0 ? (
						<Chrono
							items={data.map((log, index) => ({
								title: isoToHumanReadable(log.createdDate),
								key: index,
							}))}
							mode="VERTICAL_ALTERNATING"
							cardHeight={'100%'}
							cardWidth={'100%'}
							fontSizes={{
								cardSubtitle: '1rem',
								cardDetailedText: '3rem',
								cardTitle: '1.5rem',
								title: '1rem',
							}}
							// scrollable={{ scrollbar: false }}
							theme={{
								primary: '#1b3c71',
								secondary: '#fff',
								cardBgColor: '#f5f5f7',
								titleColor: 'black',
							}}
							// enableOutline
						>
							{data.map((log, index) => {
								return (
									<div
										key={index}
										style={{
											display: 'flex',
											// gridTemplateColumns: 'repeat(2, 1fr)',
											width: '100%',
											alignItems: 'left',
											padding: '5px',
										}}>
										{/*{log.requestedUser !== null && (
											<div>
												<span>
													<h3>{log.requestedUser?.name}</h3>
												</span>
												<br />
												<span>
													<Icon name={'mail'} color={'blue'} />
													<strong>{log.requestedUser?.email}</strong>
												</span>
												<br />
												<br />
												<span>
													<Icon name={'phone'} color={'blue'} />
													<strong>{log.requestedUser?.phone} </strong>
												</span>
											</div>
										)}*/}

										<div style={{ textAlign: 'left' }}>
											{/*<span>
												<strong>Activity:</strong> {log.methodName}
											</span>
											<br />
											<span>
												<strong>Module Name:</strong> {log.modelName}
											</span>*/}

											<span>
												{log.modelChanges ? (
													(() => {
														try {
															const parsedChanges = JSON.parse(log.modelChanges);
															return (
																<div>
																	{log.modelName === 'vehicles' ? (
																		<span style={{ fontSize: '15px' }}>
																			{userName(log.requestedUser.name)} Updated Vehicles{' '}
																			<NavLink
																				to={`/operations/vehicles`}
																				style={{ color: 'blue' }}
																				onClick={() => {
																					dispatch('UPDATE_SEARCH_QUERY', log.vehicle.registrationNumber);
																				}}>
																				{log.vehicle.registrationNumber}
																			</NavLink>
																		</span>
																	) : log.modelName === 'users' ? (
																		<span style={{ fontSize: '15px' }}>
																			{log.requestedUser !== null ? userName(log.requestedUser.name) : ' '} Updated User{' '}
																			<NavLink
																				to={`/operations/users`}
																				style={{ color: 'blue' }}
																				onClick={() => {
																					dispatch('UPDATE_SEARCH_QUERY', log.user.name);
																				}}>
																				{log.user.name}
																			</NavLink>
																		</span>
																	) : log.modelName === 'sims' ? (
																		<span style={{ fontSize: '15px' }}>
																			{userName(log.requestedUser.name)} Updated Sim Number{'  '}
																			<NavLink
																				to={`/operations/sims`}
																				style={{ color: 'blue' }}
																				onClick={() => {
																					dispatch('UPDATE_SEARCH_QUERY', log.sim.mobileNumber);
																				}}>
																				{log.sim.mobileNumber}
																			</NavLink>
																		</span>
																	) : log.modelName === 'students' ? (
																		<span style={{ fontSize: '15px' }}>
																			{userName(log.requestedUser.name)} Updated Student{' '}
																			<NavLink
																				to={`/operations/students`}
																				style={{ color: 'blue' }}
																				onClick={() => {
																					dispatch('UPDATE_SEARCH_QUERY', log.student.name);
																				}}>
																				{log.student.name}
																			</NavLink>
																		</span>
																	) : log.modelName === 'organisations' ? (
																		<span style={{ fontSize: '15px' }}>
																			{userName(log.requestedUser.name)} Updated Organisations{' '}
																			<NavLink
																				to={`/operations/organisations`}
																				style={{ color: 'blue' }}
																				onClick={() => {
																					dispatch('UPDATE_SEARCH_QUERY', log.organisation.name);
																				}}>
																				{log.organisation.name}
																			</NavLink>
																		</span>
																	) : log.modelName === 'vehiclegroups' ? (
																		<span style={{ fontSize: '15px' }}>
																			{userName(log.requestedUser.name)} Updated Vehicle Group{' '}
																			<NavLink
																				to={`/operations/vehiclegroups`}
																				style={{ color: 'blue' }}
																				onClick={() => {
																					dispatch('UPDATE_SEARCH_QUERY', log.vehicleGroup.name);
																				}}>
																				{log.vehicleGroup.name}
																			</NavLink>
																		</span>
																	) : log.modelName === 'devices' ? (
																		<span style={{ fontSize: '15px' }}>
																			{userName(log.requestedUser.name)} Updated Imei Number{' '}
																			<NavLink
																				to={`/operations/devices`}
																				style={{ color: 'blue' }}
																				onClick={() => {
																					dispatch('UPDATE_SEARCH_QUERY', log.device.imei);
																				}}>
																				{log.device.imei}
																			</NavLink>
																		</span>
																	) : log.modelName === 'device_commands' ? (
																		<span style={{ fontSize: '15px' }}>
																			{log.requestedUser !== null ? userName(log.requestedUser.name) : ''} Updated
																			Device Commands{' '}
																			<NavLink
																				to={`/operations/devices`}
																				style={{ color: 'blue' }}
																				onClick={() => {
																					dispatch('UPDATE_SEARCH_QUERY', log.deviceCommands.imei);
																				}}>
																				{log.deviceCommands.imei}
																			</NavLink>
																		</span>
																	) : (
																		''
																	)}
																	{parsedChanges.map((change, index) => (
																		// <div key={index}>
																		<span key={index} style={{ fontSize: '15px' }}>
																			{'  '}
																			{change.propertyName} From{'  '}
																			{change.oldValue === null
																				? 'null'
																				: change.oldValue === true
																				? 'True'
																				: change.oldValue === false
																				? 'False'
																				: change.oldValue.registrationNumber ||
																				  change.oldValue.name ||
																				  JSON.stringify(change.oldValue)}{' '}
																			to{' '}
																			{change.newValue === null
																				? 'null'
																				: change.newValue === true
																				? 'True'
																				: change.newValue === false
																				? 'False'
																				: change.newValue.registrationNumber ||
																				  change.newValue.name ||
																				  JSON.stringify(change.newValue)}
																			,{' '}
																		</span>
																		// </div>
																	))}
																</div>
															);
														} catch (error) {
															return <div>{log.modelChanges}</div>;
														}
													})()
												) : (
													<div>
														{log.modelName === 'permissions' && log.permission !== null ? (
															<span>
																{userName(log.requestedUser.name)} Inserted Permissions
																{/*<b>{log.permission.action !== null && log.permission.action !== undefined ? log.permission.action : 'Action'} :</b>{' '}*/}
																{/*{log.permission.subject !== null ? log.permission.subject : 'Subject'}*/}
															</span>
														) : log.modelName === 'organisations' && log.organisation !== null ? (
															<span style={{ fontSize: '15px' }}>
																{userName(log.requestedUser.name)} Inserted Organisations{' '}
																<NavLink
																	to={`/operations/organisations`}
																	style={{ color: 'blue' }}
																	onClick={() => {
																		dispatch('UPDATE_SEARCH_QUERY', log.organisation.name);
																	}}>
																	{log.organisation.name}
																</NavLink>
															</span>
														) : log.modelName === 'vehicles' && log.vehicle !== null ? (
															<span style={{ fontSize: '15px' }}>
																{userName(log.requestedUser.name)} Inserted Vehicle{' '}
																<NavLink
																	to={`/operations/vehicles`}
																	style={{ color: 'blue' }}
																	onClick={() => {
																		dispatch('UPDATE_SEARCH_QUERY', log.vehicle.registrationNumber);
																	}}>
																	{log.vehicle.registrationNumber}
																</NavLink>
																{/*<br />*/}
																{/*<b>Type :</b> {log.vehicle.type}*/}
															</span>
														) : log.modelName === 'users' && log.user !== null ? (
															<span style={{ fontSize: '15px' }}>
																{userName(log.requestedUser.name)} Inserted User{' '}
																<NavLink
																	to={`/operations/users`}
																	style={{ color: 'blue' }}
																	onClick={() => {
																		dispatch('UPDATE_SEARCH_QUERY', log.user.name);
																	}}>
																	{log.user.name}
																</NavLink>
															</span>
														) : log.modelName === 'students' && log.student !== null ? (
															<span style={{ fontSize: '15px' }}>
																{userName(log.requestedUser.name)} Inserted Student{' '}
																<NavLink
																	to={`/operations/students`}
																	style={{ color: 'blue' }}
																	onClick={() => {
																		dispatch('UPDATE_SEARCH_QUERY', log.student.name);
																	}}>
																	{log.student.name}
																</NavLink>
															</span>
														) : log.modelName === 'vehiclegroups' && log.vehicleGroup !== null ? (
															<span style={{ fontSize: '15px' }}>
																{userName(log.requestedUser.name)} Inserted Vehicle Group
																<NavLink
																	to={`/operations/vehiclegroups`}
																	style={{ color: 'blue' }}
																	onClick={() => {
																		dispatch('UPDATE_SEARCH_QUERY', log.vehicleGroup.name);
																	}}>
																	{log.vehicleGroup.name}
																</NavLink>
															</span>
														) : log.modelName === 'alerts' && log.alert !== null ? (
															<span style={{ fontSize: '15px' }}>
																{userName(log.requestedUser.name)} Inserted Alert{' '}
																<NavLink
																	to={`/operations/alerts`}
																	style={{ color: 'blue' }}
																	onClick={() => {
																		dispatch('UPDATE_SEARCH_QUERY', log.alert.name);
																	}}>
																	{log.alert.name}
																</NavLink>
															</span>
														) : log.modelName === 'geofences' && log.geofence !== null ? (
															<span style={{ fontSize: '15px' }}>
																{userName(log.requestedUser.name)} Inserted Geofence {log.geofence.name}
															</span>
														) : log.modelName === 'documents' && log.document !== null ? (
															<span style={{ fontSize: '15px' }}>
																{userName(log.requestedUser.name)} Inserted Documents{' '}
																<NavLink
																	to={`/documents`}
																	style={{ color: 'blue' }}
																	onClick={() => {
																		dispatch('UPDATE_SEARCH_QUERY', log.document.type);
																	}}>
																	{log.document.type}
																</NavLink>
															</span>
														) : log.modelName === 'sims' && log.sim !== null ? (
															<span style={{ fontSize: '15px' }}>
																{userName(log.requestedUser.name)} Inserted Sim Number{' '}
																<NavLink
																	to={`/operations/sims`}
																	style={{ color: 'blue' }}
																	onClick={() => {
																		dispatch('UPDATE_SEARCH_QUERY', log.sim.mobileNumber);
																	}}>
																	{log.sim.mobileNumber}
																</NavLink>
															</span>
														) : log.modelName === 'devices' && log.device !== null ? (
															<span style={{ fontSize: '15px' }}>
																{userName(log.requestedUser.name)} Inserted Imei Number{' '}
																<NavLink
																	to={`/operations/devices`}
																	style={{ color: 'blue' }}
																	onClick={() => {
																		dispatch('UPDATE_SEARCH_QUERY', log.device.imei);
																	}}>
																	{log.device.imei}
																</NavLink>
															</span>
														) : log.modelName === 'devicemodels' && log.deviceModel !== null ? (
															<span style={{ fontSize: '15px' }}>
																{userName(log.requestedUser.name)} Inserted Manu Facturer{' '}
																<NavLink
																	to={`/operations/device-models`}
																	style={{ color: 'blue' }}
																	onClick={() => {
																		dispatch('UPDATE_SEARCH_QUERY', log.deviceModel.manufacturer);
																	}}>
																	{log.deviceModel.manufacturer}
																</NavLink>
																<br />
																{userName(log.requestedUser.name)} Inserted Device Model{' '}
																<NavLink
																	to={`/operations/device-models`}
																	style={{ color: 'blue' }}
																	onClick={() => {
																		dispatch('UPDATE_SEARCH_QUERY', log.deviceModel.model);
																	}}>
																	{log.deviceModel.model}
																</NavLink>
															</span>
														) : log.modelName === 'roles' && log.role !== null ? (
															<span style={{ fontSize: '15px' }}>
																{userName(log.requestedUser.name)} Inserted Roles{' '}
																<NavLink
																	to={`/ums`}
																	style={{ color: 'blue' }}
																	onClick={() => {
																		dispatch('UPDATE_SEARCH_QUERY', log.role.name);
																	}}>
																	{log.role.name}
																</NavLink>
															</span>
														) : log.modelName === 'device_commands' && log.deviceCommands !== null ? (
															<span style={{ fontSize: '15px' }}>
																{log.requestedUser !== null ? userName(log.requestedUser.name) : ''} Inserted Device
																Commands{' '}
																<NavLink
																	to={`/operations/devices`}
																	style={{ color: 'blue' }}
																	onClick={() => {
																		dispatch('UPDATE_SEARCH_QUERY', log.deviceCommands.imei);
																	}}>
																	{log.deviceCommands.imei}
																</NavLink>
															</span>
														) : (
															''
														)}
													</div>
												)}
											</span>
										</div>
									</div>
								);
							})}
						</Chrono>
					) : (
						<PlaceHolder />
					)}
				</ChronoContainer>
			)}
			<CategoryFooter>
				<FooterPagination
					handlePageChange={(e) => handlePageChange(e)}
					pageNumber={props.pageNumber}
					count={props.count}
					disabled={props.loading || !data.length}
					handleDataSizeLimitChange={handleDataSizeLimitChange}
				/>
			</CategoryFooter>

			{filterModalOpen && (
				<FilterModal
					open={filterModalOpen}
					activeFilters={activeFilters}
					userDropdownOptions={userDropdownOptions}
					orgDropdownOption={orgDropdownOption}
					vehicleDropdownOption={vehicleDropdownOption}
					onCancel={() => setFilterModalOpen(false)}
					handleOnApply={(filterValues) => {
						setActiveFilters(filterValues);
						setFilterModalOpen(false);
					}}
					onResetClick={() => {
						setActiveFilters({});
					}}
					onClose={() => setFilterModalOpen(false)}
				/>
			)}
		</CategoryContentMembrane>
	);
};

const mapStateToProps = (state) => ({
	sidebarExpanded: state.ui.sidebarExpanded,
	dataSizeLimit: state.ui.dataSizeLimit,
	count: state.activityLogs.count,
	pageNumber: state.activityLogs.pageNumber,
	activityLogs: state.activityLogs.activityLogs,
});
export default connect(mapStateToProps)(ActivityLogs);
