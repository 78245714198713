import styled from 'styled-components';
import { SIDEBAR_HEADER_HEIGHT } from '../../../../../data/constants/dimensions';

export const Container = styled.section`
	width: 100%;
	height: ${SIDEBAR_HEADER_HEIGHT};

	display: flex;
	align-items: center;

	overflow-x: hidden;

	span {
		font-size: 1.25em;
		font-weight: bold;

		white-space: nowrap;
	}
`;

export const ImageContainer = styled.section`
	width: 4em;
	min-width: 4em;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding: 1em;

	img {
		height: 100%;
	}
`;
