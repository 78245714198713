export default function sims(
	initialState = {
		sims: [],
		loading: true,
		bulkimportLoading: false,
		pageNumber: 1,
		count: 0,
		activeIndex: -1,
		activeSort: '',
		sortOrder: '',
		deleting: false,
		deleteModalOpen: false,
		adding: false,
		addModalOpen: false,
		updating: false,
		editMode: false,
		restoreModal: false,
		restoring: false,
		importModalOpen: false,
		filterModalOpen: false,
		changeHistoryModal: false,

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
	},
	action
) {
	switch (action.type) {
		case 'FEED_SIMS':
			return {
				...initialState,
				sims: action.payload,
				loading: false,
			};

		case 'SIMS_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'SIMS_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'SIMS_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'SIMS_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'SIMS_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'SIMS_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'SIMS_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'SIMS_DELETING':
			return {
				...initialState,
				deleting: true,
			};

		case 'SIMS_DELETED':
			return {
				...initialState,
				deleting: false,
			};

		case 'SIMS_DELETE_MODAL_OPEN':
			return {
				...initialState,
				deleteModalOpen: true,
			};

		case 'SIMS_DELETE_MODAL_CLOSE':
			return {
				...initialState,
				deleteModalOpen: false,
			};

		case 'SIMS_ADDING':
			return {
				...initialState,
				adding: true,
			};

		case 'SIMS_ADDED':
			return {
				...initialState,
				adding: false,
			};

		case 'SIMS_ADD_MODAL_OPEN':
			return {
				...initialState,
				addModalOpen: true,
			};

		case 'SIMS_ADD_MODAL_CLOSE':
			return {
				...initialState,
				addModalOpen: false,
			};

		case 'SIMS_UPDATING':
			return {
				...initialState,
				updating: true,
			};

		case 'SIMS_UPDATED':
			return {
				...initialState,
				updating: false,
			};

		case 'SIMS_EDIT_MODAL_OPEN':
			return {
				...initialState,
				editMode: true,
			};

		case 'SIMS_EDIT_MODAL_CLOSE':
			return {
				...initialState,
				editMode: false,
			};

		case 'SIMS_RESTORE_MODAL_OPEN':
			return {
				...initialState,
				restoreModal: true,
			};

		case 'SIMS_RESTORE_MODAL_CLOSE':
			return {
				...initialState,
				restoreModal: false,
			};

		case 'SIMS_RESTORING':
			return {
				...initialState,
				restoring: true,
			};

		case 'SIMS_RESTORED':
			return {
				...initialState,
				restoring: false,
			};

		case 'SIMS_CHANGE_HISTORY_MODAL_OPEN':
			return {
				...initialState,
				changeHistoryModal: true,
			};

		case 'SIMS_CHANGE_HISTORY_MODAL_CLOSE':
			return {
				...initialState,
				changeHistoryModal: false,
			};

		case 'SIMS_IMPORT_MODAL_OPEN':
			return {
				...initialState,
				importModalOpen: true,
			};

		case 'SIMS_IMPORT_MODAL_CLOSE':
			return {
				...initialState,
				importModalOpen: false,
			};

		case 'SIMS_FILTER_MODAL_OPEN':
			return {
				...initialState,
				filterModalOpen: true,
			};

		case 'SIMS_FILTER_MODAL_CLOSE':
			return {
				...initialState,
				filterModalOpen: false,
			};

		case 'SIMS_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'SIMS_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_SIMS_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_SIMS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_SIMS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'SIMS_UNMOUNT':
			return {
				...initialState,
				sims: [],
				activeSort: '',
				sortOrder: '',
				activeIndex: -1,
				pageNumber: 1,
			};
		case 'BULK_IMPORT_LOAD':
			return {
				...initialState,
				bulkimportLoading: action.payload,
			};
		default:
			return initialState;
	}
}
