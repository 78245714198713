export default function customersMetrics(
	initialState = {
		// Initial loading.
		loading: false,

		// List of all customers.
		customers: [],
		// Used for search.
		customersBackup: [],

		// Selected via customers list.
		selectedCustomers: new Set(),
		selectedCustomersMetrics: [],
		loadingMetricsData: false,

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
	},
	action
) {
	switch (action.type) {
		case 'CUSTOMERS_METRICS_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'CUSTOMERS_METRICS_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'CUSTOMERS_METRICS_FEED_CUSTOMERS_LIST':
			return {
				...initialState,
				customers: action.payload,
				customersBackup: action.payload,
				loading: false,
			};

		case 'CUSTOMERS_METRICS_SET_CUSTOMERS_LIST':
			return {
				...initialState,
				customers: action.payload,
			};

		case 'CUSTOMERS_METRICS_RESET_CUSTOMERS_LIST':
			return {
				...initialState,
				customers: action.payload,
				customersBackup: action.payload,
			};

		case 'CUSTOMERS_METRICS_SET_SELECTED_CUSTOMERS':
			return {
				...initialState,
				selectedCustomers: action.payload,
			};

		case 'CUSTOMERS_METRICS_CLEAR_SELECTED_CUSTOMERS':
			return {
				...initialState,
				selectedCustomers: new Set(),
			};

		case 'CUSTOMERS_METRICS_SET_SELECTED_CUSTOMERS_METRICS':
			return {
				...initialState,
				selectedCustomersMetrics: action.payload,
			};

		case 'CUSTOMERS_METRICS_CLEAR_SELECTED_CUSTOMERS_METRICS':
			return {
				...initialState,
				selectedCustomersMetrics: [],
			};

		case 'CUSTOMERS_METRICS_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'CUSTOMERS_METRICS_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};
		case 'FETCHING_CUSTOMER_METRICS_DATA':
			return {
				...initialState,
				loadingMetricsData: true,
			};

		case 'FETCHED_CUSTOMER_METRICS_DATA':
			return {
				...initialState,
				loadingMetricsData: false,
			};

		case 'SET_CUSTOMER_METRICS_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_CUSTOMER_METRICS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_CUSTOMER_METRICS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		default:
			return initialState;
	}
}
