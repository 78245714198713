import { EXPORT_API, SIMS_API } from '../configs/api';

import { SIMS_FILE_HEADER } from '../../data/constants/general';
import { SIM_STATUS } from '../../data/constants/statuses';
import { createSimDTO } from '../dto/sims';
import { createSimPlanDTO } from '../dto/simPlans';
import Logo from '../../ui/assets/images/logo.png';
import { dispatch, dispatchNoPayload } from './middleware';

import Store from '../../data/store/store';
import { handleError } from './messaging';
import { fetchSimPlans } from './simPlans';
import { exportToExcelWithLogo } from '../utilities/excel';

//Utility Package
import axios from 'axios';
import { jsPDF } from 'jspdf';
import fileDownload from 'js-file-download';
import { exportApi } from './services/apiRequest';

export function fetchSims(params = '') {
	return axios(SIMS_API + params, {
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json',
		},
	});
}

export function refreshSims(params = '', activePage = 1, resetOffset = false) {
	dispatchNoPayload('SIMS_LOADING');

	axios(
		SIMS_API +
			'?pagecount=' +
			getPageNumberFromStore().dataSizeLimit +
			'&pagenumber=' +
			(resetOffset ? 1 : activePage) +
			params,
		{
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
				'Content-Type': 'application/json',
			},
		}
	)
		.then((response) => {
			dispatch('FEED_SIMS', createSimDTO(response?.data?.data));
			dispatch('SIMS_SET_PAGE_COUNT', response?.data?.count || 0);
			dispatch('SIMS_SET_PAGE_NUMBER', activePage);
		})
		.catch((error) => {
			dispatchNoPayload('SIMS_LOADED');
			handleError(error);
		});
}

export function searchSims(query) {
	return `&search=${encodeURIComponent(query)
		.trim()
		.split('+')
		.map((searchTerm) => `${searchTerm}`)}`;
}

// Export
export function openExportModal() {
	dispatchNoPayload('SIMS_EXPORT_MODAL_OPEN');
}

export function closeExportModal() {
	dispatchNoPayload('SIMS_EXPORT_MODAL_CLOSE');
}

export function formatExcelExport(response) {
	return response.map((sim) => {
		return {
			'SIM Number': sim.simNumber,
			'Mobile Number': sim.mobileNumber,
			'Service Provider': sim.serviceProvider,
			'Plan Name': sim.simPlan.planName,
			Rental: sim.rental,
			APN: sim.apn,
			'Plan Detail': sim.planDetail,
			'Activation Date': sim.activationDate,
			Status:
				sim.status === SIM_STATUS.New
					? 'New'
					: sim.status === SIM_STATUS['No Data']
					? 'No Data'
					: sim.status === SIM_STATUS.Device
					? 'Device'
					: sim.status === SIM_STATUS.Terminated
					? 'Terminated'
					: '',
		};
	});
}

export function exportData(data, fileName, extension) {
	if (extension === 'excel') {
		exportToExcelWithLogo(formatExcelExport(data), `${!fileName ? 'Sims' : fileName}`, SIMS_FILE_HEADER);
	} else {
		convertToPdf(formatExcelExport(data), fileName);
	}

	closeExportModal();
}

export function convertToPdf(data, fileName) {
	const doc = new jsPDF({ orientation: 'l', unit: 'mm', format: 'a4' });

	let col = [
		'SIM Number',
		'Mobile Number',
		'Service Provider',
		'Plan Name',
		'Rental',
		'APN',
		'Plan Detail',
		'Activation Date',
		'Status',
	];
	let rows = [];

	data.forEach((sim) => {
		let temp = [];
		Object.keys(sim).forEach((key, index) => {
			if (col[index] === key) temp[index] = sim[key];
		});

		rows.push(temp);
	});

	let header = function (data) {
		doc.addImage(Logo, 'png', data.settings.margin.left, 10, 40, 14);
	};

	doc.autoTable(col, rows, {
		margin: { top: 25 },
		beforePageContent: header,
		theme: 'grid',
	});

	doc.save(`${!fileName ? 'Sims' : fileName}`);
}

export function exportAll(param) {
	// dispatchNoPayload('EXPORTING_SIMS_EXPORT_DATA');

	// axios
	// 	.get(EXPORT_API + '/sims' + param, {
	// 		responseType: 'arraybuffer',
	// 		headers: {
	// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		},
	// 	})
	exportApi(EXPORT_API + '/sims' + param)
		.then((response) => {
			// openExportModal();
			// dispatch('SET_SIMS_EXPORT_DATA', createSimDTO(response?.data?.data));
			// dispatchNoPayload('EXPORTED_SIMS_EXPORT_DATA');
			const contentDisposition = response.headers['content-disposition'];
			const matches = /filename="([^"]+)"/.exec(contentDisposition);

			if (matches) {
				const fileName = matches[1];
				fileDownload(response?.data, fileName);
			}
		})
		.catch((error) => {
			handleError(error);
			dispatchNoPayload('EXPORTED_SIMS_EXPORT_DATA');
		});
}

// Accordion
export function toggleAccordion(e, titleProps, current) {
	const { index } = titleProps;
	const { activeIndex } = {
		activeIndex: current,
	};
	const newIndex = activeIndex === index ? -1 : index;

	dispatchNoPayload('SIMS_EDIT_MODAL_CLOSE');
	dispatch('SIMS_TOGGLE_ACCORDION', newIndex);
}

export function openFilterModal() {
	dispatchNoPayload('SIMS_FILTER_MODAL_OPEN');
}

export function closeFilterModal() {
	dispatchNoPayload('SIMS_FILTER_MODAL_CLOSE');
}

// Dropdown
export function getSimPlansForDropdown(params = '') {
	return fetchSimPlans(params)
		.then((response) => {
			return createSimPlanDTO(response?.data?.data).map((plan, index) => {
				return {
					key: index,
					value: plan.id,
					text: plan.planName,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			return [];
		});
}

export function getServiceProvidersForDropdown() {
	return [
		{
			key: 0,
			value: 'Airtel',
			text: 'Airtel',
		},
		{
			key: 1,
			value: 'Vodafone',
			text: 'Vodafone',
		},
		{
			key: 2,
			value: 'Bsnl',
			text: 'Bsnl',
		},
	];
}

// export function getSimTypesForDropdown() {
//   return [
//     {
//       key: 0,
//       value: 'Micro',
//       text: 'Micro',
//     },
//     {
//       key: 1,
//       value: 'Mini',
//       text: 'Mini',
//     },
//     {
//       key: 3,
//       value: 'Standard',
//       text: 'Standard',
//     },
//     {
//       key: 4,
//       value: '3 in 1',
//       text: '3 in 1',
//     },
//   ];
// }

// Connect to Store
function getPageNumberFromStore() {
	return {
		pageNumber: Store.getState().sims.pageNumber,
		dataSizeLimit: Store.getState().ui.dataSizeLimit,
	};
}
