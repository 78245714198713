import React from 'react';

import CommonModal from '../../../molecules/modal/CommonModal';

import { SWAP_SIM_REASONS } from '../../../../../../data/constants/general';

import { Button, Dropdown, Form } from 'semantic-ui-react';

const SwapSimModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="small"
			header="Swap Sim"
			content={
				<Form>
					<Form.Group inline>
						<Form.Radio
							label="Sim 1"
							value="sim1"
							disabled={!props.sim1SwapId}
							checked={props.selectedSim === 'sim1'}
							onChange={props.onSelectSwapSim}
						/>
						<Form.Radio
							label="Sim 2"
							value="sim2"
							disabled={!props.sim2SwapId}
							checked={props.selectedSim === 'sim2'}
							onChange={props.onSelectSwapSim}
						/>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field>
							<label>Sim 1</label>
							<Dropdown
								placeholder="Choose ..."
								fluid
								search
								clearable
								disabled={!props.sim1SwapId || props.selectedSim !== 'sim1'}
								selection
								options={props.sims}
								onChange={props.sim1SwapChange}
							/>
						</Form.Field>
						<Form.Field>
							<label>Sim 2</label>
							<Dropdown
								placeholder="Choose ..."
								fluid
								search
								clearable
								disabled={!props.sim2SwapId || props.selectedSim !== 'sim2'}
								selection
								options={props.sims}
								onChange={props.sim2SwapChange}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Field required>
						<label>Reason</label>
						<Dropdown
							placeholder="Choose ..."
							fluid
							search
							clearable
							selection
							options={SWAP_SIM_REASONS}
							value={props.reason}
							onChange={props.reasonSelect}
						/>
					</Form.Field>
				</Form>
			}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button
						negative
						disabled={!props.reason || (props.sim1SwapId === null && props.sim2SwapId === null)}
						loading={props.swapButtonLoading}
						onClick={props.onSwapButtonClick}>
						Swap
					</Button>
				</>
			}
		/>
	);
};

export default SwapSimModal;
