export default function fuelLogs(
	initialState = {
		fuelLogs: [],
		loading: true,
		pageNumber: 1,
		count: 0,
		filterModalOpen: false,
		addModalOpen: false,
		adding: false,
		activeSort: '',
		sortOrder: '',
		calibrationEditModal: false,
		activeIndex: -1,
		updating: false,
		editMode: false,
		deleting: false,
		deleteModalOpen: false,
	},
	action
) {
	switch (action.type) {
		case 'FUEL_LOGS_LOADING':
			return {
				...initialState,
				loading: true,
			};
		case 'FEED_FUEL_LOGS':
			return {
				...initialState,
				fuelLogs: action.payload,
				loading: false,
			};

		case 'LOGS_FUEL_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'FUEL_LOGS_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'FUEL_LOGS_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'FUEL_LOGS_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'FUEL_LOGS_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'FUEL_LOGS_ADD_MODAL_OPEN':
			return {
				...initialState,
				addModalOpen: true,
			};
		case 'FUEL_LOGS_ADD_MODAL_CLOSE':
			return {
				...initialState,
				addModalOpen: false,
			};
		case 'FUEL_LOGS_UPDATING':
			return {
				...initialState,
				updating: true,
			};

		case 'FUEL_LOGS_UPDATED':
			return {
				...initialState,
				updating: false,
			};

		case 'FUEL_LOGS_EDIT_MODAL_OPEN':
			return {
				...initialState,
				editMode: true,
			};

		case 'FUEL_LOGS_EDIT_MODAL_CLOSE':
			return {
				...initialState,
				editMode: false,
			};
		case 'FUEL_LOGS_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};
		case 'FUEL_LOGS_ADDING':
			return {
				...initialState,
				adding: true,
			};
		case 'FUEL_LOGS_ADDED':
			return {
				...initialState,
				adding: false,
			};
		case 'FUEL_LOGS_DELETING':
			return {
				...initialState,
				deleting: true,
			};

		case 'FUEL_LOGS_DELETED':
			return {
				...initialState,
				deleting: false,
			};

		case 'FUEL_LOGS_DELETE_MODAL_OPEN':
			return {
				...initialState,
				deleteModalOpen: true,
			};

		case 'FUEL_LOGS_DELETE_MODAL_CLOSE':
			return {
				...initialState,
				deleteModalOpen: false,
			};
		case 'FUEL_LOGS_UNMOUNT':
			return {
				...initialState,
				fuelLogs: [],
				loading: true,
				pageNumber: 1,
				count: 0,
				activeSort: '',
				sortOrder: '',
				adding: false,
				addModalOpen: false,
				updating: false,
				deleting: false,
				deleteModalOpen: false,
				activeIndex: -1,
			};

		default:
			return initialState;
	}
}
