export default function saasPlans(
	initialState = {
		saasPlans: [],
		loading: true,
		pageNumber: 1,
		count: 0,
		activeIndex: -1,
		activeSort: '',
		sortOrder: '',
		anyRowSelected: false,
		selectedRows: new Set(),
		adding: false,
		addModalOpen: false,
		updating: false,
		editMode: false,
		deleting: false,
		deleteModalOpen: false,
		restoreModal: false,
		restoring: false,
		filterModalOpen: false,
		exportModal: false,
		exportingData: false,
		exportData: [],
	},
	action
) {
	switch (action.type) {
		case 'FEED_SAAS_PLANS':
			return {
				...initialState,
				saasPlans: action.payload,
				loading: false,
			};

		case 'SAAS_PLANS_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'SAAS_PLANS_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'SAAS_PLANS_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'SAAS_PLANS_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'SAAS_PLANS_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'SAAS_PLANS_DELETING':
			return {
				...initialState,
				deleting: true,
			};

		case 'SAAS_PLANS_DELETED':
			return {
				...initialState,
				deleting: false,
			};

		case 'SAAS_PLANS_DELETE_MODAL_OPEN':
			return {
				...initialState,
				deleteModalOpen: true,
			};

		case 'SAAS_PLANS_DELETE_MODAL_CLOSE':
			return {
				...initialState,
				deleteModalOpen: false,
			};

		case 'SAAS_PLANS_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'SAAS_PLANS_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'SAAS_PLANS_ROW_SELECTED':
			return {
				...initialState,
				anyRowSelected: true,
			};

		case 'SAAS_PLANS_NO_ROW_SELECTED':
			return {
				...initialState,
				anyRowSelected: false,
				selectedRows: new Set(),
			};

		case 'SAAS_PLANS_SET_SELECTED_ROWS':
			return {
				...initialState,
				selectedRows: action.payload,
			};

		case 'SAAS_PLANS_ADDING':
			return {
				...initialState,
				adding: true,
			};

		case 'SAAS_PLANS_ADDED':
			return {
				...initialState,
				adding: false,
			};

		case 'SAAS_PLANS_ADD_MODAL_OPEN':
			return {
				...initialState,
				addModalOpen: true,
			};

		case 'SAAS_PLANS_ADD_MODAL_CLOSE':
			return {
				...initialState,
				addModalOpen: false,
			};

		case 'SAAS_PLANS_UPDATING':
			return {
				...initialState,
				updating: true,
			};

		case 'SAAS_PLANS_UPDATED':
			return {
				...initialState,
				updating: false,
			};

		case 'SAAS_PLANS_EDIT_MODAL_OPEN':
			return {
				...initialState,
				editMode: true,
			};

		case 'SAAS_PLANS_EDIT_MODAL_CLOSE':
			return {
				...initialState,
				editMode: false,
			};

		case 'SAAS_PLANS_RESTORE_MODAL_OPEN':
			return {
				...initialState,
				restoreModal: true,
			};

		case 'SAAS_PLANS_RESTORE_MODAL_CLOSE':
			return {
				...initialState,
				restoreModal: false,
			};

		case 'SAAS_PLANS_RESTORING':
			return {
				...initialState,
				restoring: true,
			};

		case 'SAAS_PLANS_RESTORED':
			return {
				...initialState,
				restoring: false,
			};

		case 'SAAS_PLANS_FILTER_MODAL_OPEN':
			return {
				...initialState,
				filterModalOpen: true,
			};

		case 'SAAS_PLANS_FILTER_MODAL_CLOSE':
			return {
				...initialState,
				filterModalOpen: false,
			};

		case 'SAAS_PLANS_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				exportModal: true,
			};

		case 'SAAS_PLANS_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				exportModal: false,
			};

		case 'FETCHING_SAAS_PLANS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'FETCHED_SAAS_PLANS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'SET_SAAS_PLANS_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'CLEAR_SAAS_PLANS_EXPORT_DATA':
			return {
				...initialState,
				exportData: [],
			};

		case 'SAAS_PLANS_UNMOUNT':
			return {
				...initialState,
				saasPlans: [],
				activeSort: '',
				sortOrder: '',
				activeIndex: -1,
				anyRowSelected: false,
				selectedRows: new Set(),
				pageNumber: 1,
			};

		default:
			return initialState;
	}
}
