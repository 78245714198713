export default function logs(
	initialState = {
		logs: [],
		loading: true,
		pageNumber: 1,
		count: 0,
		deleteModalOpen: false,
		filterModalOpen: false,

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
		DeleteLogId: null,
	},
	action
) {
	switch (action.type) {
		case 'LOGS_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'FEED_LOGS':
			return {
				...initialState,
				logs: action.payload,
				loading: false,
			};

		case 'APPEND_LOGS':
			return {
				...initialState,
				logs: [...action.payload, ...initialState.logs],
				loading: false,
			};

		case 'LOGS_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'LOGS_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'LOGS_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'LOGS_ROW_SELECTED':
			return {
				...initialState,
				anyRowSelected: true,
			};

		case 'LOGS_NO_ROW_SELECTED':
			return {
				...initialState,
				anyRowSelected: false,
				selectedRows: new Set(),
			};

		case 'LOGS_SET_SELECTED_ROWS':
			return {
				...initialState,
				selectedRows: action.payload,
			};

		case 'LOGS_FILTER_MODAL_OPEN':
			return {
				...initialState,
				filterModalOpen: true,
			};

		case 'LOGS_FILTER_MODAL_CLOSE':
			return {
				...initialState,
				filterModalOpen: false,
			};

		case 'LOGS_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'LOGS_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_LOGS_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_LOGS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_LOGS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		// case 'LOGS_DELETE_MODAL_OPEN':
		// 	return {
		// 		...initialState,
		// 		deleteModalOpen: true,
		// 	};

		// case 'LOGS_DELETE_MODAL_CLOSE':
		// 	return {
		// 		...initialState,
		// 		deleteModalOpen: false,
		// 	};

		case 'LOGS_UNMOUNT':
			return {
				...initialState,
				logs: [],
				pageNumber: 1,
				deleteModalOpen: false,
				count: 0,
			};
		// case 'DELETE_LOGS':
		// 	return {
		// 		...initialState,
		// 		DeleteLogId: action.payload,
		// 	};
		default:
			return initialState;
	}
}
