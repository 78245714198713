export default function deviceCommands(
	initialState = {
		deviceCommands: [],
		loading: true,
		pageNumber: 1,
		count: 0,
		activeIndex: -1,
		deleting: false,
		deleteModalOpen: false,
		activeSort: '',
		sortOrder: '',
		adding: false,
		addModalOpen: false,
		updating: false,
		editMode: false,
		restoreModal: false,
		restoring: false,

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
	},
	action
) {
	switch (action.type) {
		case 'FEED_DEVICE_COMMANDS':
			return {
				...initialState,
				deviceCommands: action.payload,
				loading: false,
			};

		case 'DEVICE_COMMANDS_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'DEVICE_COMMANDS_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'DEVICE_COMMANDS_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'DEVICE_COMMANDS_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'DEVICE_COMMANDS_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'DEVICE_COMMANDS_DELETING':
			return {
				...initialState,
				deleting: true,
			};

		case 'DEVICE_COMMANDS_DELETED':
			return {
				...initialState,
				deleting: false,
			};

		case 'DEVICE_COMMANDS_DELETE_MODAL_OPEN':
			return {
				...initialState,
				deleteModalOpen: true,
			};

		case 'DEVICE_COMMANDS_DELETE_MODAL_CLOSE':
			return {
				...initialState,
				deleteModalOpen: false,
			};

		case 'DEVICE_COMMANDS_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'DEVICE_COMMANDS_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'DEVICE_COMMANDS_ADDING':
			return {
				...initialState,
				adding: true,
			};

		case 'DEVICE_COMMANDS_ADDED':
			return {
				...initialState,
				adding: false,
			};

		case 'DEVICE_COMMANDS_ADD_MODAL_OPEN':
			return {
				...initialState,
				addModalOpen: true,
			};

		case 'DEVICE_COMMANDS_ADD_MODAL_CLOSE':
			return {
				...initialState,
				addModalOpen: false,
			};

		case 'DEVICE_COMMANDS_UPDATING':
			return {
				...initialState,
				updating: true,
			};

		case 'DEVICE_COMMANDS_UPDATED':
			return {
				...initialState,
				updating: false,
			};

		case 'DEVICE_COMMANDS_EDIT_MODAL_OPEN':
			return {
				...initialState,
				editMode: true,
			};

		case 'DEVICE_COMMANDS_EDIT_MODAL_CLOSE':
			return {
				...initialState,
				editMode: false,
			};

		case 'DEVICE_COMMANDS_RESTORE_MODAL_OPEN':
			return {
				...initialState,
				restoreModal: true,
			};

		case 'DEVICE_COMMANDS_RESTORE_MODAL_CLOSE':
			return {
				...initialState,
				restoreModal: false,
			};

		case 'DEVICE_COMMANDS_RESTORING':
			return {
				...initialState,
				restoring: true,
			};

		case 'DEVICE_COMMANDS_RESTORED':
			return {
				...initialState,
				restoring: false,
			};

		case 'DEVICE_COMMANDS_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'DEVICE_COMMANDS_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_DEVICE_COMMANDS_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_DEVICE_COMMANDS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_DEVICE_COMMANDS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'DEVICE_COMMANDS_UNMOUNT':
			return {
				...initialState,
				deviceCommands: [],
				activeSort: '',
				sortOrder: '',
				activeIndex: -1,
				pageNumber: 1,
			};

		default:
			return initialState;
	}
}
