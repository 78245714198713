import styled from 'styled-components';
import { LIST_WIDTH } from '../../../../data/constants/dimensions';

export const List = styled.section`
	width: ${LIST_WIDTH};
	min-width: ${LIST_WIDTH};
	height: 100%;

	display: flex;
	flex-direction: column;

	box-sizing: border-box;
	padding: 0.5em;

	border-left: 1px solid #ddd;
`;
