import styled from 'styled-components';

export const Container = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;

	.ui.menu {
		border: none;
		border-radius: 5px;
		box-shadow:
			rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
			rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
	}

	.ui.pagination.menu {
		border: none;
		border-radius: 5px;
		box-shadow:
			rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
			rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
	}

	// .ui.pagination.menu .active.item {
	// 	color: ${(props) => props.theme.paginationActiveColor};
	// 	background: ${(props) => props.theme.theme};
	// }
`;
