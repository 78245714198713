import React, { useEffect, useState } from 'react';
import CalendarImage from '../../../assets/images/calendar.png';
import Store from '../../../../data/store/store';
import { connect } from 'react-redux';
import {
	CalendarBlock,
	Container,
	DayBlock,
	PickerContainer,
	PickerControls,
	PickerPane,
	PickerView,
	PlaceholderView,
	ShortcutsBlock,
	ShortcutsPane,
} from './CalendarMonthStyles';
import { Button, Modal } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';

function CalendarMonth(props) {
	const [initialDate, setInitialDate] = useState(moment().subtract(5, 'months').startOf('month').toISOString());
	const [finalDate, setFinalDate] = useState(moment().endOf('month').toISOString());

	const handleChange = (event, { name, value }) => {
		if (name === 'date1') {
			setInitialDate(moment(value).toISOString());
		}

		if (name === 'date2') {
			setFinalDate(moment(value).toISOString());
		}
	};

	const submitDates = () => {
		Store.dispatch({
			type: 'CALENDAR_MONTH_SET_DATES',
			payload: {
				initialDate: initialDate,
				finalDate: finalDate,
			},
		});

		Store.dispatch({
			type: 'CALENDAR_MONTH_HIDE_CALENDAR',
		});
	};

	const handleShortcut = (newInitialDate, newFinalDate) => {
		setInitialDate(newInitialDate);
		setFinalDate(newFinalDate);

		setTimeout(() => {
			submitDates();
		}, 100);
	};

	const resetCalendar = () => {
		Store.dispatch({ type: 'CALENDAR_MONTH_HIDE_CALENDAR' });

		setInitialDate(props.initialDate);
		setFinalDate(props.finalDate);
	};

	useEffect(() => {
		return () => {
			Store.dispatch({ type: 'CALENDAR_MONTH_RESET_DATES' });
		};
	}, []);

	return (
		<Container>
			<PlaceholderView
				disabled={props.disabled || false}
				onClick={() => Store.dispatch({ type: 'CALENDAR_MONTH_SHOW_CALENDAR' })}>
				<DayBlock>{moment(props.initialDate).format('MMM YYYY')}</DayBlock>
				<img src={CalendarImage} alt="Calendar" />
				<DayBlock>{moment(props.finalDate).format('MMM YYYY')}</DayBlock>
			</PlaceholderView>
			<Modal className="CalendarModal" open={props.calendarVisible} onClose={resetCalendar}>
				<Modal.Content>
					<PickerContainer>
						<CalendarBlock>
							<PickerView>
								<PickerPane>
									<DateInput
										name="date1"
										inline
										value={initialDate}
										dateFormat="YYYY-MM-DDTHH:mm:ss.sssZ"
										maxDate={finalDate}
										onChange={handleChange}
										marked={initialDate}
										markColor="red"
									/>
								</PickerPane>
								<PickerPane>
									<DateInput
										name="date2"
										inline
										value={finalDate}
										dateFormat="YYYY-MM-DDTHH:mm:ss.sssZ"
										minDate={initialDate}
										maxDate={moment().endOf('month')}
										onChange={handleChange}
										marked={finalDate}
										markColor="red"
									/>
								</PickerPane>
							</PickerView>
							<PickerControls>
								<Button primary onClick={submitDates}>
									Apply
								</Button>
							</PickerControls>
						</CalendarBlock>
						<ShortcutsBlock>
							<ShortcutsPane>
								<h4>Calendar</h4>
								<Button
									primary
									onClick={() => {
										handleShortcut(moment().startOf('year').toISOString(), moment().endOf('month').toISOString());
									}}>
									Current Year
								</Button>
								<Button
									primary
									onClick={() => {
										handleShortcut(
											moment().subtract(1, 'year').startOf('year').toISOString(),
											moment().subtract(1, 'year').endOf('year').toISOString()
										);
									}}>
									Last Year
								</Button>
								{/* Other shortcut buttons */}
							</ShortcutsPane>
						</ShortcutsBlock>
					</PickerContainer>
				</Modal.Content>
			</Modal>
		</Container>
	);
}

// Connect to Store
const mapStateToProps = (state) => ({
	initialDate: state.calendarMonth.initialDate,
	finalDate: state.calendarMonth.finalDate,
	calendarVisible: state.calendarMonth.calendarVisible,
});

export default connect(mapStateToProps)(CalendarMonth);
