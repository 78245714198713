// CategorySidebar Width
export const SIDEBAR_EXPANDED_WIDTH = '20em';
export const SIDEBAR_COLLAPSED_WIDTH = '4em';
export const SIDEBAR_HEADER_HEIGHT = '4em';
export const SIDEBAR_FOOTER_HEIGHT = '4em';
export const SIDEBAR_LIST_ITEMS_HEIGHT = '3em';

// Nav Bar
export const NAV_BAR_WIDTH = '4em';
export const NAV_BAR_LOGO_HEIGHT = '4em';
export const NAV_BAR_LIST_ITEMS_HEIGHT = '3em';
export const NAV_BAR_SETTINGS_MENU_HEIGHT = '4em';

// CategoryHeader
export const HEADER_HEIGHT = '4em';

// CategoryFooter
export const FOOTER_HEIGHT = '4em';

// CategoryMain CategoryHeader
export const CONTENT_HEADER_HEIGHT = '3em';

// List
export const LIST_WIDTH = '20em';

// LazyLoad
export const LAZY_LOAD_OFFSET = 1024;

// Graph Table View Footer
export const GRAPH_TABLE_VIEW_FOOTER_HEIGHT = '4em';

// SimBills
export const SIM_BILLS_OVERVIEW_GRAPH_HEIGHT = '12em';
