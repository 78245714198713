import { ResponseStatusCode, StatusCode } from '../../data/constants/status-code';
import { logout } from './auth';
import { MessageType } from '../../data/constants/message-type';
import { toast } from 'react-toastify';

export function showMessage(content, type) {
	switch (type) {
		case MessageType.Info:
			toast.info(content, { position: 'top-right', theme: 'dark' });
			break;

		case MessageType.Error:
			toast.error(content, { position: 'top-right', theme: 'dark' });
			break;

		case MessageType.Warn:
			toast.warn(content, { position: 'top-right', theme: 'dark' });
			break;

		case MessageType.Success:
			toast.success(content, { position: 'top-right', theme: 'dark' });
			break;

		default:
			toast(content, { position: 'top-right', theme: 'dark' });
			break;
	}
}

// export function handleError(error, peopleServer = false) {
//   if (error?.response?.status) {
//     if (error.response.status === 401) {
//       peopleServer ? peopleLogout() : logout();
//       if (error?.response?.data?.message) {
//         showMessage(JSON.stringify(error.response.data.message), 'ERROR');
//       } else {
//         showMessage('Session expired !!', 'ERROR');
//       }
//     } else if (error?.response?.data?.message) {
//       showMessage(JSON.stringify(error.response.data.message), 'ERROR');
//     } else {
//       showMessage('Something went wrong !!', 'ERROR');
//     }
//   } else {
//     showMessage('Something went wrong !!', 'ERROR');
//   }
// }

export function handleError(error) {
	const status = error?.response?.status;
	const message =
		error?.response?.data?.message || error?.response?.data?.error?.message
			? JSON.stringify(error.response.data?.message || error.response.data?.error?.message)
			: null;

	if (status) {
		if (status === StatusCode.UnAuthorized) {
			if (message) {
				showMessage(message, MessageType.Error);
			} else {
				showMessage('Unauthorized - Requested resource requires valid Credentials.', MessageType.Error);
				// showMessage('Unauthorized - Requested resource requires valid Credentials.', MessageType.Error);
			}
			if (localStorage.getItem('token')) logout();
		} else if (message) {
			showMessage(message, MessageType.Error);
		} else {
			showMessage('Requested Resource not available.', MessageType.Error);
		}
	} else {
		if (process.env.REACT_APP_BUILD_ENVIRONMENT === 'development') showMessage('Network Error', MessageType.Error);
	}
}

export function handleWarning(message) {
	showMessage(JSON.stringify(message), 'WARNING');
}

export function handleSuccess(response) {
	if (response?.data?.message) {
		showMessage(JSON.stringify(response?.data?.message), 'SUCCESS');
	}
}

export function generateSuccessMessage(response, msg) {
	showMessage(JSON.stringify(msg), 'SUCCESS');
	if (response?.status && !msg) {
		showMessage(
			JSON.stringify(
				response?.status === ResponseStatusCode.Created
					? 'Created'
					: response?.status === ResponseStatusCode.Success
					? 'Success'
					: ''
			),
			'SUCCESS'
		);
	}
}

export function generateErrorMessage(message) {
	showMessage(JSON.stringify(message), 'ERROR');
}
