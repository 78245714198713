import { isoToDate } from '../utilities/date';

export function createOwnerHistoryDTO(data) {
	let ownerHistory = [];

	if (data && data.length) {
		data.forEach((history) => {
			ownerHistory.push({
				startDate: history?.ownershipStartDate ? isoToDate(history.ownershipStartDate) : null,
				endDate: history?.ownershipEndDate ? isoToDate(history.ownershipEndDate) : null,
				organisation: history?.organisation?.name ? history.organisation.name : null,
			});
		});
	}

	return ownerHistory;
}
