import { isoToDate } from '../utilities/date';

export function createSimDTO(data) {
	if (data && data.length !== 0) {
		return data.map((sim) => {
			return {
				id: sim?.mobileNumber ? sim.mobileNumber : null,
				simNumber: sim?.simNumber ? sim.simNumber : null,
				mobileNumber: sim?.mobileNumber ? sim.mobileNumber : null,
				serviceProvider: sim?.serviceProvider ? sim.serviceProvider : null,
				simPlan: {
					id: sim?.simPlan?.id ? sim.simPlan.id : null,
					planName: sim?.simPlan?.planName ? sim.simPlan.planName : null,
				},
				deviceImei: sim?.devices[0]?.imei ? sim?.devices[0].imei : null,
				rental: sim?.simPlan?.rental ? sim.simPlan.rental : null,
				apn: sim?.simPlan?.apn ? sim.simPlan.apn : null,
				planDetail: sim?.simPlan?.planDetail ? sim.simPlan.planDetail : null,
				activationDate: sim?.activationDate ? isoToDate(sim.activationDate) : null,
				status: sim?.status ? sim.status : null,
				deletedDate: sim?.deletedDate ? isoToDate(sim.deletedDate) : null,
				note: sim?.note ? sim.note : null,
			};
		});
	} else return [];
}
