import React from 'react';

import { ButtonContainer, DetailsHeader, ExcelDataRow, HeaderElement, Row, RowElement } from './ImportStyles';

import * as XLSX from 'xlsx';
import stringSimilarity from 'string-similarity';

import { Button, Dropdown, Icon, Label, Modal } from 'semantic-ui-react';
import { getData } from '../../../logic/middleware/services/apiRequest';
import { ORGANISATIONS_API, ROLES_API } from '../../../logic/configs/api';
import { handleError } from '../../../logic/middleware/messaging';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { dispatch } from '../../../logic/middleware/middleware';

class UserImport extends React.Component {
	fileTemplate = [];
	headers;
	rowData;
	formattedData = [];
	allData = [];
	finalData = {};

	rolesDropdownData = [];
	orgDropdownData = [];

	userDropdownData = [];

	isSuperAdminAccess = localStorage.getItem('isSuperAdmin');

	constructor(props) {
		super(props);

		this.errorFocusRefs = [];

		this.state = {
			loading: false,
			// submitting: false,

			templateModalOpen: false,
			excelDataModalOpen: false,
			selectedHeaders: [],
			showError: false,
			emailUpdate: '',

			pingState: true,
			mapping: false,
		};

		this.closeTemplateModal = this.closeTemplateModal.bind(this);
		this.getDropdownOptions = this.getDropdownOptions.bind(this);
		this.closeExcelDataModal = this.closeExcelDataModal.bind(this);
		this.pingState = this.pingState.bind(this);
		this.sendMessages = this.sendMessages.bind(this);
		this.addRow = this.addRow.bind(this);
		this.removeRow = this.removeRow.bind(this);
		this.error = this.error.bind(this);
		this.success = this.success.bind(this);
		this.isValidPhone = this.isValidPhone.bind(this);
		this.isValidOrganisation = this.isValidOrganisation.bind(this);
		this.isValidRole = this.isValidRole.bind(this);
		this.isValidType = this.isValidType.bind(this);
	}

	parseFile(file) {
		this.setState({
			loading: true,
		});

		const fileReader = new FileReader();
		const readAsBinaryString = !!fileReader.readAsBinaryString;

		fileReader.onload = (e) => {
			const binaryString = e.target.result;
			const workbook = XLSX.read(binaryString, {
				type: readAsBinaryString ? 'binary' : 'array',
				// cellDates: true,
			});

			const worksheetName = workbook.SheetNames[0];
			const workSheet = workbook.Sheets[worksheetName];

			const data = XLSX.utils.sheet_to_json(workSheet, {
				header: 1,
				blankrows: false,
				defval: '',
				raw: false,
			});

			this.mapData(data);
		};

		if (readAsBinaryString) fileReader.readAsBinaryString(file);
		else fileReader.readAsArrayBuffer(file);
	}

	componentDidMount() {
		if (this.props.template) this.fileTemplate = this.props.template;
		this.parseFile(this.props.file);
		getData(ROLES_API, '?dropdown=true')
			.then((response) => {
				this.rolesDropdownData = response?.data?.data;
			})
			.catch((err) => handleError(err));

		getData(ORGANISATIONS_API, '?dropdown=true')
			.then((response) => {
				this.orgDropdownData = response?.data?.data;
			})
			.catch((err) => handleError(err));
	}

	componentWillUnmount() {
		this.closeExcelDataModal();
		this.closeTemplateModal();
		this.cleanAllDataVariable();
		this.props.closeImport();
		this.setState({
			showError: false,
			emailUpdate: '',
		});
		this.rolesDropdownData = [];
		this.orgDropdownData = [];
	}

	mapData(data) {
		let headers = data[0];
		let rowData = data.slice(1);

		this.headers = headers;
		this.rowData = rowData;
		this.setState({
			loading: false,
		});

		this.smartFill();
		this.openTemplateModal();
	}

	smartFill() {
		let selectedHeaders = [];
		this.fileTemplate.forEach((template, index) => {
			let targetIndex = 0;
			let maxWeight = 0;
			this.headers &&
				this.headers.forEach((header, localIndex) => {
					let weight = stringSimilarity.compareTwoStrings(template.name, header ? header.toString() : '');
					if (weight > maxWeight) {
						maxWeight = weight;
						targetIndex = localIndex;
					}
				});

			if (maxWeight > 0.5) {
				selectedHeaders = Object.assign([], selectedHeaders, {
					[index]: this.headers[targetIndex],
				});
			}
		});

		this.setState({ selectedHeaders });
	}

	openTemplateModal() {
		this.setState({
			templateModalOpen: true,
		});
	}

	closeTemplateModal() {
		this.setState({
			templateModalOpen: false,
		});
		this.props.closeImport();
	}

	getDropdownOptions(origin) {
		const options = [];
		this.headers &&
			this.headers.forEach((value, index) => {
				if (value === this.state.selectedHeaders[origin] || this.state.selectedHeaders.indexOf(value) === -1) {
					options.push({ key: index, value: value, text: value });
				}
			});

		return options;
	}

	formatData() {
		this.rowData.forEach((row) => {
			let data = [];

			this.fileTemplate.forEach((column, index) => {
				data.push(row[this.headers.indexOf(this.state.selectedHeaders[index])]);
			});

			this.formattedData.push(data);
		});
		this.allData = this.formattedData;
	}

	openExcelDataModal() {
		this.setState({
			templateModalOpen: false,
			excelDataModalOpen: true,
			mapping: false,
		});
	}

	closeExcelDataModal() {
		this.setState({
			excelDataModalOpen: false,
		});
		this.formattedData = [];
		this.props.closeImport();
	}

	isEmpty(value) {
		return value === '' || value === undefined;
	}

	pingState() {
		this.setState({
			pingState: !this.state.pingState,
		});
	}

	async convertToJson() {
		try {
			this.finalData = this.allData.map((value) => {
				let data = {};
				this.fileTemplate.forEach((template, localIndex) => {
					if (value[localIndex]) {
						Object.assign(data, {
							[template.requestTitle]: value[localIndex].toString(),
						});
					}
				});
				return data;
			});

			let modifiedFinalData = [];
			this.allData.map((eachRow) => {
				let orgId = this.orgDropdownData.filter((obj) => {
					return eachRow[4] === obj.name;
				});
				let roleId = this.rolesDropdownData.filter((obj) => eachRow[5] === obj.name);

				if (orgId[0]?.id && roleId[0]?.id) {
					modifiedFinalData.push({
						...eachRow,
						organisation: { id: orgId[0]?.id },
						role: { id: roleId[0]?.id },
					});
				}
			});

			this.props.upload({
				data: modifiedFinalData,
				success: this.success,
				error: this.error,
			});
		} catch (error) {
			handleError(error);
		}
	}

	success() {
		if (this.props.usersResponse?.failedObjects.length === 0) {
			this.cleanAllDataVariable();
			this.closeExcelDataModal();
		}
		// this.setState({
		// 	submitting: false,
		// });
	}

	error() {
		// this.setState({
		// 	submitting: false,
		// });
	}

	sendMessages() {
		dispatch('BULK_IMPORT_LOAD', true);
		// this.setState({
		// 	submitting: true,
		// });
		this.convertToJson();
	}

	enterManually() {
		this.allData.push(new Array(this.fileTemplate.length).fill(''));
		this.pingState();
	}

	addRow() {
		this.allData.push(new Array(this.fileTemplate.length).fill(''));
		this.pingState();
	}

	removeRow(index) {
		this.allData.splice(index, 1);
		this.pingState();
	}

	cleanAllDataVariable() {
		this.rowData = [];
		this.headers = [];
		this.finalData = {};
		this.formattedData = [];
		this.allData = [];
		this.setState({
			selectedHeaders: [],
		});
	}

	cleanMappedDataVariable() {
		this.rowData = [];
		this.headers = [];
		this.setState({
			selectedHeaders: [],
		});
	}

	isValidEmail(email) {
		const removeSpaceEmail = email.replaceAll(/\s/g, '');
		const emailRegex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return emailRegex.test(String(removeSpaceEmail).toLowerCase());
	}

	isValidPhone(number) {
		const removeSpace = number.replaceAll(/\s/g, '');
		return removeSpace.toString().length === 10 && /^-?\d+$/.test(removeSpace);
	}

	isValidOrganisation(orgName) {
		if (this.isSuperAdminAccess === 'false') {
			const localStorageOrg = localStorage.getItem('organisationName');
			return localStorageOrg === orgName;
		} else {
			const verifiedOrg = this.orgDropdownData.filter((obj) => orgName === obj.name);
			return verifiedOrg?.length > 0;
		}
	}

	isValidRole(roleName) {
		const verifiedRoleName = this.rolesDropdownData.filter((obj) => roleName === obj.name);
		return verifiedRoleName[0]?.name;
	}

	isValidType(userType) {
		return ['default', 'parent', 'driver'].includes(userType.toLowerCase());
	}

	isValidUserName(userName) {
		return userName !== '';
	}

	hasInvalidData() {
		let errorIdx;
		const invalidData = this.allData.filter((row, idx) => {
			if (
				(errorIdx === undefined && !this.isValidEmail(row[3])) ||
				!this.isValidPhone(row[2]) ||
				!this.isValidOrganisation(row[4]) ||
				!this.isValidRole(row[5]) ||
				!this.isValidType(row[1]) ||
				!this.isValidUserName(row[0])
			) {
				errorIdx = idx;
			}
			return (
				!this.isValidEmail(row[3]) ||
				!this.isValidPhone(row[2]) ||
				!this.isValidRole(row[5]) ||
				!this.isValidOrganisation(row[4]) ||
				!this.isValidType(row[1]) ||
				!this.isValidUserName(row[0])
			);
		});

		if (invalidData.length > 0) {
			const firstInvalidInputRef = this.errorFocusRefs[(errorIdx + 4) * 4];
			firstInvalidInputRef?.current?.focus({ preventScroll: false });
		}
		return invalidData.length > 0;
	}

	hasInvalidEmail() {
		const invalidEmail = this.allData.filter((row) => !this.isValidEmail(row[3]));
		return invalidEmail ? invalidEmail[0]?.length > 0 : false;
	}

	hasInvalidPhone() {
		const invalidPhone = this.allData.filter((row) => !this.isValidPhone(row[2]));
		return invalidPhone ? invalidPhone[0]?.length > 0 : false;
	}

	hasInvalidRole() {
		const isValidRole = this.allData.filter((row) => !this.isValidRole(row[5]));
		return isValidRole ? isValidRole[0]?.length > 0 : false;
	}

	hasInvalidType() {
		const isValidType = this.allData.filter((row) => !this.isValidType(row[1]));
		return isValidType ? isValidType[0]?.length > 0 : false;
	}

	hasInvalidOrg() {
		const isValidOrg = this.allData.filter((row) => !this.isValidOrganisation(row[4]));
		return isValidOrg ? isValidOrg[0]?.length > 0 : false;
	}

	hasInvalidName() {
		const isValidUserName = this.allData.filter((row) => !this.isValidUserName(row[0]));
		return isValidUserName ? isValidUserName[0]?.length > 0 : false;
	}

	render() {
		return (
			<>
				{this.state.templateModalOpen && (
					<Modal
						onClose={() => {
							this.closeTemplateModal();
							this.cleanMappedDataVariable();
						}}
						open={this.state.templateModalOpen}>
						<Modal.Header>
							Map Data
							{/*<span style={{ fontSize: '1rem', color: 'red', float: 'right' }}>*/}
							{/*	Fields marked with red are mandatory*/}
							{/*</span>*/}
						</Modal.Header>
						<Modal.Content>
							{this.fileTemplate.map((name, index) => {
								return (
									<Row key={index}>
										{/*{isRequired && <span style={{ color: 'red', marginLeft: '-1em' }}>*</span>}*/}
										<div style={{ color: 'red' }}>*</div>
										<RowElement style={{ color: this.state.selectedHeaders[index] !== name.name ? 'red' : 'black' }}>
											{name.name}
										</RowElement>
										<RowElement>
											<Dropdown
												error={this.state.selectedHeaders[index] !== name.name}
												placeholder="Choose..."
												fluid
												selection
												clearable
												options={this.getDropdownOptions(index)}
												value={this.state.selectedHeaders[index]}
												onChange={(e, { value }) => {
													this.setState({
														selectedHeaders: Object.assign([], this.state.selectedHeaders, { [index]: value }),
													});
												}}
											/>
											{this.state.selectedHeaders[index] !== name.name && this.state.selectedHeaders[index] !== '' && (
												<Label basic color="red" pointing>
													Please enter correct header
												</Label>
											)}

											{this.state.selectedHeaders[index] == '' && (
												<Label basic color="red" pointing>
													Required field cannot be empty
												</Label>
											)}
										</RowElement>
									</Row>
								);
							})}
						</Modal.Content>
						<Modal.Actions>
							<Button
								secondary
								color="black"
								onClick={() => {
									this.closeTemplateModal();
									this.cleanMappedDataVariable();
								}}>
								Cancel
							</Button>
							<Button
								loading={this.state.mapping}
								disabled={
									!this.state.selectedHeaders.includes('User Name') ||
									!this.state.selectedHeaders.includes('User Type') ||
									!this.state.selectedHeaders.includes('User Email') ||
									!this.state.selectedHeaders.includes('User Role') ||
									!this.state.selectedHeaders.includes('User Contact Number') ||
									!this.state.selectedHeaders.includes('Organisation')
								}
								onClick={() => {
									this.setState({ mapping: true });
									setTimeout(() => {
										this.formatData();
										this.openExcelDataModal();
									}, 1);
								}}
								positive>
								Map
							</Button>
						</Modal.Actions>
					</Modal>
				)}

				{this.state.excelDataModalOpen && (
					<Modal
						onClose={() => {
							this.closeExcelDataModal();
							this.cleanAllDataVariable();
						}}
						open={this.state.excelDataModalOpen}>
						<Modal.Header>Delivery Details</Modal.Header>
						<DetailsHeader>
							<HeaderElement width="5%">Serial</HeaderElement>
							{this.fileTemplate.map((name, index) => {
								const isRequired = [
									'User Name',
									'User Type',
									'User email',
									'User Contact Number',
									'Organisation',
									'User Role',
								].includes(name.name);
								return (
									<HeaderElement width={`${(90 / this.fileTemplate.length).toString()}%`} key={index}>
										{isRequired && <span style={{ color: 'red' }}>*</span>}
										{name.name}
									</HeaderElement>
								);
							})}
							<ButtonContainer />
						</DetailsHeader>
						{/*Padding*/}
						<div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
							{this.hasInvalidPhone() ? <div style={{ color: 'red' }}>Please fill correct phone number</div> : ''}
							{this.hasInvalidEmail() ? <div style={{ color: 'red' }}>Please fill correct Email</div> : ''}
							{this.hasInvalidRole() ? <div style={{ color: 'red' }}>Please fill correct role name</div> : ''}
							{this.hasInvalidType() ? <div style={{ color: 'red' }}>Please fill correct Type</div> : ''}
							{this.hasInvalidOrg() ? <div style={{ color: 'red' }}>Please fill correct org name</div> : ''}
							{this.hasInvalidName() ? <div style={{ color: 'red' }}>Name cannot be empty</div> : ''}
						</div>

						<Modal.Content scrolling>
							<Modal.Description>
								{this.allData.map((row, index) => {
									return (
										<div key={index}>
											<ExcelDataRow>
												<>
													<label style={{ width: '5%', textAlign: 'center' }}>{index + 1}</label>
													{row.map((data, localIndex) => {
														const email = row[3];
														const phoneNumber = row[2];
														const organisationName = row[4];
														const roleName = row[5];
														const userType = row[1];
														const userName = row[0];
														const inputRef = React.createRef();
														const inputField = (
															<input
																key={`${index},${localIndex},${data}`}
																defaultValue={data}
																ref={inputRef}
																style={{
																	width: `${(90 / this.fileTemplate.length).toString()}%`,
																	border:
																		!this.isValidEmail(email) ||
																		!this.isValidPhone(phoneNumber) ||
																		!this.isValidOrganisation(organisationName) ||
																		!this.isValidRole(roleName) ||
																		!this.isValidType(userType) ||
																		!this.isValidUserName(userName)
																			? `2px solid red`
																			: '',
																}}
																onChange={(e) => this.setState({ emailUpdate: e.target.value })}
																onBlur={(e) => {
																	this.allData[index][localIndex] = e.target.value;
																	this.setState({ emailUpdate: e.target.value });
																}}
															/>
														);
														this.errorFocusRefs.push(inputRef);
														return inputField;
													})}
													<ButtonContainer>
														<Button
															icon
															size="small"
															style={{ background: 'none' }}
															onClick={() => {
																this.removeRow(index);
															}}>
															<Icon name="close" />
														</Button>
													</ButtonContainer>
												</>
											</ExcelDataRow>
										</div>
									);
								})}
							</Modal.Description>
						</Modal.Content>

						<Modal.Actions
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}>
							<Button onClick={this.addRow}>Add Row</Button>
							<div style={{ display: 'flex', alignItems: 'center', gap: '.3em', fontWeight: 'bold' }}>
								{this.props.usersResponse?.failedObjects.length > 0 ? (
									<div style={{ color: 'red' }}>{`${this.props.usersResponse?.failedCount} Failed count`}</div>
								) : (
									''
								)}
								{this.props.usersResponse?.failedObjects.length > 0 ? (
									<CSVLink
										data={this.props.usersResponse?.failedObjects.map((obj) => {
											const modifiedObj = {};
											for (let key in obj) {
												if (Object.prototype.hasOwnProperty.call(obj, key)) {
													let value = obj[key];
													if (typeof value === 'object' && value !== null) {
														value = JSON.stringify(value);
													}
													const modifiedKey = key.charAt(0).toUpperCase() + key.slice(1);
													if (key === 'organisation') {
														const parsedValue = JSON.parse(value);
														value = parsedValue.organisation;
													}
													if (key === 'role') {
														const parsedValue = JSON.parse(value);
														value = parsedValue.role;
													}
													modifiedObj[modifiedKey] = value;
												}
											}
											return modifiedObj;
										})}
										filename="User.csv">
										<Button primary title={'Click to download the failed file'} icon="download" />
									</CSVLink>
								) : (
									''
								)}
							</div>
							<div>
								<Button
									color="black"
									onClick={() => {
										this.closeExcelDataModal();
										this.cleanAllDataVariable();
									}}>
									Cancel
								</Button>
								<Button
									onClick={this.sendMessages}
									positive
									disabled={this.hasInvalidData()}
									loading={this.props.bulkimportLoading}>
									Submit
								</Button>
							</div>
						</Modal.Actions>
					</Modal>
				)}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		bulkimportLoading: state.users.bulkimportLoading,
	};
};

export default connect(mapStateToProps)(UserImport);
