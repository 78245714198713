export default function accountOverview(
	initialState = {
		accounts: [],
		loading: true,
		pageNumber: 1,
		count: 0,
		activeIndex: -1,
		activeSort: '',
		sortOrder: '',
		filterModalOpen: false,

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
	},
	action
) {
	switch (action.type) {
		case 'FEED_ACCOUNT_OVERVIEW':
			return {
				...initialState,
				accounts: action.payload,
				loading: false,
			};

		case 'ACCOUNT_OVERVIEW_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'ACCOUNT_OVERVIEW_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'ACCOUNT_OVERVIEW_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'ACCOUNT_OVERVIEW_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'ACCOUNT_OVERVIEW_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'ACCOUNT_OVERVIEW_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'ACCOUNT_OVERVIEW_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'ACCOUNT_OVERVIEW_FILTER_MODAL_OPEN':
			return {
				...initialState,
				filterModalOpen: true,
			};

		case 'ACCOUNT_OVERVIEW_FILTER_MODAL_CLOSE':
			return {
				...initialState,
				filterModalOpen: false,
			};

		case 'ACCOUNT_OVERVIEW_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'ACCOUNT_OVERVIEW_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_ACCOUNT_OVERVIEW_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_ACCOUNT_OVERVIEW_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_ACCOUNT_OVERVIEW_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'ACCOUNT_OVERVIEW_UNMOUNT':
			return {
				...initialState,
				accounts: [],
				loading: true,
				pageNumber: 1,
				count: 0,
				activeIndex: -1,
				activeSort: '',
				sortOrder: '',
				filterModalOpen: false,

				// Export
				openExportModal: false,
				exportData: [],
				exportingData: false,
			};

		default:
			return initialState;
	}
}
