import React, { useEffect, useState } from "react";
import { Container } from "../user-management-system/UserManagementSystemStyles";
import { CategorySidebar } from "../../styles/containers/CategorySidebar";
import { SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_EXPANDED_WIDTH } from "../../../../data/constants/dimensions";
import { SidebarComponentList } from "../../styles/containers/SidebarComponentList";
import SidebarListItem from "../../blocks/molecules/sidebar-list-item/SidebarListItem";
import SidebarToggleIcon from "../../blocks/atoms/sidebar-toggle-icon/SidebarToggleIcon";
import { collapseSidebar, expandSidebar } from "../../../../logic/middleware/ui";
import { CategoryContent } from "../../styles/containers/CategoryContent";
import SidebarHeader from "../../blocks/molecules/sidebar-header/SidebarHeader";
import logs from "../../../assets/images/logs.png";
import { connect } from "react-redux";
import VehicleLogs from "../../pages/secured/diagnostic-and-logs/VehicleLogs";
import { Redirect, Route, Switch } from "react-router-dom";
import DeviceCommands from "../../pages/secured/diagnostic-and-logs/DeviceCommands";
import { entityAccessSetter } from "../../../../logic/utilities/accessSetter";
import { fetchDevices } from "../../../../logic/middleware/devices";
import { handleError } from "../../../../logic/middleware/messaging";
import { dispatchNoPayload } from "../../../../logic/middleware/middleware";
import ActivityLogs from "../../pages/secured/diagnostic-and-logs/ActivityLogs";
// import ActivityLogs from '../../pages/secured/diagnostic-and-logs/ActivityLogs';

const DiagnosticAndLogs = (props) => {
	// let isSuperAdmin = localStorage.getItem('isSuperAdmin') === 'true' ? true : false;

	const [managePermission, setManagePermission] = useState({
		readDiagnosticsAccess: false,
		createDiagnosticsAccess: false,
		updateDiagnosticsAccess: false,
		deleteDiagnosticsAccess: false,
		manageDiagnosticsAccess: false,
	});
	const [dropdownOptions, setDropdownOptions] = useState([]);
	const [vehicleValue, setVehicleValue] = useState('');

	const url = props.match.url;

	const manageAccessSetter = (rule, action) => {
		setManagePermission((prev) => ({ ...prev, [action]: rule }));
	};

	useEffect(() => {
		const permissions = JSON.parse(localStorage.getItem('permissions'));
		const diagnosticsPermissions = permissions?.filter((permission) => permission.subject === 'Diagnostics');
		if (diagnosticsPermissions) {
			for (const diagnosticsPrm of diagnosticsPermissions) {
				entityAccessSetter(diagnosticsPrm?.action, 'Diagnostics', manageAccessSetter);
			}
		}

		if (!managePermission.manageDiagnosticsAccess && !managePermission.readDiagnosticsAccess) {
			return;
		}

		fetchDevices('?dropdown=true')
			.then((response) => {
				let deviceOptions = response?.data?.data.map((log, index) => {
					let text = log?.vehicle?.registrationNumber ? `${log.vehicle.registrationNumber} (${log.imei})` : log.imei;
					return {
						key: index,
						text: text,
						value: log.imei,
					};
				});
				setDropdownOptions(deviceOptions);
			})
			.catch((error) => handleError(error));

		return () => {
			dispatchNoPayload('RESET_DATES');
		};
	}, [managePermission.manageDiagnosticsAccess, managePermission.readDiagnosticsAccess]);

	return (
		<Container>
			{/*Navigation*/}
			<CategorySidebar width={props.sidebarExpanded ? SIDEBAR_EXPANDED_WIDTH : SIDEBAR_COLLAPSED_WIDTH}>
				<SidebarHeader
					imageSource={logs}
					alt="DiagnosticAndLogs"
					name="Diagnostic And Logs"
					expanded={props.sidebarExpanded}
				/>

				<SidebarComponentList>
					<div
						style={{
							opacity: `${
								!managePermission.manageDiagnosticsAccess && !managePermission.readDiagnosticsAccess ? '.4' : '1'
							}`,
							pointerEvents: `${
								!managePermission.manageDiagnosticsAccess && !managePermission.readDiagnosticsAccess ? 'none' : ''
							}`,
						}}>
						<SidebarListItem
							path={`${url}/logs`}
							// exact={true}
							iconName="file outline"
							name="Logs"
							expanded={props.sidebarExpanded}
						/>
					</div>

					<div
						style={{
							opacity: `${
								!managePermission.manageDiagnosticsAccess && !managePermission.readDiagnosticsAccess ? '.4' : '1'
							}`,
							pointerEvents: `${
								!managePermission.manageDiagnosticsAccess && !managePermission.readDiagnosticsAccess ? 'none' : ''
							}`,
						}}>
						<SidebarListItem
							path={`${url}/device-commands`}
							exact={true}
							iconName="microchip"
							name="Device Commands"
							expanded={props.sidebarExpanded}
						/>
					</div>

					{/*{isSuperAdmin && (*/}
					<SidebarListItem
						path={`${url}/activity-logs`}
						iconName="clock outline"
						name="Activity Logs"
						expanded={props.sidebarExpanded}
					/>
					{/*)}*/}
				</SidebarComponentList>

				<SidebarToggleIcon
					name={props.sidebarExpanded ? 'chevron circle left' : 'chevron circle right'}
					handleClick={props.sidebarExpanded ? collapseSidebar : expandSidebar}
				/>
			</CategorySidebar>

			<CategoryContent
				width={
					props.sidebarExpanded ? `calc(100% - ${SIDEBAR_EXPANDED_WIDTH})` : `calc(100% - ${SIDEBAR_COLLAPSED_WIDTH})`
				}>
				<Switch>
					<Route exact path={`${url}`}>
						<Redirect to={`${url}/logs`} />
					</Route>
					<Route
						path={`${url}/logs`}
						// exact
						render={(props) => (
							<VehicleLogs
								onChangeValue={(value) => setVehicleValue(value || '')}
								dropdownValue={vehicleValue}
								vehicleDropdownOption={dropdownOptions}
								{...props}
							/>
						)}
					/>
					<Route
						path={`${url}/device-commands`}
						render={(props) => (
							<DeviceCommands
								onChangeValue={(value) => setVehicleValue(value || '')}
								dropdownValue={vehicleValue}
								deviceDropdownOptions={dropdownOptions}
								{...props}
							/>
						)}
					/>

					<Route path={`${url}/activity-logs`} exact component={ActivityLogs} />
				</Switch>
			</CategoryContent>
		</Container>
	);
};

const mapStateToProps = (state) => ({
	sidebarExpanded: state.ui.sidebarExpanded,
	// exportingData: state.logs.exportingData,
	// loading: state.logs.loading,
});
export default connect(mapStateToProps)(DiagnosticAndLogs);
