export function createLogsDTO(data) {
	if (data && data.length) {
		return data.map((log) => {
			const hasRelay =
				log.vehicle.sensors &&
				Array.isArray(log.vehicle.sensors) &&
				(log.vehicle.sensors.includes('relay') || log.vehicle.sensors.includes('inverted_relay'));
			const hasSOS = log.vehicle.sensors && Array.isArray(log.vehicle.sensors) && log.vehicle.sensors.includes('sos');

			return {
				id: log?.id ? log.id : null,
				createdDate: log?.createdDate ? log.createdDate : null,
				latitude: log?.latitude ? log.latitude : 0,
				longitude: log?.longitude ? log.longitude : 0,
				altitude: log?.altitude ? log.altitude.toFixed(2) : 0,
				angle: log?.angle ? log.angle : 0,
				satellites: log?.satellites ? log.satellites : 0,
				speed: log?.speed ? log.speed : 0,
				distance: log?.distance ? log.distance : 0,
				dte: log?.dte ? log.dte : 0,
				soc: log?.soc ? log.soc : 0,
				batteryLevel: log?.batteryLevel ? log.batteryLevel : 0,
				ignitionStatus: log?.ignitionStatus !== undefined ? log.ignitionStatus : 0,
				gnssStatus: log?.gnssStatus !== undefined ? log.gnssStatus : 0,
				priority: log?.priority ? log.priority : 0,
				eventId: log?.eventId ? log.eventId : null,
				relayStatus: hasRelay ? log.relayStatus : 'No Relay',
				timestamp: log?.timestamp ? log.timestamp : null,
				acStatus: log?.acStatus ? log.acStatus : null,
				panicButton: hasSOS ? (log.panicButton ? true : false) : 'No SOS',
				gsmSignal: log?.gsmSignal ? log.gsmSignal : 'No Signal',
				gnssHDOP: log?.gnssHDOP ? log.gnssHDOP : 0,
				gnssPDOP: log?.gnssPDOP ? log.gnssPDOP : 0,
				movementStatus: log?.movementStatus !== null ? log.movementStatus : 'Not Available',
				tripOdometer: log?.tripOdometer,
				vehicleStatus: log?.status ? log.status : 'Not Available',
				// activeGsmOperator: log?.activeGsmOperator ? log.activeGsmOperator : null,
				rawFuel: log?.rawFuel === null ? '-' : log.rawFuel,
				totalOdometer: log?.totalOdometer ? log.totalOdometer.toLocaleString() : 0,
				externalPowerStatus: log?.externalPowerStatus ? 'True' : 'False',
				externalPowerVoltage: log?.externalPowerVoltage ? (log?.externalPowerVoltage / 1000).toFixed(2) : 0.0,
				internalPowerVoltage: log?.internalPowerVoltage ? (log?.internalPowerVoltage / 1000).toFixed(2) : 0.0,
				// powerSupplyData: {
				// 	externalPowerVoltage: log?.powerSupplyData?.externalPowerVoltage
				// 		? log.powerSupplyData.externalPowerVoltage.toFixed(2)
				// 		: 0,
				// 	internalPowerVoltage: log?.powerSupplyData?.internalPowerVoltage
				// 		? log.powerSupplyData.internalPowerVoltage.toFixed(2)
				// 		: 0,
				// 	internalPowerCurrent: log?.powerSupplyData?.internalPowerCurrent
				// 		? log.powerSupplyData.internalPowerCurrent
				// 		: 0,
				// 	batteryLevel: log?.powerSupplyData?.batteryLevel ? log.powerSupplyData.batteryLevel : 0,
				// },
			};
		});
	} else return [];
}
