export default function accessModules(
	initialState = {
		accessModules: [],
		loading: true,
		pageNumber: 1,
		count: 0,
		activeIndex: -1,
		deleting: false,
		deleteModalOpen: false,
		activeSort: '',
		sortOrder: '',
		adding: false,
		addModalOpen: false,
		updating: false,
		editMode: false,
		restoreModal: false,
		restoring: false,

		exportModal: false,
		exportingData: false,
		exportData: [],
	},
	action
) {
	switch (action.type) {
		case 'FEED_ACCESS_MODULES':
			return {
				...initialState,
				accessModules: action.payload,
				loading: false,
			};

		case 'ACCESS_MODULES_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'ACCESS_MODULES_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'ACCESS_MODULES_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'ACCESS_MODULES_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'ACCESS_MODULES_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'ACCESS_MODULES_DELETING':
			return {
				...initialState,
				deleting: true,
			};

		case 'ACCESS_MODULES_DELETED':
			return {
				...initialState,
				deleting: false,
			};

		case 'ACCESS_MODULES_DELETE_MODAL_OPEN':
			return {
				...initialState,
				deleteModalOpen: true,
			};

		case 'ACCESS_MODULES_DELETE_MODAL_CLOSE':
			return {
				...initialState,
				deleteModalOpen: false,
			};

		case 'ACCESS_MODULES_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'ACCESS_MODULES_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'ACCESS_MODULES_ADDING':
			return {
				...initialState,
				adding: true,
			};

		case 'ACCESS_MODULES_ADDED':
			return {
				...initialState,
				adding: false,
			};

		case 'ACCESS_MODULES_ADD_MODAL_OPEN':
			return {
				...initialState,
				addModalOpen: true,
			};

		case 'ACCESS_MODULES_ADD_MODAL_CLOSE':
			return {
				...initialState,
				addModalOpen: false,
			};

		case 'ACCESS_MODULES_UPDATING':
			return {
				...initialState,
				updating: true,
			};

		case 'ACCESS_MODULES_UPDATED':
			return {
				...initialState,
				updating: false,
			};

		case 'ACCESS_MODULES_EDIT_MODAL_OPEN':
			return {
				...initialState,
				editMode: true,
			};

		case 'ACCESS_MODULES_EDIT_MODAL_CLOSE':
			return {
				...initialState,
				editMode: false,
			};

		case 'ACCESS_MODULES_RESTORE_MODAL_OPEN':
			return {
				...initialState,
				restoreModal: true,
			};

		case 'ACCESS_MODULES_RESTORE_MODAL_CLOSE':
			return {
				...initialState,
				restoreModal: false,
			};

		case 'ACCESS_MODULES_RESTORING':
			return {
				...initialState,
				restoring: true,
			};

		case 'ACCESS_MODULES_RESTORED':
			return {
				...initialState,
				restoring: false,
			};

		case 'ACCESS_MODULES_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				exportModal: true,
			};

		case 'ACCESS_MODULES_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				exportModal: false,
			};

		case 'FETCHING_ACCESS_MODULES_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'FETCHED_ACCESS_MODULES_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'SET_ACCESS_MODULES_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'CLEAR_ACCESS_MODULES_EXPORT_DATA':
			return {
				...initialState,
				exportData: [],
			};

		case 'ACCESS_MODULES_UNMOUNT':
			return {
				...initialState,
				accessModules: [],
				activeSort: '',
				sortOrder: '',
				activeIndex: -1,
				pageNumber: 1,

				exportModal: false,
				exportingData: false,
				exportData: [],
			};

		default:
			return initialState;
	}
}
