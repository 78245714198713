import React from "react";
// import PropTypes from 'prop-types';
import { ROLES_API } from "../../../../../logic/configs/api";
import { CategoryContentMembrane } from "../../../styles/containers/CategoryContentMembrane";
import SearchBox from "../../../blocks/atoms/search-box/SearchBox";
import { Expander } from "../../../styles/place-holders/Expander";
import { CategoryHeader } from "../../../styles/containers/CategoryHeader";
import FooterPagination from "../../../blocks/molecules/pagination/Pagination";
import { CategoryFooter } from "../../../styles/containers/CategoryFooter";
import { CategoryMain } from "../../../styles/containers/CategoryMain";
// import { CheckboxContainer } from '../../../styles/Containers';
import { AccordionHeader } from "../../../styles/containers/AccordionHeader";
import BasicLoader from "../../../blocks/molecules/loader/BasicLoader";
import PlaceHolder from "../../../blocks/molecules/place-holder/PlaceHolder";
import { AccordionMain } from "../../../styles/containers/AccordionMain";
import AddModal from "../../../blocks/organisms/modals/roles/AddModal";
import ExportModal from "../../../blocks/organisms/modals/roles/ExportModal";
// import { AccordionMainContent } from '../../../styles/containers/AccordionMainContent';
import TransitionLoader from "../../../blocks/molecules/loader/TransitionLoader";
import FilterModal from "../../../blocks/organisms/modals/roles/FilterModal";
import { connect } from "react-redux";
import { HeaderElement, Row, RowElement, TableContainer } from "../../../styles/Accordion";
import { Accordion, Button, Checkbox, Form, Icon, Input, Label, Modal, Table } from "semantic-ui-react";
import { dispatch, dispatchNoPayload } from "../../../../../logic/middleware/middleware";
import {
  closeFilterModal,
  exportData,
  fetchRolePermissions,
  refreshRoles,
  searchRoles,
  toggleAccordion
} from "../../../../../logic/middleware/roles";
import { generateSuccessMessage, handleError, handleSuccess } from "../../../../../logic/middleware/messaging";
import { getOrganisationsForDropdown } from "../../../../../logic/middleware/vehicles";
// import axios from 'axios';
import DeleteModal from "../../../blocks/organisms/modals/roles/DeleteModal";
import ToggleSwitch from "../../../blocks/molecules/toggle-switch/ToggleSwitch";
import RestoreModal from "../../../blocks/organisms/modals/roles/RestoreModal";
import { trackMixpanelEvent } from "../../../../../logic/middleware/mixpanel";
import { Container } from "../../public/Auth";
import { deleteData, patchData, postData } from "../../../../../logic/middleware/services/apiRequest";
import { capitalizeWord } from "../../../../../logic/utilities/validator";
import { datadogLogs } from "@datadog/browser-logs";
import { RolesToggle } from "../../../blocks/molecules/toggle-switch/ToggleSwitchStyles";
// import { FILE_TYPE_EXTENSIONS } from '../../../../../data/constants/file-types';

// Roles.propTypes = {
// 	restoring: PropTypes.node.isRequired,
// };

class Roles extends React.Component {
	roleID = '';
	deletedMode = false;
	searchQuery = '';
	timeoutId;
	selectAllActive = false;
	sortOrder = '';
	activeSort = '';
	activeFilters = {
		organisations: null,
		isSuperAdmin: null,
	};
	roleName = localStorage.getItem('roleName');
	superAdmin = localStorage.getItem('isSuperAdmin');

	constructor(props) {
		super(props);

		this.state = {
			initialLoading: true,
			organisations: [],
			permissions: [],
			modalIsOpen: false,
			// manageAccess: false,
			// createAccess: false,
			// updateAccess: false,
			// deleteAccess: false,
			// readAccess: false,

			// Add
			nameFA: null,
			descriptionFA: null,
			organisationFA: null,
			isSuperAdminFA: false,
			permissionsLoadingFA: false,
			permissionsFA: {},

			// Edit
			nameFE: null,
			descriptionFE: null,
			isSuperAdminFE: null,
			permissionsFE: {},

			filters: {
				organisations: null,
				isSuperAdmin: null,
			},

			// Export
			fileName: 'Roles',
			fileExtension: 'excel',

			// Selection
			anyRowSelected: false,
			selectedRows: new Set(),

			// Deletion
			deletedMode: false,

			// Unused
			accessModules: [],
			accessModulesFA: [],
		};

		this.onNameFEChange = this.onNameFEChange.bind(this);
		this.onDescriptionFEChange = this.onDescriptionFEChange.bind(this);
		this.setPermissions = this.setPermissions.bind(this);
		this.cleanEditOperation = this.cleanEditOperation.bind(this);
		this.clearAddOperation = this.clearAddOperation.bind(this);
		this.add = this.add.bind(this);
		this.onNameFAChange = this.onNameFAChange.bind(this);
		this.onOrgChange = this.onOrgChange.bind(this);
		this.onDescriptionFAChange = this.onDescriptionFAChange.bind(this);
		this.onPermissionsFAChange = this.onPermissionsFAChange.bind(this);
		this.onPermissionsFEChange = this.onPermissionsFEChange.bind(this);
		this.fileExtensionChange = this.fileExtensionChange.bind(this);
		this.fileNameChange = this.fileNameChange.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleDataSizeLimitChange = this.handleDataSizeLimitChange.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.onOrganisationFilterChange = this.onOrganisationFilterChange.bind(this);
		this.onIsSuperAdminFilterChange = this.onIsSuperAdminFilterChange.bind(this);
		// this.onIsSuperAdminChangeFA = this.onIsSuperAdminChangeFA.bind(this);
		this.onIsSuperAdminChangeFE = this.onIsSuperAdminChangeFE.bind(this);
		this.toggleDeletedMode = this.toggleDeletedMode.bind(this);
		this.cleanDeleteOperation = this.cleanDeleteOperation.bind(this);
		this.cleanRestoreOperation = this.cleanRestoreOperation.bind(this);
		this.hasPermission = this.hasPermission.bind(this);
		this.populatePermissionsForEdit = this.populatePermissionsForEdit.bind(this);
		// this.manageAccessSetter = this.manageAccessSetter.bind(this);
	}

	// manageAccessSetter(rule, action) {
	// 	this.setState({ [action]: rule });
	// }

	componentDidMount() {
		trackMixpanelEvent('Roles', { Action: 'View' });
		// const permissions = JSON.parse(localStorage.getItem('permissions'));
		// const rolesPermissions = permissions?.filter((permission) => permission.subject === 'Role');
		// if (rolesPermissions) {
		// 	for (const rolePrm of rolesPermissions) {
		// 		accessSetter(rolePrm?.action, this.manageAccessSetter);
		// 	}
		// }

		if (this.props.searchQuery) {
			dispatch('UPDATE_SEARCH_QUERY', this.props.searchQuery);
			this.handleSearch({ target: { value: this.props.searchQuery } });
		}

		refreshRoles('', 1, false);
		fetchRolePermissions().then((response) => {
			this.setState({
				permissions: response?.data || {},
			});
		});
	}

	// Fetch
	getFilteredRoles(activePage = 1, resetAccordion = true) {
		if (resetAccordion) {
			dispatch('ROLES_TOGGLE_ACCORDION', -1);
		}

		let query = '';
		if (this.searchQuery !== '') {
			query = query + searchRoles(this.searchQuery);
		}

		// if (this.activeFilters.organisations) {
		//   query = query + `&organisation[]=${this.activeFilters.organisations}`;
		// }

		if (this.activeFilters.isSuperAdmin !== null) {
			if (this.activeFilters.isSuperAdmin === true) query = query + `&isSuperAdmin=${this.activeFilters.isSuperAdmin}`;
			else query = query + '&isSuperAdmin';
		}

		if (this.activeSort !== '' && this.sortOrder !== '') {
			if (this.sortOrder === 'asc') {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			} else {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			}
		}

		if (this.deletedMode) {
			query = query + '&deleted=true';
		}

		refreshRoles(query, activePage, false);
	}

	toggleDeletedMode() {
		this.deletedMode = !this.deletedMode;
		this.setState({
			deletedMode: !this.state.deletedMode,
		});

		this.getFilteredRoles();
		this.cleanSelectedRows();
	}

	handleSearch(e) {
		this.searchQuery = e.target.value;
		dispatch('UPDATE_SEARCH_QUERY', this.searchQuery);

		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}

		this.timeoutId = setTimeout(() => {
			this.getFilteredRoles();
			this.cleanSelectedRows();
		}, 500);
	}

	// Export
	fileNameChange(e) {
		this.setState({ fileName: e.target.value });
	}

	fileExtensionChange(e, { value }) {
		this.setState({ fileExtension: value });
	}

	handleExportSelection(checkedData, id) {
		let selectedRows = this.state.selectedRows;

		if (selectedRows.has(id)) {
			selectedRows.delete(id);
		} else selectedRows.add(id);

		this.setState({
			selectedRows: selectedRows,
		});

		this.setState({
			anyRowSelected: !!selectedRows.size,
		});
	}

	handleSelectAll(checked) {
		let selectedRows = new Set();

		if (checked) {
			this.selectAllActive = true;
			this.props.roles.forEach((role) => selectedRows.add(role.id));
			this.setState({
				selectedRows: selectedRows,
			});
		} else {
			this.selectAllActive = false;
			this.setState({
				selectedRows: new Set(),
			});
		}

		this.setState({
			anyRowSelected: !!selectedRows.size,
		});
	}

	export() {
		const { roles } = this.props;

		let rolesToExport = [];
		roles.forEach((role) => {
			if (this.state.selectedRows.has(role.id)) {
				rolesToExport.push(role);
			}
		});

		return rolesToExport;
	}

	// Pagination
	handleDataSizeLimitChange() {
		this.getFilteredRoles();
	}

	handlePageChange(activePage) {
		this.getFilteredRoles(activePage);
		this.cleanSelectedRows();
	}

	sort(param, activeSort, sortOrder) {
		if (!param) {
			this.activeSort = param;
			this.sortOrder = '';
		} else {
			if (activeSort !== param) {
				this.sortOrder = 'asc';
				this.activeSort = param;
			} else {
				if (sortOrder === 'asc') {
					this.sortOrder = 'desc';
				} else {
					this.sortOrder = 'asc';
				}
			}
		}

		dispatch('ROLES_SORT_ORDER', this.sortOrder);
		dispatch('ROLES_ACTIVE_ORDER', this.activeSort);
		this.getFilteredRoles(this.props.pageNumber);
	}

	cleanSelectedRows() {
		this.setState({
			anyRowSelected: false,
			selectedRows: new Set(),
		});
	}

	onNameFAChange(e) {
		const capitalizedValue = capitalizeWord(e.target.value);

		this.setState({
			nameFA: capitalizedValue,
		});
	}

	onOrgChange(e, { value }) {
		this.setState({
			organisationFA: value,
		});
	}

	onDescriptionFAChange(e) {
		this.setState({
			descriptionFA: e.target.value,
		});
	}

	// onIsSuperAdminChangeFA(data) {
	// 	this.setState({
	// 		isSuperAdminFA: data.checked,
	// 	});
	// }

	onPermissionsFAChange(name, action, checked) {
		let permissions = this.state.permissionsFA;

		if (name === 'Configurations' && action === 'write') {
			{
				if (checked)
					permissions[name] = {
						...permissions[name],
						write: true,
					};
				else
					permissions[name] = {
						...permissions[name],
						read: false,
						write: false,
						update: false,
						erase: false,
					};
			}
		}

		if (name !== 'Configurations') {
			if (action === 'read') {
				if (checked)
					permissions[name] = {
						...permissions[name],
						read: true,
					};
				else
					permissions[name] = {
						...permissions[name],
						read: false,
						write: false,
						update: false,
						erase: false,
					};
			} else if (action === 'write') {
				if (checked)
					permissions[name] = {
						...permissions[name],
						read: true,
						write: true,
					};
				else
					permissions[name] = {
						...permissions[name],
						write: false,
						update: false,
						erase: false,
					};
			} else if (action === 'update') {
				if (checked)
					permissions[name] = {
						...permissions[name],
						read: true,
						write: true,
						update: true,
					};
				else
					permissions[name] = {
						...permissions[name],
						update: false,
						erase: false,
					};
			} else if (action === 'erase') {
				if (checked)
					permissions[name] = {
						...permissions[name],
						read: true,
						write: true,
						update: true,
						erase: true,
					};
				else
					permissions[name] = {
						...permissions[name],
						erase: false,
					};
			} else if (action === 'manage') {
				if (checked)
					permissions[name] = {
						...permissions[name],
						manage: true,
						read: true,
						write: true,
						update: true,
						erase: true,
					};
				else
					permissions[name] = {
						...permissions[name],
						manage: false,
						read: false,
						write: false,
						update: false,
						erase: false,
					};
			}
		}

		this.setState({
			permissionsFA: permissions,
		});
	}

	// Add
	add() {
		const { nameFA, organisationFA, descriptionFA, permissionsFA } = this.state;

		let payload = {};

		if (nameFA) payload = { ...payload, name: nameFA.trim() };
		if (organisationFA)
			payload = {
				...payload,
				organisation: {
					id: organisationFA,
				},
			};

		if (descriptionFA) payload = { ...payload, description: descriptionFA.trim() };
		// payload = { ...payload, isSuperAdmin: !!isSuperAdminFA };
		let permissionPayload = [];
		Object.keys(permissionsFA).forEach((name) => {
			let subject;
			Object.keys(this.state.permissions).forEach((permission) => {
				if (permission === name) {
					subject = this.state.permissions[name];
				}
			});
			if (permissionsFA[name]?.manage || permissionsFA[name]?.erase) {
				permissionPayload.push({ subject: subject, action: 'manage' });
			} else if (permissionsFA[name]?.update) {
				permissionPayload.push(
					{ subject: subject, action: 'update' },
					{ subject: subject, action: 'create' },
					{ subject: subject, action: 'read' }
				);
			} else if (permissionsFA[name]?.write && subject !== 'Configurations') {
				permissionPayload.push({ subject: subject, action: 'create' }, { subject: subject, action: 'read' });
			} else if (permissionsFA[name]?.write && subject === 'Configurations') {
				permissionPayload.push({ subject: subject, action: 'create' });
			} else if (permissionsFA[name]?.read) {
				permissionPayload.push({ subject: subject, action: 'read' });
			}
		});
		payload = { ...payload, permissions: [...permissionPayload] };
		dispatchNoPayload('ROLES_ADDING');

		// axios
		// 	.post(ROLES_API, payload, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 			'Content-Type': 'application/json',
		// 		},
		// 	})
		postData(ROLES_API, '', payload)
			.then((response) => {
				dispatchNoPayload('ROLES_ADDED');
				generateSuccessMessage(response);
				this.getFilteredRoles(this.props.pageNumber);
				this.clearAddOperation();
			})
			.catch((error) => {
				dispatchNoPayload('ROLES_ADDED');
				datadogLogs.logger.error('Roles add api', {}, error);
				handleError(error);
			});
	}

	clearAddOperation() {
		dispatchNoPayload('ROLES_ADD_MODAL_CLOSE');

		this.setState({
			nameFA: null,
			descriptionFA: null,
			organisationFA: null,
			isSuperAdminFA: false,
			permissionsFA: {},
		});
	}

	populatePermissionsForEdit(permissions) {
		let localPermissions = {};
		permissions.forEach((permission) => {
			if (permission.action === 'manage') {
				localPermissions[permission.subject] = {
					...localPermissions[permission.subject],
					manage: true,
					read: true,
					write: true,
					update: true,
					erase: true,
				};
			} else if (permission.action === 'read') {
				localPermissions[permission.subject] = {
					...localPermissions[permission.subject],
					read: true,
				};
			} else if (permission.action === 'create') {
				localPermissions[permission.subject] = {
					...localPermissions[permission.subject],
					read: true,
					write: true,
				};
			} else if (permission.action === 'update') {
				localPermissions[permission.subject] = {
					...localPermissions[permission.subject],
					read: true,
					write: true,
					update: true,
				};
			} else if (permission.action === 'delete') {
				localPermissions[permission.subject] = {
					...localPermissions[permission.subject],
					read: true,
					write: true,
					update: true,
					erase: true,
				};
			}
		});

		this.setState({
			permissionsFE: localPermissions,
		});
	}

	onPermissionsFEChange(name, action, checked) {
		let permissions = this.state.permissionsFE;
		if (name === 'Configurations' && action === 'write') {
			{
				if (checked)
					permissions[name] = {
						...permissions[name],
						write: true,
					};
				else
					permissions[name] = {
						...permissions[name],
						read: false,
						write: false,
						update: false,
						erase: false,
					};
			}
		}
		if (name !== 'Configurations') {
			if (action === 'read') {
				if (checked)
					permissions[name] = {
						...permissions[name],
						read: true,
					};
				else
					permissions[name] = {
						...permissions[name],
						read: false,
						write: false,
						update: false,
						erase: false,
					};
			} else if (action === 'write') {
				if (checked)
					permissions[name] = {
						...permissions[name],
						read: true,
						write: true,
					};
				else
					permissions[name] = {
						...permissions[name],
						write: false,
						update: false,
						erase: false,
					};
			} else if (action === 'update') {
				if (checked)
					permissions[name] = {
						...permissions[name],
						read: true,
						write: true,
						update: true,
					};
				else
					permissions[name] = {
						...permissions[name],
						update: false,
						erase: false,
					};
			} else if (action === 'erase') {
				if (checked)
					permissions[name] = {
						...permissions[name],
						read: true,
						write: true,
						update: true,
						erase: true,
					};
				else
					permissions[name] = {
						...permissions[name],
						erase: false,
					};
			} else if (action === 'manage') {
				if (checked)
					permissions[name] = {
						...permissions[name],
						manage: true,
						read: true,
						write: true,
						update: true,
						erase: true,
					};
				else
					permissions[name] = {
						...permissions[name],
						manage: false,
						read: false,
						write: false,
						update: false,
						erase: false,
					};
			}
		}

		this.setState({
			permissionsFE: permissions,
		});
	}

	// Edit
	update(id) {
		const { nameFE, descriptionFE, isSuperAdminFE, permissionsFE } = this.state;

		let payload = {};

		if (nameFE) payload = { ...payload, name: nameFE.trim() };
		payload = descriptionFE === null ? payload : { ...payload, description: descriptionFE.trim() };
		if (isSuperAdminFE !== null) payload = { ...payload, isSuperAdmin: isSuperAdminFE };
		let permissionPayload = [];
		Object.keys(permissionsFE).forEach((name) => {
			let subject;
			Object.keys(this.state.permissions).forEach((permission) => {
				if (this.state.permissions[permission] === name) {
					subject = name;
				}
			});
			if (permissionsFE[name]?.manage || permissionsFE[name]?.erase) {
				permissionPayload.push({ subject: subject, action: 'manage' });
			} else if (permissionsFE[name]?.update) {
				permissionPayload.push(
					{ subject: subject, action: 'update' },
					{ subject: subject, action: 'create' },
					{ subject: subject, action: 'read' }
				);
			} else if (permissionsFE[name]?.read && subject === 'Report') {
				permissionPayload.push({ subject: subject, action: 'read' });
			} else if (permissionsFE[name]?.write && subject === 'Configurations') {
				permissionPayload.push({ subject: subject, action: 'create' });
			} else if (permissionsFE[name]?.write && subject !== 'Configurations') {
				permissionPayload.push({ subject: subject, action: 'create' }, { subject: subject, action: 'read' });
			} else if (permissionsFE[name]?.read) {
				permissionPayload.push({ subject: subject, action: 'read' });
			}
		});
		payload = { ...payload, permissions: [...permissionPayload] };
		payload.permissions = payload.permissions.filter((permission) => permission.action !== null);
		payload.permissions = payload.permissions.length ? payload.permissions : null;

		dispatchNoPayload('ROLES_UPDATING');

		// axios
		// 	.patch(ROLES_API + `/${id}`, payload, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		patchData(ROLES_API + `/${id}`, payload)
			.then((response) => {
				dispatchNoPayload('ROLES_UPDATED');
				generateSuccessMessage(response);
				this.getFilteredRoles(this.props.pageNumber, false);
				this.setState({ modalIsOpen: false });
				this.cleanEditOperation();
				handleSuccess(response);
			})
			.catch((error) => {
				dispatchNoPayload('ROLES_UPDATED');
				handleError(error);
			});
	}

	cleanEditOperation() {
		dispatchNoPayload('ROLES_EDIT_MODAL_CLOSE');

		this.setState({
			nameFE: null,
			descriptionFE: null,
			isSuperAdminFE: null,
			permissionsFE: {},
		});
	}

	onNameFEChange(e) {
		const capitalizedValue = capitalizeWord(e.target.value);
		this.setState({
			nameFE: capitalizedValue,
		});
	}

	onDescriptionFEChange(e) {
		this.setState({
			descriptionFE: e.target.value,
		});
	}

	onIsSuperAdminChangeFE(data) {
		this.setState({
			isSuperAdminFE: data.checked,
		});
	}

	setPermissions(permissions) {
		let object = {};

		if (permissions.length) {
			permissions.forEach((permission) => {
				if (!object[permission.accessModule.id]) {
					object[permission.accessModule.id] = {
						read: permission.read || false,
						write: permission.write || false,
						update: permission.update || false,
						erase: permission.erase || false,
					};
				}
			});
		}

		this.setState({
			permissionsFE: object,
		});
	}

	onOrganisationFilterChange(e, { value }) {
		this.setState({
			filters: { ...this.state.filters, organisations: value },
		});
	}

	onIsSuperAdminFilterChange(e, { value }) {
		this.setState({
			filters: { ...this.state.filters, isSuperAdmin: !!value },
		});
	}

	deleteRole() {
		dispatchNoPayload('ROLES_DELETING');
		// axios
		// 	.delete(ROLES_API + `/${this.roleID}`, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		deleteData(ROLES_API, `/${this.roleID}`)
			.then((response) => {
				dispatchNoPayload('ROLES_DELETED');
				generateSuccessMessage(response);
				this.getFilteredRoles(this.props.pageNumber);
				this.cleanDeleteOperation();
			})
			.catch((error) => {
				dispatchNoPayload('ROLES_DELETED');
				handleError(error);
			});
	}

	cleanDeleteOperation() {
		dispatchNoPayload('ROLES_DELETE_MODAL_CLOSE');

		this.pobsID = '';
	}

	// Restore
	restoreRole(id) {
		dispatchNoPayload('ROLES_RESTORING');
		// axios
		// 	.post(
		// 		ROLES_API + `/${id}/restore`,
		// 		{},
		// 		{
		// 			headers: {
		// 				Authorization: 'Bearer ' + localStorage.getItem('peopleToken'),
		// 			},
		// 		}
		// 	)
		postData(ROLES_API, `/${id}/restore`)
			.then((response) => {
				dispatchNoPayload('ROLES_RESTORED');
				generateSuccessMessage(response);
				this.getFilteredRoles(this.props.pageNumber);
				this.cleanRestoreOperation();
			})
			.catch((error) => {
				handleError(error, true);
				datadogLogs.logger.error('Roles restore api', {}, error);
				dispatchNoPayload('ROLES_RESTORED');
			});
	}

	cleanRestoreOperation() {
		dispatchNoPayload('ROLES_RESTORE_MODAL_CLOSE');

		this.roleID = '';
	}

	componentDidUpdate(prevProps) {
		if (prevProps.loading !== this.props.loading) {
			if (!this.props.loading) {
				this.setState({
					initialLoading: false,
				});
			}
		}

		if (prevProps.pageNumber !== this.props.pageNumber) {
			document.getElementById('AccordionMain').scrollTo(0, 0);
		}
	}

	hasPermission(key, action, role) {
		let permissionStatus = false;
		role.permissions.forEach((permission) => {
			if (permission.subject === this.state.permissions[key] && permission.action === action) {
				permissionStatus = true;
			}
		});

		return permissionStatus;
	}

	componentWillUnmount() {
		dispatchNoPayload('ROLES_UNMOUNT');
	}

	renderCheckbox(permissionKey) {
		return (
			<>
				<Table.Cell collapsing>
					{this.state.permissions[permissionKey] !== 'Configurations' &&
						this.state.permissions[permissionKey] !== 'Report' &&
						this.state.permissions[permissionKey] !== 'Diagnostics' && (
							<RolesToggle>
								<Checkbox
									toggle
									disabled={!this.props.editMode}
									// checked={this.hasPermission(permissionKey, 'manage', role)}
									checked={this.state.permissionsFE?.[this.state.permissions[permissionKey]]?.manage}
									onChange={(e, { checked }) =>
										this.onPermissionsFEChange(this.state.permissions[permissionKey], 'manage', checked)
									}
								/>
							</RolesToggle>
						)}
				</Table.Cell>
				<Table.Cell collapsing>
					{this.state.permissions[permissionKey] !== 'Configurations' && (
						<RolesToggle>
							<Checkbox
								toggle
								disabled={
									!this.props.editMode || this.state.permissionsFE?.[this.state.permissions[permissionKey]]?.manage
								}
								checked={this.state.permissionsFE?.[this.state.permissions[permissionKey]]?.read}
								onChange={(e, { checked }) =>
									this.onPermissionsFEChange(this.state.permissions[permissionKey], 'read', checked)
								}
							/>
						</RolesToggle>
					)}
				</Table.Cell>
				<Table.Cell collapsing>
					{this.state.permissions[permissionKey] !== 'Report' &&
						this.state.permissions[permissionKey] !== 'Diagnostics' && (
							<RolesToggle>
								<Checkbox
									toggle
									disabled={
										!this.props.editMode || this.state.permissionsFE?.[this.state.permissions[permissionKey]]?.manage
									}
									checked={this.state.permissionsFE?.[this.state.permissions[permissionKey]]?.write}
									onChange={(e, { checked }) =>
										this.onPermissionsFEChange(this.state.permissions[permissionKey], 'write', checked)
									}
								/>
							</RolesToggle>
						)}
				</Table.Cell>
				<Table.Cell collapsing>
					{this.state.permissions[permissionKey] !== 'Configurations' &&
						this.state.permissions[permissionKey] !== 'Report' &&
						this.state.permissions[permissionKey] !== 'Diagnostics' && (
							<RolesToggle>
								<Checkbox
									toggle
									disabled={
										!this.props.editMode || this.state.permissionsFE?.[this.state.permissions[permissionKey]]?.manage
									}
									checked={this.state.permissionsFE?.[this.state.permissions[permissionKey]]?.update}
									onChange={(e, { checked }) =>
										this.onPermissionsFEChange(this.state.permissions[permissionKey], 'update', checked)
									}
								/>
							</RolesToggle>
						)}
				</Table.Cell>
				<Table.Cell collapsing>
					{this.state.permissions[permissionKey] !== 'Configurations' &&
						this.state.permissions[permissionKey] !== 'Report' &&
						this.state.permissions[permissionKey] !== 'Diagnostics' && (
							<RolesToggle>
								<Checkbox
									toggle
									disabled={
										!this.props.editMode || this.state.permissionsFE?.[this.state.permissions[permissionKey]]?.manage
									}
									checked={this.state.permissionsFE?.[this.state.permissions[permissionKey]]?.erase}
									onChange={(e, { checked }) =>
										this.onPermissionsFEChange(this.state.permissions[permissionKey], 'erase', checked)
									}
								/>
							</RolesToggle>
						)}
				</Table.Cell>
			</>
		);
	}

	render() {
		return (
			<CategoryContentMembrane>
				<CategoryHeader>
					<SearchBox
						placeholder="Search in roles"
						searchQuery={this.searchQuery}
						clearSearchIconClick={() => {
							this.searchQuery = '';
							this.getFilteredRoles(this.props.pageNumber);

							dispatch('UPDATE_SEARCH_QUERY', '');
						}}
						value={this.props.searchQuery}
						handleSearch={this.handleSearch}
						// disabled={!this.searchQuery && (this.props.loading || this.props.exportingData)}
					/>
					<Button
						icon
						primary
						disabled={this.props.loading || this.props.exportingData}
						title="Refresh Roles"
						onClick={() => {
							this.getFilteredRoles(this.props.pageNumber);
						}}>
						<Icon name="refresh" />
					</Button>
					<Expander />

					{/*<Button*/}
					{/*	primary={!this.activeFilters.isSuperAdmin}*/}
					{/*	icon*/}
					{/*	disabled={this.props.loading || this.props.exportingData}*/}
					{/*	color={this.activeFilters.isSuperAdmin !== null ? 'orange' : 'white'}*/}
					{/*	title="Filter Roles"*/}
					{/*	onClick={() => {*/}
					{/*		openFilterModal();*/}
					{/*		getOrganisationsForDropdown('?dropdown=true').then((response) => {*/}
					{/*			this.setState({ organisations: response });*/}
					{/*		});*/}
					{/*	}}>*/}
					{/*	<Icon name="filter" />*/}
					{/*</Button>*/}

					{/*Add*/}
					{!this.state.deletedMode && this.superAdmin=="true" && (
						<Button
							primary
							title="Add Role"
							disabled={this.props.loading || this.props.exportingData}
							onClick={() => {
								trackMixpanelEvent('Roles', { Action: 'Add' });
								dispatchNoPayload('ROLES_ADD_MODAL_OPEN');
								getOrganisationsForDropdown('?dropdown=true').then((response) => {
									this.setState({ organisations: response });
								});
							}}>
							Add
						</Button>
					)}
					{/*Export*/}
					{/*<Button*/}
					{/*	disabled={*/}
					{/*		!this.state.anyRowSelected || this.props.loading || !this.props.roles.length || this.props.exportingData*/}
					{/*	}*/}
					{/*	title={!this.state.anyRowSelected ? 'Please select atleast one role to export' : 'Export Roles'}*/}
					{/*	onClick={() => {*/}
					{/*		openExportModal();*/}
					{/*		dispatch('SET_ROLES_EXPORT_DATA', this.export());*/}
					{/*	}}>*/}
					{/*	Export*/}
					{/*</Button>*/}

					{/*Export All*/}
					{/*<Button.Group>*/}
					{/*	<Button*/}
					{/*		primary*/}
					{/*		loading={this.props.exportingData}*/}
					{/*		disabled={this.props.loading || !this.props.roles.length || this.props.exportingData}*/}
					{/*		title="Export All Roles"*/}
					{/*		onClick={() => exportAll(this.deletedMode ? '&deleted=true' : '', this.state.fileExtension)}>*/}
					{/*		Export*/}
					{/*	</Button>*/}
					{/*	<Dropdown*/}
					{/*		className="button icon"*/}
					{/*		style={{ background: "#ffffff", color: "#1b3c71", borderColor: "#1b3c71" }}*/}
					{/*		floating*/}
					{/*		value={this.state.fileExtension}*/}
					{/*		options={FILE_TYPE_EXTENSIONS}*/}
					{/*		onChange={this.state.fileExtensionChange}*/}
					{/*		trigger={<></>}*/}
					{/*	/>*/}
					{/*</Button.Group>*/}
					{/*Export All Button Ends*/}

					{/* Deleted Mode*/}
					{this.superAdmin === 'true' && (
						<ToggleSwitch
							title={this.deletedMode ? 'Hide Deleted Roles' : 'Show Deleted Roles'}
							label="Deleted Mode"
							checked={this.deletedMode}
							disabled={this.props.loading || this.props.exportingData}
							action={this.toggleDeletedMode}
						/>
					)}
				</CategoryHeader>
				<CategoryMain>
					<AccordionHeader background={this.deletedMode}>
						{/*<HeaderElement*/}
						{/*	width="3%"*/}
						{/*	disabled={this.props.loading || this.props.exportingData || !this.props.roles.length}>*/}
						{/*	<CheckboxContainer>*/}
						{/*		<Checkbox*/}
						{/*			checked={this.props.roles.length !== 0 && this.state.selectedRows.size === this.props.roles.length}*/}
						{/*			indeterminate={*/}
						{/*				this.state.selectedRows.size !== 0 && this.state.selectedRows.size !== this.props.roles.length*/}
						{/*			}*/}
						{/*			onClick={(e, data) => {*/}
						{/*				this.handleSelectAll(data.checked);*/}
						{/*			}}*/}
						{/*		/>*/}
						{/*	</CheckboxContainer>*/}
						{/*</HeaderElement>*/}
						<HeaderElement
							width="7%"
							disabled={this.props.loading || this.props.exportingData || !this.props.roles.length}>
							No.
						</HeaderElement>
						<HeaderElement
							width="20%"
							onClick={() => {
								this.sort('name', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.roles.length}
							activeSort={this.activeSort === 'name'}>
							{this.props.activeSort === 'name' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet up" />
								) : (
									<Icon name="sort alphabet down" />
								)
							) : (
								<Icon name="sort" />
							)}
							Name
						</HeaderElement>
						<HeaderElement
							width="15%"
							disabled={this.props.loading || this.props.exportingData || !this.props.roles.length}>
							Super Admin
						</HeaderElement>
						<HeaderElement
							width="25%"
							onClick={() => {
								this.sort('description', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.roles.length}
							activeSort={this.activeSort === 'description'}>
							{this.props.activeSort === 'description' ? (
								this.props.sortOrder === 'description' ? (
									<Icon name="sort alphabet up" />
								) : (
									<Icon name="sort alphabet down" />
								)
							) : (
								<Icon name="sort" />
							)}
							Description
						</HeaderElement>
						{/*<HeaderElement*/}
						{/*	width="33%"*/}
						{/*	disabled={this.props.loading || this.props.exportingData || !this.props.roles.length}>*/}
						{/*	Count*/}
						{/*</HeaderElement>*/}
					</AccordionHeader>
					<AccordionMain id="AccordionMain">
						{this.state.initialLoading ? (
							<BasicLoader />
						) : this.props.roles.length ? (
							<Accordion fluid>
								{this.props.roles.map((role, index) => {
									return (
										<section key={index}>
											<Accordion.Title
												active={this.props.activeIndex === index}
												index={index}
												onClick={(event, data) => {
													this.setState({
														modalIsOpen: !this.state.modalIsOpen,
													});
													toggleAccordion(event, data, data.index - 1);
													this.cleanEditOperation();
													this.populatePermissionsForEdit(role.permissions);
												}}
												style={{ padding: 0 }}>
												<Row>
													{/*<CheckboxContainer>*/}
													{/*	<Checkbox*/}
													{/*		checked={this.state.selectedRows.has(role.id)}*/}
													{/*		onChange={(e, data) => {*/}
													{/*			e.stopPropagation();*/}
													{/*			this.handleExportSelection(data.checked, role.id);*/}
													{/*		}}*/}
													{/*	/>*/}
													{/*</CheckboxContainer>*/}
													{/*{this.superAdmin !== "true" ? (*/}
													{/*  <RowElement width="7%">{1}</RowElement>*/}
													{/*) : (*/}
													{/*  */}
													{/*)}*/}
													<RowElement width="7%">
														{1 + index + (this.props.pageNumber - 1) * this.props.dataSizeLimit}
													</RowElement>

													<RowElement
														width="20%"
														style={{ color: `${role.name ? 'black' : 'grey'}` }}
														title={role.name}>
														{role.name ? role.name : 'Not Available'}
													</RowElement>
													<RowElement
														width="15%"
														style={{ color: `${role.isSuperAdmin ? 'black' : 'grey'}` }}
														title={role.isSuperAdmin ? 'Yes' : 'No'}>
														{role.isSuperAdmin ? (
															<Label size="medium" style={{ minWidth: '40%', textAlign: 'center' }} color="green">
																Yes
															</Label>
														) : (
															<Label size="medium" style={{ minWidth: '40%', textAlign: 'center' }} color="red">
																No
															</Label>
														)}
													</RowElement>
													<RowElement
														width="25%"
														style={{ color: `${role.description ? 'black' : 'grey'}` }}
														title={role.description}>
														{role.description ? role.description : 'Not Available'}
													</RowElement>

													{/*<RowElement*/}
													{/*	width="32%"*/}
													{/*	style={{ color: `${role.count ? 'black' : 'grey'}` }}*/}
													{/*	title={role.count}>*/}
													{/*	{role.count ? role.count : 'Not Available'}*/}
													{/*</RowElement>*/}
												</Row>
											</Accordion.Title>
											{this.props.activeIndex === index && (
												<Accordion.Content active={false}>
													<Container>
														<Modal
															dimmer="inverted"
															active={this.state.modalIsOpen}
															open={this.state.modalIsOpen}
															onClose={() => {
																this.setState({
																	modalIsOpen: !this.state.modalIsOpen,
																});
															}}
															onOpen={() => {
																this.setState({
																	modalIsOpen: !this.state.modalIsOpen,
																});
															}}>
															<Modal.Content scrolling={true}>
																<Form style={{ padding: '1rem' }}>
																	<Form.Group widths="equal">
																		<Form.Field>
																			<label>Name</label>
																			<Input
																				fluid
																				value={
																					this.state.nameFE !== null
																						? this.state.nameFE
																						: role.name === null
																						? ''
																						: role.name
																				}
																				disabled={!this.props.editMode}
																				onChange={this.onNameFEChange}
																			/>
																		</Form.Field>
																		<Form.Field>
																			<label>Description</label>
																			<Input
																				fluid
																				value={
																					this.state.descriptionFE !== null
																						? this.state.descriptionFE
																						: role.description === null
																						? ''
																						: role.description
																				}
																				disabled={!this.props.editMode}
																				onChange={this.onDescriptionFEChange}
																			/>
																		</Form.Field>

																		{/*<Form.Field>*/}
																		{/*	<section*/}
																		{/*		style={{*/}
																		{/*			width: '100%',*/}
																		{/*			display: 'flex',*/}
																		{/*			alignItems: 'center',*/}
																		{/*			marginTop: '2em',*/}
																		{/*		}}>*/}
																		{/*		<label style={{ fontWeight: 'bold' }}>Super Admin</label>*/}
																		{/*		<Expander />*/}
																		{/*		<Checkbox*/}
																		{/*			toggle*/}
																		{/*			disabled={!this.props.editMode}*/}
																		{/*			onChange={(e, data) => {*/}
																		{/*				this.onIsSuperAdminChangeFE(data);*/}
																		{/*			}}*/}
																		{/*			checked={*/}
																		{/*				this.state.isSuperAdminFE === null*/}
																		{/*					? role.isSuperAdmin*/}
																		{/*					: this.state.isSuperAdminFE*/}
																		{/*			}*/}
																		{/*		/>*/}
																		{/*	</section>*/}
																		{/*</Form.Field>*/}
																	</Form.Group>
																	<TableContainer>
																		<Form.Field required>
																			<h5>Permissions</h5>
																		</Form.Field>
																		<Table celled striped compact selectable>
																			<Table.Header>
																				<Table.Row>
																					<Table.HeaderCell>Name</Table.HeaderCell>
																					<Table.HeaderCell>Manage</Table.HeaderCell>
																					<Table.HeaderCell>Read</Table.HeaderCell>
																					<Table.HeaderCell>Write</Table.HeaderCell>
																					<Table.HeaderCell>Update</Table.HeaderCell>
																					<Table.HeaderCell>Delete</Table.HeaderCell>
																				</Table.Row>
																			</Table.Header>
																			<Table.Body scrolling="true">
																				{Object.keys(this.state.permissions).map((permission, index) => {
																					return (
																						<Table.Row key={index}>
																							<Table.Cell>{this.state.permissions[permission]}</Table.Cell>
																							{this.renderCheckbox(permission)}
																						</Table.Row>
																					);
																				})}
																			</Table.Body>
																		</Table>
																	</TableContainer>
																</Form>
															</Modal.Content>
															{this.state.deletedMode ? (
																<Modal.Actions>
																	<Button
																		primary
																		onClick={() => {
																			dispatchNoPayload('ROLES_RESTORE_MODAL_OPEN');
																			this.roleID = role.id;
																		}}>
																		Restore
																	</Button>
																</Modal.Actions>
															) : this.props.editMode ? (
																<Modal.Actions>
																	<Button
																		secondary
																		onClick={() => {
																			this.cleanEditOperation();
																			this.setState({ modalIsOpen: false });
																		}}>
																		Cancel
																	</Button>
																	<Button
																		positive
																		loading={this.props.updating}
																		onClick={() => {
																			trackMixpanelEvent('Roles', { Action: 'Update' });
																			this.update(role.id);
																		}}>
																		Update
																	</Button>
																</Modal.Actions>
															) : (
																<Modal.Actions>
																	{this.superAdmin === 'true' && (
																		<Button
																			primary
																			// disabled={!(this.state.manageAccess || this.state.updateAccess)}
																			onClick={() => {
																				trackMixpanelEvent('Roles', { Action: 'Edit' });
																				dispatchNoPayload('ROLES_EDIT_MODAL_OPEN');
																			}}>
																			Edit
																		</Button>
																	)}
																	{this.superAdmin === 'true' && (
																		<Button
																			negative
																			// disabled={!(this.state.manageAccess || this.state.deleteAccess)}
																			onClick={() => {
																				trackMixpanelEvent('Roles', { Action: 'Delete' });
																				dispatchNoPayload('ROLES_DELETE_MODAL_OPEN');
																				this.roleID = role.id;
																			}}>
																			Delete
																		</Button>
																	)}
																</Modal.Actions>
															)}
														</Modal>
													</Container>
												</Accordion.Content>
											)}
										</section>
									);
								})}
							</Accordion>
						) : (
							<PlaceHolder />
						)}
					</AccordionMain>
				</CategoryMain>
				<CategoryFooter background={this.deletedMode}>
					<FooterPagination
						handleDataSizeLimitChange={this.handleDataSizeLimitChange}
						pageNumber={this.props.pageNumber}
						handlePageChange={this.handlePageChange}
						count={this.props.count}
						disabled={this.props.loading || !this.props.roles.length || this.props.exportingData}
					/>
				</CategoryFooter>
				{this.props.loading && !this.state.initialLoading && <TransitionLoader />}
				{this.props.addModalOpen && (
					<AddModal
						onClose={this.clearAddOperation}
						open={this.props.addModalOpen}
						onNameFAChange={this.onNameFAChange}
						onDescriptionFAChange={this.onDescriptionFAChange}
						organisationsOptions={this.state.organisations}
						organisationFA={this.state.organisationFA}
						onOrgChange={this.onOrgChange}
						// isSuperAdmin={this.state.isSuperAdminFA}
						// onIsSuperAdminChangeFA={this.onIsSuperAdminChangeFA}
						permissionsLoadingFA={this.state.permissionsLoadingFA}
						permissionOptions={this.state.permissions}
						permissionsFA={this.state.permissionsFA}
						onPermissionsFAChange={this.onPermissionsFAChange}
						onCancelClick={this.clearAddOperation}
						disabled={!this.state.nameFA || Object.keys(this.state.permissionsFA).length === 0}
						onAddButtonClick={this.add}
						addButtonLoading={this.props.adding}
					/>
				)}
				{/*Filter Modal*/}
				{this.props.filterModalOpen && (
					<FilterModal
						onClose={() => {
							this.setState({
								filters: {
									organisations: this.activeFilters.organisations,
									isSuperAdmin: this.activeFilters.isSuperAdmin,
								},
							});
							closeFilterModal();
						}}
						open={this.props.filterModalOpen}
						organisationsOptions={this.state.organisations}
						organisations={this.state.filters.organisations}
						onOrganisationFilterChange={this.onOrganisationFilterChange}
						isSuperAdminOptions={[
							{ key: 0, text: 'Yes', value: true },
							{ key: 1, text: 'No', value: false },
						]}
						isSuperAdmin={this.state.filters.isSuperAdmin}
						onIsSuperAdminFilterChange={this.onIsSuperAdminFilterChange}
						onCancelClick={() => {
							this.setState({
								filters: {
									organisations: null,
									isSuperAdmin: null,
								},
							});
							closeFilterModal();
						}}
						onResetClick={() => {
							this.activeFilters = {
								organisations: null,
								isSuperAdmin: null,
							};
							this.setState({
								filters: {
									organisations: null,
									isSuperAdmin: null,
								},
							});
							// closeFilterModal();
							this.getFilteredRoles();
						}}
						onApplyClick={() => {
							trackMixpanelEvent('Roles', { Action: 'Filter' });
							this.activeFilters = this.state.filters;
							this.getFilteredRoles();
							closeFilterModal();
						}}
					/>
				)}
				{this.props.exportModal && (
					<ExportModal
						open={this.props.exportModal}
						fileName={this.state.fileName}
						fileNameChange={this.fileNameChange}
						fileExtension={this.state.fileExtension}
						fileExtensionChange={this.fileExtensionChange}
						onDownloadClick={() => exportData(this.props.exportData, this.state.fileName, this.state.fileExtension)}
					/>
				)}
				{/*Delete Modal*/}
				{this.props.deleteModalOpen && (
					<DeleteModal
						onClose={this.cleanDeleteOperation}
						open={this.props.deleteModalOpen}
						onCancelClick={this.cleanDeleteOperation}
						onDeleteButtonClick={() => {
							trackMixpanelEvent('Roles', { Action: 'Delete' });
							this.deleteRole(this.roleID);
						}}
						deleteButtonLoading={this.props.deleting}
					/>
				)}
				{/*Restore Modal*/}
				{this.props.restoreModal && (
					<RestoreModal
						onClose={this.cleanRestoreOperation}
						open={this.props.restoreModal}
						onCancelClick={this.cleanRestoreOperation}
						onRestoreButtonClick={() => {
							this.restoreRole(this.roleID);
						}}
						restoreButtonLoading={this.props.restoring}
					/>
				)}
			</CategoryContentMembrane>
		);
	}
}

// Connect to Store
const mapStateToProps = (state) => ({
	roles: state.roles.roles,
	loading: state.roles.loading,
	activeIndex: state.roles.activeIndex,
	count: state.roles.count,
	pageNumber: state.roles.pageNumber,
	dataSizeLimit: state.ui.dataSizeLimit,
	adding: state.roles.adding,
	addModalOpen: state.roles.addModalOpen,
	editMode: state.roles.editMode,
	updating: state.roles.updating,
	deleteModalOpen: state.roles.deleteModalOpen,
	deleting: state.roles.deleting,
	activeSort: state.roles.activeSort,
	sortOrder: state.roles.sortOrder,
	filterModalOpen: state.roles.filterModalOpen,
	restoreModal: state.roles.restoreModal,
	restoring: state.roles.restoring,
	exportModal: state.roles.exportModal,
	exportData: state.roles.exportData,
	exportingData: state.roles.exportingData,
	searchQuery: state.vehicles.searchQuery,
});

export default connect(mapStateToProps)(Roles);
