import React, { useState } from 'react';

import CommonModal from '../../../molecules/modal/CommonModal';

import { Button, Dropdown, Form, Icon, Input } from 'semantic-ui-react';

import { DateInput } from 'semantic-ui-calendar-react';

const AddModal = (props) => {
	// let fileInput = React.createRef();
	let isSuperAdmin = localStorage.getItem('isSuperAdmin') === 'true' ? true : false;
	const MAX_FILE_SIZE = 5 * 1024 * 1024;
	const [formValues, setFormValues] = useState([
		{
			organisation: '',
			vehicleGroup: '',
			vehicle: '',
			documentType: '',
			documentExpiry: '',
			documentDesc: '',
			documentFile: '',
		},
	]);
	const [fileSizeError, setFileSizeError] = useState(false);

	// const [presentDoc, setPresentDoc] = useState(new Set());

	const VEHICLE_DOCUMENT_TYPES_OPTIONS = [
		{
			key: 1,
			value: 'Road Tax',
			text: 'Road Tax',
			disabled: false,
		},
		{
			key: 2,
			value: 'Vehicle Fitness',
			text: 'Vehicle Fitness',
			disabled: false,
		},
		{
			key: 3,
			value: 'Vehicle Insurance',
			text: 'Vehicle Insurance',
			disabled: false,
		},
		{
			key: 4,
			value: 'Vehicle Registration',
			text: 'Vehicle Registration',
			disabled: false,
		},
		{
			key: 5,
			value: 'PUC',
			text: 'PUC',
			disabled: false,
		},
	];

	/*const fetchDocumentList = () => {
        let query = `?pagecount=100&pagenumber=1`;

        if (formValues[0].organisation && formValues[0].vehicle) {
            query += `&organisations[]=${formValues[0].organisation}`;
            query += `&vehicles[]=${formValues[0].vehicle}`;
        }

        if (formValues[0].vehicleGroup && formValues[0].vehicle) {
            // query+=`&vehicleGroups[]=${formValues[0].vehicleGroup}`;
            query += `&vehicles[]=${formValues[0].vehicle}`;
        }

        return query;
    };*/
	const handleChange = (data, i) => {
		let newFormValues = [...formValues];
		newFormValues[i][data.name] = data.value;
		setFormValues([...newFormValues]);

		// let query = fetchDocumentList();

		// fetchVehicleDocuments(query).then((response) => {
		// 	if (
		// 		(formValues[0].organisation && formValues[0].vehicle) ||
		// 		(formValues[0].vehicleGroup && formValues[0].vehicle)
		// 	) {
		// 		response.data.data.map((item) => {
		// 			setPresentDoc((prev) => new Set([...prev, item.type]));
		// 		});
		// 	} else {
		// 		setPresentDoc(new Set());
		// 	}
		// });
	};

	const handleFileChange = (event, i) => {
		let value;
		if (event === null) value = '';
		else value = event.target.files[0];
		if (value && value.size > MAX_FILE_SIZE) {
			setFileSizeError(true);
		} else {
			setFileSizeError(false);
			let newFormValues = [...formValues];
			newFormValues[i].documentFile = value;
			setFormValues(newFormValues);
		}
	};

	let addFormFields = (i) => {
		setFormValues([
			...formValues,
			{
				organisation: formValues[i].organisation,
				vehicleGroup: formValues[i].vehicleGroup,
				vehicle: formValues[i].vehicle,
				documentType: null,
				documentExpiry: null,
				documentDesc: null,
				documentFile: null,
			},
		]);
	};

	let removeFormFields = (i) => {
		let newFormValues = [...formValues];
		newFormValues.splice(i, 1);
		setFormValues(newFormValues);
	};

	// const getUpdatedVehicleDocumentTypes = (presentDoc) => {
	// 	return VEHICLE_DOCUMENT_TYPES_OPTIONS.map((option) => {
	// 		return {
	// 			...option,
	// 			disabled: presentDoc.has(option.value),
	// 		};
	// 	});
	// };
	//
	// const updatedVehicleDocumentTypes = getUpdatedVehicleDocumentTypes(presentDoc);

	// let handleSubmit = (event) => {
	// 	event.preventDefault();
	// 	alert(JSON.stringify(formValues));
	// };

	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			header="Add Document"
			subHeader="(Document upload size should not exceed 5MB)"
			scrolling={true}
			size={'fullscreen'}
			content={
				<Form style={{ minHeight: '20em' }}>
					{formValues.map((element, index) => {
						return (
							<Form.Group key={index} style={{ width: '100%' }}>
								{isSuperAdmin ? (
									<Form.Field style={{ width: '16%' }} required>
										<label>Organisation</label>
										<Dropdown
											placeholder="Choose ..."
											name="organisation"
											fluid
											search
											selection
											clearable
											disabled={index + 1 < formValues.length}
											value={element.organisation}
											options={props.organisationDropdown}
											onChange={(event, data) => {
												props.handleOrgChange(event, data);
												handleChange(data, index);
											}}
										/>
									</Form.Field>
								) : (
									<Form.Field style={{ width: '16%' }} required>
										<label>VehicleGroups</label>
										<Dropdown
											placeholder="Choose ..."
											name="vehicleGroup"
											fluid
											search
											selection
											clearable
											disabled={index + 1 < formValues.length}
											value={element.vehicleGroup}
											options={props.vehicleGroupsDropDowm}
											onChange={(event, data) => {
												props.handleVGChnage(event, data);
												handleChange(data, index);
											}}
										/>
									</Form.Field>
								)}
								<Form.Field required={true} style={{ width: '16%' }}>
									<label>Vehicle No.</label>
									<Dropdown
										placeholder="Choose ..."
										name="vehicle"
										fluid
										search
										selection
										clearable
										disabled={index + 1 < formValues.length}
										value={element.vehicle}
										options={props.vehicleDropDown}
										onChange={(event, data) => handleChange(data, index)}
									/>
								</Form.Field>

								<Form.Field required style={{ width: '16%' }}>
									<label>Document Type</label>
									<Dropdown
										placeholder="Choose ..."
										name="documentType"
										fluid
										search
										selection
										clearable
										disabled={index + 1 < formValues.length}
										selectOnBlur={false}
										options={VEHICLE_DOCUMENT_TYPES_OPTIONS}
										value={element.documentType}
										onChange={(event, data) => handleChange(data, index)}
									/>
								</Form.Field>
								<Form.Field style={{ width: '16%' }}>
									<label>Document Expiry</label>
									<DateInput
										placeholder="Select"
										name="documentExpiry"
										fluid
										clearable
										disabled={index + 1 < formValues.length || element.documentType === 'Vehicle Registration'}
										value={element.documentExpiry}
										onChange={(event, data) => handleChange(data, index)}
									/>
								</Form.Field>
								<Form.Field style={{ width: '16%' }}>
									<label>Document Description</label>
									<Input
										placeholder="Enter Document Description..."
										name="documentDesc"
										fluid
										clearable
										disabled={index + 1 < formValues.length}
										value={element.documentDesc}
										onChange={(event, data) => handleChange(data, index)}
									/>
								</Form.Field>

								<Form.Field required style={{ width: '16%' }}>
									<label>Upload</label>
									<Form.Input
										type="file"
										error={
											fileSizeError && {
												content: 'Document upload size should not exceed 5MB',
											}
										}
										// ref={(ref) => (fileInput = ref)}
										accept=".jpg, .png, .pdf"
										{...(formValues[index].documentFile && {
											icon: (
												<Icon
													name="times circle outline"
													link
													onClick={() => {
														// fileInput.inputRef.current.value = '';
														handleFileChange(null, index);
													}}
												/>
											),
										})}
										fluid
										onChange={(e) => handleFileChange(e, index)}
									/>
								</Form.Field>

								<Form.Field style={{ width: '2%', alignSelf: 'center', textAlign: 'center' }}>
									<Icon
										style={{ cursor: 'pointer' }}
										type="button"
										disabled={!index}
										size={'large'}
										name="minus circle"
										onClick={() => removeFormFields(index)}
									/>
								</Form.Field>
								<Form.Field style={{ width: '2%', alignSelf: 'center', textAlign: 'center' }}>
									<Icon
										style={{ cursor: 'pointer' }}
										// disabled={
										// 	!formValues[index].documentType ||
										// 	!formValues[index].organisation ||
										// 	!formValues[index].vehicle ||
										// 	!formValues[index].documentFile ||
										// 	index + 1 < formValues.length
										// }
										disabled={
											!formValues[formValues.length - 1].vehicle ||
											// !formValues[formValues.length - 1].organisation ||
											!formValues[formValues.length - 1].documentFile ||
											!formValues[formValues.length - 1].documentType
										}
										name="plus circle"
										size={'large'}
										color={'blue'}
										// loading={addButtonLoading}
										onClick={() => addFormFields(index)}
									/>
								</Form.Field>
							</Form.Group>
						);
					})}
				</Form>
			}
			actions={
				<>
					<Button color="black" onClick={props.onCancel}>
						Cancel
					</Button>
					<Button
						positive
						disabled={
							!formValues[formValues.length - 1].vehicle ||
							// !formValues[formValues.length - 1].organisation ||
							!formValues[formValues.length - 1].documentFile ||
							!formValues[formValues.length - 1].documentType
						}
						onClick={() => props.handleFormSubmit(formValues)}>
						Submit Documents
					</Button>
				</>
			}></CommonModal>
	);
};

export default AddModal;
