export default function simPlans(
	initialState = {
		simPlans: [],
		loading: false,
		activeSort: '',
		sortOrder: '',
		deleting: false,
		deleteModalOpen: false,
		adding: false,
		addModalOpen: false,
		updating: false,
		editMode: false,
		activeIndex: -1,
		pageNumber: 1,
		count: 0,
		restoreModal: false,
		restoring: false,

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
	},
	action
) {
	switch (action.type) {
		case 'FEED_SIM_PLANS':
			return {
				...initialState,
				simPlans: action.payload,
				loading: false,
			};

		case 'SIM_PLANS_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'SIM_PLANS_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'SIM_PLANS_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'SIM_PLANS_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'SIM_PLANS_DELETING':
			return {
				...initialState,
				deleting: true,
			};

		case 'SIM_PLANS_DELETED':
			return {
				...initialState,
				deleting: false,
			};

		case 'SIM_PLANS_DELETE_MODAL_OPEN':
			return {
				...initialState,
				deleteModalOpen: true,
			};

		case 'SIM_PLANS_DELETE_MODAL_CLOSE':
			return {
				...initialState,
				deleteModalOpen: false,
			};

		case 'SIM_PLANS_ADDING':
			return {
				...initialState,
				adding: true,
			};

		case 'SIM_PLANS_ADDED':
			return {
				...initialState,
				adding: false,
			};

		case 'SIM_PLANS_ADD_MODAL_OPEN':
			return {
				...initialState,
				addModalOpen: true,
			};

		case 'SIM_PLANS_ADD_MODAL_CLOSE':
			return {
				...initialState,
				addModalOpen: false,
			};

		case 'SIM_PLANS_UPDATING':
			return {
				...initialState,
				updating: true,
			};

		case 'SIM_PLANS_UPDATED':
			return {
				...initialState,
				updating: false,
			};

		case 'SIM_PLANS_EDIT_MODAL_OPEN':
			return {
				...initialState,
				editMode: true,
			};

		case 'SIM_PLANS_EDIT_MODAL_CLOSE':
			return {
				...initialState,
				editMode: false,
			};

		case 'SIM_PLANS_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'SIM_PLANS_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'SIM_PLANS_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'SIM_PLANS_RESTORE_MODAL_OPEN':
			return {
				...initialState,
				restoreModal: true,
			};

		case 'SIM_PLANS_RESTORE_MODAL_CLOSE':
			return {
				...initialState,
				restoreModal: false,
			};

		case 'SIM_PLANS_RESTORING':
			return {
				...initialState,
				restoring: true,
			};

		case 'SIM_PLANS_RESTORED':
			return {
				...initialState,
				restoring: false,
			};

		case 'SIM_PLANS_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'SIM_PLANS_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_SIM_PLANS_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_SIM_PLANS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_SIM_PLANS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'SIM_PLANS_UNMOUNT':
			return {
				...initialState,
				simPlans: [],
				activeSort: '',
				sortOrder: '',
				activeIndex: -1,
				pageNumber: 1,
			};

		default:
			return initialState;
	}
}
