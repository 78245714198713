export default function organisations(
	initialState = {
		organisations: [],
		loading: true,
		pageNumber: 1,
		count: 0,
		activeIndex: -1,
		activeSort: '',
		sortOrder: '',
		adding: false,
		addModalOpen: false,
		restoreModal: false,
		restoring: false,
		deactivateModal: false,
		deactivateConfirmModal: false,
		deactivate: false,
		saasPlansModal: false,
		openSendVerificationModal: false,
		filterModalOpen: false,
		sending: false,

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
	},
	action
) {
	switch (action.type) {
		case 'FEED_ORGANISATIONS':
			return {
				...initialState,
				organisations: action.payload,
				loading: false,
			};

		case 'ORGANISATIONS_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'ORGANISATIONS_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'ORGANISATIONS_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'ORGANISATIONS_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'ORGANISATIONS_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'ORGANISATIONS_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'ORGANISATIONS_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'ORGANISATIONS_ADDING':
			return {
				...initialState,
				adding: true,
			};

		case 'ORGANISATIONS_ADDED':
			return {
				...initialState,
				adding: false,
			};

		case 'ORGANISATIONS_ADD_MODAL_OPEN':
			return {
				...initialState,
				addModalOpen: true,
			};

		case 'ORGANISATIONS_ADD_MODAL_CLOSE':
			return {
				...initialState,
				addModalOpen: false,
			};

		case 'ORGANISATIONS_UPDATING':
			return {
				...initialState,
				organisationsUpdating: true,
			};

		case 'ORGANISATIONS_UPDATED':
			return {
				...initialState,
				organisationsUpdating: false,
			};

		case 'ORGANISATIONS_EDIT_MODAL_OPEN':
			return {
				...initialState,
				organisationsEditMode: true,
			};

		case 'ORGANISATIONS_EDIT_MODAL_CLOSE':
			return {
				...initialState,
				organisationsEditMode: false,
			};

		case 'ORGANISATIONS_RESTORE_MODAL_OPEN':
			return {
				...initialState,
				restoreModal: true,
			};

		case 'ORGANISATIONS_RESTORE_MODAL_CLOSE':
			return {
				...initialState,
				restoreModal: false,
			};

		case 'ORGANISATIONS_RESTORING':
			return {
				...initialState,
				restoring: true,
			};

		case 'ORGANISATIONS_RESTORED':
			return {
				...initialState,
				restoring: false,
			};

		case 'ORGANISATIONS_FILTER_MODAL_OPEN':
			return {
				...initialState,
				filterModalOpen: true,
			};

		case 'ORGANISATIONS_FILTER_MODAL_CLOSE':
			return {
				...initialState,
				filterModalOpen: false,
			};

		case 'ORGANISATIONS_DEACTIVATE_MODAL_OPEN':
			return {
				...initialState,
				deactivateModal: true,
			};

		case 'ORGANISATIONS_DEACTIVATE_MODAL_CLOSE':
			return {
				...initialState,
				deactivateModal: false,
			};

		case 'ORGANISATIONS_DEACTIVATE_CONFIRM_MODAL_OPEN':
			return {
				...initialState,
				deactivateConfirmModal: true,
			};

		case 'ORGANISATIONS_DEACTIVATE_CONFIRM_MODAL_CLOSE':
			return {
				...initialState,
				deactivateConfirmModal: false,
			};

		case 'ORGANISATIONS_DEACTIVATED':
			return {
				...initialState,
				deactivate: false,
			};

		case 'ORGANISATIONS_DEACTIVATE':
			return {
				...initialState,
				deactivate: true,
			};

		case 'ORGANISATIONS_SAAS_PLANS_MODAL_OPEN':
			return {
				...initialState,
				saasPlansModal: true,
			};

		case 'ORGANISATIONS_SAAS_PLANS_MODAL_CLOSE':
			return {
				...initialState,
				saasPlansModal: false,
			};

		case 'ORGANISATIONS_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'ORGANISATIONS_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_ORGANISATIONS_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_ORGANISATIONS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_ORGANISATIONS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'ORGANISATIONS_SEND_VERIFICATION_MODAL_OPEN':
			return {
				...initialState,
				openSendVerificationModal: true,
			};

		case 'ORGANISATIONS_SEND_VERIFICATION_MODAL_CLOSE':
			return {
				...initialState,
				openSendVerificationModal: false,
			};

		case 'ORGANISATIONS_VERIFICATION_LINK_SENDING':
			return {
				...initialState,
				sending: true,
			};

		case 'ORGANISATIONS_VERIFICATION_LINK_SENDED':
			return {
				...initialState,
				sending: false,
			};

		case 'ORGANISATIONS_UNMOUNT':
			return {
				...initialState,
				organisations: [],
				loading: true,
				pageNumber: 1,
				count: 0,
				activeIndex: -1,
				activeSort: '',
				sortOrder: '',
				adding: false,
				addModalOpen: false,
				restoreModal: false,
				restoring: false,
				deactivateModal: false,
				deactivateConfirmModal: false,
				deactivate: false,
				saasPlansModal: false,
				openSendVerificationModal: false,
				sending: false,
			};

		default:
			return initialState;
	}
}
