import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Devices from '../../pages/secured/operations/Devices';
import DeviceModels from '../../pages/secured/operations/DeviceModels';
import SIM from '../../pages/secured/operations/SIM';
import Vehicles from '../../pages/secured/operations/Vehicles';
import VehicleGroups from '../../pages/secured/operations/VehicleGroups';
import Organisations from '../../pages/secured/operations/Organisations';
import Users from '../../pages/secured/operations/Users';
import SidebarHeader from '../../blocks/molecules/sidebar-header/SidebarHeader';
import SidebarToggleIcon from '../../blocks/atoms/sidebar-toggle-icon/SidebarToggleIcon';
import SidebarListItem from '../../blocks/molecules/sidebar-list-item/SidebarListItem';
import Alerts from '../../pages/secured/operations/Alerts';

import { CategoryContent } from '../../styles/containers/CategoryContent';
import { SidebarComponentList } from '../../styles/containers/SidebarComponentList';

import { SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_EXPANDED_WIDTH } from '../../../../data/constants/dimensions';
import OperationsImage from '../../../assets/images/rocket.png';
import { collapseSidebar, expandSidebar } from '../../../../logic/middleware/ui';

import { connect } from 'react-redux';

import { CategorySidebar } from '../../styles/containers/CategorySidebar';
import { Container, IconContainer, SidebarList } from './OperationsView';
import { Accordion, Icon } from 'semantic-ui-react';
import { COLORS } from '../../../styles/colors';
import Students from '../../pages/secured/operations/Students';

import { entityAccessSetter } from '../../../../logic/utilities/accessSetter';

class Operations extends React.Component {
	constructor() {
		super();

		this.state = {
			manageUserAccess: false,
			readUserAccess: false,
			createUserAccess: false,
			updateUserAccess: false,
			deleteUserAccess: false,

			manageOrganisationAccess: false,
			readOrganisationAccess: false,
			createOrganisationAccess: false,
			updateOrganisationAccess: false,
			deleteOrganisationAccess: false,

			manageStudentAccess: false,
			readStudentAccess: false,
			createStudentAccess: false,
			updateStudentAccess: false,
			deleteStudentAccess: false,

			manageVehicleAccess: false,
			readVehicleAccess: false,
			createVehicleAccess: false,
			updateVehicleAccess: false,
			deleteVehicleAccess: false,

			manageVehicleGrpAccess: false,
			readVehicleGrpAccess: false,
			createVehicleGrpAccess: false,
			updateVehicleGrpAccess: false,
			deleteVehicleGrpAccess: false,

			manageSimAccess: false,
			readSimAccess: false,
			createSimAccess: false,
			updateSimAccess: false,
			deleteSimAccess: false,

			isSuperAdminAccess: false,

			manageAlertAccess: false,
			readAlertAccess: false,
			createAlertAccess: false,
			updateAlertAccess: false,
			deleteAlertAccess: false,

			manageDeviceModelAccess: false,
			readDeviceModelAccess: false,
			createDeviceModelAccess: false,
			updateDeviceModelAccess: false,
			deleteDeviceModelAccess: false,

			manageDeviceAccess: false,
			readDeviceAccess: false,
			createDeviceAccess: false,
			updateDeviceAccess: false,
			deleteDeviceAccess: false,

			activeIndex: -1,
			expandedAccordion: new Set(),
		};
		this.manageAccessSetter = this.manageAccessSetter.bind(this);
		// this.manageVehicleAccessSetter = this.manageVehicleAccessSetter.bind(this);
	}

	handleClick = (title) => {
		let expandedAccordion = this.state.expandedAccordion;
		if (expandedAccordion.has(title)) {
			expandedAccordion.delete(title);
		} else expandedAccordion.add(title);

		this.setState({
			expandedAccordion,
		});
	};

	manageAccessSetter(rule, action) {
		this.setState({ [action]: rule });
	}

	componentDidMount() {
		const permissions = JSON.parse(localStorage.getItem('permissions'));
		const isSuperAdmin = JSON.parse(localStorage.getItem('isSuperAdmin'));

		if (isSuperAdmin) {
			this.setState({ isSuperAdminAccess: isSuperAdmin });
		}

		const userPermissions = permissions?.filter((permission) => permission.subject === 'User');
		if (userPermissions) {
			for (const userPrm of userPermissions) {
				entityAccessSetter(userPrm?.action, 'User', this.manageAccessSetter);
			}
		}
		const vehiclePermissions = permissions?.filter((permission) => permission.subject === 'Vehicle');
		if (vehiclePermissions) {
			for (const vehiclePrm of vehiclePermissions) {
				entityAccessSetter(vehiclePrm?.action, 'Vehicle', this.manageAccessSetter);
			}
		}
		const studentPermission = permissions?.filter((permissions) => permissions.subject === 'Student');
		if (studentPermission) {
			for (const studentPrm of studentPermission) {
				entityAccessSetter(studentPrm?.action, 'Student', this.manageAccessSetter);
			}
		}

		const organisationPermission = permissions?.filter((permissions) => permissions.subject === 'Organisation');
		if (organisationPermission) {
			for (const organisationPrm of organisationPermission) {
				entityAccessSetter(organisationPrm?.action, 'Organisation', this.manageAccessSetter);
			}
		}

		const devicePermission = permissions?.filter((permissions) => permissions.subject === 'Device And Sim');
		if (devicePermission) {
			for (const devicePrm of devicePermission) {
				entityAccessSetter(devicePrm?.action, 'Device', this.manageAccessSetter);
			}
		}

		const vehicleGrpPermission = permissions?.filter((permissions) => permissions.subject === 'VehicleGroup');
		if (vehicleGrpPermission) {
			for (const vehicleGrpPrm of vehicleGrpPermission) {
				entityAccessSetter(vehicleGrpPrm?.action, 'VehicleGrp', this.manageAccessSetter);
			}
		}

		// const simPermission = permissions?.filter((permissions) => permissions.subject === 'Sim');
		// if (simPermission) {
		// 	for (const simPrm of simPermission) {
		// 		entityAccessSetter(simPrm?.action, 'Sim', this.manageAccessSetter);
		// 	}
		// }

		const alertPermission = permissions?.filter((permissions) => permissions.subject === 'Alert');
		if (alertPermission) {
			for (const alertPrm of alertPermission) {
				entityAccessSetter(alertPrm?.action, 'Alert', this.manageAccessSetter);
			}
		}

		const deviceModelPermission = permissions?.filter((permissions) => permissions.subject === 'Device And Sim');
		if (deviceModelPermission) {
			for (const deviceModelPrm of deviceModelPermission) {
				entityAccessSetter(deviceModelPrm?.action, 'DeviceModel', this.manageAccessSetter);
			}
		}
	}

	render() {
		const { sidebarExpanded } = this.props;
		const url = this.props.match.url;

		return (
			<Container>
				{/*Navigation*/}
				<CategorySidebar width={sidebarExpanded ? SIDEBAR_EXPANDED_WIDTH : SIDEBAR_COLLAPSED_WIDTH}>
					<SidebarHeader imageSource={OperationsImage} alt="Operations" name="Operations" expanded={sidebarExpanded} />
					<SidebarComponentList>
						<div
							style={{
								opacity: `${!(this.state.manageVehicleAccess || this.state.readVehicleAccess) ? '.4' : '1'}`,
								pointerEvents: `${!(this.state.manageVehicleAccess || this.state.readVehicleAccess) ? 'none' : ''}`,
							}}>
							<SidebarListItem
								style={{ backgroundColor: 'red' }}
								path={`${url}/vehicles`}
								// exact={true}
								iconName="truck"
								name="Vehicles"
								expanded={sidebarExpanded}
								background={this.state.expandedAccordion.has('users') ? `${COLORS.sidebarAccordionBackground}` : ''}
							/>
						</div>

						{/*<Accordion>*/}
						{/*<Accordion.Title style={{ padding: 0 }}>*/}
						{/*<SidebarList>*/}
						<div
							style={{
								opacity: `${!(this.state.manageUserAccess || this.state.readUserAccess) ? '.4' : '1'}`,
								pointerEvents: `${!(this.state.manageUserAccess || this.state.readUserAccess) ? 'none' : ''}`,
							}}>
							<SidebarListItem
								path={`${url}/users`}
								iconName="user"
								name="Users"
								expanded={sidebarExpanded}
								background={this.state.expandedAccordion.has('users') ? `${COLORS.sidebarAccordionBackground}` : ''}
							/>
						</div>

						{/*<IconContainer*/}
						{/*	active={this.props.location.pathname === '/operations/users'}*/}
						{/*	expanded={this.state.expandedAccordion.has('users')}>*/}
						{/*	<Icon*/}
						{/*		name={this.state.expandedAccordion.has('users') ? 'minus circle' : 'plus circle'}*/}
						{/*		onClick={(e) => {*/}
						{/*			e.stopPropagation();*/}
						{/*			this.handleClick('users');*/}
						{/*		}}*/}
						{/*	/>*/}
						{/*</IconContainer>*/}
						{/*</SidebarList>*/}
						{/*</Accordion.Title>*/}
						{/*<Accordion.Content active={this.state.expandedAccordion.has('users')}>*/}
						<div
							style={{
								opacity: `${!(this.state.manageStudentAccess || this.state.readStudentAccess) ? '.4' : '1'}`,
								pointerEvents: `${!(this.state.manageStudentAccess || this.state.readStudentAccess) ? 'none' : ''}`,
							}}>
							<SidebarListItem
								path={`${url}/students`}
								iconName="users"
								name="Students"
								expanded={sidebarExpanded}
								background={this.state.expandedAccordion.has('users') ? `${COLORS.sidebarAccordionBackground}` : ''}
							/>
						</div>
						{/*</Accordion.Content>*/}
						{/*</Accordion>*/}

						{/*<Accordion>*/}
						{/*	<Accordion.Title style={{ padding: 0 }}>*/}
						{/*<SidebarList>*/}
						<div
							style={{
								opacity: `${!(this.state.manageOrganisationAccess || this.state.readOrganisationAccess) ? '.4' : '1'}`,
								pointerEvents: `${
									!(this.state.manageOrganisationAccess || this.state.readOrganisationAccess) ? 'none' : ''
								}`,
							}}>
							<SidebarListItem
								path={`${url}/organisations`}
								iconName="sitemap"
								name="Organisations"
								expanded={sidebarExpanded}
								background={
									this.state.expandedAccordion.has('organisations') ? `${COLORS.sidebarAccordionBackground}` : ''
								}
							/>
						</div>
						{/*{sidebarExpanded && (*/}
						{/*	<IconContainer*/}
						{/*		active={this.props.location.pathname === '/operations/organisations'}*/}
						{/*		expanded={this.state.expandedAccordion.has('organisations')}>*/}
						{/*		<Icon*/}
						{/*			name={this.state.expandedAccordion.has('organisations') ? 'minus circle' : 'plus circle'}*/}
						{/*			onClick={(e) => {*/}
						{/*				e.stopPropagation();*/}
						{/*				this.handleClick('organisations');*/}
						{/*			}}*/}
						{/*		/>*/}
						{/*	</IconContainer>*/}
						{/*)}*/}
						{/*</SidebarList>*/}
						{/*</Accordion.Title>*/}
						{/*<Accordion.Content active={this.state.expandedAccordion.has('organisations')}>*/}
						<div
							style={{
								opacity: `${!(this.state.manageVehicleGrpAccess || this.state.readVehicleGrpAccess) ? '.4' : '1'}`,
								pointerEvents: `${
									!(this.state.manageVehicleGrpAccess || this.state.readVehicleGrpAccess) ? 'none' : ''
								}`,
							}}>
							<SidebarListItem
								path={`${url}/vehicle-groups`}
								iconName="truck"
								name="Vehicle Groups"
								expanded={sidebarExpanded}
								// accordionChild
								background={
									this.state.expandedAccordion.has('organisations') ? `${COLORS.sidebarAccordionBackground}` : ''
								}
							/>
						</div>
						{/*</Accordion.Content>*/}
						{/*</Accordion>*/}

						<Accordion>
							<Accordion.Title style={{ padding: 0 }}>
								<SidebarList
									style={{
										display: `${!this.state.isSuperAdminAccess ? 'none' : 'flex'}`,
									}}>
									<SidebarListItem
										path={`${url}/devices`}
										iconName="microchip"
										name="Devices"
										expanded={sidebarExpanded}
										background={
											this.state.expandedAccordion.has('devices') ? `${COLORS.sidebarAccordionBackground}` : ''
										}
									/>
									{sidebarExpanded && (
										<IconContainer
											active={this.props.location.pathname === '/operations/devices'}
											expanded={this.state.expandedAccordion.has('devices')}>
											<Icon
												name={this.state.expandedAccordion.has('devices') ? 'minus circle' : 'plus circle'}
												onClick={(e) => {
													e.stopPropagation();
													this.handleClick('devices');
												}}
											/>
										</IconContainer>
									)}
								</SidebarList>
							</Accordion.Title>
							<Accordion.Content active={this.state.expandedAccordion.has('devices')}>
								<div
									style={{
										opacity: `${
											!(this.state.manageDeviceModelAccess || this.state.readDeviceModelAccess) ? '.4' : '1'
										}`,
										pointerEvents: `${
											!(this.state.manageDeviceModelAccess || this.state.readDeviceModelAccess) ? 'none' : ''
										}`,
									}}>
									<SidebarListItem
										path={`${url}/device-models`}
										iconName="microchip"
										name="Device Models"
										expanded={sidebarExpanded}
										accordionChild
										background={
											this.state.expandedAccordion.has('devices') ? `${COLORS.sidebarAccordionBackground}` : ''
										}
									/>
								</div>
							</Accordion.Content>
						</Accordion>
						<Accordion>
							<Accordion.Title style={{ padding: 0 }}>
								<SidebarList
									style={{
										display: `${!this.state.isSuperAdminAccess ? 'none' : 'flex'}`,
									}}>
									<SidebarListItem
										path={`${url}/sims`}
										iconName="signal"
										name="Sims"
										expanded={sidebarExpanded}
										background={this.state.expandedAccordion.has('sims') ? `${COLORS.sidebarAccordionBackground}` : ''}
									/>
									{/*} {sidebarExpanded && (
                    <IconContainer
                      active={this.props.location.pathname === '/operations/sims'}
                      expanded={this.state.expandedAccordion.has('sims')}>
                      <Icon
                        name={this.state.expandedAccordion.has('sims') ? 'minus circle' : 'plus circle'}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.handleClick('sims');
                        }}
                      />
                    </IconContainer>
                  )} */}
								</SidebarList>
							</Accordion.Title>
							<Accordion.Content active={this.state.expandedAccordion.has('sims')}>
								<SidebarListItem
									path={`${url}/sim-plans`}
									iconName="signal"
									name="Sim Plans"
									expanded={sidebarExpanded}
									accordionChild
									background={this.state.expandedAccordion.has('sims') ? `${COLORS.sidebarAccordionBackground}` : ''}
								/>
							</Accordion.Content>
						</Accordion>

						<div
							style={{
								opacity: `${!(this.state.manageAlertAccess || this.state.readAlertAccess) ? '.4' : '1'}`,
								pointerEvents: `${!(this.state.manageAlertAccess || this.state.readAlertAccess) ? 'none' : ''}`,
							}}>
							<SidebarListItem
								path={`${url}/alerts`}
								exact={true}
								iconName="alarm"
								name="Alerts"
								expanded={sidebarExpanded}
							/>
						</div>

						{/*<Accordion>*/}
						{/*	<Accordion.Title style={{ padding: 0 }}>*/}
						{/*		<SidebarList>*/}
						{/*			<SidebarListItem*/}
						{/*				path={`${url}/vehicle-documents`}*/}
						{/*				iconName="file alternate"*/}
						{/*				name="Vehicle Documents"*/}
						{/*				expanded={sidebarExpanded}*/}
						{/*				background={*/}
						{/*					this.state.expandedAccordion.has('vehicleDocuments') ? `${COLORS.sidebarAccordionBackground}` : ''*/}
						{/*				}*/}
						{/*			/>*/}
						{/*			{sidebarExpanded && (*/}
						{/*				<IconContainer*/}
						{/*					active={this.props.location.pathname === '/operations/vehicleDocuments'}*/}
						{/*					expanded={this.state.expandedAccordion.has('vehicleDocuments')}>*/}
						{/*					<Icon*/}
						{/*						name={this.state.expandedAccordion.has('vehicleDocuments') ? 'minus circle' : 'plus circle'}*/}
						{/*						onClick={(e) => {*/}
						{/*							e.stopPropagation();*/}
						{/*							this.handleClick('vehicleDocuments');*/}
						{/*						}}*/}
						{/*					/>*/}
						{/*				</IconContainer>*/}
						{/*			)}*/}
						{/*		</SidebarList>*/}
						{/*	</Accordion.Title>*/}
						{/*	<Accordion.Content active={this.state.expandedAccordion.has('vehicleDocuments')}>*/}
						{/*		<SidebarListItem*/}
						{/*			path={`${url}/user-documents`}*/}
						{/*			iconName="file alternate"*/}
						{/*			name="User Documents"*/}
						{/*			expanded={sidebarExpanded}*/}
						{/*			accordionChild*/}
						{/*			background={*/}
						{/*				this.state.expandedAccordion.has('vehicleDocuments') ? `${COLORS.sidebarAccordionBackground}` : ''*/}
						{/*			}*/}
						{/*		/>*/}
						{/*	</Accordion.Content>*/}
						{/*</Accordion>*/}

						{/*<SidebarListItem*/}
						{/*  path={`${url}/vehicle-documents`}*/}
						{/*  exact={true}*/}
						{/*  iconName="file alternate"*/}
						{/*  name="Vehicle Documents"*/}
						{/*  expanded={sidebarExpanded}*/}
						{/*/>*/}

						{/* <Accordion>
               <Accordion.Title style={{padding: 0}}>
                <SidebarList>
                  <SidebarListItem
                    path={`${url}/commands-pipeline`}
                    iconName="history"
                    name="Commands Pipeline"
                    expanded={sidebarExpanded}
                    background={
                      this.state.expandedAccordion.has('commands-pipeline')
                        ? `${COLORS.sidebarAccordionBackground}`
                        : ''
                    }
                  />
                  {sidebarExpanded && (
                    <IconContainer
                      active={this.props.location.pathname === '/operations/commands-pipeline'}
                      expanded={this.state.expandedAccordion.has('commands-pipeline')}>
                      <Icon
                        name={this.state.expandedAccordion.has('commands-pipeline') ? 'minus circle' : 'plus circle'}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.handleClick('commands-pipeline');
                        }}
                      />
                    </IconContainer>
                  )}
                </SidebarList>
              </Accordion.Title>
              <Accordion.Content active={this.state.expandedAccordion.has('commands-pipeline')}>
                <SidebarListItem
                  path={`${url}/device-commands`}
                  iconName="terminal"
                  name="Device Commands"
                  expanded={sidebarExpanded}
                  accordionChild
                  background={
                    this.state.expandedAccordion.has('commands-pipeline') ? `${COLORS.sidebarAccordionBackground}` : ''
                  }
                />
              </Accordion.Content>
            </Accordion>

            <SidebarListItem
              path={`${url}/sms-report`}
              iconName="envelope"
              name="Sms Report"
              expanded={sidebarExpanded}
            /> */}
					</SidebarComponentList>
					<SidebarToggleIcon
						title={sidebarExpanded ? 'Collapse Sidebar' : 'Expand Sidebar'}
						name={sidebarExpanded ? 'chevron circle left' : 'chevron circle right'}
						handleClick={sidebarExpanded ? collapseSidebar : expandSidebar}
					/>
				</CategorySidebar>
				<CategoryContent
					width={
						sidebarExpanded ? `calc(100% - ${SIDEBAR_EXPANDED_WIDTH})` : `calc(100% - ${SIDEBAR_COLLAPSED_WIDTH})`
					}>
					<Switch>
						<Route exact path={`${url}`}>
							<Redirect to={`${url}/vehicles`} />
						</Route>
						<Route path={`${url}/vehicles`} component={Vehicles} />
						<Route path={`${url}/devices`} component={Devices} />
						<Route path={`${url}/device-models`} component={DeviceModels} />
						<Route path={`${url}/sims`} component={SIM} />
						{/* <Route path={`${url}/sim-plans`} component={SIMPlans} /> */}
						<Route path={`${url}/vehicle-groups`} component={VehicleGroups} />
						<Route path={`${url}/organisations`} component={Organisations} />
						<Route path={`${url}/users`} component={Users} />
						<Route path={`${url}/students`} component={Students} />
						{/* <Route path={`${url}/sms-report`} component={SMSReport} />
            <Route path={`${url}/device-commands`} component={DeviceCommands} />
            <Route path={`${url}/commands-pipeline`} component={CommandsPipeline} /> */}
						<Route path={`${url}/alerts`} component={Alerts} />
					</Switch>
				</CategoryContent>
			</Container>
		);
	}
}

// Connect to Store
const mapStateToProps = (state) => ({
	sidebarExpanded: state.ui.sidebarExpanded,
});

export default connect(mapStateToProps)(Operations);
