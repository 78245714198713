export function validateImeiNumber(params) {
	if (params !== null) {
		return params ? params.toString().length === 15 && /^-?\d+$/.test(params) : true;
	} else return true;
}

export function validateSimNumber(params) {
	if (params !== null)
		return params
			? (params.toString().length === 19 || params.toString().length === 20) && /^-?\d+$/.test(params)
			: true;
	else return true;
}

export function validateNumber(params) {
	if (params !== null) return params ? /^-?\d+$/.test(params) : true;
	else return true;
}

export function validateNumericValue(params) {
	if (params !== null) return params ? /^[1-9]\d*(\.\d+)?$/.test(params) : true;
	else return true;
}

export function validateSimMobileNumber(params) {
	if (params !== null) return params ? params.toString().length === 13 && /^-?\d+$/.test(params) : true;
	else return true;
}

export function capitalizeWord(words) {
	words = words.toLowerCase().split(' ');

	words = words.map((word) => {
		return word.charAt(0).toUpperCase() + word.slice(1);
	});
	return words.join(' ');
}

export function validateMobileNumber(params) {
	if (params !== null) return params ? params.toString().length === 10 && /^-?\d+$/.test(params) : true;
	else return true;
}

export function validateEmail(params) {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return params ? re.test(String(params).toLowerCase()) : true;
}

export function validateWebsite(params) {
	const regexp =
		/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
	return params ? regexp.test(params) : true;
}

export function validateIsEmpty(params) {
	return !!params;
}
