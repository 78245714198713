export default function students(
	initialState = {
		students: [],
		loading: true,
		bulkimportLoading: false,
		activeIndex: -1,
		pageNumber: 1,
		count: 0,
		deleting: false,
		deleteModalOpen: false,
		activeSort: '',
		sortOrder: '',
		adding: false,
		addModalOpen: false,
		updating: false,
		editMode: false,
		passwordModalOpen: false,
		vehicleGroupModal: false,
		vehicleModal: false,
		removeVehicleModal: false,
		submitting: false,
		passwordVisible: false,
		restoreModal: false,
		restoring: false,
		openSendVerificationModal: false,
		sending: false,
		vehicleMappingModal: false,
		mapping: false,
		vehicleUnmappingModal: false,
		importModalOpen: false,
		unmapping: false,
		filterModalOpen: false,

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
	},
	action
) {
	switch (action.type) {
		case 'FEED_STUDENTS':
			return {
				...initialState,
				students: action.payload,
				loading: false,
			};

		case 'STUDENTS_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'STUDENTS_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'STUDENTS_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'STUDENTS_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'STUDENTS_IMPORT_MODAL_OPEN':
			return {
				...initialState,
				importModalOpen: true,
			};

		case 'STUDENTS_IMPORT_MODAL_CLOSE':
			return {
				...initialState,
				importModalOpen: false,
			};

		case 'STUDENTS_DELETING':
			return {
				...initialState,
				deleting: true,
			};

		case 'STUDENTS_DELETED':
			return {
				...initialState,
				deleting: false,
			};

		case 'STUDENTS_DELETE_MODAL_OPEN':
			return {
				...initialState,
				deleteModalOpen: true,
			};

		case 'STUDENTS_DELETE_MODAL_CLOSE':
			return {
				...initialState,
				deleteModalOpen: false,
			};

		case 'STUDENTS_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'STUDENTS_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'STUDENTS_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'STUDENTS_ADDING':
			return {
				...initialState,
				adding: true,
			};

		case 'STUDENTS_ADDED':
			return {
				...initialState,
				adding: false,
			};

		case 'STUDENTS_ADD_MODAL_OPEN':
			return {
				...initialState,
				addModalOpen: true,
			};

		case 'STUDENTS_ADD_MODAL_CLOSE':
			return {
				...initialState,
				addModalOpen: false,
			};

		case 'STUDENTS_UPDATING':
			return {
				...initialState,
				updating: true,
			};

		case 'STUDENTS_UPDATED':
			return {
				...initialState,
				updating: false,
			};

		case 'STUDENTS_EDIT_MODAL_OPEN':
			return {
				...initialState,
				editMode: true,
			};

		case 'STUDENTS_EDIT_MODAL_CLOSE':
			return {
				...initialState,
				editMode: false,
			};

		case 'STUDENTS_PASSWORD_SUBMITING':
			return {
				...initialState,
				submitting: true,
			};

		case 'STUDENTS_PASSWORD_SUBMITED':
			return {
				...initialState,
				submiting: false,
			};

		case 'STUDENTS_PASSWORD_MODAL_OPEN':
			return {
				...initialState,
				passwordModalOpen: true,
			};

		case 'STUDENTS_PASSWORD_MODAL_CLOSE':
			return {
				...initialState,
				passwordModalOpen: false,
			};

		case 'STUDENTS_PASSWORD_VISIBLE':
			return {
				...initialState,
				passwordVisible: true,
			};

		case 'STUDENTS_PASSWORD_NOT_VISIBLE':
			return {
				...initialState,
				passwordVisible: false,
			};

		case 'STUDENTS_RESTORE_MODAL_OPEN':
			return {
				...initialState,
				restoreModal: true,
			};

		case 'STUDENTS_RESTORE_MODAL_CLOSE':
			return {
				...initialState,
				restoreModal: false,
			};

		case 'STUDENTS_VG_MODAL_OPEN':
			return {
				...initialState,
				vehicleGroupModal: true,
			};

		case 'STUDENTS_VG_MODAL_CLOSE':
			return {
				...initialState,
				vehicleGroupModal: false,
			};

		case 'STUDENTS_VEHICLE_MODAL_OPEN':
			return {
				...initialState,
				vehicleModal: true,
			};

		case 'STUDENTS_VEHICLE_MODAL_CLOSE':
			return {
				...initialState,
				vehicleModal: false,
			};

		case 'STUDENTS_REMOVE_VEHICLE_MODAL_OPEN':
			return {
				...initialState,
				removeVehicleModal: true,
			};

		case 'STUDENTS_REMOVE_VEHICLE_MODAL_CLOSE':
			return {
				...initialState,
				removeVehicleModal: false,
			};

		case 'STUDENTS_RESTORING':
			return {
				...initialState,
				restoring: true,
			};

		case 'STUDENTS_RESTORED':
			return {
				...initialState,
				restoring: false,
			};

		case 'STUDENTS_FILTER_MODAL_OPEN':
			return {
				...initialState,
				filterModalOpen: true,
			};

		case 'STUDENTS_FILTER_MODAL_CLOSE':
			return {
				...initialState,
				filterModalOpen: false,
			};

		case 'STUDENTS_SEND_VERIFICATION_MODAL_OPEN':
			return {
				...initialState,
				openSendVerificationModal: true,
			};

		case 'STUDENTS_SEND_VERIFICATION_MODAL_CLOSE':
			return {
				...initialState,
				openSendVerificationModal: false,
			};

		case 'STUDENTS_VERIFICATION_LINK_SENDING':
			return {
				...initialState,
				sending: true,
			};

		case 'STUDENTS_VERIFICATION_LINK_SENDED':
			return {
				...initialState,
				sending: false,
			};

		case 'STUDENTS_VEHICLE_MAPPING_MODAL_OPEN':
			return {
				...initialState,
				vehicleMappingModal: true,
			};

		case 'STUDENTS_VEHICLE_MAPPING_MODAL_CLOSE':
			return {
				...initialState,
				vehicleMappingModal: false,
			};

		case 'STUDENTS_MAPPING':
			return {
				...initialState,
				mapping: true,
			};

		case 'STUDENTS_MAPPED':
			return {
				...initialState,
				mapping: false,
			};

		case 'STUDENTS_VEHICLE_UNMAPPING_MODAL_OPEN':
			return {
				...initialState,
				vehicleUnmappingModal: true,
			};

		case 'STUDENTS_VEHICLE_UNMAPPING_MODAL_CLOSE':
			return {
				...initialState,
				vehicleUnmappingModal: false,
			};

		case 'STUDENTS_UNMAPPING':
			return {
				...initialState,
				unmapping: true,
			};

		case 'STUDENTS_UNMAPPED':
			return {
				...initialState,
				unmapping: false,
			};

		case 'STUDENTS_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'STUDENTS_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_STUDENTS_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_STUDENTS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_STUDENTS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'STUDENTS_UNMOUNT':
			return {
				...initialState,
				students: [],
				activeSort: '',
				sortOrder: '',
				activeIndex: -1,
				pageNumber: 1,
			};
		case 'BULK_IMPORT_LOAD':
			return {
				...initialState,
				bulkimportLoading: action.payload,
			};
		default:
			return initialState;
	}
}
