import React from 'react';

import styled from 'styled-components';

import { Loader } from 'semantic-ui-react';

export default function TransitionLoader() {
	return (
		<Container>
			<Loader active inline />
		</Container>
	);
}

export const Container = styled.section`
	box-sizing: border-box;
	padding: 3em 4em;

	position: absolute;

	background: rgba(255, 255, 255, 0.8);
	border-radius: 10px;

	span {
		font-size: 2em;
		font-weight: bold;
		color: #444;
	}
`;
