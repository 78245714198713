import React, { useState } from 'react';
import { logout } from '../../../../../logic/middleware/auth';

import { Container } from './SettingsMenuStyles';
import styles from './SettingsMenu.module.css';
import { Button, Form, Icon, Input, Label, Menu, Modal, Popup } from 'semantic-ui-react';
import { handleError, handleSuccess } from '../../../../../logic/middleware/messaging';
import axios from 'axios';
import { CHANGE_PASSWORD_API } from '../../../../../logic/configs/api';

const SettingsMenu = () => {
	const [changePassword, setChangePassword] = useState(false);
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [newPassword, setNewPassword] = useState();
	const [passwordChanging, setPasswordChanging] = useState(false);

	const cleanupChangePassword = () => {
		setChangePassword(false);
		setNewPassword('');
		setPasswordVisible(false);
		setPasswordChanging(false);
	};
	const handleChangePassword = () => {
		setPasswordChanging(true);
		let query = {
			password: newPassword,
		};

		axios
			.post(CHANGE_PASSWORD_API + `/${localStorage.getItem('userId')}/change-password`, query, {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('token'),
					'Content-Type': 'application/json',
				},
			})
			.then((response) => {
				cleanupChangePassword();
				handleSuccess(response);
				logout();
			})
			.catch((error) => {
				handleError(error);
				setPasswordChanging(false);
			});
	};

	// function Header({ title }) {
	// 	return (
	// 		<div className={styles.header}>
	// 			<span className={styles.headerTitle}>{title}</span>
	// 		</div>
	// 	);
	// }

	function Item({ icon, title, text, subText, callback, color = 'black' }) {
		return (
			<li className={styles.listItem} onClick={callback === undefined ? undefined : callback}>
				{icon ? (
					<span className={styles.itemIcon} style={{ color: 'black' }}>
						<Icon name={icon} />
					</span>
				) : null}
				<div
					style={{
						display: 'flex',
						flexDirection: title && text ? 'row' : 'column',
					}}>
					{title ? <span className={styles.itemTitle}>{title}</span> : null}
					{text ? (
						<span style={{ color: color }} className={styles.itemText}>
							{text}
						</span>
					) : null}
					{subText ? <span className={styles.itemSubText}>{subText}</span> : null}
				</div>
			</li>
		);
	}

	return (
		<div>
			<Container>
				<Popup
					style={{ padding: '0' }}
					trigger={
						<Label
							style={{ fontSize: '1.2rem', backgroundColor: '#1b3c71', color: '#fff', cursor: 'pointer' }}
							circular>
							{localStorage.getItem('name')?.[0]}
						</Label>
					}
					closeOnPortalMouseLeave={false}
					position="bottom right"
					on="click"
					flowing
					basic
					hoverable>
					<Menu vertical size={'massive'}>
						<Menu.Item>
							<Menu.Header>Profile</Menu.Header>
							<Menu.Menu>
								<Menu.Item>
									<Item
										title={'Name:'}
										text={localStorage.getItem('name') ? localStorage.getItem('name') : ''}
										icon="user outline"
									/>
								</Menu.Item>
								<Menu.Item>
									<Item
										title="Phone:"
										text={localStorage.getItem('phone') ? localStorage.getItem('phone') : ''}
										icon="mobile alternate"
										color={localStorage.getItem('phoneVerified') === 'true' ? 'green' : 'red'}
									/>
								</Menu.Item>
								<Menu.Item>
									<Item
										text={localStorage.getItem('email') ? localStorage.getItem('email') : ''}
										title="Email:"
										icon="envelope outline"
										color={localStorage.getItem('emailVerified') === 'true' ? 'green' : 'red'}
									/>
								</Menu.Item>
								<Menu.Item>
									<Item
										title="Role:"
										text={localStorage.getItem('roleName') ? localStorage.getItem('roleName') : ''}
										icon="user outline"
									/>
								</Menu.Item>
								<Menu.Item>
									<Item
										title="Organization:"
										text={localStorage.getItem('organisationName') ? localStorage.getItem('organisationName') : ''}
										icon="globe"
									/>
								</Menu.Item>
							</Menu.Menu>
						</Menu.Item>
						<Menu.Item>
							<Menu.Header>Security</Menu.Header>
							<Menu.Menu>
								<Menu.Item>
									<Item
										text={'Reset Password'}
										subText="Set New Password"
										icon="lock"
										callback={() => setChangePassword(true)}
									/>
								</Menu.Item>
								<Menu.Item>
									<Item text={'Logout'} callback={logout} icon="sign out" />
								</Menu.Item>
							</Menu.Menu>
						</Menu.Item>
					</Menu>
				</Popup>
			</Container>
			{changePassword && (
				<Modal size="mini" onClose={cleanupChangePassword} onOpen={() => setChangePassword(true)} open={changePassword}>
					<Modal.Header>Change Password</Modal.Header>
					<Modal.Content>
						<Form>
							<Form.Group widths="equal">
								<Form.Field>
									<label>New Password</label>
									<Input
										fluid
										icon={
											<Icon
												name={passwordVisible ? 'eye' : 'eye slash'}
												link
												onClick={() => setPasswordVisible(!passwordVisible)}
											/>
										}
										type={passwordVisible ? 'text' : 'password'}
										onChange={(e) => setNewPassword(e.target.value)}
									/>
								</Form.Field>
							</Form.Group>
						</Form>
					</Modal.Content>
					<Modal.Actions>
						<Button onClick={cleanupChangePassword}>Cancel</Button>
						<Button primary disabled={!newPassword} loading={passwordChanging} onClick={handleChangePassword}>
							Submit
						</Button>
					</Modal.Actions>
				</Modal>
			)}
		</div>
	);
};

export default SettingsMenu;
