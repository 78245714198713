import React from 'react';
import CommonModal from '../../../molecules/modal/CommonModal';
import { Button, Dropdown, Form } from 'semantic-ui-react';
import { TimeInput } from 'semantic-ui-calendar-react';

const AddModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			header="Add Student"
			// size="medium"
			content={
				<Form>
					<Form.Group widths="equal">
						<Form.Field required={true}>
							<label>Name</label>
							<Form.Input
								fluid
								onChange={props.onNameChange}
								error={props.nameError ? 'Please enter a valid name' : false}
							/>
						</Form.Field>
						<Form.Field required={true}>
							<label>Organisation</label>
							<Form.Dropdown
								placeholder="Choose ..."
								fluid
								search
								selection
								clearable
								options={props.organisationOptions}
								onChange={props.onOrganisationChoose}
								error={props.organisationError ? 'Please select an organisation' : false}
							/>
						</Form.Field>
						<Form.Field>
							<label>Parents</label>
							<Dropdown
								fluid
								search
								selection
								multiple
								clearable
								disabled={props.selectedOrganisation === null}
								value={props.selectedParent}
								options={props.parentsOptions}
								onChange={props.onParentChoose}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field required={true}>
							<label>Pickup Bus</label>
							<Form.Dropdown
								placeholder="Choose ..."
								fluid
								search
								selection
								clearable
								//disabled={props.selectedOrganisation === null}
								options={props.pickupBusOptions}
								onChange={props.onPickupBusChange}
								error={props.busError ? 'Please select a bus' : false}
							/>
						</Form.Field>
						<Form.Field>
							<label>Pickup Point</label>
							<Dropdown
								placeholder="Choose ..."
								fluid
								search
								selection
								clearable
								disabled={props.selectedOrganisation === null}
								options={props.pickupPointOptions}
								onChange={props.onPickupPointChange}
							/>
						</Form.Field>
						<Form.Field>
							<label>Pickup Time</label>
							<TimeInput
								name="pickupTimeFA"
								placeholder={props.pickupTime}
								value={props.pickupTime}
								iconPosition="left"
								closable
								clearable
								onChange={props.handleActiveTimeChange}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field>
							<label>Drop Bus</label>
							<Dropdown
								placeholder="Choose ..."
								fluid
								search
								selection
								clearable
								disabled={props.selectedOrganisation === null}
								options={props.dropBusOptions}
								onChange={props.onDropBusChange}
							/>
						</Form.Field>
						<Form.Field>
							<label>Drop Point</label>
							<Dropdown
								placeholder="Choose ..."
								fluid
								search
								selection
								clearable
								disabled={props.selectedOrganisation === null}
								options={props.dropPointOptions}
								onChange={props.onDropPointChange}
							/>
						</Form.Field>
						<Form.Field>
							<label>Drop Time</label>
							<TimeInput
								name="dropTimeFA"
								placeholder={props.dropTime}
								value={props.dropTime}
								closable
								clearable
								iconPosition="left"
								onChange={props.handleActiveTimeChange}
							/>
						</Form.Field>
					</Form.Group>
				</Form>
			}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button positive loading={props.addButtonLoading} disabled={props.disabled} onClick={props.onAddButtonClick}>
						Add
					</Button>
				</>
			}
		/>
	);
};

export default AddModal;
