import styled from 'styled-components';

export const Container = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	.ui.input {
		width: 100%;
	}
`;
