import React from "react";
import CommonModal from "../../../molecules/modal/CommonModal";
import { Expander } from "../../../../styles/place-holders/Expander";
// import {
//   INDUSTRY_OPTIONS,
//   ORGANISATIONS_SOLUTIONS_OPTIONS,
//   PLATFORM_AWARENESS_OPTIONS,
//   PREFERRED_COMMUNICATION_CHANNEL_OPTIONS,
// } from '../../../../../../data/constants/dropdown-options';
import { Button, Dropdown, Form } from "semantic-ui-react";
import styled from "styled-components";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";
import { getStateForDropdown } from "../../../../../../logic/middleware/organisations";
import { EMAIL_VERIFIED_OPTIONS, PHONE_VERIFIED_OPTIONS } from "../../../../../../data/constants/dropdown-options";
// import { EMAIL_VERIFIED_OPTIONS, PHONE_VERIFIED_OPTIONS } from '../../../../../../data/constants/dropdown-options';

const FilterModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="tiny"
			header="Organisations Filters"
			scrolling
			content={
				<Form>
					<Form.Group widths="equal">
						<Form.Field>
							<label>State</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								multiple
								selection
								clearable
								value={props.state}
								options={getStateForDropdown()}
								onChange={props.onStateChange}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field>
							<label>City</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								multiple
								selection
								clearable
								value={props.city}
								disabled={!props?.state?.length}
								options={props.cityOptions}
								onChange={props.onCityChange}
							/>
						</Form.Field>
					</Form.Group>
					{/*<Form.Group widths="equal">*/}
					{/*  <Form.Field>*/}
					{/*    <label>Industry</label>*/}
					{/*    <Dropdown*/}
					{/*      placeholder="Choose..."*/}
					{/*      fluid*/}
					{/*      search*/}
					{/*      multiple*/}
					{/*      selection*/}
					{/*      value={props.industry}*/}
					{/*      options={INDUSTRY_OPTIONS}*/}
					{/*      onChange={props.onIndustryChange}*/}
					{/*    />*/}
					{/*  </Form.Field>*/}
					{/*</Form.Group>*/}
					{/*<Form.Group widths="equal">*/}
					{/*  <Form.Field>*/}
					{/*    <label>Solutions</label>*/}
					{/*    <Dropdown*/}
					{/*      placeholder="Choose..."*/}
					{/*      fluid*/}
					{/*      search*/}
					{/*      multiple*/}
					{/*      selection*/}
					{/*      value={props.solutions}*/}
					{/*      options={ORGANISATIONS_SOLUTIONS_OPTIONS}*/}
					{/*      onChange={props.onSolutionsChange}*/}
					{/*    />*/}
					{/*  </Form.Field>*/}
					{/*</Form.Group>*/}
					{/*<Form.Group widths="equal">*/}
					{/*  <Form.Field>*/}
					{/*    <label>Platform Awareness</label>*/}
					{/*    <Dropdown*/}
					{/*      placeholder="Choose..."*/}
					{/*      fluid*/}
					{/*      search*/}
					{/*      multiple*/}
					{/*      selection*/}
					{/*      value={props.platformAwareness}*/}
					{/*      options={PLATFORM_AWARENESS_OPTIONS}*/}
					{/*      onChange={props.onPlatformAwarenessChange}*/}
					{/*    />*/}
					{/*  </Form.Field>*/}
					{/*</Form.Group>*/}
					{/*<Form.Group widths="equal">*/}
					{/*  <Form.Field>*/}
					{/*    <label>Preferred Communication Channel</label>*/}
					{/*    <Dropdown*/}
					{/*      placeholder="Choose..."*/}
					{/*      fluid*/}
					{/*      search*/}
					{/*      multiple*/}
					{/*      selection*/}
					{/*      value={props.preferredCommunicationChannel}*/}
					{/*      options={PREFERRED_COMMUNICATION_CHANNEL_OPTIONS}*/}
					{/*      onChange={props.onPreferredCommunicationChannelChange}*/}
					{/*    />*/}
					{/*  </Form.Field>*/}
					{/*</Form.Group>*/}

					<Form.Group widths="equal">
						<Form.Field>
							<label>Email</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								selection
								multiple
								clearable
								value={props.email}
								options={EMAIL_VERIFIED_OPTIONS}
								onChange={props.onEmailFilterChange}
							/>
						</Form.Field>
					</Form.Group>

					<Form.Group widths="equal">
						<Form.Field>
							<label>Phone</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								selection
								multiple
								clearable
								value={props.phone}
								options={PHONE_VERIFIED_OPTIONS}
								onChange={props.onPhoneFilterChange}
							/>
						</Form.Field>
					</Form.Group>

					<Form.Group widths="equal">
						<Form.Field>
							<label>Onboarding Start Date</label>
							<DateInput
								name="startDate"
								placeholder="Start Date"
								value={props.startDate}
								dateFormat="DD-MM-YYYY"
								maxDate={moment()}
								icon="calendar alternate outline"
								iconPosition="left"
								onChange={props.handleChangeDate}
								closable
								clearable
								onClear={() => {
									props.handleClearDate();
								}}
							/>
						</Form.Field>
						<Form.Field>
							<label>Onboarding End Date</label>
							<DateInput
								name="endDate"
								placeholder="End Date"
								value={props.endDate}
								dateFormat="DD-MM-YYYY"
								disabled={!props.startDate}
								maxDate={moment()}
								minDate={props.startDate}
								icon="calendar alternate outline"
								iconPosition="left"
								onChange={props.handleChangeDate}
								closable
								clearable
							/>
						</Form.Field>
					</Form.Group>
				</Form>
			}
			actions={
				<>
					<FlexContainer>
						<Button onClick={props.onResetClick}>Reset</Button>
						<Expander />
						<Button color="black" onClick={props.onCancelClick}>
							Cancel
						</Button>
						<Button
							primary
							disabled={
								!props.state?.length &&
								!props.city?.length &&
								!props.email?.length &&
								!props.phone?.length &&
								(!props?.startDate || props?.endDate)
							}
							onClick={props.onApplyClick}>
							Apply
						</Button>
					</FlexContainer>
				</>
			}
		/>
	);
};

const FlexContainer = styled.section`
	display: flex;
`;

export default FilterModal;
