import React from "react";

import { DEVICE_MODELS_API } from "../../../../../logic/configs/api";

import BasicLoader from "../../../blocks/molecules/loader/BasicLoader";
import SearchBox from "../../../blocks/atoms/search-box/SearchBox";
import FooterPagination from "../../../blocks/molecules/pagination/Pagination";
import DeleteModal from "../../../blocks/organisms/modals/device-models/DeleteModal";
import RestoreModal from "../../../blocks/organisms/modals/device-models/RestoreModal";
import AddModal from "../../../blocks/organisms/modals/device-models/AddModal";
import ExportModal from "../../../blocks/organisms/modals/device-models/ExportModal";
import { CategoryHeader } from "../../../styles/containers/CategoryHeader";
import { Expander } from "../../../styles/place-holders/Expander";
import { CategoryMain } from "../../../styles/containers/CategoryMain";
import { AccordionHeader } from "../../../styles/containers/AccordionHeader";
import { AccordionMain } from "../../../styles/containers/AccordionMain";
import { CategoryFooter } from "../../../styles/containers/CategoryFooter";
import { CategoryContentMembrane } from "../../../styles/containers/CategoryContentMembrane";
import TransitionLoader from "../../../blocks/molecules/loader/TransitionLoader";
import ObdToggle from "../../../blocks/molecules/obd-toggle/obdToggle";

import {
  exportData,
  fetchProtocols,
  getProtocolsForDropdown,
  refreshDeviceModels,
  searchDeviceModels
} from "../../../../../logic/middleware/deviceModels";
import { dispatch, dispatchNoPayload } from "../../../../../logic/middleware/middleware";

import { generateSuccessMessage, handleError, handleSuccess } from "../../../../../logic/middleware/messaging";

import { connect } from "react-redux";

// import axios from 'axios';
// import { CheckboxContainer } from '../../../styles/Containers';
import { HeaderElement, Row, RowElement } from "../../../styles/Accordion";
import { Accordion, Button, Dropdown, Form, Icon, Input, Label } from "semantic-ui-react";
import { AccordionMainContent } from "../../../styles/containers/AccordionMainContent";
import PlaceHolder from "../../../blocks/molecules/place-holder/PlaceHolder";
import accessSetter from "../../../../../logic/utilities/accessSetter";
import { trackMixpanelEvent } from "../../../../../logic/middleware/mixpanel";
import { patchData, postData } from "../../../../../logic/middleware/services/apiRequest";

// import { FILE_TYPE_EXTENSIONS } from '../../../../../data/constants/file-types';

class DeviceModels extends React.Component {
	deviceModelID = '';

	searchQuery = '';
	timeoutId;

	deletedMode = false;
	selectAllActive = false;

	sortOrder = '';
	activeSort = '';

	constructor(props) {
		super(props);

		this.state = {
			initialLoading: true,
			manufacturerFE: null,
			modelFE: null,
			selectedProtocolFE: null,
			isOBDFE: null,
			manageAccess: false,
			readAccess: false,
			createAccess: false,
			updateAccess: false,
			deleteAccess: false,

			manufacturerFA: null,
			modelFA: null,
			protocols: [],
			selectedProtocol: null,
			addDisabled: true,

			anyRowSelected: false,
			selectedRows: new Set(),

			deletedMode: false,

			protocolList: [],

			fileName: 'Device Models',
			fileExtension: 'excel',
		};

		this.toggleDeletedMode = this.toggleDeletedMode.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.add = this.add.bind(this);
		this.clearAddOperation = this.clearAddOperation.bind(this);
		this.update = this.update.bind(this);
		/*this.cleanEditOperation = this.cleanEditOperation.bind(this);
    this.deleteDeviceModel = this.deleteDeviceModel.bind(this);
    this.cleanDeleteOperation = this.cleanDeleteOperation.bind(this);*/
		this.restoreDeviceModel = this.restoreDeviceModel.bind(this);
		this.cleanRestoreOperation = this.cleanRestoreOperation.bind(this);
		this.handleDataSizeLimitChange = this.handleDataSizeLimitChange.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.export = this.export.bind(this);
		this.cleanSelectedRows = this.cleanSelectedRows.bind(this);
		this.handleExportSelection = this.handleExportSelection.bind(this);

		this.getFilteredDeviceModels = this.getFilteredDeviceModels.bind(this);
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.sort = this.sort.bind(this);

		this.onManufacturerChange = this.onManufacturerChange.bind(this);
		this.onModelChange = this.onModelChange.bind(this);
		this.onProtocolChoose = this.onProtocolChoose.bind(this);
		this.clearProtocol = this.clearProtocol.bind(this);
		this.onOBDChange = this.onOBDChange.bind(this);

		this.fileExtensionChange = this.fileExtensionChange.bind(this);
		this.fileNameChange = this.fileNameChange.bind(this);
		this.manageAccessSetter = this.manageAccessSetter.bind(this);
	}

	manageAccessSetter(rule, action) {
		this.setState({ [action]: rule });
	}

	componentDidMount() {
		trackMixpanelEvent('Device Models', { Action: 'View' });
		const permissions = JSON.parse(localStorage.getItem('permissions'));
		const devicesModelPermissions = permissions?.filter((permission) => permission.subject === 'Device And Sim');
		if (devicesModelPermissions) {
			for (const deviceModelPrm of devicesModelPermissions) {
				accessSetter(deviceModelPrm?.action, this.manageAccessSetter);
			}
		}
		refreshDeviceModels();
		this.fetchProtocolList();
	}

	// Fetch
	getFilteredDeviceModels(activePage = 1, resetAccordion = true) {
		if (resetAccordion) {
			dispatch('DEVICE_MODELS_TOGGLE_ACCORDION', -1);
		}

		let query = '';
		if (this.searchQuery !== '') {
			query = query + searchDeviceModels(this.searchQuery);
		}

		if (this.activeSort !== '' && this.sortOrder !== '') {
			if (this.sortOrder === 'asc') {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			} else {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			}
		}

		if (this.deletedMode) {
			query = query + `&deleted=1`;
		}

		refreshDeviceModels(query, activePage);
	}

	fetchProtocolList(params = '') {
		fetchProtocols(params)
			.then((response) => {
				let protocols = response?.data;

				this.setState({
					protocolList: Object.assign({}, ...Object.entries(protocols).map(([key, value]) => ({ [value]: key }))),
				});
			})
			.catch((error) => {
				handleError(error);
			});
	}

	handleSearch(e) {
		this.searchQuery = e.target.value;
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}

		this.timeoutId = setTimeout(() => {
			this.getFilteredDeviceModels();
			this.cleanSelectedRows();
		}, 500);
	}

	toggleDeletedMode() {
		this.deletedMode = !this.deletedMode;
		this.setState({
			deletedMode: !this.state.deletedMode,
		});

		this.getFilteredDeviceModels();
		this.cleanSelectedRows();
	}

	fileNameChange(e) {
		this.setState({ fileName: e.target.value });
	}

	fileExtensionChange(e, { value }) {
		this.setState({ fileExtension: value });
	}

	handleExportSelection(checkedData, id) {
		let selectedRows = this.state.selectedRows;

		if (selectedRows.has(id)) {
			selectedRows.delete(id);
		} else selectedRows.add(id);

		this.setState({
			selectedRows: selectedRows,
		});

		this.setState({
			anyRowSelected: !!selectedRows.size,
		});
	}

	handleSelectAll(checked) {
		let selectedRows = new Set();

		if (checked) {
			this.selectAllActive = true;
			this.props.deviceModels.forEach((deviceModels) => selectedRows.add(deviceModels.id));
			this.setState({
				selectedRows: selectedRows,
			});
		} else {
			this.selectAllActive = false;
			this.setState({
				selectedRows: new Set(),
			});
		}

		this.setState({
			anyRowSelected: !!selectedRows.size,
		});
	}

	export() {
		const { deviceModels } = this.props;

		let deviceModelToExport = [];
		deviceModels.forEach((deviceModel) => {
			if (this.state.selectedRows.has(deviceModel.id)) {
				deviceModelToExport.push(deviceModel);
			}
		});

		return deviceModelToExport;
	}

	// Add
	add() {
		const { manufacturerFA, modelFA, selectedProtocol, isOBD } = this.state;
		let query = {};
		if (manufacturerFA)
			query = {
				manufacturer: manufacturerFA.trim(),
			};

		if (modelFA) query = { ...query, model: modelFA.trim() };

		if (selectedProtocol) query = { ...query, port: selectedProtocol };

		if (isOBD) query = { ...query, isOBD: isOBD };

		dispatchNoPayload('DEVICE_MODELS_ADDING');
		// axios
		// 	.post(DEVICE_MODELS_API, query, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 			'Content-Type': 'application/json',
		// 		},
		// 	})
		postData(DEVICE_MODELS_API, '', query)
			.then((response) => {
				dispatchNoPayload('DEVICE_MODELS_ADDED');
				generateSuccessMessage(response);
				this.getFilteredDeviceModels(this.props.pageNumber);
				this.clearAddOperation();
			})
			.catch((error) => {
				dispatchNoPayload('DEVICE_MODELS_ADDED');
				handleError(error);
			});
	}

	clearAddOperation() {
		dispatchNoPayload('DEVICE_MODELS_ADD_MODAL_CLOSE');

		this.setState({
			manufacturerFA: null,
			modelFA: null,
			protocols: [],
		});
	}

	onManufacturerChange(e) {
		this.setState({
			manufacturerFA: e.target.value,
		});
	}

	onProtocolChoose(e, { value }) {
		this.setState({
			selectedProtocol: value,
		});
	}

	clearProtocol() {
		this.setState({
			selectedProtocol: '',
		});
	}

	onOBDChange(e, { checked }) {
		this.setState({ isOBD: checked });
	}

	onModelChange(e) {
		this.setState({
			modelFA: e.target.value,
		});
	}

	// Edit
	update(id) {
		const { manufacturerFE, modelFE, selectedProtocolFE, isOBDFE } = this.state;
		let query = {};
		if (manufacturerFE)
			query = {
				manufacturer: manufacturerFE.trim(),
			};

		if (selectedProtocolFE) query = { ...query, port: selectedProtocolFE };
		if (isOBDFE != null) query = { ...query, isOBD: isOBDFE };

		if (modelFE) query = { ...query, model: modelFE.trim() };

		dispatchNoPayload('DEVICE_MODELS_UPDATING');
		// axios
		// 	.patch(DEVICE_MODELS_API + `/${id}`, query, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		patchData(DEVICE_MODELS_API + `/${id}`, query)
			.then((response) => {
				dispatchNoPayload('DEVICE_MODELS_UPDATED');
				generateSuccessMessage(response);
				this.getFilteredDeviceModels(this.props.pageNumber, false);
				this.cleanEditOperation();
				handleSuccess(response);
			})
			.catch((error) => {
				dispatchNoPayload('DEVICE_MODELS_UPDATED');

				handleError(error);
			});
	}

	// cleanEditOperation() {
	// 	dispatchNoPayload('DEVICE_MODELS_EDIT_MODAL_CLOSE');
	//
	// 	this.setState({
	// 		manufacturerFE: null,
	// 		modelFE: null,
	// 		selectedProtocolFE: null,
	// 		isOBDFE: null,
	// 		protocols: [],
	// 	});
	// }

	// Delete
	// deleteDeviceModel() {
	// 	dispatchNoPayload('DEVICE_MODELS_DELETING');
	// 	// axios
	// 	// 	.delete(DEVICE_MODELS_API + `/${this.deviceModelID}`, {
	// 	// 		headers: {
	// 	// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 	// 		},
	// 	// 	})
	// 	deleteData(DEVICE_MODELS_API, `/${this.deviceModelID}`)
	// 		.then((response) => {
	// 			dispatchNoPayload('DEVICE_MODELS_DELETED');
	// 			generateSuccessMessage(response);
	// 			this.getFilteredDeviceModels(this.props.pageNumber);
	// 			this.cleanDeleteOperation();
	// 		})
	// 		.catch((error) => {
	// 			dispatchNoPayload('DEVICE_MODELS_DELETED');
	// 			handleError(error);
	// 		});
	// }
	//
	// cleanDeleteOperation() {
	// 	dispatchNoPayload('DEVICE_MODELS_DELETE_MODAL_CLOSE');
	//
	// 	this.deviceModelID = '';
	// }

	// Restore
	restoreDeviceModel() {
		// axios
		// 	.post(
		// 		DEVICE_MODELS_API + `/${this.deviceModelID}/restore`,
		// 		{},
		// 		{
		// 			headers: {
		// 				Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 			},
		// 		}
		// 	)
		postData(DEVICE_MODELS_API, `/${this.deviceModelID}/restore`)
			.then((response) => {
				dispatchNoPayload('DEVICE_MODELS_RESTORED');
				generateSuccessMessage(response);
				this.getFilteredDeviceModels(this.props.pageNumber);
				this.cleanRestoreOperation();
			})
			.catch((error) => {
				dispatchNoPayload('DEVICE_MODELS_RESTORED');
				handleError(error);
			});
	}

	cleanRestoreOperation() {
		dispatchNoPayload('DEVICE_MODELS_RESTORE_MODAL_CLOSE');

		this.deviceModelID = '';
	}

	sort(param, activeSort, sortOrder) {
		if (!param) {
			this.activeSort = param;
			this.sortOrder = '';
		} else {
			if (activeSort !== param) {
				this.sortOrder = 'asc';
				this.activeSort = param;
			} else {
				if (sortOrder === 'asc') {
					this.sortOrder = 'desc';
				} else {
					this.sortOrder = 'asc';
				}
			}
		}

		dispatch('DEVICE_MODELS_SORT_ORDER', this.sortOrder);
		dispatch('DEVICE_MODELS_ACTIVE_ORDER', this.activeSort);
		this.getFilteredDeviceModels(this.props.pageNumber);
	}

	// Pagination
	handleDataSizeLimitChange() {
		this.getFilteredDeviceModels();
	}

	handlePageChange(activePage) {
		this.getFilteredDeviceModels(activePage);
		this.cleanSelectedRows();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.deviceModels !== this.props.deviceModels) {
			if (this.selectAllActive) this.handleSelectAll(true);
		}

		if (prevProps.loading !== this.props.loading) {
			if (!this.props.loading) {
				this.setState({
					initialLoading: false,
				});
			}
		}

		if (prevProps.pageNumber !== this.props.pageNumber) {
			document.getElementById('AccordionMain').scrollTo(0, 0);
		}
	}

	cleanSelectedRows() {
		this.setState({
			anyRowSelected: false,
			selectedRows: new Set(),
		});
	}

	componentWillUnmount() {
		dispatchNoPayload('DEVICE_MODELS_UNMOUNT');
	}

	render() {
		return (
			<CategoryContentMembrane>
				<CategoryHeader>
					<SearchBox
						placeholder="Search in device models"
						searchQuery={this.searchQuery}
						clearSearchIconClick={() => {
							this.searchQuery = '';
							this.getFilteredDeviceModels(this.props.pageNumber);
						}}
						handleSearch={this.handleSearch}
						// disabled={!this.searchQuery && (this.props.loading || this.props.exportingData)}
					/>
					<Button
						icon
						primary
						title="Refresh Device Models"
						disabled={this.props.loading || this.props.exportingData}
						onClick={() => this.getFilteredDeviceModels(this.props.pageNumber)}>
						<Icon name="refresh" />
					</Button>
					<Expander />

					{/*Add*/}
					{!this.state.deletedMode && (
						<Button
							primary
							title="Add Device Model"
							disabled={
								this.props.loading || this.props.exportingData || !(this.state.manageAccess || this.state.createAccess)
							}
							onClick={() => {
								trackMixpanelEvent('Device Models', { Action: 'Add' });
								dispatchNoPayload('DEVICE_MODELS_ADD_MODAL_OPEN');
								getProtocolsForDropdown().then((response) => {
									this.setState({ protocols: response });
								});
							}}>
							Add
						</Button>
					)}
					{/*Export*/}
					{/*<Button*/}
					{/*	primary*/}
					{/*	title={*/}
					{/*		!this.state.anyRowSelected ? 'Please select atleast one device model to export' : 'Export Device Models'*/}
					{/*	}*/}
					{/*	disabled={*/}
					{/*		!this.state.anyRowSelected ||*/}
					{/*		this.props.loading ||*/}
					{/*		!this.props.deviceModels.length ||*/}
					{/*		this.props.exportingData*/}
					{/*	}*/}
					{/*	onClick={() => {*/}
					{/*		openExportModal();*/}
					{/*		dispatch('SET_DEVICE_MODELS_EXPORT_DATA', this.export());*/}
					{/*	}}>*/}
					{/*	Export*/}
					{/*</Button>*/}

					{/*Export All*/}
					{/*<Button.Group>*/}
					{/*	<Button*/}
					{/*		primary*/}
					{/*		disabled={this.props.loading || !this.props.deviceModels.length || this.props.exportingData}*/}
					{/*		loading={this.props.exportingData}*/}
					{/*		title="Export All Device Models"*/}
					{/*		onClick={() => exportAll(this.deletedMode ? '&deleted=1' : '', this.state.fileExtension)}>*/}
					{/*		Export*/}
					{/*	</Button>*/}
					{/*	<Dropdown*/}
					{/*		className="button icon"*/}
					{/*		style={{ background: '#ffffff', color: '#1b3c71', borderColor: '#1b3c71' }}*/}
					{/*		floating*/}
					{/*		value={this.state.fileExtension}*/}
					{/*		options={FILE_TYPE_EXTENSIONS}*/}
					{/*		defaultValue={this.state.fileExtension}*/}
					{/*		onChange={this.state.fileExtensionChange}*/}
					{/*		trigger={<></>}*/}
					{/*	/>*/}
					{/*</Button.Group>*/}

					{/*Export All Button Ends*/}
					{/* Deleted Mode*/}

					{/*<ToggleSwitch*/}
					{/*	title={this.deletedMode ? 'Hide Deleted Device Models' : 'Show Deleted Device Models'}*/}
					{/*	label="Deleted Mode"*/}
					{/*	checked={this.deletedMode}*/}
					{/*	action={this.toggleDeletedMode}*/}
					{/*	disabled={this.props.loading || this.props.exportingData}*/}
					{/*/>*/}
				</CategoryHeader>

				<CategoryMain>
					<AccordionHeader background={this.deletedMode}>
						{/*<HeaderElement*/}
						{/*	style={{ marginRight: 0 }}*/}
						{/*	width="3%"*/}
						{/*	disabled={this.props.loading || this.props.exportingData || !this.props.deviceModels.length}>*/}
						{/*	<CheckboxContainer>*/}
						{/*		<Checkbox*/}
						{/*			checked={*/}
						{/*				this.props.deviceModels.length !== 0 &&*/}
						{/*				this.state.selectedRows.size === this.props.deviceModels.length*/}
						{/*			}*/}
						{/*			indeterminate={*/}
						{/*				this.state.selectedRows.size !== 0 &&*/}
						{/*				this.state.selectedRows.size !== this.props.deviceModels.length*/}
						{/*			}*/}
						{/*			onClick={(e, data) => {*/}
						{/*				this.handleSelectAll(data.checked);*/}
						{/*			}}*/}
						{/*		/>*/}
						{/*	</CheckboxContainer>*/}
						{/*</HeaderElement>*/}
						<HeaderElement
							style={{ marginRight: 0 }}
							width="7%"
							disabled={this.props.loading || this.props.exportingData || !this.props.deviceModels.length}
							onClick={() => {
								this.sort('', '', '');
							}}>
							No.
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="18%"
							disabled={this.props.loading || this.props.exportingData || !this.props.deviceModels.length}
							onClick={() => {
								this.sort('manufacturer', this.props.activeSort, this.props.sortOrder);
							}}
							activeSort={this.activeSort === 'manufacturer'}>
							{this.props.activeSort === 'manufacturer' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Manufacturer
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="20%"
							disabled={this.props.loading || this.props.exportingData || !this.props.deviceModels.length}
							onClick={() => {
								this.sort('model', this.props.activeSort, this.props.sortOrder);
							}}
							activeSort={this.activeSort === 'model'}>
							{this.props.activeSort === 'model' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}{' '}
							Model
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="20%"
							disabled={this.props.loading || this.props.exportingData || !this.props.deviceModels.length}
							onClick={() => {
								this.sort('port', this.props.activeSort, this.props.sortOrder);
							}}
							activeSort={this.activeSort === 'port'}>
							{this.props.activeSort === 'port' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}{' '}
							Protocol
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="15%"
							disabled={this.props.loading || this.props.exportingData || !this.props.deviceModels.length}
							onClick={() => {
								this.sort('isOBD', this.props.activeSort, this.props.sortOrder);
							}}
							activeSort={this.activeSort === 'isOBD'}>
							{this.props.activeSort === 'isOBD' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}{' '}
							isOBD
						</HeaderElement>
						{this.deletedMode && (
							<HeaderElement
								style={{ marginRight: 0 }}
								width="15%"
								disabled={this.props.loading || this.props.exportingData || !this.props.deviceModels.length}>
								Deleted date
							</HeaderElement>
						)}
					</AccordionHeader>

					<AccordionMain id="AccordionMain">
						{this.state.initialLoading ? (
							<BasicLoader />
						) : this.props.deviceModels.length ? (
							<Accordion fluid>
								{this.props.deviceModels.map((device, index) => {
									return (
										<section key={index}>
											<Accordion.Title
												active={this.props.activeIndex === index}
												index={index}
												// onClick= {(event, data) => {
												// 	toggleAccordion(event, data, this.props.activeIndex);
												// 	this.cleanEditOperation();
												// }}
												style={{ padding: 0 }}>
												<Row>
													{/*<CheckboxContainer>*/}
													{/*	<Checkbox*/}
													{/*		checked={this.state.selectedRows.has(device.id)}*/}
													{/*		onChange={(e, data) => {*/}
													{/*			e.stopPropagation();*/}
													{/*			this.handleExportSelection(data.checked, device.id);*/}
													{/*		}}*/}
													{/*	/>*/}
													{/*</CheckboxContainer>*/}
													<RowElement width="7%" style={{ marginRight: 0 }}>
														{1 + index + (this.props.pageNumber - 1) * this.props.dataSizeLimit}
													</RowElement>
													<RowElement
														width="18%"
														style={{ marginRight: 0, color: `${device.manufacturer ? 'black' : 'grey'}` }}
														title={device.manufacturer}>
														{device.manufacturer}
													</RowElement>
													<RowElement
														width="20%"
														style={{ marginRight: 0, color: `${device.model ? 'black' : 'grey'}` }}
														title={device.model}>
														{device.model ? device.model : 'Not Available'}
													</RowElement>
													{/*{JSON.stringify(device)}*/}
													{device.port ? (
														<RowElement
															width="20%"
															style={{
																marginRight: 0,
																color: `${this.state.protocolList[device.port] ? 'black' : 'grey'}`,
															}}
															title={`${this.state.protocolList[device.port]} - ${device.port}`}>
															{this.state.protocolList[device.port]} - {device.port}
														</RowElement>
													) : (
														<RowElement width={'20%'} style={{ marginRight: 0, color: 'grey' }}>
															unavailable
														</RowElement>
													)}
													<RowElement style={{ marginRight: 0 }} width="15%">
														{device.isOBD ? (
															<Label size="medium" color="green">
																True
															</Label>
														) : (
															<Label size="medium" color="red">
																False
															</Label>
														)}
													</RowElement>
													{this.deletedMode && (
														<RowElement
															style={{ marginRight: 0, color: `${device.deletedDate ? 'black' : 'grey'}` }}
															width="15%"
															title={device.deletedDate}>
															{device.deletedDate ? device.deletedDate : 'Not Available'}
														</RowElement>
													)}
												</Row>
											</Accordion.Title>
											{this.props.activeIndex === index && (
												<Accordion.Content active={this.props.activeIndex === index}>
													<AccordionMainContent>
														<Form>
															<Form.Group widths="equal">
																<Form.Field required>
																	<label>Manufacturer</label>
																	<Input
																		fluid
																		value={
																			this.state.manufacturerFE !== null
																				? this.state.manufacturerFE
																				: device.manufacturer === null
																				? ''
																				: device.manufacturer
																		}
																		disabled={!this.props.editMode}
																		onChange={(e) =>
																			this.setState({
																				manufacturerFE: e.target.value,
																			})
																		}
																	/>
																</Form.Field>
																<Form.Field required>
																	<label>Model</label>
																	<Input
																		fluid
																		value={
																			this.state.modelFE !== null
																				? this.state.modelFE
																				: device.model === null
																				? ''
																				: device.model
																		}
																		disabled={!this.props.editMode}
																		onChange={(e) =>
																			this.setState({
																				modelFE: e.target.value,
																			})
																		}
																	/>
																</Form.Field>
																<Form.Field required>
																	<label>Protocol</label>
																	{this.props.editMode ? (
																		<>
																			<Dropdown
																				search
																				selection
																				options={this.state.protocols}
																				disabled={!this.props.editMode}
																				value={
																					this.state.selectedProtocolFE !== null
																						? this.state.selectedProtocolFE
																						: device.port
																				}
																				onChange={(e, { value }) => {
																					this.setState({
																						selectedProtocolFE: value,
																					});
																				}}
																			/>
																		</>
																	) : (
																		<Input
																			fluid
																			disabled
																			value={this.state.protocolList[device.port] + '-' + device.port}
																		/>
																	)}
																</Form.Field>
																<Form.Field style={{ alignSelf: 'end' }}>
																	<ObdToggle
																		label={'OBD'}
																		disabled={!this.props.editMode}
																		checked={
																			this.state.isOBDFE !== null
																				? this.state.isOBDFE
																				: device.isOBD === null
																				? ''
																				: device.isOBD
																		}
																		onChange={(e, { checked }) => {
																			this.setState({ isOBDFE: checked });
																		}}
																	/>
																</Form.Field>
															</Form.Group>
														</Form>
														{/*	{this.state.deletedMode ? (
															<Actions>
																<Button
																	primary
																	onClick={() => {
																		trackMixpanelEvent('Device Models', { Action: 'Restore' });
																		dispatchNoPayload('DEVICE_MODELS_RESTORE_MODAL_OPEN');
																		this.deviceModelID = device.id;
																	}}>
																	Restore
																</Button>
															</Actions>
														) : this.props.editMode ? (
															<Actions>
																<Button secondary onClick={this.cleanEditOperation}>
																	Cancel
																</Button>
																<Button
																	positive
																	loading={this.props.updating}
																	onClick={() => {
																		this.update(device.id);
																	}}>
																	Update
																</Button>
															</Actions>
														) : (
															<Actions>
																<Button
																	primary
																	disabled={!(this.state.manageAccess || this.state.updateAccess)}
																	onClick={() => {
																		trackMixpanelEvent('Device Models', { Action: 'Edit' });
																		dispatchNoPayload('DEVICE_MODELS_EDIT_MODAL_OPEN');
																		getProtocolsForDropdown().then((response) => {
																			this.setState({ protocols: response });
																		});
																	}}>
																	Edit
																</Button>
																<Button
																	negative
																	disabled={!(this.state.manageAccess || this.state.deleteAccess)}
																	onClick={() => {
																		trackMixpanelEvent('Device Models', { Action: 'Delete' });
																		dispatchNoPayload('DEVICE_MODELS_DELETE_MODAL_OPEN');
																		this.deviceModelID = device.id;
																	}}>
																	Delete
																</Button>
															</Actions>
														)}*/}
													</AccordionMainContent>
												</Accordion.Content>
											)}
										</section>
									);
								})}
							</Accordion>
						) : (
							<PlaceHolder />
						)}
					</AccordionMain>
				</CategoryMain>

				<CategoryFooter background={this.deletedMode}>
					<FooterPagination
						handleDataSizeLimitChange={this.handleDataSizeLimitChange}
						pageNumber={this.props.pageNumber}
						handlePageChange={this.handlePageChange}
						count={this.props.count}
						disabled={this.props.loading || this.props.exportingData || !this.props.deviceModels.length}
					/>
				</CategoryFooter>

				{this.props.loading && !this.state.initialLoading && <TransitionLoader />}

				{this.props.addModalOpen && (
					<AddModal
						manufacturerFA={this.state.manufacturerFA}
						modelFA={this.state.modelFA}
						selectedProtocol={this.state.selectedProtocol}
						onClose={this.clearAddOperation}
						addDisabled={this.state.addDisabled}
						open={this.props.addModalOpen}
						onManufacturerChange={this.onManufacturerChange}
						onModelChange={this.onModelChange}
						protocols={this.state.protocols}
						onProtocolChoose={this.onProtocolChoose}
						clearProtocol={this.clearProtocol}
						onCancelClick={this.clearAddOperation}
						onAddButtonClick={this.add}
						addButtonLoading={this.props.adding}
						onOBDChange={this.onOBDChange}
					/>
				)}

				{this.props.deleteModalOpen && (
					<DeleteModal
						onClose={this.cleanDeleteOperation}
						open={this.props.deleteModalOpen}
						onCancelClick={this.cleanDeleteOperation}
						onDeleteButtonClick={() => {
							this.deleteDeviceModel(this.deviceModelID);
						}}
						deleteButtonLoading={this.props.deleting}
					/>
				)}

				{this.props.restoreModal && (
					<RestoreModal
						onClose={this.cleanRestoreOperation}
						open={this.props.restoreModal}
						onCancelClick={this.cleanRestoreOperation}
						onRestoreButtonClick={() => {
							this.restoreDeviceModel(this.deviceModelID);
						}}
						restoreButtonLoading={this.props.restoring}
					/>
				)}

				{this.props.openExportModal && (
					<ExportModal
						open={this.props.openExportModal}
						fileName={this.state.fileName}
						fileNameChange={this.fileNameChange}
						fileExtension={this.state.fileExtension}
						fileExtensionChange={this.fileExtensionChange}
						onDownloadClick={() => exportData(this.props.exportData, this.state.fileName, this.state.fileExtension)}
					/>
				)}
			</CategoryContentMembrane>
		);
	}
}

// Connect to Store
const mapStateToProps = (state) => ({
	deviceModels: state.deviceModels.deviceModels,
	loading: state.deviceModels.loading,
	activeIndex: state.deviceModels.activeIndex,
	count: state.deviceModels.count,
	pageNumber: state.deviceModels.pageNumber,
	dataSizeLimit: state.ui.dataSizeLimit,

	adding: state.deviceModels.adding,
	addModalOpen: state.deviceModels.addModalOpen,

	editMode: state.deviceModels.editMode,
	updating: state.deviceModels.updating,

	deleteModalOpen: state.deviceModels.deleteModalOpen,
	deleting: state.deviceModels.deleting,

	activeSort: state.deviceModels.activeSort,
	sortOrder: state.deviceModels.sortOrder,

	restoreModal: state.deviceModels.restoreModal,
	restoring: state.deviceModels.restoring,

	openExportModal: state.deviceModels.openExportModal,
	exportData: state.deviceModels.exportData,
	exportingData: state.deviceModels.exportingData,
});

export default connect(mapStateToProps)(DeviceModels);
