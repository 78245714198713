import React, { useState } from 'react';
import CommonModal from '../../../molecules/modal/CommonModal';
import { VEHICLE_DOCUMENT_TYPES_OPTIONS } from '../../../../../../data/constants/dropdown-options';
import { Button, Dropdown, Form } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import styled from 'styled-components';
import { Expander } from '../../../../styles/place-holders/Expander';
import { connect } from 'react-redux';
import { dispatch } from '../../../../../../logic/middleware/middleware';

const FilterModal = (props) => {
	const { organisations = [], vehicle = [], type = [], expiryStartDate = '', expiryEndDate = '' } = props.activeFilters;

	const [filterValues, setFilterValues] = useState({
		organisations: organisations,
		vehicle: vehicle,
		type: type,
		expiryStartDate: expiryStartDate,
		expiryEndDate: expiryEndDate,
	});
	const isSuperAdmin = localStorage.getItem('isSuperAdmin');

	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="tiny"
			header="Select the Filters"
			scrolling={true}
			content={
				<Form>
					<Form.Group widths="equal">
						<Form.Field required={true}>
							<label>Organisation</label>
							<Dropdown
								placeholder="Choose ..."
								name="organisation"
								fluid
								multiple
								search
								clearable
								selection
								disabled={isSuperAdmin == 'false'}
								value={isSuperAdmin == 'true' ? props.filters.organisations : props?.organisationDropdown[0]}
								options={props.organisationDropdown}
								onChange={(event, data) => {
									props.handleOrgChange(data);
									setFilterValues({ ...filterValues, organisations: data.value });
									dispatch('ACTIVE_FILTER', { ...props.filters, organisations: data.value });
								}}
							/>
						</Form.Field>
						<Form.Field required={true}>
							<label>Vehicle No.</label>
							<Dropdown
								placeholder="Choose ..."
								name="vehicle"
								fluid
								search
								selection
								multiple
								clearable
								onClick={isSuperAdmin === 'false' ? () => {
									let data = { value: [props.organisationDropdown[0].value] };
									props.handleOrgChange(data);
									setFilterValues({ ...filterValues, organisations: data.value });
									dispatch('ACTIVE_FILTER', { ...props.filters, organisations: data.value });
								} : undefined}
								value={filterValues.vehicle}
								options={props.vehicleDropDown}
								onChange={(event, data) => setFilterValues({ ...filterValues, vehicle: data.value })}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field>
							<label>Document Type</label>
							<Dropdown
								placeholder="Choose ..."
								name="documentType"
								fluid
								search
								selection
								multiple
								clearable
								options={VEHICLE_DOCUMENT_TYPES_OPTIONS}
								value={filterValues.type}
								onChange={(event, data) => setFilterValues({ ...filterValues, type: data.value })}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field>
							<label>Expiry Start Date</label>
							<DateInput
								placeholder="Select Expiry Start Date"
								fluid
								closable
								clearable
								value={filterValues.expiryStartDate}
								onClear={() => {
									setFilterValues({
										...filterValues,
										expiryEndDate: '',
										expiryStartDate: '',
									});
								}}
								onChange={(event, data) =>
									setFilterValues({
										...filterValues,
										expiryStartDate: data.value,
									})
								}
							/>
						</Form.Field>
						<Form.Field>
							<label>Expiry End Date</label>
							<DateInput
								placeholder="Select Expiry End Date"
								fluid
								disabled={!filterValues.expiryStartDate}
								closable
								clearable
								minDate={filterValues.expiryStartDate}
								value={filterValues.expiryEndDate}
								onChange={(event, data) =>
									setFilterValues({
										...filterValues,
										expiryEndDate: data.value,
									})
								}
							/>
						</Form.Field>
					</Form.Group>
				</Form>
			}
			actions={
				<>
					<FlexContainer>
						{Object.keys(props.activeFilters).length > 0 && (
							<Button
								onClick={() => {
									props.onResetClick();
									setFilterValues({
										organisations: [],
										vehicle: [],
										type: [],
										expiryStartDate: null,
										expiryEndDate: null,
									});
									dispatch('ACTIVE_FILTER', { ...props.filters, organisations: [] });
								}}>
								Reset
							</Button>
						)}

						<Expander />
						<Button color="black" onClick={props.onCancel}>
							Cancel
						</Button>
						<Button
							positive
							disabled={
								!props.filters.organisations?.length &&
								!filterValues.vehicle.length &&
								!filterValues.type.length &&
								!filterValues.expiryStartDate
							}
							onClick={() => {
								if (
									!props.filters.organisations?.length &&
									!filterValues.vehicle.length &&
									!filterValues.type.length &&
									!filterValues.expiryStartDate
								) {
									props.handleOnApply({});
								} else {
									props.handleOnApply(filterValues);
								}
							}}>
							Apply
						</Button>
					</FlexContainer>
				</>
			}></CommonModal>
	);
};

const mapStateToProps = (state) => ({
	filters: state.vehicles.filters,
});

export default connect(mapStateToProps)(FilterModal);

const FlexContainer = styled.section`
	display: flex;
`;
