import styled from 'styled-components';

export const Container = styled.section`
	width: 100%;
	height: 100%;

	position: absolute;
	top: 0;
	left: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	z-index: 10;
`;
