import React from 'react';
import CommonModal from '../../../molecules/modal/CommonModal';
import { Button } from 'semantic-ui-react';

const SwapDeviceConfirmation = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="mini"
			header="Swap Device"
			content={<p>are you sure you want to swap device?</p>}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button negative loading={props.swapButtonLoading} onClick={props.onSwapButtonClick}>
						Ok
					</Button>
				</>
			}
		/>
	);
};

export default SwapDeviceConfirmation;
