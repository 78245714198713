// import axios from 'axios';

import { AUDIT_LOG_API } from '../configs/api';
import { getData } from './services/apiRequest';
import { dispatch, dispatchNoPayload } from './middleware';
import Store from '../../data/store/store';
import { handleError } from './messaging';

export async function refreshAuditLog(param, activePage = 1, resetOffset = false) {
	let responseToBeReturned = [];
	dispatchNoPayload('ACTIVITY_LOGS_LOADING');

	await getData(
		AUDIT_LOG_API,
		`?pagecount=${getDataFromStore().dataSizeLimit}&pagenumber=${resetOffset ? 1 : activePage}` + param
	)
		.then((response) => {
			responseToBeReturned = response.data.data;
			dispatch('FEED_ACTIVITY_LOGS', response.data.data);
			dispatch('ACTIVITY_LOGS_SET_PAGE_COUNT', response.data.count);
			dispatch('ACTIVITY_LOGS_SET_PAGE_NUMBER', activePage);
		})
		.catch((error) => {
			dispatchNoPayload('ACTIVITY_LOGS_LOADED');
			handleError(error);
		});

	return responseToBeReturned;
}

function getDataFromStore() {
	return {
		pageNumber: Store.getState().activityLogs.pageNumber,
		dataSizeLimit: Store.getState().ui.dataSizeLimit,
	};
}
