import React from 'react';

import { NAV_BAR_LOGO_HEIGHT } from '../../../../../data/constants/dimensions';
import LogoImage from '../../../../assets/images/tracknerd-logo-small.png';

import styled from 'styled-components';

function LogoMini(props) {
	return (
		<LogoContainer>
			<section>
				<img src={LogoImage} style={{ maxHeight: props.height }} alt="TrackNerdGPS" />
			</section>
		</LogoContainer>
	);
}

export default LogoMini;

/* ****************** */
/* ***** Styles ***** */
/* ****************** */

export const LogoContainer = styled.section`
	width: 100%;
	height: ${NAV_BAR_LOGO_HEIGHT};

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding: 0.5em 0;
	//margin-bottom: 1em;

	section {
		box-sizing: border-box;
		//padding: 0.5em 0.5em 0.25em 0.5em;
		padding-left: 0.5em;

		// background: ${(props) => props.theme.logoBackground};
		border-radius: 5px;
	}

	img {
		height: 100%;
	}
`;
