export default function vehicleGroups(
	initialState = {
		vehicleGroups: [],
		loading: true,
		pageNumber: 1,
		count: 0,
		activeIndex: -1,
		activeSort: '',
		sortOrder: '',
		deleting: false,
		deleteModalOpen: false,
		adding: false,
		addModalOpen: false,
		updating: false,
		editMode: false,
		userModal: false,
		vehicleModal: false,
		restoreModal: false,
		restoring: false,
		filterModalOpen: false,

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
	},
	action
) {
	switch (action.type) {
		case 'FEED_VEHICLE_GROUPS':
			return {
				...initialState,
				vehicleGroups: action.payload,
				loading: false,
			};

		case 'VEHICLE_GROUPS_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'VEHICLE_GROUPS_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'VEHICLE_GROUPS_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'VEHICLE_GROUPS_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'VEHICLE_GROUPS_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'VEHICLE_GROUPS_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'VEHICLE_GROUPS_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'VEHICLE_GROUPS_DELETING':
			return {
				...initialState,
				deleting: true,
			};

		case 'VEHICLE_GROUPS_DELETED':
			return {
				...initialState,
				deleting: false,
			};

		case 'VEHICLE_GROUPS_DELETE_MODAL_OPEN':
			return {
				...initialState,
				deleteModalOpen: true,
			};

		case 'VEHICLE_GROUPS_DELETE_MODAL_CLOSE':
			return {
				...initialState,
				deleteModalOpen: false,
			};

		case 'VEHICLE_GROUPS_ADDING':
			return {
				...initialState,
				adding: true,
			};

		case 'VEHICLE_GROUPS_ADDED':
			return {
				...initialState,
				adding: false,
			};

		case 'VEHICLE_GROUPS_ADD_MODAL_OPEN':
			return {
				...initialState,
				addModalOpen: true,
			};

		case 'VEHICLE_GROUPS_ADD_MODAL_CLOSE':
			return {
				...initialState,
				addModalOpen: false,
			};

		case 'VEHICLE_GROUPS_UPDATING':
			return {
				...initialState,
				updating: true,
			};

		case 'VEHICLE_GROUPS_UPDATED':
			return {
				...initialState,
				updating: false,
			};

		case 'VEHICLE_GROUPS_EDIT_MODAL_OPEN':
			return {
				...initialState,
				editMode: true,
			};

		case 'VEHICLE_GROUPS_EDIT_MODAL_CLOSE':
			return {
				...initialState,
				editMode: false,
			};

		case 'VEHICLE_GROUPS_USER_MODAL_OPEN':
			return {
				...initialState,
				userModal: true,
			};

		case 'VEHICLE_GROUPS_USER_MODAL_CLOSE':
			return {
				...initialState,
				userModal: false,
			};

		case 'VEHICLE_GROUPS_VEHICLES_MODAL_OPEN':
			return {
				...initialState,
				vehicleModal: true,
			};

		case 'VEHICLE_GROUPS_VEHICLES_MODAL_CLOSE':
			return {
				...initialState,
				vehicleModal: false,
			};

		case 'VEHICLE_GROUPS_RESTORE_MODAL_OPEN':
			return {
				...initialState,
				restoreModal: true,
			};

		case 'VEHICLE_GROUPS_RESTORE_MODAL_CLOSE':
			return {
				...initialState,
				restoreModal: false,
			};

		case 'VEHICLE_GROUPS_RESTORING':
			return {
				...initialState,
				restoring: true,
			};

		case 'VEHICLE_GROUPS_RESTORED':
			return {
				...initialState,
				restoring: false,
			};

		case 'VEHICLE_GROUPS_FILTER_MODAL_OPEN':
			return {
				...initialState,
				filterModalOpen: true,
			};

		case 'VEHICLE_GROUPS_FILTER_MODAL_CLOSE':
			return {
				...initialState,
				filterModalOpen: false,
			};

		case 'VEHICLE_GROUPS_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'VEHICLE_GROUPS_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_VEHICLE_GROUPS_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_VEHICLE_GROUPS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_VEHICLE_GROUPS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'VEHICLE_GROUPS_UNMOUNT':
			return {
				...initialState,
				vehicleGroups: [],
				activeSort: '',
				sortOrder: '',
				activeIndex: -1,
				pageNumber: 1,
				filterModalOpen: false,
			};

		default:
			return initialState;
	}
}
