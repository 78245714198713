export default function documentTypes(
	initialState = {
		documentTypes: [],
		loading: true,
		pageNumber: 1,
		count: 0,
		activeIndex: -1,
		deleting: false,
		deleteModalOpen: false,
		activeSort: '',
		sortOrder: '',
		adding: false,
		addModalOpen: false,
		updating: false,
		editMode: false,
		restoreModal: false,
		restoring: false,

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
	},
	action
) {
	switch (action.type) {
		case 'FEED_DOCUMENT_TYPES':
			return {
				...initialState,
				documentTypes: action.payload,
				loading: false,
			};

		case 'DOCUMENT_TYPES_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'DOCUMENT_TYPES_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'DOCUMENT_TYPES_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'DOCUMENT_TYPES_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'DOCUMENT_TYPES_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'DOCUMENT_TYPES_DELETING':
			return {
				...initialState,
				deleting: true,
			};

		case 'DOCUMENT_TYPES_DELETED':
			return {
				...initialState,
				deleting: false,
			};

		case 'DOCUMENT_TYPES_DELETE_MODAL_OPEN':
			return {
				...initialState,
				deleteModalOpen: true,
			};

		case 'DOCUMENT_TYPES_DELETE_MODAL_CLOSE':
			return {
				...initialState,
				deleteModalOpen: false,
			};

		case 'DOCUMENT_TYPES_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'DOCUMENT_TYPES_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'DOCUMENT_TYPES_ADDING':
			return {
				...initialState,
				adding: true,
			};

		case 'DOCUMENT_TYPES_ADDED':
			return {
				...initialState,
				adding: false,
			};

		case 'DOCUMENT_TYPES_ADD_MODAL_OPEN':
			return {
				...initialState,
				addModalOpen: true,
			};

		case 'DOCUMENT_TYPES_ADD_MODAL_CLOSE':
			return {
				...initialState,
				addModalOpen: false,
			};

		case 'DOCUMENT_TYPES_UPDATING':
			return {
				...initialState,
				updating: true,
			};

		case 'DOCUMENT_TYPES_UPDATED':
			return {
				...initialState,
				updating: false,
			};

		case 'DOCUMENT_TYPES_EDIT_MODAL_OPEN':
			return {
				...initialState,
				editMode: true,
			};

		case 'DOCUMENT_TYPES_EDIT_MODAL_CLOSE':
			return {
				...initialState,
				editMode: false,
			};

		case 'DOCUMENT_TYPES_RESTORE_MODAL_OPEN':
			return {
				...initialState,
				restoreModal: true,
			};

		case 'DOCUMENT_TYPES_RESTORE_MODAL_CLOSE':
			return {
				...initialState,
				restoreModal: false,
			};

		case 'DOCUMENT_TYPES_RESTORING':
			return {
				...initialState,
				restoring: true,
			};

		case 'DOCUMENT_TYPES_RESTORED':
			return {
				...initialState,
				restoring: false,
			};

		case 'DOCUMENT_TYPES_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'DOCUMENT_TYPES_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_DOCUMENT_TYPES_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_DOCUMENT_TYPES_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_DOCUMENT_TYPES_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'DOCUMENT_TYPES_UNMOUNT':
			return {
				...initialState,
				documentTypes: [],
				loading: true,
				pageNumber: 1,
				count: 0,
				activeIndex: -1,
				deleting: false,
				deleteModalOpen: false,
				activeSort: '',
				sortOrder: '',
				adding: false,
				addModalOpen: false,
				updating: false,
				editMode: false,
				restoreModal: false,
				restoring: false,

				// Export
				openExportModal: false,
				exportData: [],
				exportingData: false,
			};

		default:
			return initialState;
	}
}
