import React from 'react';
import CommonModal from '../../../molecules/modal/CommonModal';
import { Button, Label, Loader, Segment, Table } from 'semantic-ui-react';

const OrganisationHistoryModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="small"
			header={props.header}
			content={
				props.loading ? (
					<Segment style={{ border: 'none', boxShadow: 'none' }}>
						<Loader inverted />
					</Segment>
				) : (
					<Table celled striped compact selectable>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>Serial</Table.HeaderCell>
								<Table.HeaderCell>Name</Table.HeaderCell>
								<Table.HeaderCell>From</Table.HeaderCell>
								<Table.HeaderCell>To</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{props.orgHistory.length !== 0 &&
								props.orgHistory.map((org, index) => {
									return (
										<Table.Row key={index}>
											<Table.Cell>{index + 1}</Table.Cell>
											<Table.Cell>{org.organisation}</Table.Cell>
											<Table.Cell>{org.startDate}</Table.Cell>
											<Table.Cell>
												{index === props.orgHistory.length - 1 ? (
													<Label style={{ backgroundColor: '#b3d4fb' }}>Current Owner</Label>
												) : (
													org.endDate
												)}
											</Table.Cell>
										</Table.Row>
									);
								})}
						</Table.Body>
					</Table>
				)
			}
			actions={<Button onClick={props.onCancelClick}>Close</Button>}
		/>
	);
};

export default OrganisationHistoryModal;
