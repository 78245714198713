import React from 'react';
import {
	Accordion,
	Button,
	Checkbox,
	Confirm,
	Dropdown,
	Form,
	Header,
	Icon,
	Input,
	Label,
	Modal,
	Segment,
} from 'semantic-ui-react';
import {
	CHANGE_HISTORY_API,
	COMMANDS_PIPELINE_API,
	OWNER_HISTORY_API,
	RECALCULATE_API,
	VEHICLES_API,
	VEHICLES_API_BULK,
} from '../../../../../logic/configs/api';
// import VehicleLogs from '../../diagnostic-and-logs/VehicleLogs';
import BasicLoader from '../../../blocks/molecules/loader/BasicLoader';
import SearchBox from '../../../blocks/atoms/search-box/SearchBox';
import FooterPagination from '../../../blocks/molecules/pagination/Pagination';
import ToggleSwitch from '../../../blocks/molecules/toggle-switch/ToggleSwitch';
import DeleteModal from '../../../blocks/organisms/modals/vehicles/DeleteModal';
import RestoreModal from '../../../blocks/organisms/modals/vehicles/RestoreModal';
import SwapModal from '../../../blocks/organisms/modals/vehicles/SwapModal';
import VehicleGroupModal from '../../../blocks/organisms/modals/vehicles/VehicleGroupModal';
import OrganisationHistoryModal from '../../../blocks/organisms/modals/vehicles/OrganisationHistoryModal';
import EditOrganisationModal from '../../../blocks/organisms/modals/vehicles/EditOrganisationModal';
// import CalibrationModal from '../../../blocks/organisms/modals/vehicles/CalibrationModal';
import RecalculateModal from '../../../blocks/organisms/modals/vehicles/RecalculateModal';
import AddModal from '../../../blocks/organisms/modals/vehicles/AddModal';
import ExportModal from '../../../blocks/organisms/modals/vehicles/ExportModal';
import { CategoryContentMembrane } from '../../../styles/containers/CategoryContentMembrane';
import { CategoryHeader } from '../../../styles/containers/CategoryHeader';
import { Expander } from '../../../styles/place-holders/Expander';
import { CategoryMain } from '../../../styles/containers/CategoryMain';
import { AccordionHeader } from '../../../styles/containers/AccordionHeader';
import { AccordionMain } from '../../../styles/containers/AccordionMain';
import { CategoryFooter } from '../../../styles/containers/CategoryFooter';
import { AccordionMainContent } from '../../../styles/containers/AccordionMainContent';
import PlaceHolder from '../../../blocks/molecules/place-holder/PlaceHolder';
import FilterModal from '../../../blocks/organisms/modals/vehicles/FilterModal';
import TimeLineViewModal from '../../../blocks/organisms/modals/time-line-view/TimeLineViewModal';
import MessageTimeLine from '../../../blocks/organisms/modals/time-line/MessageTimeLine';
import TransitionLoader from '../../../blocks/molecules/loader/TransitionLoader';
import SwapDeviceConfirmation from '../../../blocks/organisms/modals/vehicles/SwapDeviceConfirmation';

import { VEHICLE_SENSORS_OPTIONS } from '../../../../../data/constants/dropdown-options';
import { MessageType } from '../../../../../data/constants/message-type';
import { createOwnerHistoryDTO } from '../../../../../logic/dto/ownerHistory';
// import { createSingleVehicleDTO } from '../../../../../logic/dto/vehicles';
import { createChangeHistoryDTO } from '../../../../../logic/dto/changeHistory';
import { createCommandsPipelineDTO } from '../../../../../logic/dto/commandsPipeline';
import {
	closeFilterModal,
	exportAll,
	exportData,
	getDevicesForDropdown,
	getDriversForDropdown,
	getGeofenceForDropdown,
	getOrganisationsForDropdown,
	getVehicleForDropdown,
	getVehicleGroupsForDropdown,
	getVehicleStatusForDropdown,
	getVehicleTypesForDropdown,
	openFilterModal,
	refreshVehicles,
	searchVehicles,
	toggleAccordion,
} from '../../../../../logic/middleware/vehicles';
import { dispatch, dispatchNoPayload } from '../../../../../logic/middleware/middleware';

import { isoToDate, isoToHumanReadableAlternate } from '../../../../../logic/utilities/date';
import { generateSuccessMessage, handleError, showMessage } from '../../../../../logic/middleware/messaging';
// import { validateNumericValue } from '../../../../../logic/utilities/validator';
import { connect } from 'react-redux';
import moment from 'moment';

import { CalibrationHeaderElement, CheckboxContainer } from '../../../styles/Containers';
import { Actions, HeaderElement, LeftActions, Row, RowElement } from '../../../styles/Accordion';
import { DateInput } from 'semantic-ui-calendar-react';
// import { ref, onChildChanged, get } from 'firebase/database';
import accessSetter, { entityAccessSetter } from '../../../../../logic/utilities/accessSetter';
import { searchUsers } from '../../../../../logic/middleware/users';
import { FILE_TYPE_EXTENSIONS } from '../../../../../data/constants/file-types';
import { trackMixpanelEvent } from '../../../../../logic/middleware/mixpanel';
import { Link } from 'react-router-dom';
import { deleteData, getData, patchData, postData } from '../../../../../logic/middleware/services/apiRequest';
import { datadogLogs } from '@datadog/browser-logs';
import VehicleBulkUpdateModal from '../../../blocks/organisms/modals/vehicles/VehicleBulkUpdateModal';
import { Container } from './Users';
import { FaTractor } from 'react-icons/fa';

class Vehicles extends React.Component {
	vehicleId = '';
	// searchQuery = '';
	vehicleNumber = '';
	calibrationValue = [];
	vehicleReg = '';
	fuelSensorDropdown = [
		{
			key: 1,
			text: 'One',
			value: 1,
		},
		{
			key: 2,
			text: 'Two',
			value: 2,
		},
		{
			key: 3,
			text: 'Three',
			value: 3,
		},
		{
			key: 4,
			text: 'Four',
			value: 4,
		},
		{
			key: 5,
			text: 'Five',
			value: 5,
		},
	];
	deviceImei = '';
	selectedDevice = '';

	deleteId = '';

	vehicleRestoreId = '';
	vehicleRestoreImei = '';
	isSuperAdminAccess = localStorage.getItem('isSuperAdmin');

	vehicle = '';
	vehicleRegistration = '';
	organisation = '';
	installationDate = '';
	createdDate = '';

	selectedOrganisation = '';

	// firebaseReferences = [];

	timeoutId;

	SheetJSFT = ['xml']
		.map(function (x) {
			return '.' + x;
		})
		.join(',');

	deletedMode = false;

	selectAllActive = false;

	hasFuelSensor = null;
	// tankCapacity = null;
	confirmInstallationDate = null;

	sortOrder = '';
	activeSort = '';
	// Status Filter
	activeFilters = {
		organisations: [],
		type: [],
		immobilizer: '',
		fuel: '',
		status: [],
		sensors: [],
		startDate: '',
		endDate: '',
		relayStatus: [],
		isFMSVehicle: null,
	};
	// activeDateFilter = false;
	newRow = false;
	pickupBus = '';
	dropBus = '';

	constructor(props) {
		super(props);

		this.fileInputRef = React.createRef();

		this.state = {
			devices: [],
			organisations: [],
			vehicleGroups: [],
			geofences: [],
			selectedVehicleGroups: [],
			selectedReason: 0,
			vehicleTypeOptions: [],
			vehicleStatusOptions: [],
			manageAccess: false,
			createAccess: false,
			readAccess: false,
			updateAccess: false,
			deleteAccess: false,
			readDiagnosticsAccess: false,
			manageDiagnosticsAccess: false,
			deleteDiagnosticsAccess: false,
			createDiagnosticsAccess: false,
			updateDiagnosticsAccess: false,

			readConfigurationsAccess: false,
			manageConfigurationsAccess: false,
			deleteConfigurationsAccess: false,
			createConfigurationsAccess: false,
			updateConfigurationsAccess: false,

			driversDropdownOptions: [],

			// Bulk Update

			installationDateBulk: null,
			odometerBulk: null,
			isFMSBulk: false,
			speedLimitBulk: null,
			idlingDurationBulk: null,
			parkingDurationBulk: null,
			makeBulk: null,
			modelBulk: null,
			noteBulk: null,
			expectedMileageBulk: null,
			vehicleTypeBulk: null,
			vehicleStatusBulk: null,

			registrationNumberFE: null,
			// acStatusFE: null,
			typeFE: null,
			makeFE: null,
			modelFE: null,
			chassisNumberFE: null,
			speedLimitFE: null,
			expectedMileageFE: null,
			idlingDurationFE: null,
			parkingDurationFE: null,
			driverFE: null,
			noteFE: null,
			// hasImmobilizerFE: null,
			// immobilizerFE: null,
			// fuelDataSourceFE: null,
			geofenceFE: null,
			// isOBDFE:null,
			// hasFuelSensorFE: null,
			// tankCapacityFE: null,
			llsCountFE: null,
			odometerFE: null,
			statusFE: null,
			trialEndDateFE: null,
			trialEndDateSC: null,
			sensorsFE: null,
			installationDateFE: null,
			createdDateFE: null,
			openConfirmForVehicle: false,

			deviceIMEIFA: null,
			registrationNumberFA: null,
			organisationFA: null,
			makeFA: null,
			modelFA: null,
			// acStatusFA: false,
			chassisNumberFA: null,
			noteFA: null,
			invoiceNumberFA: null,
			expectedMileageFA: null,
			installationDateFA: null,
			createdDateFA: null,

			isConfirmationOpen: false,

			vehicleGroupFA: [],
			geofenceFA: [],
			vehicleTypeFA: null,
			// hasImmobilizerFA: 'No Immobilizer',
			hasFuelSensorFA: false,
			// tankCapacityFA: false,
			odometerFA: null,
			statusFA: null,
			sensorsFA: null,
			idlingDurationFA: null,
			parkingDurationFA: null,
			trialEndDate: null,
			speedLimitFA: null,
			fuelDataSourceFA: 'No Fuel',

			pingState: false,
			countOfFuelSensor: '',
			hasAnalog: false,

			// Recalculation
			selectedOrganisations: [],
			allOrganisations: true,
			vehiclesFRC: [],
			selectedVehicles: [],
			allVehicles: false,
			startTime: `${moment().startOf('day').format('DD-MM-YYYY')}`,
			enableForce: false,

			// Edit Org.
			selectedOrganisation: '',
			organisationsFEO: [],
			vehicleGroupsFEO: [],
			selectedVehicleGroupsFEO: '',

			lastLocation: {},
			sortedVehicles: [],

			anyRowSelected: false,
			selectedRows: new Set(),

			deletedMode: false,

			// Org. History
			organisationHistory: [],
			orgHistoryLoading: false,

			// Service History
			serviceHistory: [],
			serviceHistoryLoading: false,

			commandsHistory: null,
			commandsHistoryLoading: false,

			//Filter
			filters: {
				organisations: [],
				type: [],
				immobilizer: [],
				fuel: [],
				status: [],
				sensors: [],
				startDate: '',
				endDate: '',
				relayStatus: [],
				isFMSVehicle: null,
			},

			fileName: 'Vehicles',
			fileExtension: 'pdf',

			showLogsComponent: false,
			vehicleDetails: null,
			initialLoading: true,

			vehicles: [],

			// Activation
			activation: false,

			// modal
			swapDeviceConfirmation: false,
			installationDateConfirmation: false,
			vehicleBulkUpdateModal: false,
			searchQuery: '',
			accordionWidth: 200,
			showCheckBox: false,
			isFMSVehicleFA: false,
			isFMSVehicleFE: null,
			isDeviceImeiModal: false,
		};

		this.toggleDeletedMode = this.toggleDeletedMode.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.add = this.add.bind(this);
		this.cleanAddOperation = this.cleanAddOperation.bind(this);
		this.export = this.export.bind(this);
		this.updateVehicles = this.updateVehicles.bind(this);
		this.cleanEditOperation = this.cleanEditOperation.bind(this);
		this.cleanDeleteOperation = this.cleanDeleteOperation.bind(this);
		this.cleanRestoreOperation = this.cleanRestoreOperation.bind(this);
		this.handleDataSizeLimitChange = this.handleDataSizeLimitChange.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.updateVehicleGroup = this.updateVehicleGroup.bind(this);
		this.getFilteredVehicles = this.getFilteredVehicles.bind(this);
		this.cleanVGOperation = this.cleanVGOperation.bind(this);
		this.cleanSwapOperation = this.cleanSwapOperation.bind(this);
		this.swapDevice = this.swapDevice.bind(this);
		this.updateVehicleGroup = this.updateVehicleGroup.bind(this);
		this.handleUpdateSelection = this.handleUpdateSelection.bind(this);
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.deleteVehicle = this.deleteVehicle.bind(this);
		this.restoreVehicle = this.restoreVehicle.bind(this);
		this.sort = this.sort.bind(this);
		this.cleanOrgHistoryOperation = this.cleanOrgHistoryOperation.bind(this);
		this.cleanUpdateOrgOperation = this.cleanUpdateOrgOperation.bind(this);
		this.loadVehicleTypes = this.loadVehicleTypes.bind(this);
		this.loadVehicleStatus = this.loadVehicleStatus.bind(this);
		this.addRow = this.addRow.bind(this);
		this.removeRow = this.removeRow.bind(this);
		this.onDriverChooseFE = this.onDriverChooseFE.bind(this);
		// this.handleInputFile = this.handleInputFile.bind(this);
		// this.onTankCapacityChange = this.onTankCapacityChange.bind(this);
		this.cleanUpdateCalibrationOperation = this.cleanUpdateCalibrationOperation.bind(this);
		this.recalculate = this.recalculate.bind(this);
		// this.newrecalculate = this.newrecalculate.bind(this);
		this.cleanRecalculationOperation = this.cleanRecalculationOperation.bind(this);
		this.getRows = this.getRows.bind(this);
		this.cleanSelectedRows = this.cleanSelectedRows.bind(this);
		this.toggleEnableForce = this.toggleEnableForce.bind(this);
		this.onStatusChangeFA = this.onStatusChangeFA.bind(this);
		this.onSensorChangeFA = this.onSensorChangeFA.bind(this);
		this.onTrialDateChange = this.onTrialDateChange.bind(this);
		this.onRelayFilterChange = this.onRelayFilterChange.bind(this);

		this.onIdlingDurationChange = this.onIdlingDurationChange.bind(this);
		this.onParkingDurationChange = this.onParkingDurationChange.bind(this);

		this.onDeviceChoose = this.onDeviceChoose.bind(this);
		this.onReasonChoose = this.onReasonChoose.bind(this);

		this.onVehicleGroupUpdate = this.onVehicleGroupUpdate.bind(this);

		this.onOrgChooseFEO = this.onOrgChooseFEO.bind(this);
		this.onVGChooseFEO = this.onVGChooseFEO.bind(this);
		this.onAnalogChange = this.onAnalogChange.bind(this);
		this.onFuelSensorChange = this.onFuelSensorChange.bind(this);

		this.onOrgChecked = this.onOrgChecked.bind(this);
		this.onOrgSelect = this.onOrgSelect.bind(this);
		this.onAllVehicleChecked = this.onAllVehicleChecked.bind(this);
		this.onVehicleSelect = this.onVehicleSelect.bind(this);

		this.onDeviceModelChoose = this.onDeviceModelChoose.bind(this);
		this.onRegNumberChange = this.onRegNumberChange.bind(this);
		this.onVehicleTypeChoose = this.onVehicleTypeChoose.bind(this);
		this.onMakeChange = this.onMakeChange.bind(this);
		this.onModelChange = this.onModelChange.bind(this);
		this.onChassisNumberChange = this.onChassisNumberChange.bind(this);
		this.onNoteChange = this.onNoteChange.bind(this);
		this.onExpectedMileageChange = this.onExpectedMileageChange.bind(this);
		this.onOrgChange = this.onOrgChange.bind(this);
		this.onVGChange = this.onVGChange.bind(this);
		this.onGeofenceChange = this.onGeofenceChange.bind(this);
		this.onOdometerChange = this.onOdometerChange.bind(this);
		this.onInstallationDateChange = this.onInstallationDateChange.bind(this);
		// this.onImmobilizerChoose = this.onImmobilizerChoose.bind(this);
		this.onFuelSensorChoose = this.onFuelSensorChoose.bind(this);
		// this.onFuelDataSourceChoose = this.onFuelDataSourceChoose.bind(this);
		this.onAnalogChoose = this.onAnalogChoose.bind(this);
		this.getStatusOption = this.getStatusOption.bind(this);

		this.cleanCommandsHistoryOperation = this.cleanCommandsHistoryOperation.bind(this);
		this.fileExtensionChange = this.fileExtensionChange.bind(this);
		this.fileNameChange = this.fileNameChange.bind(this);
		this.closeLogsComponent = this.closeLogsComponent.bind(this);
		this.handleChangeDate = this.handleChangeDate.bind(this);
		this.handleClearDate = this.handleClearDate.bind(this);
		this.onTypeFilterChange = this.onTypeFilterChange.bind(this);
		this.onImmobilizerFilterChange = this.onImmobilizerFilterChange.bind(this);
		this.onFuelSensorFilterChange = this.onFuelSensorFilterChange.bind(this);
		this.onStatusFilterChange = this.onStatusFilterChange.bind(this);
		this.onSensorsFilterChange = this.onSensorsFilterChange.bind(this);
		this.cleanServiceHistoryOperation = this.cleanServiceHistoryOperation.bind(this);
		this.onOrganisationFilterChange = this.onOrganisationFilterChange.bind(this);
		// this.onACStatusChange = this.onACStatusChange.bind(this);
		this.manageAccessSetter = this.manageAccessSetter.bind(this);
		this.handleCloseConfirmation = this.handleCloseConfirmation.bind(this);
		this.handleChangeStatus = this.handleChangeStatus.bind(this);
		this.handleSearchQuery = this.handleSearchQuery.bind(this);
		this.handleDoubleClick = this.handleDoubleClick.bind(this);
		this.handleIconClick = this.handleIconClick.bind(this);
		this.onBulkInstallationDateChange = this.onBulkInstallationDateChange.bind(this);
		this.bulkUpdateVehicles = this.bulkUpdateVehicles.bind(this);
		this.cleanBulkUpdateOperations = this.cleanBulkUpdateOperations.bind(this);
		this.onVehicleTypeChange = this.onVehicleTypeChange.bind(this);
		this.onVehicleStatusChange = this.onVehicleStatusChange.bind(this);
		this.onIsFMSChangeBulk = this.onIsFMSChangeBulk.bind(this);
		this.onisFMSVehicleFAChange = this.onisFMSVehicleFAChange.bind(this);
		this.onIsFMSFilterChange = this.onIsFMSFilterChange.bind(this);

		// this.unsubscribeFromFirebase = this.unsubscribeFromFirebase.bind(this);
	}

	manageAccessSetter(rule, action) {
		this.setState({ [action]: rule });
	}

	componentDidMount() {
		const { location } = this.props;
		const relayStatus = location.state;

		this.pickupBus = location?.state?.pickupBus;
		this.vehicleImei = location?.state?.vehicleImei;
		this.dropBus = location?.state?.dropBus;
		this.deviceStatus = location?.state?.deviceStatus;

		if (this.pickupBus) {
			this.setState({ searchQuery: this.pickupBus });
			this.handleSearch({ target: { value: this.pickupBus } });
			return;
		}
		if (this.dropBus) {
			this.setState({ searchQuery: this.dropBus });
			this.handleSearch({ target: { value: this.dropBus } });
			return;
		}
		if (this.deviceStatus) {
			this.setState({ searchQuery: this.deviceStatus });
			this.handleSearch({ target: { value: this.deviceStatus } });
			return;
		}

		if (this.props.searchQuery) {
			dispatch('UPDATE_SEARCH_QUERY', this.props.searchQuery);
			this.handleSearch({ target: { value: this.props.searchQuery } });
			return;
		}

		let path = window.location.pathname.split('/');
		if (path.length > 3) {
			this.vehicleNumber = decodeURIComponent(path[3]);

			if (this.vehicleNumber) {
				this.setState({ searchQuery: this.vehicleNumber });
				this.handleSearch({ target: { value: this.vehicleNumber } });
				return;
			}
		}
		trackMixpanelEvent('Vehicles', { Action: 'View' });
		const permissions = JSON.parse(localStorage.getItem('permissions'));

		const vehiclePermissions = permissions?.filter((permission) => permission.subject === 'Vehicle');
		if (vehiclePermissions) {
			for (const vehiclePrm of vehiclePermissions) {
				accessSetter(vehiclePrm?.action, this.manageAccessSetter);
			}
		}

		const logsPermissions = permissions?.filter((permission) => permission.subject === 'Diagnostics');
		if (logsPermissions) {
			for (const logsPrm of logsPermissions) {
				entityAccessSetter(logsPrm?.action, 'Diagnostics', this.manageAccessSetter);
			}
		}

		const configPermissions = permissions?.filter((permission) => permission.subject === 'Configurations');
		if (configPermissions) {
			for (const configPrm of configPermissions) {
				entityAccessSetter(configPrm?.action, 'Configurations', this.manageAccessSetter);
			}
		}

		if (this.props.location.state) {
			let { status } = this.props.location.state;
			let { startDate, endDate, orgId } = this.props.location.state;
			let query = '';
			if (status) {
				query += `&status[]=${Object.values(status).join('&status[]=')}`;
				this.setState({
					filters: { ...this.state.filters, status: Object.values(status) },
				});
				this.activeFilters = {
					...this.activeFilters,
					status: Object.values(status),
				};
			}

			if (orgId) {
				query += `&organisations[]=${orgId}`;
				this.setState({
					filters: { ...this.state.filters, organisations: [orgId] },
				});
				this.activeFilters = {
					...this.activeFilters,
					organisations: [orgId],
				};
			}

			if (relayStatus?.immobilizedVehicles) {
				query += `&relayStatus=true&status[]=Active&status[]=Trial`;
				this.setState({
					filters: { ...this.state.filters, relayStatus: [true], status: { active: 'Active', trial: 'Trial' } },
				});
				this.activeFilters = {
					...this.activeFilters,
					relayStatus: [true],
					status: { active: 'Active', trial: 'Trial' },
				};
			}
			if (relayStatus?.mobilizedVehicles) {
				query += `&relayStatus&status[]=Active&status[]=Trial`;
				this.setState({
					filters: { ...this.state.filters, relayStatus: [false], status: { active: 'Active', trial: 'Trial' } },
				});
				this.activeFilters = {
					...this.activeFilters,
					relayStatus: [false],
					status: { active: 'Active', trial: 'Trial' },
				};
			}

			if (startDate && endDate) {
				query += `&installationStartDate=${moment(startDate, 'DD-MM-YYYY').toISOString()}&installationEndDate=${moment()
					.endOf('day')
					.toISOString()}`;
				this.setState({
					filters: { ...this.state.filters, startDate: startDate, endDate: endDate },
				});
				this.activeFilters = {
					...this.activeFilters,
					startDate: startDate,
					endDate: endDate,
				};
			}

			refreshVehicles(query);
		} else if (this.props.filters.organisations.length === 0) {
			refreshVehicles();
		}

		if (this.props.filters.organisations.length > 0) {
			this.activeFilters = this.state.filters;
			this.activeFilters.organisations = this.props.filters.organisations;
			this.getFilteredVehicles();
			return;
		}
		this.loadVehicleTypes();
		this.loadVehicleStatus();

		window.history.replaceState(null, null, window.location.pathname);
	}

	getVehiclesAccordingPagination(vehicles, pagenumber) {
		let data = [];
		let sorted = [];

		if (this.activeSort === 'lastData' && this.state.sortedVehicles.length) {
			if (this.sortOrder === 'asc') {
				this.state.sortedVehicles.forEach((id) => {
					vehicles.every((element) => {
						if (element.id === Number(id)) {
							sorted.push(element);
							return false;
						} else return true;
					});
				});
			} else {
				for (let x = this.state.sortedVehicles.length - 1; x >= 0; x--) {
					vehicles.every((element) => {
						if (element.id === Number(this.state.sortedVehicles[x])) {
							sorted.push(element);
							return false;
						} else return true;
					});
				}
			}
		}

		if (sorted.length) {
			sorted.forEach((vehicle, index) => {
				if (index >= this.props.dataSizeLimit * (pagenumber - 1) && index < this.props.dataSizeLimit * pagenumber) {
					data.push(vehicle);
				}
			});
		} else {
			if (vehicles.length) {
				vehicles.forEach((vehicle, index) => {
					if (index >= this.props.dataSizeLimit * (pagenumber - 1) && index < this.props.dataSizeLimit * pagenumber) {
						data.push(vehicle);
					}
				});
			}
		}

		return data;
	}

	// Fetch
	getFilteredVehicles(activePage = 1, resetAccordion = true) {
		if (resetAccordion) {
			dispatch('VEHICLES_TOGGLE_ACCORDION', -1);
		}

		let query = '';
		if (this.state.searchQuery !== '') {
			query = query + searchVehicles(this.state.searchQuery);
		}
		if (this.activeSort !== '' && this.sortOrder !== '') {
			if (this.sortOrder === 'asc') {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			} else {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			}
		}

		if (this.deletedMode) {
			query = query + '&deleted=true';
		}

		if (this.activeFilters?.organisations?.length) {
			query =
				query + this.activeFilters.organisations.map((organisation) => `&organisations[]=${organisation}`).join('');
		}

		if (this.activeFilters?.type?.length) {
			query = query + this.activeFilters.type.map((type) => `&type[]=${type}`).join('');
		}

		// if (this.activeFilters.immobilizer.length) {
		// 	query = query + this.activeFilters.immobilizer.map((immobilizer) => `&immobilizer[]=${immobilizer}`).join('');
		// }
		//
		// if (this.activeFilters.fuel.length) {
		// 	query = query + this.activeFilters.fuel.map((fuel) => `&fuelDataSource[]=${fuel}`).join('');
		// }

		if (this.activeFilters?.status?.length) {
			query = query + this.activeFilters.status.map((status) => `&status[]=${status}`).join('');
		}

		if (this.activeFilters?.sensors?.length) {
			query = query + this.activeFilters.sensors.map((sensor) => `&sensors[]=${sensor}`).join('');
		}

		if (this.activeFilters?.relayStatus?.length) {
			if (this.activeFilters.relayStatus.includes(true)) {
				query += '&relayStatus=true';
			} else {
				query += '&relayStatus';
			}
		}
		if (this.activeFilters?.isFMSVehicle) {
			if (this.activeFilters.isFMSVehicle.includes(1)) {
				query = query + `&isFMSVehicle=true`;
			} else {
				query = query + `&isFMSVehicle`;
			}
		}

		if (this.activeFilters?.startDate) {
			query =
				query +
				`&installationStartDate=${moment(
					this.state.filters.startDate,
					'DD-MM-YYYY'
				).toISOString()}&installationEndDate=${moment(this.state.filters.endDate, 'DD-MM-YYYY')
					.endOf('day')
					.toISOString()}`;
		}

		refreshVehicles(query, activePage);
	}

	// subscribeToFirebase(vehicles) {
	// 	// this.unsubscribeFromFirebase();
	// 	let firebaseReference = null;
	// 	vehicles.forEach((vehicle) => {
	// 		firebaseReference = ref(database, `/${vehicle.id}-${vehicle.registrationNumber}/location`);
	// 		get(firebaseReference).then((snapshot) => {
	// 			if (snapshot.exists()) {
	// 				let localState = this.state.lastLocation;
	// 				localState[vehicle.id] = snapshot.val().timestamp ? snapshot.val().timestamp : null;
	// 				this.setState({
	// 					lastLocation: localState,
	// 				});
	// 			}
	// 		});
	// 		onChildChanged(firebaseReference, (data) => {
	// 			if (data.key === 'timestamp') {
	// 				let localState = this.state.lastLocation;
	// 				localState[vehicle.id] = data.val();
	// 				this.setState({
	// 					lastLocation: localState,
	// 				});
	// 			}
	// 		});
	// 		// onValue(firebaseReference, (snapshot) => {
	// 		// 	let localState = this.state.lastLocation;
	// 		// 	localState[vehicle.id] = snapshot.val()?.timestamp
	// 		// 		? snapshot.val().timestamp
	// 		// 		: Object.hasOwn(localState, vehicle.id)
	// 		// 		? localState[vehicle.id]
	// 		// 		: null;
	// 		// 	this.setState({
	// 		// 		lastLocation: localState,
	// 		// 	});
	// 		// });
	// 		this.firebaseReferences.push(firebaseReference);
	// 	});
	// }

	handleSearch(e) {
		if (this.pickupBus) {
			this.setState({ searchQuery: this.pickupBus });
			dispatch('UPDATE_SEARCH_QUERY', this.pickupBus);
		}
		if (this.dropBus) {
			this.setState({ searchQuery: this.dropBus });
			dispatch('UPDATE_SEARCH_QUERY', this.dropBus);
		}
		if (this.deviceStatus) {
			this.setState({ searchQuery: this.deviceStatus });
			dispatch('UPDATE_SEARCH_QUERY', this.deviceStatus);
		}
		if (this.vehicleImei) {
			this.setState({ searchQuery: this.vehicleImei });
			dispatch('UPDATE_SEARCH_QUERY', this.vehicleImei);
		}
		if (this.vehicleNumber) {
			this.setState({ searchQuery: this.vehicleNumber });
			dispatch('UPDATE_SEARCH_QUERY', this.vehicleNumber);
		} else {
			this.setState({ searchQuery: e.target.value });
			dispatch('UPDATE_SEARCH_QUERY', e.target.value);
		}
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}

		this.timeoutId = setTimeout(() => {
			this.getFilteredVehicles();
			this.cleanSelectedRows();
		}, 500);
	}

	// handleInputFile(e) {
	//   const files = e.target.files;
	//
	//   if (files && files[0] && files[0].type === 'text/xml') {
	//     const reader = new FileReader();
	//
	//     reader.readAsText(files[0]);
	//
	//     reader.onloadend = (evt) => {
	//       const readerData = evt.target.result;
	//
	//       const parser = new DOMParser();
	//       const xml = parser.parseFromString(readerData, 'text/xml');
	//       let NewXml = new XMLParser().parseFromString(new XMLSerializer().serializeToString(xml.documentElement)); // Assume xmlText contains the example XML
	//
	//       this.setState({
	//         countOfFuelSensor: '',
	//       });
	//       this.calibrationValue = [];
	//       this.formatData(NewXml);
	//     };
	//   } else generateErrorMessage('Only xml file types are allowed.');
	// }

	toggleDeletedMode() {
		this.deletedMode = !this.deletedMode;
		this.setState({
			deletedMode: !this.state.deletedMode,
		});

		this.getFilteredVehicles();
		this.cleanSelectedRows();
	}

	// Export
	fileNameChange(e) {
		this.setState({ fileName: e.target.value });
	}

	fileExtensionChange(e, { value }) {
		this.setState({ fileExtension: value });
	}

	handleUpdateSelection(checkedData, id) {
		let selectedRows = this.state.selectedRows;

		if (selectedRows.has(id)) {
			selectedRows.delete(id);
		} else selectedRows.add(id);

		this.setState({
			selectedRows: selectedRows,
			anyRowSelected: !!selectedRows.size,
		});
	}

	handleSelectAll(checked) {
		let selectedRows = new Set();

		if (checked) {
			this.selectAllActive = true;
			this.props.vehicles.forEach((vehicle) => selectedRows.add(vehicle.id));
			this.setState({
				selectedRows: selectedRows,
				anyRowSelected: !!selectedRows.size,
			});
		} else {
			this.selectAllActive = false;
			this.setState({
				selectedRows: new Set(),
				anyRowSelected: !!selectedRows.size,
			});
		}
	}

	export() {
		const { vehicles } = this.props;

		let vehiclesToExport = [];
		vehicles.forEach((vehicle) => {
			if (this.state.selectedRows.has(vehicle.id)) {
				vehiclesToExport.push(vehicle);
			}
		});

		return vehiclesToExport;
	}

	getFilterListQuery() {
		let query = '';

		query = query + `?fileType=${this.state.fileExtension}`;

		if (this.deletedMode) {
			query = query + '&deleted=1';
		}

		if (this.state.searchQuery !== '') {
			query = query + searchUsers(this.state.searchQuery);
		}

		if (this.activeFilters.type.length) {
			query = query + `&type[]=${this.activeFilters.type.join(',')}`;
		}

		if (this.activeFilters?.organisations?.length) {
			query =
				query + this.activeFilters.organisations.map((organisation) => `&organisations[]=${organisation}`).join('');
		}

		if (this.activeFilters.immobilizer.length && this.activeFilters.immobilizer.length < 2) {
			query = query + `&hasImmobilizer[]=${this.activeFilters.immobilizer.join(',')}`;
		}

		if (this.activeFilters.fuel.length && this.activeFilters.fuel.length < 2) {
			query = query + `&hasFuelSensor[]=${this.activeFilters.fuel.join(',')}`;
		}

		if (this.activeSort !== '' && this.sortOrder !== '') {
			if (this.sortOrder === 'asc') {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			} else {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			}
		}

		if (this.activeFilters.status.length) {
			query = query + `&status[]=${this.activeFilters.status.join(',')}`;
		}

		if (this.activeFilters?.relayStatus?.length) {
			if (this.activeFilters.relayStatus.includes(true)) {
				query += '&relayStatus=true';
			} else {
				query += '&relayStatus';
			}
		}

		if (this.activeFilters?.sensors?.length) {
			query = query + this.activeFilters.sensors.map((sensor) => `&sensors[]=${sensor}`).join('');
		}

		if (this.activeFilters?.isFMSVehicle) {
			if (this.activeFilters.isFMSVehicle.includes(1)) {
				query = query + `&isFMSVehicle=true`;
			} else {
				query = query + `&isFMSVehicle`;
			}
		}

		// if (this.activeFilters?.startDate) {
		// 	query =
		// 		query +
		// 		`&startDate=${moment(this.state.filters.startDate, 'DD-MM-YYYY').toISOString()}&endDate=${moment(
		// 			this.state.filters.endDate,
		// 			'DD-MM-YYYY'
		// 		)
		// 			.endOf('day')
		// 			.toISOString()}`;
		// }
		if (this.activeFilters?.startDate) {
			query =
				query +
				`&installationStartDate=${moment(
					this.state.filters.startDate,
					'DD-MM-YYYY'
				).toISOString()}&installationEndDate=${moment(this.state.filters.endDate, 'DD-MM-YYYY')
					.endOf('day')
					.toISOString()}`;
		}

		return query;
	}

	// Add
	add() {
		const {
			vehicleGroupFA,
			geofenceFA,
			deviceIMEIFA,
			installationDateFA,
			createdDateFA,
			registrationNumberFA,
			organisationFA,
			noteFA,
			invoiceNumberFA,
			expectedMileageFA,
			makeFA,
			modelFA,
			chassisNumberFA,
			vehicleTypeFA,
			// hasImmobilizerFA,
			// acStatusFA,
			// hasFuelSensorFA,
			// fuelDataSourceFA,
			odometerFA,
			// tankCapacityFA,
			idlingDurationFA,
			parkingDurationFA,
			statusFA,
			sensorsFA,
			trialEndDate,
			speedLimitFA,
			isFMSVehicleFA,
		} = this.state;
		let vehicleGroups;
		let geofenceData;

		// if (registrationNumberFA.match(/(\r\n|\n|\r|\t|\.||\$|\[|\])/gm)) {
		// 	return showMessage('Registration no can not have .,#,$,[,]', MessageType.Error);
		// }

		// if (statusFA === 7 && !trialEndDate) {
		// 	return showMessage('Please select trial end date.', MessageType.Error);
		// }

		// if (fuelDataSourceFA > 0 && !tankCapacityFA) {
		//   return showMessage('Please, enter tank capacity of vehicle.', MessageType.Error);
		// }

		// if ((fuelDataSourceFA === 1 || fuelDataSourceFA === 2) && !this.state.countOfFuelSensor) {
		//   return showMessage('Please, select numbers of sensors.', MessageType.Error);
		// }

		if (vehicleGroupFA === null) {
			vehicleGroups = '';
		} else {
			vehicleGroups = vehicleGroupFA.map((group) => {
				return {
					id: group,
				};
			});
		}

		if (geofenceFA?.length) {
			geofenceData = geofenceFA.map((geofence) => {
				return {
					id: geofence,
				};
			});
		}

		let query = {};
		if (deviceIMEIFA)
			query = {
				device: {
					imei: String(deviceIMEIFA.trim()),
				},
			};

		if (installationDateFA)
			query = { ...query, installationDate: moment(installationDateFA, 'DD-MM-YYYY').toISOString() };

		if (createdDateFA) query = { ...query, createdDate: moment(createdDateFA, 'DD_MM_YYYY').toISOString() };

		if (trialEndDate) query = { ...query, trialEndDate: moment(trialEndDate, 'DD-MM-YYYY').toISOString() };

		if (registrationNumberFA) query = { ...query, registrationNumber: registrationNumberFA.trim() };

		if (organisationFA)
			query = {
				...query,
				organisation: {
					id: organisationFA,
				},
			};

		if (makeFA) query = { ...query, make: makeFA.trim() };

		if (modelFA) query = { ...query, model: modelFA.trim() };

		if (chassisNumberFA) query = { ...query, chassisNumber: chassisNumberFA.trim() };

		if (noteFA) query = { ...query, note: noteFA.trim() };

		if (invoiceNumberFA) query = { ...query, invoiceDate: invoiceNumberFA.trim() };

		if (expectedMileageFA) query = { ...query, expectedMileage: expectedMileageFA.trim() };

		// if (tankCapacityFA) query = {...query, tankCapacity: Number(tankCapacityFA.trim())};

		if (vehicleGroups.length) query = { ...query, vehicleGroups: vehicleGroups };

		if (vehicleTypeFA) query = { ...query, type: vehicleTypeFA };

		if (geofenceData?.length) query = { ...query, geofences: geofenceData };

		// if (hasImmobilizerFA) query = { ...query, immobilizer: hasImmobilizerFA };

		if (statusFA) query = { ...query, status: statusFA };
		if (sensorsFA) query = { ...query, sensors: sensorsFA };

		if (odometerFA) query = { ...query, odometerReading: Number(odometerFA.trim()) * 1000 || 0 };
		// if (acStatusFA) query = { ...query, acStatus: acStatusFA };
		if (idlingDurationFA) query = { ...query, idlingDuration: parseInt(idlingDurationFA) };
		if (parkingDurationFA) query = { ...query, parkingDuration: parseInt(parkingDurationFA) };
		if (speedLimitFA) query = { ...query, speedLimit: parseInt(speedLimitFA) };

		query = {
			...query,
			// fuelDataSource: fuelDataSourceFA,
		};

		query = { ...query, isFMSVehicle: isFMSVehicleFA };
		// let data = [];
		// this.calibrationValue.forEach((values) => {
		//   let filtered = values.filter(function (el) {
		//     return el !== '';
		//   });
		//   if (filtered.length) {
		//     values.forEach((value, innerIndex) => {
		//       if (innerIndex < values.length - 1) {
		//         data.push({
		//           sensorId: innerIndex + 1,
		//           ...(!this.state.hasAnalog
		//             ? {llsValue: value ? parseInt(value) : null}
		//             : {analogValue: value ? parseInt(value) / 100 : null}),
		//           litre: Number(values[values.length - 1]),
		//         });
		//       }
		//     });
		//   }
		// });

		// if (!data.length && (fuelDataSourceFA === 'Digital LLS' || fuelDataSourceFA === 'Analog LLS')) {
		//   return showMessage('Please, add fuel calibration data.', MessageType.Error);
		// }

		// if (fuelDataSourceFA === 'Digital LLS' || fuelDataSourceFA === 'Analog LLS') {
		//   for (let a = 0; a < data.length; a++) {
		//     if (!this.state.hasAnalog ? data[a].llsValue === null : data[a].analogValue === null) {
		//       return showMessage('fuel sensor value required.', MessageType.Error);
		//     }
		//   }
		// }

		// if (fuelDataSourceFA === 'Digital LLS' || fuelDataSourceFA === 'Analog LLS') {
		//   if (!this.state.hasAnalog ? data[0].llsValue !== 0 : data[0].analogValue !== 0) {
		//     return showMessage('first value of fuel calibration should be 0.', MessageType.Error);
		//   }
		// }

		// if (fuelDataSourceFA > 0) {
		//   if (data[data.length - 1]?.litre !== Number(tankCapacityFA)) {
		//     return showMessage('highest fuel reading not match with tank capacity.', MessageType.Error);
		//   }
		// }

		// if (fuelDataSourceFA === 'Digital LLS' || fuelDataSourceFA === 'Analog LLS') {
		//   query = {
		//     ...query,
		//     fuelDataSource: fuelDataSourceFA,
		//     llsCount: this.state.countOfFuelSensor,
		//     fuelCalibration: data,
		//   };
		// } else {
		//   query = {
		//     ...query,
		//     fuelDataSource: fuelDataSourceFA,
		//   };
		// }
		dispatchNoPayload('VEHICLES_ADDING');
		// axios
		// 	.post(VEHICLES_API, query, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 			'Content-Type': 'application/json',
		// 		},
		// 	})
		postData(VEHICLES_API, '', query)
			.then((response) => {
				dispatchNoPayload('VEHICLES_ADDED');
				generateSuccessMessage(response);
				this.getFilteredVehicles(this.props.pageNumber);
				this.cleanAddOperation();
			})
			.catch((error) => {
				dispatchNoPayload('VEHICLES_ADDED');
				datadogLogs.logger.error('Vehicle add api', {}, error);
				handleError(error);
			});
	}

	bulkUpdateVehicles() {
		const {
			installationDateBulk,
			odometerBulk,
			speedLimitBulk,
			idlingDurationBulk,
			parkingDurationBulk,
			makeBulk,
			modelBulk,
			noteBulk,
			expectedMileageBulk,
			isFMSBulk,
			vehicleTypeBulk,
			vehicleStatusBulk,
		} = this.state;

		let selectedRows = Array.from(this.state.selectedRows);
		selectedRows = selectedRows.map((row) => row.toString());
		this.setState({ openConfirm: false });
		let vehiclePayload = {};
		if (odometerBulk)
			vehiclePayload = {
				...vehiclePayload,
				odometerReading: Number(odometerBulk.trim()) * 1000 || 0,
			};
		if (speedLimitBulk) vehiclePayload = { ...vehiclePayload, speedLimit: parseInt(speedLimitBulk) };
		if (installationDateBulk) {
			vehiclePayload = {
				...vehiclePayload,
				installationDate: moment(installationDateBulk, 'DD-MM-YYYY').toISOString(),
			};
		}
		if (idlingDurationBulk) vehiclePayload = { ...vehiclePayload, idlingDuration: parseInt(idlingDurationBulk) };
		if (parkingDurationBulk) vehiclePayload = { ...vehiclePayload, parkingDuration: parseInt(parkingDurationBulk) };
		if (makeBulk) vehiclePayload = { ...vehiclePayload, make: makeBulk };
		if (modelBulk) vehiclePayload = { ...vehiclePayload, model: modelBulk };
		if (noteBulk) vehiclePayload = { ...vehiclePayload, note: noteBulk };
		if (expectedMileageBulk) vehiclePayload = { ...vehiclePayload, expectedMileage: expectedMileageBulk };
		if (isFMSBulk) vehiclePayload = { ...vehiclePayload, isFMSVehicle: isFMSBulk };
		if (vehicleTypeBulk) vehiclePayload = { ...vehiclePayload, type: vehicleTypeBulk };
		if (vehicleStatusBulk) vehiclePayload = { ...vehiclePayload, status: vehicleStatusBulk };

		dispatchNoPayload('VEHICLES_BULK_UPDATING');
		patchData(VEHICLES_API_BULK + `?ids=${selectedRows}`, vehiclePayload)
			.then((response) => {
				generateSuccessMessage(response);
				dispatchNoPayload('VEHICLES_BULK_UPDATED');
				this.getFilteredVehicles(this.props.pageNumber);
				this.cleanBulkUpdateOperations();
				this.cleanSelectedRows();
			})
			.catch((error) => {
				dispatchNoPayload('VEHICLES_BULK_UPDATED');
				handleError(error);
			});
		this.setState({ showCheckBox: false });
		this.cleanSelectedRows();
	}

	cleanBulkUpdateOperations() {
		this.setState({ vehicleBulkUpdateModal: false });
		this.setState({
			installationDateBulk: null,
			odometerBulk: null,
			isFMSBulk: false,
			speedLimitBulk: null,
			idlingDurationBulk: null,
			parkingDurationBulk: null,
			makeBulk: null,
			modelBulk: null,
			noteBulk: null,
			expectedMileageBulk: null,
			vehicleTypeBulk: null,
			vehicleStatusBulk: null,
		});
		this.setState({ showCheckBox: false });
		this.cleanSelectedRows();
	}

	cleanAddOperation() {
		dispatchNoPayload('VEHICLES_ADD_MODAL_CLOSE');

		this.vehicleId = '';
		this.calibrationValue = [];
		this.newRow = false;
		this.setState({
			devices: [],
			organisations: [],
			vehicleGroups: [],
			geofences: [],
			deviceIMEIFA: null,
			registrationNumberFA: null,
			// fuelDataSourceFA: 'No Fuel',
			organisationFA: null,
			makeFA: null,
			modelFA: null,
			chassisNumberFA: null,
			noteFA: null,
			invoiceNumberFA: null,
			expectedMileageFA: null,
			installationDateFA: null,
			createdDateFA: null,
			vehicleGroupFA: [],
			geofenceFA: [],
			vehicleTypeFA: null,
			// hasImmobilizerFA: 'No Immobilizer',
			hasFuelSensorFA: false,
			countOfFuelSensor: '',
			hasAnalog: false,
			odometerFA: null,
			statusFA: null,
			sensorsFA: null,
			idlingDurationFA: null,
			parkingDurationFA: null,
			// acStatusFA: false,
			trialEndDate: null,
			speedLimitFA: null,
			isFMSVehicleFA: false,
			expectedMileageFE: null,
		});
	}

	onDeviceModelChoose(e, { value }) {
		this.setState({
			deviceIMEIFA: value,
		});
	}

	// onTankCapacityChange(e) {
	//   this.setState({tankCapacityFA: e.target.value});
	// }

	onRegNumberChange(e) {
		this.setState({
			registrationNumberFA: e.target.value,
		});
	}

	onIdlingDurationChange(e) {
		this.setState({
			idlingDurationFA: e.target.value,
		});
	}

	onParkingDurationChange(e) {
		this.setState({
			parkingDurationFA: e.target.value,
		});
	}

	onVehicleTypeChoose(e, { value }) {
		this.setState({
			vehicleTypeFA: value,
		});
	}

	onMakeChange(e) {
		this.setState({ makeFA: e.target.value });
	}

	onModelChange(e) {
		this.setState({ modelFA: e.target.value });
	}

	onChassisNumberChange(e) {
		this.setState({ chassisNumberFA: e.target.value });
	}

	onNoteChange(e) {
		this.setState({ noteFA: e.target.value });
	}

	onExpectedMileageChange(e) {
		this.setState({ expectedMileageFA: e.target.value });
	}

	onOrgChange(e, { value }) {
		this.setState({
			organisationFA: value,
			vehicleGroupFA: [],
			geofenceFA: [],
		});
		getVehicleGroupsForDropdown(`?organisations[]=${value}&dropdown=true`).then((response) => {
			this.setState({ vehicleGroups: response });
		});

		// For Geofence
		getGeofenceForDropdown(`?organisations[]=${value}&dropdown=true`).then((response) => {
			this.setState({ geofences: response });
		});
	}

	onVGChange(e, { value }) {
		this.setState({ vehicleGroupFA: value });
	}

	onGeofenceChange(e, { value }) {
		this.setState({ geofenceFA: value });
	}

	onOdometerChange(e) {
		this.setState({ odometerFA: e.target.value });
	}

	onInstallationDateChange(e, data) {
		if (this.state.status && this.state.status === 7 && !this.state.trialEndDate)
			this.setState({ trialEndDate: moment(data.value, 'DD-MM-YYYY').add(30, 'days').format('DD-MM-YYYY') });

		this.setState({ installationDateFA: data.value });
	}

	// onImmobilizerChoose(e, { value }) {
	// 	this.setState({ hasImmobilizerFA: value });
	// }

	onFuelSensorChoose(e, { checked }) {
		this.setState({ hasFuelSensorFA: checked });
	}

	// onFuelDataSourceChoose(e, { value }) {
	// 	this.setState({ fuelDataSourceFA: value });
	// }

	onAnalogChoose(e, { checked }) {
		this.setState({ hasAnalog: checked });
	}

	onStatusChangeFA(e, { value }) {
		this.setState({ statusFA: value });
	}

	onSensorChangeFA(e, { value }) {
		this.setState({ sensorsFA: value });
	}

	onTrialDateChange(e, data) {
		this.setState({ trialEndDate: data.value });
	}

	onDriverChooseFE(e, { value }) {
		this.setState({ driverFE: value });
	}

	onBulkInstallationDateChange(e, data) {
		if (this.state.status && this.state.status === 7 && !this.state.trialEndDate)
			this.setState({ trialEndDate: moment(data.value, 'DD-MM-YYYY').add(30, 'days').format('DD-MM-YYYY') });

		this.setState({ installationDateBulk: data.value });
	}

	onVehicleTypeChange(e, { value }) {
		this.setState({ vehicleTypeBulk: value });
	}

	onVehicleStatusChange(e, { value }) {
		this.setState({ vehicleStatusBulk: value });
	}

	onIsFMSChangeBulk(data) {
		this.setState({ isFMSBulk: data.checked });
	}

	updateVehicles(id) {
		const {
			registrationNumberFE,
			odometerFE,
			driverFE,
			trialEndDateFE,
			installationDateFE,
			createdDateFE,
			typeFE,
			makeFE,
			modelFE,
			chassisNumberFE,
			speedLimitFE,
			expectedMileageFE,
			idlingDurationFE,
			parkingDurationFE,
			noteFE,
			geofenceFE,
			sensorsFE,
			isFMSVehicleFE,
		} = this.state;

		this.setState({ openConfirm: false });
		let vehiclePayload = {};
		if (registrationNumberFE) vehiclePayload = { ...vehiclePayload, registrationNumber: registrationNumberFE.trim() };
		if (driverFE) vehiclePayload = { ...vehiclePayload, driver: { id: driverFE } };
		if (installationDateFE) {
			vehiclePayload = {
				...vehiclePayload,
				installationDate: moment(installationDateFE, 'DD-MM-YYYY').toISOString(),
			};
		}
		if (createdDateFE) {
			vehiclePayload = {
				...vehiclePayload,
				createdDate: moment(createdDateFE, 'DD-MM-YYYY').toISOString(),
			};
		}

		if (isFMSVehicleFE !== null) vehiclePayload = { ...vehiclePayload, isFMSVehicle: isFMSVehicleFE };

		if (sensorsFE) vehiclePayload = { ...vehiclePayload, sensors: sensorsFE };
		if (trialEndDateFE)
			vehiclePayload = { ...vehiclePayload, trialEndDate: moment(trialEndDateFE, 'DD-MM-YYYY').toISOString() };

		if (geofenceFE)
			vehiclePayload = {
				...vehiclePayload,
				geofences: geofenceFE.map((id) => {
					return { id: id };
				}),
			};
		if (typeFE) vehiclePayload = { ...vehiclePayload, type: typeFE };
		vehiclePayload = makeFE === null ? vehiclePayload : { ...vehiclePayload, make: makeFE.trim() };
		vehiclePayload = modelFE === null ? vehiclePayload : { ...vehiclePayload, model: modelFE.trim() };
		vehiclePayload =
			chassisNumberFE === null ? vehiclePayload : { ...vehiclePayload, chassisNumber: chassisNumberFE.trim() };
		vehiclePayload = odometerFE === null ? vehiclePayload : { ...vehiclePayload, odometerReading: null };
		vehiclePayload = speedLimitFE === null ? vehiclePayload : { ...vehiclePayload, speedLimit: null };

		if (speedLimitFE) vehiclePayload = { ...vehiclePayload, speedLimit: parseInt(speedLimitFE) };
		if (odometerFE) {
			const odometer = parseFloat(odometerFE) * 1000;
			vehiclePayload = { ...vehiclePayload, odometerReading: odometer };
		}
		if (expectedMileageFE) vehiclePayload = { ...vehiclePayload, expectedMileage: parseInt(expectedMileageFE) };
		if (idlingDurationFE) vehiclePayload = { ...vehiclePayload, idlingDuration: parseInt(idlingDurationFE) };
		if (parkingDurationFE) vehiclePayload = { ...vehiclePayload, parkingDuration: parseInt(parkingDurationFE) };
		vehiclePayload = noteFE === null ? vehiclePayload : { ...vehiclePayload, note: noteFE.trim() };
		dispatchNoPayload('VEHICLES_UPDATING');

		// axios
		// 	.patch(VEHICLES_API + `/${id}`, vehiclePayload, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		patchData(VEHICLES_API + `/${id}`, vehiclePayload)
			.then((response) => {
				dispatchNoPayload('VEHICLES_UPDATED');
				generateSuccessMessage(response);
				this.getFilteredVehicles(this.props.pageNumber, false);
				this.cleanEditOperation();
				this.cleanUpdateCalibrationOperation();
			})
			.catch((error) => {
				dispatchNoPayload('VEHICLES_UPDATED');
				this.cleanEditOperation();
				handleError(error);
			});
	}

	handleChangeStatus(id) {
		// vehicleTrialEndDate = moment(vehicleTrialEndDate, 'DD-MM-YYYY').toISOString();
		let body;
		if (this.state.trialEndDateSC)
			body = {
				status: `${this.state.statusFE}`,
				trialEndDate: `${moment(this.state.trialEndDateSC, 'DD-MM-YYYY').toISOString()}`,
			};
		else body = { status: `${this.state.statusFE}` };

		if (this.state.statusFE) {
			patchData(VEHICLES_API + `/${id}/status`, body)
				.then((response) => {
					generateSuccessMessage(response, 'Status Changed');
					this.getFilteredVehicles(this.props.pageNumber, false);
				})
				.catch((error) => handleError(error));
		}
	}

	// updateMetaData(id) {
	// 	const {
	// 		typeFE,
	// 		makeFE,
	// 		modelFE,
	// 		// acStatusFE,
	// 		chassisNumberFE,
	// 		speedLimitFE,
	// 		noteFE,
	// 		// hasImmobilizerFE,
	// 		// isOBDFE,
	// 		//hasFuelSensorFE,
	// 		//selectedVehicleGroups,
	// 		geofenceFE,
	// 		//trialEndDateFE,
	// 	} = this.state;
	// 	this.setState({ openConfirmForMetadata: false });
	//
	// 	let metaPayload = {};
	//
	// 	dispatchNoPayload('VEHICLES_UPDATING');
	//
	// 	axios
	// 		.patch(VEHICLES_API + `/${id}/metadata`, metaPayload, {
	// 			headers: {
	// 				Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 			},
	// 		})
	// 		.then((response) => {
	// 			dispatchNoPayload('VEHICLES_UPDATED');
	// 			generateSuccessMessage(response[0]);
	// 			this.getFilteredVehicles(this.props.pageNumber, false);
	// 			this.cleanEditOperation();
	// 			this.cleanUpdateCalibrationOperation();
	// 		})
	// 		.catch((error) => {
	// 			dispatchNoPayload('VEHICLES_UPDATED');
	// 			handleError(error);
	// 		});
	// }

	cleanEditOperation() {
		dispatchNoPayload('VEHICLES_EDIT_MODAL_CLOSE');

		this.vehicleId = '';
		this.hasFuelSensor = null;
		// this.confirmInstallationDate = null;
		this.setState({
			registrationNumberFE: null,
			typeFE: null,
			makeFE: null,
			modelFE: null,
			chassisNumberFE: null,
			openConfirmForVehicle: false,
			speedLimitFE: null,
			expectedMileageFE: null,
			idlingDurationFE: null,
			parkingDurationFE: null,
			driverFE: null,
			noteFE: null,
			hasImmobilizerFE: null,
			immobilizerFE: null,
			fuelDataSourceFE: null,
			// isOBDFE:null,
			hasFuelSensorFE: null,
			odometerFE: null,
			llsCountFE: null,
			// tankCapacityFE: null,
			selectedVehicleGroups: [],
			statusFE: null,
			// acStatusFE: null,
			trialEndDateFE: null,
			installationDateFE: null,
			createdDateFE: null,
			geofenceFE: null,
			trialEndDateSC: null,
			geofences: null,
			isFMSVehicleFE: null,
			sensorsFE: null,
		});
	}

	// Vehicle Group Update
	updateVehicleGroup(id) {
		let query = {};
		if (this.state.selectedVehicleGroups) {
			query = {
				...query,
				vehicleGroups: this.state.selectedVehicleGroups.map((group) => {
					return {
						id: group,
					};
				}),
			};
		}
		dispatchNoPayload('VEHICLES_UPDATING');

		// axios
		// 	.patch(VEHICLES_API + `/${id}`, query, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		patchData(VEHICLES_API + `/${id}`, query)
			.then((response) => {
				dispatchNoPayload('VEHICLES_UPDATED');
				generateSuccessMessage(response);
				this.getFilteredVehicles(this.props.pageNumber, false);
				this.cleanVGOperation();
			})
			.catch((error) => {
				dispatchNoPayload('VEHICLES_UPDATED');
				handleError(error);
			});
	}

	cleanVGOperation() {
		dispatchNoPayload('VEHICLES_VG_MODAL_CLOSE');

		this.setState({ selectedVehicleGroups: [] });
	}

	onVehicleGroupUpdate(e, { value }) {
		this.setState({ selectedVehicleGroups: value });
	}

	// Swap Device
	swapDevice(id) {
		let query = {};
		if (this.selectedDevice) {
			query = { ...query, device: { imei: this.selectedDevice } };
		}
		if (this.state.selectedReason) {
			query = { ...query, reason: `${this.state.selectedReason}` };
		}
		dispatchNoPayload('VEHICLES_SWAPPING');

		// axios
		// 	.patch(VEHICLES_API + `/${id}/swap-device`, query, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		patchData(VEHICLES_API + `/${id}/swap-device`, query)
			.then((response) => {
				dispatchNoPayload('VEHICLES_SWAPPED');
				generateSuccessMessage(response);
				this.getFilteredVehicles(this.props.pageNumber, false);
				this.cleanSwapOperation();
			})
			.catch((error) => {
				dispatchNoPayload('VEHICLES_SWAPPED');
				handleError(error);
			});
	}

	cleanSwapOperation() {
		dispatchNoPayload('VEHICLES_SWAP_MODAL_CLOSE');

		this.deviceImei = '';
		this.vehicleID = '';
		this.selectedDevice = '';
		this.setState({
			devices: [],
			selectedReason: 0,
			swapDeviceConfirmation: false,
		});
	}

	onDeviceChoose(e, { value }) {
		this.selectedDevice = value;
	}

	onReasonChoose(e, { value }) {
		this.setState({
			selectedReason: value,
		});
	}

	// Delete
	deleteVehicle(id) {
		dispatchNoPayload('VEHICLES_DELETING');
		// axios
		// 	.delete(VEHICLES_API + `/${id}`, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		deleteData(VEHICLES_API, `/${id}`)
			.then((response) => {
				dispatchNoPayload('VEHICLES_DELETED');
				generateSuccessMessage(response);
				this.getFilteredVehicles(this.props.pageNumber);
				this.cleanDeleteOperation();
			})
			.catch((error) => {
				dispatchNoPayload('VEHICLES_DELETED');
				handleError(error);
			});
	}

	cleanDeleteOperation() {
		dispatchNoPayload('VEHICLES_DELETE_MODAL_CLOSE');
		this.deleteId = '';
	}

	restoreVehicle(id, imei) {
		dispatchNoPayload('VEHICLES_RESTORING');
		// axios
		// 	.post(
		// 		VEHICLES_API + `/${id}/restore`,
		// 		{},
		// 		{
		// 			headers: {
		// 				Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 			},
		// 		}
		// 	)
		postData(VEHICLES_API, `/${id}/restore`, {
			device: {
				imei: imei,
			},
		})
			.then((response) => {
				dispatchNoPayload('VEHICLES_RESTORED');
				generateSuccessMessage(response, 'Vehicle Restored');
				this.getFilteredVehicles(this.props.pageNumber);
				this.cleanRestoreOperation();
			})
			.catch((error) => {
				dispatchNoPayload('VEHICLES_RESTORED');
				datadogLogs.logger.error('Vehicle restore api', {}, error);
				handleError(error);
			});
	}

	cleanRestoreOperation() {
		dispatchNoPayload('VEHICLES_RESTORE_MODAL_CLOSE');

		this.vehicleRestoreId = '';
		this.vehicleRestoreImei = '';
	}

	// Org. History
	getOrganisationHistory(id) {
		this.setState({ orgHistoryLoading: true });

		// axios(OWNER_HISTORY_API + `/${id}`, {
		// 	headers: {
		// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		'Content-Type': 'application/json',
		// 	},
		// })
		getData(OWNER_HISTORY_API, `/${id}`)
			.then((response) => {
				let history = createOwnerHistoryDTO(response?.data);
				let current = {
					startDate: history.length ? history[history.length - 1].endDate : this.installationDate,
					endDate: '',
					organisation: this.organisation,
				};
				history.push(current);
				this.setState({
					organisationHistory: history,
					orgHistoryLoading: false,
				});
			})
			.catch((error) => {
				handleError(error);
				datadogLogs.logger.error('Organisation history api', {}, error);
				this.setState({ orgHistoryLoading: false });
			});
	}

	cleanOrgHistoryOperation() {
		dispatchNoPayload('VEHICLES_ORGANISATION_HISTORY_MODAL_CLOSE');

		this.vehicle = '';
		this.vehicleRegistration = '';
		this.organisation = '';
		this.installationDate = '';
		this.selectedOrganisation = '';
		this.setState({
			organisationHistory: [],
		});
	}

	// Service History
	getServiceHistory(id) {
		this.setState({ serviceHistoryLoading: true });

		// axios(CHANGE_HISTORY_API + `/vehicle/${id}`, {
		// 	headers: {
		// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		'Content-Type': 'application/json',
		// 	},
		// })
		getData(CHANGE_HISTORY_API, `/vehicle/${id}`)
			.then((response) => {
				let history = createChangeHistoryDTO(response?.data?.data);
				this.setState({
					serviceHistory: history,
					serviceHistoryLoading: false,
				});
			})
			.catch((error) => {
				handleError(error);
				this.setState({ serviceHistoryLoading: false });
			});
	}

	cleanServiceHistoryOperation() {
		dispatchNoPayload('VEHICLES_SERVICE_HISTORY_MODAL_CLOSE');

		this.vehicleRegistration = '';
		this.setState({
			serviceHistory: null,
		});
	}

	getConfigCommandsHistory(imei) {
		this.setState({ commandsHistoryLoading: true });

		// axios(COMMANDS_PIPELINE_API + `?pagination=0&device=${imei}`, {
		// 	headers: {
		// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		'Content-Type': 'application/json',
		// 	},
		// })
		getData(COMMANDS_PIPELINE_API, `?pagination=0&device=${imei}`)
			.then((response) => {
				let history = createCommandsPipelineDTO(response?.data?.data);
				this.setState({
					commandsHistory: history,
					commandsHistoryLoading: false,
				});
			})
			.catch((error) => {
				handleError(error);
				this.setState({ commandsHistoryLoading: false });
			});
	}

	cleanCommandsHistoryOperation() {
		dispatchNoPayload('VEHICLES_COMMANDS_HISTORY_MODAL_CLOSE');

		this.vehicleRegistration = '';
		this.setState({
			commandsHistory: null,
		});
	}

	changeOrganisation(id) {
		const { selectedVehicleGroupsFEO, selectedOrganisation } = this.state;

		let payload;

		payload = {
			organisation: { id: selectedOrganisation },
		};

		if (selectedVehicleGroupsFEO.length) {
			let vehicleGroup = selectedVehicleGroupsFEO.map((group) => {
				return { id: group };
			});
			payload = { ...payload, vehicleGroups: vehicleGroup };
		}

		dispatchNoPayload('VEHICLES_ORGANISATION_EDITING');
		// axios
		// 	.patch(VEHICLES_API + `/${id}/change-organisation`, payload, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		patchData(VEHICLES_API + `/${id}/change-organisation`, payload)
			.then((response) => {
				dispatchNoPayload('VEHICLES_ORGANISATION_EDITED');
				generateSuccessMessage(response);
				this.getFilteredVehicles(this.props.pageNumber, false);
				this.cleanUpdateOrgOperation();
			})
			.catch((error) => {
				dispatchNoPayload('VEHICLES_ORGANISATION_EDITED');
				datadogLogs.logger.error('Change organisation api', {}, error);
				handleError(error);
			});
	}

	cleanUpdateOrgOperation() {
		dispatchNoPayload('VEHICLES_EDIT_ORGANISATION_MODAL_CLOSE');

		this.vehicle = '';
		this.vehicleRegistration = '';
		this.organisation = '';
		this.setState({
			selectedOrganisation: '',
			organisationsFEO: [],
			vehicleGroupsFEO: [],
			selectedVehicleGroupsFEO: '',
		});
	}

	onOrgChooseFEO(e, { value }) {
		this.setState({
			selectedOrganisation: value,
			selectedVehicleGroupsFEO: '',
		});
		getVehicleGroupsForDropdown(`?organisations[]=${value}&pagenumber=1&pagecount=1000`).then((response) => {
			this.setState({ vehicleGroupsFEO: response });
		});
	}

	onVGChooseFEO(e, { value }) {
		this.setState({
			selectedVehicleGroupsFEO: value,
		});
	}

	// fetchCalibrationData(id) {
	// 	dispatchNoPayload('VEHICLES_CALIBRATION_LOADING');
	// 	axios(VEHICLES_API + `/${id}`, {
	// 		headers: {
	// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 			'Content-Type': 'application/json',
	// 		},
	// 	})
	// 		.then((response) => {
	// 			dispatchNoPayload('VEHICLES_CALIBRATION_MODAL_OPEN');
	// 			dispatchNoPayload('VEHICLES_CALIBRATION_LOADED');
	// 			handleSuccess(response);
	// 			let vehicle = createSingleVehicleDTO(response?.data);
	// 			this.setState({
	// 				llsCountFE: vehicle?.llsCount,
	// 			});
	// 			this.convertData(vehicle?.fuelCalibration);
	// 		})
	// 		.catch((error) => {
	// 			dispatchNoPayload('VEHICLES_CALIBRATION_LOADED');
	// 			handleError(error);
	// 		});
	// }

	formatData(data) {
		let tempData = [];
		let count = 0;
		Object.keys(data).forEach((key) => {
			if (key === 'children') {
				data[key].forEach((child) => {
					if (child.name === 'sensor') {
						count++;
					}
				});
			}
		});
		this.setState({
			countOfFuelSensor: count,
		});
		Object.keys(data).forEach((key) => {
			if (key === 'children') {
				let index = 0;
				data[key].forEach((child) => {
					if (child.name === 'sensor') {
						child.children.forEach((value, localIndex) => {
							if (value.name === 'value') {
								if (index === 0) {
									tempData.push(new Array(count + 1).fill(''));
								}
								tempData[localIndex][index] = value.attributes.code;
								tempData[localIndex][count] = count === 2 ? (value.value / 10) * 2 : value.value / 10;
							}
						});
						index++;
					}
				});
			}
		});

		this.calibrationValue = tempData;
		this.pingState();
	}

	convertData(data) {
		let formatedData = {};
		let hasAnalog = false;
		data.forEach((value) => {
			if (!hasAnalog && value.analogValue) {
				hasAnalog = true;
			}

			if (!formatedData[value.litre]) {
				formatedData[value.litre] = {
					[`lls${value.sensorId}`]:
						value.llsValue !== null ? value.llsValue : value.analogValue !== null ? value.analogValue * 100 : null,
				};
			} else {
				Object.assign(formatedData[value.litre], {
					[`lls${value.sensorId}`]:
						value.llsValue !== null ? value.llsValue : value.analogValue !== null ? value.analogValue * 100 : null,
				});
			}
		});

		let array = [];
		Object.keys(formatedData).forEach((value) => {
			array.push(
				Object.values(
					Object.keys(formatedData[value])
						.sort()
						.reduce((obj, key) => {
							obj[key] = formatedData[value][key];
							return obj;
						}, {})
				).concat(value)
			);
		});

		this.calibrationValue = array;

		this.setState({
			countOfFuelSensor: this.state.llsCountFE,
			hasAnalog,
		});
	}

	cleanUpdateCalibrationOperation() {
		dispatchNoPayload('VEHICLES_CALIBRATION_MODAL_CLOSE');
		dispatchNoPayload('VEHICLES_EDIT_CALIBRATION_MODAL_CLOSE');

		this.vehicleReg = '';
		this.vehicleID = '';
		this.calibrationValue = [];
		this.newRow = false;
		this.setState({
			countOfFuelSensor: '',
			hasAnalog: false,
		});
	}

	onAnalogChange(e, { checked }) {
		this.setState({
			hasAnalog: checked,
		});
	}

	onFuelSensorChange(e, { value }) {
		this.setState({
			countOfFuelSensor: value,
		});

		this.calibrationValue = [];
		if (!this.calibrationValue.length)
			if (value < 2) {
				this.calibrationValue[0] = new Array(2).fill('');
			} else this.calibrationValue[0] = new Array(value + 1).fill('');
	}

	sort(param, activeSort, sortOrder) {
		if (!param) {
			this.activeSort = param;
			this.sortOrder = '';
		} else {
			if (activeSort !== param) {
				this.sortOrder = 'asc';
				this.activeSort = param;
			} else {
				if (sortOrder === 'asc') {
					this.sortOrder = 'desc';
				} else {
					this.sortOrder = 'asc';
				}
			}
		}

		dispatch('VEHICLES_SORT_ORDER', this.sortOrder);
		dispatch('VEHICLES_ACTIVE_ORDER', this.activeSort);
		this.getFilteredVehicles(this.props.pageNumber);
	}

	// onACStatusChange() {
	// 	this.setState({ acStatusFA: !this.state.acStatusFA });
	// }

	onOrganisationFilterChange(e, { value }) {
		// this.setState({
		// 	filters: { ...this.state.filters, organisations: value },
		// });
		dispatch('ACTIVE_FILTER', { ...this.props.filters, organisations: value });
	}

	onTypeFilterChange(e, { value }) {
		this.setState({
			filters: { ...this.state.filters, type: value },
		});
	}

	onRelayFilterChange(e, { value }) {
		this.setState({
			filters: { ...this.state.filters, relayStatus: value },
		});
	}

	onImmobilizerFilterChange(e, { value }) {
		this.setState({
			filters: { ...this.state.filters, immobilizer: value },
		});
	}

	onFuelSensorFilterChange(e, { value }) {
		this.setState({
			filters: { ...this.state.filters, fuel: value },
		});
	}

	onisFMSVehicleFAChange(e, { checked }) {
		this.setState({
			isFMSVehicleFA: checked,
		});
	}

	onIsFMSFilterChange(e, { value }) {
		this.setState({
			filters: { ...this.state.filters, isFMSVehicle: value },
		});
	}

	onStatusFilterChange(e, { value }) {
		this.setState({
			filters: { ...this.state.filters, status: value },
		});
	}

	onSensorsFilterChange(e, { value }) {
		this.setState({
			filters: { ...this.state.filters, sensors: value },
		});
	}

	setFilterChange(organisations, type, immobilizer, fuel, status, sensors, startDate, endDate, relayStatus) {
		this.setState({
			filters: {
				organisations: organisations,
				type: type,
				immobilizer: immobilizer,
				fuel: fuel,
				status: status,
				startDate: startDate,
				endDate: endDate,
				sensors: sensors,
				relayStatus: relayStatus,
			},
		});
	}

	handleChangeDate = (event, { name, value }) => {
		if (name === 'startDate') {
			this.setState({
				filters: { ...this.state.filters, startDate: value },
			});
		}

		if (name === 'endDate') {
			this.setState({
				filters: { ...this.state.filters, endDate: value },
			});
		}
	};

	handleClearDate = () => {
		this.setState({
			filters: { endDate: '' },
		});
	};

	resetFilter() {
		this.activeFilters = {
			organisations: [],
			type: [],
			immobilizer: '',
			fuel: '',
			sensors: [],
			status: [],
			startDate: '',
			endDate: '',
			relayStatus: [],
			isFMSVehicle: null,
		};
		// this.activeDateFilter = false;
		this.setState({
			filters: {
				organisations: [],
				type: [],
				immobilizer: '',
				fuel: '',
				status: [],
				sensors: [],
				startDate: [],
				endDate: [],
				relayStatus: [],
				isFMSVehicle: null,
			},
		});
	}

	isFilterActive() {
		return (
			this.activeFilters.organisations.length ||
			this.activeFilters.type.length ||
			this.activeFilters.immobilizer.length ||
			this.activeFilters.fuel.length ||
			this.activeFilters.status.length ||
			this.activeFilters?.relayStatus?.length ||
			this.activeFilters?.sensors?.length ||
			this.activeFilters.startDate ||
			this.activeFilters.endDate
		);
	}

	// Recalculation
	recalculate() {
		const { selectedVehicles, startTime } = this.state;
		let query = {};
		// if (selectedVehicles.length) query += `vehicles[]=${selectedVehicles.join('&vehicles[]=')}`;
		// query = query + `&startTime=${moment(startTime, 'DD-MM-YYYY').startOf('day').toISOString()}`;

		if (selectedVehicles.length) query = { ...query, vehicleIds: selectedVehicles };
		if (startTime) query = { ...query, startTime: `${moment(startTime, 'DD-MM-YYYY').startOf('day').toISOString()}` };
		dispatchNoPayload('VEHICLES_RECALCULATING');

		// axios
		// 	.post(
		// 		RECALCULATE_API + `${query}`,
		// 		{},
		// 		{
		// 			headers: {
		// 				Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 			},
		// 		}
		// 	)
		postData(RECALCULATE_API, '', query)
			.then((response) => {
				dispatchNoPayload('VEHICLES_RECALCULATED');
				generateSuccessMessage(response, 'Success');
				this.getFilteredVehicles(this.props.pageNumber);
				this.cleanRecalculationOperation();
			})
			.catch((error) => {
				dispatchNoPayload('VEHICLES_RECALCULATED');
				datadogLogs.logger.error('Recalculate api', {}, error);
				handleError(error);
			});
	}

	// newrecalculate() {
	// 	const { selectedVehicles, startTime } = this.state;
	// 	let query = {};
	// 	// if (selectedVehicles.length) query += `vehicles[]=${selectedVehicles.join('&vehicles[]=')}`;
	// 	// query = query + `&startTime=${moment(startTime, 'DD-MM-YYYY').startOf('day').toISOString()}`;
	// 	if (selectedVehicles.length) query = { ...query, vehicleIds: selectedVehicles };
	// 	if (startTime) query = { ...query, startTime: `${moment(startTime, 'DD-MM-YYYY').startOf('day').toISOString()}` };
	// 	dispatchNoPayload('VEHICLES_RECALCULATING');
	// 	// axios
	// 	// 	.post(
	// 	// 		RECALCULATE_API + `${query}`,
	// 	// 		{},
	// 	// 		{
	// 	// 			headers: {
	// 	// 				Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 	// 			},
	// 	// 		}
	// 	// 	)
	// 	postData(NEW_RECALCULATE_API, '', query)
	// 		.then((response) => {
	// 			dispatchNoPayload('VEHICLES_RECALCULATED');
	// 			generateSuccessMessage(response, 'Success');
	// 			this.getFilteredVehicles(this.props.pageNumber);
	// 			this.cleanRecalculationOperation();
	// 		})
	// 		.catch((error) => {
	// 			dispatchNoPayload('VEHICLES_RECALCULATED');
	// 			datadogLogs.logger.error('Recalculate api', {}, error);
	// 			handleError(error);
	// 		});
	// }

	handleChange = (event, { name, value }) => {
		if (name === 'startTime') {
			this.setState({
				startTime: value,
			});
		}
	};

	toggleEnableForce() {
		this.setState({
			enableForce: !this.state.enableForce,
		});
	}

	cleanRecalculationOperation() {
		dispatchNoPayload('VEHICLES_RECALCULATION_MODAL_CLOSE');
		dispatchNoPayload('VEHICLES_RECALCULATION_MODAL_NEW_CLOSE');

		this.setState({
			organisations: [],
			selectedOrganisations: [],
			allOrganisations: true,
			vehiclesFRC: [],
			selectedVehicles: [],
			allVehicles: false,
			enableForce: false,
			startTime: `${moment().startOf('day').format('DD-MM-YYYY')}`,
		});
	}

	onOrgChecked(e, { checked }) {
		this.setState({ allOrganisations: checked });
		if (checked) {
			getVehicleForDropdown('?pagenumber=1&pagecount=1000').then((response) => {
				this.setState({ vehiclesFRC: response });
			});
		}
	}

	onOrgSelect(e, { value }) {
		this.setState({
			selectedOrganisations: value,
			selectedVehicles: [],
		});
		if (value.length) {
			getVehicleForDropdown(
				`?${value.map((organisation) => `&organisations[]=${organisation}`).join('')}&dropdown=true`
			).then((response) => {
				this.setState({ vehiclesFRC: response });
			});
		}
	}

	onAllVehicleChecked(e, { checked }) {
		this.setState({ allVehicles: checked });
		if (checked) {
			getVehicleForDropdown('?pagenumber=1&pagecount=1000').then((response) => {
				const allVehicleValues = response.map((vehicle) => vehicle.value);
				// this.setState({ vehiclesFRC: response });
				this.setState({ selectedVehicles: allVehicleValues });
			});
		}
	}

	onVehicleSelect(e, { value }) {
		this.setState({
			selectedVehicles: value,
		});
	}

	// Pagination
	handleDataSizeLimitChange() {
		this.getFilteredVehicles();
	}

	handlePageChange(activePage) {
		this.getFilteredVehicles(activePage);
		this.cleanSelectedRows();
	}

	loadVehicleTypes() {
		getVehicleTypesForDropdown().then((response) => {
			this.setState({
				vehicleTypeOptions: response,
			});
		});
	}

	loadVehicleStatus() {
		getVehicleStatusForDropdown().then((response) => {
			this.setState({
				vehicleStatusOptions: response,
			});
		});
	}

	addRow() {
		this.calibrationValue.push(new Array(this.state.countOfFuelSensor + 1).fill(''));
		this.pingState();
	}

	removeRow(index) {
		this.calibrationValue.splice(index, 1);
		this.pingState();
	}

	pingState() {
		this.setState({
			pingState: !this.state.pingState,
		});
	}

	getHeaders() {
		let headers = [];
		for (let i = 0; i <= this.state.countOfFuelSensor; i++) {
			if (i === this.state.countOfFuelSensor) {
				headers.push(<CalibrationHeaderElement>Fuel Level</CalibrationHeaderElement>);
			} else {
				headers.push(<CalibrationHeaderElement>Reading {i + 1}</CalibrationHeaderElement>);
			}
		}

		return headers;
	}

	getRows(row, index) {
		let rows = [];
		this.newRow = false;
		for (let i = 0; i <= this.state.countOfFuelSensor; i++) {
			rows.push(
				<input
					key={`${index},${i},${row[i]}`}
					disabled={!this.props.calibrationEditModal && !this.props.addModalOpen}
					style={{ margin: '0 1em' }}
					defaultValue={row[i]}
					onBlur={(e) => {
						this.calibrationValue[index][i] = e.target.value;

						if (!this.newRow && this.calibrationValue[this.calibrationValue.length - 1][i]) {
							this.addRow();
							this.newRow = true;
						}
					}}
				/>
			);
		}

		return rows;
	}

	cleanSelectedRows() {
		this.setState({
			anyRowSelected: false,
			selectedRows: new Set(),
		});
	}

	// unsubscribeFromFirebase() {
	// 	if (this.firebaseReferences.length) {
	// 		this.firebaseReferences.forEach((reference) => {
	// 			if (reference) reference.off('value');
	// 		});
	//
	// 		this.firebaseReferences = [];
	// 	}
	// }

	getStatusOption(status) {
		let options = [];
		if (this.isSuperAdminAccess === 'true') {
			if (status === 'Active') {
				options.push(
					{ key: '1', text: 'Trial', value: 'Trial' },
					{
						key: '2',
						text: 'Breakdown',
						value: 'Breakdown',
					},
					{ key: '3', text: 'Paused', value: 'Paused' }
				);
			}
			if (status === 'Trial') {
				options.push({ key: '1', text: 'Active', value: 'Active' }, { key: '2', text: 'Paused', value: 'Paused' });
			}
			if (status === 'Breakdown') {
				options.push({ key: '1', text: 'Active', value: 'Active' });
			}
			if (status === 'Paused') {
				options.push(
					{ key: '1', text: 'Active', value: 'Active' },
					{
						key: '2',
						text: 'Breakdown',
						value: 'Breakdown',
					}
				);
			}
		} else {
			if (status === 'Active') {
				options.push({
					key: '2',
					text: 'Breakdown',
					value: 'Breakdown',
				});
			}
			if (status === 'Trial') {
				options.push({ key: '1', text: 'Active', value: 'Active', disabled: true });
			}
			if (status === 'Breakdown') {
				options.push({ key: '1', text: 'Active', value: 'Active' });
			}
			if (status === 'Paused') {
				options.push({ key: '1', text: 'Active', value: 'Active', disabled: true });
			}
		}
		return options;
	}

	handleOpenConfirmation = () => {
		this.setState({
			isConfirmationOpen: true,
		});
	};
	// moment(trialEndDate, 'DD-MM-YYYY').toISOString(), this.props.finalDate
	handleConfirmChangeStatus = (id, vehicleTrialEndDate) => {
		this.handleChangeStatus(id, vehicleTrialEndDate);
		this.handleCloseConfirmation();
	};

	handleCloseConfirmation = () => {
		this.setState({ isConfirmationOpen: false, statusFE: null, trialEndDateSC: null });
	};

	componentDidUpdate(prevProps) {
		if (prevProps.vehicles !== this.props.vehicles) {
			// this.subscribeToFirebase(this.props.vehicles);
			this.setState({
				vehicles: this.getVehiclesAccordingPagination(this.props.vehicles, this.props.pageNumber),
			});
			if (this.selectAllActive) this.handleSelectAll(true);
		}

		if (prevProps.pageNumber !== this.props.pageNumber) {
			document.getElementById('AccordionMain').scrollTo(0, 0);
			this.setState({
				vehicles: this.getVehiclesAccordingPagination(this.props.vehicles, this.props.pageNumber),
			});
		}

		if (prevProps.loading !== this.props.loading) {
			if (!this.props.loading) {
				this.setState({
					initialLoading: false,
				});
			}
		}
	}

	componentWillUnmount() {
		// this.unsubscribeFromFirebase();
		// this.setState({ firebaseReferences: [] });
		// const { location, history } = this.props;
		dispatchNoPayload('VEHICLES_UNMOUNT');
	}

	closeLogsComponent() {
		this.setState({ showLogsComponent: false });
	}

	handleSearchQuery(value) {
		this.setState({ searchQuery: value });
		dispatch('UPDATE_SEARCH_QUERY', value);
	}

	handleDoubleClick = (imei) => {
		if (this.isSuperAdminAccess) {
			this.props.history.push({
				pathname: '/operations/devices',
				state: { deviceID: imei },
			});
		}
	};

	handleIconClick = () => {
		this.setState({ showCheckBox: false });
		this.cleanSelectedRows();
	};

	render() {
		return (
			<CategoryContentMembrane>
				<CategoryHeader>
					<SearchBox
						placeholder="Search in vehicles"
						searchQuery={this.state.searchQuery}
						clearSearchIconClick={() => {
							this.setState({ searchQuery: '' }, () => {
								this.vehicleNumber = '';
								this.vehicleImei = '';
								this.pickupBus = '';
								this.dropBus = '';
								this.deviceStatus = '';
								this.getFilteredVehicles(this.props.pageNumber);
							});
							dispatch('UPDATE_SEARCH_QUERY', '');
						}}
						value={this.props.searchQuery}
						handleSearchQuery={this.handleSearchQuery}
						// disabled={!this.state.searchQuery && (this.props.loading || this.props.exportingData)}
						handleSearch={this.handleSearch}
					/>

					<Button
						icon
						primary
						disabled={this.props.loading || this.props.exportingData}
						title="Refresh Vehicles"
						onClick={() => this.getFilteredVehicles(this.props.pageNumber)}>
						<Icon name="refresh" />
					</Button>

					<Expander />
					{/*Recalculation*/}
					{/*{!this.deletedMode && this.isSuperAdminAccess === 'true' && (*/}
					{/*	<Button*/}
					{/*		primary*/}
					{/*		title="Recalculate Data of Vehicle"*/}
					{/*		disabled={*/}
					{/*			this.props.loading || !this.props.vehicles.length || this.props.exportingData*/}
					{/*			// || !(this.state.createConfigurationsAccess || this.state.manageConfigurationsAccess)*/}
					{/*		}*/}
					{/*		onClick={() => {*/}
					{/*			dispatchNoPayload('VEHICLES_RECALCULATION_MODAL_OPEN');*/}
					{/*			getOrganisationsForDropdown('?dropdown=true').then((response) => {*/}
					{/*				this.setState({ organisations: response });*/}
					{/*			});*/}
					{/*			getVehicleForDropdown('?dropdown=true').then((response) => {*/}
					{/*				this.setState({ vehiclesFRC: response });*/}
					{/*			});*/}
					{/*		}}>*/}
					{/*		Recalculate*/}
					{/*	</Button>*/}
					{/*)}*/}

					{/*{!this.deletedMode && this.isSuperAdminAccess === 'true' && (*/}
					{/*	<Button*/}
					{/*		primary*/}
					{/*		title="Recalculate Data of Vehicle"*/}
					{/*		disabled={this.props.loading || !this.props.vehicles.length || this.props.exportingData}*/}
					{/*		onClick={() => {*/}
					{/*			dispatchNoPayload('VEHICLES_RECALCULATION_MODAL_NEW_OPEN');*/}
					{/*			getOrganisationsForDropdown('?dropdown=true').then((response) => {*/}
					{/*				this.setState({ organisations: response });*/}
					{/*			});*/}
					{/*			getVehicleForDropdown('?dropdown=true').then((response) => {*/}
					{/*				this.setState({ vehiclesFRC: response });*/}
					{/*			});*/}
					{/*		}}>*/}
					{/*		New Recalculate*/}
					{/*	</Button>*/}
					{/*)}*/}

					{/*#1b3c71*/}
					{/*{this.isSuperAdminAccess === 'true' && (*/}
					<div>
						{this.state.showCheckBox && (
							<span onClick={this.handleIconClick}>
								<Icon name="close" />
							</span>
						)}

						<Button
							primary
							onClick={() => {
								this.setState({ showCheckBox: true });
								this.cleanSelectedRows();
							}}
							title="Bulk Changes">
							Bulk Update
						</Button>
					</div>
					{/*)}*/}

					<Button
						primary={!this.isFilterActive()}
						icon
						disabled={this.props.loading || this.props.exportingData}
						color={this.isFilterActive() ? 'orange' : null}
						title="Filter Vehicles"
						onClick={() => {
							openFilterModal();
							getOrganisationsForDropdown('?dropdown=true').then((response) => {
								this.setState({ organisations: response });
							});
						}}>
						<Icon name="filter" />
					</Button>
					{/*Add*/}
					{!this.state.deletedMode && this.isSuperAdminAccess === 'true' && (
						<Button
							primary
							title="Add Vehicle"
							disabled={
								this.props.loading || this.props.exportingData
								// || !(this.state.manageAccess || this.state.createAccess)
							}
							onClick={() => {
								trackMixpanelEvent('Vehicles', { Action: 'Add' });
								dispatchNoPayload('VEHICLES_ADD_MODAL_OPEN');
								getDevicesForDropdown('?status[]=New&status[]=No Data&status[]=Unlinked&sims=true&dropdown=true').then(
									(response) => {
										this.setState({ devices: response });
									}
								);
								getOrganisationsForDropdown('?dropdown=true').then((response) => {
									this.setState({ organisations: response });
								});
							}}>
							Add
						</Button>
					)}
					{/*Export*/}
					{/*<Button*/}
					{/*	primary*/}
					{/*	disabled={*/}
					{/*		!this.state.anyRowSelected ||*/}
					{/*		this.props.loading ||*/}
					{/*		!this.props.vehicles.length ||*/}
					{/*		this.props.exportingData*/}
					{/*	}*/}
					{/*	title={!this.state.anyRowSelected ? 'Please select atleast one vehicle to export' : 'Export Vehicles'}*/}
					{/*	onClick={() => {*/}
					{/*		openExportModal();*/}
					{/*		dispatch('SET_VEHICLES_EXPORT_DATA', this.export());*/}
					{/*	}}>*/}
					{/*	Export*/}
					{/*</Button>*/}
					{/*Export All*/}

					<Button.Group>
						<Button
							primary
							title="Export All Vehicles"
							disabled={this.props.loading || !this.props.vehicles.length || this.props.exportingData}
							loading={this.props.exportingData}
							onClick={() => {
								trackMixpanelEvent('Vehicles', { Action: 'Export' });
								exportAll(this.getFilterListQuery());
							}}>
							Export
						</Button>
						<Dropdown
							style={{ background: '#ffffff', color: '#1b3c71', borderColor: '#1b3c71' }}
							className="button icon"
							floating
							value={this.state.fileExtension}
							options={FILE_TYPE_EXTENSIONS}
							onChange={this.fileExtensionChange}
							trigger={<></>}
						/>
					</Button.Group>

					{/*Export All Button Ends*/}

					{this.isSuperAdminAccess === 'true' && (
						<ToggleSwitch
							title={this.deletedMode ? 'Hide Deleted Vehicles' : 'Show Deleted Vehicles'}
							label="Deleted Mode"
							checked={this.deletedMode}
							disabled={this.props.loading || this.props.exportingData}
							action={this.toggleDeletedMode}
						/>
					)}
				</CategoryHeader>
				<CategoryMain>
					<AccordionHeader background={this.deletedMode}>
						{this.state.showCheckBox && (
							<HeaderElement
								style={{ marginRight: 0 }}
								width="3%"
								disabled={this.props.loading || this.props.exportingData || !this.props.vehicles.length}>
								<CheckboxContainer>
									<Checkbox
										checked={
											this.props.vehicles.length !== 0 && this.state.selectedRows.size === this.props.vehicles.length
										}
										indeterminate={
											this.state.selectedRows.size !== 0 && this.state.selectedRows.size !== this.props.vehicles.length
										}
										onClick={(e, data) => {
											this.handleSelectAll(data.checked);
										}}
									/>
								</CheckboxContainer>
							</HeaderElement>
						)}

						{/*If clicked, clears sort filters and fetches fresh data.*/}
						<HeaderElement
							style={{ marginRight: 0 }}
							width="4%"
							disabled={this.props.loading || this.props.exportingData || !this.props.vehicles.length}>
							No.
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="13%"
							onClick={() => {
								this.sort('registrationNumber', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.vehicles.length}
							activeSort={this.activeSort === 'registrationNumber'}>
							{this.props.activeSort === 'registrationNumber' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Registration
						</HeaderElement>

						<HeaderElement
							style={{ marginRight: 0 }}
							width="13%"
							onClick={() => {
								this.sort('organisation', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.vehicles.length}
							activeSort={this.activeSort === 'organisation'}>
							{this.props.activeSort === 'organisation' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Organisation
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="12%"
							onClick={() => {
								this.sort('device', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.vehicles.length}
							activeSort={this.activeSort === 'device'}>
							{this.props.activeSort === 'device' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Device
						</HeaderElement>
						<HeaderElement
							width="10%"
							style={{ marginRight: 0, paddingLeft: '7px' }}
							disabled={this.props.loading || this.props.exportingData || !this.props.vehicles.length}>
							Sensors
						</HeaderElement>

						<HeaderElement
							width="14%"
							style={{ marginRight: 0 }}
							onClick={() => {
								this.sort('relayStatus', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.vehicles.length}
							activeSort={this.activeSort === 'relayStatus'}>
							{this.props.activeSort === 'relayStatus' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Relay Status
						</HeaderElement>

						<HeaderElement
							width="14%"
							style={{ marginRight: 0, paddingLeft: '7px' }}
							disabled={this.props.loading || this.props.exportingData || !this.props.vehicles.length}>
							Current Status
						</HeaderElement>

						<HeaderElement
							width="13%"
							style={{ marginRight: 0 }}
							onClick={() => {
								this.sort('installationDate', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.vehicles.length}
							activeSort={this.activeSort === 'installationDate'}>
							{this.props.activeSort === 'installationDate' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Installed On
						</HeaderElement>

						{/*<HeaderElement*/}
						{/*	width="11%"*/}
						{/*	style={{ marginRight: 0 }}*/}
						{/*	onClick={() => {*/}
						{/*		this.sort('createdDate', this.props.activeSort, this.props.sortOrder);*/}
						{/*	}}*/}
						{/*	disabled={this.props.loading || this.props.exportingData || !this.props.vehicles.length}*/}
						{/*	activeSort={this.activeSort === 'createdDate'}>*/}
						{/*	{this.props.activeSort === 'createdDate' ? (*/}
						{/*		this.props.sortOrder === 'asc' ? (*/}
						{/*			<Icon name="sort alphabet down" />*/}
						{/*		) : (*/}
						{/*			<Icon name="sort alphabet up" />*/}
						{/*		)*/}
						{/*	) : (*/}
						{/*		<Icon name="sort" />*/}
						{/*	)}*/}
						{/*	Created Date*/}
						{/*</HeaderElement>*/}

						<HeaderElement
							width="9%"
							style={{ marginRight: 0 }}
							onClick={() => {
								this.sort('status', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.vehicles.length}
							activeSort={this.activeSort === 'status'}>
							{this.props.activeSort === 'status' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Status
						</HeaderElement>

						{/*<HeaderElement*/}
						{/*	width="7%"*/}
						{/*	style={{ marginRight: 0 }}*/}
						{/*	disabled={this.props.loading || this.props.exportingData || !this.props.vehicles.length}>*/}
						{/*	Groups*/}
						{/*</HeaderElement>*/}

						{/*{!this.deletedMode && (*/}
						{/*	<HeaderElement*/}
						{/*		width="8%"*/}
						{/*		style={{ marginRight: 0 }}*/}
						{/*		disabled={this.props.loading || this.props.exportingData || !this.props.vehicles.length}>*/}
						{/*		Geofences*/}
						{/*	</HeaderElement>*/}
						{/*)}*/}

						<HeaderElement
							width="20%"
							style={{ marginRight: 0 }}
							onClick={() => {
								this.sort('timestamp', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.vehicles.length}
							activeSort={this.activeSort === 'timestamp'}>
							{this.props.activeSort === 'timestamp' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Last Data
						</HeaderElement>

						{this.deletedMode && (
							<HeaderElement
								width="9%"
								style={{ marginRight: 0 }}
								disabled={this.props.loading || this.props.exportingData || !this.props.vehicles.length}>
								Deleted Date
							</HeaderElement>
						)}
					</AccordionHeader>

					<AccordionMain id="AccordionMain">
						{this.state.initialLoading ? (
							<BasicLoader />
						) : this.props.vehicles.length ? (
							<Accordion fluid>
								{this.props.vehicles.map((vehicle, index) => {
									return (
										<section key={index}>
											<Accordion.Title
												active={this.props.activeIndex === index}
												index={index}
												onClick={(event, data) => {
													toggleAccordion(event, data, this.props.activeIndex);
													getDriversForDropdown(
														`?type[]=Driver&organisations[]=${vehicle.organisationId}&dropdown=true`
													).then((response) => {
														let driverDropdown = response?.data?.data.map((driver) => {
															return {
																key: driver.id,
																text: driver.name,
																value: driver.id,
															};
														});
														this.setState({ driversDropdownOptions: driverDropdown });
													});
													this.cleanEditOperation();
												}}
												style={{ padding: 0 }}>
												<Row
													style={{
														color: vehicle?.status === 5 || vehicle?.status === 6 ? 'red' : 'black',
														marginRight: 0,
													}}>
													{this.state.showCheckBox && (
														<RowElement width="3%" style={{ marginRight: 0 }}>
															<CheckboxContainer>
																<Checkbox
																	checked={this.state.selectedRows.has(vehicle.id)}
																	onChange={(e, data) => {
																		e.stopPropagation();
																		this.handleUpdateSelection(data.checked, vehicle.id);
																	}}
																/>
															</CheckboxContainer>
														</RowElement>
													)}

													<RowElement width="4%" style={{ marginRight: 0 }}>
														{1 + index + (this.props.pageNumber - 1) * this.props.dataSizeLimit}
													</RowElement>
													<RowElement
														width="13%"
														style={{
															marginRight: 0,
															color: `${vehicle.registrationNumber ? 'black' : 'gray'}`,
														}}
														title={`${vehicle.registrationNumber ? vehicle.registrationNumber : 'Not Available'}`}>
														{vehicle.type === 'Car' && <Icon name="car" color="blue" title="Car" />}
														{vehicle.type === 'Bike' && <Icon name="motorcycle" color="blue" title="Bike" />}
														{vehicle.type === 'Truck' && <Icon name="truck" color="blue" title="Truck" />}
														{vehicle.type === 'Construction Machinery' && (
															// <Icon name="dolly" color="blue" title="Construction Machinery" />
															<FaTractor
																style={{ paddingTop: '5px', marginRight: '2px' }}
																size={'17px'}
																color="#1B3C70"
																title={'Construction Machinery'}
															/>
														)}
														{vehicle.type === 'Bus' && <Icon name="bus" color="blue" title="Bus" />}
														{vehicle.type === 'Generator' && <Icon name="calendar" color="blue" title="Generator" />}
														{vehicle.type === 'Container' && <Icon name="truck" color="blue" title="Container" />}
														{vehicle.type === 'Mini Truck' && (
															<Icon name="shipping fast" color="blue" title="Mini Truck" />
														)}
														{vehicle.type === 'Auto' && <Icon name="motorcycle" color="blue" title="Auto" />}{' '}
														{vehicle.registrationNumber ? vehicle.registrationNumber : 'Not Available'}
													</RowElement>

													<RowElement
														width="13%"
														style={{
															marginRight: 0,
															color: `${vehicle.organisation ? 'black' : 'grey'}`,
														}}
														title={vehicle.organisation ? vehicle.organisation : 'Not Available'}>
														{vehicle.organisation ? vehicle.organisation : 'Not Available'}
													</RowElement>

													<div
														style={{ width: '12%' }}
														onClick={() => navigator.clipboard.writeText(vehicle.imei)}
														onDoubleClick={() => {
															if (vehicle.imei && this.isSuperAdminAccess === 'true') {
																this.handleDoubleClick(vehicle.imei);
															}
														}}>
														<RowElement
															width="100%"
															style={{
																marginRight: 0,
																color: `${vehicle.imei ? 'black' : 'gray'}`,
															}}
															title={vehicle.imei ? vehicle.imei : 'Not Available'}>
															<div>{vehicle.imei ? vehicle.imei : 'Not Available'}</div>
														</RowElement>
													</div>

													<RowElement
														width="10%"
														style={{
															marginRight: 0,
														}}>
														<Icon
															name={'lock'}
															title={
																vehicle.sensors === null
																	? 'Relay Not Available'
																	: vehicle.sensors.includes('relay')
																	? 'Relay'
																	: vehicle.sensors.includes('inverted_relay')
																	? 'Inverted Relay'
																	: 'Relay Not Available'
															}
															style={{
																color: `${
																	vehicle.sensors === null
																		? 'gray'
																		: vehicle.sensors.includes('relay')
																		? 'green'
																		: vehicle.sensors.includes('inverted_relay')
																		? 'orange'
																		: 'gray'
																}`,
															}}
														/>

														<Icon
															name={'fire'}
															title={
																vehicle.sensors === null
																	? 'Fuel Not Available'
																	: vehicle.sensors.includes('fuel_obd_litre')
																	? 'OBD Fuel Litre'
																	: vehicle.sensors.includes('fuel_obd_percentage')
																	? 'OBD Fuel percentage'
																	: 'Fuel Not Available'
															}
															style={{
																color: `${
																	vehicle.sensors === null
																		? 'gray'
																		: vehicle.sensors.includes('fuel_obd_litre')
																		? 'orange'
																		: vehicle.sensors.includes('fuel_obd_percentage')
																		? 'green'
																		: 'gray'
																}`,
															}}
														/>

														<Icon
															name={'key'}
															title={
																vehicle.sensors === null
																	? 'Keylock Not Available'
																	: vehicle.sensors.includes('keylock')
																	? 'keylock'
																	: 'Keylock Not Available'
															}
															style={{
																color: `${
																	vehicle.sensors === null
																		? 'gray'
																		: vehicle.sensors.includes('keylock')
																		? 'green'
																		: 'gray'
																}`,
															}}
														/>

														<Icon
															name={'bolt'}
															title={
																vehicle.sensors === null
																	? 'Ignition Not Available'
																	: vehicle.sensors.includes('ignition')
																	? 'Ignition'
																	: 'Ignition Not Available'
															}
															style={{
																color: `${
																	vehicle.sensors === null
																		? 'gray'
																		: vehicle.sensors.includes('ignition')
																		? 'green'
																		: 'gray'
																}`,
															}}
														/>
														<Icon
															name={'fire extinguisher'}
															title={vehicle.isFMSVehicle ? 'FMS Vehicle' : 'Not FMS Vehicle'}
															style={{
																color: `${vehicle.isFMSVehicle ? 'green' : 'gray'}`,
															}}
														/>
													</RowElement>

													<RowElement
														width="14%"
														title={vehicle.latestData.relayStatus ? vehicle.latestData.relayStatus : 'Not Available'}
														style={{
															paddingLeft: '7px',
															color: `${vehicle.latestData.relayStatus ? 'black' : 'grey'}`,
														}}>
														{vehicle.latestData.relayStatus === true
															? 'Immobilised'
															: vehicle.latestData.relayStatus === false
															? 'Mobilised'
															: vehicle.latestData.relayStatus === undefined || vehicle.latestData.relayStatus === null
															? 'Not Available'
															: ''}
													</RowElement>

													<RowElement
														width="14%"
														title={
															vehicle.latestData.currentStatus ? vehicle.latestData.currentStatus : 'Not Available'
														}
														style={{ color: `${vehicle.latestData.currentStatus ? 'black' : 'grey'}` }}>
														<Label
															size="medium"
															style={{ width: '80%', textAlign: 'center' }}
															color={
																vehicle.latestData.currentStatus === 'Moving'
																	? 'green'
																	: vehicle.latestData.currentStatus === 'Idle'
																	? 'yellow'
																	: vehicle.latestData.currentStatus === 'No Data'
																	? 'Orange'
																	: vehicle.latestData.currentStatus === 'Ignition off'
																	? 'red'
																	: null
															}>
															{vehicle.latestData.currentStatus ? vehicle.latestData.currentStatus : 'Not Available'}
														</Label>
													</RowElement>

													<RowElement
														width="13%"
														style={{
															marginRight: 0,
															paddingLeft: '8px',
															color: `${vehicle.installationDate ? 'black' : 'gray'}`,
														}}
														title={vehicle.installationDate ? vehicle.installationDate : 'Not Available'}>
														{vehicle.installationDate}
													</RowElement>

													{/*<RowElement*/}
													{/*	width="11%"*/}
													{/*	style={{*/}
													{/*		marginRight: 0,*/}
													{/*		paddingLeft: '8px',*/}
													{/*		color: `${vehicle.createdDate ? 'black' : 'gray'}`,*/}
													{/*	}}*/}
													{/*	title={vehicle.createdDate ? vehicle.createdDate : 'Not Available'}>*/}
													{/*	{vehicle.createdDate}*/}
													{/*</RowElement>*/}

													<RowElement
														width="9%"
														style={{
															marginRight: 0,
															color: `${vehicle.status ? 'black' : 'gray'}`,
														}}
														title={vehicle.status ? vehicle.status : 'Not Available'}>
														<Label
															size={'medium'}
															style={{ minWidth: '80%', textAlign: 'center' }}
															color={
																vehicle.status === 'Active'
																	? 'green'
																	: vehicle.status === 'Offline'
																	? 'red'
																	: vehicle.status === 'Standby'
																	? 'yellow'
																	: 'orange'
															}>
															{vehicle.status}
														</Label>
													</RowElement>
													{/*<RowElement*/}
													{/*	width="7%"*/}
													{/*	style={{*/}
													{/*		marginRight: 0,*/}
													{/*		color: `${vehicle.vehicleGroups ? 'black' : 'gray'}`,*/}
													{/*	}}*/}
													{/*	title={*/}
													{/*		vehicle.vehicleGroups.map((group) => group.name).join(', ')*/}
													{/*			? vehicle.vehicleGroups.length*/}
													{/*			: 'Not Available'*/}
													{/*	}>*/}
													{/*	{vehicle.vehicleGroups.length}*/}
													{/*</RowElement>*/}

													{/*{!this.deletedMode && (*/}
													{/*	<RowElement*/}
													{/*		width="8%"*/}
													{/*		style={{ marginRight: 0, color: `${vehicle.geofences ? 'black' : 'gray'}` }}*/}
													{/*		title={*/}
													{/*			vehicle.geofences.map((geofence) => geofence.name).join(', ')*/}
													{/*				? vehicle.geofences.length*/}
													{/*				: 'Not Available'*/}
													{/*		}>*/}
													{/*		{vehicle.geofences.length}*/}
													{/*	</RowElement>*/}
													{/*)}*/}

													<RowElement
														width="20%"
														// title={`${
														// 	!this.state.lastLocation[vehicle.id]
														// 		? 'Data not available'
														// 		: moment().diff(moment(this.state.lastLocation[vehicle.id]), 'seconds') >= 3600
														// 		? 'No data since 1 hour'
														// 		: moment().diff(moment(this.state.lastLocation[vehicle.id]), 'seconds') >= 300
														// 		? 'Data arrived within 1 hour'
														// 		: moment().diff(moment(this.state.lastLocation[vehicle.id]), 'seconds') >= 0
														// 		? 'Data arrived within 5 minutes'
														// 		: 'Faulty data'
														// }`}
														title={`${
															moment().diff(moment(vehicle.latestData.timestamp), 'seconds') >= 3600
																? 'No data since 1 hour'
																: moment().diff(moment(vehicle.latestData.timestamp), 'seconds') >= 300
																? 'Data arrived within 1 hour'
																: moment().diff(moment(vehicle.latestData.timestamp), 'seconds') >= 0
																? 'Data arrived within 5 minutes'
																: 'Not Available'
														}`}>
														<Label
															size={'small'}
															style={{ width: '100%', textAlign: 'center' }}
															color={`${
																vehicle.latestData.timestamp === null
																	? 'grey'
																	: moment().diff(moment(vehicle.latestData.timestamp), 'seconds') >= 3600
																	? 'red'
																	: moment().diff(moment(vehicle.latestData.timestamp), 'seconds') >= 300
																	? 'orange'
																	: moment().diff(moment(vehicle.latestData.timestamp), 'seconds') >= 0
																	? 'green'
																	: 'black'
															}`}>
															{vehicle.latestData.timestamp
																? isoToHumanReadableAlternate(vehicle.latestData.timestamp)
																: 'Not Available'}
														</Label>
													</RowElement>

													{this.deletedMode && (
														<RowElement
															width="9%"
															style={{
																textAlign: 'center',
																color: `${vehicle.deletedDate ? 'black' : 'gray'}`,
															}}
															title={vehicle.deletedDate ? vehicle.deletedDate : 'Not Available'}>
															{vehicle.deletedDate ? vehicle.deletedDate : 'Not Available'}
														</RowElement>
													)}
												</Row>
											</Accordion.Title>

											{this.props.activeIndex === index && (
												<Accordion.Content active={this.props.activeIndex === index}>
													<AccordionMainContent>
														<Header as="h3" color="black" attached="top">
															Vehicle
														</Header>
														<Segment attached>
															<Form>
																<Form.Group widths="equal">
																	<Form.Field required>
																		<label>Registration No.</label>
																		<Input
																			fluid
																			value={
																				this.state.registrationNumberFE !== null
																					? this.state.registrationNumberFE
																					: vehicle.registrationNumber === null
																					? ''
																					: vehicle.registrationNumber
																			}
																			disabled={!this.props.editMode}
																			onChange={(e) => this.setState({ registrationNumberFE: e.target.value })}
																		/>
																	</Form.Field>
																	<Form.Field required>
																		<label>Installation Date</label>
																		<DateInput
																			placeholder="Select"
																			fluid
																			// clearable
																			closable
																			disabled
																			// disabled={!this.props.editMode || this.isSuperAdminAccess === 'false'}
																			value={
																				this.state.installationDateFE !== null
																					? this.state.installationDateFE
																					: vehicle.installationDate == null
																					? ''
																					: vehicle.installationDate
																			}
																			onChange={(e, data) => {
																				this.setState({
																					installationDateFE: data.value,
																				});
																			}}
																		/>
																	</Form.Field>

																	<Form.Field>
																		<label>Device Swapped Date</label>
																		<Input fluid defaultValue={isoToDate(vehicle.deviceModifiedDate)} disabled />
																	</Form.Field>

																	<Form.Field required>
																		<label>Created Date</label>
																		<DateInput
																			placeholder="Select"
																			fluid
																			// clearable
																			closable
																			disabled
																			// disabled={this.isSuperAdminAccess === 'false'}
																			value={
																				this.state.createdDateFE !== null
																					? this.state.createdDateFE
																					: vehicle.createdDate == null
																					? ''
																					: vehicle.createdDate
																			}
																			onChange={(e, data) => {
																				this.setState({
																					createdDateFE: data.value,
																				});
																			}}
																		/>
																	</Form.Field>
																	<Form.Field
																	// control={Input}
																	// label="Odometer Reading (KM)"
																	// error={
																	// 	!validateNumericValue(this.state.odometerFE) && {
																	// 		content: 'Odometer Reading should be only digits.',
																	// 	}
																	// }
																	>
																		<label>Odometer Reading (KM)</label>
																		<Input
																			fluid
																			labelPosition="right"
																			type="number"
																			value={
																				this.state.odometerFE !== null
																					? this.state.odometerFE
																					: vehicle.odometerReading === null
																					? ''
																					: (vehicle.odometerReading / 1000).toFixed(2)
																			}
																			disabled={!this.props.editMode || this.isSuperAdminAccess === false}
																			onChange={(e, { value }) => {
																				this.setState({
																					odometerFE: value,
																				});
																			}}
																		/>
																	</Form.Field>
																	{/*<Form.Field>*/}
																	{/*  <label>Tank Capacity</label>*/}
																	{/*  <Input*/}
																	{/*    fluid*/}
																	{/*    value={*/}
																	{/*      this.state.tankCapacityFE !== null*/}
																	{/*        ? this.state.tankCapacityFE*/}
																	{/*        : vehicle.tankCapacity === null*/}
																	{/*        ? ''*/}
																	{/*        : vehicle.tankCapacity*/}
																	{/*    }*/}
																	{/*    disabled={!this.props.editMode}*/}
																	{/*    onChange={(e) => this.setState({tankCapacityFE: e.target.value})}*/}
																	{/*  />*/}
																	{/*</Form.Field>*/}
																	{/*<Form.Field>*/}
																	{/*	<label>Immobilizer</label>*/}
																	{/*	<Dropdown*/}
																	{/*		fluid*/}
																	{/*		selection*/}
																	{/*		options={[*/}
																	{/*			{ key: 0, text: 'No Immobilizer', value: 'No Immobilizer' },*/}
																	{/*			{ key: 1, text: 'Has Immobilizer', value: 'Has Immobilizer' },*/}
																	{/*			{ key: 2, text: 'Has Inverted Immobilizer', value: 'Has Inverted Immobilizer' },*/}
																	{/*		]}*/}
																	{/*		onChange={(e, { value }) => {*/}
																	{/*			this.setState({ immobilizerFE: value });*/}
																	{/*		}}*/}
																	{/*		value={*/}
																	{/*			this.state.immobilizerFE != null*/}
																	{/*				? this.state.immobilizerFE*/}
																	{/*				: vehicle.immobilizer == null*/}
																	{/*				? ''*/}
																	{/*				: vehicle.immobilizer*/}
																	{/*		}*/}
																	{/*		disabled={!this.props.editMode}*/}
																	{/*	/>*/}
																	{/*</Form.Field>*/}
																</Form.Group>

																<Form.Group widths="equal">
																	{/*<Form.Field required>*/}
																	{/*	<label>Status</label>*/}
																	{/*	<Dropdown*/}
																	{/*		fluid*/}
																	{/*		search*/}
																	{/*		clearable*/}
																	{/*		selection*/}
																	{/*		disabled={!this.props.editMode}*/}
																	{/*		options={VEHICLE_STATUS_OPTIONS}*/}
																	{/*		value={this.state.statusFE !== null ? this.state.statusFE : vehicle.status}*/}
																	{/*		onChange={(e, { value }) =>*/}
																	{/*			this.setState({*/}
																	{/*				statusFE: value,*/}
																	{/*			})*/}
																	{/*		}*/}
																	{/*	/>*/}
																	{/*</Form.Field>*/}

																	<Form.Field required>
																		<label>Vehicle Type</label>
																		<Dropdown
																			fluid
																			search
																			selection
																			clearable
																			disabled={!this.props.editMode}
																			options={this.state.vehicleTypeOptions}
																			value={this.state.typeFE !== null ? this.state.typeFE : vehicle.type.toString()}
																			onChange={(e, { value }) =>
																				this.setState({
																					typeFE: value,
																				})
																			}
																		/>
																	</Form.Field>
																	{this.isSuperAdminAccess === 'true' && (
																		<Form.Field>
																			<label>Device Model</label>
																			<Input
																				fluid
																				value={`${vehicle.manufacturer} (${vehicle.deviceModel})`}
																				disabled
																			/>
																		</Form.Field>
																	)}

																	<Form.Field>
																		<label>Sensors</label>
																		<Dropdown
																			fluid
																			search
																			multiple={true}
																			clearable
																			selection
																			disabled={!this.props.editMode || this.isSuperAdminAccess === 'false'}
																			options={VEHICLE_SENSORS_OPTIONS}
																			value={this.state.sensorsFE !== null ? this.state.sensorsFE : vehicle.sensors}
																			onChange={(e, { value }) =>
																				this.setState({
																					sensorsFE: value,
																				})
																			}
																		/>
																	</Form.Field>

																	{/*<Form.Field>*/}
																	{/*	<label>Fuel Data Source</label>*/}
																	{/*	<Dropdown*/}
																	{/*		fluid*/}
																	{/*		search*/}
																	{/*		selection*/}
																	{/*		disabled={!this.props.editMode}*/}
																	{/*		options={options}*/}
																	{/*		value={*/}
																	{/*			this.state.fuelDataSourceFE != null*/}
																	{/*				? this.state.fuelDataSourceFE*/}
																	{/*				: vehicle.fuelDataSource == null*/}
																	{/*				? ''*/}
																	{/*				: vehicle.fuelDataSource*/}
																	{/*		}*/}
																	{/*		onChange={(e, { value }) => {*/}
																	{/*			this.setState({*/}
																	{/*				fuelDataSourceFE: value,*/}
																	{/*				hasFuelSensorFE: value === 1 || value === 2,*/}
																	{/*			});*/}
																	{/*			if (value === 1 || value === 2) {*/}
																	{/*				this.vehicleReg = vehicle.registrationNumber;*/}
																	{/*				this.vehicleID = vehicle.id;*/}
																	{/*				dispatchNoPayload('VEHICLES_EDIT_CALIBRATION_MODAL_OPEN');*/}
																	{/*				dispatchNoPayload('VEHICLES_CALIBRATION_MODAL_OPEN');*/}
																	{/*				if (!this.state.countOfFuelSensor && !this.calibrationValue.length) {*/}
																	{/*					this.setState({*/}
																	{/*						countOfFuelSensor: 1,*/}
																	{/*					});*/}
																	{/*					this.calibrationValue[0] = new Array(2).fill('');*/}
																	{/*				}*/}
																	{/*			}*/}
																	{/*		}}*/}
																	{/*	/>*/}
																	{/*</Form.Field>*/}

																	{(vehicle?.status === 'Trial' || this.state.statusFE === 'Trial') && (
																		<Form.Field>
																			<label>Trial End Date</label>
																			<DateInput
																				placeholder="Select"
																				fluid
																				closable
																				clearable
																				disabled={!this.props.editMode || this.isSuperAdminAccess === 'false'}
																				minDate={vehicle.installationDate}
																				value={
																					this.state.trialEndDateFE !== null
																						? this.state.trialEndDateFE
																						: vehicle.trialEndDate
																				}
																				onChange={(e, data) =>
																					this.setState({
																						trialEndDateFE: data.value,
																					})
																				}
																			/>
																		</Form.Field>
																	)}
																</Form.Group>
																<Form.Group widths="equal">
																	<Form.Field>
																		<label>Speed Limit</label>
																		<Input
																			label={'km/hr'}
																			labelPosition="right"
																			type="number"
																			fluid
																			value={
																				this.state.speedLimitFE !== null
																					? this.state.speedLimitFE
																					: vehicle.speedLimit === null
																					? ''
																					: vehicle.speedLimit
																			}
																			disabled={!this.props.editMode}
																			onChange={(e) => this.setState({ speedLimitFE: e.target.value })}
																		/>
																	</Form.Field>

																	<Form.Field>
																		<label>Idling Duration(Min)</label>
																		<Input
																			type="number"
																			fluid
																			value={
																				this.state.idlingDurationFE !== null
																					? this.state.idlingDurationFE
																					: vehicle.idlingDuration === null
																					? ''
																					: vehicle.idlingDuration
																			}
																			disabled={!this.props.editMode}
																			onChange={(e) => this.setState({ idlingDurationFE: e.target.value })}
																		/>
																	</Form.Field>
																	<Form.Field>
																		<label>Parking Duration(Min)</label>
																		<Input
																			type="number"
																			fluid
																			value={
																				this.state.parkingDurationFE !== null
																					? this.state.parkingDurationFE
																					: vehicle.parkingDuration === null
																					? ''
																					: vehicle.parkingDuration
																			}
																			disabled={!this.props.editMode}
																			onChange={(e) => this.setState({ parkingDurationFE: e.target.value })}
																		/>
																	</Form.Field>
																	<Form.Field>
																		<label>Driver</label>
																		<Dropdown
																			fluid
																			search
																			clearable
																			selection
																			disabled={!this.props.editMode}
																			options={this.state.driversDropdownOptions}
																			onChange={this.onDriverChooseFE}
																			value={this.state.driverFE !== null ? this.state.driverFE : vehicle.driver?.id}
																		/>
																		{/*<Input fluid value={vehicle.driver?.name} disabled={!this.props.editMode} />*/}
																	</Form.Field>
																</Form.Group>
																<Form.Group widths="equal">
																	<Form.Field>
																		<label>Chassis Number</label>
																		<Input
																			fluid
																			value={
																				this.state.chassisNumberFE !== null
																					? this.state.chassisNumberFE
																					: vehicle.chassisNumber === null
																					? ''
																					: vehicle.chassisNumber
																			}
																			disabled={!this.props.editMode}
																			onChange={(e) => this.setState({ chassisNumberFE: e.target.value })}
																		/>
																	</Form.Field>
																	<Form.Field>
																		<label>Make</label>
																		<Input
																			fluid
																			value={
																				this.state.makeFE !== null
																					? this.state.makeFE
																					: vehicle.make === null
																					? ''
																					: vehicle.make
																			}
																			disabled={!this.props.editMode}
																			onChange={(e) => this.setState({ makeFE: e.target.value })}
																		/>
																	</Form.Field>
																	<Form.Field>
																		<label>Model</label>
																		<Input
																			fluid
																			value={
																				this.state.modelFE !== null
																					? this.state.modelFE
																					: vehicle.model === null
																					? ''
																					: vehicle.model
																			}
																			disabled={!this.props.editMode}
																			onChange={(e) => this.setState({ modelFE: e.target.value })}
																		/>
																	</Form.Field>
																	<Form.Field>
																		<label>Geofences</label>
																		{this.props.editMode ? (
																			<Dropdown
																				fluid
																				search
																				selection
																				multiple
																				clearable
																				placeholder={this.state.geofenceFE}
																				options={this.state.geofences}
																				value={
																					this.state.geofenceFE || vehicle.geofences.map((geofence) => geofence.id)
																				}
																				onChange={(e, { value }) => {
																					this.setState({
																						geofenceFE: value,
																						// geofenceFE: value.slice(1),
																					});
																				}}
																			/>
																		) : (
																			<Input
																				fluid
																				value={
																					vehicle.geofences.length
																						? vehicle.geofences.map((geofence) => geofence.name).join(', ')
																						: ''
																				}
																				disabled
																			/>
																		)}
																	</Form.Field>
																</Form.Group>

																<Form.Group widths="equal">
																	{this.isSuperAdminAccess === 'true' && (
																		<Form.Field>
																			<label>Note</label>
																			<Input
																				fluid
																				value={
																					this.state.noteFE !== null
																						? this.state.noteFE
																						: vehicle.note === null
																						? ''
																						: vehicle.note
																				}
																				disabled={!this.props.editMode || this.isSuperAdminAccess === 'false'}
																				onChange={(e) => this.setState({ noteFE: e.target.value })}
																			/>
																		</Form.Field>
																	)}
																	<Form.Field>
																		<label>Expected Mileage</label>
																		<Input
																			label={'km/l'}
																			labelPosition="right"
																			type="number"
																			fluid
																			value={
																				this.state.expectedMileageFE !== null
																					? this.state.expectedMileageFE
																					: vehicle.expectedMileage === null
																					? ''
																					: vehicle.expectedMileage
																			}
																			// disabled={vehicle.expectedMileage || !this.props.editMode}
																			disabled={!this.props.editMode}
																			onChange={(e) => this.setState({ expectedMileageFE: e.target.value })}
																		/>
																	</Form.Field>
																	<Form.Field
																		style={{
																			display: 'flex',
																			justifyContent: 'center',
																			alignItems: 'center',
																			marginTop: '10px',
																		}}>
																		<label>FMS</label>
																		<Container>
																			<Checkbox
																				toggle
																				onChange={(e, data) => this.setState({ isFMSVehicleFE: data.checked })}
																				checked={
																					this.state.isFMSVehicleFE === null
																						? vehicle.isFMSVehicle
																						: this.state.isFMSVehicleFE
																				}
																				// onChange={this.handleToggle}
																				disabled={!this.props.editMode || this.isSuperAdminAccess === 'false'}
																			/>
																		</Container>
																	</Form.Field>
																</Form.Group>
															</Form>
														</Segment>
														{/*<Header as="h3" attached="top" color="black">*/}
														{/*  Fuel Data*/}
														{/*</Header>*/}
														{/*<Segment attached>*/}
														{/*  <Form>*/}
														{/*    <Form.Field>*/}
														{/*      <label>Sensors</label>*/}

														{/*      <Grid columns={3} divided>*/}
														{/*        <Grid.Row>*/}
														{/*          <Grid.Column>*/}
														{/*            <FuelDataSourceDropdown*/}
														{/*              label={'Fuel data source'}*/}
														{/*              disabled={!this.props.editMode}*/}
														{/*              value={*/}
														{/*                this.state.fuelDataSourceFE != null*/}
														{/*                  ? this.state.fuelDataSourceFE*/}
														{/*                  : vehicle.fuelDataSource == null*/}
														{/*                  ? ''*/}
														{/*                  : vehicle.fuelDataSource*/}
														{/*              }*/}
														{/*              onChange={(e, {value}) => {*/}
														{/*                this.setState({*/}
														{/*                  fuelDataSourceFE: value,*/}
														{/*                  hasFuelSensorFE: value === 1 || value === 2,*/}
														{/*                });*/}
														{/*                if (value === 1 || value === 2) {*/}
														{/*                  console.log('should open modal');*/}
														{/*                  this.vehicleReg = vehicle.registrationNumber;*/}
														{/*                  this.vehicleID = vehicle.id;*/}
														{/*                  dispatchNoPayload('VEHICLES_EDIT_CALIBRATION_MODAL_OPEN');*/}
														{/*                  dispatchNoPayload('VEHICLES_CALIBRATION_MODAL_OPEN');*/}
														{/*                  if (!this.state.countOfFuelSensor && !this.calibrationValue.length) {*/}
														{/*                    this.setState({*/}
														{/*                      countOfFuelSensor: 1,*/}
														{/*                    });*/}
														{/*                    this.calibrationValue[0] = new Array(2).fill('');*/}
														{/*                  }*/}
														{/*                }*/}
														{/*              }}*/}
														{/*            />*/}

														{/*            <ToggleFormField*/}
														{/*              label="Fuel Sensor"*/}
														{/*              checked={*/}
														{/*                this.state.hasFuelSensorFE !== null*/}
														{/*                  ? this.state.hasFuelSensorFE*/}
														{/*                  : vehicle.hasFuelSensor === null*/}
														{/*                  ? ''*/}
														{/*                  : vehicle.hasFuelSensor*/}
														{/*              }*/}
														{/*              disabled={*/}
														{/*                !this.props.editMode && this.hasFuelSensor === null*/}
														{/*                  ? true*/}
														{/*                  : this.hasFuelSensor*/}
														{/*              }*/}
														{/*              onChange={(e, {checked}) => {*/}
														{/*                this.setState({*/}
														{/*                  hasFuelSensorFE: checked,*/}
														{/*                });*/}
														{/*                if (checked) {*/}
														{/*                  this.vehicleReg = vehicle.registrationNumber;*/}
														{/*                  this.vehicleID = vehicle.id;*/}
														{/*                  dispatchNoPayload('VEHICLES_EDIT_CALIBRATION_MODAL_OPEN');*/}
														{/*                  dispatchNoPayload('VEHICLES_CALIBRATION_MODAL_OPEN');*/}
														{/*                  if (!this.state.countOfFuelSensor && !this.calibrationValue.length) {*/}
														{/*                    this.setState({*/}
														{/*                      countOfFuelSensor: 1,*/}
														{/*                    });*/}
														{/*                    this.calibrationValue[0] = new Array(2).fill('');*/}
														{/*                  }*/}
														{/*                }*/}
														{/*              }}*/}
														{/*            />*/}
														{/*          </Grid.Column>*/}
														{/*        </Grid.Row>*/}
														{/*      </Grid>*/}
														{/*    </Form.Field>*/}
														{/*  </Form>*/}
														{/*</Segment>*/}

														{this.state.deletedMode ? (
															<>
																<Actions>
																	{!vehicle.imei ? (
																		<Modal
																			size={'mini'}
																			closeIcon
																			open={this.state.isDeviceImeiModal}
																			trigger={<Button primary>Restore</Button>}
																			onClose={() =>
																				this.setState({
																					isDeviceImeiModal: false,
																				})
																			}
																			onOpen={() => {
																				this.setState({
																					isDeviceImeiModal: true,
																				});

																				getDevicesForDropdown(
																					'?status[]=New&status[]=No Data&status[]=Unlinked&sims=true&dropdown=true'
																				).then((response) => {
																					this.setState({ devices: response });
																				});
																			}}>
																			<Header icon="microchip" content="Add Device" />
																			<Modal.Content>
																				<Dropdown
																					placeholder="Add Devices"
																					fluid
																					search
																					selection
																					options={this.state.devices}
																					onChange={(event, data) => {
																						this.vehicleRestoreId = vehicle.id;
																						this.vehicleRestoreImei = data.value;
																					}}
																				/>
																			</Modal.Content>
																			<Modal.Actions>
																				<Button color="red" onClick={() => this.setState({ isDeviceImeiModal: false })}>
																					<Icon name="remove" /> No
																				</Button>
																				<Button
																					color="green"
																					onClick={() => {
																						this.setState({
																							isDeviceImeiModal: false,
																						});
																						dispatchNoPayload('VEHICLES_RESTORE_MODAL_OPEN');
																					}}>
																					<Icon name="checkmark" /> Yes
																				</Button>
																			</Modal.Actions>
																		</Modal>
																	) : (
																		<Button
																			primary
																			// disabled={!(this.state.manageAccess || this.state.createAccess)}
																			onClick={() => {
																				trackMixpanelEvent('Vehicles', { Action: 'Restore' });
																				dispatchNoPayload('VEHICLES_RESTORE_MODAL_OPEN');
																				this.vehicleRestoreId = vehicle.id;
																				this.vehicleRestoreImei = vehicle.imei;
																			}}>
																			Restore
																		</Button>
																	)}
																</Actions>
															</>
														) : this.props.editMode ? (
															<Actions>
																{/*<div>*/}
																{/*  {this.state.hasFuelSensorFE && (*/}
																{/*    <Button*/}
																{/*      loading={this.props.calibrationLoading}*/}
																{/*      onClick={() => {*/}
																{/*        this.vehicleReg = vehicle.registrationNumber;*/}
																{/*        this.vehicleID = vehicle.id;*/}
																{/*        dispatchNoPayload('VEHICLES_EDIT_CALIBRATION_MODAL_OPEN');*/}
																{/*        dispatchNoPayload('VEHICLES_CALIBRATION_MODAL_OPEN');*/}
																{/*        this.setState({*/}
																{/*          countOfFuelSensor: 1,*/}
																{/*        });*/}
																{/*        this.calibrationValue[0] = new Array(2).fill('');*/}
																{/*      }}>*/}
																{/*      Calibration Table*/}
																{/*    </Button>*/}
																{/*  )}*/}
																{/*</div>*/}
																<section>
																	<Button
																		secondary
																		onClick={() => {
																			this.cleanEditOperation();
																			this.cleanUpdateCalibrationOperation();
																		}}>
																		Cancel
																	</Button>
																	<Button
																		positive
																		loading={this.props.updating}
																		onClick={() => this.setState({ openConfirmForVehicle: true })}>
																		Update
																	</Button>
																	<Confirm
																		content="Are you sure, you want to update the changes?"
																		open={this.state.openConfirmForVehicle}
																		onCancel={() => this.setState({ openConfirmForVehicle: false })}
																		onConfirm={() => {
																			if (
																				this.state.statusFE === 7 &&
																				!(this.state.trialEndDateFE || vehicle.trialEndDate)
																			) {
																				showMessage('Please select trial end date.', MessageType.Error);
																			} else {
																				this.updateVehicles(vehicle.id);
																			}
																		}}
																		size="mini"
																	/>
																</section>
															</Actions>
														) : (
															<LeftActions>
																<div>
																	<Link
																		to={{
																			pathname: '/diagnostic-and-logs/logs',
																			state: { vehicleId: vehicle?.imei },
																		}}>
																		<Button
																			// style={{
																			// 	display: `${
																			// 		!(this.state.manageDiagnosticsAccess || this.state.readDiagnosticsAccess)
																			// 			? 'none'
																			// 			: ''
																			// 	}`,
																			// }}
																			icon
																			// disabled={
																			// 	!(this.state.manageDiagnosticsAccess || this.state.readDiagnosticsAccess)
																			// }
																			title="See Vehicle Logs"
																			onClick={() => {
																				trackMixpanelEvent('Vehicles', { Action: 'Vehicle Logs' });
																				this.setState({
																					// showLogsComponent: true,
																					vehicleDetails: vehicle,
																				});
																			}}>
																			<Icon name="file outline" />
																		</Button>
																	</Link>

																	<Button
																		icon
																		title="See Vehicle Organisation history"
																		onClick={() => {
																			dispatchNoPayload('VEHICLES_ORGANISATION_HISTORY_MODAL_OPEN');
																			this.vehicle = vehicle.id;
																			this.vehicleRegistration = vehicle.registrationNumber;
																			this.organisation = vehicle.organisation;
																			this.installationDate = vehicle.installationDate;
																			this.getOrganisationHistory(vehicle.id);
																		}}>
																		<Icon name="user" />
																	</Button>

																	<Link
																		to={{
																			pathname: '/diagnostic-and-logs/activity-logs',
																			state: { vehicleId: vehicle.id },
																		}}>
																		<Button
																			icon
																			title="Vehicle History"
																			// onClick={() => {
																			//   dispatchNoPayload('VEHICLES_SERVICE_HISTORY_MODAL_OPEN');
																			//   this.vehicleRegistration = vehicle.registrationNumber;
																			//   this.getServiceHistory(vehicle.id);
																			// }}
																		>
																			<Icon name="history" />
																		</Button>
																	</Link>

																	{/*<Button*/}
																	{/*  icon*/}
																	{/*  title="See Config Commands History"*/}
																	{/*  onClick={() => {*/}
																	{/*    dispatchNoPayload('VEHICLES_COMMANDS_HISTORY_MODAL_OPEN');*/}
																	{/*    this.vehicleRegistration = vehicle.registrationNumber;*/}
																	{/*    this.getConfigCommandsHistory(vehicle.imei);*/}
																	{/*  }}>*/}
																	{/*  <Icon name="terminal" />*/}
																	{/*</Button>*/}
																</div>
																<section>
																	<Button
																		primary
																		// disabled={!(this.state.manageAccess || this.state.updateAccess)}
																		onClick={() => {
																			trackMixpanelEvent('Vehicles', { Action: 'Edit' });
																			dispatchNoPayload('VEHICLES_EDIT_MODAL_OPEN');
																			this.hasFuelSensor = vehicle.hasFuelSensor;
																			this.tankCapacity = vehicle.tankCapacity;
																			getGeofenceForDropdown(
																				`?organisations[]=${vehicle.organisationId}&dropdown=true`
																			).then((response) => {
																				this.setState({ geofences: response });
																			});
																		}}>
																		Edit
																	</Button>
																	{/*{vehicle.hasFuelSensor && (*/}
																	{/*	<Button*/}
																	{/*		color="teal"*/}
																	{/*		title="View and Edit Vehicle Calibration Data"*/}
																	{/*		loading={this.props.calibrationLoading}*/}
																	{/*		onClick={() => {*/}
																	{/*			this.vehicleReg = vehicle.registrationNumber;*/}
																	{/*			this.vehicleID = vehicle.id;*/}
																	{/*			this.hasFuelSensor = vehicle.hasFuelSensor;*/}
																	{/*			this.fetchCalibrationData(vehicle.id);*/}
																	{/*		}}>*/}
																	{/*		Calibration Table*/}
																	{/*	</Button>*/}
																	{/*)}*/}

																	{/*{this.isSuperAdminAccess === 'true' && (*/}
																	<Button
																		color="teal"
																		title="Change Status"
																		disabled={
																			this.isSuperAdminAccess === 'false' &&
																			(vehicle?.status === 'Trial' ||
																				vehicle?.status === 'Paused' ||
																				this.state.statusFE === 'Trial' ||
																				this.state.statusFE === 'Paused')
																		}
																		// disabled={!(this.state.manageAccess || this.state.updateAccess)}
																		loading={this.props.exportingData}
																		onClick={() => {
																			this.handleOpenConfirmation();
																		}}>
																		Change Status
																	</Button>
																	{/*)}*/}

																	<Modal
																		size="mini"
																		open={this.state.isConfirmationOpen}
																		onClose={this.handleCloseConfirmation}>
																		<Modal.Header>Change Vehicle Status</Modal.Header>
																		{vehicle.status === 'Active' && this.state.statusFE === 'Trial' && (
																			<div
																				style={{
																					marginLeft: '2rem',
																					marginTop: '1rem',
																					width: '85%',
																				}}>
																				<Form.Field required>
																					<label>Choose Trial End Date</label>
																					<DateInput
																						style={{ marginTop: '.5rem' }}
																						placeholder="Select"
																						fluid
																						disabled={this.state.statusFE !== 'Trial'}
																						closable
																						value={
																							this.state.trialEndDateSC !== null
																								? this.state.trialEndDateSC
																								: vehicle.trialEndDate
																						}
																						onChange={(e, data) => {
																							this.setState({
																								trialEndDateSC: data.value,
																							});
																						}}
																					/>
																				</Form.Field>
																			</div>
																		)}
																		<Modal.Content>
																			<Dropdown
																				fluid
																				clearable
																				selection
																				value={this.state.statusFE}
																				options={this.getStatusOption(vehicle.status)}
																				onChange={(e, { value }) => {
																					this.setState({ statusFE: value });
																				}}
																			/>
																		</Modal.Content>
																		<Modal.Actions>
																			<Button color="black" onClick={this.handleCloseConfirmation}>
																				Cancel
																			</Button>
																			<Button
																				positive
																				disabled={!this.state.statusFE}
																				onClick={() => {
																					this.handleConfirmChangeStatus(vehicle.id, vehicle.trialEndDate);
																				}}>
																				Update
																			</Button>
																		</Modal.Actions>
																	</Modal>

																	<Button
																		color="teal"
																		title="Edit Vehicle Group"
																		// disabled={!(this.state.manageAccess || this.state.updateAccess)}
																		onClick={() => {
																			trackMixpanelEvent('Vehicles', { Action: 'Edit Vehicle Groups' });
																			this.vehicleId = vehicle.id;
																			this.setState({
																				selectedVehicleGroups: vehicle.vehicleGroups.map((group) => group.id),
																			});
																			dispatchNoPayload('VEHICLES_VG_MODAL_OPEN');
																			getVehicleGroupsForDropdown(
																				`?organisations[]=${vehicle.organisationId}&pagenumber=1&pagecount=1000`
																			).then((response) => {
																				this.setState({ vehicleGroups: response });
																			});
																		}}>
																		Edit Vehicle Groups
																	</Button>

																	{this.isSuperAdminAccess === 'true' && (
																		<Button
																			color="teal"
																			title="Swap Device in Vehicle"
																			// disabled={!(this.state.manageAccess || this.state.updateAccess)}
																			onClick={() => {
																				trackMixpanelEvent('Vehicles', { Action: 'Swap Device' });
																				dispatchNoPayload('VEHICLES_SWAP_MODAL_OPEN');
																				getDevicesForDropdown(
																					'?status[]=New&status[]=No Data&status[]=Unlinked&sims=true&dropdown=true'
																				).then((response) => {
																					this.setState({ devices: response });
																				});
																				this.deviceImei = vehicle.imei;
																				this.vehicleID = vehicle.id;
																			}}>
																			Swap Device
																		</Button>
																	)}

																	{this.isSuperAdminAccess === 'true' && (
																		<Button
																			color="teal"
																			title="Change Vehicle Organisation"
																			// disabled={!(this.state.manageAccess || this.state.updateAccess)}
																			onClick={() => {
																				trackMixpanelEvent('Vehicles', { Action: 'Change Organisation' });
																				dispatchNoPayload('VEHICLES_EDIT_ORGANISATION_MODAL_OPEN');
																				this.vehicle = vehicle.id;
																				this.vehicleRegistration = vehicle.registrationNumber;
																				this.organisation = vehicle.organisation;
																				getOrganisationsForDropdown('?dropdown=true').then((response) => {
																					this.setState({ organisationsFEO: response });
																				});
																			}}>
																			Change Organisation
																		</Button>
																	)}

																	<Button
																		negative
																		// disabled={!(this.state.manageAccess || this.state.deleteAccess)}
																		onClick={() => {
																			trackMixpanelEvent('Vehicles', { Action: 'Delete' });
																			dispatchNoPayload('VEHICLES_DELETE_MODAL_OPEN');
																			this.deleteId = vehicle.id;
																		}}>
																		Delete
																	</Button>
																</section>
															</LeftActions>
														)}
													</AccordionMainContent>
												</Accordion.Content>
											)}
										</section>
									);
								})}
							</Accordion>
						) : (
							<PlaceHolder />
						)}
					</AccordionMain>
				</CategoryMain>
				<CategoryFooter background={this.deletedMode}>
					{this.state.selectedRows.size !== 0 && (
						<Button
							primary
							style={{ marginLeft: '1rem' }}
							onClick={() => {
								this.setState({ vehicleBulkUpdateModal: true });
							}}>
							Update
						</Button>
					)}
					<FooterPagination
						handleDataSizeLimitChange={this.handleDataSizeLimitChange}
						pageNumber={this.props.pageNumber}
						handlePageChange={this.handlePageChange}
						count={this.props.count}
						disabled={this.props.loading || !this.props.vehicles.length || this.props.exportingData}
					/>
				</CategoryFooter>
				{/*{this.state.showLogsComponent && (*/}
				{/*	<VehicleLogs vehicle={this.state.vehicleDetails} closeLogsComponent={this.closeLogsComponent} />*/}
				{/*)}*/}
				{this.props.loading && !this.state.initialLoading && <TransitionLoader />}
				{this.props.addModalOpen && (
					<AddModal
						onClose={this.cleanAddOperation}
						// onACStatusChange={this.onACStatusChange}
						// acStatusFA={this.state.acStatusFA}
						open={this.props.addModalOpen}
						devicesOptions={this.state.devices}
						onDeviceModelChoose={this.onDeviceModelChoose}
						onRegNumberChange={this.onRegNumberChange}
						vehicleTypeOptions={this.state.vehicleTypeOptions}
						vehicleType={this.state.vehicleTypeFA}
						onVehicleTypeChoose={this.onVehicleTypeChoose}
						onMakeChange={this.onMakeChange}
						onModelChange={this.onModelChange}
						onChassisNumberChange={this.onChassisNumberChange}
						onNoteChange={this.onNoteChange}
						onExpectedMileageChange={this.onExpectedMileageChange}
						organisationsOptions={this.state.organisations}
						organisationFA={this.state.organisationFA}
						onOrgChange={this.onOrgChange}
						vehicleGroups={this.state.vehicleGroups}
						vehicleGroupFA={this.state.vehicleGroupFA}
						onVGChange={this.onVGChange}
						geofences={this.state.geofences}
						geofencesFA={this.state.geofenceFA}
						onGeofenceChange={this.onGeofenceChange}
						installationDateFA={this.state.installationDateFA}
						createdDateFA={this.state.createdDateFA}
						onInstallationDateChange={this.onInstallationDateChange}
						odometerFA={this.state.odometerFA}
						onOdometerChange={this.onOdometerChange}
						onTankCapacityChange={this.onTankCapacityChange}
						status={this.state.statusFA}
						sensors={this.state.sensorsFA}
						vehicles={this.state.vehicles}
						onStatusChangeFA={this.onStatusChangeFA}
						onSensorChangeFA={this.onSensorChangeFA}
						trialEndDate={this.state.trialEndDate}
						onTrialDateChange={this.onTrialDateChange}
						hasFuelSensorFA={this.state.hasFuelSensorFA}
						onFuelSensorChoose={this.onFuelSensorChoose}
						// fuelDataSourceFA={this.state.fuelDataSourceFA}
						// onFuelDataSourceChoose={this.onFuelDataSourceChoose}
						// hasImmobilizerFA={this.state.hasImmobilizerFA}
						// onImmobilizerChoose={this.onImmobilizerChoose}
						hasAnalog={this.state.hasAnalog}
						isFMSVehicleFA={this.state.isFMSVehicleFA}
						onisFMSVehicleFAChange={this.onisFMSVehicleFAChange}
						onAnalogChoose={this.onAnalogChoose}
						fileInputRef={this.fileInputRef}
						// handleInputFile={this.handleInputFile}
						accept={this.SheetJSFT}
						fuelSensorDropdown={this.fuelSensorDropdown}
						countOfFuelSensor={this.state.countOfFuelSensor}
						onFuelSensorChange={this.onFuelSensorChange}
						calibrationHeader={this.getHeaders()}
						calibrationRows={this.getRows}
						onIdlingDuration={this.onIdlingDurationChange}
						onParkingDuration={this.onParkingDurationChange}
						calibrationValue={this.calibrationValue}
						onSpeedLimitChange={(e) => this.setState({ speedLimitFA: e.target.value })}
						onCloseButtonClick={this.removeRow}
						onCancelClick={this.cleanAddOperation}
						disabled={
							!this.state.deviceIMEIFA ||
							!this.state.registrationNumberFA ||
							!this.state.vehicleTypeFA ||
							!this.state.organisationFA ||
							!this.state.installationDateFA ||
							!this.state.statusFA ||
							// (this.state.hasFuelSensorFA && !this.state.tankCapacityFA) ||
							(this.state.hasFuelSensorFA && !this.state.countOfFuelSensor)
						}
						onAddButtonClick={this.add}
						addButtonLoading={this.props.adding}
					/>
				)}
				{this.props.openVGModal && (
					<VehicleGroupModal
						onClose={this.cleanVGOperation}
						open={this.props.openVGModal}
						selectedVehicleGroup={this.state.selectedVehicleGroups}
						vehicleGroupOptions={this.state.vehicleGroups}
						onVehicleGroupUpdate={this.onVehicleGroupUpdate}
						onCancelClick={this.cleanVGOperation}
						onUpdateButtonClick={() => this.updateVehicleGroup(this.vehicleId)}
						updateButtonLoading={this.props.updating}
					/>
				)}
				{/*<VehicleStatusModal />*/}
				{this.props.swapModal && (
					<SwapModal
						onClose={this.cleanSwapOperation}
						open={this.props.swapModal}
						placeHolder={this.deviceImei}
						devices={this.state.devices}
						onDeviceChoose={this.onDeviceChoose}
						onReasonChoose={this.onReasonChoose}
						onCancelClick={this.cleanSwapOperation}
						onSwapButtonClick={() =>
							this.setState({
								swapDeviceConfirmation: true,
							})
						}
					/>
				)}
				{this.props.deleteModalOpen && (
					<DeleteModal
						onClose={this.cleanDeleteOperation}
						open={this.props.deleteModalOpen}
						onCancelClick={this.cleanDeleteOperation}
						onDeleteButtonClick={() => {
							this.deleteVehicle(this.deleteId);
						}}
						deleteButtonLoading={this.props.deleting}
					/>
				)}
				{this.props.restoreModal && (
					<RestoreModal
						onClose={this.cleanRestoreOperation}
						open={this.props.restoreModal}
						onCancelClick={this.cleanRestoreOperation}
						onRestoreButtonClick={() => {
							this.restoreVehicle(this.vehicleRestoreId, this.vehicleRestoreImei);
						}}
						restoreButtonLoading={this.props.restoring}
					/>
				)}

				{this.props.orgHistoryModal && (
					<OrganisationHistoryModal
						onClose={this.cleanOrgHistoryOperation}
						loading={this.state.orgHistoryLoading}
						open={this.props.orgHistoryModal}
						header={this.vehicleRegistration}
						orgHistory={this.state.organisationHistory}
						onCancelClick={this.cleanOrgHistoryOperation}
					/>
				)}
				{this.props.serviceHistoryModal && (
					<TimeLineViewModal
						onClose={this.cleanServiceHistoryOperation}
						loading={this.state.serviceHistoryLoading}
						open={this.props.serviceHistoryModal}
						header={this.vehicleRegistration}
						data={this.state.serviceHistory}
						onCancelClick={this.cleanServiceHistoryOperation}
					/>
				)}
				{this.props.editOrganisationModal && (
					<EditOrganisationModal
						onClose={this.cleanUpdateOrgOperation}
						open={this.props.editOrganisationModal}
						header={this.vehicleRegistration}
						currentOrganisation={this.organisation}
						selectedOrganisation={this.state.selectedOrganisation}
						organisationOptions={this.state.organisationsFEO}
						onOrganisationChange={this.onOrgChooseFEO}
						selectedVehicleGroup={this.state.selectedVehicleGroupsFEO}
						vehicleGroupsOptions={this.state.vehicleGroupsFEO}
						onVehicleGroupChange={this.onVGChooseFEO}
						onCancelClick={this.cleanUpdateOrgOperation}
						disabled={!this.state.selectedOrganisation && !this.state.selectedVehicleGroupsFEO}
						onChangeButtonClick={() => {
							this.changeOrganisation(this.vehicle);
						}}
						changeButtonLoading={this.props.orgEditing}
					/>
				)}
				{/*{this.props.calibrationModal && (*/}
				{/*  <CalibrationModal*/}
				{/*    onClose={this.cleanUpdateCalibrationOperation}*/}
				{/*    open={this.props.calibrationModal}*/}
				{/*    header={this.vehicleReg}*/}
				{/*    editMode={this.props.calibrationEditModal}*/}
				{/*    fetchCalibrationData={() => this.fetchCalibrationData(this.vehicleID)}*/}
				{/*    hasFuelSensor={this.state.hasFuelSensorFE}*/}
				{/*    fileInputRef={this.fileInputRef}*/}
				{/*    handleInputFile={this.handleInputFile}*/}
				{/*    accept={this.SheetJSFT}*/}
				{/*    hasAnalog={this.state.hasAnalog}*/}
				{/*    onAnalogChange={this.onAnalogChange}*/}
				{/*    fuelSensorOptions={this.fuelSensorDropdown}*/}
				{/*    countOfFuelSensor={this.state.countOfFuelSensor}*/}
				{/*    onFuelSensorChange={this.onFuelSensorChange}*/}
				{/*    calibrationHeader={this.getHeaders()}*/}
				{/*    calibrationRows={this.getRows}*/}
				{/*    calibrationValue={this.calibrationValue}*/}
				{/*    onCloseButtonClick={this.removeRow}*/}
				{/*    onCancelClick={this.cleanUpdateCalibrationOperation}*/}
				{/*    onUpdateButtonClick={() => {*/}
				{/*      this.update(this.vehicleID);*/}
				{/*    }}*/}
				{/*    updateButtonLoading={this.props.updating}*/}
				{/*  />*/}
				{/*)}*/}
				{this.props.recalculationModal && (
					<RecalculateModal
						onClose={this.cleanRecalculationOperation}
						open={this.props.recalculationModal}
						allOrganisations={this.state.allOrganisations}
						onOrgChecked={this.onOrgChecked}
						organisationOptions={this.state.organisations}
						selectedOrganisations={this.state.selectedOrganisations}
						onOrgSelect={this.onOrgSelect}
						allVehicles={this.state.allVehicles}
						onAllVehicleChecked={this.onAllVehicleChecked}
						vehiclesFRC={this.state.vehiclesFRC}
						selectedVehicles={this.state.selectedVehicles}
						onVehicleSelect={this.onVehicleSelect}
						startTime={this.state.startTime}
						handleChange={this.handleChange}
						enableForce={this.state.enableForce}
						toggleEnableForce={this.toggleEnableForce}
						onCancelClick={this.cleanRecalculationOperation}
						onRecalculateButtonClick={this.recalculate}
						recalculateButtonLoading={this.props.recalculating}
					/>
				)}

				{this.props.newrecalculationModal && (
					<RecalculateModal
						onClose={this.cleanRecalculationOperation}
						open={this.props.newrecalculationModal}
						allOrganisations={this.state.allOrganisations}
						onOrgChecked={this.onOrgChecked}
						organisationOptions={this.state.organisations}
						selectedOrganisations={this.state.selectedOrganisations}
						onOrgSelect={this.onOrgSelect}
						allVehicles={this.state.allVehicles}
						onAllVehicleChecked={this.onAllVehicleChecked}
						vehiclesFRC={this.state.vehiclesFRC}
						selectedVehicles={this.state.selectedVehicles}
						onVehicleSelect={this.onVehicleSelect}
						startTime={this.state.startTime}
						handleChange={this.handleChange}
						enableForce={this.state.enableForce}
						toggleEnableForce={this.toggleEnableForce}
						onCancelClick={this.cleanRecalculationOperation}
						onRecalculateButtonClick={this.newrecalculate}
						recalculateButtonLoading={this.props.recalculating}
					/>
				)}

				{this.props.openExportModal && (
					<ExportModal
						open={this.props.openExportModal}
						fileName={this.state.fileName}
						fileNameChange={this.fileNameChange}
						fileExtension={this.state.fileExtension}
						fileExtensionChange={this.fileExtensionChange}
						onDownloadClick={() =>
							exportData(this.props.exportData, this.state.fileName, this.state.fileExtension, this.state.lastLocation)
						}
					/>
				)}
				{/*Filter Modal*/}
				{this.props.filterModalOpen && (
					<FilterModal
						onClose={() => {
							this.setFilterChange(
								this.activeFilters.organisations,
								this.activeFilters.type,
								this.activeFilters.immobilizer,
								this.activeFilters.fuel,
								this.activeFilters.status,
								this.activeFilters.sensors,
								this.activeFilters.startDate,
								this.activeFilters.endDate,
								this.activeFilters.relayStatus,
								this.activeFilters.isFMSVehicle
							);
							closeFilterModal();
						}}
						open={this.props.filterModalOpen}
						organisationsOptions={this.state.organisations}
						// organisations={this.state.filters.organisations}
						organisations={this.props.filters.organisations}
						onOrganisationFilterChange={this.onOrganisationFilterChange}
						type={this.state.filters.type}
						onTypeFilterChange={this.onTypeFilterChange}
						immobilizer={this.state.filters.immobilizer}
						onImmobilizerFilterChange={this.onImmobilizerFilterChange}
						fuel={this.state.filters.fuel}
						onRelayFilterChange={this.onRelayFilterChange}
						relayStatus={this.state.filters.relayStatus}
						onFuelSensorFilterChange={this.onFuelSensorFilterChange}
						status={this.state.filters.status}
						sensors={this.state.filters.sensors}
						onSensorsFilterChange={this.onSensorsFilterChange}
						onStatusFilterChange={this.onStatusFilterChange}
						isFMSVehicle={this.state.filters.isFMSVehicle}
						onIsFMSFilterChange={this.onIsFMSFilterChange}
						startDate={this.state.filters.startDate}
						endDate={this.state.filters.endDate}
						handleChangeDate={this.handleChangeDate}
						handleClearDate={this.handleClearDate}
						onCancelClick={() => {
							// this.activeDateFilter = false;
							this.setFilterChange(
								this.activeFilters.organisations,
								this.activeFilters.type,
								this.activeFilters.immobilizer,
								this.activeFilters.fuel,
								this.activeFilters.status,
								this.activeFilters.sensors,
								this.activeFilters.startDate,
								this.activeFilters.endDate,
								this.activeFilters.relayStatus,
								this.activeFilters.isFMSVehicle
							);
							closeFilterModal();
							dispatch('ACTIVE_FILTER', { ...this.props.filters, organisations: [] });
						}}
						onResetClick={() => {
							this.resetFilter();
							// closeFilterModal();
							this.getFilteredVehicles();
							dispatch('ACTIVE_FILTER', {
								...this.props.filters,
								organisations: [],
								isFMSVehicle: null,
							});
						}}
						onApplyClick={() => {
							trackMixpanelEvent('Vehicles', { Action: 'Filter' });
							this.activeFilters = this.state.filters;
							this.activeFilters.organisations = this.props.filters.organisations;
							this.getFilteredVehicles();
							closeFilterModal();
						}}
					/>
				)}
				{this.props.commandsHistoryModal && (
					<MessageTimeLine
						onClose={this.cleanCommandsHistoryOperation}
						loading={this.state.commandsHistoryLoading}
						open={this.props.commandsHistoryModal}
						header={this.vehicleRegistration}
						data={this.state.commandsHistory}
						onCancelClick={this.cleanCommandsHistoryOperation}
					/>
				)}
				{this.state.swapDeviceConfirmation && (
					<SwapDeviceConfirmation
						onClose={() => this.setState({ swapDeviceConfirmation: false })}
						open={this.state.swapDeviceConfirmation}
						onCancelClick={() => this.setState({ swapDeviceConfirmation: false })}
						onSwapButtonClick={() => this.swapDevice(this.vehicleID)}
						swapButtonLoading={this.props.swapping}
					/>
				)}

				{/*VehicleModal*/}
				{this.state.vehicleBulkUpdateModal && (
					<VehicleBulkUpdateModal
						open={this.state.vehicleBulkUpdateModal}
						onClose={this.cleanBulkUpdateOperations}
						onCancelClick={this.cleanBulkUpdateOperations}
						installationDate={this.state.installationDateBulk}
						onInstallationDateChange={this.onBulkInstallationDateChange}
						odometer={this.state.odometerBulk}
						isFMS={this.state.isFMSBulk}
						onIsFMSChange={this.onIsFMSChangeBulk}
						onOdometerChange={(e) => this.setState({ odometerBulk: e.target.value })}
						onSpeedLimitChange={(e) => this.setState({ speedLimitBulk: e.target.value })}
						onIdlingDuration={(e) => this.setState({ idlingDurationBulk: e.target.value })}
						onParkingDuration={(e) => this.setState({ parkingDurationBulk: e.target.value })}
						onMakeChange={(e) => this.setState({ makeBulk: e.target.value })}
						onModelChange={(e) => this.setState({ modelBulk: e.target.value })}
						onNoteChange={(e) => this.setState({ noteBulk: e.target.value })}
						onExpectedMileageChange={(e) => {
							this.setState({ expectedMileageBulk: Number(e.target.value) });
						}}
						vehicleTypeOptions={this.state.vehicleTypeOptions}
						vehicleStatusOptions={this.state.vehicleStatusOptions}
						vehicleTypeBulk={this.state.vehicleTypeBulk}
						vehicleStatusBulk={this.state.vehicleStatusBulk}
						onVehicleTypeChoose={this.onVehicleTypeChange}
						onVehicleStatusChoose={this.onVehicleStatusChange}
						onApplyClick={this.bulkUpdateVehicles}
						// disabled={
						// 	!this.state.vehicleTypeBulk &&
						// 	!this.state.odometerBulk &&
						// 	!this.state.speedLimitBulk &&
						// 	!this.state.idlingDurationBulk &&
						// 	!this.state.parkingDurationBulk &&
						// 	!this.state.installationDateBulk &&
						// 	!this.state.makeBulk &&
						// 	!this.state.modelBulk &&
						// 	!this.state.noteBulk &&
						// 	!this.state.expectedMileageBulk &&
						// 	!this.state.isFMSBulk
						// }
						bulkButtonLoading={this.props.bulkUpdating}
					/>
				)}

				{/*{this.state.installationDateConfirmation && (*/}
				{/*  <InstallationDateConfirmation*/}
				{/*    onClose={() => {*/}
				{/*      this.confirmInstallationDate = false;*/}
				{/*      this.setState({ installationDateConfirmation: false });*/}
				{/*    }}*/}
				{/*    open={this.state.installationDateConfirmation}*/}
				{/*    onCancelClick={() => {*/}
				{/*      this.confirmInstallationDate = false;*/}
				{/*      this.setState({ installationDateConfirmation: false });*/}
				{/*    }}*/}
				{/*    onOkButtonClick={() => {*/}
				{/*      this.confirmInstallationDate = true;*/}
				{/*      this.setState({ installationDateConfirmation: false });*/}
				{/*    }}*/}
				{/*  />*/}
				{/*)}*/}
			</CategoryContentMembrane>
		);
	}
}

// Connect to Store
const mapStateToProps = (state) => ({
	vehicles: state.vehicles.vehicles,
	loading: state.vehicles.loading,
	initialDate: state.dates.initialDate,
	finalDate: state.dates.finalDate,
	activeIndex: state.vehicles.activeIndex,
	count: state.vehicles.count,
	pageNumber: state.vehicles.pageNumber,
	dataSizeLimit: state.ui.dataSizeLimit,

	adding: state.vehicles.adding,
	addModalOpen: state.vehicles.addModalOpen,

	editMode: state.vehicles.editMode,
	updating: state.vehicles.updating,

	swapModal: state.vehicles.swapModal,
	swapping: state.vehicles.swapping,

	openVGModal: state.vehicles.vehicleGroupModal,

	deleteModalOpen: state.vehicles.deleteModalOpen,
	deleting: state.vehicles.deleting,

	activeSort: state.vehicles.activeSort,
	sortOrder: state.vehicles.sortOrder,

	restoreModal: state.vehicles.restoreModal,
	restoring: state.vehicles.restoring,

	serviceHistoryModal: state.vehicles.serviceHistoryModal,
	orgHistoryModal: state.vehicles.orgHistoryModal,
	filterModalOpen: state.vehicles.filterModalOpen,
	commandsHistoryModal: state.vehicles.commandsHistoryModal,

	editOrganisationModal: state.vehicles.editOrganisationModal,
	orgEditing: state.vehicles.orgEditing,
	// calibrationModal: state.vehicles.calibrationModal,
	calibrationEditModal: state.vehicles.calibrationEditModal,
	calibrationLoading: state.vehicles.calibrationLoading,
	// timestampStatus: state.firebaseService.timestampStatus,
	recalculationModal: state.vehicles.recalculationModal,
	newrecalculationModal: state.vehicles.newrecalculationModal,
	recalculating: state.vehicles.recalculating,

	openExportModal: state.vehicles.openExportModal,
	exportData: state.vehicles.exportData,
	exportingData: state.vehicles.exportingData,
	searchQuery: state.vehicles.searchQuery,
	filters: state.vehicles.filters,
	updateModal: state.vehicles.updateModal,
	bulkUpdating: state.vehicles.bulkUpdating,
});

export default connect(mapStateToProps)(Vehicles);
