import styled from 'styled-components';
import * as THEME from '../../styles/colors';

export const MainContainer = styled.section`
	width: 100vw;
	height: 100vh;

	box-sizing: border-box;
	padding: 1.25em;

	display: flex;
	justify-content: center;
	align-items: center;

	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;

	background: rgba(0, 0, 0, 0.75);
`;

export const Container = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;

	background-color: #fff;
	border-radius: 8px;

	overflow: hidden;

	.ui.divider {
		margin: 0;
	}
`;

export const Header = styled.section`
	width: 100%;
	height: 3.5em;

	display: flex;
	justify-content: space-between;
	align-items: center;

	box-sizing: border-box;
	padding: 0.5em;

	img {
		height: 2.5em;
		padding-right: 1em;
	}
`;

export const BackButtonContainer = styled.section`
	height: 100%;
	justify-content: flex-end;
	display: flex;
	align-items: center;
`;

export const PdfContainer = styled.section`
	width: 100%;
	height: calc(100% - 3.5em);

	display: flex;
	justify-content: center;

	box-sizing: border-box;
	padding: 1.5em;

	overflow: auto;
`;

export const FileHeader = styled.section`
	margin-left: 1em;
	//font-weight: 700;
	font-size: 1.4em;
	//justify-content: flex-start;
	align-items: center;
	color: ${THEME.COLORS.theme};
`;
