import styled from 'styled-components';

export const GraphTooltip = styled.section`
	box-sizing: border-box;
	padding: 1em;

	color: ${(props) => props.theme.graphToolTipText};
	background: ${(props) => props.theme.graphToolTipBackground};
	border-radius: 5px;

	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

	white-space: nowrap;
`;
