// Device Status Filter
export const DEVICES_STATUS_FILTER_OPTIONS = [
	{
		key: 1,
		text: 'New',
		value: 'New',
	},
	{
		key: 2,
		text: 'Unlinked',
		value: 'Unlinked',
	},
	// {
	// 	key: 3,
	// 	text: 'Client',
	// 	value: 'Client',
	// },
	{
		key: 4,
		text: 'Vehicle',
		value: 'Vehicle',
	},
	{
		key: 5,
		text: 'Missing',
		value: 'Missing',
	},
	{
		key: 6,
		text: 'Terminated',
		value: 'Terminated',
	},
	{
		key: 7,
		text: 'No Data',
		value: 'No Data',
	},
	// {
	// 	key: 8,
	// 	text: 'Sent for Service',
	// 	value: 'Sent for Service',
	// },
	// {
	// 	key: 9,
	// 	text: 'Repaired',
	// 	value: 'Repaired',
	// },
	// {
	// 	key: 10,
	// 	text: 'Replaced',
	// 	value: 'Replaced',
	// },
];

// Sims Status Filter
export const SIMS_STATUS_FILTER_OPTIONS = [
	{
		key: 1,
		text: 'New',
		value: 'New',
	},
	// {
	//   key: 2,
	//   text: 'No Data',
	//   value: 'No Data',
	// },
	{
		key: 3,
		text: 'Device',
		value: 'Device',
	},
	{
		key: 4,
		text: 'Terminated',
		value: 'Terminated',
	},
];

// Vehicle Type Filter
export const VEHICLES_TYPE_FILTER_OPTIONS = [
	{
		key: 1,
		text: 'Car',
		value: 'Car',
	},
	{
		key: 2,
		text: 'Bike',
		value: 'Bike',
	},
	{
		key: 3,
		text: 'Truck',
		value: 'Truck',
	},
	{
		key: 4,
		text: 'Construction Machinery',
		value: 'Construction Machinery',
	},
	{
		key: 5,
		text: 'Bus',
		value: 'Bus',
	},
	{
		key: 6,
		text: 'Generator',
		value: 'Generator',
	},
	{
		key: 7,
		text: 'Container',
		value: 'Container',
	},
	{
		key: 8,
		text: 'Mini Truck',
		value: 'Mini Truck',
	},
	{
		key: 9,
		text: 'Auto',
		value: 'Auto',
	},
];

// Vehicle Immobilizer Filter
export const VEHICLES_IMMOBILIZER_FILTER_OPTIONS = [
	{
		key: 1,
		text: 'Installed',
		value: 1,
	},
	{
		key: 2,
		text: 'Not Installed',
		value: 0,
	},
];

// Vehicle Fuel Filter
export const VEHICLES_FUEL_FILTER_OPTIONS = [
	{
		key: 1,
		text: 'Installed',
		value: 1,
	},
	{
		key: 2,
		text: 'Not Installed',
		value: 0,
	},
];

// Saas Plan Product Filter
export const SAAS_PLAN_PRODUCT_FILTER_OPTIONS = [
	{
		key: 1,
		text: 'GPS',
		value: 1,
	},
	{
		key: 2,
		text: 'FMS',
		value: 2,
	},
];

// Saas Plan Tenure Filter
export const SAAS_PLAN_TENURE_FILTER_OPTIONS = [
	{
		key: 1,
		text: 'Monthly',
		value: 1,
	},
	{
		key: 2,
		text: 'Quarterly',
		value: 3,
	},
	{
		key: 1,
		text: 'Half Yearly',
		value: 6,
	},
	{
		key: 2,
		text: 'Yearly',
		value: 12,
	},
];

// Saas Plan Status Filter
export const SAAS_PLAN_STATUS_FILTER_OPTIONS = [
	{
		key: 1,
		text: 'Active',
		value: 1,
	},
	{
		key: 2,
		text: 'Customize',
		value: 2,
	},
];

// Sim Bills Status Filter
export const SIM_BILLS_STATUS_FILTER_OPTIONS = [
	{
		key: 1,
		text: 'Used',
		value: 1,
	},
	{
		key: 2,
		text: 'Not Used',
		value: 2,
	},
];

// Sim Bills Service Provider Filter
export const SIM_BILLS_SERVICE_PROVIDER_FILTER_OPTIONS = [
	{
		key: 1,
		text: 'Airtel',
		value: 'Airtel',
	},
	{
		key: 2,
		text: 'Vodafone',
		value: 'Vodafone',
	},
];

// Commands Pipeline Status Filter
export const COMMANDS_PIPELINE_STATUS_FILTER_OPTIONS = [
	{
		key: 1,
		text: 'Pipeline',
		value: 1,
	},
	{
		key: 2,
		text: 'Sent',
		value: 2,
	},
	{
		key: 3,
		text: 'Not Sent',
		value: 3,
	},
	{
		key: 4,
		text: 'Received',
		value: 4,
	},
	{
		key: 5,
		text: 'Deleted',
		value: 5,
	},
];

// Commands Pipeline Source Filter
export const COMMANDS_PIPELINE_SOURCE_FILTER_OPTIONS = [
	{
		key: 1,
		text: 'API',
		value: 1,
	},
	{
		key: 2,
		text: 'Server',
		value: 2,
	},
];

export const COMMANDS_PIPELINE_TYPE_FILTER_OPTIONS = [
	{
		key: 1,
		text: 'Request',
		value: 1,
	},
	{
		key: 2,
		text: 'Response',
		value: 2,
	},
];

// Vehicle Logs Ignition Filter
export const VEHICLE_LOGS_IGNITION_FILTER_OPTIONS = [
	{
		key: 1,
		text: 'On',
		value: 1,
	},
	{
		key: 2,
		text: 'Off',
		value: 0,
	},
];

export const VEHICLE_LOGS_EXTERNAL_POWER_FILTER_OPTIONS = [
	{
		key: 1,
		text: 'True',
		value: 1,
	},
	{
		key: 2,
		text: 'False',
		value: 0,
	},
];

// export const RELAY_STATUS_OPTIONS = [
// 	{
// 		key: 1,
// 		text: 'True',
// 		value: 1,
// 	},
// 	{
// 		key: 2,
// 		text: 'False',
// 		value: 0,
// 	},
// ];

// Vehicle Logs GPS Filter
export const VEHICLE_LOGS_GPS_FILTER_OPTIONS = [
	{
		key: 1,
		text: 'On',
		value: 1,
	},
	{
		key: 2,
		text: 'Off',
		value: 0,
	},
];

export const AC_STATUS_FILTER_OPTIONS = [
	{
		key: 1,
		text: 'On',
		value: 1,
	},
	{
		key: 2,
		text: 'Off',
		value: 0,
	},
];

export const PANIC_BUTTON_FILTER_OPTIONS = [
	{
		key: 1,
		text: 'True',
		value: 1,
	},
	{
		key: 2,
		text: 'False',
		value: 0,
	},
];

// Vehicle Logs Immobilizer Filter
export const VEHICLE_LOGS_IMMOBILIZER_FILTER_OPTIONS = [
	{
		key: 1,
		text: 'On',
		value: 1,
	},
	{
		key: 2,
		text: 'Off',
		value: 0,
	},
];

// SMS Report Events Filter
export const SMS_REPORT_EVENTS_FILTER__OPTIONS = [
	{
		key: 1,
		value: 1,
		text: 'Ignition',
	},
	{
		key: 3,
		value: 3,
		text: 'Immobilizer',
	},
	{
		key: 4,
		value: 4,
		text: 'Geo Fence',
	},
	{
		key: 5,
		value: 5,
		text: 'Main Power',
	},
	{
		key: 6,
		value: 6,
		text: 'Delivery',
	},
	{
		key: 7,
		value: 7,
		text: 'Over Speeding',
	},
	{
		key: 8,
		value: 10,
		text: 'Tracking Link',
	},
];

// SMS Report Status Filter
export const SMS_REPORT_STATUS_FILTER__OPTIONS = [
	{
		key: 1,
		value: 1,
		text: 'Sent',
	},
	{
		key: 2,
		value: 2,
		text: 'Not Sent',
	},
	{
		key: 3,
		value: 3,
		text: 'Delivered',
	},
	{
		key: 4,
		value: 4,
		text: 'Not Delivered',
	},
];

// User Type Filter
export const USERS_TYPE_FILTER__OPTIONS = [
	{
		key: 1,
		value: 1,
		text: 'Default',
	},
	{
		key: 2,
		value: 2,
		text: 'Driver',
	},
];

// Organisations
export const INDUSTRY_OPTIONS = [
	{
		key: 1,
		value: 1,
		text: 'Construction',
	},
	{
		key: 2,
		value: 2,
		text: 'Logistics',
	},
	{
		key: 3,
		value: 3,
		text: 'Public Transport',
	},
	{
		key: 4,
		value: 4,
		text: 'Car Rentals',
	},
	{
		key: 5,
		value: 5,
		text: 'Government Municipality',
	},
];
export const DATA_PROCESSING_OPTIONS = [
	{
		key: 1,
		value: 'Real Time',
		text: 'Real Time',
	},
	{
		key: 2,
		value: 'Accuracy',
		text: 'Accuracy',
	},
];
export const PLATFORM_AWARENESS_OPTIONS = [
	{
		key: 1,
		value: 1,
		text: 'Low',
	},
	{
		key: 2,
		value: 2,
		text: 'Medium',
	},
	{
		key: 3,
		value: 3,
		text: 'High',
	},
];

export const PREFERRED_COMMUNICATION_CHANNEL_OPTIONS = [
	{
		key: 1,
		value: 1,
		text: 'Phone',
	},
	{
		key: 2,
		value: 2,
		text: 'SMS',
	},
	{
		key: 3,
		value: 3,
		text: 'Email',
	},
	{
		key: 4,
		value: 4,
		text: 'Whatsapp',
	},
];

export const ORGANISATIONS_SOLUTIONS_OPTIONS = [
	{
		key: 1,
		value: 1,
		text: 'GPS',
	},
	{
		key: 2,
		value: 2,
		text: 'FMS',
	},
	{
		key: 3,
		value: 3,
		text: 'GPS/FMS',
	},
];

// Vehicle Trial
export const VEHICLE_STATUS_OPTIONS = [
	{
		key: 1,
		value: 'Active',
		text: 'Active',
	},
	{
		key: 2,
		value: 'Breakdown',
		text: 'Breakdown',
	},
	// {
	// 	key: 3,
	// 	value: 'Maintenance',
	// 	text: 'Maintenance',
	// },
	// {
	// 	key: 4,
	// 	value: 'Standby',
	// 	text: 'Standby',
	// },
	{
		key: 5,
		value: 'Paused',
		text: 'Paused',
	},
	// {
	// 	key: 6,
	// 	value: 'Suspended',
	// 	text: 'Suspended',
	// },
	{
		key: 7,
		value: 'Trial',
		text: 'Trial',
	},
];

export const ADD_VEHICLE_STATUS_OPTIONS = [
	{
		key: 1,
		value: 'Active',
		text: 'Active',
	},
	{
		key: 2,
		value: 'Trial',
		text: 'Trial',
	},
];
export const VEHICLE_SENSORS_OPTIONS = [
	// { key: 0, text: 'Immobilizer', value: 'Immobilizer' },
	{ key: 0, text: 'FUEL_OBD_LITRE', value: 'fuel_obd_litre' },
	{ key: 1, text: 'FUEL_OBD_PERCENTAGE', value: 'fuel_obd_percentage' },
	{ key: 2, text: 'RELAY', value: 'relay' },
	{ key: 3, text: 'INVERTED_RELAY', value: 'inverted_relay' },
	{ key: 4, text: 'AC_ALERT', value: 'ac_alert' },
	{ key: 5, text: 'IGNITION', value: 'ignition' },
	{ key: 6, text: 'KEYLOCK', value: 'keylock' },
	{ key: 7, text: 'SOS', value: 'sos' },
	{ key: 8, text: 'WHEEL_LOCK', value: 'wheellock' },
];

export const RELAY_STATUS_OPTIONS = [
	{
		key: '0',
		text: 'Immobilised',
		value: true,
	},
	{
		key: '1',
		text: 'Mobilised',
		value: false,
	},
];

export const VEHICLE_DOCUMENT_TYPES_OPTIONS = [
	{
		key: 1,
		value: 'Road Tax',
		text: 'Road Tax',
	},
	{
		key: 2,
		value: 'Vehicle Fitness',
		text: 'Vehicle Fitness',
	},
	{
		key: 3,
		value: 'Vehicle Insurance',
		text: 'Vehicle Insurance',
	},
	{
		key: 4,
		value: 'Vehicle Registration',
		text: 'Vehicle Registration',
	},
	{
		key: 5,
		value: 'PUC',
		text: 'PUC',
	},
];

export const TESTCASE_OPTIONS = [
	// {
	// 	key: 1,
	// 	value: 'Fleet Status Lokkate Dash Check',
	// 	text: 'Fleet Status Lokkate Dash Check',
	// },
	{
		key: 2,
		value: 'MVP Lokkate Dash Check',
		text: 'MVP Lokkate Dash Check',
	},
	{
		key: 3,
		value: 'Performers Lokkate Dash Check',
		text: 'Performers Lokkate Dash Check',
	},
	{
		key: 4,
		value: 'Idling Lokkate Dash Check',
		text: 'Idling Lokkate Dash Check',
	},

	{
		key: 5,
		value: 'KM Match - Lokkate Check',
		text: 'KM Match - Lokkate Check',
	},

	{
		key: 6,
		value: 'Summary Accuracy - Lokkate Check',
		text: 'Summary Accuracy - Lokkate Check',
	},

	{
		key: 7,
		value: 'Trial',
		text: 'Trial Count - CRM Dash Check',
	},

	{
		key: 8,
		value: 'Deactivated',
		text: 'Deactivated Count - CRM Dash Check',
	},

	{
		key: 9,
		value: 'Recent Vehicle',
		text: 'Recent Vehicle Count - CRM Dash Check',
	},
	{
		key: 10,
		value: 'Recent Org',
		text: 'Recent Org Count - CRM Dash Check',
	},

	{
		key: 11,
		value: 'immobilized',
		text: 'Immobilized Count - CRM Dash Check',
	},

	{
		key: 12,
		value: 'mobilized',
		text: 'mobilized Count - CRM Dash Check',
	},
];

export const USER_DOCUMENT_TYPES_OPTIONS = [
	{
		key: 1,
		value: 'Driving License',
		text: 'Driving License',
	},
	{
		key: 2,
		value: 'Aadhaar',
		text: 'Aadhaar',
	},
	{
		key: 3,
		value: 'Pan Card',
		text: 'Pan Card',
	},
];

export const EMAIL_VERIFIED_OPTIONS = [
	{
		key: 1,
		value: 1,
		text: 'Verified',
	},
	{
		key: 2,
		value: 0,
		text: 'Unverified',
	},
];
export const IS_FMSVEHICLE_OPTIONS = [
	{
		key: 1,
		value: 1,
		text: 'True',
	},
	{
		key: 2,
		value: 0,
		text: 'False',
	},
];

export const PHONE_VERIFIED_OPTIONS = [
	{
		key: 1,
		value: 1,
		text: 'Verified',
	},
	{
		key: 2,
		value: 0,
		text: 'Unverified',
	},
];

export const RELAY_STATUS_OPTIONS_LOGS = [
	{
		key: '0',
		text: 'True',
		value: 1,
	},
	{
		key: '1',
		text: 'false',
		value: 0,
	},
];
