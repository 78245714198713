import styled from 'styled-components';

export const GraphScrollView = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-around;

	box-sizing: border-box;
	padding: 1em;

	overflow-y: auto;

	::-webkit-scrollbar {
		width: 0; /* Remove scrollbar space */
		background: transparent; /* Optional: just make scrollbar invisible */
	}
`;
