import React from 'react';

import CommonModal from '../../../molecules/modal/CommonModal';

import { Button, Checkbox, Dropdown, Form, Input } from 'semantic-ui-react';
import { Expander } from '../../../../styles/place-holders/Expander';
import { TimeInput } from 'semantic-ui-calendar-react';
import { RolesToggle } from '../../../molecules/toggle-switch/ToggleSwitchStyles';

const AddModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			header="Add Alert"
			scrolling
			content={
				<Form>
					<Form.Group widths="equal">
						<Form.Field required={true}>
							<label>Name</label>
							<Input fluid onBlur={props.onNameChange} />
						</Form.Field>
						<Form.Field control={Input} label="Description">
							<Input fluid placeholder="Optional" onBlur={props.onDescriptionChange} />
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field required={true}>
							<label>Organisation</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								selection
								clearable
								options={props.organisationOptions}
								onChange={props.onOrganisationChoose}
							/>
						</Form.Field>
						<Form.Field required={true}>
							<label>Users</label>
							<Dropdown
								fluid
								search
								selection
								multiple
								clearable
								disabled={props.selectedOrganisation === null}
								value={props.selectedUser}
								options={props.usersOptions}
								onChange={props.onUserChoose}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field required={true}>
							<label>Alert Type</label>
							<Dropdown
								fluid
								search
								selection
								multiple
								clearable
								value={props.selectedEventTypes}
								options={props.eventTypesOptions}
								onChange={props.onEventTypesChange}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field>
							<label>Active Days</label>
							<Dropdown
								fluid
								search
								selection
								multiple
								clearable
								value={props.selectedActiveDays}
								options={props.activeDaysOptions}
								onChange={props.onActiveDaysChange}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field>
							<label>Active Start Time</label>
							<TimeInput
								name="activeStartTimeFA"
								placeholder={props.activeStartTime}
								value={props.activeStartTime}
								iconPosition="left"
								onChange={props.handleActiveTimeChange}
								clearable
							/>
						</Form.Field>
						<Form.Field>
							<label>Active End Time</label>
							<TimeInput
								name="activeEndTimeFA"
								placeholder={props.activeEndTime}
								value={props.activeEndTime}
								iconPosition="left"
								onChange={props.handleActiveTimeChange}
								clearable
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field>
							<section style={{ width: '50%', display: 'flex', marginTop: '2em' }}>
								<label style={{ fontWeight: 'bold' }}>Enabled</label>
								<Expander />
								<RolesToggle>
									<Checkbox toggle onChange={(e, data) => props.onIsEnabledChange(data)} checked={props.isEnabled} />
								</RolesToggle>
							</section>
						</Form.Field>
					</Form.Group>
				</Form>
			}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button loading={props.addButtonLoading} positive disabled={props.disabled} onClick={props.onAddButtonClick}>
						Add
					</Button>
				</>
			}
		/>
	);
};

export default AddModal;
