export function createGeoFenceDTO(data) {
	let geoFences = [];

	if (data !== undefined && data.length !== 0) {
		data.forEach((geoFence) => {
			geoFences.push({
				id: geoFence.id,
				name: geoFence.name || null,
				latitude: parseFloat(geoFence.latitude) || null,
				longitude: parseFloat(geoFence.longitude) || null,
				radius: parseFloat(geoFence.radius) || null,
				address: geoFence.googleAddress || '',
				org: {
					id: geoFence?.organisation?.id ? geoFence.organisation.id : null,
					name: geoFence?.organisation?.name ? geoFence.organisation.name : null,
				},
			});
		});
	}

	return geoFences;
}
