import React from 'react';

import { Container } from './ToggleFormFieldStyles';

import { Checkbox } from 'semantic-ui-react';

function ToggleSwitch(props) {
	return (
		<Container style={props.style}>
			<label>{props.label}</label>

			<Checkbox toggle checked={props.checked} disabled={props.disabled} onChange={props.onChange} />
		</Container>
	);
}

export default ToggleSwitch;
