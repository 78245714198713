import { ORGANISATIONS_API, SAAS_CUSTOMERS_METRICS_API } from '../configs/api';
import { createOrganisationDTO } from '../dto/organisations';
import { createCustomersMetricsDTO } from '../dto/customersMetrics';
import Logo from '../../ui/assets/images/logo.png';
import { dispatch, dispatchNoPayload } from './middleware';
import { handleError } from './messaging';
import { exportToExcelWithLogo } from '../utilities/excel';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { CUSTOMER_METRICS_FILE_HEADER } from '../../data/constants/general';
import { getData } from './services/apiRequest';

export function fetchCustomers(params = '') {
	return getData(ORGANISATIONS_API, params);
	// axios(ORGANISATIONS_API + params, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// });
}

export function fetchSaasMetrics(params = '') {
	return getData(SAAS_CUSTOMERS_METRICS_API, params);
	// axios(SAAS_CUSTOMERS_METRICS_API + params, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// });
}

export function loadCustomersList(params = '') {
	dispatchNoPayload('CUSTOMERS_METRICS_LOADING');

	fetchCustomers(params)
		.then((response) => {
			const customers = createOrganisationDTO(response?.data?.data);

			// Store customers to store and stop loading.
			dispatch('CUSTOMERS_METRICS_FEED_CUSTOMERS_LIST', customers);

			if (customers.length) {
				// Fetch sass metrics of first customer.
				loadSaasMetrics(customers[0].id);
				// Set first customer in list as selected.
				dispatch('CUSTOMERS_METRICS_SET_SELECTED_CUSTOMERS', new Set().add(customers[0].id));
			}
		})
		.catch((error) => {
			dispatchNoPayload('CUSTOMERS_METRICS_LOADED');
			handleError(error);
		});
}

export function loadSaasMetrics(organisationIds, params = '') {
	// Fetching Data
	dispatchNoPayload('FETCHING_CUSTOMER_METRICS_DATA');

	fetchSaasMetrics(`?organisation=${organisationIds}${params}`)
		.then((response) => {
			const customerMetrics = createCustomersMetricsDTO(response?.data?.data);
			dispatch('CUSTOMERS_METRICS_SET_SELECTED_CUSTOMERS_METRICS', customerMetrics);
			dispatchNoPayload('FETCHED_CUSTOMER_METRICS_DATA');
		})
		.catch((error) => {
			handleError(error);
			dispatchNoPayload('FETCHED_CUSTOMER_METRICS_DATA');
		});
}

export function handleCardClick(customerID, initialDate, finalDate) {
	// Fetch sass metrics for single customer.
	loadSaasMetrics(customerID, `&startDate=${initialDate}&endDate=${finalDate}`);

	// Set single customer as selected.
	dispatch('CUSTOMERS_METRICS_SET_SELECTED_CUSTOMERS', new Set().add(customerID));
}

export function handleCheckboxClick(customerID, selectedCustomers, initialDate, finalDate) {
	let newSelectedCustomers = selectedCustomers;

	if (newSelectedCustomers.has(customerID)) {
		newSelectedCustomers.delete(customerID);
	} else {
		newSelectedCustomers.add(customerID);
	}

	// Fetch sass metrics for single customer.
	if (newSelectedCustomers.size) {
		loadSaasMetrics(Array.from(selectedCustomers), `&startDate=${initialDate}&endDate=${finalDate}`);
	} else {
		dispatch('CUSTOMERS_METRICS_SET_SELECTED_CUSTOMERS_METRICS', []);
	}

	// Set selected customers.
	setSelectedCustomers(newSelectedCustomers);
}

export function setSelectedCustomers(selectedCustomer) {
	dispatch('CUSTOMERS_METRICS_SET_SELECTED_CUSTOMERS', selectedCustomer);
}

// Graph Formatters
export function formatDataCustomerWise(CustomerData) {
	let formatedData = {};

	CustomerData.forEach((data) => {
		if (!formatedData[data.organisation.name]) {
			formatedData[data.organisation.name] = [
				{
					name: data.organisation.name,
					date: data.date,
					clv: data.clv,
					arpa: data.arpa,
					mrr: data.mrr,
					churnedMrr: data.churnedMrr,
					expansionMrr: data.expansionMrr,
					netMrr: data.netMrr,
					churnedVehicles: data.churnedVehicles,
					expansionVehicles: data.expansionVehicles,
					totalVehicles: data.totalVehicles,
					netPromoterScore: data.netPromoterScore,
				},
			];
		} else {
			formatedData[data.organisation.name].push({
				name: data.organisation.name,
				date: data.date,
				clv: data.clv,
				arpa: data.arpa,
				mrr: data.mrr,
				churnedMrr: data.churnedMrr,
				expansionMrr: data.expansionMrr,
				netMrr: data.netMrr,
				churnedVehicles: data.churnedVehicles,
				expansionVehicles: data.expansionVehicles,
				totalVehicles: data.totalVehicles,
				netPromoterScore: data.netPromoterScore,
			});
		}
	});

	return formatedData;
}

// Single Customers
export function formatNetMRRGraphData(data) {
	let graphData = [];

	data.forEach((metrics) => {
		graphData.push({
			month: metrics['date'],
			netMrr: metrics['netMrr'],
		});
	});

	return graphData;
}

export function formatNetMRRDistributionGraphData(data) {
	let graphData = [];

	data.forEach((metrics) => {
		graphData.push({
			month: metrics['date'],
			mrr: metrics['netMrr'] - metrics['expansionMrr'],
			churnedMrr: -metrics['churnedMrr'],
			expansionMrr: metrics['expansionMrr'],
		});
	});

	return graphData;
}

export function formatCLVGraphData(data) {
	let graphData = [];

	data.forEach((metrics) => {
		graphData.push({
			x: metrics['date'],
			y: metrics['clv'],
		});
	});

	return [{ id: 'clv', data: graphData }];
}

export function formatARPAGraphData(data) {
	let graphData = [];

	data.forEach((metrics) => {
		graphData.push({
			x: metrics['date'],
			y: metrics['arpa'],
		});
	});

	return [{ id: 'clv', data: graphData }];
}

export function formatTotalVehiclesGraphData(data) {
	let graphData = [];

	data.forEach((metrics) => {
		graphData.push({
			month: metrics['date'],
			totalVehicles: metrics['totalVehicles'],
		});
	});

	return graphData;
}

export function formatVehicleExpansionGraphData(data) {
	let graphData = [];

	data.forEach((metrics) => {
		graphData.push({
			month: metrics['date'],
			churnedVehicles: metrics['churnedVehicles'],
			expansionVehicles: metrics['expansionVehicles'],
		});
	});

	return graphData;
}

export function formatNetPromoterScoreGraphData(data) {
	let graphData = [];

	data.forEach((metrics) => {
		graphData.push({
			x: metrics['date'],
			y: metrics['netPromoterScore'],
		});
	});

	return [{ id: 'netPromoterScore', data: graphData }];
}

// Multiple Customers
export function formatMultipleCLVGraphData(data) {
	let formatedData = formatDataCustomerWise(data);
	let graphData = [];
	let customerData = [];

	Object.keys(formatedData).forEach((customer) => {
		formatedData[customer].forEach((metrics) => {
			customerData.push({
				x: metrics['date'],
				y: metrics['clv'],
			});
		});
		graphData.push({ id: customer, data: customerData });
		customerData = [];
	});

	return graphData;
}

export function formatMultipleARPAGraphData(data) {
	let formatedData = formatDataCustomerWise(data);
	let graphData = [];
	let customerData = [];

	Object.keys(formatedData).forEach((customer) => {
		formatedData[customer].forEach((metrics) => {
			customerData.push({
				x: metrics['date'],
				y: metrics['arpa'],
			});
		});
		graphData.push({ id: customer, data: customerData });
		customerData = [];
	});

	return graphData;
}

export function formatMultipleNetPromoterScoreGraphData(data) {
	let formatedData = formatDataCustomerWise(data);
	let graphData = [];
	let customerData = [];

	Object.keys(formatedData).forEach((customer) => {
		formatedData[customer].forEach((metrics) => {
			customerData.push({
				x: metrics['date'],
				y: metrics['netPromoterScore'],
			});
		});
		graphData.push({ id: customer, data: customerData });
		customerData = [];
	});

	return graphData;
}

export function formatMultipleTotalVehicleGraphData(data) {
	let formatedData = formatDataCustomerWise(data);
	let graphData = [];
	let customerData = [];

	Object.keys(formatedData).forEach((customer) => {
		formatedData[customer].forEach((metrics) => {
			customerData.push({
				x: metrics['date'],
				y: metrics['totalVehicles'],
			});
		});
		graphData.push({ id: customer, data: customerData });
		customerData = [];
	});

	return graphData;
}

export function formatMultipleVehicleExpansionGraphData(data) {
	let formatedData = formatDataCustomerWise(data);
	let graphData = [];
	let customerData = [];

	Object.keys(formatedData).forEach((customer) => {
		formatedData[customer].forEach((metrics) => {
			customerData.push({
				x: metrics['date'],
				y: metrics['expansionVehicles'],
			});
		});
		graphData.push({ id: customer, data: customerData });
		customerData = [];
	});

	return graphData;
}

export function formatMultipleVehicleChurnedGraphData(data) {
	let formatedData = formatDataCustomerWise(data);
	let graphData = [];
	let customerData = [];

	Object.keys(formatedData).forEach((customer) => {
		formatedData[customer].forEach((metrics) => {
			customerData.push({
				x: metrics['date'],
				y: metrics['churnedVehicles'],
			});
		});
		graphData.push({ id: customer, data: customerData });
		customerData = [];
	});

	return graphData;
}

export function formatMultipleNetMRRGraphData(data) {
	let formatedData = formatDataCustomerWise(data);
	let graphData = [];
	let customerData = [];

	Object.keys(formatedData).forEach((customer) => {
		formatedData[customer].forEach((metrics) => {
			customerData.push({
				x: metrics['date'],
				y: metrics['netMrr'],
			});
		});
		graphData.push({ id: customer, data: customerData });
		customerData = [];
	});

	return graphData;
}

export function formatMultipleChurnedMRRGraphData(data) {
	let formatedData = formatDataCustomerWise(data);
	let graphData = [];
	let customerData = [];

	Object.keys(formatedData).forEach((customer) => {
		formatedData[customer].forEach((metrics) => {
			customerData.push({
				x: metrics['date'],
				y: metrics['churnedMrr'],
			});
		});
		graphData.push({ id: customer, data: customerData });
		customerData = [];
	});

	return graphData;
}

export function formatMultipleExpansionMRRGraphData(data) {
	let formatedData = formatDataCustomerWise(data);
	let graphData = [];
	let customerData = [];

	Object.keys(formatedData).forEach((customer) => {
		formatedData[customer].forEach((metrics) => {
			customerData.push({
				x: metrics['date'],
				y: metrics['expansionMrr'],
			});
		});
		graphData.push({ id: customer, data: customerData });
		customerData = [];
	});

	return graphData;
}

export function search(e, customers, customersBackup) {
	if (e.which === 13 /* Enter */) {
		if (e.target.value === '') {
			dispatch('CUSTOMERS_METRICS_RESET_CUSTOMERS_LIST', customersBackup);
		} else {
			const searchQuery = e.target.value;
			const filteredCustomers = [];
			customersBackup.forEach((customer) => {
				if (customer.name.toLowerCase().includes(searchQuery.toLowerCase())) {
					filteredCustomers.push(customer);
				}
			});

			dispatch('CUSTOMERS_METRICS_SET_CUSTOMERS_LIST', filteredCustomers);
		}
	}
}

// Export
export function openExportModal() {
	dispatchNoPayload('CUSTOMERS_METRICS_EXPORT_MODAL_OPEN');
}

export function closeExportModal() {
	dispatchNoPayload('CUSTOMERS_METRICS_EXPORT_MODAL_CLOSE');
	dispatch('SET_CUSTOMER_METRICS_EXPORT_DATA', []);
}

export function formatExcelExport(response) {
	return response.map((metrics) => {
		return {
			Name: metrics.organisation.name,
			Month: metrics.date,
			CLV: metrics.clv,
			ARPA: metrics.arpa,
			MRR: metrics.mrr,
			'Churned MRR': metrics.churnedMrr,
			'Expansion MRR': metrics.expansionMrr,
			'Net MRR': metrics.netMrr,
			'Churned Vehicles': metrics.churnedVehicles,
			'Expansion Vehicles': metrics.expansionVehicles,
			'Net Promoter Score': metrics.netPromoterScore,
		};
	});
}

export function exportData(data, fileName, extension) {
	let formatExcelData = formatExcelExport(data);
	let formatedData = {};

	formatExcelData.forEach((data) => {
		if (!formatedData[data.Name]) {
			formatedData[data.Name] = [
				{
					Name: data.Name,
					Month: data.Month,
					CLV: data.CLV,
					ARPA: data.ARPA,
					MRR: data.MRR,
					'Churned MRR': data['Churned MRR'],
					'Expansion MRR': data['Expansion MRR'],
					'Net MRR': data['Net MRR'],
					'Churned Vehicles': data['Churned Vehicles'],
					'Expansion Vehicles': data['Expansion Vehicles'],
					'Net Promoter Score': data['Net Promoter Score'],
				},
			];
		} else {
			formatedData[data.Name].push({
				Name: data.Name,
				Month: data.Month,
				CLV: data.CLV,
				ARPA: data.ARPA,
				MRR: data.MRR,
				'Churned MRR': data['Churned MRR'],
				'Expansion MRR': data['Expansion MRR'],
				'Net MRR': data['Net MRR'],
				'Churned Vehicles': data['Churned Vehicles'],
				'Expansion Vehicles': data['Expansion Vehicles'],
				'Net Promoter Score': data['Net Promoter Score'],
			});
		}
	});

	let formatedExportData = [];

	Object.keys(formatedData).forEach((customer, index) => {
		formatedData[customer].forEach((data) => {
			formatedExportData.push(data);
		});
		if (index !== Object.keys(formatedData).length - 1)
			formatedExportData.push(Array(formatedData[customer].length).fill(''));
	});

	if (extension === 'excel') {
		exportToExcelWithLogo(formatedExportData, `${!fileName ? 'Metrics' : fileName}`, CUSTOMER_METRICS_FILE_HEADER);
	} else {
		convertToPdf(formatedExportData, fileName);
	}

	closeExportModal();
}

export function exportAll(param = '') {
	dispatchNoPayload('EXPORTING_CUSTOMER_METRICS_EXPORT_DATA');
	fetchSaasMetrics(param)
		.then((response) => {
			openExportModal();
			dispatch('SET_CUSTOMER_METRICS_EXPORT_DATA', createCustomersMetricsDTO(response?.data?.data));
			dispatchNoPayload('EXPORTED_CUSTOMER_METRICS_EXPORT_DATA');
		})
		.catch((error) => {
			handleError(error);
			dispatchNoPayload('EXPORTED_CUSTOMER_METRICS_EXPORT_DATA');
		});
}

export function convertToPdf(data, fileName) {
	const doc = new jsPDF({ orientation: 'l', unit: 'mm', format: 'a4' });

	let col = [
		'Name',
		'Month',
		'CLV',
		'ARPA',
		'MRR',
		'Churned MRR',
		'Expansion MRR',
		'Net MRR',
		'Churned Vehicles',
		'Expansion Vehicles',
		'Net Promoter Score',
		// 'Refueling Volume',
		// 'Draining Volume',
	];
	let rows = [];

	data.forEach((metrics) => {
		let temp = [];
		Object.keys(metrics).forEach((key, index) => {
			if (col[index] === key) temp[index] = metrics[key];
		});

		rows.push(temp);
		// rows.push(Array.from({length: col.length}).map((value) => ''));
	});

	let header = function (data) {
		doc.addImage(Logo, 'png', data.settings.margin.left, 10, 40, 14);
	};

	doc.autoTable(col, rows, {
		margin: { top: 25 },
		beforePageContent: header,
		theme: 'grid',
	});

	doc.save(`${!fileName ? 'Metrics' : fileName}`);
}
