import { isoToDate } from '../utilities/date';

export function createUsersDTO(data) {
	if (data && data.length !== 0) {
		return data.map((user) => {
			return {
				id: user?.id ? user.id : null,
				name: user?.name ? user.name : null,
				type: user?.type ? user.type : null,
				phone: user?.phone ? user.phone : null,
				deletedDate: user?.deletedDate ? isoToDate(user.deletedDate) : null,
				phoneVerified: user?.phoneVerified ? user.phoneVerified : null,
				isEnabledSMS: user.isEnabledSMS,
				email: user?.email ? user.email : null,
				emailVerified: user?.emailVerified ? user.emailVerified : null,
				isEnabledEmail: user.isEnabledEmail,
				isEnabledPush: user.isEnabledPush,
				role: user?.role ? user.role : null,
				organisation: {
					id: user?.organisation?.id ? user.organisation.id : null,
					name: user?.organisation?.name ? user.organisation.name : null,
				},
				isLoginEnabled: user.isLoginEnabled,
				vehicleGroups:
					user?.vehicleGroups && user.vehicleGroups.length !== 0
						? user.vehicleGroups.map((group) => {
								return {
									id: group.id,
									name: group.name,
								};
						  })
						: [],
				schoolBus: {
					id: user?.schoolBus?.id ? user.schoolBus.id : null,
					registrationNumber: user?.schoolBus?.registrationNumber ? user.schoolBus.registrationNumber : null,
				},
				pickupDropPoint: {
					id: user?.pickupDropPoint?.id ? user.pickupDropPoint.id : null,
					name: user?.pickupDropPoint?.name ? user.pickupDropPoint.name : null,
				},
				vehicle: user?.driverVehicle
					? {
							id: user?.driverVehicle?.id ? user.driverVehicle.id : null,
							registrationNumber: user?.driverVehicle?.registrationNumber
								? user.driverVehicle.registrationNumber
								: null,
					  }
					: null,
			};
		});
	} else return [];
}
