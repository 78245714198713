import React from 'react';

import { Container, ImageContainer } from './SidebarHeaderStyles';
import { Expander } from '../../../styles/place-holders/Expander';

function SidebarHeader(props) {
	const { expanded } = props;

	return (
		<Container>
			<ImageContainer>
				<img src={props.imageSource} alt={props.alt} />
			</ImageContainer>
			{expanded && (
				<>
					<span>{props.name}</span>
					<Expander />
				</>
			)}
		</Container>
	);
}

export default SidebarHeader;
