import React from "react";

import CommonModal from "../../../molecules/modal/CommonModal";

import { TableContainer } from "../../../../styles/Accordion";

import { Button, Checkbox, Form, FormField, Input, Loader, Segment, Table } from "semantic-ui-react";
// import styled from 'styled-components';
import { RolesToggle } from "../../../molecules/toggle-switch/ToggleSwitchStyles";

// import { Expander } from '../../../../styles/place-holders/Expander';

const AddModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			scrolling={true}
			header="Add Role"
			content={
				<Form>
					<Form.Group widths="equal">
						<Form.Field required>
							<label>Name</label>
							<Input fluid onChange={props.onNameFAChange} />
						</Form.Field>
						<Form.Field control={Input} label="Description">
							<Input fluid onChange={props.onDescriptionFAChange} />
						</Form.Field>
						{/*<Form.Field>*/}
						{/*	<section style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: '2em' }}>*/}
						{/*		<label style={{ fontWeight: 'bold' }}>Super Admin</label>*/}
						{/*		<Expander />*/}
						{/*		<Checkbox*/}
						{/*			toggle*/}
						{/*			onChange={(e, data) => props.onIsSuperAdminChangeFA(data)}*/}
						{/*			checked={props.isSuperAdmin}*/}
						{/*		/>*/}
						{/*	</section>*/}
						{/*</Form.Field>*/}
					</Form.Group>
					{/*<Form.Group widths="equal">*/}
					{/*  <Form.Field>*/}
					{/*    <label>Organisation</label>*/}
					{/*    <Dropdown*/}
					{/*      placeholder="Choose ..."*/}
					{/*      fluid*/}
					{/*      search*/}
					{/*      selection*/}
					{/*      options={props.organisationsOptions}*/}
					{/*      onChange={props.onOrgChange}*/}
					{/*    />*/}
					{/*  </Form.Field>*/}
					{/*</Form.Group>*/}
					{props.permissionsLoadingFA ? (
						<Segment style={{ border: 'none', boxShadow: 'none' }}>
							<Loader inverted />
						</Segment>
					) : (
						props.permissionOptions?.length !== 0 && (
							<TableContainer>
								<FormField required>
									<label>Permissions</label>
								</FormField>
								<Table celled striped compact selectable>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>Name</Table.HeaderCell>
											<Table.HeaderCell>Manage</Table.HeaderCell>
											<Table.HeaderCell>Read</Table.HeaderCell>
											<Table.HeaderCell>Write</Table.HeaderCell>
											<Table.HeaderCell>Update</Table.HeaderCell>
											<Table.HeaderCell>Delete</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{Object.keys(props.permissionOptions).map((permissionKey, index) => {
											return (
												<Table.Row key={index}>
													<Table.Cell>{props.permissionOptions[permissionKey]}</Table.Cell>
													<Table.Cell collapsing>
														{props.permissionOptions[permissionKey] !== 'Configurations' &&
															props.permissionOptions[permissionKey] !== 'Report' &&
															props.permissionOptions[permissionKey] !== 'Diagnostics' && (
																<RolesToggle>
																	<Checkbox
																		toggle
																		checked={props.permissionsFA?.[permissionKey]?.manage}
																		onChange={(e, { checked }) =>
																			props.onPermissionsFAChange(permissionKey, 'manage', checked)
																		}
																	/>
																</RolesToggle>
															)}
													</Table.Cell>
													<Table.Cell collapsing>
														{props.permissionOptions[permissionKey] !== 'Configurations' && (
															<RolesToggle>
																<Checkbox
																	toggle
																	checked={props.permissionsFA?.[permissionKey]?.read}
																	disabled={props.permissionsFA?.[permissionKey]?.manage}
																	onChange={(e, { checked }) =>
																		props.onPermissionsFAChange(permissionKey, 'read', checked)
																	}
																/>
															</RolesToggle>
														)}
													</Table.Cell>
													<Table.Cell collapsing>
														{props.permissionOptions[permissionKey] !== 'Report' &&
															props.permissionOptions[permissionKey] !== 'Diagnostics' && (
																<RolesToggle>
																	<Checkbox
																		toggle
																		checked={props.permissionsFA?.[permissionKey]?.write}
																		disabled={props.permissionsFA?.[permissionKey]?.manage}
																		onChange={(e, { checked }) =>
																			props.onPermissionsFAChange(permissionKey, 'write', checked)
																		}
																	/>
																</RolesToggle>
															)}
													</Table.Cell>

													<Table.Cell collapsing>
														{props.permissionOptions[permissionKey] !== 'Configurations' &&
															props.permissionOptions[permissionKey] !== 'Report' &&
															props.permissionOptions[permissionKey] !== 'Diagnostics' && (
																<RolesToggle>
																	<Checkbox
																		toggle
																		checked={props.permissionsFA?.[permissionKey]?.update}
																		disabled={props.permissionsFA?.[permissionKey]?.manage}
																		onChange={(e, { checked }) =>
																			props.onPermissionsFAChange(permissionKey, 'update', checked)
																		}
																	/>
																</RolesToggle>
															)}
													</Table.Cell>
													<Table.Cell collapsing>
														{props.permissionOptions[permissionKey] !== 'Configurations' &&
															props.permissionOptions[permissionKey] !== 'Report' &&
															props.permissionOptions[permissionKey] !== 'Diagnostics' && (
																<RolesToggle>
																	<Checkbox
																		toggle
																		checked={props.permissionsFA?.[permissionKey]?.erase}
																		disabled={props.permissionsFA?.[permissionKey]?.manage}
																		onChange={(e, { checked }) =>
																			props.onPermissionsFAChange(permissionKey, 'erase', checked)
																		}
																	/>
																</RolesToggle>
															)}
													</Table.Cell>
												</Table.Row>
											);
										})}
									</Table.Body>
								</Table>
							</TableContainer>
						)
					)}
				</Form>
			}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button positive loading={props.addButtonLoading} disabled={props.disabled} onClick={props.onAddButtonClick}>
						Add
					</Button>
				</>
			}
		/>
	);
};

export default AddModal;
