import styled from 'styled-components';

export const Container = styled.section`
	//width: 100%;
	//height: 100%;

	height: 95vh; /* Set a fixed height, adjust as needed */
	overflow-y: auto;

	background: #fafcfe;
`;

export const GraphsWrapper = styled.section`
	//height:100% ;

	display: flex;
	align-content: flex-start;
	justify-content: space-evenly;
	flex-wrap: wrap;

	background: #fafcfe;
	overflow-y: auto;
`;

export const GraphHeader = styled.section`
	width: 100%;
	box-sizing: border-box;
	padding: 1em 2em;

	h1 {
		font-size: 1.25em;
		margin: 0;
	}
`;
