import React from 'react';

import CommonModal from '../../../molecules/modal/CommonModal';
import { Expander } from '../../../../styles/place-holders/Expander';

import { Button, Dropdown, Form } from 'semantic-ui-react';
import styled from 'styled-components';

const FilterModal = (props) => {
	const isSuperAdminAccess = localStorage.getItem('isSuperAdmin');

	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="tiny"
			scrolling
			header="Alerts Filters"
			content={
				<Form>
					{isSuperAdminAccess === 'true' && (
						<Form.Group widths="equal">
							<Form.Field>
								<label>Organisations</label>
								<Dropdown
									placeholder="Choose..."
									fluid
									search
									multiple
									selection
									// clearable
									value={props.organisations}
									options={props.organisationsOptions}
									onChange={props.onOrganisationsFilterChange}
								/>
							</Form.Field>
						</Form.Group>
					)}

					<Form.Group widths="equal">
						<Form.Field>
							<label>Users</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								multiple
								selection
								clearable
								value={props.users}
								options={props.usersOptions}
								onChange={props.onUsersFilterChange}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field>
							<label>Alert Type</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								multiple
								selection
								clearable
								value={props.eventTypes}
								options={props.eventTypesOptions}
								onChange={props.onEventTypesFilterChange}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field>
							<label>Active Days</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								multiple
								selection
								clearable
								value={props.activeDays}
								options={props.activeDaysOptions}
								onChange={props.onActiveDaysFilterChange}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field>
							<label>Is Enabled</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								selection
								clearable
								value={props.isEnabled}
								options={props.isEnabledOptions}
								onChange={props.onIsEnabledFilterChange}
							/>
						</Form.Field>
					</Form.Group>
				</Form>
			}
			actions={
				<>
					<FlexContainer>
						<Button onClick={props.onResetClick}>Reset</Button>
						<Expander />
						<Button color="black" onClick={props.onCancelClick}>
							Cancel
						</Button>
						<Button primary disabled={props.disabled} onClick={props.onApplyClick}>
							Apply
						</Button>
					</FlexContainer>
				</>
			}
		/>
	);
};

const FlexContainer = styled.section`
	display: flex;
`;

export default FilterModal;
