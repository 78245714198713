import mixpanel from 'mixpanel-browser';
import { handleError } from '../middleware/messaging';

export const initializeMixpanel = () => {
	// Do nothing if development environment.
	if (!['staging', 'production'].includes(process.env.REACT_APP_BUILD_ENVIRONMENT)) return;

	mixpanel.init('877fe0f326f44e6d311a232cd1ce59ef', { debug: true });
	mixpanel.register_once({
		name: localStorage.getItem('name'),
		organisation: localStorage.getItem('organisationName'),
	});
	mixpanel.identify(localStorage.getItem('userId'));
};

export const resetMixpanel = () => {
	// Do nothing if development environment.
	if (!['staging', 'production'].includes(process.env.REACT_APP_BUILD_ENVIRONMENT)) return;

	mixpanel.reset();
};

export const trackMixpanelEvent = (eventName, obj = {}) => {
	// Do nothing if development environment.
	if (!['staging', 'production'].includes(process.env.REACT_APP_BUILD_ENVIRONMENT)) return;
	try {
		mixpanel.track(eventName, {
			...obj,
		});
	} catch (err) {
		handleError('Some issue with mixPanel Occured');
		handleError(err);
	}
};
