import React from 'react';
import CommonModal from '../../../molecules/modal/CommonModal';
import ObdToggle from '../../../molecules/obd-toggle/obdToggle';
import { Button, Dropdown, Form, Input } from 'semantic-ui-react';

const containerStyle = {
	position: 'relative',
	display: 'inline-block',
};

const AddModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			header="Add Device Model"
			content={
				<Form>
					<Form.Group widths="equal">
						<Form.Field control={Input} required label="Manufacturer">
							<Input fluid onChange={props.onManufacturerChange} />
						</Form.Field>
						<Form.Field required control={Input} label="Model">
							<Input fluid onChange={props.onModelChange} />
						</Form.Field>
						<Form.Field required>
							<label>Protocols</label>
							<div style={containerStyle}>
								<Dropdown
									selection
									placeholder="Optional"
									value={props.selectedProtocol}
									options={props.protocols}
									onChange={props.onProtocolChoose}
									clearable
								/>
							</div>
						</Form.Field>
						<Form.Field required style={{ alignSelf: 'end' }}>
							<ObdToggle label={'OBD'} onChange={props.onOBDChange}></ObdToggle>
						</Form.Field>
					</Form.Group>
				</Form>
			}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button
						positive
						disabled={
							props.manufacturerFA == null ||
							props.manufacturerFA === '' ||
							props.modelFA == null ||
							props.modelFA === ''
						}
						loading={props.addButtonLoading}
						onClick={props.onAddButtonClick}>
						Add
					</Button>
				</>
			}
		/>
	);
};

export default AddModal;
