export default function peopleOrganisations(
	initialState = {
		peopleOrganisations: [],
		loading: true,
		pageNumber: 1,
		count: 0,
		activeIndex: -1,
		activeSort: '',
		sortOrder: '',
		deleting: false,
		deleteModalOpen: false,
		adding: false,
		addModalOpen: false,
		restoreModal: false,
		restoring: false,
		deactivateModal: false,
		deactivate: false,
		saasPlansModal: false,

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
	},
	action
) {
	switch (action.type) {
		case 'FEED_PEOPLE_ORGANISATIONS':
			return {
				...initialState,
				peopleOrganisations: action.payload,
				loading: false,
			};

		case 'PEOPLE_ORGANISATIONS_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'PEOPLE_ORGANISATIONS_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'PEOPLE_ORGANISATIONS_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'PEOPLE_ORGANISATIONS_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'PEOPLE_ORGANISATIONS_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'PEOPLE_ORGANISATIONS_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'PEOPLE_ORGANISATIONS_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'PEOPLE_ORGANISATIONS_DELETING':
			return {
				...initialState,
				deleting: true,
			};

		case 'PEOPLE_ORGANISATIONS_DELETED':
			return {
				...initialState,
				deleting: false,
			};

		case 'PEOPLE_ORGANISATIONS_DELETE_MODAL_OPEN':
			return {
				...initialState,
				deleteModalOpen: true,
			};

		case 'PEOPLE_ORGANISATIONS_DELETE_MODAL_CLOSE':
			return {
				...initialState,
				deleteModalOpen: false,
			};

		case 'PEOPLE_ORGANISATIONS_ADDING':
			return {
				...initialState,
				adding: true,
			};

		case 'PEOPLE_ORGANISATIONS_ADDED':
			return {
				...initialState,
				adding: false,
			};

		case 'PEOPLE_ORGANISATIONS_ADD_MODAL_OPEN':
			return {
				...initialState,
				addModalOpen: true,
			};

		case 'PEOPLE_ORGANISATIONS_ADD_MODAL_CLOSE':
			return {
				...initialState,
				addModalOpen: false,
			};

		case 'PEOPLE_ORGANISATIONS_UPDATING':
			return {
				...initialState,
				organisationsUpdating: true,
			};

		case 'PEOPLE_ORGANISATIONS_UPDATED':
			return {
				...initialState,
				organisationsUpdating: false,
			};

		case 'PEOPLE_ORGANISATIONS_EDIT_MODAL_OPEN':
			return {
				...initialState,
				organisationsEditMode: true,
			};

		case 'PEOPLE_ORGANISATIONS_EDIT_MODAL_CLOSE':
			return {
				...initialState,
				organisationsEditMode: false,
			};

		case 'PEOPLE_ORGANISATIONS_RESTORE_MODAL_OPEN':
			return {
				...initialState,
				restoreModal: true,
			};

		case 'PEOPLE_ORGANISATIONS_RESTORE_MODAL_CLOSE':
			return {
				...initialState,
				restoreModal: false,
			};

		case 'PEOPLE_ORGANISATIONS_RESTORING':
			return {
				...initialState,
				restoring: true,
			};

		case 'PEOPLE_ORGANISATIONS_RESTORED':
			return {
				...initialState,
				restoring: false,
			};

		case 'PEOPLE_ORGANISATIONS_DEACTIVATE_MODAL_OPEN':
			return {
				...initialState,
				deactivateModal: true,
			};

		case 'PEOPLE_ORGANISATIONS_DEACTIVATE_MODAL_CLOSE':
			return {
				...initialState,
				deactivateModal: false,
			};

		case 'PEOPLE_ORGANISATIONS_DEACTIVATED':
			return {
				...initialState,
				deactivate: false,
			};

		case 'PEOPLE_ORGANISATIONS_DEACTIVATE':
			return {
				...initialState,
				deactivate: true,
			};

		case 'PEOPLE_ORGANISATIONS_SAAS_PLANS_MODAL_OPEN':
			return {
				...initialState,
				saasPlansModal: true,
			};

		case 'PEOPLE_ORGANISATIONS_SAAS_PLANS_MODAL_CLOSE':
			return {
				...initialState,
				saasPlansModal: false,
			};

		case 'PEOPLE_ORGANISATIONS_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'PEOPLE_ORGANISATIONS_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_PEOPLE_ORGANISATIONS_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_PEOPLE_ORGANISATIONS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_PEOPLE_ORGANISATIONS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'PEOPLE_ORGANISATIONS_UNMOUNT':
			return {
				...initialState,
				peopleOrganisations: [],
				activeSort: '',
				sortOrder: '',
				activeIndex: -1,
				pageNumber: 1,
			};

		default:
			return initialState;
	}
}
