import React from 'react';
import CommonModal from '../../../molecules/modal/CommonModal';
import { Button } from 'semantic-ui-react';

const RestoreModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="mini"
			header="Restore Vehicle Group"
			content={<p>Do you want to restore vehicle group ?</p>}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button primary loading={props.restoreButtonLoading} onClick={props.onRestoreButtonClick}>
						Restore
					</Button>
				</>
			}
		/>
	);
};

export default RestoreModal;
