import { isoToDate } from '../utilities/date';

export function createAlertsDTO(data) {
	if (data && data.length !== 0) {
		let formattedData = data.map((alert) => {
			return {
				id: alert?.id ? alert.id : null,
				name: alert?.name ? alert.name : null,
				description: alert?.description ? alert.description : null,
				type: alert.type ? alert.type : [],
				deletedDate: alert?.deletedDate ? isoToDate(alert.deletedDate) : null,
				activeDays: alert.activeDays ? alert.activeDays : [],
				activeStartTime: alert?.activeStartTime ? alert.activeStartTime.slice(0, 5) : null,
				activeEndTime: alert?.activeEndTime ? alert.activeEndTime.slice(0, 5) : null,
				isEnabled: alert.isEnabled !== null && alert.isEnabled !== undefined ? alert.isEnabled : null,
				organisation: {
					id: alert?.organisation?.id ? alert.organisation.id : null,
					name: alert?.organisation?.name ? alert.organisation.name : null,
				},
				users:
					alert?.users && alert.users.length !== 0
						? alert.users.map((user) => {
								return {
									id: user.id,
									name: user.name,
								};
						  })
						: [],
			};
		});

		formattedData.forEach((alert) => {
			if (alert.activeDays.length === 1 && alert.activeDays.indexOf('All Days') !== 1) {
				alert.activeDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
			}
		});
		return formattedData;
	} else return [];
}
