import React, { useState } from 'react';

import CommonModal from '../../../molecules/modal/CommonModal';

import { Button, Dropdown, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { Expander } from '../../../../styles/place-holders/Expander';
import { dispatch } from '../../../../../../logic/middleware/middleware';
import { connect } from 'react-redux';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment/moment';

const FilterModal = (props) => {
	let isSuperAdmin = localStorage.getItem('isSuperAdmin') === 'true';

	const { organisation = [], vehicle = [], vehicleGroup = [], startDate = null, endDate = null } = props.activeFilters;

	const [filterValues, setFilterValues] = useState({
		organisation: organisation,
		vehicle: vehicle,
		vehicleGroup: vehicleGroup,
		startDate: startDate,
		endDate: endDate,
	});

	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			header="Select the Filters"
			size="tiny"
			scrolling={true}
			content={
				<Form>
					<Form.Group widths="equal">
						{/*{isSuperAdmin && (*/}
							<Form.Field>
								<label>Organisation</label>
								<Dropdown
									placeholder="Choose ..."
									name="organisation"
									fluid
									multiple
									search
									clearable
									selection
									disabled={!isSuperAdmin}
									// value={filterValues.organisation}
									value={isSuperAdmin ? filterValues.organisation : props?.organisationDropdown[0]}
									options={props.organisationDropdown}
									onChange={(_event, data) => {
										props.handleOrgChange(data);
										setFilterValues({ ...filterValues, organisation: data.value });
										dispatch('ACTIVE_FILTER', { ...props.filters, organisations: data.value });
									}}
								/>
							</Form.Field>
						{/*)}*/}
					</Form.Group>

					<Form.Group widths="equal">
						<Form.Field>
							<label>Vehicle No.</label>
							<Dropdown
								placeholder="Choose ..."
								name="vehicle"
								fluid
								search
								selection
								multiple
								clearable
								value={filterValues.vehicle}
								options={props.vehicleOptions}
								onClick={!isSuperAdmin ? () => {
									let data = { value: [props.organisationDropdown[0].value] };
									props.handleOrgChange(data);
									setFilterValues({ ...filterValues, organisation: data.value });
									dispatch('ACTIVE_FILTER', { ...props.filters, organisations: data.value });
								} : undefined}
								onChange={(event, data) => setFilterValues({ ...filterValues, vehicle: data.value })}
							/>
						</Form.Field>
					</Form.Group>

					<Form.Group widths="equal">
						<Form.Field>
							<label>Vehicle Group</label>
							<Dropdown
								placeholder="Choose ..."
								name="vehicle"
								fluid
								search
								selection
								multiple
								clearable
								value={filterValues.vehicleGroup}
								options={props.vehicleGroupDropdown}
								onChange={(event, data) => {
									// props.handleVGChange(event, data);
									setFilterValues({ ...filterValues, vehicleGroup: data.value });
								}}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field>
							<label>Start Date</label>
							<DateInput
								name="startDate"
								placeholder="Start Date"
								value={filterValues.startDate}
								dateFormat="DD-MM-YYYY"
								maxDate={moment()}
								icon="calendar alternate outline"
								iconPosition="left"
								closable
								clearable
								onClear={() => {
									setFilterValues({ ...filterValues, startDate: null, endDate: null });
								}}
								onChange={(event, data) => {
									setFilterValues({ ...filterValues, startDate: data.value });
								}}
							/>
						</Form.Field>
						<Form.Field>
							<label>End Date</label>
							<DateInput
								name="endDate"
								placeholder="End Date"
								value={filterValues.endDate}
								disabled={!filterValues.startDate}
								dateFormat="DD-MM-YYYY"
								maxDate={moment()}
								minDate={props.startDate}
								icon="calendar alternate outline"
								iconPosition="left"
								closable
								clearable
								onChange={(event, data) => {
									setFilterValues({ ...filterValues, endDate: data.value });
								}}
							/>
						</Form.Field>
					</Form.Group>
				</Form>
			}
			actions={
				<>
					<FlexContainer>
						{Object.keys(props.activeFilters).length > 0 && (
							<Button
								onClick={() => {
									props.onResetClick();
									setFilterValues({
										organisation: [],
										vehicle: [],
										vehicleGroup: [],
										startDate: null,
										endDate: null,
									});
									dispatch('ACTIVE_FILTER', { ...props.filters, organisations: [] });
								}}>
								Reset
							</Button>
						)}

						<Expander />
						<Button color="black" onClick={props.onCancel}>
							Cancel
						</Button>
						<Button
							positive
							disabled={
								!filterValues.organisation.length &&
								!filterValues.vehicle.length &&
								!filterValues.vehicleGroup.length &&
								(!filterValues?.startDate || !filterValues?.endDate)
							}
							onClick={() => {
								props.handleOnApply(filterValues);
							}}>
							Apply
						</Button>
					</FlexContainer>
				</>
			}></CommonModal>
	);
};

const mapStateToProps = (state) => ({
	filters: state.vehicles.filters,
});
export default connect(mapStateToProps)(FilterModal);

const FlexContainer = styled.section`
	display: flex;
`;
