import React from "react";
import CommonModal from "../../../molecules/modal/CommonModal";
import { Button, Checkbox, Dropdown, Form, Input } from "semantic-ui-react";
// import { DateInput } from 'semantic-ui-calendar-react';
import { validateNumericValue } from "../../../../../../logic/utilities/validator";
import { Expander } from "../../../../styles/place-holders/Expander";
import { RolesToggle } from "../../../molecules/toggle-switch/ToggleSwitchStyles";
// import moment from 'moment/moment';

// const maxDate = moment().format('DD-MM-YY');

const VehicleBulkUpdateModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="medium"
			header="Bulk Update"
			scrolling
			content={
				<Form>
					<Form.Group widths="equal">
						{/*<Form.Field>*/}
						{/*	<label>Installation Date</label>*/}
						{/*	<DateInput*/}
						{/*		placeholder="Select"*/}
						{/*		fluid*/}
						{/*		maxDate={maxDate}*/}
						{/*		closable*/}
						{/*		value={props.installationDate}*/}
						{/*		onChange={props.onInstallationDateChange}*/}
						{/*	/>*/}
						{/*</Form.Field>*/}

						<Form.Field
							control={Input}
							label="Odometer Reading (KM)"
							error={
								!validateNumericValue(props.odometerFA) && {
									content: 'Odometer Reading should be only digits.',
								}
							}>
							<Input type="number" fluid placeholder="Optional" onBlur={props.onOdometerChange} />
						</Form.Field>

						<Form.Field>
							<label>Vehicle Type</label>
							<Dropdown
								fluid
								search
								selection
								clearable
								options={props.vehicleTypeOptions}
								value={props.vehicleTypeBulk}
								onChange={props.onVehicleTypeChoose}
							/>
						</Form.Field>
						<Form.Field>
							<label>Vehicle Status</label>
							<Dropdown
								fluid
								search
								selection
								clearable
								options={props.vehicleStatusOptions}
								value={props.vehicleStatusBulk}
								onChange={props.onVehicleStatusChoose}
							/>
						</Form.Field>

						<Form.Field>
							<label>Expected Mileage</label>
							<Input
								label={'km/l'}
								labelPosition="right"
								type="number"
								fluid
								placeholder="Optional"
								onBlur={props.onExpectedMileageChange}
							/>
						</Form.Field>
					</Form.Group>

					<Form.Group widths="equal">
						<Form.Field>
							<label>Speed Limit</label>
							<Input
								label={'km/hr'}
								labelPosition="right"
								type="number"
								fluid
								placeholder="Optional"
								onBlur={props.onSpeedLimitChange}
							/>
						</Form.Field>

						<Form.Field>
							<label>Idling Duration</label>
							<Input type="number" fluid placeholder="Optional" onBlur={props.onIdlingDuration} />
						</Form.Field>

						<Form.Field>
							<label>Parking Duration</label>
							<Input type="number" fluid placeholder="Optional" onBlur={props.onParkingDuration} />
						</Form.Field>
					</Form.Group>

					<Form.Group widths="equal">
						<Form.Field>
							<label>Make</label>
							<Input fluid placeholder="Optional" onBlur={props.onMakeChange} />
						</Form.Field>

						<Form.Field>
							<label>Model</label>
							<Input fluid placeholder="Optional" onBlur={props.onModelChange} />
						</Form.Field>

						<Form.Field>
							<label>Note</label>
							<Input fluid placeholder="Optional" onBlur={props.onNoteChange} />
						</Form.Field>

						<Form.Field>
							<section style={{ width: '50%', display: 'flex', marginTop: '2em' }}>
								<label style={{ fontWeight: 'bold' }}>FMS</label>
								<Expander />
								<RolesToggle>
									<Checkbox toggle onChange={(e, data) => props.onIsFMSChange(data)} checked={props.isFMS} />
								</RolesToggle>
							</section>
						</Form.Field>
					</Form.Group>
				</Form>
			}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button
						primary
						loading={props.bulkButtonLoading}
						// disabled={props.disabled}
						onClick={props.onApplyClick}>
						Update
					</Button>
				</>
			}
		/>
	);
};

export default VehicleBulkUpdateModal;
