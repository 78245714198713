import React from 'react';

import { Container } from './ToggleSwitchStyles';

import { Checkbox } from 'semantic-ui-react';

function ToggleSwitch(props) {
	return (
		<Container title={props.title}>
			<Checkbox
				toggle
				checked={props.checked}
				label={props.label}
				disabled={props.disabled || false}
				onClick={props.action}
			/>
		</Container>
	);
}

export default ToggleSwitch;
