export default function message(
	initialState = {
		messageShown: false,
		messageContent: null,
		messageType: null,
	},
	action
) {
	switch (action.type) {
		case 'MESSAGING_SHOW_MESSAGE':
			return {
				...initialState,
				messageShown: true,
				messageContent: action.payload.content,
				messageType: action.payload.type,
			};

		case 'MESSAGING_HIDE_MESSAGE':
			return {
				...initialState,
				messageShown: false,
				messageContent: null,
				messageType: null,
			};

		default:
			return initialState;
	}
}
