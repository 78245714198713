export default function sidebar(
	initialState = {
		sidebarWidth: 18,
		isCollapsed: false,
	},
	action
) {
	switch (action.type) {
		case 'SIDEBAR_COLLAPSE':
			return {
				...initialState,
				sidebarWidth: 5,
				isCollapsed: true,
			};

		case 'SIDEBAR_EXPAND':
			return {
				...initialState,
				sidebarWidth: 18,
				isCollapsed: false,
			};

		default:
			return initialState;
	}
}
