import React from 'react';
import CommonModal from '../../../molecules/modal/CommonModal';
import { FILE_TYPE_EXTENSIONS } from '../../../../../../data/constants/file-types';
import { closeExportModal } from '../../../../../../logic/middleware/vehicles';

import { Button, Dropdown, Form, Input } from 'semantic-ui-react';

const ExportModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={closeExportModal}
			size="tiny"
			header="Download Files"
			content={
				<Form>
					<Button.Group>
						<Form.Field width={10}>
							<label>File Name</label>
							<Input autoFocus value={props.fileName} onChange={props.fileNameChange} />
						</Form.Field>
						<Button width={6}>
							<label>File Type</label>
							<Dropdown
								floating
								className="button-icon"
								value={props.fileExtension}
								options={FILE_TYPE_EXTENSIONS}
								onChange={props.fileExtensionChange}
							/>
						</Button>
					</Button.Group>{' '}
				</Form>
			}
			actions={
				<>
					<Button color="black" onClick={closeExportModal}>
						Cancel
					</Button>
					<Button primary onClick={props.onDownloadClick}>
						Download
					</Button>
				</>
			}
		/>
	);
};

export default ExportModal;
