import { isoToDate } from '../utilities/date';

export function createVehicleGroupDTO(data) {
	if (data && data.length !== 0) {
		return data.map((group) => {
			return {
				id: group?.id ? group.id : null,
				name: group?.name ? group.name : null,
				description: group?.description ? group.description : null,
				deletedDate: group?.deletedDate ? isoToDate(group.deletedDate) : null,
				organisation: {
					id: group?.organisation?.id ? group.organisation.id : null,
					name: group?.organisation?.name ? group.organisation.name : null,
				},
				vehicles:
					group?.vehicles && group.vehicles.length !== 0
						? group.vehicles.map((vehicle) => {
								return {
									id: vehicle.id,
									registrationNumber: vehicle.registrationNumber,
								};
						  })
						: [],
				users:
					group?.users && group.users.length !== 0
						? group.users.map((user) => {
								return {
									id: user.id,
									name:
										(user.name ? user.name : '') +
										(user.first_name ? user.first_name : '') +
										(user.middle_name ? user.middle_name : '') +
										(user.last_name ? user.last_name : ''),
								};
						  })
						: [],
				vehicleCount: group?.vehicles ? group.vehicles.length : null,
				userCount: group?.users ? group.users.length : null,
			};
		});
	} else return [];
}
