import React from 'react';

import { PAGE_COUNT_OPTIONS } from '../../../../../data/constants/general';

import { Container } from './PaginationDropdownStyles';

import { Dropdown } from 'semantic-ui-react';

function PaginationDropdown(props) {
	return (
		<Container>
			<Dropdown
				fluid
				// search
				selection
				disabled={props.disabled}
				defaultValue={props.defaultValue}
				options={PAGE_COUNT_OPTIONS}
				onChange={props.onChange}
			/>
		</Container>
	);
}

export default PaginationDropdown;
