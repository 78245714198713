import React from 'react';

import GraphLoader from '../../../molecules/graph-loader/GraphLoader';
import { GraphCard } from '../../../../styles/containers/GraphCard';
import { GraphCardMain } from '../../../../styles/containers/GraphCardMain';
import { GraphTooltip } from '../../../../styles/containers/GraphTooltip';
import { GraphCardHeader } from '../../../../styles/containers/GraphCardHeader';
import { GraphCardFooter } from '../../../../styles/containers/GraphCardFooter';
import { Expander } from '../../../../styles/place-holders/Expander';

import { COLORS } from '../../../../../styles/colors';

import moment from 'moment';
import { ResponsiveLine } from '@nivo/line';
import Avatar from '../../../atoms/graph-footer-label/Avatar';

const VehicleExpansionGraphMultiple = () => {
	const { data } = this.props;
	const dataLength = this.props?.data[0]?.data?.length || 6;

	return (
		<GraphCard>
			{this.props.loading && <GraphLoader />}
			<GraphCardHeader>
				<h3>Expansion Vehicles</h3>
			</GraphCardHeader>
			<GraphCardMain>
				<ResponsiveLine
					data={data}
					margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
					xScale={{ type: 'point' }}
					yScale={{ type: 'linear', min: '0', max: '100', stacked: false, reverse: false }}
					yFormat=" >-.2f"
					label={null}
					axisTop={null}
					axisRight={null}
					axisLeft={{
						orient: 'left',
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						format: (value) => `${value}`,
					}}
					axisBottom={{
						tickRotation: dataLength < 7 ? 0 : -45,
						format: (tick) => {
							if (dataLength < 7) return moment(tick, 'MMM YYYY').format('MMM YYYY');
							else if (dataLength >= 7 && dataLength <= 18) return moment(tick, 'MMM YYYY').format('MM/YY');
							else return '';
						},
					}}
					pointSize={10}
					pointColor="#fff"
					pointBorderWidth={2}
					pointBorderColor={{ from: 'serieColor' }}
					colors={COLORS.customersMetricsGraphs.slice(0, data.length)}
					enableCrosshair
					motion={true}
					motionConfig="gentle"
					tooltip={(value) => (
						<GraphTooltip>
							<h4>{value.point.serieId}</h4>
							{moment(value.point.data.x, 'MMM YYYY').format('MMM, YYYY')} : Vehicles {value.point.data.y}
						</GraphTooltip>
					)}
					useMesh={true}
				/>
			</GraphCardMain>
			<GraphCardFooter>
				<span>X = Months, Y = Vehicles</span>
				<Expander />
				{data.map((customer, index) => {
					return (
						<Avatar
							key={index}
							title={customer.id}
							text={customer.id
								.split(' ')
								.map((digit) => digit.charAt(0))
								.join('')}
							colorIndex={index}
							zIndex={data.length - index}
						/>
					);
				})}
			</GraphCardFooter>
		</GraphCard>
	);
};

export default VehicleExpansionGraphMultiple;
