import { getFormattedDate } from '../utilities/date';

export function createCompanyDTO(response) {
	let metrics = [];

	if (response && response.length) {
		response.forEach((metric) => {
			metrics.push({
				id: metric?.id ? metric.id : null,
				date: metric?.date ? getFormattedDate(metric.date, 'MMM YYYY') : null,
				clv: metric?.clv ? parseFloat(metric.clv) : 0.0,
				mrrPlatform: metric?.mrrPlatform ? parseFloat(metric.mrrPlatform) : 0.0,
				mrrProduct: metric?.mrrProduct ? parseFloat(metric.mrrProduct) : 0.0,
				newMrr: metric?.newMrr ? parseFloat(metric.newMrr) : 0.0,
				expansionMrr: metric?.expansionMrr ? parseFloat(metric.expansionMrr) : 0.0,
				churnedMrr: metric?.churnedMrr ? parseFloat(metric.churnedMrr) : 0.0,
				netMrr: metric?.netMrr ? parseFloat(metric.netMrr) : 0.0,
				cac: metric?.cac ? parseFloat(metric.cac) : 0.0,
				clvCacRatio: metric?.clvCacRatio ? parseFloat(metric.clvCacRatio) : 0.0,
				cogsPlatform: metric?.cogsPlatform ? parseFloat(metric.cogsPlatform) : 0.0,
				cogsProduct: metric?.cogsProduct ? parseFloat(metric.cogsProduct) : 0.0,
				fixedExpenditure: metric?.fixedExpenditure ? parseFloat(metric.fixedExpenditure) : 0.0,
				variableExpenditure: metric?.variableExpenditure ? parseFloat(metric.variableExpenditure) : 0.0,
				gm: metric?.gm ? parseFloat(metric.gm) : 0.0,
				cm: metric?.cm ? parseFloat(metric.cm) : 0.0,
				ebitda: metric?.ebitda ? parseFloat(metric.ebitda) : 0.0,
				nm: metric?.nm ? parseFloat(metric.nm) : 0.0,
				newCustomers: metric?.newCustomers ? metric.newCustomers : 0,
				totalCustomers: metric?.totalCustomers ? metric.totalCustomers : 0,
				churnedCustomers: metric?.churnedCustomers ? metric.churnedCustomers : 0,
				newVehicles: metric?.newVehicles ? metric.newVehicles : 0,
				expansionVehicles: metric?.expansionVehicles ? metric.expansionVehicles : 0,
				churnedVehicles: metric?.churnedVehicles ? metric.churnedVehicles : 0,
				totalVehicles: metric?.totalVehicles ? metric.totalVehicles : 0,
			});
		});
	}

	return metrics;
}

// [
//   {
//     id: 1,
//     date: 'Jan 2021',
//     clv: 1,
//     cac: 1,
//     clvCacRatio: 1,
//     mrrPlatform: 1,
//     mrrProduct: 1,
//     churnMrr: 1000,
//     expansionMrr: 5000,
//     newMrr: 1000,
//     netMrr: 10000,
//     customerNew: 10,
//     customerChurn: 10,
//     totalVehicles: 10,
//     totalCustomers: 10,
//     vehicleNew: 14,
//     vehicleExpansion: 10,
//     vehicleChurn: 10,
//     cogsPlatform: 1,
//     cogsProduct: 1,
//     fixedExpenditure: 1,
//     variableExpenditure: 1,
//     gm: 1,
//     cm: 1,
//     ebitda: 1,
//     nm: 1,
//   },
//   {
//     id: 1,
//     date: 'Feb 2021',
//     clv: 2,
//     cac: 1,
//     clvCacRatio: 1,
//     mrrPlatform: 1,
//     mrrProduct: 1,
//     churnMrr: 1000,
//     expansionMrr: 3000,
//     newMrr: 5000,
//     netMrr: 20000,
//     customerNew: 20,
//     customerChurn: 9,
//     totalVehicles: 15,
//     totalCustomers: 15,
//     vehicleNew: 20,
//     vehicleExpansion: 15,
//     vehicleChurn: 9,
//     cogsPlatform: 1,
//     cogsProduct: 1,
//     fixedExpenditure: 1,
//     variableExpenditure: 1,
//     gm: 1,
//     cm: 1,
//     ebitda: 1,
//     nm: 1,
//   },
//   {
//     id: 1,
//     date: 'Mar 2021',
//     clv: 3,
//     cac: 1,
//     clvCacRatio: 1,
//     mrrPlatform: 1,
//     mrrProduct: 1,
//     churnMrr: 1000,
//     expansionMrr: 6000,
//     newMrr: 10000,
//     netMrr: 40000,
//     customerNew: 30,
//     customerChurn: 8,
//     totalVehicles: 20,
//     totalCustomers: 20,
//     vehicleNew: 25,
//     vehicleExpansion: 20,
//     vehicleChurn: 8,
//     cogsPlatform: 1,
//     cogsProduct: 1,
//     fixedExpenditure: 1,
//     variableExpenditure: 1,
//     gm: 1,
//     cm: 1,
//     ebitda: 1,
//     nm: 1,
//   },
//   {
//     id: 1,
//     date: 'Apr 2021',
//     clv: 4,
//     cac: 1,
//     clvCacRatio: 1,
//     mrrPlatform: 1,
//     mrrProduct: 1,
//     churnMrr: 6000,
//     expansionMrr: 10000,
//     newMrr: 10000,
//     netMrr: 50000,
//     customerNew: 40,
//     customerChurn: 0,
//     totalVehicles: 30,
//     totalCustomers: 30,
//     vehicleNew: 30,
//     vehicleExpansion: 25,
//     vehicleChurn: 7,
//     cogsPlatform: 1,
//     cogsProduct: 1,
//     fixedExpenditure: 1,
//     variableExpenditure: 1,
//     gm: 1,
//     cm: 1,
//     ebitda: 1,
//     nm: 1,
//   },
//   {
//     id: 1,
//     date: 'May 2021',
//     clv: 5,
//     cac: 1,
//     clvCacRatio: 1,
//     mrrPlatform: 1,
//     mrrProduct: 1,
//     churnMrr: 50,
//     expansionMrr: 100,
//     newMrr: 10,
//     netMrr: 1000,
//     customerNew: 50,
//     customerChurn: 5,
//     totalVehicles: 35,
//     totalCustomers: 35,
//     vehicleNew: 35,
//     vehicleExpansion: 30,
//     vehicleChurn: 6,
//     cogsPlatform: 1,
//     cogsProduct: 1,
//     fixedExpenditure: 1,
//     variableExpenditure: 1,
//     gm: 1,
//     cm: 1,
//     ebitda: 1,
//     nm: 1,
//   },
//   {
//     id: 1,
//     date: 'Jun 2021',
//     clv: 6,
//     cac: 1,
//     clvCacRatio: 1,
//     mrrPlatform: 1,
//     mrrProduct: 1,
//     churnMrr: 100,
//     expansionMrr: 50,
//     newMrr: 50,
//     netMrr: 2000,
//     customerNew: 60,
//     customerChurn: 2,
//     totalVehicles: 40,
//     totalCustomers: 40,
//     vehicleNew: 40,
//     vehicleExpansion: 35,
//     vehicleChurn: 5,
//     cogsPlatform: 1,
//     cogsProduct: 1,
//     fixedExpenditure: 1,
//     variableExpenditure: 1,
//     gm: 1,
//     cm: 1,
//     ebitda: 1,
//     nm: 1,
//   },
// ]
