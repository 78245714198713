import React, { useState } from 'react';

import CommonModal from '../../../molecules/modal/CommonModal';

import { Button, Dropdown, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { Expander } from '../../../../styles/place-holders/Expander';

const FilterModal = (props) => {
	const { organisation = [], userName = [], vehicle = [] } = props.activeFilters;

	const [filterValues, setFilterValues] = useState({
		organisation: organisation,
		userName: userName,
		vehicle: vehicle,
	});

	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="tiny"
			header="Select the Filters"
			scrolling={true}
			content={
				<Form>
					<Form.Group widths="equal">
						<Form.Field>
							<label>User Name</label>
							<Dropdown
								placeholder="Choose ..."
								name="activity-log"
								fluid
								search
								selection
								multiple
								clearable
								options={props.userDropdownOptions}
								value={filterValues.userName}
								onChange={(event, data) => {
									setFilterValues({ ...filterValues, userName: data.value });
								}}
							/>
						</Form.Field>
					</Form.Group>

					<Form.Group widths="equal">
						<Form.Field>
							<label>Organisation</label>
							<Dropdown
								placeholder="Choose ..."
								name="activity-log"
								fluid
								search
								selection
								multiple
								clearable
								options={props.orgDropdownOption}
								value={filterValues.organisation}
								onChange={(event, data) => {
									setFilterValues({ ...filterValues, organisation: data.value });
								}}
							/>
						</Form.Field>
					</Form.Group>

					<Form.Group widths="equal">
						<Form.Field>
							<label>Vehicles</label>
							<Dropdown
								placeholder="Choose ..."
								name="activity-log"
								fluid
								search
								selection
								multiple
								clearable
								options={props.vehicleDropdownOption}
								value={filterValues.vehicle}
								onChange={(event, data) => {
									setFilterValues({ ...filterValues, vehicle: data.value });
								}}
							/>
						</Form.Field>
					</Form.Group>
				</Form>
			}
			actions={
				<>
					<FlexContainer>
						<Button
							onClick={() => {
								props.onResetClick();
								setFilterValues({
									organisation: [],
									userName: [],
									vehicle: [],
								});
							}}>
							Reset
						</Button>

						<Expander />
						<Button color="black" onClick={props.onCancel}>
							Cancel
						</Button>
						<Button
							positive
							onClick={() => {
								if (!filterValues.organisation.length && !filterValues.userName.length && !filterValues.vehicle.length)
									props.handleOnApply({});
								else props.handleOnApply(filterValues);
							}}>
							Apply
						</Button>
					</FlexContainer>
				</>
			}></CommonModal>
	);
};

export default FilterModal;

const FlexContainer = styled.section`
	display: flex;
`;
