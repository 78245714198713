import React from 'react';

import { Labels } from './AvatarStyles';

function Avatar(props) {
	return (
		<Labels title={props.title} colorIndex={props.colorIndex} zIndex={props.zIndex}>
			{props.text}
		</Labels>
	);
}

export default Avatar;
