export default function service(
	initialState = {
		vehicles: [],
		vehiclesBackup: [],
		loading: false,

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
	},
	action
) {
	switch (action.type) {
		case 'FEED_SERVICE_VEHICLES':
			return {
				...initialState,
				vehicles: action.payload,
				vehiclesBackup: action.payload,
				loading: false,
			};

		case 'FEED_SERVICE_VEHICLES_BACKUP':
			return {
				...initialState,
				vehiclesBackup: action.payload,
			};

		case 'FILTER_SERVICE_VEHICLES':
			return {
				...initialState,
				vehicles: action.payload,
				loading: false,
			};

		case 'SERVICE_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'SERVICE_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'SERVICE_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'SERVICE_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_SERVICE_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_SERVICE_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_SERVICE_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'SERVICE_UNMOUNT':
			return {
				...initialState,
				vehicles: [],
				loading: true,

				// Export
				openExportModal: false,
				exportData: [],
				exportingData: false,
			};

		default:
			return initialState;
	}
}
