import { EXPORT_API, GEOFENCES_API, STUDENTS_API } from '../configs/api';
import { STUDENTS_FILE_HEADER } from '../../data/constants/general';
import { createStudentsDTO } from '../dto/students';
import { createOrganisationDTO } from '../dto/organisations';
import { createVehicleGroupDTO } from '../dto/vehicleGroups';
import { createRolesDTO } from '../dto/roles';
import { createVehicleDTO } from '../dto/vehicles';
import { createGeoFenceDTO } from '../dto/geofences';
import Logo from '../../ui/assets/images/logo.png';
import { dispatch, dispatchNoPayload } from './middleware';
import { fetchVehicleGroups } from './vehicleGroups';
import { fetchOrganisations } from './organisations';
import { fetchVehicles } from './vehicles';
import Store from '../../data/store/store';
import { exportToExcelWithLogo } from '../utilities/excel';
import { handleError } from './messaging';
import { fetchRoles } from './roles';

import { jsPDF } from 'jspdf';
import { fetchUsers } from './users';
import { createUsersDTO } from '../dto/users';
import fileDownload from 'js-file-download';
import { exportApi, getData } from './services/apiRequest';
import { datadogLogs } from '@datadog/browser-logs';

export function fetchStudents(params = '') {
	return getData(STUDENTS_API, params);
	// axios(STUDENTS_API + params, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// });
}

export function refreshStudents(params = '', activePage = 1, resetOffset = false) {
	dispatchNoPayload('STUDENTS_LOADING');

	// axios(
	// 	STUDENTS_API +
	// 		'?pagecount=' +
	// 		getPageNumberFromStore().dataSizeLimit +
	// 		'&pagenumber=' +
	// 		(resetOffset ? 1 : activePage) +
	// 		params,
	// 	{
	// 		headers: {
	// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 			'Content-Type': 'application/json',
	// 		},
	// 	}
	// )
	getData(
		STUDENTS_API +
			'?pagecount=' +
			getPageNumberFromStore().dataSizeLimit +
			'&pagenumber=' +
			(resetOffset ? 1 : activePage) +
			params
	)
		.then((response) => {
			dispatch('FEED_STUDENTS', createStudentsDTO(response?.data?.data));
			dispatch('STUDENTS_SET_PAGE_COUNT', response?.data?.count || 0);
			dispatch('STUDENTS_SET_PAGE_NUMBER', activePage);
		})
		.catch((error) => {
			dispatchNoPayload('STUDENTS_LOADED');
			datadogLogs.logger.error('Refresh students api', {}, error);
			handleError(error);
		});
}

export function searchStudents(query) {
	return `&search=${encodeURIComponent(query)
		.trim()
		.split('+')
		.map((searchTerm) => `${searchTerm}`)}`;
}

// Export
export function openExportModal() {
	dispatchNoPayload('STUDENTS_EXPORT_MODAL_OPEN');
}

export function closeExportModal() {
	dispatchNoPayload('STUDENTS_EXPORT_MODAL_CLOSE');
}

export function formatExcelExport(response) {
	return response.map((student) => {
		return {
			Name: student.name,
			Organisation: student.organisation.name,
			PickupBus: student.pickupBus.registrationNumber,
			PickupPoint: student.pickupPoint.name,
			PickupTime: student.pickupTime,
			DropBus: student.dropBus.registrationNumber,
			DropPoint: student.dropPoint.name,
			DropTime: student.dropTime,
			Parents: student.parents.map((parent) => parent.name).join(', '),
		};
	});
}

export function exportData(data, fileName, extension) {
	if (extension === 'excel') {
		exportToExcelWithLogo(formatExcelExport(data), `${!fileName ? 'Students' : fileName}`, STUDENTS_FILE_HEADER);
	} else {
		convertToPdf(formatExcelExport(data), fileName);
	}

	closeExportModal();
}

export function convertToPdf(data, fileName) {
	const doc = new jsPDF({ orientation: 'l', unit: 'mm', format: 'a4' });

	let col = [
		'Name',
		'Organisation',
		'PickupBus',
		'PickupPoint',
		'PickupTime',
		'DropBus',
		'DropPoint',
		'DropTime',
		'Parents',
	];
	let rows = [];

	data.forEach((user) => {
		let temp = [];
		Object.keys(user).forEach((key, index) => {
			if (col[index] === key) temp[index] = user[key];
		});

		rows.push(temp);
	});

	let header = function (data) {
		doc.addImage(Logo, 'png', data.settings.margin.left, 10, 40, 14);
	};

	doc.autoTable(col, rows, {
		margin: { top: 25 },
		beforePageContent: header,
		theme: 'grid',
	});

	doc.save(`${!fileName ? 'Students' : fileName}`);
}

export function exportAll(param) {
	// dispatchNoPayload('EXPORTING_STUDENTS_EXPORT_DATA');

	// axios(EXPORT_API + '/students' + param, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// })
	exportApi(EXPORT_API + '/students' + param)
		.then((response) => {
			// openExportModal();
			// dispatch('SET_STUDENTS_EXPORT_DATA', createStudentsDTO(response?.data?.data));
			// dispatchNoPayload('EXPORTED_STUDENTS_EXPORT_DATA');
			const contentDisposition = response.headers['content-disposition'];
			const matches = /filename="([^"]+)"/.exec(contentDisposition);

			if (matches) {
				const fileName = matches[1];
				fileDownload(response?.data, fileName);
			}
			// fileDownload(response?.data, `Students.${fileExtension}`);
		})
		.catch((error) => {
			handleError(error);
			datadogLogs.logger.error('Students export api', {}, error);
			dispatchNoPayload('EXPORTED_STUDENTS_EXPORT_DATA');
		});
}

// Verification
export function openSendVerificationModal() {
	dispatchNoPayload('STUDENTS_SEND_VERIFICATION_MODAL_OPEN');
}

export function closeSendVerificationModal() {
	dispatchNoPayload('STUDENTS_SEND_VERIFICATION_MODAL_CLOSE');
}

// Accordion
export function toggleAccordion(e, titleProps, current) {
	const { index } = titleProps;
	const { activeIndex } = {
		activeIndex: current,
	};
	const newIndex = activeIndex === index ? -1 : index;
	dispatchNoPayload('STUDENTS_EDIT_MODAL_CLOSE');
	dispatch('STUDENTS_TOGGLE_ACCORDION', newIndex);
}

// Dropdowns
export function getOrganisationsForDropdown() {
	return fetchOrganisations('?dropdown=true')
		.then((response) => {
			let organisation = createOrganisationDTO(response.data.data);
			if (organisation) {
				return createOrganisationDTO(response.data.data).map((organisation, index) => {
					return {
						key: index,
						value: organisation.id,
						text: organisation.name,
					};
				});
			} else return [];
		})
		.catch((error) => {
			handleError(error);
			return [];
		});
}

export function getVehicleGroupsForDropdown(params) {
	return fetchVehicleGroups(params)
		.then((response) => {
			return createVehicleGroupDTO(response?.data?.data).map((vehicleGroup, index) => {
				return {
					key: index,
					value: vehicleGroup.id,
					text: vehicleGroup.name,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			return [];
		});
}

export function getVehiclesForDropdown(params) {
	return fetchVehicles(params)
		.then((response) => {
			return createVehicleDTO(response?.data?.data).map((vehicle, index) => {
				return {
					key: index,
					value: vehicle.id,
					text: vehicle.registrationNumber,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			return [];
		});
}

export function getGeofenceForDropdown(params) {
	// axios(GEOFENCES_API + params, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// })

	return getData(GEOFENCES_API, params)
		.then((response) => {
			return createGeoFenceDTO(response?.data?.data).map((geofence, index) => {
				return {
					key: index,
					value: geofence.id,
					text: geofence.name,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			return [];
		});
}

export function getDynamicRolesForDropdown(params = '') {
	return fetchRoles(params)
		.then((response) => {
			return createRolesDTO(response?.data?.data).map((vehicle, index) => {
				return {
					key: index,
					value: vehicle.id,
					text: vehicle.name,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			return [];
		});
}

export function getStudentTypesForDropdown() {
	return [
		{
			key: 0,
			value: 'Default',
			text: 'Default',
		},
		{
			key: 1,
			value: 'Driver',
			text: 'Driver',
		},
		{
			key: 2,
			value: 'Parent',
			text: 'Parent',
		},
	];
}

export function getRolesForDropdown(params = '') {
	return fetchRoles(params)
		.then((response) => {
			return createRolesDTO(response?.data?.data).map((role, index) => {
				return {
					key: index,
					value: role.id,
					text: role.name,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			return [];
		});
}

export function getUsersForDropdown(params = '') {
	return fetchUsers(params)
		.then((response) => {
			return createUsersDTO(response?.data?.data).map((user, index) => {
				return {
					key: index,
					value: user.id,
					text: user.name,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			return [];
		});
}

export function openFilterModal() {
	dispatchNoPayload('STUDENTS_FILTER_MODAL_OPEN');
}

export function closeFilterModal() {
	dispatchNoPayload('STUDENTS_FILTER_MODAL_CLOSE');
}

// Connect to Store
function getPageNumberFromStore() {
	return {
		pageNumber: Store.getState().students.pageNumber,
		dataSizeLimit: Store.getState().ui.dataSizeLimit,
	};
}
