export default function items(
	initialState = {
		items: [],
		loading: true,
		deleting: false,
		deleteModalOpen: false,
		activeIndex: -1,
		activeSort: '',
		sortOrder: '',
		adding: false,
		addModalOpen: false,
		updating: false,
		editMode: false,
		pageNumber: 1,
		count: 0,
		restoreModal: false,
		restoring: false,

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
	},
	action
) {
	switch (action.type) {
		case 'FEED_ITEMS':
			return {
				...initialState,
				items: action.payload,
				loading: false,
			};

		case 'ITEMS_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'ITEMS_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'ITEMS_DELETING':
			return {
				...initialState,
				deleting: true,
			};

		case 'ITEMS_DELETED':
			return {
				...initialState,
				deleting: false,
			};

		case 'ITEMS_DELETE_MODAL_OPEN':
			return {
				...initialState,
				deleteModalOpen: true,
			};

		case 'ITEMS_DELETE_MODAL_CLOSE':
			return {
				...initialState,
				deleteModalOpen: false,
			};

		case 'ITEMS_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'ITEMS_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'ITEMS_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'ITEMS_ADDING':
			return {
				...initialState,
				adding: true,
			};

		case 'ITEMS_ADDED':
			return {
				...initialState,
				adding: false,
			};

		case 'ITEMS_ADD_MODAL_OPEN':
			return {
				...initialState,
				addModalOpen: true,
			};

		case 'ITEMS_ADD_MODAL_CLOSE':
			return {
				...initialState,
				addModalOpen: false,
			};

		case 'ITEMS_UPDATING':
			return {
				...initialState,
				updating: true,
			};

		case 'ITEMS_UPDATED':
			return {
				...initialState,
				updating: false,
			};

		case 'ITEMS_EDIT_MODAL_OPEN':
			return {
				...initialState,
				editMode: true,
			};

		case 'ITEMS_EDIT_MODAL_CLOSE':
			return {
				...initialState,
				editMode: false,
			};

		case 'ITEMS_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'ITEMS_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'ITEMS_RESTORE_MODAL_OPEN':
			return {
				...initialState,
				restoreModal: true,
			};

		case 'ITEMS_RESTORE_MODAL_CLOSE':
			return {
				...initialState,
				restoreModal: false,
			};

		case 'ITEMS_RESTORING':
			return {
				...initialState,
				restoring: true,
			};

		case 'ITEMS_RESTORED':
			return {
				...initialState,
				restoring: false,
			};

		case 'ITEMS_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'ITEMS_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_ITEMS_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_ITEMS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_ITEMS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'ITEMS_UNMOUNT':
			return {
				...initialState,
				items: [],
				activeSort: '',
				sortOrder: '',
				activeIndex: -1,
				pageNumber: 1,
			};
		default:
			return initialState;
	}
}
