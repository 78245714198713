import { isoToDate } from '../utilities/date';

export function createFuelLogsDTO(data) {
	if (data && data.length) {
		return data.map((fuelLog) => {
			return {
				id: fuelLog?.id ? fuelLog.id : null,
				timestamp: fuelLog?.timestamp ? isoToDate(fuelLog.timestamp) : null,
				refuelQuantity: fuelLog?.refuelQuantity ? fuelLog.refuelQuantity : 1,
				odometer: fuelLog?.odometer ? fuelLog.odometer : 1,
				billAmount: fuelLog?.billAmount ? fuelLog.billAmount : 1,
				mileage: fuelLog?.mileage ? fuelLog.mileage : 1,
				fuelPrice: fuelLog?.fuelPrice ? fuelLog.fuelPrice : 1,
				odometerDistanceDifference: fuelLog?.odometerDistanceDifference ? fuelLog.odometerDistanceDifference : null,
				vehicle: {
					id: fuelLog?.vehicle?.id ? fuelLog.vehicle.id : null,
					registrationNumber: fuelLog?.vehicle?.registrationNumber ? fuelLog.vehicle.registrationNumber : null,
				},
				organisation: {
					id: fuelLog?.organisation?.id ? fuelLog.organisation.id : null,
					name: fuelLog?.organisation?.name ? fuelLog.organisation.name : null,
				},
				fileExists: fuelLog?.fileExists ? fuelLog.fileExists : false,
			};
		});
	}
}
