import styled from 'styled-components';

import { COLORS } from '../../../../styles/colors';

export const Labels = styled.section`
	width: 2.7em;
	height: 2.7em;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: -1%;

	position: relative;
	box-sizing: border-box;

	border: 2px solid #fff;
	border-radius: 50%;
	color: #fff;
	background: ${(props) => COLORS.customersMetricsGraphs[props.colorIndex]};

	font-weight: bold;
	cursor: pointer;

	z-index: ${(props) => props.zIndex};

	&:hover {
		box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	}
`;
