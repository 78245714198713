import React from 'react';

import CommonModal from '../../../molecules/modal/CommonModal';
import { Expander } from '../../../../styles/place-holders/Expander';

import { Button, Dropdown, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { EMAIL_VERIFIED_OPTIONS, PHONE_VERIFIED_OPTIONS } from '../../../../../../data/constants/dropdown-options';

const FilterModal = (props) => {
	const isSuperAdminAccess = localStorage.getItem('isSuperAdmin');

	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="tiny"
			scrolling
			header="Users Filters"
			content={
				<Form>
					{isSuperAdminAccess === 'true' && (
						<Form.Group widths="equal">
							<Form.Field>
								<label>Organisations</label>
								<Dropdown
									placeholder="Choose..."
									fluid
									search
									selection
									multiple
									clearable
									value={props.organisations}
									options={props.organisationsOptions}
									onChange={props.onOrganisationFilterChange}
								/>
							</Form.Field>
						</Form.Group>
					)}

					<Form.Group widths="equal">
						<Form.Field>
							<label>User Types</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								selection
								multiple
								clearable
								value={props.types}
								options={props.typesOptions}
								onChange={props.onTypesFilterChange}
							/>
						</Form.Field>
					</Form.Group>

					<Form.Group widths="equal">
						<Form.Field>
							<label>Email</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								selection
								multiple
								clearable
								value={props.email}
								options={EMAIL_VERIFIED_OPTIONS}
								onChange={props.onEmailFilterChange}
							/>
						</Form.Field>
					</Form.Group>

					<Form.Group widths="equal">
						<Form.Field>
							<label>Phone</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								selection
								multiple
								clearable
								value={props.phone}
								options={PHONE_VERIFIED_OPTIONS}
								onChange={props.onPhoneFilterChange}
							/>
						</Form.Field>
					</Form.Group>

					<Form.Group widths="equal">
						<Form.Field>
							<label>Roles</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								selection
								multiple
								clearable
								upward={false}
								value={props.roles}
								options={props.rolesOptions}
								onChange={props.onRolesFilterChange}
							/>
						</Form.Field>
					</Form.Group>
				</Form>
			}
			actions={
				<>
					<FlexContainer>
						<Button onClick={props.onResetClick}>Reset</Button>
						<Expander />
						<Button color="black" onClick={props.onCancelClick}>
							Cancel
						</Button>
						<Button
							primary
							onClick={props.onApplyClick}
							disabled={
								!props.organisations?.length &&
								!props.types?.length &&
								!props.email?.length &&
								!props.phone?.length &&
								!props.roles?.length
							}>
							Apply
						</Button>
					</FlexContainer>
				</>
			}
		/>
	);
};

const FlexContainer = styled.section`
	display: flex;
`;

export default FilterModal;
