import React from 'react';
import { ALERTS_API } from '../../../../../logic/configs/api';

import BasicLoader from '../../../blocks/molecules/loader/BasicLoader';
import SearchBox from '../../../blocks/atoms/search-box/SearchBox';
import ToggleSwitch from '../../../blocks/molecules/toggle-switch/ToggleSwitch';
import FooterPagination from '../../../blocks/molecules/pagination/Pagination';
import DeleteModal from '../../../blocks/organisms/modals/alerts/DeleteModal';
import RestoreModal from '../../../blocks/organisms/modals/alerts/RestoreModal';
import AddModal from '../../../blocks/organisms/modals/alerts/AddModal';
import ExportModal from '../../../blocks/organisms/modals/alerts/ExportModal';
import { CategoryHeader } from '../../../styles/containers/CategoryHeader';
import { Expander } from '../../../styles/place-holders/Expander';
import { CategoryMain } from '../../../styles/containers/CategoryMain';
import { AccordionHeader } from '../../../styles/containers/AccordionHeader';
import { AccordionMain } from '../../../styles/containers/AccordionMain';
import { CategoryFooter } from '../../../styles/containers/CategoryFooter';
import { CategoryContentMembrane } from '../../../styles/containers/CategoryContentMembrane';
import { AccordionMainContent } from '../../../styles/containers/AccordionMainContent';
import PlaceHolder from '../../../blocks/molecules/place-holder/PlaceHolder';
import FilterModal from '../../../blocks/organisms/modals/alerts/FilterModal';
import TransitionLoader from '../../../blocks/molecules/loader/TransitionLoader';
import {
	closeFilterModal,
	exportData,
	getActiveDaysForDropdown,
	getEventTypesForDropdown,
	getIsEnabledForDropdown,
	getOrganisationsForDropdown,
	getUsersForDropdown,
	openFilterModal,
	refreshAlerts,
	searchAlerts,
	toggleAccordion,
} from '../../../../../logic/middleware/alerts';
import { dispatch, dispatchNoPayload } from '../../../../../logic/middleware/middleware';
import { connect } from 'react-redux';
import { generateSuccessMessage, handleError, handleSuccess } from '../../../../../logic/middleware/messaging';
// import axios from 'axios';
// import { CheckboxContainer } from '../../../styles/Containers';
import { Actions, HeaderElement, Row, RowElement } from '../../../styles/Accordion';
import { Accordion, Button, Checkbox, Dropdown, Form, Icon, Input, Label } from 'semantic-ui-react';
import { TimeInput } from 'semantic-ui-calendar-react';
import accessSetter from '../../../../../logic/utilities/accessSetter';
import { trackMixpanelEvent } from '../../../../../logic/middleware/mixpanel';
import { deleteData, patchData, postData } from '../../../../../logic/middleware/services/apiRequest';
import { capitalizeWord } from '../../../../../logic/utilities/validator';
import { datadogLogs } from '@datadog/browser-logs';
import { RolesToggle } from '../../../blocks/molecules/toggle-switch/ToggleSwitchStyles';
// import { FILE_TYPE_EXTENSIONS } from '../../../../../data/constants/file-types';

// import { searchVehicleGroups } from '../../../../../logic/middleware/vehicleGroups';

class Alerts extends React.Component {
	alertID = '';

	searchQuery = '';
	timeoutId;

	deletedMode = false;
	selectAllActive = false;

	sortOrder = '';
	activeSort = '';
	isSuperAdminAccess = localStorage.getItem('isSuperAdmin');

	// Status Filter
	activeFilters = {
		organisations: [],
		users: [],
		eventTypes: [],
		activeDays: [],
		isEnabled: null,
	};
	days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

	constructor(props) {
		super(props);

		this.fileInputRef = React.createRef();

		this.state = {
			initialLoading: true,
			manageAccess: false,
			readAccess: false,
			createAccess: false,
			deleteAccess: false,
			updateAccess: false,

			organisations: [],
			users: [],
			eventTypes: [],

			// Add
			nameFA: null,
			descriptionFA: null,
			organisationFA: null,
			usersFA: [],
			eventTypesFA: [],
			activeDaysFA: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
			activeStartTimeFA: '00:00',
			activeEndTimeFA: '23:59',
			isEnabledFA: true,

			// Edit
			nameFE: null,
			descriptionFE: null,
			organisationFE: null,
			usersFE: null,
			eventTypesFE: null,
			activeDaysFE: null,
			activeStartTimeFE: null,
			activeEndTimeFE: null,
			isEnabledFE: null,

			// Selection
			anyRowSelected: false,
			selectedRows: new Set(),

			allUsers: [],
			filters: {
				organisations: [],
				users: [],
				eventTypes: [],
				activeDays: [],
				isEnabled: null,
			},

			deletedMode: false,

			// Export
			fileName: 'Alerts',
			fileExtension: 'pdf',
		};

		this.toggleDeletedMode = this.toggleDeletedMode.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.add = this.add.bind(this);
		this.cleanAddOperation = this.cleanAddOperation.bind(this);
		this.export = this.export.bind(this);
		this.update = this.update.bind(this);
		this.cleanEditOperation = this.cleanEditOperation.bind(this);
		this.cleanDeleteOperation = this.cleanDeleteOperation.bind(this);
		this.cleanRestoreOperation = this.cleanRestoreOperation.bind(this);
		this.handleDataSizeLimitChange = this.handleDataSizeLimitChange.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.cleanSelectedRows = this.cleanSelectedRows.bind(this);

		this.getFilteredAlerts = this.getFilteredAlerts.bind(this);
		this.handleExportSelection = this.handleExportSelection.bind(this);
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.deleteAlert = this.deleteAlert.bind(this);
		this.restoreAlert = this.restoreAlert.bind(this);
		this.sort = this.sort.bind(this);
		this.onOrganisationsFilterChange = this.onOrganisationsFilterChange.bind(this);
		this.onUsersFilterChange = this.onUsersFilterChange.bind(this);
		this.onEventTypesFilterChange = this.onEventTypesFilterChange.bind(this);
		this.onActiveDaysFilterChange = this.onActiveDaysFilterChange.bind(this);
		this.onIsEnabledFilterChange = this.onIsEnabledFilterChange.bind(this);

		this.onNameChange = this.onNameChange.bind(this);
		this.onDescriptionChange = this.onDescriptionChange.bind(this);
		this.onOrganisationChoose = this.onOrganisationChoose.bind(this);
		this.onUserChoose = this.onUserChoose.bind(this);
		this.onOrganisationChooseFE = this.onOrganisationChooseFE.bind(this);
		this.onUserChooseFE = this.onUserChooseFE.bind(this);
		this.onActiveDaysChange = this.onActiveDaysChange.bind(this);
		this.onActiveDaysChangeFE = this.onActiveDaysChangeFE.bind(this);
		this.onEventTypesChange = this.onEventTypesChange.bind(this);
		this.onEventTypesChangeFE = this.onEventTypesChangeFE.bind(this);
		this.onIsEnabledChange = this.onIsEnabledChange.bind(this);
		this.onIsEnabledChangeFE = this.onIsEnabledChangeFE.bind(this);

		this.fileExtensionChange = this.fileExtensionChange.bind(this);
		this.fileNameChange = this.fileNameChange.bind(this);
		this.manageAccessSetter = this.manageAccessSetter.bind(this);
		this.onNameChangeFE = this.onNameChangeFE.bind(this);
	}

	manageAccessSetter(rule, action) {
		this.setState({ [action]: rule });
	}

	componentDidMount() {
		trackMixpanelEvent('Alerts', { Action: 'Add' });
		const permissions = JSON.parse(localStorage.getItem('permissions'));
		const alertPermissions = permissions?.filter((permission) => permission.subject === 'Alert');
		if (alertPermissions) {
			for (const alertPrm of alertPermissions) {
				accessSetter(alertPrm?.action, this.manageAccessSetter);
			}
		}

		if (this.props.searchQuery) {
			dispatch('UPDATE_SEARCH_QUERY', this.props.searchQuery);
			this.handleSearch({ target: { value: this.props.searchQuery } });
			return;
		}

		getOrganisationsForDropdown('?dropdown=true').then((response) => {
			this.setState({ organisations: response });
		});

		getUsersForDropdown('?dropdown=true').then((response) => {
			this.setState({ allUsers: response });
		});

		getEventTypesForDropdown().then((response) => {
			this.setState({ eventTypes: response });
		});
		refreshAlerts();

		if (this.props.filters.organisations) {
			this.activeFilters = this.state.filters;
			this.activeFilters.organisations = this.props.filters.organisations;
			this.getFilteredAlerts();
		}
	}

	// Fetch
	getFilteredAlerts(activePage = 1, resetAccordion = true) {
		if (resetAccordion) {
			dispatch('ALERTS_TOGGLE_ACCORDION', -1);
		}

		let query = '';
		if (this.searchQuery !== '') {
			query = query + searchAlerts(this.searchQuery);
		}

		if (this.activeSort !== '' && this.sortOrder !== '') {
			if (this.sortOrder === 'asc') {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			} else {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			}
		}

		if (this.activeFilters.organisations?.length) {
			query =
				query + this.activeFilters.organisations.map((organisation) => `&organisations[]=${organisation}`).join('');
		}

		if (this.activeFilters.users?.length) {
			query = query + this.activeFilters.users.map((user) => `&users[]=${user}`).join('');
		}

		if (this.activeFilters.eventTypes?.length) {
			query = query + this.activeFilters.eventTypes.map((type) => `&type[]=${type}`).join('');
		}

		if (this.activeFilters.activeDays?.length) {
			query = query + this.activeFilters.activeDays.map((activeDays) => `&activeDays[]=${activeDays}`).join('');
		}

		if (this.activeFilters.isEnabled !== null) {
			query = query + `&isEnabled=${this.activeFilters.isEnabled || ''}`;
		}

		if (this.deletedMode) {
			query = query + '&deleted=true';
		}

		refreshAlerts(query, activePage);
	}

	handleSearch(e) {
		this.searchQuery = e.target.value;
		dispatch('UPDATE_SEARCH_QUERY', this.searchQuery);

		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}

		this.timeoutId = setTimeout(() => {
			this.getFilteredAlerts();
			this.cleanSelectedRows();
		}, 500);
	}

	toggleDeletedMode() {
		this.deletedMode = !this.deletedMode;
		this.setState({
			deletedMode: !this.state.deletedMode,
		});

		this.getFilteredAlerts();
		this.cleanSelectedRows();
	}

	// Export
	fileNameChange(e) {
		this.setState({ fileName: e.target.value });
	}

	fileExtensionChange(e, { value }) {
		this.setState({ fileExtension: value });
	}

	handleExportSelection(checkedData, id) {
		let selectedRows = this.state.selectedRows;

		if (selectedRows.has(id)) {
			selectedRows.delete(id);
		} else selectedRows.add(id);

		this.setState({
			selectedRows: selectedRows,
			anyRowSelected: !!selectedRows.size,
		});
	}

	handleSelectAll(checked) {
		let selectedRows = new Set();

		if (checked) {
			this.selectAllActive = true;
			this.props.alerts.forEach((alert) => selectedRows.add(alert.id));
			this.setState({
				selectedRows: selectedRows,
				anyRowSelected: !!selectedRows.size,
			});
		} else {
			this.selectAllActive = false;
			this.setState({
				selectedRows: new Set(),
				anyRowSelected: !!selectedRows.size,
			});
		}
	}

	export() {
		const { alerts } = this.props;

		let alertsToExport = [];
		alerts.forEach((alert) => {
			if (this.state.selectedRows.has(alert.id)) {
				alertsToExport.push(alert);
			}
		});

		return alertsToExport;
	}

	getFilterListQuery() {
		let query = '';

		if (this.searchQuery !== '') {
			query = query + searchAlerts(this.searchQuery);
		}

		if (this.activeSort !== '' && this.sortOrder !== '') {
			if (this.sortOrder === 'asc') {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			} else {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			}
		}

		query = query + `&fileType[]=${this.state.fileExtension}`;

		if (this.activeFilters.organisations) {
			query =
				query + this.activeFilters.organisations.map((organisation) => `&organisations[]=${organisation}`).join('');
		}

		if (this.activeFilters.users.length) {
			query = query + this.activeFilters.users.map((user) => `&users[]=${user}`).join('');
		}

		if (this.activeFilters.eventTypes.length) {
			query = query + this.activeFilters.eventTypes.map((type) => `&type[]=${type}`).join('');
		}

		if (this.activeFilters.activeDays.length) {
			query = query + this.activeFilters.activeDays.map((activeDays) => `&activeDays[]=${activeDays}`).join('');
		}

		if (this.activeFilters.isEnabled !== null) {
			query = query + `&isEnabled=${this.activeFilters.isEnabled || ''}`;
		}

		return query;
	}

	// Add
	add() {
		const {
			nameFA,
			descriptionFA,
			organisationFA,
			usersFA,
			eventTypesFA,
			activeDaysFA,
			activeStartTimeFA,
			activeEndTimeFA,
			isEnabledFA,
		} = this.state;
		let query = {};

		if (nameFA) query = { ...query, name: nameFA.trim() };
		if (descriptionFA) query = { ...query, description: descriptionFA.trim() };

		// Active Days
		query = {
			...query,
			activeDays: activeDaysFA.length === 0 || activeDaysFA.length === 7 ? ['All Days'] : activeDaysFA,
		};
		query.type = eventTypesFA;
		query.isEnabled = isEnabledFA;
		query.activeStartTime = activeStartTimeFA;
		query.activeEndTime = activeEndTimeFA;
		query.isEnabled = isEnabledFA;
		query = {
			...query,
			organisation: {
				id: organisationFA,
			},
		};

		query = {
			...query,
			users: usersFA.map((user) => {
				return {
					id: user,
				};
			}),
		};

		if (eventTypesFA?.length)
			query = {
				...query,
				type: eventTypesFA,
			};

		dispatchNoPayload('ALERTS_ADDING');

		// axios
		//   .post(ALERTS_API, query, {
		//     headers: {
		//       Authorization: 'Bearer ' + localStorage.getItem('token'),
		//       'Content-Type': 'application/json',
		//     },
		//   })
		postData(ALERTS_API, '', query)
			.then((response) => {
				dispatchNoPayload('ALERTS_ADDED');
				generateSuccessMessage(response);
				this.getFilteredAlerts(this.props.pageNumber);
				this.cleanAddOperation();
			})
			.catch((error) => {
				dispatchNoPayload('ALERTS_ADDED');
				datadogLogs.logger.error('Alerts add api', {}, error);
				handleError(error);
			});
	}

	cleanAddOperation() {
		dispatchNoPayload('ALERTS_ADD_MODAL_CLOSE');

		this.setState({
			organisations: [],
			users: [],
			nameFA: null,
			descriptionFA: null,
			organisationFA: null,
			usersFA: [],
			eventTypesFA: [],
			activeDaysFA: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
			isEnabledFA: true,
			activeStartTimeFA: '00:00',
			activeEndTimeFA: '23:59',
		});
	}

	onNameChange(e) {
		const capitalizedValue = capitalizeWord(e.target.value);
		this.setState({
			nameFA: capitalizedValue,
		});
	}

	onDescriptionChange(e) {
		this.setState({
			descriptionFA: e.target.value,
		});
	}

	onOrganisationChoose(e, { value }) {
		this.setState({
			organisationFA: value,
			usersFA: [],
		});
		getUsersForDropdown(`?organisations[]=${value}&dropdown=true`).then((response) => {
			this.setState({ users: response });
		});
	}

	onUserChoose(e, { value }) {
		this.setState({
			usersFA: value,
		});
	}

	onEventTypesChange(e, { value }) {
		this.setState({
			eventTypesFA: value,
		});
	}

	onActiveDaysChange(e, { value }) {
		this.setState({
			activeDaysFA: value,
		});
	}

	onIsEnabledChange(data) {
		this.setState({
			isEnabledFA: data.checked,
		});
	}

	handleActiveTimeChange = (event, { name, value }) => {
		// if (Object.hasOwn(Object, name)) {
		this.setState({ [name]: value });
		// }
	};

	onOrganisationChooseFE(e, { value }) {
		this.setState({
			organisationFE: value,
			usersFE: null,
		});
		getUsersForDropdown(`?organisations[]=${value}&dropdown=true`).then((response) => {
			this.setState({ users: response, usersFE: [] });
		});
	}

	onUserChooseFE(e, { value }) {
		this.setState({
			usersFE: value,
		});
	}

	onEventTypesChangeFE(e, { value }) {
		this.setState({
			eventTypesFE: value,
		});
	}

	onNameChangeFE(e, { value }) {
		const capitalizedValue = capitalizeWord(value);
		this.setState({
			nameFE: capitalizedValue,
		});
	}

	onActiveDaysChangeFE(e, { value }) {
		this.setState({
			activeDaysFE: value,
		});
	}

	onIsEnabledChangeFE(data) {
		this.setState({
			isEnabledFE: data.checked,
		});
	}

	// Update
	update(id) {
		const {
			nameFE,
			descriptionFE,
			organisationFE,
			usersFE,
			eventTypesFE,
			activeDaysFE,
			activeStartTimeFE,
			activeEndTimeFE,
			isEnabledFE,
		} = this.state;

		let query = {};

		if (nameFE) query = { ...query, name: nameFE.trim() };

		query = descriptionFE === null ? query : { ...query, description: descriptionFE.trim() };

		if (organisationFE)
			query = {
				...query,
				organisation: {
					id: organisationFE,
				},
			};

		if (usersFE !== null)
			query = {
				...query,
				users: usersFE.map((user) => {
					return {
						id: user,
					};
				}),
			};

		if (eventTypesFE !== null)
			query = {
				...query,
				type: eventTypesFE,
			};

		if (activeDaysFE !== null)
			query = {
				...query,
				activeDays: activeDaysFE.length === 0 || activeDaysFE.length === 7 ? ['All Days'] : activeDaysFE,
			};

		if (activeStartTimeFE) query = { ...query, activeStartTime: activeStartTimeFE };
		if (activeEndTimeFE) query = { ...query, activeEndTime: activeEndTimeFE };

		if (isEnabledFE !== null)
			query = {
				...query,
				isEnabled: isEnabledFE,
			};

		dispatchNoPayload('ALERTS_UPDATING');
		// axios
		//   .patch(ALERTS_API + `/${id}`, query, {
		//     headers: {
		//       Authorization: 'Bearer ' + localStorage.getItem('token'),
		//     },
		//   })
		patchData(ALERTS_API + `/${id}`, query)
			.then((response) => {
				dispatchNoPayload('ALERTS_UPDATED');

				handleSuccess(response);
				generateSuccessMessage(response);
				this.getFilteredAlerts(this.props.pageNumber, false);
				this.cleanEditOperation();
			})
			.catch((error) => {
				dispatchNoPayload('ALERTS_UPDATED');
				handleError(error);
			});
	}

	cleanEditOperation() {
		dispatchNoPayload('ALERTS_EDIT_MODAL_CLOSE');

		this.setState({
			nameFE: null,
			descriptionFE: null,
			organisationFE: null,
			usersFE: null,
			eventTypesFE: null,
			activeDaysFE: null,
			activeStartTimeFE: null,
			activeEndTimeFE: null,
			isEnabledFE: null,
		});
	}

	// Delete
	deleteAlert(id) {
		dispatchNoPayload('ALERTS_DELETING');
		// axios
		//   .delete(ALERTS_API + `/${id}`, {
		//     headers: {
		//       Authorization: 'Bearer ' + localStorage.getItem('token'),
		//     },
		//   })
		deleteData(ALERTS_API, `/${id}`)
			.then((response) => {
				dispatchNoPayload('ALERTS_DELETED');
				generateSuccessMessage(response);
				this.getFilteredAlerts(this.props.pageNumber);
				this.cleanDeleteOperation();
			})
			.catch((error) => {
				dispatchNoPayload('ALERTS_DELETED');
				handleError(error);
			});
	}

	cleanDeleteOperation() {
		dispatchNoPayload('ALERTS_DELETE_MODAL_CLOSE');

		this.alertID = '';
	}

	// Restore
	restoreAlert(id) {
		dispatchNoPayload('ALERTS_RESTORING');
		// axios
		//   .post(
		//     ALERTS_API + `/${id}/restore`,
		//     {},
		//     {
		//       headers: {
		//         Authorization: 'Bearer ' + localStorage.getItem('token'),
		//       },
		//     }
		//   )
		postData(ALERTS_API, `/${id}/restore`)
			.then((response) => {
				dispatchNoPayload('ALERTS_RESTORED');
				// handleSuccess(response);
				generateSuccessMessage(response, 'Success');
				this.getFilteredAlerts(this.props.pageNumber);
				this.cleanRestoreOperation();
			})
			.catch((error) => {
				dispatchNoPayload('ALERTS_RESTORED');
				datadogLogs.logger.error('Alerts restore api', {}, error);
				handleError(error);
			});
	}

	cleanRestoreOperation() {
		dispatchNoPayload('ALERTS_RESTORE_MODAL_CLOSE');
		this.alertID = '';
	}

	sort(param, activeSort, sortOrder) {
		if (!param) {
			this.activeSort = param;
			this.sortOrder = '';
		} else {
			if (activeSort !== param) {
				this.sortOrder = 'asc';
				this.activeSort = param;
			} else {
				if (sortOrder === 'asc') {
					this.sortOrder = 'desc';
				} else {
					this.sortOrder = 'asc';
				}
			}
		}

		dispatch('ALERTS_SORT_ORDER', this.sortOrder);
		dispatch('ALERTS_ACTIVE_ORDER', this.activeSort);
		this.getFilteredAlerts(this.props.pageNumber);
	}

	onOrganisationsFilterChange(e, { value }) {
		let query;
		if (typeof value === 'number') query = `?organisations[]=${value}`;
		else query = `?organisations[]=${value.join('&organisations[]=')}`;
		query += '&dropdown=true';
		getUsersForDropdown(query).then((response) => this.setState({ allUsers: response }));
		// this.setState({
		// 	filters: { ...this.state.filters, organisations: value },
		// });
		dispatch('ACTIVE_FILTER', { ...this.props.filters, organisations: value });
	}

	onUsersFilterChange(e, { value }) {
		this.setState({
			filters: { ...this.state.filters, users: value },
		});
	}

	onEventTypesFilterChange(e, { value }) {
		this.setState({
			filters: { ...this.state.filters, eventTypes: value },
		});
	}

	onActiveDaysFilterChange(e, { value }) {
		this.setState({
			filters: { ...this.state.filters, activeDays: value },
		});
	}

	onIsEnabledFilterChange(e, { value }) {
		// if (value === '') {
		// 	this.setState({ filters: { ...this.state.filters, isEnabled: null } });
		// 	return;
		// }
		this.setState({
			filters: { ...this.state.filters, isEnabled: value === '' ? null : !!value },
		});
	}

	setFilterChange(organisations, users, eventTypes, activeDays, isEnabled) {
		this.setState({
			filters: {
				organisations,
				users,
				eventTypes,
				activeDays,
				isEnabled,
			},
		});
	}

	isFilterActive() {
		return (
			this.activeFilters.organisations.length ||
			this.activeFilters.users.length ||
			this.activeFilters.eventTypes.length ||
			this.activeFilters.activeDays.length ||
			this.activeFilters.isEnabled !== null
		);
	}

	// Pagination
	handleDataSizeLimitChange() {
		this.getFilteredAlerts();
	}

	handlePageChange(activePage) {
		this.getFilteredAlerts(activePage);
		this.cleanSelectedRows();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.alerts !== this.props.alerts) {
			if (this.selectAllActive) this.handleSelectAll(true);
		}

		if (prevProps.loading !== this.props.loading) {
			if (!this.props.loading) {
				this.setState({
					initialLoading: false,
				});
			}
		}

		if (prevProps.pageNumber !== this.props.pageNumber) {
			document.getElementById('AccordionMain').scrollTo(0, 0);
		}
	}

	cleanSelectedRows() {
		this.setState({
			anyRowSelected: false,
			selectedRows: new Set(),
		});
	}

	componentWillUnmount() {
		dispatchNoPayload('ALERTS_UNMOUNT');
	}

	render() {
		return (
			<CategoryContentMembrane>
				<CategoryHeader>
					<SearchBox
						placeholder="Search"
						searchQuery={this.searchQuery}
						clearSearchIconClick={() => {
							this.searchQuery = '';
							this.getFilteredAlerts(this.props.pageNumber);

							dispatch('UPDATE_SEARCH_QUERY', '');
						}}
						value={this.props.searchQuery}
						handleSearch={this.handleSearch}
						// disabled={!this.searchQuery && (this.props.loading || this.props.exportingData)}
					/>
					<Button
						icon
						primary
						title="Refresh Alerts"
						disabled={this.props.loading || this.props.exportingData}
						onClick={() => this.getFilteredAlerts(this.props.pageNumber)}>
						<Icon name="refresh" />
					</Button>
					<Expander />

					<Button
						primary={!this.isFilterActive()}
						icon
						disabled={this.props.loading || this.props.exportingData}
						color={this.isFilterActive() ? 'orange' : null}
						title="Filter Alerts"
						onClick={openFilterModal}>
						<Icon name="filter" />
					</Button>

					{/*Add*/}
					{!this.state.deletedMode && (
						<>
							<Button
								primary
								title="Add Alert"
								disabled={
									this.props.loading ||
									this.props.exportingData ||
									!(this.state.manageAccess || this.state.createAccess)
								}
								onClick={() => {
									trackMixpanelEvent('Alerts', { Action: 'Add' });
									dispatchNoPayload('ALERTS_ADD_MODAL_OPEN');
									getOrganisationsForDropdown('?dropdown=true').then((response) => {
										this.setState({ organisations: response });
									});
									getEventTypesForDropdown().then((response) => {
										this.setState({ eventTypes: response });
									});
								}}>
								Add
							</Button>
						</>
					)}

					{/*Export*/}

					{/*<Button*/}
					{/*	primary*/}
					{/*	disabled={*/}
					{/*		!this.state.anyRowSelected || this.props.loading || !this.props.alerts.length || this.props.exportingData*/}
					{/*	}*/}
					{/*	title={!this.state.anyRowSelected ? 'Please select at least one alert to export' : 'Export Alerts'}*/}
					{/*	onClick={() => {*/}
					{/*		openExportModal();*/}
					{/*		dispatch('SET_ALERTS_EXPORT_DATA', this.export());*/}
					{/*	}}>*/}
					{/*	Export*/}
					{/*</Button>*/}

					{/*Export All*/}

					{/*<Button.Group>*/}
					{/*	<Button*/}
					{/*		primary*/}
					{/*		title="Export All Alerts"*/}
					{/*		loading={this.props.exportingData}*/}
					{/*		disabled={this.props.loading || !this.props.alerts.length || this.props.exportingData}*/}
					{/*		onClick={() => exportAll(this.getFilterListQuery(), this.state.fileExtension)}>*/}
					{/*		Export All*/}
					{/*	</Button>*/}
					{/*	<Dropdown*/}
					{/*		className="button icon"*/}
					{/*		style={{ background: "#ffffff", color: "#1b3c71", borderColor: "#1b3c71" }}*/}
					{/*		floating*/}
					{/*		value={this.state.fileExtension}*/}
					{/*		options={FILE_TYPE_EXTENSIONS}*/}
					{/*		onChange={this.state.fileExtensionChange}*/}
					{/*		trigger={<></>}*/}
					{/*	/>*/}
					{/*</Button.Group>*/}
					{this.isSuperAdminAccess === 'true' && (
						<ToggleSwitch
							title={this.deletedMode ? 'Hide Deleted Alerts' : 'Show Deleted Alerts'}
							label="Deleted Mode"
							checked={this.deletedMode}
							disabled={this.props.loading || this.props.exportingData}
							action={this.toggleDeletedMode}
						/>
					)}
				</CategoryHeader>

				<CategoryMain>
					<AccordionHeader background={this.deletedMode}>
						{/*<HeaderElement*/}
						{/*	width="3%"*/}
						{/*	disabled={this.props.loading || this.props.exportingData || !this.props.alerts.length}>*/}
						{/*	<CheckboxContainer>*/}
						{/*		<Checkbox*/}
						{/*			checked={this.props.alerts.length !== 0 && this.state.selectedRows.size === this.props.alerts.length}*/}
						{/*			indeterminate={*/}
						{/*				this.state.selectedRows.size !== 0 && this.state.selectedRows.size !== this.props.alerts.length*/}
						{/*			}*/}
						{/*			onClick={(e, data) => {*/}
						{/*				this.handleSelectAll(data.checked);*/}
						{/*			}}*/}
						{/*		/>*/}
						{/*	</CheckboxContainer>*/}
						{/*</HeaderElement>*/}
						<HeaderElement
							width="4%"
							disabled={this.props.loading || this.props.exportingData || !this.props.alerts.length}
							onClick={() => {
								this.sort('', '', '');
							}}>
							No.
						</HeaderElement>
						<HeaderElement
							width="11%"
							onClick={() => {
								this.sort('name', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.alerts.length}
							activeSort={this.activeSort === 'name'}>
							{this.props.activeSort === 'name' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Name
						</HeaderElement>
						<HeaderElement
							width="11%"
							onClick={() => {
								this.sort('description', this.props.activeSort, this.props.sortOrder);
							}}
							inActive={true}
							disabled={this.props.loading || this.props.exportingData || !this.props.alerts.length}
							activeSort={this.activeSort === 'description'}>
							{this.props.activeSort === 'description' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Description
						</HeaderElement>
						<HeaderElement
							width="11%"
							inActive={true}
							onClick={() => {
								this.sort('type', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.alerts.length}
							activeSort={this.activeSort === 'type'}>
							{this.props.activeSort === 'type' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Type
						</HeaderElement>
						<HeaderElement
							width="12%"
							onClick={() => {
								this.sort('activeDays', this.props.activeSort, this.props.sortOrder);
							}}
							inActive={true}
							disabled={this.props.loading || this.props.exportingData || !this.props.alerts.length}
							activeSort={this.activeSort === 'activeDays'}>
							{this.props.activeSort === 'activeDays' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Active Days
						</HeaderElement>
						<HeaderElement
							width="11%"
							onClick={() => {
								this.sort('activeStartTime', this.props.activeSort, this.props.sortOrder);
							}}
							inActive={true}
							disabled={this.props.loading || this.props.exportingData || !this.props.alerts.length}
							activeSort={this.activeSort === 'activeStartTime'}>
							{this.props.activeSort === 'activeStartTime' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Active Time
						</HeaderElement>
						<HeaderElement
							width="8%"
							disabled={this.props.loading || this.props.exportingData || !this.props.alerts.length}
							onClick={() => {
								this.sort('isEnabled', this.props.activeSort, this.props.sortOrder);
							}}
							activeSort={this.activeSort === 'isEnabled'}>
							{this.props.activeSort === 'isEnabled' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Enabled
						</HeaderElement>
						<HeaderElement
							width="13%"
							disabled={this.props.loading || this.props.exportingData || !this.props.alerts.length}
							onClick={() => {
								this.sort('organisation', this.props.activeSort, this.props.sortOrder);
							}}
							activeSort={this.activeSort === 'organisation'}>
							{this.props.activeSort === 'organisation' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Organisation
						</HeaderElement>
						<HeaderElement
							width="11%"
							inActive={true}
							disabled={this.props.loading || this.props.exportingData || !this.props.alerts.length}>
							Users
						</HeaderElement>

						{this.deletedMode && (
							<HeaderElement
								width="9%"
								disabled={this.props.loading || this.props.exportingData || !this.props.alerts.length}>
								Deleted date
							</HeaderElement>
						)}
					</AccordionHeader>

					<AccordionMain id="AccordionMain">
						{this.state.initialLoading ? (
							<BasicLoader />
						) : this.props.alerts.length ? (
							<Accordion fluid>
								{this.props.alerts.map((alert, index) => {
									return (
										<section key={index}>
											{/*Accordion Title*/}
											<Accordion.Title
												active={this.props.activeIndex === index}
												index={index}
												onClick={(event, data) => {
													toggleAccordion(event, data, this.props.activeIndex);
													getUsersForDropdown(`?organisations[]=${alert.organisation.id}&dropdown=true`).then(
														(response) => {
															this.setState({ users: response });
														}
													);
													this.cleanEditOperation();
												}}
												style={{ padding: 0 }}>
												<Row>
													{/*<RowElement width="3%">*/}
													{/*	<CheckboxContainer>*/}
													{/*		<Checkbox*/}
													{/*			checked={this.state.selectedRows.has(alert.id)}*/}
													{/*			onChange={(e, data) => {*/}
													{/*				e.stopPropagation();*/}
													{/*				this.handleExportSelection(data.checked, alert.id);*/}
													{/*			}}*/}
													{/*		/>*/}
													{/*	</CheckboxContainer>*/}
													{/*</RowElement>*/}
													<RowElement width="4%">
														{1 + index + (this.props.pageNumber - 1) * this.props.dataSizeLimit}
													</RowElement>
													<RowElement
														width="11%"
														style={{ color: `${alert.name ? 'black' : 'grey'}` }}
														title={alert.name}>
														{alert.name ? alert.name : 'Not Available'}
													</RowElement>
													<RowElement
														width="11%"
														style={{ color: `${alert.description ? 'black' : 'grey'}` }}
														title={alert.description}>
														{alert.description ? alert.description : 'Not Available'}
													</RowElement>
													<RowElement
														width="11%"
														style={{
															textOverflow: 'ellipsis',
															overflow: 'hidden',
															whiteSpace: 'nowrap',
															color: `${alert.type ? 'black' : 'grey'}`,
														}}
														title={alert.type.join(', ')}
														wrap="true">
														{/*{alert.type.map((alertType) => (*/}
														{/*  <Label size="small" style={{marginBottom: '.25em'}}>*/}
														{/*    {console.log('alertType', alertType)}*/}

														{/*    {alertType}*/}
														{/*  </Label>*/}
														{/*))}*/}
														{alert.type.join(',') ? alert.type.join(', ') : 'Not Available'}
													</RowElement>
													<RowElement
														width="12%"
														title={alert.activeDays.join(', ')}
														style={{
															textOverflow: 'ellipsis',
															overflow: 'hidden',
															whiteSpace: 'nowrap',
														}}
														wrap="true">
														{this.days.map((day, index) => {
															if (alert.activeDays.includes(day))
																return (
																	<span
																		key={index}
																		style={{
																			fontWeight: 'bold',
																			paddingLeft: '0.5em',
																		}}>
																		{day[0]}
																	</span>
																);
															else
																return (
																	<span key={index} style={{ color: 'grey', paddingLeft: '0.5em' }}>
																		{day[0]}
																	</span>
																);
														})}
													</RowElement>
													<RowElement width="11%" title={`${alert.activeStartTime} - ${alert.activeEndTime}`}>
														{`${alert.activeStartTime} - ${alert.activeEndTime}`}
													</RowElement>
													<RowElement width="8%">
														{alert.isEnabled ? (
															<Label size="medium" color="green">
																Yes
															</Label>
														) : (
															<Label size="medium" color="red">
																No
															</Label>
														)}
													</RowElement>
													<RowElement width="13%" title={alert.organisation.name}>
														{alert.organisation.name ? alert.organisation.name : 'Not Available'}
													</RowElement>
													<RowElement
														width="11%"
														style={{
															textOverflow: 'ellipsis',
															overflow: 'hidden',
															whiteSpace: 'nowrap',
														}}
														title={alert.users.map((user) => user.name).join(', ')}
														wrap="true">
														{alert.users.map((user) => user.name).join(',')
															? alert.users.map((user) => user.name).join(',')
															: 'Not Available'}
													</RowElement>
													{this.deletedMode && (
														<RowElement width="9%" title={alert.deletedDate}>
															{alert.deletedDate ? alert.deletedDate : 'Not Available'}
														</RowElement>
													)}
												</Row>
											</Accordion.Title>

											{/*Accordion Content*/}
											{this.props.activeIndex === index && (
												<Accordion.Content active={this.props.activeIndex === index}>
													<AccordionMainContent>
														<Form>
															<Form.Group widths="equal">
																<Form.Field required>
																	<label>Name</label>
																	<Input
																		fluid
																		value={
																			this.state.nameFE !== null
																				? this.state.nameFE
																				: alert.name === null
																				? ''
																				: alert.name
																		}
																		disabled={!this.props.editMode}
																		// onChange={(e) => this.setState({ nameFE: e.target.value })}
																		onChange={this.onNameChangeFE}
																	/>
																</Form.Field>
																<Form.Field>
																	<label>Description</label>
																	<Input
																		fluid
																		value={
																			this.state.descriptionFE !== null
																				? this.state.descriptionFE
																				: alert.description === null
																				? ''
																				: alert.description
																		}
																		disabled={!this.props.editMode}
																		onChange={(e) => this.setState({ descriptionFE: e.target.value })}
																	/>
																</Form.Field>
															</Form.Group>
															<Form.Group widths="equal">
																<Form.Field required>
																	<label>Organisation</label>
																	<Dropdown
																		fluid
																		search
																		selection
																		value={this.state.organisationFE || alert.organisation.id}
																		options={this.state.organisations}
																		onChange={this.onOrganisationChooseFE}
																		disabled
																	/>
																</Form.Field>
																<Form.Field required>
																	<label>Users</label>
																	<Dropdown
																		fluid
																		search
																		selection
																		multiple
																		disabled={!this.props.editMode}
																		value={
																			this.state.usersFE !== null
																				? this.state.usersFE
																				: alert.users.map((user) => user.id)
																		}
																		options={this.state.users}
																		onChange={this.onUserChooseFE}
																	/>
																</Form.Field>
															</Form.Group>
															<Form.Group widths="equal">
																<Form.Field required>
																	<label>Alert Type</label>
																	<Dropdown
																		fluid
																		search
																		selection
																		multiple
																		value={this.state.eventTypesFE !== null ? this.state.eventTypesFE : alert.type}
																		options={this.state.eventTypes}
																		onChange={this.onEventTypesChangeFE}
																		disabled={!this.props.editMode}
																	/>
																</Form.Field>
																<Form.Field>
																	<label>Active Days</label>
																	<Dropdown
																		fluid
																		search
																		selection
																		multiple
																		value={
																			this.state.activeDaysFE !== null ? this.state.activeDaysFE : alert.activeDays
																		}
																		options={getActiveDaysForDropdown()}
																		onChange={this.onActiveDaysChangeFE}
																		disabled={!this.props.editMode}
																	/>
																</Form.Field>
															</Form.Group>
															<Form.Group widths="equal">
																<Form.Field>
																	<label>Active Start Time</label>
																	<TimeInput
																		name="activeStartTimeFE"
																		closable
																		value={this.state.activeStartTimeFE || alert.activeStartTime}
																		iconPosition="left"
																		onChange={this.handleActiveTimeChange}
																		disabled={!this.props.editMode}
																	/>
																</Form.Field>
																<Form.Field>
																	<label>Active End Time</label>
																	<TimeInput
																		name="activeEndTimeFE"
																		closable
																		value={this.state.activeEndTimeFE || alert.activeEndTime}
																		iconPosition="left"
																		onChange={this.handleActiveTimeChange}
																		disabled={!this.props.editMode}
																	/>
																</Form.Field>
															</Form.Group>
															<Form.Group widths="equal">
																<Form.Field>
																	<section
																		style={{
																			width: '50%',
																			display: 'flex',
																			marginTop: '2em',
																		}}>
																		<label style={{ fontWeight: 'bold' }}>Enabled</label>
																		<Expander />
																		<RolesToggle>
																			<Checkbox
																				toggle
																				onChange={(e, data) => this.onIsEnabledChangeFE(data)}
																				checked={
																					this.state.isEnabledFE === null ? alert.isEnabled : this.state.isEnabledFE
																				}
																				disabled={!this.props.editMode}
																			/>
																		</RolesToggle>
																	</section>
																</Form.Field>
															</Form.Group>
														</Form>

														{this.state.deletedMode ? (
															<Actions>
																<Button
																	primary
																	disabled={!(this.state.manageAccess || this.state.createAccess)}
																	onClick={() => {
																		trackMixpanelEvent('Alerts', { Action: 'Restore' });
																		dispatchNoPayload('ALERTS_RESTORE_MODAL_OPEN');
																		this.alertID = alert.id;
																	}}>
																	Restore
																</Button>
															</Actions>
														) : this.props.editMode ? (
															<Actions>
																<Button secondary onClick={this.cleanEditOperation}>
																	Cancel
																</Button>
																<Button
																	positive
																	loading={this.props.updating}
																	onClick={() => {
																		trackMixpanelEvent('Alerts', { Action: 'Update' });
																		this.update(alert.id);
																	}}>
																	Update
																</Button>
															</Actions>
														) : (
															<Actions>
																<section>
																	<Button
																		primary
																		disabled={!(this.state.manageAccess || this.state.updateAccess)}
																		onClick={() => {
																			trackMixpanelEvent('Alerts', { Action: 'Edit' });
																			dispatchNoPayload('ALERTS_EDIT_MODAL_OPEN');
																			getOrganisationsForDropdown('?dropdown=true').then((response) => {
																				this.setState({ organisations: response });
																			});
																			getEventTypesForDropdown().then((response) => {
																				this.setState({ eventTypes: response });
																			});
																		}}>
																		Edit
																	</Button>
																	<Button
																		negative
																		disabled={!(this.state.manageAccess || this.state.deleteAccess)}
																		onClick={() => {
																			trackMixpanelEvent('Alerts', { Action: 'Delete' });
																			dispatchNoPayload('ALERTS_DELETE_MODAL_OPEN');
																			this.alertID = alert.id;
																		}}>
																		Delete
																	</Button>
																</section>
															</Actions>
														)}
													</AccordionMainContent>
												</Accordion.Content>
											)}
										</section>
									);
								})}
							</Accordion>
						) : (
							<PlaceHolder />
						)}
					</AccordionMain>
				</CategoryMain>

				<CategoryFooter background={this.deletedMode}>
					<FooterPagination
						handleDataSizeLimitChange={this.handleDataSizeLimitChange}
						pageNumber={this.props.pageNumber}
						handlePageChange={this.handlePageChange}
						count={this.props.count}
						disabled={this.props.loading || !this.props.alerts.length || this.props.exportingData}
					/>
				</CategoryFooter>

				{this.props.loading && !this.state.initialLoading && <TransitionLoader />}

				{/*Add Modal*/}
				{this.props.addModalOpen && (
					<AddModal
						onClose={this.cleanAddOperation}
						open={this.props.addModalOpen}
						onNameChange={this.onNameChange}
						onDescriptionChange={this.onDescriptionChange}
						organisationOptions={this.state.organisations}
						selectedOrganisation={this.state.organisationFA}
						onOrganisationChoose={this.onOrganisationChoose}
						usersOptions={this.state.users}
						selectedUser={this.state.usersFA}
						onUserChoose={this.onUserChoose}
						eventTypesOptions={this.state.eventTypes}
						selectedEventTypes={this.state.eventTypesFA}
						onEventTypesChange={this.onEventTypesChange}
						activeDaysOptions={getActiveDaysForDropdown()}
						selectedActiveDays={this.state.activeDaysFA}
						onActiveDaysChange={this.onActiveDaysChange}
						activeStartTime={this.state.activeStartTimeFA}
						activeEndTime={this.state.activeEndTimeFA}
						handleActiveTimeChange={this.handleActiveTimeChange}
						isEnabled={this.state.isEnabledFA}
						onIsEnabledChange={this.onIsEnabledChange}
						disabled={
							!this.state.nameFA ||
							this.state.users.length === 0 ||
							this.state.eventTypesFA.length === 0 ||
							!this.state.organisationFA
						}
						onCancelClick={this.cleanAddOperation}
						onAddButtonClick={this.add}
						addButtonLoading={this.props.adding}
					/>
				)}

				{/*Delete Modal*/}
				{this.props.deleteModalOpen && (
					<DeleteModal
						onClose={this.cleanDeleteOperation}
						open={this.props.deleteModalOpen}
						onCancelClick={this.cleanDeleteOperation}
						onDeleteButtonClick={() => {
							this.deleteAlert(this.alertID);
						}}
						deleteButtonLoading={this.props.deleting}
					/>
				)}

				{/*Restore Modal*/}
				{this.props.restoreModal && (
					<RestoreModal
						onClose={this.cleanRestoreOperation}
						open={this.props.restoreModal}
						onCancelClick={this.cleanRestoreOperation}
						onRestoreButtonClick={() => {
							this.restoreAlert(this.alertID);
						}}
						restoreButtonLoading={this.props.restoring}
					/>
				)}

				{/*Filter Modal*/}

				{this.props.filterModalOpen && (
					<FilterModal
						onClose={() => {
							this.setFilterChange(
								this.activeFilters.organisations,
								this.activeFilters.users,
								this.activeFilters.eventTypes,
								this.activeFilters.activeDays,
								this.activeFilters.isEnabled
							);
							closeFilterModal();
							dispatch('ACTIVE_FILTER', { ...this.props.filters, organisations: [] });
						}}
						open={this.props.filterModalOpen}
						organisationsOptions={this.state.organisations}
						organisations={this.props.filters.organisations}
						onOrganisationsFilterChange={this.onOrganisationsFilterChange}
						usersOptions={this.state.allUsers}
						users={this.state.filters.users}
						onUsersFilterChange={this.onUsersFilterChange}
						eventTypesOptions={this.state.eventTypes}
						eventTypes={this.state.filters.eventTypes}
						onEventTypesFilterChange={this.onEventTypesFilterChange}
						activeDaysOptions={getActiveDaysForDropdown()}
						activeDays={this.state.filters.activeDays}
						onActiveDaysFilterChange={this.onActiveDaysFilterChange}
						isEnabledOptions={getIsEnabledForDropdown()}
						isEnabled={this.state.filters.isEnabled}
						onIsEnabledFilterChange={this.onIsEnabledFilterChange}
						onCancelClick={() => {
							this.setFilterChange(
								this.activeFilters.organisations,
								this.activeFilters.users,
								this.activeFilters.eventTypes,
								this.activeFilters.activeDays,
								this.activeFilters.isEnabled
							);
							closeFilterModal();
							dispatch('ACTIVE_FILTER', { ...this.props.filters, organisations: [] });
						}}
						onResetClick={() => {
							this.activeFilters = {
								organisations: [],
								users: [],
								eventTypes: [],
								activeDays: [],
								isEnabled: null,
							};
							this.setFilterChange(
								this.activeFilters.organisations,
								this.activeFilters.users,
								this.activeFilters.eventTypes,
								this.activeFilters.activeDays,
								this.activeFilters.isEnabled
							);
							// closeFilterModal();
							dispatch('ACTIVE_FILTER', { ...this.props.filters, organisations: [] });
							this.getFilteredAlerts();
						}}
						onApplyClick={() => {
							trackMixpanelEvent('Alerts', { Action: 'Filter' });
							this.activeFilters = this.state.filters;
							this.activeFilters.organisations = this.props.filters.organisations;
							this.getFilteredAlerts();
							closeFilterModal();
						}}
						disabled={
							!this.props.filters.organisations.length &&
							!this.state.filters.users.length &&
							!this.state.filters.eventTypes.length &&
							!this.state.filters.activeDays.length &&
							this.state.filters.isEnabled === null
						}
					/>
				)}

				{/*Export Modal*/}
				{this.props.openExportModal && (
					<ExportModal
						open={this.props.openExportModal}
						fileName={this.state.fileName}
						fileNameChange={this.fileNameChange}
						fileExtension={this.state.fileExtension}
						fileExtensionChange={this.fileExtensionChange}
						onDownloadClick={() => exportData(this.props.exportData, this.state.fileName, this.state.fileExtension)}
					/>
				)}
			</CategoryContentMembrane>
		);
	}
}

// Connect to Store
const mapStateToProps = (state) => ({
	alerts: state.alerts.alerts,
	loading: state.alerts.loading,
	activeIndex: state.alerts.activeIndex,
	count: state.alerts.count,
	pageNumber: state.alerts.pageNumber,
	dataSizeLimit: state.ui.dataSizeLimit,

	adding: state.alerts.adding,
	addModalOpen: state.alerts.addModalOpen,

	editMode: state.alerts.editMode,
	updating: state.alerts.updating,

	deleteModalOpen: state.alerts.deleteModalOpen,
	deleting: state.alerts.deleting,

	activeSort: state.alerts.activeSort,
	sortOrder: state.alerts.sortOrder,

	restoreModal: state.alerts.restoreModal,
	restoring: state.alerts.restoring,

	importModalOpen: state.alerts.importModalOpen,
	filterModalOpen: state.alerts.filterModalOpen,
	changeHistoryModal: state.alerts.changeHistoryModal,

	openExportModal: state.alerts.openExportModal,
	exportData: state.alerts.exportData,
	searchQuery: state.vehicles.searchQuery,
	exportingData: state.alerts.exportingData,
	filters: state.vehicles.filters,
});

export default connect(mapStateToProps)(Alerts);
