export default function deviceModels(
	initialState = {
		deviceModels: [],
		loading: true,
		pageNumber: 1,
		count: 0,
		activeIndex: -1,
		deleting: false,
		deleteModalOpen: false,
		activeSort: '',
		sortOrder: '',
		adding: false,
		addModalOpen: false,
		updating: false,
		editMode: false,
		restoreModal: false,
		restoring: false,

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
	},
	action
) {
	switch (action.type) {
		case 'FEED_DEVICE_MODELS':
			return {
				...initialState,
				deviceModels: action.payload,
				loading: false,
			};

		case 'DEVICE_MODELS_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'DEVICE_MODELS_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'DEVICE_MODELS_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'DEVICE_MODELS_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'DEVICE_MODELS_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'DEVICE_MODELS_DELETING':
			return {
				...initialState,
				deleting: true,
			};

		case 'DEVICE_MODELS_DELETED':
			return {
				...initialState,
				deleting: false,
			};

		case 'DEVICE_MODELS_DELETE_MODAL_OPEN':
			return {
				...initialState,
				deleteModalOpen: true,
			};

		case 'DEVICE_MODELS_DELETE_MODAL_CLOSE':
			return {
				...initialState,
				deleteModalOpen: false,
			};

		case 'DEVICE_MODELS_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'DEVICE_MODELS_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'DEVICE_MODELS_ADDING':
			return {
				...initialState,
				adding: true,
			};

		case 'DEVICE_MODELS_ADDED':
			return {
				...initialState,
				adding: false,
			};

		case 'DEVICE_MODELS_ADD_MODAL_OPEN':
			return {
				...initialState,
				addModalOpen: true,
			};

		case 'DEVICE_MODELS_ADD_MODAL_CLOSE':
			return {
				...initialState,
				addModalOpen: false,
			};

		case 'DEVICE_MODELS_UPDATING':
			return {
				...initialState,
				updating: true,
			};

		case 'DEVICE_MODELS_UPDATED':
			return {
				...initialState,
				updating: false,
			};

		case 'DEVICE_MODELS_EDIT_MODAL_OPEN':
			return {
				...initialState,
				editMode: true,
			};

		case 'DEVICE_MODELS_EDIT_MODAL_CLOSE':
			return {
				...initialState,
				editMode: false,
			};

		case 'DEVICE_MODELS_RESTORE_MODAL_OPEN':
			return {
				...initialState,
				restoreModal: true,
			};

		case 'DEVICE_MODELS_RESTORE_MODAL_CLOSE':
			return {
				...initialState,
				restoreModal: false,
			};

		case 'DEVICE_MODELS_RESTORING':
			return {
				...initialState,
				restoring: true,
			};

		case 'DEVICE_MODELS_RESTORED':
			return {
				...initialState,
				restoring: false,
			};

		case 'DEVICE_MODELS_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'DEVICE_MODELS_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_DEVICE_MODELS_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_DEVICE_MODELS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_DEVICE_MODELS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'DEVICE_MODELS_UNMOUNT':
			return {
				...initialState,
				deviceModels: [],
				activeSort: '',
				sortOrder: '',
				activeIndex: -1,
				pageNumber: 1,
			};

		default:
			return initialState;
	}
}
