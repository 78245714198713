export const INVOICES_SEARCH_DROPDOWN_OPTIONS = [
	{
		key: 1,
		text: 'Doc Number',
		value: 'DocNumber',
	},
];

export const ESTIMATES_SEARCH_DROPDOWN_OPTIONS = [
	{
		key: 1,
		text: 'Doc Number',
		value: 'DocNumber',
	},
];

export const ITEMS_SEARCH_DROPDOWN_OPTIONS = [
	{
		key: 1,
		text: 'Name',
		value: 'Name',
	},
	{
		key: 2,
		text: 'SKU',
		value: 'Sku',
	},
];

export const ACCOUNT_ADMIN_DROPDOWN_OPTIONS = [
	{
		key: 1,
		text: 'Account Admin',
		value: 'Account Admin',
		label: { color: 'orange', content: 'Company' },
	},
];

export const ADMIN_DROPDOWN_OPTIONS = [
	{
		key: 1,
		text: 'Admin',
		value: 'Admin',
		label: { color: 'purple', content: 'TrackNerd' },
	},
];

export const CUSTOMER_SEARCH_DROPDOWN_OPTIONS = [
	{
		key: 1,
		text: 'Name',
		value: 'DisplayName',
	},
	{
		key: 2,
		text: 'Company Name',
		value: 'CompanyName',
	},
	{
		key: 3,
		text: 'Email',
		value: 'PrimaryEmailAddr',
	},
];

// Pagination
export const PAGE_COUNT_OPTIONS = [
	{
		key: 0,
		text: 25,
		value: 25,
	},
	{
		key: 1,
		text: 50,
		value: 50,
	},
	{
		key: 2,
		text: 100,
		value: 100,
	},
	{
		key: 3,
		text: 300,
		value: 300,
	},
	{
		key: 4,
		text: 500,
		value: 500,
	},
	{
		key: 5,
		text: 1000,
		value: 1000,
	},
];

export const VEHICLE_TYPE = {
	Car: 1,
	Bike: 2,
	Truck: 3,
	Construction: 4,
	'School Bus': 5,
	Generator: 6,
	Container: 7,
	'Mini Truck': 8,
	'Auto Rickshaw': 9,
};

export const SWAP_SIM_REASONS = [
	{ key: 1, value: 'Deactivated', text: 'Deactivated' },
	{ key: 2, value: 'Transfer', text: 'Transfer' },
];

export const REMOVE_SIM_REASONS = [
	{ key: 1, value: 'Deactivated', text: 'Deactivated' },
	{ key: 2, value: 'Transfer', text: 'Transfer' },
];

export const SWAP_DEVICE_REASON = [
	{ key: 1, value: 'No Data', text: 'No Data' },
	{ key: 3, value: 'Physical Damage', text: 'Physical Damage' },
	{ key: 4, value: 'Missing', text: 'Missing' },
];

// Subscription
export const BillingPeriod = [
	{ key: 1, value: 1, text: 'Monthly' },
	{ key: 3, value: 3, text: 'Quarterly' },
	{ key: 6, value: 6, text: 'Half Yearly' },
	{ key: 12, value: 12, text: 'Yearly' },
];

// Recalculation
export const ENTITY = [
	{ key: 2, value: 11, text: 'Distance' },
	{ key: 1, value: 3, text: 'Summary' },
];

// Deactivate Organisation Reasons
export const DEACTIVATE_ORGANISATION_REASONS = [
	{ key: 1, value: 1, text: 'Non Payments' },
	{ key: 2, value: 2, text: 'Customer Request' },
	{ key: 3, value: 3, text: 'Other Reasons' },
];

// Calender
export const HOUR_OPTIONS = [
	{
		key: 1,
		text: '01',
		value: '01',
	},
	{
		key: 2,
		text: '02',
		value: '02',
	},
	{
		key: 3,
		text: '03',
		value: '03',
	},
	{
		key: 4,
		text: '04',
		value: '04',
	},
	{
		key: 5,
		text: '05',
		value: '05',
	},
	{
		key: 6,
		text: '06',
		value: '06',
	},
	{
		key: 7,
		text: '07',
		value: '07',
	},
	{
		key: 8,
		text: '08',
		value: '08',
	},
	{
		key: 9,
		text: '09',
		value: '09',
	},
	{
		key: 10,
		text: '10',
		value: '10',
	},
	{
		key: 11,
		text: '11',
		value: '11',
	},
	{
		key: 12,
		text: '12',
		value: '12',
	},
];
export const MINUTE_OPTIONS = [
	{
		key: 0,
		text: '00',
		value: '00',
	},
	{
		key: 1,
		text: '01',
		value: '01',
	},
	{
		key: 2,
		text: '02',
		value: '02',
	},
	{
		key: 3,
		text: '03',
		value: '03',
	},
	{
		key: 4,
		text: '04',
		value: '04',
	},
	{
		key: 5,
		text: '05',
		value: '05',
	},
	{
		key: 6,
		text: '06',
		value: '06',
	},
	{
		key: 7,
		text: '07',
		value: '07',
	},
	{
		key: 8,
		text: '08',
		value: '08',
	},
	{
		key: 9,
		text: '09',
		value: '09',
	},
	{
		key: 10,
		text: '10',
		value: '10',
	},
	{
		key: 11,
		text: '11',
		value: '11',
	},
	{
		key: 12,
		text: '12',
		value: '12',
	},
	{
		key: 13,
		text: '13',
		value: '13',
	},
	{
		key: 14,
		text: '14',
		value: '14',
	},
	{
		key: 15,
		text: '15',
		value: '15',
	},
	{
		key: 16,
		text: '16',
		value: '16',
	},
	{
		key: 17,
		text: '17',
		value: '17',
	},
	{
		key: 18,
		text: '18',
		value: '18',
	},
	{
		key: 19,
		text: '19',
		value: '19',
	},
	{
		key: 20,
		text: '20',
		value: '20',
	},
	{
		key: 21,
		text: '21',
		value: '21',
	},
	{
		key: 22,
		text: '22',
		value: '22',
	},
	{
		key: 23,
		text: '23',
		value: '23',
	},
	{
		key: 24,
		text: '24',
		value: '24',
	},
	{
		key: 25,
		text: '25',
		value: '25',
	},
	{
		key: 26,
		text: '26',
		value: '26',
	},
	{
		key: 27,
		text: '27',
		value: '27',
	},
	{
		key: 28,
		text: '28',
		value: '28',
	},
	{
		key: 29,
		text: '29',
		value: '29',
	},
	{
		key: 30,
		text: '30',
		value: '30',
	},
	{
		key: 31,
		text: '31',
		value: '31',
	},
	{
		key: 32,
		text: '32',
		value: '32',
	},
	{
		key: 33,
		text: '33',
		value: '33',
	},
	{
		key: 34,
		text: '34',
		value: '34',
	},
	{
		key: 35,
		text: '35',
		value: '35',
	},
	{
		key: 36,
		text: '36',
		value: '36',
	},
	{
		key: 37,
		text: '37',
		value: '37',
	},
	{
		key: 38,
		text: '38',
		value: '38',
	},
	{
		key: 39,
		text: '39',
		value: '39',
	},
	{
		key: 40,
		text: '40',
		value: '40',
	},
	{
		key: 41,
		text: '41',
		value: '41',
	},
	{
		key: 42,
		text: '42',
		value: '42',
	},
	{
		key: 43,
		text: '43',
		value: '43',
	},
	{
		key: 44,
		text: '44',
		value: '44',
	},
	{
		key: 45,
		text: '45',
		value: '45',
	},
	{
		key: 46,
		text: '46',
		value: '46',
	},
	{
		key: 47,
		text: '47',
		value: '47',
	},
	{
		key: 48,
		text: '48',
		value: '48',
	},
	{
		key: 49,
		text: '49',
		value: '49',
	},
	{
		key: 50,
		text: '50',
		value: '50',
	},
	{
		key: 51,
		text: '51',
		value: '51',
	},
	{
		key: 52,
		text: '52',
		value: '52',
	},
	{
		key: 53,
		text: '53',
		value: '53',
	},
	{
		key: 54,
		text: '54',
		value: '54',
	},
	{
		key: 55,
		text: '55',
		value: '55',
	},
	{
		key: 56,
		text: '56',
		value: '56',
	},
	{
		key: 57,
		text: '57',
		value: '57',
	},
	{
		key: 58,
		text: '58',
		value: '58',
	},
	{
		key: 59,
		text: '59',
		value: '59',
	},
];

export const STATES = [
	{
		key: 0,
		text: 'Andhra Pradesh',
		value: 'Andhra Pradesh',
	},
	{
		key: 1,
		text: 'Arunachal Pradesh',
		value: 'Arunachal Pradesh',
	},
	{
		key: 2,
		text: 'Assam',
		value: 'Assam',
	},
	{
		key: 3,
		text: 'Bihar',
		value: 'Bihar',
	},
	{
		key: 4,
		text: 'Chhattisgarh',
		value: 'Chhattisgarh',
	},
	{
		key: 5,
		text: 'Dadra and Nagar Haveli',
		value: 'Dadra and Nagar Haveli',
	},
	{
		key: 6,
		text: 'Daman and Diu',
		value: 'Daman and Diu',
	},
	{
		key: 7,
		text: 'Delhi',
		value: 'Delhi',
	},
	{
		key: 8,
		text: 'Goa',
		value: 'Goa',
	},
	{
		key: 9,
		text: 'Gujarat',
		value: 'Gujarat',
	},
	{
		key: 10,
		text: 'Haryana',
		value: 'Haryana',
	},
	{
		key: 11,
		text: 'Himachal Pradesh',
		value: 'Himachal Pradesh',
	},
	{
		key: 12,
		text: 'Jammu and Kashmir',
		value: 'Jammu and Kashmir',
	},
	{
		key: 13,
		text: 'Jharkhand',
		value: 'Jharkhand',
	},
	{
		key: 14,
		text: 'Karnataka',
		value: 'Karnataka',
	},
	{
		key: 15,
		text: 'Kerala',
		value: 'Kerala',
	},
	{
		key: 16,
		text: 'Madhya Pradesh',
		value: 'Madhya Pradesh',
	},
	{
		key: 17,
		text: 'Maharashtra',
		value: 'Maharashtra',
	},
	{
		key: 18,
		text: 'Manipur',
		value: 'Manipur',
	},
	{
		key: 19,
		text: 'Meghalaya',
		value: 'Meghalaya',
	},
	{
		key: 20,
		text: 'Mizoram',
		value: 'Mizoram',
	},
	{
		key: 21,
		text: 'Nagaland',
		value: 'Nagaland',
	},
	{
		key: 22,
		text: 'Orissa',
		value: 'Orissa',
	},
	{
		key: 23,
		text: 'Puducherry',
		value: 'Puducherry',
	},
	{
		key: 24,
		text: 'Punjab',
		value: 'Punjab',
	},
	{
		key: 25,
		text: 'Rajasthan',
		value: 'Rajasthan',
	},
	{
		key: 26,
		text: 'Sikkim',
		value: 'Sikkim',
	},
	{
		key: 27,
		text: 'Tamil Nadu',
		value: 'Tamil Nadu',
	},
	{
		key: 28,
		text: 'Telangana',
		value: 'Telangana',
	},
	{
		key: 29,
		text: 'Tripura',
		value: 'Tripura',
	},
	{
		key: 30,
		text: 'Uttar Pradesh',
		value: 'Uttar Pradesh',
	},
	{
		key: 31,
		text: 'Uttarakhand',
		value: 'Uttarakhand',
	},
	{
		key: 32,
		text: 'West Bengal',
		value: 'West Bengal',
	},
];

export const CUSTOMER_METRICS_FILE_HEADER = [
	{ key: 'Name' },
	{ key: 'Month' },
	{ key: 'CLV' },
	{ key: 'ARPA' },
	{ key: 'MRR' },
	{ key: 'Churned MRR' },
	{ key: 'Expansion MRR' },
	{ key: 'Net MRR' },
	{ key: 'Churned Vehicles' },
	{ key: 'Expansion Vehicles' },
	{ key: 'Net Promoter Score' },
];

export const COMPANY_METRICS_FILE_HEADER = [
	{ key: 'Month' },
	{ key: 'Net MRR' },
	{ key: 'New MRR' },
	{ key: 'Expansion MRR' },
	{ key: 'Churned MRR' },
	{ key: 'MRR Platform' },
	{ key: 'MRR Product' },
	{ key: 'Total Customers' },
	{ key: 'New Customers' },
	{ key: 'Churned Customers' },
	{ key: 'Total Vehicles' },
	{ key: 'New Vehicles' },
	{ key: 'Expansion Vehicles' },
	{ key: 'Churned Vehicles' },
	{ key: 'CLV' },
	{ key: 'CAC' },
	{ key: 'CLV-CAC Ratio' },
	{ key: 'Cogs Platform' },
	{ key: 'Cogs Products' },
	{ key: 'Fixed Expenditure' },
	{ key: 'Variable Expenditure' },
	{ key: 'GM' },
	{ key: 'CM' },
	{ key: 'EBITDA' },
	{ key: 'NM' },
];

// Devices
export const SIM_SERVICE_PROVIDER = [
	{
		key: 0,
		value: 'Airtel',
		text: 'Airtel',
	},
	{
		key: 1,
		value: 'Vodafone',
		text: 'Vodafone',
	},
	{
		key: 2,
		value: 'Bsnl',
		text: 'Bsnl',
	},
];

export const DEVICES_FILE_HEADER = [
	{ key: 'Imei' },
	{ key: 'Serial No.' },
	{ key: 'Status' },
	{ key: 'Manufacturer' },
	{ key: 'Model' },
	{ key: 'Sim1' },
	{ key: 'Sim Provider of 1' },
	{ key: 'Sim2' },
	{ key: 'Sim Provider of 2' },
	{ key: 'Note' },
];

export const VEHICLE_DOCUMENTS_FILE_HEADERS = [
	{
		key: 'Document Type',
	},
	{
		key: 'Vehicle Number',
	},
	{
		key: 'Organisation',
	},
	{
		key: 'Expiry Date',
	},
	{
		key: 'Description',
	},
];

export const USER_DOCUMENTS_FILE_HEADERS = [
	{
		key: 'Document Type',
	},
	{
		key: 'User Name',
	},
	{
		key: 'Organisation',
	},
	{
		key: 'Expiry Date',
	},
	{
		key: 'Description',
	},
];

export const FUELLOGS_FILE_HEADER = [
	{ key: 'Serial No' },
	{ key: 'Organisation' },
	{ key: 'Vehicle' },
	{ key: 'Date' },
	{ key: 'Refuel Quantity' },
	{ key: 'Odometer' },
	{ key: 'Bill Amount' },
	{ key: 'Mileage' },
	{ key: 'Fuel Price' },
	{ key: 'Distance Difference' },
];

export const SIM_TYPES = [
	{
		key: 0,
		value: 'Micro',
		text: 'Micro',
	},
	{
		key: 1,
		value: 'Mini',
		text: 'Mini',
	},
	{
		key: 3,
		value: 'Standard',
		text: 'Standard',
	},
	{
		key: 4,
		value: '3 in 1',
		text: '3 in 1',
	},
];

// Users
export const USERS_TYPES = [
	{
		key: 1,
		value: 1,
		text: 'Default',
	},
	{
		key: 2,
		value: 2,
		text: 'Driver',
	},
];

// People User Roles
export const PEOPLE_USERS_ROLES = [
	{
		key: 1,
		value: 1,
		text: 'Admin',
	},
	{
		key: 2,
		value: 2,
		text: 'Employer',
	},
	{
		key: 3,
		value: 4,
		text: 'Employee',
	},
];

// Saas Plans
export const SAAS_PLANS_PRODUCTS = [
	{ key: 1, value: 1, text: 'GPS' },
	{ key: 2, value: 2, text: 'FMS' },
];

export const SAAS_PLANS_STATUS_DROPDOWN_OPTIONS = [
	{ key: 1, value: 1, text: 'Active' },
	{ key: 2, value: 2, text: 'Customized' },
];

export const SAAS_PLANS_FILE_HEADER = [
	{ key: 'Name' },
	{ key: 'Product' },
	{ key: 'Tenure' },
	{ key: 'Amount' },
	{ key: 'Status' },
	{ key: 'Description' },
];

// Device-Models
export const DEVICE_MODELS_FILE_HEADER = [
	{ key: 'Manufacturer' },
	{ key: 'Model' },
	{ key: 'Protocol' },
	{ key: 'isOBD' },
];

// Device-Commands
export const DEVICE_COMMANDS_FILE_HEADER = [
	{ key: 'Name' },
	{ key: 'Manufacturer' },
	{ key: 'Model' },
	{ key: 'Description' },
	{ key: 'ASCII' },
	{ key: 'Hex' },
];

// Commands Pipeline
export const COMMANDS_PIPELINE_FILE_HEADER = [
	{ key: 'Name' },
	{ key: 'Device' },
	{ key: 'Status' },
	{ key: 'Request Time' },
	{ key: 'Response Time' },
	{ key: 'Response ASCII' },
	{ key: 'Response HEX' },
	{ key: 'Source' },
];

// Sims
export const SIMS_FILE_HEADER = [
	{ key: 'SIM Number' },
	{ key: 'Mobile Number' },
	{ key: 'Service Provider' },
	{ key: 'Plan Name' },
	{ key: 'Rental' },
	{ key: 'APN' },
	{ key: 'Plan Detail' },
	{ key: 'Activation Date' },
	{ key: 'Status' },
];

// Sim Plans
export const SIM_PLANS_FILE_HEADER = [{ key: 'Service Provider' }, { key: 'Plan Name' }, { key: 'Rental' }];

// Vehicle
export const VEHICLES_FILE_HEADER = [
	{ key: 'Registration' },
	{ key: 'Organisation' },
	{ key: 'Device' },
	{ key: 'Immobilizer' },
	{ key: 'Fuel' },
	{ key: 'InstallationDate' },
	{ key: 'Status' },
	{ key: 'Groups Count' },
	{ key: 'Geofence Count' },
	{ key: 'Last Data' },
];

// Trial Vehicle
export const TRIAL_VEHICLES_FILE_HEADER = [
	{ key: 'Registration' },
	{ key: 'Organisation' },
	{ key: 'Installation Date' },
	{ key: 'Days in Trial Period' },
];

// Service Vehicle
export const SERVICE_VEHICLES_FILE_HEADER = [
	{ key: 'Registration' },
	{ key: 'Offline Time' },
	{ key: 'Last Data' },
	{ key: 'Organisation' },
	{ key: 'Phone (Organisation)' },
	{ key: 'Type' },
	{ key: 'Installation Date' },
];

// Vehicle Groups
export const VEHICLE_GROUPS_FILE_HEADER = [
	{ key: 'Name' },
	{ key: 'Organisation' },
	{ key: 'Vehicles' },
	{ Key: 'Users' },
	{ key: 'Description' },
];

// Organisations
export const ORGANISATIONS_FILE_HEADER = [
	{ key: 'Organisation' },
	{ key: 'Email' },
	{ key: 'Phone' },
	// {key: 'Company Address'},
	// {key: 'Website'},
	// {key: 'CIN'},
	// {key: 'GST'},
	// {key: 'PAN'},
	{ key: 'STATE' },
	{ key: 'CITY' },
];

// Trial Organisations
export const TRIAL_ORGANISATIONS_FILE_HEADER = [
	{ key: 'Organisation' },
	{ key: 'Vehicle Count' },
	{ key: 'Phone' },
	{ key: 'Onboarding Date' },
	{ key: 'Days in Trial Period' },
];

// Accounts Overview
export const ACCOUNTS_OVERVIEW_FILE_HEADER = [
	{ key: 'Account' },
	{ key: 'Solution' },
	{ key: 'Last Interaction' },
	{ key: 'Medium of Last Interaction' },
	{ key: 'Outstanding Issues' },
	{ key: 'GPS Count' },
	{ key: 'FMS Count' },
];

// People Organisations
export const PEOPLE_ORGANISATIONS_FILE_HEADER = [
	{ key: 'Company Name' },
	{ key: 'Company Email' },
	{ key: 'Company Phone' },
	{ key: 'Company Address' },
	{ key: 'Website' },
	{ key: 'Login Time' },
	{ key: 'Logout Time' },
	{ key: 'CIN' },
	{ key: 'GST' },
	{ key: 'PAN' },
];

// Users
export const USERS_FILE_HEADER = [
	{ key: 'Name' },
	{ key: 'Type' },
	{ key: 'Phone' },
	{ key: 'Email' },
	{ key: 'Organisation' },
	{ key: 'Role' },
	{ key: 'Vehicle Groups' },
];

// Parents
export const PARENTS_FILE_HEADER = [
	{ key: 'Name' },
	{ key: 'Email' },
	{ key: 'Phone' },
	{ key: 'Organisation' },
	{ key: 'School Bus' },
	{ key: 'Pickup/Drop Point' },
];

// Students
export const STUDENTS_FILE_HEADER = [
	{ key: 'Name' },
	{ key: 'Organisation' },
	{ key: 'PickupBus' },
	{ key: 'PickupPoint' },
	{ key: 'PickupTime' },
	{ key: 'DropBus' },
	{ key: 'DropPoint' },
	{ key: 'DropTime' },
	{ key: 'Parents' },
];

// Users
export const DRIVERS_FILE_HEADER = [
	{ key: 'Name' },
	{ key: 'Email' },
	{ key: 'Phone' },
	{ key: 'Organisation' },
	{ key: 'Vehicle' },
	{ key: 'Role' },
];
// People Users
export const PEOPLE_USERS_FILE_HEADER = [
	{ key: 'Name' },
	{ key: 'Email' },
	{ key: 'Phone' },
	{ key: 'Organisation' },
	{ key: 'Login Time' },
	{ key: 'Logout Time' },
	{ key: 'Role' },
];

// SMS Reports
export const SMS_REPORTS_FILE_HEADER = [
	{ key: 'Sent To' },
	{ key: 'Organisation' },
	{ key: 'Sender' },
	{ key: 'Status' },
	{ key: 'Event Type' },
	{ key: 'Price' },
	{ key: 'Sent Date' },
];

// Document Types
export const DOCUMENT_TYPES_FILE_HEADER = [{ key: 'Name' }, { key: 'Description' }];

// Items
export const ITEMS_FILE_HEADER = [
	{ key: 'Name' },
	{ key: 'SKU' },
	{ key: 'Description' },
	{ key: 'Type' },
	{ key: 'Purchase Cost' },
	{ key: 'Unit Price' },
	{ key: 'Taxable' },
];

// Items
export const POBS_FILE_HEADER = [
	{ key: 'Place Name' },
	{ key: 'City' },
	{ key: 'State' },
	{ key: 'Address' },
	{ key: 'Description' },
];

// Customers
export const CUSTOMERS_FILE_HEADER = [
	{ key: 'Name' },
	{ key: 'Company Name' },
	{ key: 'Email' },
	{ key: 'Phone' },
	{ key: 'Billing Address' },
	{ key: 'Shipping Address' },
];

// Subscriptions
export const SUBSCRIPTIONS_FILE_HEADER = [
	{ key: 'Invoice Number' },
	{ key: 'Registration No' },
	{ key: 'Organisation Name' },
	{ key: 'Status' },
	{ key: 'Subscription Start Date' },
	{ key: 'Subscription End Date' },
	{ key: 'Billing Period' },
	{ key: 'Amount' },
	{ key: 'Note' },
];

// Estimates
export const ESTIMATES_FILE_HEADER = [
	{ key: 'Customer Name' },
	{ key: 'Email' },
	{ key: 'Doc Number' },
	{ key: 'Total Amount' },
	{ key: 'Total Tax' },
	{ key: 'Billing Address' },
	{ key: 'Shipping Address' },
	{ key: 'Item Name' },
	{ key: 'Quantity' },
	{ key: 'Unit Price' },
	{ key: 'Amount' },
	{ key: 'Description' },
];

// Invoices
export const INVOICES_FILE_HEADER = [
	{ key: 'Customer Name' },
	{ key: 'Doc Number' },
	{ key: 'Total Amount' },
	{ key: 'Total Tax' },
	{ key: 'Due Date' },
	{ key: 'Balance' },
	{ key: 'Item Name' },
	{ key: 'Quantity' },
	{ key: 'Unit Price' },
	{ key: 'Amount' },
	{ key: 'Description' },
];

// Sim Bills
export const SIM_BILLS_FILE_HEADER = [
	{ key: 'Invoice' },
	{ key: 'Mobile Number' },
	{ key: 'Billing Start Date' },
	{ key: 'Billing End Date' },
	{ key: 'Sim Number' },
	{ key: 'Service Provider' },
	{ key: 'Device' },
	{ key: 'Vehicle' },
	{ key: 'Amount' },
	{ key: 'Status' },
];

// Vehicle Logs
export const VEHICLE_LOGS_FILE_HEADER = [
	{ key: 'Time' },
	{ key: 'Coordinates (Lat, Lng)' },
	{ key: 'Ignition' },
	{ key: 'GPS' },
	{ key: 'Immobilize' },
	{ key: 'Altitude' },
	{ key: 'Angle' },
	{ key: 'Satellites' },
	{ key: 'Power' },
	{ key: 'External Power' },
	{ key: 'Speed' },
	{ key: 'Distance' },
	{ key: 'Total Odometer' },
	{ key: 'Raw Fuel' },
	{ key: 'GSM Signal' },
];

// Modules
export const ACCESS_MODULES_FILE_HEADER = [{ key: 'Name' }, { key: 'Permissions' }];

// Roles
export const Roles_FILE_HEADER = [
	{ key: 'Name' },
	{ key: 'Organisation' },
	{ key: 'Description' },
	{ key: 'IsSuperAdmin' },
];

// Access Module by Category
export const ACCESS_MODULE_CATEGORY_VIEW = {
	Lokkate: {
		Reports: ['Consignment'],
		Management: ['Geofence', 'Driver', 'Document', 'Notification'],
	},
	CRM: {
		Operations: [
			'Vehicle',
			'User',
			'Organisation',
			'VehicleGroup',
			'Device',
			'DeviceModel',
			'Sim',
			'SimPlan',
			'DeviceCommand',
			'DocumentType',
		],
		'Diagnostics & Analytics': ['SMSReport', 'CommandsPipeline', 'OwnerHistory'],
		Management: ['Role'],
		Finance: ['Subscription', 'SimBill', 'Product', 'Pob'],
	},
};

// Reports
export const REPORTS_FILE_HEADER = [
	{ key: 'Vehicle' },
	{ key: 'Date' },
	{ key: 'First Ignition ON' },
	{ key: 'Distance (KM)' },
	{ key: 'AverageSpeed (KM/H)' },
	{ key: 'Top Speed (KM/H)' },
	{ key: 'EngineHours' },
	{ key: 'MovementHours' },
	{ key: 'IdleHours' },
	{ key: 'Last Ignition OFF' },
];

export const ALERTS_FILE_HEADER = [
	{ key: 'Name' },
	{ key: 'Description' },
	{ key: 'Type' },
	{ key: 'Active Days' },
	{ key: 'Active Time' },
	{ key: 'Enabled' },
	{ key: 'Organisation' },
	{ key: 'Users' },
];
