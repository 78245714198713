export default function auth(
	initialState = {
		loading: false,
		loggedIn: false,
		sendingOTP: false,
		otpSent: false,
		verifyingOTP: false,
		otpVerified: false,
	},
	action
) {
	switch (action.type) {
		case 'LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'LOGIN':
			return {
				...initialState,
				loggedIn: true,
			};

		case 'AUTH_SEND_OTP_REQUEST':
			return {
				...initialState,
				sendingOTP: true,
			};

		case 'AUTH_SEND_OTP_RESOLVE':
			return {
				...initialState,
				sendingOTP: false,
				otpSent: true,
			};

		case 'AUTH_SEND_OTP_REJECT':
			return {
				...initialState,
				sendingOTP: false,
				otpSent: false,
			};

		case 'AUTH_VERIFY_OTP_REQUEST':
			return {
				...initialState,
				verifyingOTP: true,
			};

		case 'AUTH_VERIFY_OTP_RESOLVE':
			return {
				...initialState,
				verifyingOTP: false,
				otpVerified: true,
			};

		case 'AUTH_VERIFY_OTP_REJECT':
			return {
				...initialState,
				verifyingOTP: false,
				otpVerified: false,
			};

		case 'AUTH_RESET':
			return {
				...initialState,
				loading: false,
				sendingOTP: false,
				otpSent: false,
				verifyingOTP: false,
				otpVerified: false,
			};

		case 'LOGOUT':
			return {
				...initialState,
				loggedIn: false,
			};

		default:
			return initialState;
	}
}
