import React from 'react';

import CommonModal from '../../../molecules/modal/CommonModal';

import { SIM_SERVICE_PROVIDER } from '../../../../../../data/constants/general';
import {
	validateImeiNumber,
	validateSimMobileNumber,
	validateSimNumber,
} from '../../../../../../logic/utilities/validator';

import { Button, Dropdown, Form, Input } from 'semantic-ui-react';

const AddModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			header="Add Sim"
			content={
				<Form>
					<Form.Group widths="equal">
						<Form.Field
							required={true}
							control={Input}
							label="Mobile Number"
							error={
								!validateSimMobileNumber(props.mobileNumber) && {
									content: 'mobile number should be 13 digit.',
								}
							}>
							<Input fluid onChange={props.onMobileNumberChange} />
						</Form.Field>
						<Form.Field
							required={true}
							control={Input}
							label="Sim Number"
							error={
								!validateSimNumber(props.simNumber) && {
									content: 'Sim number should be 19 or 20 digit.',
								}
							}>
							<Input fluid onChange={props.onSimNumberChange} />
						</Form.Field>
						<Form.Field
							control={Input}
							label="IMSI Number"
							error={
								!validateImeiNumber(props.imsiNumber) && {
									content: 'Sim imsi number should be 15 digit.',
								}
							}>
							<Input fluid placeholder="Optional" onChange={props.onImsiNumberChange} />
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field required={true}>
							<label>Service Provider</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								selection
								clearable
								options={SIM_SERVICE_PROVIDER}
								onChange={props.onServiceProviderChange}
							/>
						</Form.Field>
						{/*<Form.Field>*/}
						{/*  <label>Sim Type</label>*/}
						{/*  <Dropdown*/}
						{/*    placeholder="Choose..."*/}
						{/*    fluid*/}
						{/*    search*/}
						{/*    selection*/}
						{/*    options={SIM_TYPES}*/}
						{/*    onChange={props.onSimTypeChange}*/}
						{/*  />*/}
						{/*</Form.Field>*/}
						<Form.Field>
							<label>Note</label>
							<Input fluid placeholder="Optional" onChange={props.onNoteChange} />
						</Form.Field>
					</Form.Group>
				</Form>
			}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button loading={props.addButtonLoading} positive disabled={props.disabled} onClick={props.onAddButtonClick}>
						Add
					</Button>
				</>
			}
		/>
	);
};

export default AddModal;
