import React from 'react';
import CommonModal from '../../../molecules/modal/CommonModal';
import { validateEmail, validateMobileNumber, validateWebsite } from '../../../../../../logic/utilities/validator';
import { Button, Dropdown, Form, Input, Label } from 'semantic-ui-react';

const AddModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			header="Add Organisation"
			content={
				<Form>
					<Form.Group widths="equal">
						<Form.Field required>
							<label>Registered Name</label>
							<Input fluid onChange={props.onRegistrationNameChange} />
						</Form.Field>
						<Form.Field style={{ height: '60px' }}>
							<label>Brand Name</label>
							<Form.Input error={props.sameRegistarionBrandName} fluid onChange={props.onBrandNameChange} />
							{props.sameRegistarionBrandName && (
								<Label style={{ zIndex: '5' }} basic color="red" pointing>
									Enter only if your registration name is different from brand name
								</Label>
							)}
						</Form.Field>
						<Form.Field required>
							<label>State</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								selection
								clearable
								value={props.state}
								options={props.stateOptions}
								onChange={props.onStateChangeFA}
							/>
						</Form.Field>
						<Form.Field required>
							<label>City</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								selection
								clearable
								value={props.city}
								disabled={!props.state}
								options={props.cityOptions}
								onChange={props.onCityChangeFA}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field>
							<label>GST</label>
							<Input fluid placeholder="Optional" onChange={props.onGSTChange} />
						</Form.Field>
						<Form.Field>
							<label>PAN</label>
							<Input fluid placeholder="Optional" onChange={props.onPANChange} />
						</Form.Field>
						<Form.Field>
							<label>CIN</label>
							<Input fluid placeholder="Optional" onChange={props.onCINChange} />
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field
							control={Input}
							label="Email"
							error={
								!validateEmail(props.email) && {
									content: 'Email should be valid.',
								}
							}>
							<Input fluid onChange={props.onEmailChange} />
						</Form.Field>
						<Form.Field
							control={Input}
							label="Phone"
							error={
								!validateMobileNumber(props.phone) && {
									content: 'Phone number should be valid.',
								}
							}>
							<Input fluid onChange={props.onPhoneChange} />
						</Form.Field>
						<Form.Field
							control={Input}
							label="Website"
							error={
								!validateWebsite(props.website) && {
									content: 'Website should be valid.',
								}
							}>
							<Input fluid placeholder="Optional" onBlur={props.onWebsiteChange} />
						</Form.Field>
					</Form.Group>
					{/*<Form.Group widths="equal">*/}
					{/*  <Form.Field>*/}
					{/*    <label>Industry</label>*/}
					{/*    <Dropdown*/}
					{/*      placeholder="Choose..."*/}
					{/*      fluid*/}
					{/*      search*/}
					{/*      selection*/}
					{/*      value={this.props.industry}*/}
					{/*      options={INDUSTRY_OPTIONS}*/}
					{/*      onChange={this.props.onIndustryChange}*/}
					{/*    />*/}
					{/*  </Form.Field>*/}
					{/*  <Form.Field>*/}
					{/*    <label>Use Case</label>*/}
					{/*    <Input fluid onChange={this.props.onUseCaseChange} />*/}
					{/*  </Form.Field>*/}
					{/*  <Form.Field>*/}
					{/*    <label>Platform Awareness</label>*/}
					{/*    <Dropdown*/}
					{/*      placeholder="Optional"*/}
					{/*      fluid*/}
					{/*      search*/}
					{/*      selection*/}
					{/*      value={this.props.platformAwareness}*/}
					{/*      options={PLATFORM_AWARENESS_OPTIONS}*/}
					{/*      onChange={this.props.onPlatformAwarenessChange}*/}
					{/*    />*/}
					{/*  </Form.Field>*/}
					{/*</Form.Group>*/}
					{/*<Form.Group widths="equal">*/}
					{/*  <Form.Field>*/}
					{/*    <label>GPS Potential</label>*/}
					{/*    <Input placeholder="Optional" type="number" fluid onChange={this.props.onGpsPotentialChange} />*/}
					{/*  </Form.Field>*/}
					{/*  <Form.Field>*/}
					{/*    <label>FMS Potential</label>*/}
					{/*    <Input placeholder="Optional" type="number" fluid onChange={this.props.onFmsPotentialChange} />*/}
					{/*  </Form.Field>*/}
					{/*  <Form.Field>*/}
					{/*    <label>Preferred Communication Channel</label>*/}
					{/*    <Dropdown*/}
					{/*      placeholder="Optional"*/}
					{/*      fluid*/}
					{/*      search*/}
					{/*      selection*/}
					{/*      value={this.props.preferredCommunicationChannel}*/}
					{/*      options={PREFERRED_COMMUNICATION_CHANNEL_OPTIONS}*/}
					{/*      onChange={this.props.onPreferredCommunicationChannelChange}*/}
					{/*    />*/}
					{/*  </Form.Field>*/}
					{/*</Form.Group>*/}
					<Form.Group widths="equal">
						<Form.Field required>
							<label>Address</label>
							<Input fluid onChange={props.onAddressChange} />
						</Form.Field>
						{/*<Form.Field>*/}
						{/*  <label>Billing Period</label>*/}
						{/*  <Dropdown*/}
						{/*    placeholder="Choose..."*/}
						{/*    fluid*/}
						{/*    search*/}
						{/*    selection*/}
						{/*    options={BillingPeriod}*/}
						{/*    onChange={this.props.onBillingPeriodChange}*/}
						{/*  />*/}
						{/*</Form.Field>*/}
					</Form.Group>
					{/*<Form.Group widths="equal">*/}
					{/*  <Form.Field>*/}
					{/*    <label>Saas Plans</label>*/}
					{/*    <Dropdown*/}
					{/*      placeholder="Choose..."*/}
					{/*      search*/}
					{/*      multiple*/}
					{/*      selection*/}
					{/*      options={this.props.saasPlansOptions}*/}
					{/*      value={this.props.selectedSaasPlans}*/}
					{/*      onChange={this.props.onSaasPlansChange}*/}
					{/*    />*/}
					{/*  </Form.Field>*/}
					{/*  <Form.Field>*/}
					{/*    <label>Plans</label>*/}
					{/*    <ToggleFormField*/}
					{/*      style={{padding: '0.5em 0 0 1em'}}*/}
					{/*      label="Customized Plans"*/}
					{/*      onChange={this.props.onCheckedCustomizedPlan}*/}
					{/*    />*/}
					{/*  </Form.Field>*/}
					{/*</Form.Group>*/}
				</Form>
			}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button positive loading={props.addButtonLoading} disabled={props.disabled} onClick={props.onAddButtonClick}>
						Add
					</Button>
				</>
			}
		/>
	);
};

export default AddModal;
