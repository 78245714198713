import React from 'react';

import { Container } from './PlaceHolderStyles';
import NoDataImage from '../../../../assets/images/data-not-found.jpg';

function PlaceHolder() {
	return (
		<Container>
			<img src={NoDataImage} alt="No Data" />
			<h4>No data found.</h4>
		</Container>
	);
}

export default PlaceHolder;
