// import axios from 'axios';
import { SAAS_COMPANY_METRICS_API } from '../configs/api';
import { dispatch, dispatchNoPayload } from './middleware';
import { handleError } from './messaging';
import { createCompanyDTO } from '../dto/companyMetrics';
import { exportToExcelWithLogo } from '../utilities/excel';
import { COMPANY_METRICS_FILE_HEADER } from '../../data/constants/general';
import { jsPDF } from 'jspdf';
import Logo from '../../ui/assets/images/logo.png';
import { getData } from './services/apiRequest';

export function fetchCompanySaasMetrics(params = '') {
	// axios(SAAS_COMPANY_METRICS_API + params, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// });
	return getData(SAAS_COMPANY_METRICS_API, params);
}

export function loadSaasCompanyMetrics(params = '') {
	// Fetching Data
	dispatchNoPayload('FETCHING_COMPANY_METRICS_DATA');

	fetchCompanySaasMetrics(params)
		.then((response) => {
			const companyMetrics = createCompanyDTO(response?.data?.data);
			dispatch('FEED_COMPANY_METRICS_DATA', companyMetrics);
		})
		.catch((error) => {
			handleError(error);
			dispatchNoPayload('FETCHED_COMPANY_METRICS_DATA');
		});
}

// Export
export function openExportModal() {
	dispatchNoPayload('COMPANY_METRICS_EXPORT_MODAL_OPEN');
}

export function closeExportModal() {
	dispatchNoPayload('COMPANY_METRICS_EXPORT_MODAL_CLOSE');
}

export function formatExcelExport(response) {
	return response.map((metrics) => {
		return {
			Month: metrics.date,
			'Net MRR': metrics.netMrr,
			'New MRR': metrics.newMrr,
			'Expansion MRR': metrics.expansionMrr,
			'Churned MRR': metrics.churnedMrr,
			'MRR Platform': metrics.mrrPlatform,
			'MRR Product': metrics.mrrProduct,
			'Total Customers': metrics.totalCustomers,
			'New Customers': metrics.newCustomers,
			'Churned Customers': metrics.churnedCustomers,
			'Total Vehicles': metrics.totalVehicles,
			'New Vehicles': metrics.newVehicles,
			'Expansion Vehicles': metrics.expansionVehicles,
			'Churned Vehicles': metrics.churnedVehicles,
			CLV: metrics.clv,
			CAC: metrics.cac,
			'CLV-CAC Ratio': metrics.clvCacRatio,
			'Cogs Platform': metrics.cogsPlatform,
			'Cogs Products': metrics.cogsProduct,
			'Fixed Expenditure': metrics.fixedExpenditure,
			'Variable Expenditure': metrics.variableExpenditure,
			GM: metrics.gm,
			CM: metrics.cm,
			EBITDA: metrics.ebitda,
			NM: metrics.nm,
		};
	});
}

export function exportData(data, fileName, extension) {
	if (extension === 'excel') {
		exportToExcelWithLogo(
			formatExcelExport(data),
			`${!fileName ? 'Company Metrics' : fileName}`,
			COMPANY_METRICS_FILE_HEADER
		);
	} else {
		convertToPdf(formatExcelExport(data), fileName);
	}

	closeExportModal();
}

export function convertToPdf(data, fileName) {
	const doc = new jsPDF({ orientation: 'l', unit: 'mm', format: 'a4' });

	let col = [
		'Month',
		'Net MRR',
		'New MRR',
		'Expansion MRR',
		'Churned MRR',
		'MRR Platform',
		'MRR Product',
		'Total Customers',
		'New Customers',
		'Churned Customers',
		'Total Vehicles',
		'New Vehicles',
		'Expansion Vehicles',
		'Churned Vehicles',
		'CLV',
		'CAC',
		'CLV-CAC Ratio',
		'Cogs Platform',
		'Cogs Products',
		'Fixed Expenditure',
		'Variable Expenditure',
		'GM',
		'CM',
		'EBITDA',
		'NM',
	];
	let rows = [];

	data.forEach((metrics) => {
		let temp = [];
		Object.keys(metrics).forEach((key, index) => {
			if (col[index] === key) temp[index] = metrics[key];
		});

		rows.push(temp);
	});

	let header = function (data) {
		doc.addImage(Logo, 'png', data.settings.margin.left, 10, 40, 14);
	};

	doc.autoTable(col, rows, {
		margin: { top: 25 },
		beforePageContent: header,
		theme: 'grid',
	});

	doc.save(`${!fileName ? 'Company Metrice' : fileName}`);
}

// Graph Formatter
export function formatNetMRRGraphData(data) {
	let graphData = [];

	data.forEach((metrics) => {
		graphData.push({
			month: metrics['date'],
			netMrr: metrics['netMrr'],
		});
	});

	return graphData;
}

export function formatNetMRRDistributionGraphData(data) {
	let graphData = [];

	data.forEach((metrics) => {
		graphData.push({
			month: metrics['date'],
			newMrr: metrics['newMrr'],
			churnedMrr: -metrics['churnedMrr'],
			expansionMrr: metrics['expansionMrr'],
		});
	});

	return graphData;
}

export function formatVehicleExpansionGraphData(data) {
	let graphData = [];

	data.forEach((metrics) => {
		graphData.push({
			month: metrics['date'],
			newVehicles: metrics['newVehicles'],
			churnedVehicles: -metrics['churnedVehicles'],
			expansionVehicles: metrics['expansionVehicles'],
		});
	});

	return graphData;
}

export function formatTotalVehiclesGraphData(data) {
	let graphData = [];

	data.forEach((metrics) => {
		graphData.push({
			month: metrics['date'],
			totalVehicles: metrics['totalVehicles'],
		});
	});

	return graphData;
}

export function formatTotalCustomersGraphData(data) {
	let graphData = [];

	data.forEach((metrics) => {
		graphData.push({
			month: metrics['date'],
			totalCustomers: metrics['totalCustomers'],
		});
	});

	return graphData;
}

export function formatCustomerDistributionGraphData(data) {
	let graphData = [];

	data.forEach((metrics) => {
		graphData.push({
			month: metrics['date'],
			newCustomers: metrics['newCustomers'],
			churnedCustomers: -metrics['churnedCustomers'],
		});
	});

	return graphData;
}

export function formatCLVGraphData(data) {
	let graphData = [];

	data.forEach((metrics) => {
		graphData.push({
			x: metrics['date'],
			y: metrics['clv'],
		});
	});

	return [{ id: 'clv', data: graphData }];
}
