import styled from 'styled-components';

export const Container = styled.section`
	width: 100%;
	height: calc(100% - 4em);

	//position: fixed;
	//top: 7%;
	//right: 5px;

	display: flex;
	align-items: center;
	justify-content: center;
`;
