import React, { useRef } from 'react';
import { Icon, Input } from 'semantic-ui-react';
import { Container } from './CustomerMetricsSearchBoxStyles';

function CustomerMetricsSearchBox(props) {
	const searchRef = useRef(null);

	const handleClearClick = () => {
		props.clearSearchIconClick();
		if (searchRef.current && searchRef.current.inputRef.current) {
			searchRef.current.inputRef.current.value = '';
		}
	};

	const handleKeyPress = (e) => {
		props.handleSearch(e);
	};

	return (
		<Container>
			<Input
				ref={searchRef}
				icon={
					searchRef.current?.inputRef?.current?.value && (
						<Icon name="times circle outline" link onClick={handleClearClick} />
					)
				}
				placeholder="Search"
				onKeyPress={handleKeyPress}
			/>
		</Container>
	);
}

export default CustomerMetricsSearchBox;
