import { isoToDate } from '../utilities/date';

export function createDeviceModelsDTO(data) {
	if (data && data.length !== 0) {
		return data.map((model) => {
			return {
				id: model?.id ? model.id : null,
				manufacturer: model?.manufacturer ? model.manufacturer : null,
				model: model?.model ? model.model : null,
				port: model?.port ? model.port : null,
				isOBD: model?.isOBD ? model.isOBD : null,
				deletedDate: model?.deletedDate ? isoToDate(model.deletedDate) : null,
			};
		});
	} else return [];
}
