import React from 'react';

import CommonModal from '../../../molecules/modal/CommonModal';
import { Expander } from '../../../../styles/place-holders/Expander';

import { Button, Dropdown, Form } from 'semantic-ui-react';
import styled from 'styled-components';

const FilterModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="tiny"
			header="Roles Filters"
			scrolling
			content={
				<Form>
					<Form.Group widths="equal">
						<Form.Field>
							<label>Is Super Admin</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								selection
								clearable
								value={props.isSuperAdmin}
								options={props.isSuperAdminOptions}
								onChange={props.onIsSuperAdminFilterChange}
							/>
						</Form.Field>
					</Form.Group>
				</Form>
			}
			actions={
				<>
					<FlexContainer>
						<Button onClick={props.onResetClick}>Reset</Button>
						<Expander />
						<Button color="black" onClick={props.onCancelClick}>
							Cancel
						</Button>
						<Button primary disabled={props.isSuperAdmin === null} onClick={props.onApplyClick}>
							Apply
						</Button>
					</FlexContainer>
				</>
			}
		/>
	);
};

const FlexContainer = styled.section`
	display: flex;
`;

export default FilterModal;
