import styled from 'styled-components';

export const ListMain = styled.section`
	width: 100%;
	flex-grow: 1;

	overflow-y: auto;

	::-webkit-scrollbar {
		width: 0; /* Remove scrollbar space */
		background: transparent; /* Optional: just make scrollbar invisible */
	}
`;
