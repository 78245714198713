import { DEVICE_COMMANDS_API, EXPORT_API } from '../configs/api';
import { DEVICE_COMMANDS_FILE_HEADER } from '../../data/constants/general';
import { createDeviceCommandsDTO } from '../dto/deviceCommands';
import Logo from '../../ui/assets/images/logo.png';
import Store from '../../data/store/store';
import { exportToExcelWithLogo } from '../utilities/excel';
import { handleError } from './messaging';
import { dispatch, dispatchNoPayload } from './middleware';

import { jsPDF } from 'jspdf';
import { exportApi, getData } from './services/apiRequest';
import fileDownload from 'js-file-download';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export function fetchDeviceCommands(params = '') {
	return getData(DEVICE_COMMANDS_API, params);
	// axios(DEVICE_COMMANDS_API + params, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// });
}

export function refreshDeviceCommands(imei, params = '', activePage = 1, resetOffset = false) {
	dispatchNoPayload('DEVICE_COMMANDS_LOADING');

	// axios(
	// 	DEVICES_API +
	// 		`/${imei}/command-info` +
	// 		'?pagecount=' +
	// 		getPageNumberFromStore().dataSizeLimit +
	// 		'&pagenumber=' +
	// 		(resetOffset ? 1 : activePage) +
	// 		'&startTime=' +
	// 		getPageNumberFromStore().initialDate +
	// 		'&endTime=' +
	// 		getPageNumberFromStore().finalDate +
	// 		params,
	// 	{
	// 		headers: {
	// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 			'Content-Type': 'application/json',
	// 		},
	// 	}
	// )
	getData(
		BASE_URL +
			`v1/device/commands?imei=${imei}` +
			'&pagecount=' +
			getPageNumberFromStore().dataSizeLimit +
			'&pagenumber=' +
			(resetOffset ? 1 : activePage) +
			'&startTime=' +
			getPageNumberFromStore().initialDate +
			'&endTime=' +
			getPageNumberFromStore().finalDate +
			params
	)
		.then((response) => {
			dispatch('FEED_DEVICE_COMMANDS', createDeviceCommandsDTO(response?.data?.data));
			dispatch('DEVICE_COMMANDS_SET_PAGE_COUNT', response?.data?.count || 0);
			dispatch('DEVICE_COMMANDS_SET_PAGE_NUMBER', activePage);
		})
		.catch((error) => {
			handleError(error);
			dispatchNoPayload('DEVICE_COMMANDS_LOADED');
		});
}

export function searchDeviceCommands(query) {
	return `&search=${encodeURIComponent(query)
		.trim()
		.split('+')
		.map((searchTerm) => `${searchTerm}`)}`;
}

// Export
export function openExportModal() {
	dispatchNoPayload('DEVICE_COMMANDS_EXPORT_MODAL_OPEN');
}

export function closeExportModal() {
	dispatchNoPayload('DEVICE_COMMANDS_EXPORT_MODAL_CLOSE');
}

export function formatExcelExport(response) {
	return response.map((command) => {
		return {
			Name: command.name,
			Manufacturer: command.deviceModel.manufacturer,
			Model: command.deviceModel.model,
			Description: command.description,
			ASCII: command.commandAscii,
			Hex: command.commandHex,
		};
	});
}

export function exportData(data, fileName, extension) {
	if (extension === 'excel') {
		exportToExcelWithLogo(
			formatExcelExport(data),
			`${!fileName ? 'Device Commands' : fileName}`,
			DEVICE_COMMANDS_FILE_HEADER
		);
	} else {
		convertToPdf(formatExcelExport(data), fileName);
	}

	closeExportModal();
}

export function exportAll(param, imei) {
	// imei = imei.toString();
	exportApi(
		EXPORT_API + '/device/commands',
		`?imei=${imei}` +
			param +
			'&startTime=' +
			getPageNumberFromStore().initialDate +
			'&endTime=' +
			getPageNumberFromStore().finalDate
	)
		.then((response) => {
			const contentDisposition = response.headers['content-disposition'];
			const matches = /filename="([^"]+)"/.exec(contentDisposition);

			if (matches) {
				const fileName = matches[1];
				fileDownload(response?.data, fileName);
			}
		})
		.catch((error) => {
			handleError(error);
			dispatchNoPayload('EXPORTED_DEVICES_EXPORT_DATA');
		});
}

export function convertToPdf(data, fileName) {
	const doc = new jsPDF({ orientation: 'l', unit: 'mm', format: 'a4' });

	let col = ['Name', 'Manufacturer', 'Model', 'Description', 'ASCII', 'Hex'];
	let rows = [];

	data.forEach((command) => {
		let temp = [];
		Object.keys(command).forEach((key, index) => {
			if (col[index] === key) temp[index] = command[key];
		});

		rows.push(temp);
	});

	let header = function (data) {
		doc.addImage(Logo, 'png', data.settings.margin.left, 10, 40, 14);
	};

	doc.autoTable(col, rows, {
		margin: { top: 25 },
		beforePageContent: header,
		theme: 'grid',
	});

	doc.save(`${!fileName ? 'Device Commands' : fileName}`);
}

// export function exportAll(param) {
// 	dispatchNoPayload('EXPORTING_DEVICE_COMMANDS_EXPORT_DATA');
//
// 	// axios(DEVICE_COMMANDS_API + '?pagination=0' + param, {
// 	// 	headers: {
// 	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
// 	// 		'Content-Type': 'application/json',
// 	// 	},
// 	// })
// 	getData(DEVICE_COMMANDS_API + '?pagination=0', param)
// 		.then((response) => {
// 			openExportModal();
// 			dispatch('SET_DEVICE_COMMANDS_EXPORT_DATA', createDeviceCommandsDTO(response?.data?.data));
// 			dispatchNoPayload('EXPORTED_DEVICE_COMMANDS_EXPORT_DATA');
// 		})
// 		.catch((error) => {
// 			handleError(error);
// 			dispatchNoPayload('EXPORTED_DEVICE_COMMANDS_EXPORT_DATA');
// 		});
// }

// Accordion
export function toggleAccordion(e, titleProps, current) {
	const { index } = titleProps;
	const { activeIndex } = {
		activeIndex: current,
	};
	const newIndex = activeIndex === index ? -1 : index;
	dispatchNoPayload('DEVICE_COMMANDS_EDIT_MODAL_CLOSE');
	dispatch('DEVICE_COMMANDS_TOGGLE_ACCORDION', newIndex);
}

// Connect to Store
function getPageNumberFromStore() {
	return {
		pageNumber: Store.getState().deviceCommands.pageNumber,
		dataSizeLimit: Store.getState().ui.dataSizeLimit,
		initialDate: Store.getState().dates.initialDate,
		finalDate: Store.getState().dates.finalDate,
	};
}
