import React from 'react';

import { GraphCard } from '../../../../styles/containers/GraphCard';
import { GraphCardMain } from '../../../../styles/containers/GraphCardMain';
import { GraphTooltip } from '../../../../styles/containers/GraphTooltip';
import { GraphCardHeader } from '../../../../styles/containers/GraphCardHeader';
import { GraphCardFooter } from '../../../../styles/containers/GraphCardFooter';
import GraphLoader from '../../../molecules/graph-loader/GraphLoader';

import moment from 'moment';
import { ResponsiveBar } from '@nivo/bar';

const VehicleExpansionGraph = () => {
	const dataLength = this.props?.data?.length || 6;

	return (
		<GraphCard>
			{this.props.loading && <GraphLoader />}
			<GraphCardHeader>
				<h3>Vehicle Expansion/Churn</h3>
			</GraphCardHeader>
			<GraphCardMain>
				<ResponsiveBar
					data={this.props.data}
					keys={['expansionVehicles', 'churnedVehicles']}
					indexBy="month"
					margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
					padding={0.25}
					label={null}
					axisTop={null}
					axisRight={null}
					axisLeft={{
						orient: 'left',
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						format: (count) => `${count}`,
					}}
					axisBottom={{
						tickRotation: dataLength < 7 ? 0 : -45,
						format: (tick) => {
							if (dataLength < 7) return moment(tick, 'MMM YYYY').format('MMM YYYY');
							else if (dataLength >= 7 && dataLength <= 18) return moment(tick, 'MMM YYYY').format('MM/YY');
							else return '';
						},
					}}
					labelSkipWidth={12}
					labelSkipHeight={12}
					labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
					colors={['#00c48c', '#ff647c']}
					tooltip={(data) => (
						<GraphTooltip>
							{moment(data.data.month, 'MMM YYYY').format('MMM, YYYY')} : Vehicles ={' '}
							{data.id === 'expansionVehicles' ? data.data.expansionVehicles : data.data.churnedVehicles}
						</GraphTooltip>
					)}
					borderRadius={5}
					innerPadding={2}
					motionConfig="gentle"
					theme={{
						tooltip: {
							container: {
								padding: 0,
								borderRadius: '5px',
								position: 'absolute',
								top: -50,
								transform: 'translateX(-50%)',
							},
						},
					}}
				/>
			</GraphCardMain>
			<GraphCardFooter>
				<span>X = Months, Y = No. of Vehicles</span>
			</GraphCardFooter>
		</GraphCard>
	);
};

export default VehicleExpansionGraph;
