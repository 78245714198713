import styled from 'styled-components';

export const Container = styled.section`
	width: 100%;

	box-sizing: border-box;
	padding: 1em 0;
	//padding: 0;

	overflow-y: auto;
`;
