import moment from 'moment';

export function isoToDate(timestamp) {
	return moment(timestamp).format('DD-MM-YYYY');
}

export function daysLeft(startDate, endDate) {
	return moment(endDate).diff(moment(startDate), 'days');
}

export function getHumanReadableElapsedTime(milliseconds) {
	let years = moment.duration(milliseconds).asYears();
	let months = moment.duration(milliseconds).asMonths();
	let days = moment.duration(milliseconds).asDays();
	let hours = moment.duration(milliseconds).asHours();
	let minutes = moment.duration(milliseconds).asMinutes();

	return years > 1
		? `${Math.floor(years)} Years`
		: months > 1
		? `${Math.floor(months)} Months`
		: days > 1
		? `${Math.floor(days)} Days`
		: hours > 1
		? `${Math.floor(hours)} Hours`
		: minutes > 1
		? `${Math.floor(minutes)} Minutes`
		: '';
}

export function isoToHumanReadable(timestamp) {
	return moment(timestamp).format('DD-MM-YYYY, hh:mm:ss A');
}

export function isoToHumanReadableAlternate(timestamp) {
	return moment(timestamp).format('MMMM Do YYYY, h:mm:ss a');
}

export function isoToHumanReadableDay(timestamp) {
	return moment(timestamp).format('DD-MM-YYYY');
}

export function getFormattedDate(timestamp, format) {
	return moment(timestamp).format(format);
}

export function timeDifference(time) {
	const duration = moment.duration(time, 'seconds');

	if (time >= 86400) {
		return `${Math.floor(duration.asDays())} d ${duration.hours()} h`;
	} else if (time >= 3600) {
		return `${moment.utc(duration.asMilliseconds()).format('H [h] m [m]')} delay`;
	} else if (time >= 60) {
		return `${duration.minutes()}m : ${duration.seconds()}s delay`;
	} else {
		return `${duration.seconds()}s delay`;
	}
}

export function getElapsedTime(timestamp, unit) {
	let t1 = moment(timestamp);
	let t2 = moment();

	switch (unit) {
		case 'hours':
			return moment.duration(t2.diff(t1, 'hours'), 'hours').asMilliseconds();

		case 'minutes':
			return moment.duration(t2.diff(t1, 'minutes'), 'minutes').asMilliseconds();

		default:
			return moment.duration(t2.diff(t1, 'seconds'), 'seconds').asMilliseconds();
	}
}

export function hoursToHMS(hours) {
	return moment.utc(moment.duration(hours, 'hours').asMilliseconds()).format('HH:mm:ss');
}
