import styled from 'styled-components';

export const CategoryContentMembrane = styled.section`
	width: 100%;
	min-width: 1000px;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	position: relative;
`;
