import styled from 'styled-components';

export const Container = styled.section`
	width: 1.5em;
	height: 1.5em;

	display: flex;
	align-items: center;
	justify-content: center;

	position: absolute;
	bottom: 1em;
	right: -0.75em;

	background: ${(props) => props.theme.sidebarToggleIconBackground};
	border-radius: 50%;

	i.link.icon,
	i.link.icons {
		margin: 0 !important;

		color: ${(props) => props.theme.sidebarToggleIcon};

		transition: color 0.25s;
		opacity: 0.95;
	}

	z-index: 99;
`;
