export default function ui(
	initialState = {
		// Data Limit
		dataSizeLimit: 100,

		// CategorySidebar State
		sidebarExpanded: true,

		metricsViewMode: 'graph',
	},
	action
) {
	switch (action.type) {
		// Data Size Limit
		case 'SET_DATA_SIZE_LIMIT':
			return {
				...initialState,
				dataSizeLimit: action.payload,
			};

		// CategorySidebar State
		case 'EXPAND_SIDEBAR':
			return {
				...initialState,
				sidebarExpanded: true,
			};

		case 'COLLAPSE_SIDEBAR':
			return {
				...initialState,
				sidebarExpanded: false,
			};

		case 'SET_METRICS_VIEW_MODE':
			return {
				...initialState,
				metricsViewMode: action.payload,
			};

		default:
			return initialState;
	}
}
