import styled from 'styled-components';

export const Container = styled.section`
	width: 100%;
	height: 100vh;

	display: flex;
`;

export const Main = styled.section`
	width: calc(100% - 4em);
	height: 100%;
`;
