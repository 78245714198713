import React, { useEffect, useState } from 'react';

import { ButtonContainer, DetailsHeader, ExcelDataRow, HeaderElement, Row, RowElement } from './ImportStyles';

import * as XLSX from 'xlsx';
import stringSimilarity from 'string-similarity';

import { Button, Dropdown, Icon, Label, Modal } from 'semantic-ui-react';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import { dispatch } from '../../../logic/middleware/middleware';

const SimsImport = (props) => {
	let finalData = {};
	let errorFocusRefs = [];

	const [headers, setHeaders] = useState([]);
	const [fileTemplate, setFileTemplate] = useState([]);
	const [rowData, setRowData] = useState([]);
	const [allData, setAllData] = useState([]);
	// const [submitting, setSubmitting] = useState(false);
	const [templateModalOpen, setTemplateModalOpen] = useState(false);
	const [excelDataModalOpen, setExcelDataModalOpen] = useState(false);
	const [selectedHeaders, setSelectedHeaders] = useState([]);
	const [mapping, setMapping] = useState(false);

	const bulkimportLoading = useSelector((state) => state.sims.bulkimportLoading);

	const parseFile = (file) => {
		const fileReader = new FileReader();
		const readAsBinaryString = !!fileReader.readAsBinaryString;

		fileReader.onload = (e) => {
			const binaryString = e.target && e.target.result;
			const workbook = XLSX.read(binaryString, {
				type: readAsBinaryString ? 'binary' : 'array',
				// cellDates: true,
			});

			const worksheetName = workbook.SheetNames[0];
			const workSheet = workbook.Sheets[worksheetName];

			const data = XLSX.utils.sheet_to_json(workSheet, {
				header: 1,
				blankrows: false,
				defval: '',
				raw: false,
			});

			mapData(data);
		};

		if (readAsBinaryString) fileReader.readAsBinaryString(file);
		else fileReader.readAsArrayBuffer(file);
	};

	useEffect(() => {
		if (props?.template && props?.template?.length > 0) {
			setFileTemplate(props.template);
		}

		if (props.file) {
			parseFile(props.file);
		}

		return () => {
			closeExcelDataModal();
			closeTemplateModal();
			cleanAllDataVariable();
			setFileTemplate([]);
			// props.closeImport();
		};
	}, [props.simsResponse, props.file, props.template]);

	const mapData = (data) => {
		let header = data[0];
		let RowData = data.slice(1);

		setHeaders(header);
		setRowData(RowData);
		// setLoading(false);

		smartFill();
		openTemplateModal();
	};

	const smartFill = () => {
		let selectedHeaders = [];
		fileTemplate &&
			fileTemplate.forEach((template, index) => {
				let targetIndex = 0;
				let maxWeight = 0;
				headers &&
					headers.forEach((header, localIndex) => {
						let weight = stringSimilarity.compareTwoStrings(template.name, header ? header.toString() : '');
						if (weight > maxWeight) {
							maxWeight = weight;
							targetIndex = localIndex;
						}
					});

				if (maxWeight > 0.5) {
					selectedHeaders = Object.assign([], selectedHeaders, {
						[index]: headers[targetIndex],
					});
				}
			});

		setSelectedHeaders(selectedHeaders);
	};

	const openTemplateModal = () => {
		setTemplateModalOpen(true);
	};

	const closeTemplateModal = () => {
		setTemplateModalOpen(false);
		props.closeImport();
	};

	const getDropdownOptions = (origin) => {
		const options = [];
		headers &&
			headers.forEach((value, index) => {
				if (value === selectedHeaders[origin] || selectedHeaders.indexOf(value) === -1) {
					options.push({ key: index, value: value, text: value });
				}
			});

		return options;
	};

	const formatData = () => {
		const newFormattedData = rowData.map((row) => {
			return fileTemplate.map((_column, index) => {
				return row[headers.indexOf(selectedHeaders[index])];
			});
		});

		setAllData(newFormattedData);
	};

	const openExcelDataModal = () => {
		setTemplateModalOpen(false);
		setExcelDataModalOpen(true);
		setMapping(false);
	};

	const closeExcelDataModal = () => {
		setExcelDataModalOpen(false);
		if (props.closeImport) {
			props.closeImport();
		}
	};

	const convertToJson = () => {
		finalData = allData.map((value) => {
			let data = {};
			fileTemplate.forEach((template, localIndex) => {
				if (value[localIndex])
					return Object.assign(data, {
						[template.requestTitle]: value[localIndex].toString(),
					});
			});
			return data;
		});

		props.upload({ data: finalData, success: success, error: error });
	};

	const success = () => {
		if (props.simsResponse?.failedCount.length === 0) {
			cleanAllDataVariable();
			closeExcelDataModal();
		}
		// setSubmitting(false);
	};

	const error = () => {
		// setSubmitting(false);
	};

	const sendMessages = () => {
		dispatch('BULK_IMPORT_LOAD', true);
		// setSubmitting(true);
		convertToJson();
	};

	const addRow = () => {
		setAllData((prev) => [...prev, new Array(fileTemplate.length).fill('')]);
	};

	const removeRow = (index) => {
		setAllData((prevData) => prevData.filter((_, idx) => idx !== index));
	};

	const isValidSimNumber = (simNumber) => {
		if (simNumber) {
			const removedWhiteSpace = simNumber.replaceAll(/\s/g, '');
			return (removedWhiteSpace.length === 19 || removedWhiteSpace.length === 20) && /^-?\d+$/.test(removedWhiteSpace);
		}
		return false; // Return false if simNumber is undefined
	};

	const isValidSimMobileNumber = (mobileNumber) => {
		return mobileNumber?.toString().length === 13 && /^-?\d+$/.test(mobileNumber);
	};

	const isValidServiceProvider = (servProvider) => {
		return ['Airtel', 'Bsnl', 'Vodafone'].includes(servProvider);
	};

	const hasInvalidServiceProvider = () => {
		const invalidServiceProvider = allData.filter((row) => !isValidServiceProvider(row[3]));
		return invalidServiceProvider ? invalidServiceProvider[0]?.length > 0 : false;
	};

	const hasInvalidSimMobileNumber = () => {
		const invalidSimMobileNumber = allData.filter((row) => !isValidSimMobileNumber(row[0]));
		return invalidSimMobileNumber ? invalidSimMobileNumber[0]?.length > 0 : false;
	};

	const hasInvalidSimNumber = () => {
		const invalidSimNumber = allData.filter((row) => !isValidSimNumber(row[1]));
		return invalidSimNumber ? invalidSimNumber[0]?.length > 0 : false;
	};

	const hasInvalidData = () => {
		let errorIdx;
		const invalidData = allData.filter((row, idx) => {
			if (
				(errorIdx === undefined && !isValidSimNumber(row[1])) ||
				!isValidSimMobileNumber(row[0]) ||
				!isValidServiceProvider(row[3])
			) {
				errorIdx = idx;
			}
			return !isValidSimNumber(row[1]) || !isValidSimMobileNumber(row[0]) || !isValidServiceProvider(row[3]);
		});

		if (invalidData.length > 0) {
			const firstInvalidInputRef = errorFocusRefs[(errorIdx + 4) * 4];
			firstInvalidInputRef?.current?.focus({ preventScroll: false });
		}
		return invalidData.length > 0;
	};

	const cleanAllDataVariable = () => {
		setRowData([]);
		setHeaders([]);
		finalData = {};
		// formattedData = [];
		setAllData([]);
		setSelectedHeaders([]);
		errorFocusRefs = [];
	};

	const cleanMappedDataVariable = () => {
		setRowData([]);
		setHeaders([]);
		setSelectedHeaders([]);
	};

	return (
		<>
			{templateModalOpen && (
				<Modal
					onClose={() => {
						closeTemplateModal();
						cleanMappedDataVariable();
					}}
					open={templateModalOpen}>
					<Modal.Header>
						Map Data
						<span style={{ fontSize: '1rem', color: 'red', float: 'right' }}>Fields marked with red are mandatory</span>
					</Modal.Header>
					<Modal.Content>
						{fileTemplate?.map((name, index) => {
							const requiredFields = ['SIM Number', 'Mobile Number', 'Service Provider'];
							const isRequired = requiredFields.includes(name.name);
							const hasError = selectedHeaders[index] !== name.name;
							const isEmpty = selectedHeaders[index] === '' || null || undefined;

							return (
								<Row key={index}>
									{isRequired ? (
										<>
											<div style={{ color: 'red' }}>*</div>
											<RowElement style={{ color: hasError || isEmpty ? 'red' : 'black' }}>{name.name}</RowElement>
										</>
									) : (
										<RowElement style={{ color: hasError && !isEmpty ? 'red' : 'black' }}>{name.name}</RowElement>
									)}

									<RowElement>
										<Dropdown
											error={isRequired ? hasError || isEmpty : hasError && !isEmpty}
											placeholder="Choose..."
											fluid
											selection
											clearable
											options={getDropdownOptions(index)}
											value={selectedHeaders[index]}
											// onChange={(_e, { value }) => {
											// 	setSelectedHeaders((prevSelectedHeaders) => {
											// 		const updatedHeaders = [...prevSelectedHeaders];
											// 		updatedHeaders[index] = value;
											// 		return updatedHeaders;
											// 	});
											// }}
											onChange={(_e, { value }) => {
												setSelectedHeaders(Object.assign([], selectedHeaders, { [index]: value }));
											}}
										/>

										{hasError && !isEmpty && (
											<Label basic color="red" pointing>
												Please enter correct header
											</Label>
										)}

										{isRequired && isEmpty && (
											<Label basic color="red" pointing>
												Required field cannot be empty
											</Label>
										)}
									</RowElement>
								</Row>
							);
						})}
					</Modal.Content>
					<Modal.Actions>
						<Button
							secondary
							color="black"
							onClick={() => {
								closeTemplateModal();
								cleanMappedDataVariable();
							}}>
							Cancel
						</Button>
						<Button
							loading={mapping}
							// disabled={
							// 	!selectedHeaders.includes('Mobile Number') ||
							// 	!selectedHeaders.includes('Sim Number') ||
							// 	!selectedHeaders.includes('Service Provider')
							// }
							onClick={() => {
								setMapping(true);
								setTimeout(() => {
									formatData();
									openExcelDataModal();
								}, 1);
							}}
							positive>
							Map
						</Button>
					</Modal.Actions>
				</Modal>
			)}

			{excelDataModalOpen && (
				<Modal
					onClose={() => {
						closeExcelDataModal();
						cleanAllDataVariable();
					}}
					open={excelDataModalOpen}>
					<Modal.Header>Delivery Details</Modal.Header>
					<DetailsHeader>
						<HeaderElement width="5%">Serial</HeaderElement>
						{fileTemplate.map((name, index) => {
							const isRequired = ['SIM Number', 'Mobile Number', 'Service Provider'].includes(name.name);
							return (
								<HeaderElement width={`${(90 / fileTemplate.length).toString()}%`} key={index}>
									{isRequired && <span style={{ color: 'red' }}>*</span>} {name.name}
								</HeaderElement>
							);
						})}
						<ButtonContainer />
					</DetailsHeader>
					{/*Padding*/}

					<div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
						{hasInvalidServiceProvider() ? (
							<div style={{ color: 'red' }}>Please fill correct Service Provider</div>
						) : (
							''
						)}
						{hasInvalidSimNumber() ? <div style={{ color: 'red' }}>Sim number must be 19 or 20 digits</div> : ''}
						{hasInvalidSimMobileNumber() ? <div style={{ color: 'red' }}>Mobile number must be 13 digits</div> : ''}
					</div>

					<Modal.Content scrolling>
						<Modal.Description>
							{allData.map((row, index) => {
								return (
									<div key={index}>
										<ExcelDataRow>
											<>
												<label style={{ width: '5%', textAlign: 'center' }}>{index + 1}</label>
												{row.map((data, localIndex) => {
													const simNumber = row[1];
													const mobileNumber = row[0];
													const serviceProvider = row[3];
													const inputRef = React.createRef();
													const inputField = (
														<input
															key={`${index},${localIndex},${data}`}
															defaultValue={data}
															ref={inputRef}
															style={{
																width: `${(90 / fileTemplate.length).toString()}%`,
																border:
																	!isValidSimNumber(simNumber) ||
																	!isValidSimMobileNumber(mobileNumber) ||
																	!isValidServiceProvider(serviceProvider)
																		? `2px solid red`
																		: '',
															}}
															onBlur={(e) => {
																// allData[index][localIndex] = e.target.value;
																const temp = [...allData];
																temp[index][localIndex] = e.target.value;
																setAllData(temp);
															}}
														/>
													);
													errorFocusRefs.push(inputRef);
													return inputField;
												})}
												<ButtonContainer>
													<Button
														icon
														size="small"
														style={{ background: 'none' }}
														onClick={() => {
															removeRow(index);
														}}>
														<Icon name="close" />
													</Button>
												</ButtonContainer>
											</>
										</ExcelDataRow>
									</div>
								);
							})}
						</Modal.Description>
					</Modal.Content>
					<Modal.Actions
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}>
						<Button onClick={addRow}>Add Row</Button>
						<div style={{ display: 'flex', alignItems: 'center', gap: '.3em', fontWeight: 'bold' }}>
							{props.simsResponse?.failedObjects.length > 0 ? (
								<div style={{ color: 'red' }}>{`${props.simsResponse?.failedCount} Failed count`}</div>
							) : (
								''
							)}

							{props.simsResponse?.failedObjects.length > 0 ? (
								<CSVLink
									data={props.simsResponse?.failedObjects.map((obj) => {
										const modifiedObj = {};
										for (let key in obj) {
											if (Object.prototype.hasOwnProperty.call(obj, key)) {
												let value = obj[key];
												if (typeof value === 'object' && value !== null) {
													value = JSON.stringify(value);
												}
												const modifiedKey = key.charAt(0).toUpperCase() + key.slice(1);
												modifiedObj[modifiedKey] = value;
											}
										}
										return modifiedObj;
									})}
									filename="Sims.csv">
									<Button primary title={'Click to download the failed file'} icon="download" />
								</CSVLink>
							) : (
								''
							)}
						</div>
						<div>
							<Button
								color="black"
								onClick={() => {
									closeExcelDataModal();
									cleanAllDataVariable();
								}}>
								Cancel
							</Button>
							<Button onClick={sendMessages} positive loading={bulkimportLoading} disabled={hasInvalidData()}>
								Submit
							</Button>
						</div>
					</Modal.Actions>
				</Modal>
			)}
		</>
	);
};

export default SimsImport;
