import styled from "styled-components";

// Placeholder
export const Container = styled.section`
	margin-right: 0.5em;
`;

export const PlaceholderView = styled.section`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;

	pointer-events: ${(props) => (props.disabled ? 'none' : 'default')};
	opacity: ${(props) => (props.disabled ? '0.4' : '1')};

	background: ${(props) => props.theme.calendarBackground};

	box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0, rgba(27, 31, 35, 0.15) 0 0 0 1px;
	border: 1px solid #64b5f6;
	border-radius: 5px;

	cursor: pointer;

	transition: box-shadow 0.15s ease;

	img {
		height: 2.57em;
	}
`;

export const DayBlock = styled.section`
	width: 6em;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

// Modal
export const PickerContainer = styled.section`
	width: 100%;
	height: 500px;

	display: flex;
`;

export const CalendarBlock = styled.section`
	display: flex;
	flex-direction: column;

	box-sizing: border-box;

	background: #fff;
`;

export const PickerView = styled.section`
	width: 100%;

	display: flex;
`;

export const PickerPane = styled.section`
	height: 100%;

	display: flex;
	flex-direction: column;

	margin: 0 1em;
`;

export const TimePickerBlock = styled.section`
	flex-grow: 1;

	display: flex;
	align-items: center;
	justify-content: space-around;

	margin: 1em 0;

	border-radius: 5px;

	background: #eee;
`;

export const PickerControls = styled.section`
	width: 100%;
	flex-grow: 1;

	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ShortcutsBlock = styled.section`
	flex-grow: 1;
	height: 100%;

	box-sizing: border-box;
	padding: 1em;

	.button {
		width: 100%;

		margin-bottom: 1em;
	}

	background: #f5f5f5;
	border-radius: 5px;
`;

export const ShortcutsPane = styled.section`
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const MainContainer = styled.section`
	width: 100%;

	display: flex;
	justify-content: center;
`;
