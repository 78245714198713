import styled from 'styled-components';

export const GraphTableMain = styled.section`
	width: 100%;
	height: 100%;

	overflow-x: auto;

	.ui.table {
		margin: 0 !important;
		border-top: none !important;
		border-left: none !important;
		border-right: none !important;
		border-radius: 0 !important;
	}
`;
