import styled from 'styled-components';

export const GraphContainerForBar = styled.section`
	height: 27em;
	width: 50%;
	flex-grow: 0;

	box-sizing: border-box;
	padding: 0.75em;
`;
export const GraphHeader = styled.section`
	width: 100%;

	box-sizing: border-box;
	padding-top: 1.5em;
	padding-left: 2em;
	padding-bottom: 0.5rem;

	h1 {
		font-size: 1em;
		margin: 0;
		color: black;
	}
`;
export const GraphCard = styled.section`
	width: 100%;
	height: 100%;
	padding: 1% 3.5%;
	display: flex;
	flex-direction: column;
	align-items: center;

	position: relative;

	background: #fff;
	box-shadow: rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px;
	border-radius: 20px;

	div {
		display: flex;
		justify-content: center;
	}

	svg > g > g:last-child {
		pointer-events: none;
	}
`;
