export default function alerts(
	initialState = {
		alerts: [],
		loading: true,
		pageNumber: 1,
		count: 0,
		activeIndex: -1,
		activeSort: '',
		sortOrder: '',
		deleting: false,
		deleteModalOpen: false,
		adding: false,
		addModalOpen: false,
		updating: false,
		editMode: false,
		restoreModal: false,
		restoring: false,
		importModalOpen: false,
		filterModalOpen: false,
		changeHistoryModal: false,

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
	},
	action
) {
	switch (action.type) {
		case 'FEED_ALERTS':
			return {
				...initialState,
				alerts: action.payload,
				loading: false,
			};

		case 'ALERTS_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'ALERTS_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'ALERTS_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'ALERTS_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'ALERTS_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'ALERTS_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'ALERTS_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'ALERTS_DELETING':
			return {
				...initialState,
				deleting: true,
			};

		case 'ALERTS_DELETED':
			return {
				...initialState,
				deleting: false,
			};

		case 'ALERTS_DELETE_MODAL_OPEN':
			return {
				...initialState,
				deleteModalOpen: true,
			};

		case 'ALERTS_DELETE_MODAL_CLOSE':
			return {
				...initialState,
				deleteModalOpen: false,
			};

		case 'ALERTS_ADDING':
			return {
				...initialState,
				adding: true,
			};

		case 'ALERTS_ADDED':
			return {
				...initialState,
				adding: false,
			};

		case 'ALERTS_ADD_MODAL_OPEN':
			return {
				...initialState,
				addModalOpen: true,
			};

		case 'ALERTS_ADD_MODAL_CLOSE':
			return {
				...initialState,
				addModalOpen: false,
			};

		case 'ALERTS_UPDATING':
			return {
				...initialState,
				updating: true,
			};

		case 'ALERTS_UPDATED':
			return {
				...initialState,
				updating: false,
			};

		case 'ALERTS_EDIT_MODAL_OPEN':
			return {
				...initialState,
				editMode: true,
			};

		case 'ALERTS_EDIT_MODAL_CLOSE':
			return {
				...initialState,
				editMode: false,
			};

		case 'ALERTS_RESTORE_MODAL_OPEN':
			return {
				...initialState,
				restoreModal: true,
			};

		case 'ALERTS_RESTORE_MODAL_CLOSE':
			return {
				...initialState,
				restoreModal: false,
			};

		case 'ALERTS_RESTORING':
			return {
				...initialState,
				restoring: true,
			};

		case 'ALERTS_RESTORED':
			return {
				...initialState,
				restoring: false,
			};

		case 'ALERTS_CHANGE_HISTORY_MODAL_OPEN':
			return {
				...initialState,
				changeHistoryModal: true,
			};

		case 'ALERTS_CHANGE_HISTORY_MODAL_CLOSE':
			return {
				...initialState,
				changeHistoryModal: false,
			};

		case 'ALERTS_IMPORT_MODAL_OPEN':
			return {
				...initialState,
				importModalOpen: true,
			};

		case 'ALERTS_IMPORT_MODAL_CLOSE':
			return {
				...initialState,
				importModalOpen: false,
			};

		case 'ALERTS_FILTER_MODAL_OPEN':
			return {
				...initialState,
				filterModalOpen: true,
			};

		case 'ALERTS_FILTER_MODAL_CLOSE':
			return {
				...initialState,
				filterModalOpen: false,
			};

		case 'ALERTS_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'ALERTS_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_ALERTS_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_ALERTS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_ALERTS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'ALERTS_UNMOUNT':
			return {
				...initialState,
				alerts: [],
				activeSort: '',
				sortOrder: '',
				activeIndex: -1,
				pageNumber: 1,
			};

		default:
			return initialState;
	}
}
