import styled from 'styled-components';

export const GraphCardHeader = styled.section`
	width: 100%;
	height: 3em;

	display: flex;
	align-items: center;
	justify-content: center;

	background: #f5f5f7;
	border-radius: 5px;
`;
