import React from "react";
import { Route, Switch } from "react-router-dom";
import SidebarHeader from "../../blocks/molecules/sidebar-header/SidebarHeader";
import SidebarListItem from "../../blocks/molecules/sidebar-list-item/SidebarListItem";
import { CategorySidebar } from "../../styles/containers/CategorySidebar";
import { CategoryContent } from "../../styles/containers/CategoryContent";
import { SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_EXPANDED_WIDTH } from "../../../../data/constants/dimensions";
import { Container } from "./DocumentStyle";
import documentImage from "../../../assets/images/documents.png";
import { collapseSidebar, expandSidebar } from "../../../../logic/middleware/ui";
import VehicleDocuments from "../../pages/secured/documents/VehicleDocuments";
import { connect } from "react-redux";
import UserDocuments from "../../pages/secured/documents/UserDocuments";
// import { IconContainer } from '../operations/OperationsView';
// import { Accordion } from 'semantic-ui-react';
import { COLORS } from "../../../styles/colors";
import SidebarToggleIcon from "../../blocks/atoms/sidebar-toggle-icon/SidebarToggleIcon";
import { entityAccessSetter } from "../../../../logic/utilities/accessSetter";
import { SidebarComponentList } from "../../styles/containers/SidebarComponentList";

// import { collapseSidebar, expandSidebar } from '../../../../logic/middleware/ui';

class Documents extends React.Component {
	constructor() {
		super();

		this.state = {
			activeIndex: -1,
			expandedAccordion: new Set(),
			manageDocumentAccess: false,
			readDocumentAccess: false,
			createDocumentAccess: false,
			updateDocumentAccess: false,
			deleteDocumentAccess: false,
		};
		this.manageAccessSetter = this.manageAccessSetter.bind(this);
	}

	handleClick = (title) => {
		let expandedAccordion = this.state.expandedAccordion;
		if (expandedAccordion.has(title)) {
			expandedAccordion.delete(title);
		} else expandedAccordion.add(title);

		this.setState({
			expandedAccordion,
		});
	};

	manageAccessSetter(rule, action) {
		this.setState({ [action]: rule });
	}

	componentDidMount() {
		const permissions = JSON.parse(localStorage.getItem('permissions'));
		const documentPermissions = permissions?.filter((permission) => permission.subject === 'Document');
		if (documentPermissions) {
			for (const documentPrm of documentPermissions) {
				entityAccessSetter(documentPrm?.action, 'Document', this.manageAccessSetter);
			}
		}
	}

	render() {
		const { sidebarExpanded } = this.props;
		const url = this.props.match.url;

		return (
			<Container>
				{/*Navigation*/}
				<CategorySidebar width={sidebarExpanded ? SIDEBAR_EXPANDED_WIDTH : SIDEBAR_COLLAPSED_WIDTH}>
					<SidebarHeader imageSource={documentImage} alt="Documents" name="Documents" expanded={sidebarExpanded} />
					<SidebarComponentList>
						<div
							style={{
								opacity: `${!(this.state.manageDocumentAccess || this.state.readDocumentAccess) ? '.4' : '1'}`,
								pointerEvents: `${!(this.state.manageDocumentAccess || this.state.readDocumentAccess) ? 'none' : ''}`,
							}}>
							<SidebarListItem
								path={`${url}`}
								exact={true}
								iconName="bus"
								name="Vehicle Documents"
								expanded={sidebarExpanded}
								background={
									this.state.expandedAccordion.has('vehicleDocuments') ? `${COLORS.sidebarAccordionBackground}` : ''
								}
							/>
						</div>
						{/*{sidebarExpanded && (*/}
						{/*	<IconContainer*/}
						{/*		active={this.props.location.pathname === '/operations/vehicleDocuments'}*/}
						{/*		expanded={this.state.expandedAccordion.has('vehicleDocuments')}>*/}
						{/*		<Icon*/}
						{/*			name={this.state.expandedAccordion.has('vehicleDocuments') ? 'minus circle' : 'plus circle'}*/}
						{/*			onClick={(e) => {*/}
						{/*				e.stopPropagation();*/}
						{/*				this.handleClick('vehicleDocuments');*/}
						{/*			}}*/}
						{/*		/>*/}
						{/*	</IconContainer>*/}
						{/*)}*/}
						{/*<Accordion.Content active={this.state.expandedAccordion.has('vehicleDocuments')}>*/}
						<div
							style={{
								opacity: `${!(this.state.manageDocumentAccess || this.state.readDocumentAccess) ? '.4' : '1'}`,
								pointerEvents: `${!(this.state.manageDocumentAccess || this.state.readDocumentAccess) ? 'none' : ''}`,
							}}>
							<SidebarListItem
								path={`${url}/user-documents`}
								iconName="user outline"
								name="User Documents"
								expanded={sidebarExpanded}
								background={
									this.state.expandedAccordion.has('vehicleDocuments') ? `${COLORS.sidebarAccordionBackground}` : ''
								}
							/>
						</div>
					</SidebarComponentList>
					{/*</Accordion.Content>*/}
					<SidebarToggleIcon
						title={sidebarExpanded ? 'Collapse Sidebar' : 'Expand Sidebar'}
						name={sidebarExpanded ? 'chevron circle left' : 'chevron circle right'}
						handleClick={sidebarExpanded ? collapseSidebar : expandSidebar}
					/>
				</CategorySidebar>

				<CategoryContent
					width={
						sidebarExpanded ? `calc(100% - ${SIDEBAR_EXPANDED_WIDTH})` : `calc(100% - ${SIDEBAR_COLLAPSED_WIDTH})`
					}>
					<Switch>
						<Route path={`${url}`} exact component={VehicleDocuments} />
						<Route path={`${url}/user-documents`} component={UserDocuments} />
					</Switch>
				</CategoryContent>
			</Container>
		);
	}
}

const mapStateToProps = (state) => ({
	sidebarExpanded: state.ui.sidebarExpanded,
});

export default connect(mapStateToProps)(Documents);
