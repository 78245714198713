export default function trialOrganisations(
	initialState = {
		organisations: [],
		loading: true,
		pageNumber: 1,
		count: 0,
		activeSort: '',
		sortOrder: '',
		filterModalOpen: false,

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
	},
	action
) {
	switch (action.type) {
		case 'FEED_TRIAL_ORGANISATIONS':
			return {
				...initialState,
				organisations: action.payload,
				loading: false,
			};

		case 'TRIAL_ORGANISATIONS_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'TRIAL_ORGANISATIONS_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'TRIAL_ORGANISATIONS_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'TRIAL_ORGANISATIONS_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'TRIAL_ORGANISATIONS_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'TRIAL_ORGANISATIONS_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'TRIAL_ORGANISATIONS_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'TRIAL_ORGANISATIONS_FILTER_MODAL_OPEN':
			return {
				...initialState,
				filterModalOpen: true,
			};

		case 'TRIAL_ORGANISATIONS_FILTER_MODAL_CLOSE':
			return {
				...initialState,
				filterModalOpen: false,
			};

		case 'TRIAL_ORGANISATIONS_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'TRIAL_ORGANISATIONS_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_TRIAL_ORGANISATIONS_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_TRIAL_ORGANISATIONS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_TRIAL_ORGANISATIONS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'TRIAL_ORGANISATIONS_UNMOUNT':
			return {
				...initialState,
				organisations: [],
				loading: true,
				pageNumber: 1,
				count: 0,
				activeSort: '',
				sortOrder: '',
				filterModalOpen: false,

				// Export
				openExportModal: false,
				exportData: [],
				exportingData: false,
			};

		default:
			return initialState;
	}
}
