import React from 'react';
import CommonModal from '../../../molecules/modal/CommonModal';
import { Button, Dropdown, Form, Icon, Input } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';

const AddModal = (props) => {
	const isSuperAdmin = localStorage.getItem('isSuperAdmin') === 'true' ? true : false;
	const FuelType = [
		{ key: 'Petrol', text: 'Petrol', value: 'Petrol' },
		{ key: 'Gasoline', text: 'Gasoline', value: 'Gasoline' },
		{ key: 'Diesel', text: 'Diesel', value: 'Diesel' },
		// { key: 'Electric', text: 'Electric', value: 'Electric'},
		{ key: 'Biodiesel', text: 'Biodiesel', value: 'Biodiesel' },
	];

	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			header="Add FuelLog"
			scrolling={true}
			content={
				<Form>
					<Form.Group widths="equal">
						<Form.Field required={true}>
							<label>Organisations</label>
							<Dropdown
								placeholder="Choose ..."
								fluid
								search
								clearable
								selection
								disabled={!isSuperAdmin}
								value={isSuperAdmin ? props.organistaionValue : props?.organisationsOptions[0]?.value}
								options={props.organisationsOptions}
								onChange={props.onOrgChange}
							/>
						</Form.Field>
						<Form.Field required={true}>
							<label>Vehicle</label>
							<Dropdown
								placeholder="Choose"
								fluid
								search
								selection
								clearable
								options={props.vehicleOptions}
								value={props.vehicle}
								onClick={
									!isSuperAdmin
										? () => {
												let data = { value: [props?.organisationsOptions[0]?.value], name: 'organisation' };
												props.onOrgChange(event, data);
										  }
										: undefined
								}
								onChange={props.onVehicleChange}
							/>
						</Form.Field>
						<Form.Field required={true}>
							<label> Date</label>
							<DateInput
								pointer
								placeholder="Select"
								fluid
								closable
								maxDate={moment()}
								clearable
								value={props.DateFA}
								onChange={props.onDateChnage}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field required={true}>
							<label>Refuel Quantity (L)</label>
							<Input type="number" min={0} fluid placeholder="Select" onBlur={props.onRefuelQuantity} />
						</Form.Field>
						<Form.Field required={true}>
							<label>Odometer(KM)</label>
							<Input type="number" min={0} fluid placeholder="Select" onBlur={props.onOdometer} />
						</Form.Field>
						<Form.Field required={true}>
							<label>Bill Amount (INR)</label>
							<Input type="number" min={0} fluid placeholder="Select" onBlur={props.onBillAmount} />
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field required={true}>
							<label>Fuel Type</label>
							<Dropdown
								placeholder="Choose ..."
								fluid
								search
								clearable
								selection
								options={FuelType}
								onChange={props.onFuelType}
							/>
						</Form.Field>
						<Form.Field>
							<label>Upload</label>
							<Input
								ref={props.fileInputRef}
								type="file"
								error={
									props.fileSizeError && {
										content: 'Document upload size should not exceed 5MB',
									}
								}
								accept=".jpg, .png, .pdf"
								{...(props.file && {
									icon: <Icon name="times circle outline" link onClick={props.handleFileReset} />,
								})}
								fluid
								onChange={(e) => props.handleFileChange(e)}
							/>
						</Form.Field>
					</Form.Group>
				</Form>
			}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button positive disabled={props.disabled} loading={props.addButtonLoading} onClick={props.onAddButtonClick}>
						Add
					</Button>
				</>
			}
		/>
	);
};

export default AddModal;
