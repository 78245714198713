import React from "react";
import { Button, Divider } from "semantic-ui-react";
import { BackButtonContainer, Container, FileHeader, Header, MainContainer, PdfContainer } from "./PDFViewStyles";
// import * as THEME from '../../styles/colors';
// import fileDownload from 'js-file-download';

function ImageView(props) {
	return (
		<MainContainer>
			<Container>
				<Header>
					<FileHeader>{props.documentFileType?.split('.')[0]}</FileHeader>
					<BackButtonContainer>
						<Divider />
						{/*<img*/}
						{/*	title={'Download File'}*/}
						{/*	// src={Download}*/}
						{/*	onClick={() => fileDownload(this.props.source, this.props.filename)}*/}
						{/*/>*/}
						{/*<div alt={'icon'} style={{ cursor: 'pointer', color: `${THEME.COLORS.theme}` }}>*/}
						{/*	{this.props.documentFileType}*/}
						{/*</div>*/}
						<Button circular color="black" icon="close" onClick={props.onClose} />
					</BackButtonContainer>
				</Header>
				<Divider />

				<PdfContainer>
					<img
						src={`data:image/*;base64,${props.source}`}
						// style={{ display: 'flex', height: '1000px', width: '50%' }}
						alt={'icon'}
					/>
				</PdfContainer>
			</Container>
		</MainContainer>
	);
}

export default ImageView;
