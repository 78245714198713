import React from 'react';
import { Expander } from '../../../styles/place-holders/Expander';
import PaginationDropdown from '../../atoms/dropdown/PaginationDropdown';
import { dispatch } from '../../../../../logic/middleware/middleware';
import { connect } from 'react-redux';
import { Container } from './PaginationStyles';
import { Icon, Pagination, Popup } from 'semantic-ui-react';

function FooterPagination(props) {
	const handleDataSizeLimitChange = (value) => {
		dispatch('SET_DATA_SIZE_LIMIT', value);

		props.handleDataSizeLimitChange();
	};

	const totalPage = Math.ceil(props.count / props.dataSizeLimit);

	return (
		<Container>
			<Expander />
			<PaginationDropdown
				defaultValue={props.dataSizeLimit}
				disabled={props.disabled || false}
				onChange={(e, { value }) => {
					handleDataSizeLimitChange(value);
				}}
			/>
			{!props.autoRefresh && (
				<Pagination
					boundaryRange={0}
					// defaultActivePage={1}
					activePage={props.pageNumber}
					disabled={props.disabled || false}
					ellipsisItem={null}
					// firstItem={null}
					// lastItem={null}
					siblingRange={1}
					pointing
					secondary
					// ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
					firstItem={{
						content: (
							<Popup
								disabled={props.pageNumber == 1}
								content="Move to 1st page"
								position="top left"
								trigger={<Icon name="angle double left" disabled={props.pageNumber == 1} />}
							/>
						),
						icon: true,
					}}
					lastItem={{
						content: (
							<Popup
								disabled={props.pageNumber == totalPage}
								content="Move to last page"
								position="top left"
								trigger={<Icon name="angle double right" disabled={props.pageNumber == totalPage} />}
							/>
						),
						icon: true,
					}}
					prevItem={{
						content: (
							<Popup
								disabled={props.pageNumber == 1}
								content="Move to prev page"
								position="top left"
								trigger={<Icon name="angle left" disabled={props.pageNumber == 1} />}
							/>
						),
						icon: true,
					}}
					nextItem={{
						content: (
							<Popup
								disabled={props.pageNumber == totalPage}
								content="Move to next page"
								position="top left"
								trigger={<Icon name="angle right" disabled={props.pageNumber == totalPage} />}
							/>
						),
						icon: true,
					}}
					totalPages={totalPage}
					onPageChange={(e, { activePage }) => {
						props.handlePageChange(activePage);
					}}
				/>
			)}
		</Container>
	);
}

// Connect to Store
const mapStateToProps = (state) => ({
	dataSizeLimit: state.ui.dataSizeLimit,
});

export default connect(mapStateToProps)(FooterPagination);
