import React from 'react';

import { GraphCard } from '../../../../styles/containers/GraphCard';
import { GraphCardMain } from '../../../../styles/containers/GraphCardMain';
import { GraphTooltip } from '../../../../styles/containers/GraphTooltip';
import { GraphCardHeader } from '../../../../styles/containers/GraphCardHeader';
import { GraphCardFooter } from '../../../../styles/containers/GraphCardFooter';
import GraphLoader from '../../../molecules/graph-loader/GraphLoader';

import moment from 'moment';
import { ResponsiveLine } from '@nivo/line';

const NetPromoterScoreGraph = () => {
	const dataLength = this.props?.data[0]?.data?.length || 6;

	return (
		<GraphCard>
			{this.props.loading && <GraphLoader />}
			<GraphCardHeader>
				<h3>Net Promoter Score</h3>
			</GraphCardHeader>
			<GraphCardMain>
				<ResponsiveLine
					data={this.props.data}
					margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
					xScale={{ type: 'point' }}
					yScale={{ type: 'linear', min: '0', max: '100', stacked: true, reverse: false }}
					yFormat=" >-.2f"
					label={null}
					axisTop={null}
					axisRight={null}
					axisLeft={{
						orient: 'left',
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						format: (value) => `${value}`,
					}}
					axisBottom={{
						tickRotation: dataLength < 7 ? 0 : -45,
						format: (tick) => {
							if (dataLength < 7) return moment(tick, 'MMM YYYY').format('MMM YYYY');
							else if (dataLength >= 7 && dataLength <= 18) return moment(tick, 'MMM YYYY').format('MM/YY');
							else return '';
						},
					}}
					pointSize={10}
					pointColor="#fff"
					pointBorderWidth={2}
					pointBorderColor="#7ddfc3"
					colors="#7ddfc3"
					enableCrosshair
					motion={true}
					motionConfig="gentle"
					tooltip={(value) => (
						<GraphTooltip>
							{moment(value.point.data.x, 'MMM YYYY').format('MMM, YYYY')} : Score {value.point.data.y}
						</GraphTooltip>
					)}
					useMesh={true}
				/>
			</GraphCardMain>
			<GraphCardFooter>
				<span>X = Months, Y = Score</span>
			</GraphCardFooter>
		</GraphCard>
	);
};

export default NetPromoterScoreGraph;
