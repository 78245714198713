import axios from 'axios';
import { DOCUMENTS_API, EXPORT_API } from '../configs/api';
import { createVehicleDTO } from '../dto/vehicles';
import { handleError } from './messaging';
import { fetchVehicles } from './vehicles';
import { exportToExcelWithLogo } from '../utilities/excel';
import { VEHICLE_DOCUMENTS_FILE_HEADERS } from '../../data/constants/general';
import { closeExportModal } from './devices';
import { jsPDF } from 'jspdf';
import Logo from '../../ui/assets/images/logo.png';
import { exportApi, getData, patchData, postData } from './services/apiRequest';
import fileDownload from 'js-file-download';

export const fetchVehicleDocuments = (query) => {
	return getData(DOCUMENTS_API + query);
	// axios(DOCUMENTS_API + query, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// });
};

export function getVehiclesForDropdown(params) {
	return fetchVehicles(params)
		.then((response) => {
			return createVehicleDTO(response?.data?.data).map((vehicle, index) => {
				return {
					key: index,
					value: vehicle.id,
					text: vehicle.registrationNumber,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			return [];
		});
}

export function postAddRequest(formData) {
	return postData(DOCUMENTS_API, '', formData);
	// axios.post(DOCUMENTS_API, formData, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'multipart/form-data',
	// 	},
	// });
}

export function patchEditRequest(id, formData) {
	return patchData(DOCUMENTS_API + `/${id}`, formData);
	// axios.patch(DOCUMENTS_API + `/${id}`, formData, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'multipart/form-data',
	// 	},
	// });
}

export function fetchDocumentFile(id) {
	return exportApi(EXPORT_API, `/documents/${id}`);
	// axios(EXPORT_API + `/documents/${id}`, {
	// 	responseType: 'arraybuffer',
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// });
}

export function exportAll(param, fileExtension) {
	exportApi(EXPORT_API + '/documents', param)
		.then((response) => {
			fileDownload(response?.data, `VehicleDocument.${fileExtension}`);
		})
		.catch((error) => {
			handleError(error);
		});
}

export function exportData(data, fileName, extension) {
	if (extension === 'excel') {
		exportToExcelWithLogo(
			formatExcelExport(data),
			`${!fileName ? 'Devices' : fileName}`,
			VEHICLE_DOCUMENTS_FILE_HEADERS
		);
	} else {
		convertToPdf(formatExcelExport(data), fileName);
	}

	closeExportModal();
}

export function formatExcelExport(response) {
	return response?.map((document) => {
		return {
			'Document Type': document.documentType,
			'Vehicle Number': document.vehicleNumber,
			Organisation: document.organisationName,
			'Expiry Date': document.documentExpiry,
			Description: document.documentDescription,
		};
	});
}

export function convertToPdf(data, fileName) {
	const doc = new jsPDF({ orientation: 'l', unit: 'mm', format: 'a4' });

	let col = ['Document Type', 'Vehicle Number', 'Organisation', 'Expiry Date', 'Description'];
	let rows = [];

	data?.forEach((document) => {
		let temp = [];
		Object.keys(document).forEach((key, index) => {
			if (col[index] === key) temp[index] = document[key];
		});

		rows.push(temp);
	});

	let header = function (data) {
		doc.addImage(Logo, 'png', data.settings.margin.left, 10, 40, 14);
	};

	doc.autoTable(col, rows, {
		margin: { top: 25 },
		beforePageContent: header,
		theme: 'grid',
	});

	doc.save(`${!fileName ? 'Devices' : fileName}`);
}

export const deleteDocument = (id) => {
	return axios.delete(DOCUMENTS_API + `/${id}`, {
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json',
		},
	});
};

export function documentRestore(id) {
	return postData(DOCUMENTS_API, `/${id}/restore`);
	// axios.post(
	// 	DOCUMENTS_API + `/${id}/restore`,
	// 	{},
	// 	{
	// 		headers: {
	// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 			'Content-Type': 'application/json',
	// 		},
	// 	}
	// );
}
