import React from 'react';

import CommonModal from '../../../molecules/modal/CommonModal';
import { validateImeiNumber, validateMobileNumber } from '../../../../../../logic/utilities/validator';

import { Button, Dropdown, Form, Input } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';

const AddModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			header="Add Device"
			content={
				<Form>
					<Form.Group widths="equal">
						<Form.Field
							required={true}
							control={Input}
							label="IMEI"
							error={
								!validateImeiNumber(props.imei) && {
									content: 'Imei number should be 15 digit.',
								}
							}>
							<Input placeholder="IMEI / ID for MT100 Devices" fluid onChange={props.onImeiChange} />
						</Form.Field>
						<Form.Field
							control={Input}
							label="Serial No."
							error={
								!validateMobileNumber(props.serialNo) && {
									content: 'Serial number should be 10 digit.',
								}
							}>
							<Input fluid placeholder="Serial No / IMEI for MT100 Devices" onChange={props.onSerialNoChange} />
						</Form.Field>
						<Form.Field required={true}>
							<label>Model</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								selection
								clearable
								options={props.deviceModels}
								onChange={props.onDeviceModelChoose}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field required={true} control={Input} label="Invoice Number">
							<Input placeholder="Invoice Number" fluid onChange={props.onInvoiceNumberChangeFA} />
						</Form.Field>
						<Form.Field required={true}>
							<label>Invoice Date</label>
							<DateInput
								placeholder="Select"
								fluid
								closable
								clearable
								maxDate={new Date()}
								value={props.invoiceDateFA}
								onChange={props.onInvoiceDateChangeFA}
							/>
						</Form.Field>
					</Form.Group>
					{/* <Form.Group widths="equal">
              <Form.Field>
                <label>Invoice Number</label>
                <Input fluid placeholder="Optional" onChange={this.props.onInvoiceNumberChangeFA} />
              </Form.Field>

              <Form.Field>
                <label>Purchase Date</label>
                <DateInput
                  placeholder="Select"
                  fluid
                  closable
                  value={this.props.dateOfPurchaseFA}
                  onChange={this.props.onDateOfPurchaseChange}
                />
              </Form.Field>
              <Form.Field>
                <label>Supplier Name</label>
                <Input fluid placeholder="Optional" onChange={this.props.onSupplierNameChangeFA} />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Note</label>
                <Input fluid placeholder="Optional" onChange={this.props.onNoteChange} />
              </Form.Field>
            </Form.Group> */}
				</Form>
			}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button loading={props.addButtonLoading} positive disabled={props.disabled} onClick={props.onAddButtonClick}>
						Add
					</Button>
				</>
			}
		/>
	);
};

export default AddModal;
