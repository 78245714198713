import styled from 'styled-components';

export const Container = styled.section`
	width: 6em;

	margin-right: 1em;

	border: none;
	border-radius: 5px;
	box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;
`;
