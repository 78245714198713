import React from 'react';

import CommonModal from '../../../molecules/modal/CommonModal';

import { Button, Form } from 'semantic-ui-react';

const RemoveSimModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="small"
			header="Remove Sim"
			content={
				<Form>
					<Form.Group inline>
						<Form.Radio
							label="Sim 1"
							value="sim1"
							disabled={!props.sim1Id}
							checked={props.selectedSim === 'sim1'}
							onChange={props.onSelectRemoveSim}
						/>
						<Form.Radio
							label="Sim 2"
							value="sim2"
							disabled={!props.sim2Id}
							checked={props.selectedSim === 'sim2'}
							onChange={props.onSelectRemoveSim}
						/>
					</Form.Group>
					{/*<Form.Field>*/}
					{/*  <label>Reason</label>*/}
					{/*  <Dropdown*/}
					{/*    placeholder="Choose ..."*/}
					{/*    fluid*/}
					{/*    search*/}
					{/*    selection*/}
					{/*    options={REMOVE_SIM_REASONS}*/}
					{/*    value={this.props.reason}*/}
					{/*    onChange={this.props.onReasonSelect}*/}
					{/*  />*/}
					{/*</Form.Field>*/}
				</Form>
			}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button negative loading={props.RemoveButtonLoading} onClick={props.onRemoveButtonClick}>
						Remove
					</Button>
				</>
			}
		/>
	);
};

export default RemoveSimModal;
