import moment from 'moment';

export default function simBills(
	initialState = {
		bills: [],
		loading: true,
		activeSort: '',
		sortOrder: '',
		activeIndex: -1,
		pageNumber: 1,
		count: 0,
		adding: false,
		addModalOpen: false,
		initialDate: moment().startOf('month').utc(true).toISOString(),
		finalDate: moment().endOf('month').utc(true).toISOString(),
		filterModalOpen: false,

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
	},
	action
) {
	switch (action.type) {
		case 'FEED_SIM_BILLS':
			return {
				...initialState,
				bills: action.payload,
				loading: false,
			};

		case 'SIM_BILLS_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'SIM_BILLS_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'SIM_BILLS_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'SIM_BILLS_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'SIM_BILLS_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'SIM_BILLS_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'SIM_BILLS_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'SIM_BILLS_ADDING':
			return {
				...initialState,
				adding: true,
			};

		case 'SIM_BILLS_ADDED':
			return {
				...initialState,
				adding: false,
			};

		case 'SIM_BILLS_ADD_MODAL_OPEN':
			return {
				...initialState,
				addModalOpen: true,
			};

		case 'SIM_BILLS_ADD_MODAL_CLOSE':
			return {
				...initialState,
				addModalOpen: false,
			};

		case 'SIM_BILLS_INITIAL_DATE':
			return {
				...initialState,
				initialDate: action.payload,
			};

		case 'SIM_BILLS_FINAL_DATE':
			return {
				...initialState,
				finalDate: action.payload,
			};

		case 'SIM_BILLS_RESET_DATES':
			return {
				...initialState,
				initialDate: moment().startOf('month').utc(true).toISOString(),
				finalDate: moment().endOf('month').utc(true).toISOString(),
			};

		case 'SIM_BILLS_FILTER_MODAL_OPEN':
			return {
				...initialState,
				filterModalOpen: true,
			};

		case 'SIM_BILLS_FILTER_MODAL_CLOSE':
			return {
				...initialState,
				filterModalOpen: false,
			};

		case 'SIM_BILLS_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'SIM_BILLS_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_SIM_BILLS_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_SIM_BILLS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_SIM_BILLS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'SIM_BILLS_UNMOUNT':
			return {
				...initialState,
				bills: [],
				activeSort: '',
				sortOrder: '',
				activeIndex: -1,
				pageNumber: 1,
				initialDate: moment().startOf('month').utc(true).toISOString(),
				finalDate: moment().endOf('month').utc(true).toISOString(),
			};

		default:
			return initialState;
	}
}
