// Device

export const DEVICE_STATUS_COLOR = {
	New: 'green',
	Technician: 'olive',
	Client: 'olive',
	Vehicle: 'green',
	Missing: 'red',
	Terminated: 'black',
	'No Data': 'black',
	'Sent for Service': 'blue',
	Repaired: 'orange',
	Replaced: 'yellow',
};

// Commands Pipeline
export const COMMANDS_PIPELINE_STATUS = {
	Pipeline: 1,
	Sent: 2,
	'Not Sent': 3,
	Received: 4,
	Deleted: 5,
};

export const COMMANDS_PIPELINE_SOURCE = {
	API: 1,
	Server: 2,
};

export const COMMANDS_PIPELINE_MESSAGE_TYPE = {
	Request: 1,
	Response: 2,
};

// SIM
export const SIM_STATUS = {
	New: 'New',
	// 'No Data': 'No Data',
	Device: 'Device',
	Terminated: 'Terminated',
};

// Users
export const USERS_ROLE = {
	Admin: 1,
	'Account Admin': 3,
};

export const USERS_TYPE = {
	Default: 1,
	Driver: 2,
};

// People Users
export const PEOPLE_USERS_ROLE = {
	Admin: 1,
	Employer: 2,
	Employee: 4,
};

// SMS Reports
export const SMS_REPORT_STATUS = {
	Sent: 1,
	'No Sent': 2,
	Delivered: 3,
	'Not Delivered': 4,
};

export const EVENT_TYPE = {
	Ignition: 1,
	Movement: 2,
	Immobilizer: 3,
	GeoFence: 4,
	'Main Power': 5,
	Delivery: 6,
	'Over Speeding': 7,
	'Tracking Link': 10,
};

// Subscriptions
export const SUBSCRIPTIONS_STATUS = {
	Pending: 1,
	Active: 2,
	'Due for Renewal': 3,
	Expired: 4,
	'Expired and Terminated': 6,
};

export const BILLING_PERIOD = {
	Monthly: 1,
	Quarterly: 3,
	'Half Yearly': 6,
	Yearly: 12,
};

// Sim Bills
export const SIM_BILLS_STATUS = {
	Used: 1,
	'Not Used': 2,
};

// Saas Plans
export const SAAS_PLANS_PRODUCT = {
	Fuel: 1,
	FMS: 2,
};

export const SAAS_PLANS_STATUS = {
	Activate: 1,
	Customize: 2,
};

// Organisations
export const ORGANISATIONS_SOLUTIONS = {
	GPS: 1,
	FMS: 2,
	'GPS/FMS': 3,
};

export const PREFERRED_COMMUNICATION_CHANNEL = {
	Phone: 1,
	SMS: 2,
	Email: 3,
	Whatsapp: 4,
};
