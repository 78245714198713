import React, { useEffect, useRef, useState } from "react";
import { CategoryHeader } from "../../styles/containers/CategoryHeader";
import { CategoryContentMembrane } from "../../styles/containers/CategoryContentMembrane";
import Calendar from "../../../common/calender/standard/Calender";
import { Accordion, Button, Dropdown, Header, Icon, Segment } from "semantic-ui-react";
import { TESTCASE_OPTIONS } from "../../../../data/constants/dropdown-options";
import { getData, postData } from "../../../../logic/middleware/services/apiRequest";
import {
  ORGANISATIONS_API,
  ORGANISATIONS_DASHBOARD_API,
  VEHICLES_API,
  VEHICLES_DASHBOARD_API
} from "../../../../logic/configs/api";
import { handleError } from "../../../../logic/middleware/messaging";
import { connect } from "react-redux";
import moment from "moment/moment";
import { AccordionHeader, HeaderElement, Row, RowElement } from "../../styles/Accordion";
import { AccordionMain } from "../../styles/containers/AccordionMain";
import { CategoryMain } from "../../styles/containers/CategoryMain";
import { AccordionMainContent } from "../../styles/containers/AccordionMainContent";
import { isoToHumanReadable } from "../../../../logic/utilities/date";
import PlaceHolder from "../../blocks/molecules/place-holder/PlaceHolder";
import { Container } from "../../blocks/molecules/place-holder/PlaceHolderStyles";
// import { ButtonContainer } from "../../../common/import/ImportStyles";

const DataAutomation = (props) => {
	const [vehicleDropdownOptions, setVehicleDropdownOptions] = useState([]);
	const [vehicleDropdownInitOptions, setVehicleDropdownInitOptions] = useState([]);
	const [testCase, setTestCase] = useState('');
	const [vehicleDropdownVal, setVehicleDropdownVal] = useState([]);
	const [vehicles, setVehicles] = useState([]);
	const [dashboardResponse, setDashboardResponse] = useState([]);
	const [lokkateDashboardResponse, setLokkateDashboardResponse] = useState([]);
	const [testResults, setTestResults] = useState([]);
	const [orgDashboardRes, setOrgDashboardRes] = useState([]);
	const [loading, setLoading] = useState(true);
	// const [isDisabled, setIsDisabled] = useState(false);
	const [activeIndexes, setActiveIndexes] = useState([]);
	const [trialVehicleCount, settrialVehicleCount] = useState();
	const BASE_URL = process.env.REACT_APP_BASE_URL;

	const minDate = moment().subtract(7, 'days').format('DD-MM-YY');
	const maxDate = moment().format('DD-MM-YY');

	const accordionContentRef = useRef(null);

	const scrollToAccordionContent = () => {
		if (accordionContentRef && accordionContentRef.current) {
			accordionContentRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}
	};

	useEffect(() => {
		scrollToAccordionContent();
	}, [scrollToAccordionContent]);

	useEffect(() => {
		getData(VEHICLES_API, '?dropdown=true')
			.then((response) => {
				let vehicleDropdown = response.data.data.map((vehicle, index) => {
					return {
						key: index,
						value: vehicle.id,
						text: vehicle.registrationNumber,
					};
				});
				setVehicleDropdownOptions(vehicleDropdown);
				setVehicleDropdownInitOptions(vehicleDropdown);
			})
			.catch((error) => handleError(error));

		getData(VEHICLES_DASHBOARD_API, '?days=60')
			.then((dashboardRes) => {
				setDashboardResponse(dashboardRes.data.metadata);
			})
			.catch((error) => handleError(error));

		getData(ORGANISATIONS_DASHBOARD_API, '?days=60')
			.then((dashboardOrgRes) => {
				setOrgDashboardRes(dashboardOrgRes.data.metadata);
			})
			.catch((error) => handleError(error));

		getData(BASE_URL + 'v2/dashboard/summary')
			.then((res) => {
				setLokkateDashboardResponse(res?.data.metadata);
			})
			.catch((error) => handleError(error));
	}, []);

	const isoToCustom = (timestamp, format) => {
		return moment(timestamp).format(format);
	};

	const handleRemoveRow = (e, rowIdx) => {
		const temp = [...testResults];
		temp.splice(rowIdx, 1);
		setTestResults(temp);
	};

	const toggleAccordion = (e, titleProps) => {
		const { index } = titleProps;
		const newIndex = [...activeIndexes];
		const currentIndexPosition = activeIndexes.indexOf(index);
		if (currentIndexPosition > -1) {
			newIndex.splice(currentIndexPosition, 1);
		} else {
			newIndex.push(index);
		}
		setActiveIndexes(newIndex);
	};

	const getTestResponse = async (params, key) => {
		if (params) {
			const vehicleRes = await getData(VEHICLES_API, `?${params}`);
			settrialVehicleCount(vehicleRes.data.count);
			if (vehicleRes.data.count === dashboardResponse[key]) {
				return {
					result: 'Passed',
				};
			} else {
				return {
					result: 'Failed',
					dashboardCount: vehicleRes.data.count,
					testcaseCount: dashboardResponse[key],
				};
			}
		}
	};
	const getOrgTestResponse = async (params, key) => {
		if (params) {
			const organisationRes = await getData(ORGANISATIONS_API, `?${params}`);
			if (organisationRes.data.count === orgDashboardRes[key]) {
				return {
					result: 'Passed',
				};
			} else {
				return {
					result: 'Failed',
					dashboardCount: organisationRes.data.count,
					testcaseCount: orgDashboardRes[key],
				};
			}
		}
	};

	const getLokkateTestResponse = async (body, selectedVehicle) => {
		if (body) {
			const response = await postData(BASE_URL + `v1/summary`, '', body);
			let distanceSum = response.data.data;

			let totalDistanceByTrack =
				distanceSum &&
				distanceSum.reduce((sum, obj) => {
					return sum + obj.distance;
				}, 0);
			totalDistanceByTrack = (totalDistanceByTrack / 1000).toFixed(1);

			let totalDistance;
			lokkateDashboardResponse.mostUsedVehicles.forEach((eachVehicle) => {
				if (selectedVehicle.text === eachVehicle.registrationNumber) {
					totalDistance = (eachVehicle.distance / 1000).toFixed(2);
				}
			});

			if (totalDistanceByTrack === totalDistance) {
				return {
					result: 'Passed',
				};
			} else {
				return {
					result: 'Failed',
					dashboardCount: totalDistance,
					testcaseCount: totalDistanceByTrack,
				};
			}
		}
	};

	const trackTestResponse = async (body, currSelectedVehicle, currVehicle) => {
		const response = await postData(BASE_URL + `v1/summary`, '', body);
		if (response.data.data && response.data.data === 0) return 'Failed';

		const distanceSum = response.data.data;

		let totalDistance = distanceSum.reduce((sum, obj) => sum + obj.distance, 0);
		totalDistance = (totalDistance / 1000).toFixed(1);

		const filteredVehicleDistanceByDist = lokkateDashboardResponse.mostRunVehicles.filter((eachVehicle) => {
			return currSelectedVehicle.includes(eachVehicle.registrationNumber);
		});

		let filteredTotalDistance;
		filteredVehicleDistanceByDist.forEach((vehicle) => {
			if (vehicle.registrationNumber === currVehicle.text) {
				filteredTotalDistance = (vehicle.topVehicleDistance / 1000).toFixed(1);
			}
		});

		if (totalDistance === filteredTotalDistance) {
			return {
				result: 'Passed',
			};
		} else {
			return {
				result: 'Failed',
				dashboardCount: filteredTotalDistance,
				testcaseCount: totalDistance,
			};
		}
	};

	const getIdleTestResponse = async (body) => {
		const response = await postData(BASE_URL + `v1/summary`, '', body);
		const idleHours = response.data.data;

		let idlingHours = idleHours.reduce((sum, obj) => sum + obj.idleHours, 0);
		idlingHours = (idlingHours / 3600).toFixed(2);

		const highestIdleVehicle = lokkateDashboardResponse.mostIdleVehicles.filter(
			(eachVehicle) => vehicles[0].text === eachVehicle.registrationNumber
		);

		let totalIdleHours = highestIdleVehicle.reduce((sum, eachVehicle) => sum + eachVehicle.idleHours, 0);

		totalIdleHours = (totalIdleHours / 3600).toFixed(2);

		if (idlingHours === totalIdleHours) {
			return {
				result: 'Passed',
			};
		} else {
			return {
				result: 'Failed',
				dashboardCount: idlingHours,
				testcaseCount: totalIdleHours,
			};
		}
	};

	const getKMTestResponse = async (body) => {
		const response = await postData(BASE_URL + `v1/trips`, '', body);
		const totalTripsDistance = response.data.data;

		const summaryDistance = await postData(BASE_URL + `v1/summary`, '', body);
		const totalSummaryDistance = summaryDistance.data.data;

		let totalTripsDist = totalTripsDistance?.reduce((sum, obj) => sum + obj.distance, 0);
		totalTripsDist = (totalTripsDist / 1000).toFixed(1);

		let totalSummaryDist = totalSummaryDistance.reduce((sum, obj) => sum + obj.distance, 0);
		totalSummaryDist = (totalSummaryDist / 1000).toFixed(1);

		const failureSources = [];

		if (totalTripsDistance.length === 0 && totalSummaryDistance.length === 0) {
			failureSources.push('Trips and Summary');
		} else if (totalTripsDist !== totalSummaryDist) {
			failureSources.push('Trips vs Summary');
		}

		if (totalTripsDist !== totalSummaryDist) {
			failureSources.push('Trips vs Summary');
		}

		if (failureSources.length > 0) {
			return {
				result: 'Failed',
				totalTripsDist: totalTripsDist,
				// totalTrackDist: totalTrackDist,
				totalSummaryDist: totalSummaryDist,
			};
		} else {
			return {
				result: 'Passed',
			};
		}
	};

	const getSummaryTestResponse = async (body) => {
		const summaryData = await postData(BASE_URL + `v1/summary`, '', body);
		const allSummaryData = summaryData.data.data;

		const eventsData = await postData(BASE_URL + `v1/events`, '', body);
		const allEventsData = eventsData.data.data;

		const tripsData = await postData(BASE_URL + `v1/trips`, '', body);
		const totalTripsData = tripsData.data.data;
		const totalTripsCount = tripsData.data.count;
		const summaryTripCount = allSummaryData[0]?.tripCount || 0;

		let tripsEngineHours = totalTripsData.reduce((sum, obj) => sum + obj.engineHours, 0);
		tripsEngineHours = (tripsEngineHours / 3600).toFixed(2);

		let summaryEngineHours = allSummaryData.reduce((sum, obj) => sum + obj.engineHours, 0);
		summaryEngineHours = (summaryEngineHours / 3600).toFixed(2);

		const ignitionOnTimestamps = allEventsData
			.filter((event) => event.status === true)
			.map((filteredEvent) => isoToCustom(filteredEvent.timestamp, 'hh:mm:ss A'));

		const ignitionOffTimestamps = allEventsData
			.filter((event) => event.status === false)
			.map((filteredEvent) => isoToCustom(filteredEvent.timestamp, 'hh:mm:ss A'));

		const summaryIgnitionOnMatch = allSummaryData.map((ignition) => {
			if (ignitionOnTimestamps.includes(isoToCustom(ignition.firstIgnitionOn, 'hh:mm:ss A'))) {
				return isoToCustom(ignition.firstIgnitionOn, 'hh:mm:ss A');
			}
			return isoToCustom(ignition.firstIgnitionOn, 'hh:mm:ss A');
		});

		const summaryIgnitionOffMatch = allSummaryData.map((ignition) => {
			if (ignitionOffTimestamps.includes(isoToCustom(ignition.lastIgnitionOff, 'hh:mm:ss A'))) {
				return isoToCustom(ignition.lastIgnitionOff, 'hh:mm:ss A');
			}
			return null;
		});

		const failedCases = [];
		if (tripsEngineHours !== summaryEngineHours) {
			failedCases.push('Trips engine vs. Summary engine hours');
		}
		if (totalTripsCount !== summaryTripCount) {
			failedCases.push('Trips count Vs. Summary trips count');
		}
		if (summaryIgnitionOnMatch !== summaryIgnitionOffMatch) {
			failedCases.push('Ignition on Vs. Ignition off');
		}
		if (failedCases.length > 0) {
			return {
				result: `Failed: Data does not match with ${failedCases.join(' ,')}`,
				tripsEngineHours: tripsEngineHours,
				totalTripsCount: totalTripsCount,
				summaryEngineHours: summaryEngineHours,
				ignitionOn: summaryIgnitionOnMatch,
				ignitionOff: summaryIgnitionOffMatch,
				summaryTripCount: summaryTripCount,
			};
		} else {
			return 'Passed';
		}
	};

	const handleTestCaseChange = (e, value) => {
		setTestCase(value);
		// let vehicleDropdownText = vehicleDropdownInitOptions.map((vehicle) => vehicle.text);
		setVehicles([]);
		setVehicleDropdownVal([]);
		// setIsDisabled(true);
		// let tests = [
		// 	'MVP Lokkate Dash Check',
		// 	'Trial',
		// 	'Deactivated',
		// 	'Recent Vehicle',
		// 	'Recent Org',
		// 	'immobilized',
		// 	'mobilized',
		// ];
		// if (tests.includes(value)) setIsDisabled(true);

		if (value === 'MVP Lokkate Dash Check') {
			let mostRunVehicles = lokkateDashboardResponse.mostRunVehicles.map((vehicle) => {
				return {
					registrationNumber: vehicle.registrationNumber,
					date: vehicle.date,
				};
			});

			let mostRunVehicleNumbers = mostRunVehicles.map((vehicle) => vehicle.registrationNumber);
			let selectedVehicles = vehicleDropdownInitOptions.filter((vehicle) =>
				mostRunVehicleNumbers.includes(vehicle.text)
			);
			let kvPair = {};
			for (const vehicle of selectedVehicles) {
				kvPair[vehicle.text] = vehicle;
			}
			selectedVehicles = mostRunVehicles.map((vehicle) => {
				return {
					...kvPair[vehicle.registrationNumber],
					date: vehicle.date,
				};
			});
			// let selectedVehicleValues = selectedVehicles.map((vehicle) => vehicle.value);
			setVehicles(selectedVehicles);
			// setVehicleDropdownVal(selectedVehicleValues);
		}
		if (value === 'Idling Lokkate Dash Check') {
			let vehicleNumbers = lokkateDashboardResponse.mostIdleVehicles?.map((res) => res.registrationNumber);
			let selectedVehicles = vehicleDropdownInitOptions.filter((vehicle) => vehicleNumbers.includes(vehicle.text));
			setVehicleDropdownOptions(selectedVehicles);
		} else if (value === 'Performers Lokkate Dash Check') {
			let vehicleNumbers = lokkateDashboardResponse.mostUsedVehicles?.map((res) => res.registrationNumber);
			let selectedVehicles = vehicleDropdownInitOptions.filter((vehicle) => vehicleNumbers.includes(vehicle.text));
			setVehicleDropdownOptions(selectedVehicles);
		} else {
			setVehicleDropdownOptions(vehicleDropdownInitOptions);
		}
	};

	const onTestCaseSubmit = async () => {
		let testResult;

		if (testCase === 'Trial') {
			testResult = await getTestResponse('status[]=Trial', 'trialVehicles');
		}

		if (testCase === 'Deactivated') testResult = await getTestResponse('status[]=Paused', 'deactivatedVehicles');
		if (testCase === 'Recent Vehicle') {
			testResult = await getTestResponse(
				`installationStartDate=${moment().subtract(60, 'days').toISOString()}&installationEndDate=${moment()
					.endOf('day')
					.toISOString()}`,
				'newVehicles'
			);
		}
		if (testCase === 'Recent Org') {
			testResult = await getOrgTestResponse(
				`createdStartDate=${moment().subtract(60, 'days').toISOString()}&createdEndDate=${moment()
					.endOf('day')
					.toISOString()}`,
				'newOrganisations'
			);
		}
		if (testCase === 'immobilized') {
			testResult = await getTestResponse('relayStatus=true', 'immobilizedVehicles');
		}
		if (testCase === 'mobilized') {
			testResult = await getTestResponse('relayStatus', 'mobilizedVehicles');
		}

		if (testCase === 'MVP Lokkate Dash Check') {
			let results = [];

			const selectedVehicleText = vehicles.map((vehicle) => vehicle.text);
			for (const vehicle of vehicles) {
				testResult = await trackTestResponse(
					{
						startTime: `${moment(vehicle.date).startOf('day').toISOString()}`,
						endTime: `${moment(vehicle.date).endOf('day').toISOString()}`,
						interval: 'daily',
						vehicleIds: [vehicle.value],
					},
					selectedVehicleText,
					vehicle
				);
				let result = {
					testCase,
					testResult,
					vehicle: vehicle.text,
					initialDate: `${moment(vehicle.date).startOf('day').toISOString()}`,
					finalDate: `${moment(vehicle.date).endOf('day').toISOString()}`,
				};
				results.push(result);
			}
			setLoading(false);
			setTestResults([...results, ...testResults]);
			return;
		}
		if (testCase === 'Performers Lokkate Dash Check') {
			let results = [];
			for (const vehicle of vehicles) {
				testResult = await getLokkateTestResponse(
					{
						startTime: `${moment().subtract(90, 'days').toISOString()}`,
						endTime: `${moment().endOf('day').toISOString()}`,
						interval: 'monthly',
						vehicleIds: [vehicle.value],
					},
					vehicle
				);
				let result = {
					testResult,
					testCase,
					vehicle: vehicle.text,
					initialDate: `${moment().subtract(90, 'days').toISOString()}`,
					finalDate: `${moment().endOf('day').toISOString()}`,
				};
				results.push(result);
			}
			setLoading(false);
			setTestResults([...testResults, ...results]);
			return;
		}

		if (testCase === 'Idling Lokkate Dash Check') {
			let results = [];
			for (const vehicle of vehicles) {
				testResult = await getIdleTestResponse(
					{
						startTime: props.initialDate,
						endTime: props.finalDate,
						interval: 'daily',
						vehicleIds: [vehicle.value],
					},
					vehicle
				);
				let result = {
					testResult,
					testCase,
					vehicle: vehicle.text,
					initialDate: props.initialDate,
					finalDate: props.finalDate,
				};
				results.push(result);
			}
			setLoading(false);
			setTestResults([...testResults, ...results]);
			return;
		}

		if (testCase === 'KM Match - Lokkate Check') {
			let results = [];
			for (const vehicle of vehicles) {
				testResult = await getKMTestResponse(
					{
						startTime: props.initialDate,
						endTime: props.finalDate,
						// interval: 'daily',
						vehicleIds: [vehicle.value],
					},
					vehicle
				);
				let result = {
					testResult,
					testCase,
					vehicle: vehicle.text,
					initialDate: props.initialDate,
					finalDate: props.finalDate,
				};
				results.push(result);
			}
			setLoading(false);
			setTestResults([...testResults, ...results]);
			return;
		}

		if (testCase === 'Summary Accuracy - Lokkate Check') {
			let results = [];
			for (const vehicle of vehicles) {
				testResult = await getSummaryTestResponse(
					{
						startTime: props.initialDate,
						endTime: props.finalDate,
						// interval: 'daily',
						vehicleIds: [vehicle.value],
					},
					vehicle
				);
				let result = {
					testResult,
					testCase,
					vehicle: vehicle.text,
					initialDate: props.initialDate,
					finalDate: props.finalDate,
				};
				results.push(result);
			}
			setLoading(false);
			setTestResults([...testResults, ...results]);
			return;
		}

		let result = {
			testCase,
			testResult,
			vehicle: vehicles[0]?.text,
			initialDate: props.initialDate,
			finalDate: props.finalDate,
		};
		setLoading(false);
		setTestResults([result, ...testResults]);
	};

	return (
		<CategoryContentMembrane style={{ height: `calc(100% - 4em)`, display: 'initial' }}>
			<CategoryHeader style={{ gap: '1rem' }}>
				<Dropdown
					fluid
					clearable
					options={TESTCASE_OPTIONS}
					selection
					search
					onChange={(e, { value }) => handleTestCaseChange(e, value)}
					placeholder="Select test case"
				/>
				<Dropdown
					fluid
					// disabled={isDisabled}
					disabled={
						testCase === 'MVP Lokkate Dash Check' ||
						testCase === 'mobilized' ||
						testCase === 'immobilized' ||
						testCase === 'Recent Org' ||
						testCase === 'Recent Vehicle' ||
						testCase === 'Deactivated' ||
						testCase === 'Trial'
					}
					options={vehicleDropdownOptions}
					value={vehicleDropdownVal}
					onChange={(e, { value }) => {
						const selectedVehicles = vehicleDropdownOptions.filter((vehicle) => value.includes(vehicle.value));
						setVehicles(selectedVehicles);
						setVehicleDropdownVal(value);
					}}
					selection
					search
					multiple
					placeholder="Select vehicle"
				/>
				<Calendar
					disabled={
						testCase === 'Idling Lokkate Dash Check' ||
						testCase === 'MVP Lokkate Dash Check' ||
						testCase === 'Performers Lokkate Dash Check' ||
						testCase === 'mobilized' ||
						testCase === 'immobilized' ||
						testCase === 'Recent Org' ||
						testCase === 'Recent Vehicle' ||
						testCase === 'Deactivated' ||
						testCase === 'Trial'
					}
					minDate={minDate}
					maxDate={maxDate}
				/>
				<Button
					primary
					disabled={vehicleDropdownVal.length === 0 && testCase === ''}
					onClick={() => onTestCaseSubmit()}>
					Apply
				</Button>
			</CategoryHeader>

			<CategoryMain>
				<AccordionHeader style={{ width: '100%' }}>
					<HeaderElement width="23%">Test Case</HeaderElement>

					<HeaderElement width="23%">Vehicles</HeaderElement>

					<HeaderElement width="25%">Date</HeaderElement>

					<HeaderElement width="23%">Result</HeaderElement>
				</AccordionHeader>
				<AccordionMain id="AccordionMain">
					{loading ? (
						<Container style={{ height: '100vh' }}>
							<h4>Please Select Test Case</h4>
						</Container>
					) : testResults.length ? (
						<Accordion fluid>
							{testResults.map((eachRes, index) => {
								return (
									<section key={index} ref={accordionContentRef}>
										<Accordion.Title
											style={{ padding: 0 }}
											active={activeIndexes.includes(index)}
											index={index}
											onClick={(event, data) => {
												toggleAccordion(event, data);
											}}>
											<Row>
												<RowElement width="23%">{eachRes.testCase || 'Not Available'}</RowElement>
												{eachRes.testCase === 'Trial' && (
													<RowElement width="23%">
														{(eachRes.testCase === 'Trial' && eachRes.vehicle) || trialVehicleCount}
													</RowElement>
												)}
												{eachRes.testCase !== 'Trial' && (
													<RowElement width="23%">{eachRes.vehicle || 'Not available'}</RowElement>
												)}

												<RowElement width="26%">
													{isoToHumanReadable(eachRes.initialDate)} - {isoToHumanReadable(eachRes.finalDate)}
												</RowElement>
												<RowElement
													title={eachRes.testResult?.result || 'Not Available'}
													width="23%"
													style={{ color: `${eachRes.testResult?.result?.startsWith('Failed') ? 'red' : 'green'}` }}>
													{eachRes.testResult?.result}
												</RowElement>

												{/*<ButtonContainer>*/}
												<Button
													icon
													size="small"
													style={{ background: 'none' }}
													onClick={(e) => {
														handleRemoveRow(e, index);
													}}>
													<Icon name="close" />
												</Button>
												{/*</ButtonContainer>*/}
											</Row>
										</Accordion.Title>
										{activeIndexes.includes(index) && eachRes.testResult?.result?.startsWith('Failed') && (
											<Accordion.Content active={activeIndexes.includes(index)}>
												<AccordionMainContent>
													<Header as="h3" style={{ color: '#ED1F2B' }} attached="top">
														Failed
													</Header>
													<Segment attached>
														<div key={index}>
															<h3>Failed Test Reason:</h3>
															<h4>Test Case: {eachRes.testCase}</h4>
															<h4>Vehicle No: {eachRes.vehicle || 'Not Available'}</h4>
															{eachRes.testCase !== 'Summary Accuracy - Lokkate Check' &&
																eachRes.testCase !== 'KM Match - Lokkate Check' && (
																	<div key={index}>
																		<p>{`Dashboard Data: ${eachRes.testResult.dashboardCount}Km`}</p>
																		<p>{`Test Case Data: ${eachRes.testResult.testcaseCount}Km`}</p>
																		<p>
																			Difference in Data:{' '}
																			<span style={{ color: '#ED1F2B' }}>
																				{Math.abs(
																					eachRes.testResult.testcaseCount - eachRes.testResult.dashboardCount
																				).toFixed(2)}
																				Km
																			</span>
																		</p>
																	</div>
																)}
															{eachRes.testCase === 'Summary Accuracy - Lokkate Check' && (
																<div style={{ fontWeight: 'bold' }}>
																	<p>{`Trips count:${eachRes.testResult.totalTripsCount}`}</p>
																	<p>{`Summary Trip count:${eachRes.testResult.summaryTripCount}`}</p>
																	<p>{`Engine Hours:${eachRes.testResult.tripsEngineHours}hrs`}</p>
																	<p>{`Summary Engine Hours:${eachRes.testResult.summaryEngineHours}hrs`}</p>
																	<p>{`Ignition On:${eachRes.testResult.ignitionOn}`}</p>
																	<p>{`Ignition Off:${eachRes.testResult.ignitionOff}`}</p>
																</div>
															)}

															{eachRes.testCase === 'KM Match - Lokkate Check' && (
																<div style={{ fontWeight: 'bold' }}>
																	<p>{`Total distance in trips:${eachRes.testResult.totalTripsDist}km`}</p>
																	{/*<p>{`Total distance in track:${eachRes.testResult.totalTrackDist}km`}</p>*/}
																	<p>{`Total distance in summary:${eachRes.testResult.totalSummaryDist}km`}</p>
																</div>
															)}
														</div>
													</Segment>
												</AccordionMainContent>
											</Accordion.Content>
										)}
									</section>
								);
							})}
						</Accordion>
					) : (
						<PlaceHolder />
					)}
				</AccordionMain>
			</CategoryMain>
		</CategoryContentMembrane>
	);
};
const mapStateToProps = (state) => ({
	initialDate: state.dates.initialDate,
	finalDate: state.dates.finalDate,
	activeIndex: state.simBills.activeIndex,
});

export default connect(mapStateToProps)(DataAutomation);
