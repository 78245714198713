import moment from 'moment';

export default function calendarMonth(
	initialState = {
		initialDate: moment().subtract(5, 'months').startOf('month').toISOString(),
		finalDate: moment().endOf('month').toISOString(),
		calendarVisible: false,
	},
	action
) {
	switch (action.type) {
		case 'CALENDAR_MONTH_SET_DATES':
			return {
				...initialState,
				initialDate: action.payload.initialDate,
				finalDate: action.payload.finalDate,
			};

		case 'CALENDAR_MONTH_RESET_DATES':
			return {
				...initialState,
				initialDate: moment().subtract(5, 'months').startOf('month').toISOString(),
				finalDate: moment().endOf('month').toISOString(),
			};

		case 'CALENDAR_MONTH_CLEAR_DATES':
			return {
				...initialState,
				initialDate: null,
				finalDate: null,
			};

		case 'CALENDAR_MONTH_SHOW_CALENDAR':
			return {
				...initialState,
				calendarVisible: true,
			};

		case 'CALENDAR_MONTH_HIDE_CALENDAR':
			return {
				...initialState,
				calendarVisible: false,
			};

		default:
			return initialState;
	}
}
