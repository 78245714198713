import styled from 'styled-components';

export const Container = styled.section`
	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding: 0 1em;

	.ui.toggle.checkbox input:checked ~ .box:before,
	.ui.toggle.checkbox input:checked ~ label:before {
		background-color: ${(props) => props.theme.danger} !important;
	}

	.ui.toggle.checkbox .box:before,
	.ui.toggle.checkbox label:before {
		background-color: ${(props) => props.theme.positive} !important;
	}
`;

export const RolesToggle = styled.section`
	.ui.toggle.checkbox .box:before,
	.ui.toggle.checkbox label:before {
		background-color: ${(props) => props.theme.danger} !important;
	}

	.ui.toggle.checkbox input:checked ~ .box:before,
	.ui.toggle.checkbox input:checked ~ label:before {
		background-color: ${(props) => props.theme.positive} !important;
	}
`;
