import styled from 'styled-components';
import { FOOTER_HEIGHT } from '../../../../data/constants/dimensions';

export const CategoryFooter = styled.section`
	width: 100%;
	height: ${FOOTER_HEIGHT};

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding: 1em 1em;

	background: ${(props) => (props.background ? '#ff647c' : props.theme.footerBackground)};
	border-top: 1px solid ${(props) => props.theme.footerBorder};
	border-bottom-left-radius: ${(props) => props.borderRadius || 0};
	border-bottom-right-radius: ${(props) => props.borderRadius || 0};
`;
