import styled from 'styled-components';

export const GraphCard = styled.section`
	flex-basis: 25%;
	min-width: 450px;
	max-width: 600px;
	flex-grow: 1;

	position: relative;

	margin: 1em;
	box-sizing: border-box;
	padding: 1em;

	border: 1px solid ${(props) => props.theme.border};
	border-radius: 10px;

	&:hover {
		box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
	}

	transition: box-shadow 0.1s;
`;
