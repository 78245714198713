import styled from 'styled-components';

export const GraphCardFooter = styled.section`
	width: 100%;
	height: 3em;

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding: 0 0.5em;

	background: #f5f5f7;
	border-radius: 5px;
`;
