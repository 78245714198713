import React from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';

import Nav from '../../blocks/organisms/nav/Nav';
import Overview from '../overview/Overview';
import Operations from '../operations/Operations';
import UserManagementSystem from '../user-management-system/UserManagementSystem';
import Metrics from '../metrics/Metrics';
// import Reports from '../reports/Reports';
import Documents from '../documents/Documents';

import { Container, Main } from './DashboardStyles';
import DiagnosticAndLogs from '../diagnostic-and-logs/DiagnosticAndLogs';
import DashboardNavListItem from '../../blocks/molecules/Dashboard-nav-list-item/DashboardNavListItem';
import SettingsMenu from '../../blocks/molecules/settings-menu/SettingsMenu';
import LogoMini from '../../blocks/molecules/logo/LogoMini';

import ServiceAndMaintenance from '../service-and-maintenance/ServiceAndMaintenance';
import { entityAccessSetter } from '../../../../logic/utilities/accessSetter';
import ErrorLayout from '../ErrorLayout';

import DataAutomation from '../data-automation/DataAutomation';
import Notifications from '../../blocks/organisms/Notifications';
import Pagenotfound from '../Pagenotfound';

const BASE_URL = process.env.REACT_APP_LOKKATE_BASE_URL;

class Dashboard extends React.Component {
	isSuperAdminAccess = localStorage.getItem('isSuperAdmin');

	constructor() {
		super();
		this.state = {
			manageReportsAccess: false,
			readReportsAccess: false,
			createReportsAccess: false,
			updateReportsAccess: false,
			deleteReportsAccess: false,
		};
		this.manageAccessSetter = this.manageAccessSetter.bind(this);
	}

	manageAccessSetter(rule, action) {
		this.setState({ [action]: rule });
	}

	componentDidMount() {
		const permissions = JSON.parse(localStorage.getItem('permissions'));

		// const isSuperAdmin = JSON.parse(localStorage.getItem('isSuperAdmin'));

		const reportPermissions = permissions?.filter((permission) => permission.subject === 'Report');
		if (reportPermissions) {
			for (const reportPrm of reportPermissions) {
				entityAccessSetter(reportPrm?.action, 'Reports', this.manageAccessSetter);
			}
		}
	}

	render() {
		return (
			<Container>
				<Nav urlPrefix={this.props.match.url} />
				<Main style={{ minHeight: '100vh', overflowY: 'hidden' }}>
					<div
						style={{
							display: 'flex',
							backgroundColor: '#8badd3',
							position: 'sticky',
							top: 0,
							zIndex: 100,
						}}>
						<div
							style={{
								width: '100%',
								backgroundColor: '#8badd3',
								height: '4em',
								display: 'flex',
								alignItems: 'center',
							}}>
							<NavLink to="#" exact>
								<LogoMini height="3em" />
							</NavLink>
							<div
								style={{
									color: '#1b3c71',
									display: 'flex',
									justifyContent: 'flex-start',
									alignItems: 'baseline',
								}}>
								<span style={{ fontSize: '1.8rem', fontWeight: '500', margin: '0 .5rem' }}>TrackNerd</span>{' '}
								<span style={{ fontSize: '1rem', fontWeight: '500' }}> All Fleet. One Platform.</span>
							</div>
						</div>

						<div style={{ display: 'flex', alignItems: 'center' }}>
							{this.isSuperAdminAccess === 'true' && <Notifications />}
							<div onClick={() => (window.location.href = BASE_URL)}>
								<DashboardNavListItem iconName="map marker alternate" popupTitle="Lokkate Web" />
							</div>
							<SettingsMenu />
						</div>
					</div>

					<Switch>
						{this.state.manageReportsAccess || this.state.readReportsAccess ? (
							<Route path={`${this.props.match.url}`} exact component={Overview} />
						) : (
							<Route path={`${this.props.match.url}`} exact component={ErrorLayout} />
						)}
						<Route path={`${this.props.match.url}operations`} component={Operations} />
						<Route path={`${this.props.match.url}ums`} component={UserManagementSystem} />
						<Route path={`${this.props.match.url}diagnostic-and-logs`} component={DiagnosticAndLogs} />
						{/*<Route path={`${this.props.match.url}diagnosticAndAnalytics`} component={DiagnosticAndAnalytics} /> */}
						{/*<Route path={`${this.props.match.url}stocks`} component={Stocks} />*/}
						{/*<Route path={`${this.props.match.url}accounts`} component={Accounts} />*/}
						{/*<Route path={`${this.props.match.url}serviceAndMaintenance`} component={ServiceAndMaintenance} />*/}
						<Route path={`${this.props.match.url}metrics`} component={Metrics} />
						{/*<Route path={`${this.props.match.url}reports`} component={Reports} />*/}
						<Route path={`${this.props.match.url}documents`} component={Documents} />
						<Route path={`${this.props.match.url}service-and-maintenance`} component={ServiceAndMaintenance} />
						<Route path={`${this.props.match.url}data-automation`} component={DataAutomation} />
						<Route path={"*"} component={Pagenotfound}/>
						{/*{isProductionEnvironment() && <Route path={`${this.props.match.url}people`} component={People} />}*/}
					</Switch>
				</Main>
			</Container>
		);
	}
}

export default Dashboard;
