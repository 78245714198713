import React from 'react';

import styled from 'styled-components';

import { Loader, Segment } from 'semantic-ui-react';

export default function BasicLoader() {
	return (
		<Container>
			<Segment style={{ width: '100%', height: '100%', border: 'none' }}>
				<Loader active />
			</Segment>
		</Container>
	);
}

export const Container = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
`;
