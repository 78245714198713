import { isoToHumanReadableDay } from '../utilities/date';

export const createVehicleDocumentsDTO = (data) => {
	if (data.length) {
		return data.map((document) => {
			return {
				documentDeletedDate: isoToHumanReadableDay(document.deletedDate),
				documentID: document.id,
				documentExpiry: document?.expiry ? isoToHumanReadableDay(document.expiry) : null,
				documentDescription: document.description,
				documentType: document.type,
				organisationID: document.organisation.id,
				organisationName: document.organisation.name,
				vehicle: {
					id: document?.vehicle?.id ? document.vehicle.id : null,
					registrationNumber: document?.vehicle?.registrationNumber ? document.vehicle.registrationNumber : null,
				},
				/* vehicleID: document.vehicle.id,
         vehicleNumber: document.vehicle.registrationNumber,*/
			};
		});
	}
};
