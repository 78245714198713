export const THEME_COLOR = '#1b3c71';
export const ACCENT_COLOR = '#bf2025';

export const COLORS = {
	theme: THEME_COLOR,
	accent: ACCENT_COLOR,

	loginFormBackground: 'whitesmoke',

	navBackground: THEME_COLOR,
	navBorder: THEME_COLOR,
	navBorderActive: '#fff',
	navIcon: '#fff',

	logoBackground: '#fff',

	border: '#ddd',

	paginationActiveColor: '#fff',

	sidebarIcon: THEME_COLOR,
	sidebarIconBackground: '#e5eefa',
	sidebarToggleIcon: THEME_COLOR,
	sidebarToggleIconBackground: '#fff',
	sidebarAccordionBackground: '#fafbfc',

	// Navbar
	activeDashboardLinkBackground: '#fff',

	// Links
	dashboardLinkColor: '#fff',

	activeSortColor: 'indianred',

	// Message TimeLine
	serverMessageBackground: '#d9fdd3',
	deviceMessageBackground: '#f4f1f2',

	listItemBorder: '#ddd',
	listItemBackground: '#f5f5f7',

	headerBackground: '#f5f5f7',
	headerBorder: '#ddd',

	footerBackground: '#f5f5f7',
	footerBorder: '#ddd',

	graphToolTipText: '#fff',
	graphToolTipBackground: THEME_COLOR,

	graphTableBackground: '#fff',

	defaultLink: '#000',

	calendarBackground: '#eee',

	// positive: '#56A668',
	// danger: '#ff647c',
	danger: '#e34234',
	positive: '#4aa96c',
	warning: '#ff8800',

	errorMessageTextColor: '#db2828',
	errorMessageBackground: '#ffe8e6',
	errorMessageBorderColor: '#db2828',

	successMessageTextColor: '#1ebc30',
	successMessageBackground: '#e5f9e7',
	successMessageBorderColor: '#1ebc30',

	warningMessageTextColor: '#ff8800',
	warningMessageBackground: '#fcedde',
	warningMessageBorderColor: '#ff8800',

	accountOverview: ['#ff8800', '#56A668', THEME_COLOR],

	customersMetricsGraphs: ['#DB332E', '#4EB6AE', '#F07138', '#56BA47', '#767676'],

	devicesGraphColor: ['#DB332E', '#F07138', '#4EB6AE', '#56BA47'],
	simsGraphColor: ['#DB332E', '#F07138', '#767676', '#1B1C1E'],
	smsReportGraphColor: ['#1B1C1E', '#56BA47', '#DB332E', '#2C85D0', '#F07138'],
	simBillsGraphColor: ['#56BA47', '#DB332E', '#767676'],
	labels: {
		green: '#21ba45',
		red: '#db2828',
		teal: '#00b5ad',
		orange: '#f2711c',
		blue: '#2185d0',
		violet: '#6435c9',
		purple: '#a333c8',
		olive: '#b5cc18',
		yellow: '#fbbd08',
		grey: '#767676',
		brown: '#a5673f',
		black: '#1b1c1d',
		white: '#fff',
	},
};
