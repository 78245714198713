// Firebase

import Mixpanel from 'mixpanel-browser';

export const FIREBASE_CONFIG = {
	apiKey: process.env.REACT_APP_FIREBASE_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
};

Mixpanel.init('877fe0f326f44e6d311a232cd1ce59ef', { debug: true });
export default Mixpanel;
