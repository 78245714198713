import React, { useEffect, useState } from 'react';
import {
	deleteDocument,
	documentRestore,
	downloadDocumentFile,
	exportData,
	fetchDocumentFile,
	fetchUserDocuments,
	getUsersForDropdown,
	patchEditRequest,
	postAddRequest,
} from '../../../../../logic/middleware/userDocuments';
import { CategoryHeader } from '../../../styles/containers/CategoryHeader';
import SearchBox from '../../../blocks/atoms/search-box/SearchBox';
import { Accordion, Button, Confirm, Form, Icon, Input, Label, Segment } from 'semantic-ui-react';
import { Expander } from '../../../styles/place-holders/Expander';
import { getOrganisationsForDropdown } from '../../../../../logic/middleware/vehicles';
import { CategoryMain } from '../../../styles/containers/CategoryMain';
import { CategoryContentMembrane } from '../../../styles/containers/CategoryContentMembrane';
import moment from 'moment/moment';
import { AccordionHeader } from '../../../styles/containers/AccordionHeader';
import { Actions, HeaderElement, Row, RowElement } from '../../../styles/Accordion';
// import { CheckboxContainer } from '../../../styles/Containers';
import BasicLoader from '../../../blocks/molecules/loader/BasicLoader';
import { AccordionMain } from '../../../styles/containers/AccordionMain';
import PlaceHolder from '../../../blocks/molecules/place-holder/PlaceHolder';
import { AccordionMainContent } from '../../../styles/containers/AccordionMainContent';
import { DateInput } from 'semantic-ui-calendar-react';
import { connect } from 'react-redux';
import { CategoryFooter } from '../../../styles/containers/CategoryFooter';
import FooterPagination from '../../../blocks/molecules/pagination/Pagination';
import AddModal from '../../../blocks/organisms/modals/userDocuments/AddModal';
import { generateSuccessMessage, handleError } from '../../../../../logic/middleware/messaging';
// import { USER_DOCUMENT_TYPES_OPTIONS } from '../../../../../data/constants/dropdown-options';
import FilterModal from '../../../blocks/organisms/modals/userDocuments/FilterModal';
import PdfView from '../../../../common/fileView/PDFView';
import ImageView from '../../../../common/fileView/ImageView';
import ExportModal from '../../../blocks/organisms/modals/devices/ExportModal';
import ToggleSwitch from '../../../blocks/molecules/toggle-switch/ToggleSwitch';
import { createUserDocumentsDTO } from '../../../../../logic/dto/userDocuments';
// import {DOCUMENTS_API} from '../../../../../logic/configs/api';
import fileDownload from 'js-file-download';
import UserDocument from '../../../../assets/images/userDocument.png';
import Download from '../../../../assets/images/download.png';
import { trackMixpanelEvent } from '../../../../../logic/middleware/mixpanel';
import accessSetter from '../../../../../logic/utilities/accessSetter';
import { Buffer } from 'buffer';
import { datadogLogs } from '@datadog/browser-logs';
import { dispatch } from '../../../../../logic/middleware/middleware';

function UserDocuments(props) {
	let isSuperAdmin = localStorage.getItem('isSuperAdmin') === 'true' ? true : false;
	const [documentList, setDocumentList] = useState([]);
	const [activeFilters, setActiveFilters] = useState({
		organisations: props.filters.organisations,
	});
	const [activeSearch, setActiveSearch] = useState(null);
	const [activeSort, setActiveSort] = useState(null);
	const [sortOrder, setSortOrder] = useState('asc');
	const [loading, setLoading] = useState(true);
	// const [anyRowSelected, setAnyRowSelected] = useState(false);
	// const [selectedDocumentRows, setSelectedDocumentRows] = useState([]);
	const [dropdownOrganisations, setDropdownOrganisations] = useState([]);
	const [dropdownUsers, setDropdownUsers] = useState([]);
	const [openExportModal, setOpenExportModal] = useState(false);
	// const [exportableData, setExportableData] = useState(null);
	const [activeIndexAccordion, setActiveIndexAccordion] = useState(-1);
	const [documentEditMode, setDocumentEditMode] = useState(false);
	const [editedValues, setEditedValues] = useState({});
	const [deletedMode, setDeletedMode] = useState(false);
	const [openDocumentRestoreModal, setOpenDocumentRestoreModal] = useState(false);
	const [openUpdateConfirmationModal, setOpenUpdateConfirmationModal] = useState(false);
	const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);
	const [pageCount, setPageCount] = useState(0);
	const [addModalOpen, setAddModalOpen] = useState(false);
	const [filterModalOpen, setFilterModalOpen] = useState(false);
	const [openPreview, setOpenPreview] = useState(false);
	const [fileSource, setFileSource] = useState(null);
	const [documentFileType, setDocumentFileType] = useState('');
	const [fileType, setFileType] = useState(null);
	const [exportFileName, setExportFileName] = useState('Document');
	const [exportFileExtension, setExportFileExtension] = useState('pdf');
	const [fileSizeError, setFileSizeError] = useState(false);

	const [managePermission, setManagePermission] = useState({
		readAccess: false,
		createAccess: false,
		updateAccess: false,
		deleteAccess: false,
		manageAccess: false,
	});

	// eslint-disable-next-line no-unused-vars
	let fileInputEdit = React.createRef();

	const manageAccessSetter = (rule, action) => {
		setManagePermission((prev) => ({ ...prev, [action]: rule }));
	};

	useEffect(() => {
		trackMixpanelEvent('User Documents', { Action: 'View' });

		const permissions = JSON.parse(localStorage.getItem('permissions'));

		const documentPermission = permissions?.filter((permission) => permission.subject === 'Document');
		if (documentPermission) {
			for (const documentPrm of documentPermission) {
				accessSetter(documentPrm?.action, manageAccessSetter);
			}
		}
		if (props.searchQuery) {
			dispatch('UPDATE_SEARCH_QUERY', props.searchQuery);
			handleSearch({ target: { value: props.searchQuery } });
		}

		fetchDocumentList({});
	}, [activeFilters, activeSearch, activeSort, deletedMode, sortOrder, pageNumber]);

	const setQueryParam = () => {
		let query = `?pagecount=${props.dataSizeLimit}&pagenumber=${pageNumber}`;
		// Updating query if Filters are set

		// query = query + `&fileType=${exportFileExtension}`;

		if (activeFilters?.organisations?.length)
			query += `&organisations[]=${activeFilters.organisations.join('&organisations[]=')}`;
		if (activeFilters?.user?.length) query += `&users[]=${activeFilters.user.join('&users[]=')}`;
		if (activeFilters?.type?.length) query += `&type[]=${activeFilters.type.join('&type[]=')}`;
		else query += '&type[]=Driving License&type[]=Aadhaar&type=Pan Card';
		if (activeFilters?.expiryStartDate) {
			query += `&expiryStartDate=${moment(activeFilters.expiryStartDate, 'DD-MM-YYYY').toISOString()}`;
		}
		if (activeFilters?.expiryEndDate)
			query += `&expiryEndDate=${moment(activeFilters.expiryEndDate, 'DD-MM-YYYY').toISOString()}`;

		// Updating query if Sort is set
		if (activeSort !== null) query += `&sortBy=${activeSort}&orderBy=${sortOrder}`;

		// Updating query if search is set
		if (activeSearch !== null || activeSearch?.length) query += `&search=${activeSearch}`;
		if (deletedMode) query += '&deleted=true';
		return query;
	};

	const fetchDocumentList = () => {
		let query = setQueryParam();
		fetchUserDocuments(query).then((response) => {
			const docList = createUserDocumentsDTO(response?.data?.data);
			setDocumentList(docList);
			// setPageNumber();
			setPageCount(response?.data?.count || 0);
			setLoading(false);
		});
	};

	// const handleSelectAll = (checked) => {
	// 	let selectedRowsTemp = [];
	//
	// 	if (checked) {
	// 		selectAllActive = true;
	// 		documentList?.forEach((document) => selectedRowsTemp.push(document.documentID));
	// 		setSelectedDocumentRows([...selectedRowsTemp]);
	// 		setAnyRowSelected(!!selectedRowsTemp.length);
	// 	} else {
	// 		selectAllActive = false;
	// 		setSelectedDocumentRows([]);
	// 		setAnyRowSelected(!!selectedRowsTemp.length);
	// 	}
	// };
	const fetchUsersDropdown = ({ value }) => {
		let query;
		if (typeof value === 'number') query = `?organisations[]=${value}`;
		else query = `?organisations[]=${value && value.join('&organisations[]=')}`;
		query += '&dropdown=true&type[]=Driver';
		getUsersForDropdown(query).then((response) => {
			setDropdownUsers(response);
		});
	};

	const addDocuments = (form) => {
		let promiseList = [];
		form.forEach((data) => {
			let formData = new FormData();
			formData.append('organisation[id]', Number(data.organisation));
			if (data.user) formData.append('user[id]', Number(data.user));
			formData.append('type', data.documentType);
			if (data.documentExpiry) formData.append('expiry', moment(data.documentExpiry, 'DD-MM-YYYY').toISOString());
			if (data.documentDesc) formData.append('description', data.documentDesc.trim());
			formData.append('file', data.documentFile);
			//
			promiseList.push(postAddRequest(formData));
		});
		Promise.all(promiseList)
			.then((response) => {
				generateSuccessMessage(response, 'created');
				fetchDocumentList();
				setAddModalOpen(false);
				// this.cleanupAddOperation();
			})
			.catch((error) => {
				// this.setState({adding: false});
				setAddModalOpen(false);
				datadogLogs.logger.error('User documents add api', {}, error);
				handleError(error);
			});
	};

	//Set export Data in this
	// const exportSelectedDocuments = () => {
	// 	let documentsToExport = [];
	// 	documentList.forEach((document) => {
	// 		if (selectedDocumentRows.includes(document.documentID)) {
	// 			documentsToExport.push(document);
	// 		}
	// 	});
	// 	setExportableData([...documentsToExport]);
	// };

	// const cleanSelectedRows = () => {
	// 	setAnyRowSelected(false);
	// 	setSelectedDocumentRows([]);
	// };

	// const handleExportSelection = (checkedData, id) => {
	// 	let selectedTemp = selectedDocumentRows;
	// 	if (selectedTemp.includes(id)) {
	// 		selectedTemp = selectedTemp.filter((filterId) => id !== filterId);
	// 	} else {
	// 		selectedTemp.push(id);
	// 	}
	// 	setSelectedDocumentRows([...selectedTemp]);
	// 	setAnyRowSelected(!!selectedTemp.length);
	// 	// setCounter(counter + 1);
	// };

	// Accordion
	const toggleAccordion = (e, titleProps, current) => {
		const { index } = titleProps;

		const newIndex = current === index ? -1 : index;
		setDocumentEditMode(false);
		setActiveIndexAccordion(newIndex);
	};

	const sort = (field) => {
		if (activeSort === field) {
			if (sortOrder === 'asc') setSortOrder('desc');
			else setSortOrder('asc');
		} else setActiveSort(field);
	};

	// const resetStates = () => {
	// 	setActiveSearch(null);
	// 	setActiveFilters(null);
	// 	setActiveSort(null);
	// };

	const restoreDocument = (id) => {
		documentRestore(id)
			.then((response) => {
				generateSuccessMessage(response, 'Restored');
				fetchDocumentList();
				setOpenDocumentRestoreModal(false);
				setActiveIndexAccordion(-1);
			})
			.catch((error) => {
				fetchDocumentList();
				datadogLogs.logger.error('User documents restore api', {}, error);
				setOpenDocumentRestoreModal(false);
			});
	};

	const MAX_FILE_SIZE = 5 * 1024 * 1024;

	const handleFileChange = (e) => {
		let value;
		if (e === null) value = '';
		else value = e.target.files[0];
		if (value && value.size > MAX_FILE_SIZE) {
			setFileSizeError(true);
		} else {
			setEditedValues({ ...editedValues, file: e.target.files[0] });
			setFileSizeError(false);
		}
	};

	// const fileExtensionChange = (e, value) => {
	// 	setExportFileExtension(value);
	// };

	const update = (id) => {
		let formData = new FormData();
		// if (editedValues?.type) formData.append('type', editedValues.type);
		if (editedValues?.expiry) formData.append('expiry', moment(editedValues.expiry, 'DD-MM-YYYY').toISOString());
		if (editedValues?.description) formData.append('description', editedValues.description);
		if (editedValues?.file) formData.append('file', editedValues.file);

		patchEditRequest(id, formData)
			.then((response) => {
				fetchDocumentList();
				generateSuccessMessage(response, 'Success');
				// handleSuccess(response, 'Success');
				cleanEditOperation();

				// this.cleanupAddOperation();
			})
			.catch((error) => {
				// this.setState({adding: false});
				handleError(error);
				cleanEditOperation();
			});
	};

	const handleSearch = (event) => {
		let value = event.target.value;
		if (!value) value = null;
		dispatch('UPDATE_SEARCH_QUERY', value);
		setActiveSearch(value);
	};

	const handleDeleteDocument = (id) => {
		deleteDocument(id)
			.then((response) => {
				generateSuccessMessage(response, 'Success');
				fetchDocumentList();
				setOpenDeleteConfirmationModal(false);
				setActiveIndexAccordion(-1);
			})
			.catch((error) => {
				handleError(error);
				setOpenDeleteConfirmationModal(false);
			});
	};

	//TODO cleanEditOperation

	const cleanEditOperation = () => {
		setEditedValues({});
		setDocumentEditMode(false);
	};

	return (
		<CategoryContentMembrane>
			<CategoryHeader>
				<SearchBox
					placeholder="Search in documents"
					searchQuery={activeSearch}
					clearSearchIconClick={() => {
						setActiveSearch(null);
						dispatch('UPDATE_SEARCH_QUERY', '');
					}}
					// disabled={!activeSearch && loading}
					// handleSearch={(event) => {
					// 	if (!event.target.value) setActiveSearch(null);
					// 	else setActiveSearch(event.target.value);
					// 	// cleanSelectedRows();
					// }}
					value={props.searchQuery}
					handleSearch={handleSearch}
				/>

				<Button icon primary disabled={loading} title="Refresh Documents" onClick={() => fetchDocumentList()}>
					<Icon name="refresh" />
				</Button>

				<Expander />
				{/*Recalculation*/}
				<Button
					primary={Object.values(activeFilters).every((value) => value.length === 0)}
					icon
					disabled={loading}
					color={Object.values(activeFilters).some((value) => value.length > 0) ? 'orange' : null}
					title="Filter Documents"
					onClick={() => {
						getOrganisationsForDropdown('?dropdown=true').then((response) => {
							setDropdownOrganisations(response);
						});
						setFilterModalOpen(true);
					}}>
					<Icon name="filter" />
				</Button>
				{/*Add*/}
				{!deletedMode && (
					<Button
						primary
						title="Add User Documents"
						disabled={loading || !managePermission.manageAccess}
						onClick={() => {
							trackMixpanelEvent('User Documents', { Action: 'Add' });
							setAddModalOpen(true);
							getOrganisationsForDropdown('?dropdown=true').then((response) => {
								setDropdownOrganisations(response);
							});
						}}>
						Add
					</Button>
				)}

				{/*Export*/}
				{/*<Button*/}
				{/*	primary*/}
				{/*	disabled={!anyRowSelected || loading}*/}
				{/*	title={!anyRowSelected ? 'Please select atleast one document to export' : 'Export Documents'}*/}
				{/*	onClick={() => {*/}
				{/*		trackMixpanelEvent('User Documents', { Action: 'Export' });*/}
				{/*		exportSelectedDocuments();*/}
				{/*		setOpenExportModal(true);*/}
				{/*	}}>*/}
				{/*	Export*/}
				{/*</Button>*/}
				{/*Export All*/}

				{/*<Button.Group>*/}
				{/*	<Button*/}
				{/*		primary*/}
				{/*		title="Export All Documents"*/}
				{/*		disabled={loading || !documentList?.length}*/}
				{/*		onClick={() => {*/}
				{/*			// setOpenExportModal(true);*/}
				{/*			// setExportableData([...documentList]);*/}
				{/*			exportAll(setQueryParam(), exportFileExtension);*/}
				{/*		}}>*/}
				{/*		Export*/}
				{/*	</Button>*/}
				{/*	<Dropdown*/}
				{/*		style={{ background: '#ffffff', color: '#1b3c71', borderColor: '#1b3c71' }}*/}
				{/*		className="button icon"*/}
				{/*		floating*/}
				{/*		value={exportFileExtension}*/}
				{/*		options={FILE_TYPE_EXTENSIONS}*/}
				{/*		onChange={fileExtensionChange}*/}
				{/*		trigger={<></>}*/}
				{/*	/>*/}
				{/*</Button.Group>*/}

				{/*Export All Button Ends*/}
				{isSuperAdmin && (
					<ToggleSwitch
						title={deletedMode ? 'Hide Deleted Documents' : 'Show Deleted Documents'}
						label="Deleted Mode"
						checked={deletedMode}
						disabled={loading}
						action={() => setDeletedMode(!deletedMode)}
					/>
				)}
			</CategoryHeader>

			<CategoryMain>
				<AccordionHeader background={deletedMode}>
					{/*<HeaderElement width="3%" disabled={loading || !documentList?.length}>*/}
					{/*	<CheckboxContainer>*/}
					{/*		<Checkbox*/}
					{/*			checked={documentList?.length !== 0 && selectedDocumentRows.length === documentList?.length}*/}
					{/*			indeterminate={*/}
					{/*				selectedDocumentRows.length !== 0 && selectedDocumentRows.length !== documentList?.length*/}
					{/*			}*/}
					{/*			onClick={(e, data) => {*/}
					{/*				handleSelectAll(data.checked);*/}
					{/*			}}*/}
					{/*		/>*/}
					{/*	</CheckboxContainer>*/}
					{/*</HeaderElement>*/}
					{/*If clicked, clears sort filters and fetches fresh data.*/}
					<HeaderElement width="5%" disabled={loading || !documentList?.length}>
						No.
					</HeaderElement>
					<HeaderElement
						width="13%"
						disabled={loading || !documentList?.length}
						onClick={() => {
							sort('type');
						}}
						activeSort={activeSort === 'type'}>
						{activeSort === 'type' ? (
							sortOrder === 'asc' ? (
								<Icon name="sort alphabet down" />
							) : (
								<Icon name="sort alphabet up" />
							)
						) : (
							<Icon name="sort" />
						)}
						Document Type
					</HeaderElement>
					<HeaderElement
						width="15%"
						disabled={loading || !documentList?.length}
						onClick={() => {
							sort('user');
						}}
						activeSort={activeSort === 'user'}>
						{activeSort === 'user' ? (
							sortOrder === 'asc' ? (
								<Icon name="sort alphabet down" />
							) : (
								<Icon name="sort alphabet up" />
							)
						) : (
							<Icon name="sort" />
						)}
						User Name
					</HeaderElement>
					<HeaderElement
						width="15%"
						disabled={loading || !documentList?.length}
						onClick={() => {
							sort('organisation');
						}}
						activeSort={activeSort === 'organisation'}>
						{activeSort === 'organisation' ? (
							sortOrder === 'asc' ? (
								<Icon name="sort alphabet down" />
							) : (
								<Icon name="sort alphabet up" />
							)
						) : (
							<Icon name="sort" />
						)}
						Organisation
					</HeaderElement>
					<HeaderElement
						width="13%"
						disabled={loading || !documentList?.length}
						onClick={() => {
							sort('expiry');
						}}
						activeSort={activeSort === 'expiry'}>
						{activeSort === 'expiry' ? (
							sortOrder === 'asc' ? (
								<Icon name="sort alphabet down" />
							) : (
								<Icon name="sort alphabet up" />
							)
						) : (
							<Icon name="sort" />
						)}
						Expiry Date
					</HeaderElement>
					<HeaderElement
						width="13%"
						disabled={loading || !documentList?.length}
						onClick={() => {
							sort('description');
						}}
						activeSort={activeSort === 'description'}>
						{activeSort === 'description' ? (
							sortOrder === 'asc' ? (
								<Icon name="sort alphabet down" />
							) : (
								<Icon name="sort alphabet up" />
							)
						) : (
							<Icon name="sort" />
						)}
						Description
					</HeaderElement>
					<HeaderElement width="13%" disabled={loading || !documentList?.length}>
						View/ Download
					</HeaderElement>
					{deletedMode && (
						<HeaderElement width="10%" disabled={loading || !documentList?.length}>
							Deleted Date
						</HeaderElement>
					)}
				</AccordionHeader>

				<AccordionMain id="AccordionMain">
					{loading ? (
						<BasicLoader />
					) : documentList?.length ? (
						<Accordion fluid>
							{documentList.map((document, index) => {
								return (
									<section key={index}>
										<Accordion.Title
											active={activeIndexAccordion === index}
											index={index}
											onClick={(event, data) => {
												toggleAccordion(event, data, activeIndexAccordion);
												cleanEditOperation();
											}}
											style={{ padding: 0 }}>
											<Row>
												{/*<RowElement width="3%">*/}
												{/*	<CheckboxContainer>*/}
												{/*		<Checkbox*/}
												{/*			checked={selectedDocumentRows.includes(document.documentID)}*/}
												{/*			onClick={(e, data) => {*/}
												{/*				handleExportSelection(data.checked, document.documentID);*/}
												{/*				e.stopPropagation();*/}
												{/*			}}*/}
												{/*		/>*/}
												{/*	</CheckboxContainer>*/}
												{/*</RowElement>*/}
												<RowElement width="5%">{1 + index + (pageNumber - 1) * props.dataSizeLimit}</RowElement>
												<RowElement
													width="13%"
													style={{ color: `${document.documentType ? 'black' : 'grey'}` }}
													title={`${document.documentType}`}>
													{document.documentType ? document.documentType : 'N/A'}
												</RowElement>
												<RowElement
													width="15%"
													style={{ color: `${document.userName ? 'black' : 'grey'}` }}
													title={document.userName}>
													{document.userName ? document.userName : 'N/A'}
												</RowElement>
												<RowElement
													width="15%"
													title={document.organisationName}
													style={{ color: `${document.organisationName ? 'black' : 'grey'}` }}>
													{document.organisationName ? document.organisationName : 'N/A'}
												</RowElement>
												<RowElement width="13%" title={document.documentExpiry ? document.documentExpiry : 'N/A'}>
													<Label
														size={'medium'}
														style={{ minWidth: '60%', textAlign: 'center' }}
														color={`${
															document.documentExpiry === null
																? 'black'
																: moment(document.documentExpiry, 'DD-MM-YYYY').diff(moment(), 'days') < 0
																? 'red'
																: moment(document.documentExpiry, 'DD-MM-YYYY').diff(moment(), 'days') < 30
																? 'orange'
																: 'green'
														}`}>
														{document.documentExpiry
															? document.documentExpiry
															: document.documentType === 'Aadhaar'
															? 'N/A'
															: 'N/A'}
													</Label>
												</RowElement>
												<RowElement
													width="13%"
													title={document.documentDescription ? document.documentDescription : 'N/A'}
													style={{ color: `${document.documentDescription ? 'black' : 'grey'}` }}>
													{document.documentDescription ? document.documentDescription : 'N/A'}
												</RowElement>
												<RowElement width="13%">
													<img
														title={'Click to view Document'}
														alt={'icon'}
														src={UserDocument}
														onClick={(e) => {
															e.stopPropagation();
															setLoading(true);
															fetchDocumentFile(document.documentID)
																.then((response) => {
																	setOpenPreview(true);
																	setLoading(false);
																	let extension = response.headers['content-type'].split('/').at(-1);
																	let value = document.userName
																		? `${document.userName} - ${document.documentType}.${extension}`
																		: document.documentType;
																	// setDocumentFileType(`${document.userName} - ${document.documentType}.${extension}`);
																	setDocumentFileType(value);
																	if (response.headers['content-type'] === 'application/pdf')
																		setFileSource(response.data);
																	else setFileSource(Buffer.from(response.data, 'binary').toString('base64'));

																	setFileType(response.headers['content-type']);
																})
																.catch((error) => {
																	setLoading(false);
																	handleError(error);
																	setOpenPreview(false);
																});
														}}
													/>
													<img
														alt={'icon'}
														title="Download Document"
														src={Download}
														onClick={(e) => {
															e.stopPropagation();
															downloadDocumentFile(document.documentID)
																.then((response) => {
																	let extension = response.headers['content-type'].split('/').at(-1);
																	fileDownload(
																		response.data,
																		`${document.documentType}_${document.userName}.${extension}`
																	);
																})
																.catch((error) => {
																	handleError(error);
																	setOpenPreview(false);
																});
														}}
													/>
												</RowElement>
												{deletedMode && (
													<RowElement
														width="10%"
														title={document.documentDeletedDate ? document.documentDeletedDate : 'N/A'}>
														{document.documentDeletedDate ? document.documentDeletedDate : 'N/A'}
													</RowElement>
												)}
											</Row>
										</Accordion.Title>
										{activeIndexAccordion === index && (
											<Accordion.Content active={activeIndexAccordion === index}>
												<AccordionMainContent>
													<Segment attached>
														<Form>
															<Form.Group widths="equal">
																{/*<Form.Field>*/}
																{/*	<label>Document Type</label>*/}
																{/*	<Dropdown*/}
																{/*		fluid*/}
																{/*		search*/}
																{/*		selection*/}
																{/*		options={USER_DOCUMENT_TYPES_OPTIONS}*/}
																{/*		value={editedValues?.type || document.documentType}*/}
																{/*		disabled={!documentEditMode}*/}
																{/*		onChange={(e, data) => setEditedValues({ ...editedValues, type: data.value })}*/}
																{/*	/>*/}
																{/*</Form.Field>*/}
																<Form.Field>
																	<label>Expiry Date</label>
																	<DateInput
																		placeholder="Select"
																		fluid
																		closable
																		disabled={!documentEditMode}
																		value={editedValues?.expiry || document.documentExpiry}
																		onChange={(e, data) => setEditedValues({ ...editedValues, expiry: data.value })}
																	/>
																</Form.Field>
																<Form.Field>
																	<label>Description</label>
																	<Input
																		fluid
																		value={editedValues?.description || document.documentDescription}
																		disabled={!documentEditMode}
																		onChange={(e, data) =>
																			setEditedValues({
																				...editedValues,
																				description: data.value,
																			})
																		}
																	/>
																</Form.Field>
																<Form.Field required>
																	<label>Upload</label>
																	<Form.Input
																		disabled={!documentEditMode}
																		type="file"
																		error={
																			fileSizeError && {
																				content: 'Document upload size should not exceed 5MB',
																			}
																		}
																		ref={(ref) => (fileInputEdit = ref)}
																		accept=".jpg, .png, .pdf, .jpeg"
																		{...(editedValues.file && {
																			icon: (
																				<Icon
																					name="times circle outline"
																					link
																					onClick={() => {
																						fileInputEdit.inputRef.current.value = '';
																						let newVal = editedValues;
																						delete newVal.file;
																						setEditedValues({ ...newVal });
																					}}
																				/>
																			),
																		})}
																		fluid
																		onChange={(e) => handleFileChange(e)}
																	/>
																</Form.Field>
															</Form.Group>
														</Form>
													</Segment>
													{deletedMode ? (
														<Actions>
															<Button
																primary
																onClick={() => {
																	trackMixpanelEvent('User Documents', { Action: 'Restore' });
																	setOpenDocumentRestoreModal(true);
																}}>
																Restore
															</Button>
															<Confirm
																content="Are you sure, you want to restore this document?"
																open={openDocumentRestoreModal}
																onCancel={() => setOpenDocumentRestoreModal(false)}
																onConfirm={() => {
																	restoreDocument(document.documentID);
																}}
																size="mini"
															/>
														</Actions>
													) : documentEditMode ? (
														<Actions>
															<section>
																<Button
																	secondary
																	onClick={() => {
																		cleanEditOperation();
																	}}>
																	Cancel
																</Button>
																<Button positive onClick={() => setOpenUpdateConfirmationModal(true)}>
																	Update
																</Button>
																<Confirm
																	content="Are you sure, you want to update the changes?"
																	open={openUpdateConfirmationModal}
																	onCancel={() => {
																		setOpenUpdateConfirmationModal(false);
																		setEditedValues({});
																	}}
																	onConfirm={() => {
																		update(document.documentID);
																		setOpenUpdateConfirmationModal(false);
																		setEditedValues({});
																	}}
																	size="mini"
																/>
															</section>
														</Actions>
													) : (
														<Actions>
															<section>
																<Button
																	primary
																	onClick={() => {
																		trackMixpanelEvent('User Documents', { Action: 'Edit' });
																		setDocumentEditMode(true);
																	}}>
																	Edit
																</Button>
																<Button
																	negative
																	onClick={() => {
																		trackMixpanelEvent('User Documents', { Action: 'Delete' });
																		setOpenDeleteConfirmationModal(true);
																	}}>
																	Delete
																</Button>
																<Confirm
																	content="Are you sure, you want to update the changes?"
																	open={openDeleteConfirmationModal}
																	onCancel={() => setOpenDeleteConfirmationModal(false)}
																	onConfirm={() => {
																		handleDeleteDocument(document.documentID);
																	}}
																	size="mini"
																/>
															</section>
														</Actions>
													)}
												</AccordionMainContent>
											</Accordion.Content>
										)}
									</section>
								);
							})}
						</Accordion>
					) : (
						<PlaceHolder />
					)}
				</AccordionMain>
			</CategoryMain>
			<CategoryFooter background={deletedMode}>
				<FooterPagination
					handleDataSizeLimitChange={fetchDocumentList}
					pageNumber={pageNumber}
					handlePageChange={(activePage) => {
						setPageNumber(activePage);
						// cleanSelectedRows();
					}}
					count={pageCount}
					disabled={loading}
				/>
			</CategoryFooter>
			{addModalOpen && (
				<AddModal
					open={addModalOpen}
					onClose={() => setAddModalOpen(false)}
					handleFormSubmit={(formValues) => {
						addDocuments(formValues);
						setAddModalOpen(false);
					}}
					handleOrgChange={fetchUsersDropdown}
					organisationDropdown={dropdownOrganisations}
					userDropDown={dropdownUsers}
					// onClose={() => setAddModalOpen(false)}
					onCancel={() => setAddModalOpen(false)}
				/>
			)}
			{filterModalOpen && (
				<FilterModal
					open={filterModalOpen}
					onClose={() => setFilterModalOpen(false)}
					handleOnApply={(filterValues) => {
						trackMixpanelEvent('User Documents', { Action: 'Filter' });
						setActiveFilters(filterValues);
						setFilterModalOpen(false);
					}}
					handleOrgChange={fetchUsersDropdown}
					organisationDropdown={dropdownOrganisations}
					userDropDown={dropdownUsers}
					activeFilters={activeFilters}
					onCancel={() => setFilterModalOpen(false)}
					onResetClick={() => {
						setActiveFilters({});
						// setFilterModalOpen(false);
					}}
				/>
			)}
			{openPreview &&
				(fileType === 'application/pdf' ? (
					<PdfView
						source={fileSource}
						onClose={() => {
							setOpenPreview(false);
							setFileType(null);
							setFileSource(null);
						}}
						documentFileType={documentFileType}
					/>
				) : (
					<ImageView
						visible={openPreview}
						source={fileSource}
						onClose={() => {
							setOpenPreview(false);
							setFileType(null);
							setFileSource(null);
						}}
						documentFileType={documentFileType}
					/>
				))}

			{openExportModal && (
				<ExportModal
					open={openExportModal}
					fileName={exportFileName}
					fileNameChange={(e) => setExportFileName(e.target.value)}
					fileExtension={exportFileExtension}
					fileExtensionChange={(e, { value }) => setExportFileExtension(value)}
					onDownloadClick={() => {
						exportData(exportFileName, exportFileExtension);
						setOpenExportModal(false);
					}}
					onClose={() => {
						setOpenExportModal(false);
					}}
				/>
			)}
		</CategoryContentMembrane>
	);
}

const mapStateToProps = (state) => ({
	dataSizeLimit: state.ui.dataSizeLimit,
	searchQuery: state.vehicles.searchQuery,
	filters: state.vehicles.filters,
});

export default connect(mapStateToProps)(UserDocuments);
