import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

export const DataDog = () => {
	datadogRum.init({
		applicationId: 'bf25f082-5590-4475-b6a2-ad070ad26e9d',
		clientToken: 'puba7b58c62c1588bb039e1ee5956ba4456',
		site: 'datadoghq.eu',
		service: 'crm',
		env: process.env.REACT_APP_BUILD_ENVIRONMENT,
		// Specify a version number to identify the deployed version of your application in Datadog
		// version: '1.0.0',
		sessionSampleRate: 100,
		sessionReplaySampleRate: 50,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: 'mask-user-input',
	});
	datadogRum.startSessionReplayRecording();
};

datadogLogs.init({
	clientToken: 'puba7b58c62c1588bb039e1ee5956ba4456',
	site: 'datadoghq.eu',
	service: 'crm',
	env: process.env.REACT_APP_BUILD_ENVIRONMENT,
	forwardErrorsToLogs: true,
	sessionSampleRate: 100,
});
const id = localStorage.getItem('userId');
const name = localStorage.getItem('name');
const phone = localStorage.getItem('phone');
const email = localStorage.getItem('email');

datadogRum.setUser({ id: id, name: name, phone: phone, email: email });
datadogLogs.setUser({ id: id, name: name, phone: phone, email: email });
datadogRum.getUser();
datadogLogs.getUser();
