import moment from 'moment/moment';
import { isoToDate } from '../utilities/date';

export function createStudentsDTO(data) {
	if (data && data.length !== 0) {
		return data.map((student) => {
			return {
				id: student?.id ? student.id : null,
				name: student?.name ? student.name : null,
				organisation: {
					id: student?.organisation?.id ? student.organisation.id : null,
					name: student?.organisation?.name ? student.organisation.name : null,
				},
				pickupBus: {
					id: student?.pickupBus?.id ? student.pickupBus.id : null,
					registrationNumber: student?.pickupBus?.registrationNumber ? student.pickupBus.registrationNumber : null,
				},
				pickupPoint: {
					id: student?.pickupPoint?.id ? student.pickupPoint.id : null,
					name: student?.pickupPoint?.name ? student.pickupPoint.name : null,
				},
				pickupTime: student?.pickupTime ? moment(student.pickupTime, 'HH:mm:ss').format('HH:mm') : null,
				dropBus: {
					id: student?.dropBus?.id ? student.dropBus.id : null,
					registrationNumber: student?.dropBus?.registrationNumber ? student.dropBus.registrationNumber : null,
				},
				dropPoint: {
					id: student?.dropPoint?.id ? student.dropPoint.id : null,
					name: student?.dropPoint?.name ? student.dropPoint.name : null,
				},
				dropTime: student?.dropTime ? moment(student.dropTime, 'HH:mm:ss').format('HH:mm') : null,
				deletedDate: student?.deletedDate ? isoToDate(student.deletedDate) : null,
				parents:
					student?.parents && student.parents.length !== 0
						? student.parents.map((parent) => {
								return {
									id: parent.id,
									name: parent.name,
								};
						  })
						: [],
			};
		});
	} else return [];
}
