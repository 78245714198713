export function createChangeHistoryDTO(data) {
	let changeHistory = [];

	if (data && data.length) {
		data.forEach((history) => {
			changeHistory.push({
				timestamp: history?.timestamp ? history.timestamp : null,
				description: history?.description ? history.description : null,
			});
		});
	}

	return changeHistory;
}
