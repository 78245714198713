import React from 'react';
import CommonModal from '../../../molecules/modal/CommonModal';
import { Expander } from '../../../../styles/place-holders/Expander';
import {
	RELAY_STATUS_OPTIONS_LOGS,
	VEHICLE_LOGS_EXTERNAL_POWER_FILTER_OPTIONS,
	VEHICLE_LOGS_GPS_FILTER_OPTIONS,
	VEHICLE_LOGS_IGNITION_FILTER_OPTIONS,
	VEHICLE_LOGS_IMMOBILIZER_FILTER_OPTIONS,
} from '../../../../../../data/constants/dropdown-options';
import { Button, Dropdown, Form } from 'semantic-ui-react';
import styled from 'styled-components';

const containerStyle = {
	position: 'relative',
	display: 'inline-block',
	width: '100%',
};

const FilterModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="tiny"
			scrolling={true}
			header="Logs Filters"
			content={
				<Form>
					<Form.Group widths="equal">
						<Form.Field>
							<label>Ignition</label>
							<div style={containerStyle}>
								<Dropdown
									fluid
									selection
									clearable
									// disabled={props.ignitionDisable === null}
									placeholder="Choose ignition value"
									value={props.ignition}
									options={VEHICLE_LOGS_IGNITION_FILTER_OPTIONS}
									onChange={props.onIgnitionFilterChange}
								/>
							</div>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field>
							<label>GPS</label>
							<div style={containerStyle}>
								<Dropdown
									fluid
									selection
									clearable
									// disabled={props.gpsDisable === null}
									placeholder="Choose GPS value"
									value={props.gps}
									options={VEHICLE_LOGS_GPS_FILTER_OPTIONS}
									onChange={props.onGPSFilterChange}
								/>
							</div>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field>
							<label>External Power Status</label>
							<div style={containerStyle}>
								<Dropdown
									fluid
									selection
									clearable
									// disabled={props.externalPowerDisable === null}
									placeholder="Choose External Power status"
									value={props.externalPowerStatus}
									options={VEHICLE_LOGS_EXTERNAL_POWER_FILTER_OPTIONS}
									onChange={props.onExternalFilterChange}
								/>
							</div>
						</Form.Field>
					</Form.Group>
					{/*<Form.Group widths="equal">*/}
					{/*	<Form.Field>*/}
					{/*		<label>AC Status</label>*/}
					{/*		<div style={containerStyle}>*/}
					{/*			<Dropdown*/}
					{/*				fluid*/}
					{/*				selection*/}
					{/*				clearable*/}
					{/*				// disabled={props.acStatusDisable === null}*/}
					{/*				placeholder="Choose AC Status"*/}
					{/*				value={props.acStatus}*/}
					{/*				options={AC_STATUS_FILTER_OPTIONS}*/}
					{/*				onChange={props.onAcStatusFilterChange}*/}
					{/*			/>*/}
					{/*		</div>*/}
					{/*	</Form.Field>*/}
					{/*</Form.Group>*/}

					{/*<Form.Group widths="equal">*/}
					{/*	<Form.Field>*/}
					{/*		<label>Panic Button</label>*/}
					{/*		<div style={containerStyle}>*/}
					{/*			<Dropdown*/}
					{/*				fluid*/}
					{/*				selection*/}
					{/*				clearable*/}
					{/*				search*/}
					{/*				upward={false}*/}
					{/*				placeholder="Choose..."*/}
					{/*				// disabled={props.panicButtonDisable === null}*/}
					{/*				value={props.panicButton}*/}
					{/*				options={PANIC_BUTTON_FILTER_OPTIONS}*/}
					{/*				onChange={props.onPanicButtonFilterChange}*/}
					{/*			/>*/}
					{/*		</div>*/}
					{/*	</Form.Field>*/}
					{/*</Form.Group>*/}

					{props.hasRelay && (
						<Form.Group widths="equal">
							<Form.Field>
								<label>Relay Status</label>
								<div style={containerStyle}>
									<Dropdown
										fluid
										selection
										clearable
										search
										upward={false}
										placeholder="Choose..."
										value={props.relayStatus}
										options={RELAY_STATUS_OPTIONS_LOGS}
										onChange={props.onRelayStatusFilterChange}
									/>
								</div>
							</Form.Field>
						</Form.Group>
					)}

					{props.hasImmobilizer && (
						<Form.Group widths="equal">
							<Form.Field>
								<label>Immobilizer</label>
								<Dropdown
									placeholder="Choose..."
									fluid
									search
									multiple
									selection
									clearable
									value={props.immobilizer}
									options={VEHICLE_LOGS_IMMOBILIZER_FILTER_OPTIONS}
									onChange={props.onImmobilizerFilterChange}
								/>
							</Form.Field>
						</Form.Group>
					)}
				</Form>
			}
			actions={
				<>
					<FlexContainer>
						<Button onClick={props.onResetClick}>Clear Filters</Button>
						<Expander />
						<Button color="black" onClick={props.onCancelClick}>
							Cancel
						</Button>
						<Button primary disabled={props.disabled} onClick={props.onApplyClick}>
							Apply
						</Button>
					</FlexContainer>
				</>
			}
		/>
	);
};

const FlexContainer = styled.section`
	display: flex;
`;

export default FilterModal;
