import React, { useEffect, useState } from 'react';
import { Icon, Menu, Popup } from 'semantic-ui-react';
import { Container } from '../molecules/settings-menu/SettingsMenuStyles';
import { refreshAuditLog } from '../../../../logic/middleware/activityLogs';
import { isoToHumanReadable } from '../../../../logic/utilities/date';

const Notifications = () => {
	// const [notificationModalOpen, setNotificationModalOpen] = useState(false);
	const [data, setData] = useState([]);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		let response = await refreshAuditLog('');
		setData(response);
	};

	return (
		<div>
			<Container style={{ padding: 0 }}>
				<Popup
					style={{ height: '90vh' }}
					position="bottom right"
					on="click"
					trigger={<Icon style={{ fontSize: '1.8rem', color: '#e34234', marginTop: '.3rem' }} name="alarm"></Icon>}>
					<Menu vertical style={{ height: '100%', overflow: 'auto' }} size={'massive'}>
						<div
							style={{
								display: 'flex',
								backgroundColor: '#fff',
								position: 'sticky',
								top: 0,
								zIndex: 100,
							}}>
							<Menu.Item>
								<Menu.Header>Recent Activity</Menu.Header>
							</Menu.Item>
						</div>

						{data.splice(1, 11).map((log, idx) => {
							return (
								<Menu.Item key={idx}>
									<Menu.Menu style={{ color: 'white' }}>
										<Menu.Item>
											<span
												style={{
													fontWeight: 'bold',
													color: 'black',
												}}>
												{log.requestedUser?.name}
											</span>{' '}
											changed{' '}
											<span
												style={{
													fontWeight: 'bold',
													color: 'black',
												}}>
												{log.modelName}
											</span>{' '}
											on{' '}
											<span
												style={{
													fontWeight: 'bold',
													color: 'black',
												}}>
												{isoToHumanReadable(log.timestamp)}
											</span>
										</Menu.Item>
									</Menu.Menu>
								</Menu.Item>
							);
						})}
					</Menu>
				</Popup>
			</Container>
		</div>
	);
};

export default Notifications;
