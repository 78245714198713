import React from 'react';

import { HOUR_OPTIONS, MINUTE_OPTIONS } from '../../../../data/constants/general';
import CalendarImage from './../../../assets/images/calendar.png';

import Store from '../../../../data/store/store';
import { connect } from 'react-redux';

import {
	CalendarBlock,
	Container,
	DateBlock,
	DayBlock,
	PickerContainer,
	PickerControls,
	PickerPane,
	PickerView,
	PlaceholderView,
	ShortcutsBlock,
	ShortcutsPane,
	TimeBlock,
	TimePickerBlock,
} from './CalenderStyles';

import { Button, Dropdown, Icon, Modal } from 'semantic-ui-react';

import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment/moment';

class Calendar extends React.Component {
	constructor() {
		super();

		this.state = {
			datePart1: moment().format('DD-MM-YY'),
			datePart2: moment().format('DD-MM-YY'),
			timePart1: moment().startOf('day').format('HH:mm'),
			timePart2: moment().endOf('day').format('HH:mm'),

			selectedFromHour: '12',
			selectedFromMinute: '00',
			selectedToHour: '11',
			selectedToMinute: '59',

			isTime1Am: true,
			isTime2Am: false,
		};

		this.handleToday = this.handleToday.bind(this);
		this.handleYesterday = this.handleYesterday.bind(this);
		this.handleLast3Days = this.handleLast3Days.bind(this);
		this.handlePastWeek = this.handlePastWeek.bind(this);
		this.handlePastMonth = this.handlePastMonth.bind(this);
		this.handleMonthToDate = this.handleMonthToDate.bind(this);
		this.handleApply = this.handleApply.bind(this);
		this.submitDates = this.submitDates.bind(this);
		this.resetTime = this.resetTime.bind(this);
	}

	componentDidMount() {
		if (this.state.datePart1 !== this.props.initialDate || this.state.datePart2 !== this.props.finalDate) {
			this.setState({
				datePart1: moment(this.props.initialDate).format('DD-MM-YY'),
				datePart2: moment(this.props.finalDate).format('DD-MM-YY'),
			});
		}
	}

	componentDidUpdate(prevProps) {
		// Check if urlDates prop has changed
		if (this.props.urlDates && prevProps.urlDates !== this.props.urlDates) {
			// Set state based on new urlDates prop
			this.setState({
				datePart1: moment(this.props.urlDates.startDate).format('DD-MM-YY'),
				datePart2: moment(this.props.urlDates.endDate).format('DD-MM-YY'),
			});
		}
	}

	handleChange = (event, { name, value }) => {
		if (name === 'date1') {
			this.setState({
				datePart1: value,
			});
		}

		if (name === 'date2') {
			this.setState({
				datePart2: value,
			});
		}
	};

	handleToday() {
		this.setState({
			datePart1: moment().format('DD-MM-YY'),
			datePart2: moment().format('DD-MM-YY'),
			timePart1: '00:00',
			timePart2: '23:59',
			selectedFromHour: '12',
			selectedFromMinute: '00',
			selectedToHour: '11',
			selectedToMinute: '59',
		});

		setTimeout(() => {
			this.submitDates();
		}, 1);
	}

	handleYesterday() {
		this.setState({
			datePart1: moment().subtract(1, 'days').format('DD-MM-YY'),
			datePart2: moment().subtract(1, 'days').format('DD-MM-YY'),
			timePart1: '00:00',
			timePart2: '23:59',
			selectedFromHour: '12',
			selectedFromMinute: '00',
			selectedToHour: '11',
			selectedToMinute: '59',
		});

		setTimeout(() => {
			this.submitDates();
		}, 1);
	}

	handleLast3Days() {
		this.setState({
			datePart1: moment().subtract(2, 'days').format('DD-MM-YY'),
			datePart2: moment().subtract(0, 'days').format('DD-MM-YY'),
			timePart1: '00:00',
			timePart2: '23:59',
			selectedFromHour: '12',
			selectedFromMinute: '00',
			selectedToHour: '11',
			selectedToMinute: '59',
		});

		setTimeout(() => {
			this.submitDates();
		}, 1);
	}

	handlePastWeek() {
		this.setState({
			datePart1: moment().subtract(6, 'days').format('DD-MM-YY'),
			datePart2: moment().subtract(0, 'days').format('DD-MM-YY'),
			timePart1: '00:00',
			timePart2: '23:59',
			selectedFromHour: '12',
			selectedFromMinute: '00',
			selectedToHour: '11',
			selectedToMinute: '59',
		});

		setTimeout(() => {
			this.submitDates();
		}, 1);
	}

	handlePastMonth() {
		this.setState({
			datePart1: moment().subtract(1, 'month').format('DD-MM-YY'),
			datePart2: moment().subtract(0, 'days').format('DD-MM-YY'),
			timePart1: '00:00',
			timePart2: '23:59',
			selectedFromHour: '12',
			selectedFromMinute: '00',
			selectedToHour: '11',
			selectedToMinute: '59',
		});

		setTimeout(() => {
			this.submitDates();
		}, 1);
	}

	handleMonthToDate() {
		this.setState({
			datePart1: moment().startOf('month').format('DD-MM-YY'),
			datePart2: moment().subtract(0, 'days').format('DD-MM-YY'),
			timePart1: '00:00',
			timePart2: '23:59',
			selectedFromHour: '12',
			selectedFromMinute: '00',
			selectedToHour: '11',
			selectedToMinute: '59',
		});

		setTimeout(() => {
			this.submitDates();
		}, 1);
	}

	handleApply() {
		let hourFrom = this.state.isTime1Am
			? this.state.selectedFromHour === '12'
				? '00'
				: this.state.selectedFromHour
			: `${12 + parseInt(this.state.selectedFromHour)}`;

		if (hourFrom === '24') hourFrom = '12';
		let hourTo = this.state.isTime2Am
			? this.state.selectedToHour === '12'
				? '00'
				: this.state.selectedToHour
			: `${12 + parseInt(this.state.selectedToHour)}`;

		if (hourTo === '24') hourTo = '12';

		this.setState({
			timePart1: `${hourFrom}:${this.state.selectedFromMinute}`,
			timePart2: `${hourTo}:${this.state.selectedToMinute}`,
		});

		setTimeout(() => {
			this.submitDates();
		}, 1);
	}

	submitDates() {
		Store.dispatch({
			type: 'SET_DATES',
			payload: {
				initialDate: moment(`${this.state.datePart1}-${this.state.timePart1}`, 'DD-MM-YYYY-HH:mm').toISOString(),
				finalDate: moment(`${this.state.datePart2}-${this.state.timePart2}`, 'DD-MM-YYYY-HH:mm').toISOString(),
			},
		});

		Store.dispatch({
			type: 'HIDE_CALENDAR',
		});
	}

	resetTime() {
		Store.dispatch({ type: 'HIDE_CALENDAR' });

		this.setState({
			datePart1: moment(this.props.initialDate).format('DD-MM-YY'),
			datePart2: moment(this.props.finalDate).format('DD-MM-YY'),
			timePart1: moment(this.props.initialDate).startOf('day').format('HH:mm'),
			timePart2: moment(this.props.finalDate).endOf('day').format('HH:mm'),

			selectedFromHour: '12',
			selectedFromMinute: '00',
			selectedToHour: '11',
			selectedToMinute: '59',

			isTime1Am: true,
			isTime2Am: false,
		});
	}

	render() {
		return (
			<Container>
				{this.props.view === 'icon' ? (
					<Button
						icon
						primary
						title={`Start Date : ${moment(this.props.initialDate).format('DD MMM, YYYY hh:mm A')}\nEnd Date : ${moment(
							this.props.finalDate
						).format('DD MMM, YYYY hh:mm A')}`}
						onClick={() => Store.dispatch({ type: 'SHOW_CALENDAR' })}>
						<Icon name="calendar alternate outline" />
					</Button>
				) : (
					<PlaceholderView
						style={{ padding: '.2em', width: 'max-content' }}
						disabled={this.props.disabled || false}
						onClick={() => Store.dispatch({ type: 'SHOW_CALENDAR' })}>
						<DayBlock style={{ display: 'contents', fontWeight: '600', fontSize: '.9rem' }}>
							<DateBlock style={{ marginRight: '.5em' }}>
								{moment(this.props.initialDate).format('DD MMM, YYYY')}
							</DateBlock>
							<TimeBlock style={{ marginRight: '.5em' }}>{moment(this.props.initialDate).format('hh:mm A')}</TimeBlock>
						</DayBlock>
						<img src={CalendarImage} style={{ height: '2em' }} alt="Calendar" />
						<DayBlock style={{ display: 'contents', fontWeight: '600', fontSize: '.9rem' }}>
							<DateBlock style={{ marginRight: '.5em' }}>
								{moment(this.props.finalDate).format('DD MMM, YYYY')}
							</DateBlock>
							<TimeBlock style={{ marginRight: '.5em' }}>{moment(this.props.finalDate).format('hh:mm A')}</TimeBlock>
						</DayBlock>
					</PlaceholderView>
				)}

				<Modal className="CalendarModal" open={this.props.calendarVisible} onClose={this.resetTime}>
					<Modal.Content>
						<PickerContainer>
							<CalendarBlock>
								<PickerView>
									<PickerPane>
										<DateInput
											name="date1"
											inline
											value={this.state.datePart1}
											dateFormat="DD-MM-YY"
											minDate={this.props.minDate}
											maxDate={this.state.datePart2}
											onChange={this.handleChange}
											marked={moment(this.state.datePart1, 'DD-MM-YY')}
											markColor="red"
										/>
										<TimePickerBlock>
											<Dropdown
												basic
												compact
												placeholder="12"
												search
												selection
												options={HOUR_OPTIONS}
												value={this.state.selectedFromHour}
												onChange={(e, { value }) => this.setState({ selectedFromHour: value })}
											/>
											<Dropdown
												basic
												compact
												placeholder="00"
												search
												selection
												options={MINUTE_OPTIONS}
												value={this.state.selectedFromMinute}
												onChange={(e, { value }) => this.setState({ selectedFromMinute: value })}
											/>
											<Button.Group>
												<Button primary={this.state.isTime1Am} onClick={() => this.setState({ isTime1Am: true })}>
													AM
												</Button>
												<Button primary={!this.state.isTime1Am} onClick={() => this.setState({ isTime1Am: false })}>
													PM
												</Button>
											</Button.Group>
										</TimePickerBlock>
									</PickerPane>
									<PickerPane>
										<DateInput
											name="date2"
											inline
											value={this.state.datePart2}
											dateFormat="DD-MM-YY"
											minDate={this.state.datePart1}
											maxDate={moment()}
											onChange={this.handleChange}
											marked={moment(this.state.datePart2, 'DD-MM-YY')}
											markColor="red"
										/>
										<TimePickerBlock>
											<TimePickerBlock>
												<Dropdown
													basic
													compact
													placeholder="11"
													search
													selection
													options={HOUR_OPTIONS}
													value={this.state.selectedToHour}
													onChange={(e, { value }) => this.setState({ selectedToHour: value })}
												/>
												<Dropdown
													basic
													compact
													placeholder="59"
													search
													selection
													options={MINUTE_OPTIONS}
													value={this.state.selectedToMinute}
													onChange={(e, { value }) => this.setState({ selectedToMinute: value })}
												/>
												<Button.Group>
													<Button.Group>
														<Button primary={this.state.isTime2Am} onClick={() => this.setState({ isTime2Am: true })}>
															AM
														</Button>
														<Button primary={!this.state.isTime2Am} onClick={() => this.setState({ isTime2Am: false })}>
															PM
														</Button>
													</Button.Group>
												</Button.Group>
											</TimePickerBlock>
										</TimePickerBlock>
									</PickerPane>
								</PickerView>
								<PickerControls>
									<Button primary onClick={this.handleApply}>
										Apply
									</Button>
								</PickerControls>
							</CalendarBlock>
							<ShortcutsBlock>
								<ShortcutsPane>
									<Button primary onClick={this.handleToday}>
										Today
									</Button>
									<Button primary onClick={this.handleYesterday}>
										Yesterday
									</Button>
									<Button primary onClick={this.handleLast3Days}>
										Last 3 Days
									</Button>
									<Button primary onClick={this.handlePastWeek}>
										Past Week
									</Button>
									<Button primary onClick={this.handlePastMonth}>
										Past Month
									</Button>
									<Button primary onClick={this.handleMonthToDate}>
										Month to Date
									</Button>
								</ShortcutsPane>
							</ShortcutsBlock>
						</PickerContainer>
					</Modal.Content>
				</Modal>
			</Container>
		);
	}
}

// Connect to Store
const mapStateToProps = (state) => ({
	initialDate: state.dates.initialDate,
	finalDate: state.dates.finalDate,
	calendarVisible: state.dates.calendarVisible,
});

export default connect(mapStateToProps)(Calendar);
