import moment from 'moment';

export default function dates(
	initialState = {
		initialDate: moment().startOf('day').toISOString(),
		finalDate: moment().endOf('day').toISOString(),
		calendarVisible: false,
	},
	action
) {
	switch (action.type) {
		case 'SET_DATES':
			return {
				...initialState,
				initialDate: action.payload.initialDate,
				finalDate: action.payload.finalDate,
			};

		case 'RESET_DATES':
			return {
				...initialState,
				initialDate: moment().startOf('day').toISOString(),
				finalDate: moment().endOf('day').toISOString(),
			};

		case 'CLEAR_DATES':
			return {
				...initialState,
				initialDate: null,
				finalDate: null,
			};

		case 'SHOW_CALENDAR':
			return {
				...initialState,
				calendarVisible: true,
			};

		case 'HIDE_CALENDAR':
			return {
				...initialState,
				calendarVisible: false,
			};

		default:
			return initialState;
	}
}
