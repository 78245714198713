import React, { useEffect, useState } from 'react';
import DashboardNavListItem from '../../molecules/Dashboard-nav-list-item/DashboardNavListItem';
import { Container } from './DashboardNavListStyles';
import { entityAccessSetter } from '../../../../../logic/utilities/accessSetter';

const DashboardNavList = (props) => {
	const [manageDiagnosticsAccess, setManageDiagnosticsAccess] = useState(false);
	const [readDiagnosticsAccess, setReadDiagnosticsAccess] = useState(false);
	// const [createDiagnosticsAccess, setCreateDiagnosticsAccess] = useState(false);
	// const [updateDiagnosticsAccess, setUpdateDiagnosticsAccess] = useState(false);
	// const [deleteDiagnosticsAccess, setDeleteDiagnosticsAccess] = useState(false);

	const [manageFuelLogsAccess, setManageFuelLogsAccess] = useState(false);
	const [readFuelLogsAccess, setReadFuelLogsAccess] = useState(false);
	// const [createFuelLogsAccess, setCreateFuelLogsAccess] = useState(false);
	// const [updateFuelLogsAccess, setUpdateFuelLogsAccess] = useState(false);
	// const [deleteFuelLogsAccess, setDeleteFuelLogsAccess] = useState(false);

	const isSuperAdmin = localStorage.getItem('isSuperAdmin');

	const manageAccessSetter = (rule, action) => {
		switch (action) {
			case 'manageDiagnosticsAccess':
				setManageDiagnosticsAccess(rule);
				break;
			case 'readDiagnosticsAccess':
				setReadDiagnosticsAccess(rule);
				break;
			case 'manageFuelLogsAccess':
				setManageFuelLogsAccess(rule);
				break;
			case 'readFuelLogsAccess':
				setReadFuelLogsAccess(rule);
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		const permissions = JSON.parse(localStorage.getItem('permissions'));

		const diagnosticsPermissions = permissions?.filter((permission) => permission.subject === 'Diagnostics');
		if (diagnosticsPermissions) {
			for (const diagnosticsPrm of diagnosticsPermissions) {
				entityAccessSetter(diagnosticsPrm?.action, 'Diagnostics', manageAccessSetter);
			}
		}

		const fuelLogsPermissions = permissions?.filter((permission) => permission.subject === 'Service And Maintenance');
		if (fuelLogsPermissions) {
			for (const fuelLogsPrm of fuelLogsPermissions) {
				entityAccessSetter(fuelLogsPrm?.action, 'FuelLogs', manageAccessSetter);
			}
		}
	}, []);

	return (
		<Container>
			<div style={{ marginTop: '3rem' }}>
				<DashboardNavListItem urlPrefix={props.urlPrefix} exact={true} iconName="home" popupTitle="Home" />
			</div>
			<DashboardNavListItem urlPrefix={`${props.urlPrefix}operations`} iconName="rocket" popupTitle="Operations" />
			<DashboardNavListItem urlPrefix={`${props.urlPrefix}ums`} iconName="shield" popupTitle="User Management System" />
			<DashboardNavListItem urlPrefix={`${props.urlPrefix}documents`} iconName="folder" popupTitle="Documents" />
			<div style={{ display: `${!manageDiagnosticsAccess && !readDiagnosticsAccess ? 'none' : 'flex'}` }}>
				<DashboardNavListItem
					urlPrefix={`${props.urlPrefix}diagnostic-and-logs`}
					iconName="gg"
					popupTitle="Diagnostics And Logs"
				/>
			</div>
			<div style={{ display: `${!manageFuelLogsAccess && !readFuelLogsAccess ? 'none' : 'flex'}` }}>
				<DashboardNavListItem
					urlPrefix={`${props.urlPrefix}service-and-maintenance`}
					iconName="wrench"
					popupTitle="Service and Maintenance"
				/>
			</div>
			<div style={{ display: `${isSuperAdmin === 'false' ? 'none' : 'flex'}` }}>
				<DashboardNavListItem
					urlPrefix={`${props.urlPrefix}data-automation`}
					iconName="settings"
					popupTitle="Data Automation"
				/>
			</div>
		</Container>
	);
};

export default DashboardNavList;
