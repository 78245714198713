import React from 'react';
import styled from 'styled-components';
import PagenotfoundImg from '../../assets/images/page-not-found.jpg';
import { Button, Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

const Info = styled.div`
	text-align: center;
	margin-bottom: 50px;

	h2 {
		font-style: normal;
		font-weight: bold;
		font-size: 64px;
		line-height: 95px;
		letter-spacing: -0.035em;
		margin: 0 0 36px 0;
		color: #1b3c71;
		@media (max-width: 768px) {
			font-size: 48px;
			line-height: 71px;
			margin: 0 0 29px 0;
		}
		@media (max-width: 400px) {
			letter-spacing: -0.05em;
		}
	}

	p {
		display: block;
		font-style: normal;
		font-weight: normal;
		font-size: 24px;
		line-height: 36px;
		letter-spacing: -0.035em;
		color: #8aadd3;
		margin: 0 0 64px 0;
		@media (max-width: 768px) {
			width: 100%;
			padding: 0 20px 0 0;
			font-size: 18px;
			line-height: 27px;
		}
	}
`;

const Image = styled.img`
	display: block;
	width: 100%;
	max-width: 540px;
	margin: 50px auto;
`;

const Pagenotfound = () => {
	const history = useHistory();
	const handleBackToDashboard = () => {
		history.push('/');
	};
	return (
		<div>
			<div>
				<Image src={PagenotfoundImg} alt="" />
			</div>
			<Info>
				<h2>I have bad news for you</h2>
				<p>The page you are looking for might be removed or is temporarily unavailable</p>
				<Button style={{ backgroundColor: '#1b3c71', color: '#fff', padding: '20px' }} onClick={handleBackToDashboard}>
					<Icon name={'home'} />
					Back to home
				</Button>
			</Info>
		</div>
	);
};
export default Pagenotfound;
