import React, { useEffect, useState } from 'react';

import { ANDROID_APP_LINK, IOS_APP_LINK } from '../../../../data/constants/constants';
import LogoImage from '../../../assets/images/logo.png';
import PlayStoreImage from '../../../assets/images/play-store.png';
import AppStoreImage from '../../../assets/images/app-store.png';

import Store from '../../../../data/store/store';

import { Button, Form, Icon, Input } from 'semantic-ui-react';
import { THEME_COLOR } from '../../../styles/colors';

import { getOTP, login, verifyOTP } from '../../../../logic/middleware/auth';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

const LoginForm = () => {
	const { loading, sendingOTP, otpSent, verifyingOTP } = useSelector((state) => state.auth);
	//let history = useHistory();
	// State
	const [username, setUsername] = useState('');
	const [otp, setOtp] = useState(null);
	const [counter, setCounter] = useState(null);
	const [withPassword, setWithPassword] = useState(true);
	const [password, setPassword] = useState(null);
	const [passwordVisible, setPasswordVisible] = useState(null);

	useEffect(() => {
		if (counter && counter > 0) {
			setTimeout(() => {
				setCounter(counter - 1);
			}, 1000);
		}
	}, [counter]);

	useEffect(() => {
		if (otpSent) {
			setCounter(15);
		}
	}, [sendingOTP, otpSent]);

	useEffect(() => {
		if (withPassword) {
			Store.dispatch({ type: 'AUTH_SEND_OTP_REJECT' });
		}
	}, [withPassword]);

	// Unmount
	useEffect(
		() => () => {
			Store.dispatch({ type: 'AUTH_RESET' });
		},
		[]
	);

	return (
		<Container>
			<LogoContainer>
				<img src={LogoImage} alt="Logo" />
			</LogoContainer>
			<Form>
				<Form.Field>
					<label>{withPassword ? 'Email or Phone' : 'Mobile Number'}</label>
					{withPassword ? (
						<Input
							type="text"
							placeholder="Email or Phone"
							value={username}
							onChange={(e) => {
								setUsername(e.target.value);
							}}
						/>
					) : (
						<Input
							type="text"
							placeholder="Phone"
							maxLength={10}
							value={username}
							onChange={(e) => {
								setUsername(e.target.value);
							}}
						/>
					)}
				</Form.Field>
				{otpSent && !withPassword && (
					<Form.Field>
						<label>OTP</label>
						<Input
							type="text"
							placeholder="OTP"
							maxLength={6}
							onChange={(e) => {
								setOtp(e.target.value);
							}}
						/>
					</Form.Field>
				)}
				{withPassword && (
					<Form.Field>
						<label>Password</label>
						<Input
							type={passwordVisible ? 'text' : 'password'}
							placeholder="Secret Password"
							icon={
								<Icon
									name={passwordVisible ? 'eye' : 'eye slash'}
									link
									color={passwordVisible ? 'blue' : 'grey'}
									onClick={() => setPasswordVisible(!passwordVisible)}
								/>
							}
							onChange={(e) => {
								setPassword(e.target.value);
							}}
						/>
					</Form.Field>
				)}
				<ButtonContainer>
					<Button
						primary
						fluid
						type="submit"
						// disabled={!withPassword ? (otpSent ? !username || !otp : !username) : !username || !password}
						loading={withPassword ? loading : !otpSent ? sendingOTP : verifyingOTP}
						onClick={() => {
							if (withPassword) {
								login(username, password, function (flag) {
									if (flag) {
										window.location.href = '/';
									}
								});
							} else {
								if (!otpSent) {
									getOTP(username).catch();
								} else {
									verifyOTP(username, otp, function (flag) {
										if (flag) {
											window.location.href = '/';
										}
									});
								}
							}
						}}>
						{withPassword ? 'Login' : !otpSent ? 'Send OTP' : 'Verify'}
					</Button>
					{otpSent && !withPassword && (
						<Button
							primary
							fluid
							type="submit"
							style={{ margin: 0 }}
							loading={loading}
							disabled={!!counter}
							onClick={() => {
								getOTP(username);
							}}>
							{`Resend OTP${counter ? ` (${counter})` : ''}`}
						</Button>
					)}
				</ButtonContainer>

				<LoginWithPassword
					onClick={() => {
						setUsername('');
						setWithPassword(!withPassword);
					}}>
					{withPassword ? 'Login with OTP' : 'Login with Password'}
				</LoginWithPassword>
				<AppLinksContainer>
					<a href={ANDROID_APP_LINK} target="_blank" rel="noreferrer">
						<img src={PlayStoreImage} alt="Play Store" />
					</a>
					<a href={IOS_APP_LINK} target="_blank" rel="noreferrer">
						<img src={AppStoreImage} alt="App Store" />
					</a>
				</AppLinksContainer>
			</Form>
		</Container>
	);
};

export default LoginForm;

export const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding: 3em;

	background: #fff;
	border-radius: 10px;
`;

export const LogoContainer = styled.section`
	width: 100%;

	margin-bottom: 3em;
	box-sizing: border-box;

	display: flex;
	justify-content: center;
`;

export const ButtonContainer = styled.section`
	width: 100%;

	display: flex;
	justify-content: center;
	align-items: center;
`;

export const LoginWithPassword = styled.section`
	width: 100%;
	display: flex;
	justify-content: center;
	cursor: pointer;
	margin-top: 0.5em;

	span {
		font-size: 1em;
		font-weight: bold;
	}

	&:hover {
		color: ${THEME_COLOR};
	}
`;

export const AppLinksContainer = styled.section`
	width: 100%;

	margin-top: 0.5em;
	display: flex;
	justify-content: space-around;

	box-sizing: border-box;

	img {
		width: 11.5em;
	}
`;
