import React, { useEffect, useState } from 'react';
import BasicLoader from '../../../blocks/molecules/loader/BasicLoader';
import { GraphCard, GraphContainerForPie } from '../pie-charts/PieChartsStyles';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { Chart } from 'react-google-charts';

const RecentlyAddedVehiclesGraph = (props) => {
	const [stateValue, setStateValue] = useState({});
	const navLinkRef = React.createRef();

	useEffect(() => {
		if (navLinkRef.current && Object.keys(stateValue).length > 0) {
			navLinkRef.current.click();
		}
	}, [
		props.totalVehicleLoading,
		props.trialVehicleCount,
		props.newVehicleCount,
		stateValue.startDate,
		stateValue.endDate,
	]);
	return (
		<GraphContainerForPie>
			<GraphCard>
				{props.totalVehicleLoading && props.totalVehicleCount !== null && props.newVehicleCount !== null ? (
					<BasicLoader />
				) : (
					<Chart
						chartType="PieChart"
						data={[
							['Vehicles', 'Count'],
							['Active Vehicles', props.totalVehicleCount - props.newVehicleCount],
							['Recently Added Vehicles', props.newVehicleCount],
						]}
						height="100%"
						width="100%"
						options={{
							title: `Recently Added Vehicles - 60 Days ${props.newVehicleCount ? `(${props.newVehicleCount})` : ''}`,

							pieHole: 0.4,
							slices: {
								1: { offset: 0.1 },
							},
							pieStartAngle: -90,
							pieSliceText: 'value',
							sliceVisibilityThreshold: 0,
							chartArea: {
								right: 0, // set this to adjust the legend width
								left: 20, // set this eventually, to adjust the left margin
							},
							fontSize: 12,
							legend: {
								position: 'bottom',
								alignment: 'center',
							},
						}}
						chartEvents={[
							{
								eventName: 'ready',
								callback: ({ chartWrapper, google }) => {
									const chart = chartWrapper.getChart();
									google.visualization.events.addListener(chart, 'click', (e) => {
										if (e.targetID === 'slice#1') {
											setStateValue({
												startDate: `${moment().subtract(60, 'days').format('DD-MM-YYYY')}`,
												endDate: `${moment().endOf('day').format('DD-MM-YYYY')}`,
											});
										} else if (e.targetID === 'slice#1' || e.targetID === 'slice#0') {
											setStateValue({
												startDate: '',
												endDate: '',
											});
										}
									});
								},
							},
						]}
					/>
				)}
			</GraphCard>
			<NavLink
				ref={navLinkRef}
				to={{
					pathname: '/operations/vehicles',
					state: stateValue,
				}}
			/>
		</GraphContainerForPie>
	);
};

export default RecentlyAddedVehiclesGraph;
