import { SERVICE_VEHICLES_FILE_HEADER, VEHICLE_TYPE } from "../../data/constants/general";
import Logo from "../../ui/assets/images/logo.png";
import { dispatchNoPayload } from "./middleware";
import { exportToExcelWithLogo } from "../utilities/excel";

import { jsPDF } from "jspdf";
import { getHumanReadableElapsedTime, isoToHumanReadable } from "../utilities/date";

// Export
export function openExportModal() {
	dispatchNoPayload('SERVICE_EXPORT_MODAL_OPEN');
}

export function closeExportModal() {
	dispatchNoPayload('SERVICE_EXPORT_MODAL_CLOSE');
}

export function formatExcelExport(response) {
	return response.map((vehicle) => {
		return {
			Registration: vehicle.registrationNumber,
			'Offline Time': vehicle.offlineTime ? getHumanReadableElapsedTime(vehicle.offlineTime) : '',
			'Last Data': vehicle.lastData ? isoToHumanReadable(vehicle.lastData) : '',
			Organisation: vehicle.organisation,
			'Phone (Organisation)': vehicle.organisationPhone,
			Type:
				vehicle.type === VEHICLE_TYPE.Car
					? 'Car'
					: vehicle.type === VEHICLE_TYPE.Bike
					? 'Bike'
					: vehicle.type === VEHICLE_TYPE.Truck
					? 'Truck'
					: vehicle.type === VEHICLE_TYPE.Construction
					? 'Construction'
					: vehicle.type === VEHICLE_TYPE['School Bus']
					? 'School Bus'
					: vehicle.type === VEHICLE_TYPE.Generator
					? 'Generator'
					: vehicle.type === VEHICLE_TYPE.Container
					? 'Container'
					: vehicle.type === VEHICLE_TYPE['Mini Truck']
					? 'Mini Truck'
					: vehicle.type === VEHICLE_TYPE['Auto Rickshaw']
					? 'Auto Rickshaw'
					: '',
			'Installation Date': vehicle.installationDate,
		};
	});
}

export function exportData(data, fileName, extension) {
	if (extension === 'excel') {
		exportToExcelWithLogo(formatExcelExport(data), `${!fileName ? 'Service' : fileName}`, SERVICE_VEHICLES_FILE_HEADER);
	} else {
		convertToPdf(formatExcelExport(data), fileName);
	}

	closeExportModal();
}

export function convertToPdf(data, fileName) {
	const doc = new jsPDF({ orientation: 'l', unit: 'mm', format: 'a4' });

	let col = [
		'Registration',
		'Offline Time',
		'Last Data',
		'Organisation',
		'Phone (Organisation)',
		'Type',
		'Installation Date',
	];
	let rows = [];

	data.forEach((vehicle) => {
		let temp = [];
		Object.keys(vehicle).forEach((key, index) => {
			if (col[index] === key) temp[index] = vehicle[key];
		});

		rows.push(temp);
	});

	let header = function (data) {
		doc.addImage(Logo, 'png', data.settings.margin.left, 10, 40, 14);
	};

	doc.autoTable(col, rows, {
		margin: { top: 25 },
		beforePageContent: header,
		theme: 'grid',
	});

	doc.save(`${!fileName ? 'Vehicles' : fileName}`);
}
