import { GraphCard, GraphContainerForBar } from './comboChartStyle';
import BasicLoader from '../../../blocks/molecules/loader/BasicLoader';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Chart } from 'react-google-charts';
import { GraphHeader } from '../OverviewStyles';

function TotalVehiclesGraph(props) {
	const [totalVehicleDataGraph, setTotalVehicleDataGraph] = useState([]);
	useEffect(() => {
		let totalVehicleDataValue = [];
		let { newVehiclesMonthOnMonth, totalVehicles } = props;
		if (totalVehicles) {
			let totalNewVehicle = newVehiclesMonthOnMonth.reduce((total, curr) => {
				return total + parseInt(curr.count);
			}, 0);
			let currTotal = totalVehicles - totalNewVehicle;
			newVehiclesMonthOnMonth.forEach((element) => {
				currTotal += parseInt(element.count);
				let currMonth = moment(element.month, 'YYYY-MM').format('MMM-YYYY');
				totalVehicleDataValue.push({
					Date: currMonth,
					'New Vehicles': parseInt(element.count),
					'Total Vehicles': currTotal,
					GrowthPercentage: `${((element.count / currTotal) * 100).toFixed(1)}%`,
					// GrowthPercentage: `${((element.count / (currTotal - element.count)) * 100).toFixed(1)}%`,
				});
			});
			setTotalVehicleDataGraph(totalVehicleDataValue);
		}
	}, [props]);

	const keys = ['Date', 'New Vehicles', 'Total Vehicles'];
	const graphData = [
		keys,
		...totalVehicleDataGraph.map((item) => [item.Date, item['New Vehicles'], item['Total Vehicles']]),
	];

	const options = {
		title: 'New Vehicles (M-o-M)',

		seriesType: 'bars',
		series: {
			0: {
				targetAxisIndex: 0,
			},
			1: {
				type: 'line',
				targetAxisIndex: 1,
			},
		},
		vAxes: {
			0: {
				title: 'New Vehicles',
				gridlines: { color: 'transparent' },
				titleTextStyle: {
					color: 'black',
					fontName: 'Arial',
					fontSize: 13,
					bold: true,
					italic: false,
				},
			},
			1: {
				title: 'Total Vehicles',
				gridlines: { color: 'transparent' },
				titleTextStyle: {
					color: 'black',
					fontName: 'Arial',
					fontSize: 13,
					bold: true,
					italic: false,
				},
			},
		},
		legend: {
			position: 'bottom',
			interactivity: false,
			click: false,
		},
		colors: ['#DC3A2F', '#3366CC'],
	};
	return (
		<GraphContainerForBar>
			{props.totalVehicleLoading ? (
				<BasicLoader />
			) : (
				<GraphCard>
					{totalVehicleDataGraph.length > 0 ? (
						<Chart chartType="ComboChart" width="100%" height="300px" data={graphData} options={options} />
					) : (
						<>
							<GraphHeader>
								<h1>New Vehicles (M-o-M)</h1>
							</GraphHeader>
							<div
								style={{
									justifyContent: 'center',
									alignItems: 'center',
									flex: 1,
								}}>
								<p>No Data!</p>
							</div>
						</>
					)}
				</GraphCard>
			)}
		</GraphContainerForBar>
	);
}

export default TotalVehiclesGraph;

/*
/!*
import { GraphCard, GraphContainerForBar, GraphHeader } from '../pie-charts/PieChartsStyles';
import BasicLoader from '../../../blocks/molecules/loader/BasicLoader';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
// import {ResponsiveLine} from "@nivo/line";
// import {ResponsiveBar} from "@nivo/bar";
// import { Layer } from '@nivo/core';
// import { ResponsiveXYFrame } from '@nivo/xy';
import { ResponsiveBar} from '@nivo/bar';
import { ResponsiveLine} from '@nivo/line';

function TotalVehiclesGraph(props) {
    const [totalVehicleDataGraph, setTotalVehicleDataGraph] = useState([]);
    useEffect(() => {
        let totalVehicleDataValue = [];
        let { newVehiclesMonthOnMonth, totalVehicles } = props;
        if (totalVehicles) {
            let totalNewVehicle = newVehiclesMonthOnMonth.reduce((total, curr) => {
                return total + parseInt(curr.count);
            }, 0);
            let currTotal = totalVehicles - totalNewVehicle;
            newVehiclesMonthOnMonth.forEach((element) => {
                currTotal += parseInt(element.count);
                let currMonth = moment(element.month, 'YYYY-MM').format('MMM-YYYY');
                totalVehicleDataValue.push({
                    Date: currMonth,
                    'New Vehicles': parseInt(element.count),
                    'Total Vehicles': currTotal,
                    GrowthPercentage: `${((element.count / currTotal) * 100).toFixed(1)}%`,
                    // GrowthPercentage: `${((element.count / (currTotal - element.count)) * 100).toFixed(1)}%`,
                });
            });
            console.log("totalVehicleDataValue",totalVehicleDataValue);
            setTotalVehicleDataGraph(totalVehicleDataValue);
        }
    }, [props]);
    console.log("totalVehicleDataGraph", totalVehicleDataGraph);

    const barChartData = [
        {
            country: 'USA',
            value: 150,
        },
        {
            country: 'Canada',
            value: 100,
        },
        // Add more data as needed
    ];

    const lineChartData = [
        {
            id: 'series1',
            data: [
                { x: 0, y: 10 },
                { x: 1, y: 15 },
                { x: 2, y: 7 },
                // Add more data points as needed
            ],
        },
    ];
   /!* const layers = [
        {
            id: 'bars',
            type: 'bar',
            data: barChartData,
            render: Bar,
        },
        {
            id: 'lines',
            type: 'line',
            data: lineChartData,
            render: Line,
        },
    ];
*!/

    console.log("barChartData",barChartData);
    console.log("lineChartData",lineChartData);
    return (
        <GraphContainerForBar>
            {props.totalVehicleLoading ? (
                <BasicLoader />
            ) : (
                <GraphCard>
                    <GraphHeader>
                        <h1>New Vehicles (M-o-M)</h1>
                    </GraphHeader>
                    <div style={{ height: '400px' }}>
                    <svg width={600} height={400}>
                        {/!* Bar chart *!/}
                        <g transform={`translate(50, 50)`}>
                            <ResponsiveBar
                                data={barChartData}
                                keys={['value']}
                                indexBy="country"
                                colors={{ scheme: 'nivo' }}
                                padding={0.3}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                }}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                }}
                            />
                        </g>
                        {/!* Line chart *!/}
                        <g transform={`translate(50, 50)`}>
                            <ResponsiveLine
                                data={lineChartData}
                                xScale={{ type: 'linear' }}
                                yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                                curve="monotoneX"
                                axisTop={null}
                                axisRight={null}
                                enableGridX={false}
                                enableGridY={false}
                                enablePoints={false}
                                colors={{ scheme: 'nivo' }}
                                lineWidth={2}
                                enableArea={true}
                                areaOpacity={0.2}
                                enableSlices="x"
                                useMesh={true}
                            />
                        </g>
                    </svg>
                    </div>

                    ===========================
                   {/!* <ResponsiveBar
                        data={totalVehicleDataGraph}
                        width={500}
                        // height={300}
                        // groupMode="grouped"
                        enableGridY={false}
                        enableGridX={false}
                        keys={['New Vehicles']}
                        indexBy="Date"
                        margin={{ top: 20, right: 50, bottom: 80, left: 50 }}
                        padding={0.1}
                        valueScale={{ type: 'symlog' }}
                        indexScale={{ type: 'band', round: true }}
                        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        axisTop={null}
                        axisRight={null}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor="white"
                        axisBottom={{
                            tickRotation: -45,
                            tickPadding: 5,
                            legendOffset: 40,
                        }}
                        tooltip={({ data }) => (
                            <div
                                style={{
                                    padding: 12,
                                    color: 'black',
                                    background: 'white',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                }}>
                                <div style={{ justifyContent: 'left' }}>
                                    Total Vehicles - {`${data.Date}`} <strong> {`: ${data['Total Vehicles']}`}</strong>
                                </div>
                                <div style={{ justifyContent: 'left' }}>
                                    Growth: <strong>{data.GrowthPercentage}</strong>
                                </div>
                            </div>
                        )}
                        axisLeft={null}
                        colors={['#DC3A2F']}
                        // layers={['grid', 'axes', 'bars', 'markers', 'legends']}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'bottom',
                                direction: 'row',
                                justify: false,
                                translateX: 0,
                                translateY: 70,
                                itemsSpacing: 18,
                                itemWidth: 100,
                                itemHeight: 0,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 15,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1,
                                        },
                                    },
                                ],
                            },
                        ]}
                        enableLabel={true}
                        label={({ value }) => value.toLocaleString()}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}>
                    </ResponsiveBar>*!/}
                  {/!*
                  <ResponsiveLine
                        data={totalVehicleDataGraph}
                        keys={['New Vehicles']}
                        margin={{ top: 20, right: 50, bottom: 80, left: 50 }}
                        xScale={{ type: 'point' }}
                        yScale={{ type: 'linear', stacked: true, min: 'auto', max: 'auto' }}
                        curve="monotoneX"
                        yFormat=" >-.2f"
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            // orient: 'bottom',
                            // tickRotation: -45,
                            // tickPadding: 5,
                            // legendOffset: 40,
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'transportation',
                            legendOffset: 36,
                            legendPosition: 'middle'
                        }}
                        axisLeft={{
                            // orient: 'left',
                            // tickSize: 5,
                            // tickPadding: 5,
                            // tickRotation: 0,
                            // legend: 'Count',
                            // legendOffset: -50,
                            // legendPosition: 'middle',

                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'count',
                            legendOffset: -40,
                            legendPosition: 'middle'

                        }}
                        colors={['#DC3A2F', '#3366CC']}
                        enableGridX={false}
                        enableGridY={false}
                        lineWidth={2}
                        enablePoints={true}
                        pointSize={6}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointLabel="y"
                        pointLabelYOffset={-12}
                        enableArea={true}
                        areaOpacity={0.2}
                        useMesh={true}
                        legends={[
                            {
                                anchor: 'bottom-left',
                                // direction: 'row',
                                justify: false,
                                // translateX: 0,
                                // translateY: 50,
                                // itemsSpacing: 5,
                                itemDirection: 'left-to-right',
                                itemWidth: 80,
                                itemHeight: 12,
                                itemOpacity: 0.75,
                                symbolSize: 12,
                                symbolShape: 'circle',
                                symbolBorderColor: 'rgba(0, 0, 0, .5)',

                                direction: 'column',
                                translateX: 100,
                                translateY: 0,
                                itemsSpacing: 0,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemBackground: 'rgba(0, 0, 0, .03)',
                                            itemOpacity: 1
                                        }
                                    }
                                ]

                            },
                        ]}
                    />*!/}

                   {/!* <ResponsiveBar
                        data={barChartData}
                        keys={['value']}
                        indexBy="country"
                        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                        padding={0.3}
                        colors={{ scheme: 'nivo' }}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                            },
                        ]}
                    />

                    <ResponsiveLine
                        data={lineChartData}
                        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                        xScale={{ type: 'linear' }}
                        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                        curve="monotoneX"
                        axisTop={null}
                        axisRight={null}
                        enableGridX={false}
                        enableGridY={false}
                        enablePoints={false}
                        colors={{ scheme: 'nivo' }}
                        lineWidth={2}
                        enableArea={true}
                        areaOpacity={0.2}
                        enableSlices="x"
                        useMesh={true}
                        legends={[
                            {
                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 100,
                                translateY: 0,
                                itemsSpacing: 0,
                                itemDirection: 'left-to-right',
                                itemWidth: 80,
                                itemHeight: 20,
                                itemOpacity: 0.75,
                                symbolSize: 12,
                                symbolShape: 'circle',
                                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            },
                        ]}
                        layers={[
                            // Render the bar chart on top of the line chart
                            'grid',
                            'axes',
                            'points',
                            'areas',
                            'crosshair',
                            'lines',
                            'slices',
                            'mesh',
                            'legends',
                            (props) => (
                                <Layer key="custom-bars" {...props}>
                                    <ResponsiveBar
                                        data={barChartData}
                                        keys={['value']}
                                        indexBy="country"
                                        innerPadding={0}
                                        axisLeft={null}
                                        axisBottom={null}
                                        enableLabel={false}
                                        colors={{ scheme: 'nivo' }}
                                        animate={false}
                                        motionStiffness={90}
                                        motionDamping={15}
                                        borderRadius={0}
                                    />
                                </Layer>
                            ),
                        ]}
                    />*!/}



                </GraphCard>
            )}
        </GraphContainerForBar>
    );
}

export default TotalVehiclesGraph;


/!*function TotalVehiclesGraph(props) {
	const [totalVehicleDataGraph, setTotalVehicleDataGraph] = useState([]);

	useEffect(() => {
		let totalVehicleDataValue = [];
		let { newVehiclesMonthOnMonth, totalVehicles } = props;
		if (totalVehicles) {
			let totalNewVehicle = newVehiclesMonthOnMonth.reduce((total, curr) => {
				return total + parseInt(curr.count);
			}, 0);
			let currTotal = totalVehicles - totalNewVehicle;
			newVehiclesMonthOnMonth.forEach((element) => {
				currTotal += parseInt(element.count);
				let currMonth = moment(element.month, 'YYYY-MM').format('MMM-YYYY');
				totalVehicleDataValue.push({
					Date: currMonth,
					'New Vehicles': parseInt(element.count),
					'Total Vehicles': currTotal,
					GrowthPercentage: `${((element.count / currTotal) * 100).toFixed(1)}%`,
				});
			});
			setTotalVehicleDataGraph(totalVehicleDataValue);
		}
	}, [props]);

	return (
		<GraphContainerForBar>
			{props.totalVehicleLoading ? (
				<BasicLoader />
			) : (
				<GraphCard>
					<GraphHeader>
						<h1>New Vehicles (M-o-M)</h1>
					</GraphHeader>
					<ResponsiveBar
						data={totalVehicleDataGraph}
						groupMode="grouped"
						enableGridY={false}
						enableGridX={false}
						keys={['New Vehicles']}
						indexBy="Date"
						margin={{ top: 20, right: 50, bottom: 80, left: 50 }}
						padding={0.1}
						valueScale={{ type: 'symlog' }}
						indexScale={{ type: 'band', round: true }}
						borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
						axisTop={null}
						axisRight={null}
						labelSkipWidth={12}
						labelSkipHeight={12}
						labelTextColor="white"
						axisBottom={{
							tickRotation: -45,
							tickPadding: 5,
							legendOffset: 40,
						}}
						tooltip={({ data }) => (
							<div
								style={{
									padding: 12,
									color: 'black',
									background: 'white',
									display: 'flex',
									flexDirection: 'column',
									boxShadow:
										'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
								}}
							>
								<div style={{ justifyContent: 'left' }}>
									New Vehicles - {`${data.Date}`}{' '}
									<strong> {`: ${data['New Vehicles']}`}</strong>
								</div>
							</div>
						)}
						colors={['#DC3A2F']}
						enableLabel={true}
						label={({ value }) => value.toLocaleString()}
						animate={true}
						motionStiffness={90}
						motionDamping={15}
					/>
					<ResponsiveLine
						data={totalVehicleDataGraph}
						margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
						yScale={{
							type: 'linear',
							min: 'auto',
							max: 'auto',
							stacked: true,
							reverse: false,
						}}
						xScale={{
							type: 'point',
						}}
						axisTop={null}
						axisRight={null}
						enableGridX={false}
						enableGridY={false}
						enablePoints={true}
						enableArea={true}
						lineWidth={2}
						pointSize={4}
						pointColor={{ theme: 'background' }}
						pointBorderWidth={2}
						pointBorderColor={{ from: 'serieColor' }}
						pointLabel="y"
						pointLabelYOffset={-12}
						colors={{ scheme: 'nivo' }}
						enableSlices="x"
						useMesh={true}
						enableCrosshair={false}
						legends={[
							{
								anchor: 'bottom-left',
								direction: 'row',
								justify: false,
								translateX: 0,
								translateY: 50,
								itemsSpacing: 5,
								itemDirection: 'left-to-right',
								itemWidth: 80,
								itemHeight: 12,
								itemOpacity: 0.75,
								symbolSize: 12,
								symbolShape: 'circle',
								symbolBorderColor: 'rgba(0, 0, 0, .5)',
							},
						]}
					/>
				</GraphCard>
			)}
		</GraphContainerForBar>
	);
}*!/

// export default TotalVehiclesGraph;

*!/


import { GraphCard, GraphContainerForBar, GraphHeader } from '../pie-charts/PieChartsStyles';
import BasicLoader from '../../../blocks/molecules/loader/BasicLoader';
import React, { useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import moment from 'moment';

import {line} from 'd3-shape';
function TotalVehiclesGraph(props) {
    const [totalVehicleDataGraph, setTotalVehicleDataGraph] = useState([]);
    useEffect(() => {
        let totalVehicleDataValue = [];
        let { newVehiclesMonthOnMonth, totalVehicles } = props;
        if (totalVehicles) {
            let totalNewVehicle = newVehiclesMonthOnMonth.reduce((total, curr) => {
                return total + parseInt(curr.count);
            }, 0);
            let currTotal = totalVehicles - totalNewVehicle;
            newVehiclesMonthOnMonth.forEach((element) => {
                currTotal += parseInt(element.count);
                let currMonth = moment(element.month, 'YYYY-MM').format('MMM-YYYY');
                totalVehicleDataValue.push({
                    Date: currMonth,
                    'New Vehicles': parseInt(element.count),
                    'Total Vehicles': currTotal,
                    GrowthPercentage: `${((element.count / currTotal) * 100).toFixed(1)}%`,
                    // GrowthPercentage: `${((element.count / (currTotal - element.count)) * 100).toFixed(1)}%`,
                });
            });
            setTotalVehicleDataGraph(totalVehicleDataValue);
        }
    }, [props]);

    console.log("totalVehicleDataValue",totalVehicleDataGraph);
    const LineLayer = ({ bars, xScale , yScale }) => {
    	bars = bars.filter((bar) => bar.key.startsWith("Total Vehicles"));
    	console.log("bars", bars);
    	const lineGenerator = line()
    		.x((d) => xScale(d.data.data.Date) + d.width / 2)
    		.y((d) => yScale(d.data.value));
    	return (
    		<path d={lineGenerator(bars)} fill="none" stroke="#DC3A2F" strokeDasharray="5 5" strokeWidth="4" />
    	);
    };
    return (
        <GraphContainerForBar>
            {props.totalVehicleLoading ? (
                <BasicLoader />
            ) : (
                <GraphCard>
                    <GraphHeader>
                        <h1>New Vehicles (M-o-M)</h1>
                    </GraphHeader>
                    <ResponsiveBar
                        data={totalVehicleDataGraph}
                        // width={596}
                        // height={300}
                        groupMode="grouped"
                        enableGridY={false}
                        enableGridX={false}
                        keys={['New Vehicles', "Total Vehicles"]}
                        indexBy="Date"
                        margin={{ top: 20, right: 50, bottom: 80, left: 50 }}
                        padding={0.1}
                        valueScale={{ type: 'symlog' }}
                        indexScale={{ type: 'band', round: true }}
                        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        axisTop={null}
                        axisRight={null}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor="white"
                        axisBottom={{
                            tickRotation: -45,
                            tickPadding: 5,
                            legendOffset: 40,
                        }}
                        tooltip={({ data }) => (
                            <div
                                style={{
                                    padding: 12,
                                    color: 'black',
                                    background: 'white',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                }}>
                                <div style={{ justifyContent: 'left' }}>
                                    Total Vehicles - {`${data.Date}`} <strong> {`: ${data['Total Vehicles']}`}</strong>
                                </div>
                                <div style={{ justifyContent: 'left' }}>
                                    Growth: <strong>{data.GrowthPercentage}</strong>
                                </div>
                            </div>
                        )}
                        axisLeft={null}
                        colors={['#DC3A2F', '#fff']}
                        layers={['grid', 'axes', 'bars', 'markers', 'legends', LineLayer]}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'bottom',
                                direction: 'row',
                                justify: false,
                                translateX: 0,
                                translateY: 70,
                                itemsSpacing: 18,
                                itemWidth: 100,
                                itemHeight: 0,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 15,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1,
                                        },
                                    },
                                ],
                            },
                        ]}
                        enableLabel={true}
                        label={({ value }) => value.toLocaleString()}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}>
                        <ResponsiveBar data={totalVehicleDataGraph}></ResponsiveBar>
                    </ResponsiveBar>
                </GraphCard>
            )}
        </GraphContainerForBar>
    );
}

export default TotalVehiclesGraph;
*/
