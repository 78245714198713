export default function customersCB(
	initialState = {
		customers: [],
		loading: true,
		pageNumber: 1,
		count: 0,
		activeIndex: -1,

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
	},
	action
) {
	switch (action.type) {
		case 'FEED_CB_CUSTOMERS':
			return {
				...initialState,
				customers: action.payload,
				loading: false,
			};

		case 'CB_CUSTOMERS_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'CB_CUSTOMERS_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'CB_CUSTOMERS_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'CB_CUSTOMERS_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'CB_CUSTOMERS_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'CB_CUSTOMERS_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'CB_CUSTOMERS_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_CB_CUSTOMERS_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_CB_CUSTOMERS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_CB_CUSTOMERS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'CB_CUSTOMERS_UNMOUNT':
			return {
				...initialState,
				customers: [],
				loading: true,
				pageNumber: 1,
				count: 0,
				activeIndex: -1,

				// Export
				openExportModal: false,
				exportData: [],
				exportingData: false,
			};

		default:
			return initialState;
	}
}
