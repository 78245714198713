import { getFormattedDate } from '../utilities/date';

export function createCustomersMetricsDTO(response) {
	let metrics = [];

	if (response && response.length) {
		response.forEach((metric) => {
			metrics.push({
				id: metric?.id ? metric.id : null,
				date: metric?.date ? getFormattedDate(metric.date, 'MMM YYYY') : null,
				clv: metric?.clv ? parseFloat(metric.clv) : 0.0,
				arpa: metric?.arpa ? parseFloat(metric.arpa) : 0.0,
				mrr: metric?.mrr ? parseFloat(metric.mrr) : 0.0,
				churnedMrr: metric?.churnedMrr ? parseFloat(metric.churnedMrr) : 0.0,
				expansionMrr: metric?.expansionMrr ? parseFloat(metric.expansionMrr) : 0.0,
				netMrr: metric?.netMrr ? parseFloat(metric.netMrr) : 0.0,
				organisation: {
					id: metric?.organisation?.id ? metric.organisation.id : null,
					name: metric?.organisation?.name ? metric?.organisation?.name : null,
				},
				churnedVehicles: metric?.churnedVehicles ? metric.churnedVehicles : 0,
				expansionVehicles: metric?.expansionVehicles ? metric.expansionVehicles : 0,
				totalVehicles: metric?.totalVehicles ? metric.totalVehicles : 10,
				netPromoterScore: metric?.netPromoterScore ? metric.netPromoterScore : 0,
			});
		});
	}

	return metrics;
}
