export default function subscriptionsCB(
	initialState = {
		subscriptions: [],
		loading: true,
		pageNumber: 1,
		count: 0,
		activeIndex: -1,

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
	},
	action
) {
	switch (action.type) {
		case 'FEED_CB_SUBSCRIPTIONS':
			return {
				...initialState,
				subscriptions: action.payload,
				loading: false,
			};

		case 'CB_SUBSCRIPTIONS_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'CB_SUBSCRIPTIONS_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'CB_SUBSCRIPTIONS_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'CB_SUBSCRIPTIONS_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'CB_SUBSCRIPTIONS_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'CB_SUBSCRIPTIONS_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'CB_SUBSCRIPTIONS_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_CB_SUBSCRIPTIONS_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_CB_SUBSCRIPTIONS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_CB_SUBSCRIPTIONS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'CB_SUBSCRIPTIONS_UNMOUNT':
			return {
				...initialState,
				subscriptions: [],
				loading: true,
				pageNumber: 1,
				count: 0,
				activeIndex: -1,

				// Export
				openExportModal: false,
				exportData: [],
				exportingData: false,
			};

		default:
			return initialState;
	}
}
