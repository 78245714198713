export default function users(
	initialState = {
		users: [],
		loading: true,
		bulkimportLoading: false,
		activeIndex: -1,
		pageNumber: 1,
		count: 0,
		deleting: false,
		deleteModalOpen: false,
		activeSort: '',
		sortOrder: '',
		adding: false,
		addModalOpen: false,
		updating: false,
		editMode: false,
		passwordModalOpen: false,
		vehicleGroupModal: false,
		vehicleModal: false,
		removeVehicleModal: false,
		submiting: false,
		passwordVisible: false,
		restoreModal: false,
		importModalOpen: false,

		restoring: false,
		openSendVerificationModal: false,
		sending: false,
		vehicleMappingModal: false,
		mapping: false,
		vehicleUnmappingModal: false,
		unmapping: false,
		filterModalOpen: false,

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
	},
	action
) {
	switch (action.type) {
		case 'FEED_USERS':
			return {
				...initialState,
				users: action.payload,
				loading: false,
			};

		case 'USERS_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'USERS_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'USERS_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'USERS_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'USERS_DELETING':
			return {
				...initialState,
				deleting: true,
			};

		case 'USERS_DELETED':
			return {
				...initialState,
				deleting: false,
			};

		case 'USERS_DELETE_MODAL_OPEN':
			return {
				...initialState,
				deleteModalOpen: true,
			};

		case 'USERS_DELETE_MODAL_CLOSE':
			return {
				...initialState,
				deleteModalOpen: false,
			};

		case 'USERS_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'USERS_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'USERS_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'USERS_ADDING':
			return {
				...initialState,
				adding: true,
			};

		case 'USERS_ADDED':
			return {
				...initialState,
				adding: false,
			};

		case 'USERS_IMPORT_MODAL_OPEN':
			return {
				...initialState,
				importModalOpen: true,
			};

		case 'USERS_IMPORT_MODAL_CLOSE':
			return {
				...initialState,
				importModalOpen: false,
			};

		case 'USERS_ADD_MODAL_OPEN':
			return {
				...initialState,
				addModalOpen: true,
			};

		case 'USERS_ADD_MODAL_CLOSE':
			return {
				...initialState,
				addModalOpen: false,
			};

		case 'USERS_UPDATING':
			return {
				...initialState,
				updating: true,
			};

		case 'USERS_UPDATED':
			return {
				...initialState,
				updating: false,
			};

		case 'USERS_EDIT_MODAL_OPEN':
			return {
				...initialState,
				editMode: true,
			};

		case 'USERS_EDIT_MODAL_CLOSE':
			return {
				...initialState,
				editMode: false,
			};

		case 'USERS_PASSWORD_SUBMITING':
			return {
				...initialState,
				submiting: true,
			};

		case 'USERS_PASSWORD_SUBMITED':
			return {
				...initialState,
				submiting: false,
			};

		case 'USERS_PASSWORD_MODAL_OPEN':
			return {
				...initialState,
				passwordModalOpen: true,
			};

		case 'USERS_PASSWORD_MODAL_CLOSE':
			return {
				...initialState,
				passwordModalOpen: false,
			};

		case 'USERS_PASSWORD_VISIBLE':
			return {
				...initialState,
				passwordVisible: true,
			};

		case 'USERS_PASSWORD_NOT_VISIBLE':
			return {
				...initialState,
				passwordVisible: false,
			};

		case 'USERS_RESTORE_MODAL_OPEN':
			return {
				...initialState,
				restoreModal: true,
			};

		case 'USERS_RESTORE_MODAL_CLOSE':
			return {
				...initialState,
				restoreModal: false,
			};

		case 'USERS_VG_MODAL_OPEN':
			return {
				...initialState,
				vehicleGroupModal: true,
			};

		case 'USERS_VG_MODAL_CLOSE':
			return {
				...initialState,
				vehicleGroupModal: false,
			};

		case 'USERS_VEHICLE_MODAL_OPEN':
			return {
				...initialState,
				vehicleModal: true,
			};

		case 'USERS_VEHICLE_MODAL_CLOSE':
			return {
				...initialState,
				vehicleModal: false,
			};

		case 'USERS_REMOVE_VEHICLE_MODAL_OPEN':
			return {
				...initialState,
				removeVehicleModal: true,
			};

		case 'USERS_REMOVE_VEHICLE_MODAL_CLOSE':
			return {
				...initialState,
				removeVehicleModal: false,
			};

		case 'USERS_RESTORING':
			return {
				...initialState,
				restoring: true,
			};

		case 'USERS_RESTORED':
			return {
				...initialState,
				restoring: false,
			};

		case 'USERS_FILTER_MODAL_OPEN':
			return {
				...initialState,
				filterModalOpen: true,
			};

		case 'USERS_FILTER_MODAL_CLOSE':
			return {
				...initialState,
				filterModalOpen: false,
			};

		case 'USERS_SEND_VERIFICATION_MODAL_OPEN':
			return {
				...initialState,
				openSendVerificationModal: true,
			};

		case 'USERS_SEND_VERIFICATION_MODAL_CLOSE':
			return {
				...initialState,
				openSendVerificationModal: false,
			};

		case 'USERS_VERIFICATION_LINK_SENDING':
			return {
				...initialState,
				sending: true,
			};

		case 'USERS_VERIFICATION_LINK_SENDED':
			return {
				...initialState,
				sending: false,
			};

		case 'USERS_VEHICLE_MAPPING_MODAL_OPEN':
			return {
				...initialState,
				vehicleMappingModal: true,
			};

		case 'USERS_VEHICLE_MAPPING_MODAL_CLOSE':
			return {
				...initialState,
				vehicleMappingModal: false,
			};

		case 'USERS_MAPPING':
			return {
				...initialState,
				mapping: true,
			};

		case 'USERS_MAPPED':
			return {
				...initialState,
				mapping: false,
			};

		case 'USERS_VEHICLE_UNMAPPING_MODAL_OPEN':
			return {
				...initialState,
				vehicleUnmappingModal: true,
			};

		case 'USERS_VEHICLE_UNMAPPING_MODAL_CLOSE':
			return {
				...initialState,
				vehicleUnmappingModal: false,
			};

		case 'USERS_UNMAPPING':
			return {
				...initialState,
				unmapping: true,
			};

		case 'USERS_UNMAPPED':
			return {
				...initialState,
				unmapping: false,
			};

		case 'USERS_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'USERS_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_USERS_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_USERS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_USERS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'USERS_UNMOUNT':
			return {
				...initialState,
				users: [],
				activeSort: '',
				sortOrder: '',
				activeIndex: -1,
				pageNumber: 1,
			};
		case 'BULK_IMPORT_LOAD':
			return {
				...initialState,
				bulkimportLoading: action.payload,
			};
		default:
			return initialState;
	}
}
