export function createDeviceCommandsDTO(data) {
	if (data && data.length) {
		return data.map((command) => {
			return {
				id: command?.id ? command.id : null,
				asciiCommand: command?.asciiCommand ? command?.asciiCommand : null,
				imei: command?.imei ? command?.imei : null,
				modifiedDate: command?.modifiedDate ? command?.modifiedDate : null,
				protocol: command?.protocol ? command.protocol : null,
				status: command?.status ? command.status : null,
				createdBy: {
					name: command?.createdBy?.name ? command.createdBy.name : null,
					id: command?.createdBy?.id ? command.createdBy.id : null,
				},
				createdDate: command?.createdDate ? command.createdDate : null,
				deletedDate: command?.deletedDate ? command.deletedDate : null,
			};
		});
	} else return [];
}
