// Base URL
// eslint-disable-next-line no-undef
const BASE_URL = process.env.REACT_APP_BASE_URL;

// People Base URL
// eslint-disable-next-line no-undef
const PEOPLE_BASE_URL = process.env.REACT_APP_PEOPLE_BASE_URL;

export const LOGIN_API = BASE_URL + 'v1/auth/login';
export const GET_OTP_API = BASE_URL + 'v1/auth/login/otp';
export const VERIFY_OTP_API = BASE_URL + 'v1/auth/login/otp';
export const LOGOUT_API = BASE_URL + 'v1/auth/logout';

// Operations APIs Begin

export const DEVICES_API = BASE_URL + 'v1/devices';
export const DEVICES_API_BULK = BASE_URL + 'v1/devices/import';
export const USERS_API_BULK = BASE_URL + 'v1/users/import';

export const SIMS_API_BULK = BASE_URL + 'v1/sims/import';
export const STUDENTS_API_BULK = BASE_URL + 'v1/students/import';

export const DEVICE_MODELS_API = BASE_URL + 'v1/device-models';
export const PROTOCOLS_API = BASE_URL + 'v1/device-models/ports';
export const SIMS_API = BASE_URL + 'v1/sims';
export const SIM_PLANS_API = BASE_URL + 'v1/sim-plans';
export const VEHICLES_API = BASE_URL + 'v1/vehicles';
export const VEHICLES_API_BULK = BASE_URL + 'v1/vehicles/bulk';
export const VEHICLES_DASHBOARD_API = BASE_URL + 'v1/dashboard/vehicles';
export const ORGANISATIONS_DASHBOARD_API = BASE_URL + 'v1/dashboard/organisations';

export const ORGANISATIONS_PIECHART_API = BASE_URL + 'v1/vehicles/tree';
export const GEOFENCES_API = BASE_URL + 'v1/geofences';
export const VEHICLE_GROUPS_API = BASE_URL + 'v1/vehicle-groups';
export const ORGANISATIONS_API = BASE_URL + 'v1/organisations';
export const USERS_API = BASE_URL + 'v1/users';
export const EXPORT_API = BASE_URL + 'v1/export';
export const STUDENTS_API = BASE_URL + 'v1/students';
export const RESET_PASSWORD_API = BASE_URL + 'v1/users';
export const SMS_REPORT_API = BASE_URL + 'v1/sms-report';
export const LOGS_API = BASE_URL + 'v1/vehicles';

export const FUEL_LOGS_API = BASE_URL + 'v1/fuel-log';
export const OWNER_HISTORY_API = BASE_URL + 'v1/owner-history';
export const CHANGE_HISTORY_API = BASE_URL + 'v1/vehicle-change-history';
export const RECALCULATE_API = BASE_URL + 'v1/vehicles/recalculate-data';
// export const NEW_RECALCULATE_API = BASE_URL + 'v2/vehicles/recalculate-data';
export const SIM_BILLS_API = BASE_URL + 'v1/sim-bills';
export const DOCUMENT_TYPES_API = BASE_URL + 'v1/document-types';
export const DEVICE_COMMANDS_API = BASE_URL + 'v1/device-commands';
export const COMMANDS_PIPELINE_API = BASE_URL + 'v1/commands-pipeline';
export const SAAS_PLANS_API = BASE_URL + 'v1/saas-plans';
export const VERIFIED_ASSETS_API = BASE_URL + 'v1/verified-assets';
export const ALERTS_API = BASE_URL + 'v1/alerts';
export const EVENTS_API = BASE_URL + 'v1/events';
export const CHANGE_PASSWORD_API = BASE_URL + 'v1/users';
export const DOCUMENTS_API = BASE_URL + 'v1/documents';

// Operations APIs End

// UMS APIs Begin

export const ACCESS_MODULES_API = BASE_URL + 'v1/access-modules';
export const ROLES_API = BASE_URL + 'v1/roles';
export const AUDIT_LOG_API = BASE_URL + 'v1/audit';

// UMS APIs End

// Inventory APIs Begin

export const ITEMS_API = BASE_URL + 'v1/qb-items';
export const SITES_API = BASE_URL + 'v1/pobs';

// Inventory APIs End

// Accounts APIs Begin

export const SUBSCRIPTION_API = BASE_URL + 'v1/subscriptions';
export const INVOICE_API = BASE_URL + 'v1/invoices';
export const CUSTOMERS_API = BASE_URL + 'v1/qb-customers';
export const ESTIMATES_API = BASE_URL + 'v1/qb-estimates';
export const INVOICES_API = BASE_URL + 'v1/qb-invoices';

// Inventory APIs End

// Quickbooks Auth
export const QUICKBOOKS_AUTH_API = BASE_URL + 'v1/qb-auth';

// Accounts APIs Start

export const CHARGE_BEE_CUSTOMERS_API = BASE_URL + 'v1/cb-interface/customers';
export const CHARGE_BEE_SUBSCRIPTIONS_API = BASE_URL + 'v1/cb-interface/subscriptions';
export const CHARGE_BEE_PLANS_API = BASE_URL + 'v1/cb-interface/plans';

// Accounts APIs Ends

// Metrics APIs Start

export const SAAS_CUSTOMERS_METRICS_API = BASE_URL + 'v1/saas-customer-metrics';
export const SAAS_COMPANY_METRICS_API = BASE_URL + 'v1/saas-company-metrics';

// Metrics APIs Ends

// People APIs Start

export const PEOPLE_AUTH_API = PEOPLE_BASE_URL + 'v1/auth';
export const PEOPLE_USERS_API = PEOPLE_BASE_URL + 'v1/users';
export const PEOPLE_ORGANISATIONS_API = PEOPLE_BASE_URL + 'v1/organisations';
export const PEOPLE_GEOFENCES_API = PEOPLE_BASE_URL + 'v1/geofences';

// People APIs Ends

// Diagnostic and Analysis APIs Start

export const ACCOUNT_OVERVIEW_API = BASE_URL + 'v1/account-overview';

// Diagnostic and Analysis APIs Ends

export const SUMMARY_API = BASE_URL + 'v1/summary';

// Vehicle Types API
export const VEHICLE_TYPES_API = BASE_URL + 'v1/vehicles/types';
// Vehicle Status API
export const VEHICLE_STATUS_API = BASE_URL + 'v1/vehicles/Status';
