import React from 'react';

import { Container } from './DashboardNavListItemStyles';
import { COLORS, THEME_COLOR } from '../../../../styles/colors';

import { NavLink } from 'react-router-dom';

import { Icon, Popup } from 'semantic-ui-react';

function DashboardNavListItem(props) {
	return (
		<Container>
			<Popup
				trigger={
					<NavLink
						to={props.urlPrefix || ''}
						exact={!!props.exact}
						activeStyle={{
							color: THEME_COLOR,
							backgroundColor: COLORS.activeDashboardLinkBackground,
							borderRadius: '5px',
						}}>
						<Icon name={props.iconName || 'question'} size="large" />
					</NavLink>
					/*  <NavLink
              to={this.props.urlPrefix || ''}
              exact={!!this.props.exact}
              activeStyle={{
                  color: THEME_COLOR,
                  backgroundColor: this.props.iconName ? COLORS.activeDashboardLinkBackground : this.props.mainIcon ? THEME_COLOR : '#8badd3',
                  borderRadius: '5px',
              }}>
              {!this.props.iconName ? (
              <img src={tracknerd} width='25px' height='30px'/>
              ): (
              <Icon name={this.props.iconName || 'question'} size="large"/>
              )
              }
          </NavLink>*/
				}
				basic
				content={props.popupTitle || 'No Title'}
				position="right center"
				offset={[0, 10]}
				style={{ borderRadius: '5px' }}
			/>
		</Container>
	);
}

export default DashboardNavListItem;
