import React, { useEffect, useState } from "react";
// import BasicLoader from '../../../blocks/molecules/loader/BasicLoader';
import { ResponsivePie } from "@nivo/pie";
import { GraphContainerForPie, GraphHeader } from "../pie-charts/PieChartsStyles";
import { Dropdown } from "semantic-ui-react";
import { GraphCard } from "../../../styles/Containers";
import { fetchDashboardOrganisationData } from "../../../../../logic/middleware/vehicles";
import { handleError } from "../../../../../logic/middleware/messaging";
import { NavLink } from "react-router-dom";
// import { connectToFirebase } from '../../../../../logic/middleware/firebase';

// import axios from 'axios';

function createOrganisationDTO(data) {
	const organisationData = data.organisations;
	const vehicleList = [];
	const selectedOrganisations = [];
	let vehicleObjects = {};
	if (organisationData.length) {
		organisationData.forEach((org) => {
			if (org.vehicleGroups.length !== 0) {
				const selectedGroups = [];
				org.vehicleGroups.forEach((group) => {
					const vehiclesInGroup = [];
					if (group.vehicles.length !== 0) {
						group.vehicles.forEach((vehicle) => {
							vehiclesInGroup.push(vehicle);
							if (!Object.hasOwn(vehicleObjects, vehicle.id)) {
								vehicleObjects = { ...vehicleObjects, [vehicle.id]: false };
								vehicleList.push(vehicle);
							}
						});
						if (vehiclesInGroup.length) {
							selectedGroups.push({
								id: group.id,
								name: group.name,
								vehicles: vehiclesInGroup.length,
							});
						}
					}
				});
				if (selectedGroups.length) {
					selectedOrganisations.push({
						id: org.id,
						name: org.name,
						vehicleGroups: selectedGroups,
					});
				}
			}
		});

		return selectedOrganisations;
	}
}

export function createVehicleGroupsDTONew(data) {
	const groupData = data.vehicleGroups;
	const newGroupData = [];
	let vehicleList = [];
	let vehicleObjects = {};

	if (groupData.length) {
		groupData.forEach((group) => {
			const vehiclesInGroup = [];
			if (group.vehicles.length) {
				group.vehicles.forEach((vehicle) => {
					vehiclesInGroup.push(vehicle);
					if (!Object.hasOwn(vehicleObjects, vehicle.id)) {
						vehicleObjects = { ...vehicleObjects, [vehicle.id]: false };
						vehicleList.push(vehicle);
					}
				});
				newGroupData.push({
					id: group.id,
					name: group.name,
					vehicles: vehiclesInGroup.length,
				});
			}
		});
	}
	return newGroupData;
}

const TotalVehicleGroupGraph = () => {
	const [organisationData, setOrganisationData] = useState([]);
	const [initOrgData, setInitOrgData] = useState([]);
	const [organisationOptions, setOrganisationOption] = useState([]);
	const [organisationName, setOrganisationName] = useState('Organisations');
	const [organisation, setOrganisation] = useState('');
	const [rawOrgData, setRawOrgData] = useState([]);
	const [organisationId, setOrganisationId] = useState(null);
	const OrganisationName = localStorage.getItem('organisationName');

	const navLinkRef = React.createRef();
	useEffect(() => {
		if (navLinkRef.current && organisationId !== null) {
			navLinkRef.current.click();
		}

		fetchDashboardOrganisationData()
			.then((response) => {
				if (localStorage.getItem('isSuperAdmin') === 'true') {
					let organisations = createOrganisationDTO(response.data);
					formatOrganisationData(organisations);
				} else {
					let formattedVehicleGroupData = [];
					let vehicleGroup = createVehicleGroupsDTONew(response.data);
					vehicleGroup?.forEach((group) => {
						formattedVehicleGroupData.push({
							id: group?.name,
							label: group?.name,
							value: group?.vehicles,
						});
					});
					setOrganisationData(formattedVehicleGroupData);
				}
			})
			.catch((error) => handleError(error));
	}, [organisationId]);

	const formatOrganisationData = (organisations) => {
		let orgData = [];
		let options = [];
		setRawOrgData(organisations);
		organisations?.forEach((org) => {
			orgData?.push({
				id: org?.name,
				label: org?.name,
				value: org?.vehicleGroups?.length,
				orgId: org?.id,
			});
			options.push({
				key: org?.id,
				text: org?.name,
				value: org?.name,
			});
		});
		setOrganisationData(orgData);
		setInitOrgData(orgData);
		setOrganisationOption(options);
	};

	const selectedVehicleGroup = (value) => {
		setOrganisation(value);
		setOrganisationName(value);
		let selectedOrganisation = [];
		const selectedOrg = rawOrgData.filter((org) => org.name === value);
		selectedOrg.forEach((org) => {
			org.vehicleGroups.forEach((vehicleGroup) => {
				selectedOrganisation.push({
					id: vehicleGroup?.name,
					label: vehicleGroup?.name,
					value: vehicleGroup?.vehicles,
				});
			});
		});
		setOrganisationData(selectedOrganisation);
	};

	return (
		<GraphContainerForPie>
			<GraphCard>
				{localStorage.getItem('isSuperAdmin') === 'true' ? (
					<GraphHeader
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							flexDirection: 'row-reverse',
						}}>
						<Dropdown
							style={{ marginRight: '.5rem' }}
							placeholder="Select Organisation"
							search
							selection
							clearable
							upward={false}
							value={organisation}
							onChange={(e, data) => {
								if (data.value === '') {
									setOrganisationData(initOrgData);
									setOrganisation('');
									setOrganisationName('Organisations');
									return;
								}
								selectedVehicleGroup(data.value);
							}}
							options={organisationOptions}
						/>
						<h1>{organisationName}</h1>
					</GraphHeader>
				) : (
					<GraphHeader>
						<h1>{OrganisationName}</h1>
					</GraphHeader>
				)}

				<ResponsivePie
					data={organisationData}
					margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
					innerRadius={0.5}
					cornerRadius={3}
					colors={['#4aa96c', '#ffcc29', '#777777', '#ff8243', '#e34234', '#000']}
					activeOuterRadiusOffset={8}
					borderWidth={1}
					borderColor={{
						from: 'color',
						modifiers: [['darker', 0.2]],
					}}
					arcLinkLabelsSkipAngle={10}
					arcLinkLabelsTextColor="#333333"
					arcLinkLabelsThickness={2}
					arcLinkLabelsColor={{ from: 'color' }}
					arcLabelsSkipAngle={10}
					arcLabelsTextColor={{
						from: 'color',
						modifiers: [['darker', 2]],
					}}
					onClick={(e) => {
						if (e.data.orgId) {
							setOrganisationId(e.data.orgId);
						}
					}}
				/>
			</GraphCard>
			<NavLink
				ref={navLinkRef}
				to={{
					pathname: '/operations/vehicles',
					state: { orgId: organisationId },
				}}
			/>
		</GraphContainerForPie>
	);
};

export default TotalVehicleGroupGraph;
