import React from 'react';
import CommonModal from '../../../molecules/modal/CommonModal';

import { Button } from 'semantic-ui-react';

const DeleteModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="mini"
			header="Delete Student"
			content={<p>Do you want to delete student ?</p>}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button negative loading={props.deleteButtonLoading} onClick={props.onDeleteButtonClick}>
						Delete
					</Button>
				</>
			}
		/>
	);
};

export default DeleteModal;
