import { GET_OTP_API, LOGIN_API, LOGOUT_API, VERIFY_OTP_API } from '../configs/api';

import Store from '../../data/store/store';

import { handleError, handleWarning, showMessage } from './messaging';

// import axios from 'axios';
import { MessageType } from '../../data/constants/message-type';
import { trackMixpanelEvent } from './mixpanel';
import { getData, postData } from './services/apiRequest';

export function login(username, password, cb) {
	startLoading();

	// axios
	// 	.post(
	// 		LOGIN_API,
	// 		{
	// 			username: username,
	// 			password: password,
	// 		},
	// 		{
	// 			headers: {
	// 				'Content-Type': 'application/json',
	// 			},
	// 		}
	// 	)
	postData(LOGIN_API, '', {
		username: username,
		password: password,
	})
		.then((response) => {
			if (response?.data?.user?.id && response?.data?.token) {
				trackMixpanelEvent('Sign in', { userLogin: 'User Login' });
				setUserDetails(response);
				cb(true);
				Store.dispatch({
					type: 'LOGIN',
				});
			} else {
				handleWarning('Login failed.');
				cb(false);
			}
			finishLoading();
		})
		.catch((error) => {
			finishLoading();
			handleError(error);
			cb(false);
		});
}

function setUserDetails(response) {
	const { id, name, phone, email, organisation, role } = response.data.user;

	localStorage.setItem('userId', id);
	localStorage.setItem('organisation', organisation.id);
	localStorage.setItem('organisationName', organisation.name);
	localStorage.setItem('data', `${name} (${phone})`);
	localStorage.setItem('name', name);
	localStorage.setItem('phone', phone);
	localStorage.setItem('email', email);
	localStorage.setItem('token', response.data.token);
	localStorage.setItem('roleName', role.name);
	localStorage.setItem('isSuperAdmin', role.isSuperAdmin);
	localStorage.setItem('isEnabledEmail', response.data.user.isEnabledEmail);
	localStorage.setItem('isEnabledPush', response.data.user.isEnabledPush);
	localStorage.setItem('isEnabledSMS', response.data.user.isEnabledSMS);
	localStorage.setItem('phoneVerified', response.data.user.phoneVerified);
	localStorage.setItem('emailVerified', response.data.user.emailVerified);
	localStorage.setItem('permissions', JSON.stringify(role.permissions));
}

function startLoading() {
	Store.dispatch({
		type: 'LOADING',
	});
}

function finishLoading() {
	Store.dispatch({
		type: 'LOADED',
	});
}

export async function getOTP(phone) {
	Store.dispatch({ type: 'AUTH_SEND_OTP_REQUEST' });

	// axios(GET_OTP_API + `/${phone}`, { headers: { 'Content-Type': 'application/json' } })
	getData(GET_OTP_API + `/${phone}`)
		.then(() => {
			Store.dispatch({ type: 'AUTH_SEND_OTP_RESOLVE' });
			showMessage('OTP sent.', MessageType.Success);
		})
		.catch((error) => {
			Store.dispatch({ type: 'AUTH_SEND_OTP_REJECT' });
			handleError(error);
		});
}

export async function verifyOTP(phone, otp, cb) {
	Store.dispatch({ type: 'AUTH_VERIFY_OTP_REQUEST' });

	// axios
	// 	.post(
	// 		VERIFY_OTP_API + `/${phone}`,
	// 		{
	// 			otp: parseInt(otp),
	// 		},
	// 		{ headers: { 'Content-Type': 'application/json' } }
	// 	)
	postData(VERIFY_OTP_API, `/${phone}`, {
		otp: parseInt(otp),
	})
		.then((response) => {
			if (response?.data?.user?.id && response?.data?.token) {
				setUserDetails(response);
				cb(true);
				Store.dispatch({
					type: 'LOGIN',
				});
				Store.dispatch({ type: 'AUTH_VERIFY_OTP_RESOLVE' });
			} else {
				Store.dispatch({ type: 'AUTH_VERIFY_OTP_REJECT' });
				handleWarning('Login failed.');
				cb(false);
			}
			finishLoading();
		})
		.catch((error) => {
			finishLoading();
			Store.dispatch({ type: 'AUTH_VERIFY_OTP_REJECT' });
			handleError(error);
			cb(false);
		});
}

export function handleUserSession() {
	if (localStorage.getItem('token')) {
		Store.dispatch({
			type: 'LOGIN',
		});
	}
}

export function logout() {
	sendLogoutRequest();
	localStorage.clear();
	window.location.href = '/';
	Store.dispatch({
		type: 'LOGOUT',
	});
}

function sendLogoutRequest() {
	if (localStorage.getItem('token')) {
		// axios(LOGOUT_API, {
		// 	headers: {
		// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 	},
		// })
		getData(LOGOUT_API)
			// .then((response) => console.log(response))
			.catch((error) => handleError(error));
	}
}
