export function createCommandsPipelineDTO(data) {
	if (data && data.length) {
		return data.map((command) => {
			return {
				id: command?.id ? command.id : null,
				device: { imei: command?.device.imei ? command.device.imei : null },
				deviceCommand: {
					name: command?.deviceCommand?.name ? command.deviceCommand.name : null,
					commandAscii: command?.deviceCommand?.commandAscii ? command.deviceCommand.commandAscii : null,
					commandHex: command?.deviceCommand?.commandHex ? command.deviceCommand.commandHex : null,
				},
				status: command?.status ? command.status : null,
				messageType: command?.messageType ? command.messageType : null,
				requestAscii: command?.requestAscii ? command.requestAscii : null,
				responseAscii: command?.responseAscii ? command.responseAscii : null,
				requestHex: command?.requestHex ? command.requestHex : null,
				responseHex: command?.responseHex ? command.responseHex : null,
				requestTimestamp: command?.requestTimestamp ? command.requestTimestamp : null,
				responseTimestamp: command?.responseTimestamp ? command.responseTimestamp : null,
				source: command?.source ? command.source : null,
				protocol: command?.protocol ? command.protocol : null,
			};
		});
	} else return [];
}
