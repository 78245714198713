import React from 'react';

import { Icon, Input } from 'semantic-ui-react';

function SearchBox(props) {
	let searchRef = React.createRef();
	return (
		<Input
			ref={(ref) => (searchRef = ref)}
			style={{ marginRight: '0.25em', width: '15em' }}
			disabled={props.disabled || false}
			{...(props.searchQuery && {
				icon: (
					<Icon
						name="times circle outline"
						link
						onClick={() => {
							props.clearSearchIconClick();
							if (searchRef?.inputRef?.current?.value) {
								searchRef.inputRef.current.value = '';
							}
						}}
					/>
				),
			})}
			value={props.value}
			placeholder={props.placeholder || 'SearchBox...'}
			onChange={(e) => {
				props.handleSearch(e);
				if (props.handleSearchQuery) props.handleSearchQuery(e.target.value);
			}}
		/>
	);
}

export default SearchBox;
