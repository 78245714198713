import { SIDEBAR_LIST_ITEMS_HEIGHT } from '../../../../../data/constants/dimensions';

import styled from 'styled-components';

export const Container = styled.section`
	width: 100%;

	overflow-x: hidden;

	background: ${(props) => props.background};

	a {
		width: 100%;

		display: flex;
		align-items: center;
		padding-left: ${(props) => (props.expanded ? (props.accordionChild ? '1em' : '0') : '0')};

		color: ${(props) => props.theme.defaultLink};

		&:hover {
			color: ${(props) => props.theme.sidebarIcon};
		}
	}

	span {
		white-space: nowrap;
	}

	.ui.label {
		color: #fff;
		background: ${(props) => props.labelColor};
	}
`;

export const IconContainer = styled.section`
	width: 4em;
	min-width: 4em;
	height: ${SIDEBAR_LIST_ITEMS_HEIGHT};

	display: flex;
	align-items: center;
	justify-content: center;

	i.icon,
	i.icons {
		margin: 0 !important;
	}
`;
