import {
	EXPORT_API,
	GEOFENCES_API,
	ORGANISATIONS_PIECHART_API,
	USERS_API,
	VEHICLE_STATUS_API,
	VEHICLE_TYPES_API,
	VEHICLES_API,
	VEHICLES_DASHBOARD_API,
} from '../configs/api';

import { VEHICLES_FILE_HEADER } from '../../data/constants/general';

import { createVehicleDTO } from '../dto/vehicles';
import { createDevicesDTO } from '../dto/devices';
import { createOrganisationDTO, createOrganisationSaasPlanDTO } from '../dto/organisations';
import { createVehicleGroupDTO } from '../dto/vehicleGroups';
import { fetchDevices } from './devices';
import { fetchVehicleGroups } from './vehicleGroups';
import { fetchOrganisations } from './organisations';
import Logo from '../../ui/assets/images/logo.png';
import { dispatch, dispatchNoPayload } from './middleware';

import Store from '../../data/store/store';
import { handleError } from './messaging';
import { exportToExcelWithLogo } from '../utilities/excel';

// import axios from 'axios';
import { jsPDF } from 'jspdf';
import { createGeoFenceDTO } from '../dto/geofences';
import fileDownload from 'js-file-download';
import { exportApi, getData } from './services/apiRequest';
import { datadogLogs } from '@datadog/browser-logs';

// import { fetchUsers } from './users';

export function fetchDashboardVehiclesData(params = '') {
	return getData(VEHICLES_DASHBOARD_API + params);
	// axios(VEHICLES_DASHBOARD_API + params, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// });
}

export function fetchDashboardOrganisationData() {
	return getData(ORGANISATIONS_PIECHART_API);
	// axios(ORGANISATIONS_PIECHART_API, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// });
}

export function fetchVehicles(params = '') {
	return getData(VEHICLES_API, params);
	// axios(VEHICLES_API + params, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// });
}

export function refreshVehicles(params = '', activePage = 1, resetOffset = false) {
	dispatchNoPayload('VEHICLES_LOADING');
	// axios()
	// 	VEHICLES_API +
	// 		'?pagecount=' +
	// 		getPageNumberFromStore().dataSizeLimit +
	// 		'&pagenumber=' +
	// 		(resetOffset ? 1 : activePage) +
	// 		params,
	//
	// 	{
	// 		headers: {
	// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 			'Content-Type': 'application/json',
	// 		},
	// 	}
	// )
	getData(
		VEHICLES_API,
		'?pagecount=' + getPageNumberFromStore().dataSizeLimit + '&pagenumber=' + (resetOffset ? 1 : activePage) + params
	)
		.then((response) => {
			let vehicles = createVehicleDTO(response?.data?.data.slice(0));
			dispatch('FEED_VEHICLES', vehicles);
			dispatch('VEHICLES_SET_PAGE_COUNT', response?.data?.count || 0);
			dispatch('VEHICLES_SET_PAGE_NUMBER', activePage);
			// connectToFirebase(vehicles);
		})
		.catch((error) => {
			dispatchNoPayload('VEHICLES_LOADED');
			datadogLogs.logger.error('Refresh vehicle api', {}, error);
			handleError(error);
		});
}

export function getDriversForDropdown(params) {
	// axios(USERS_API + params, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// });
	return getData(USERS_API, params);
}

export function searchVehicles(query) {
	return `&search=${encodeURIComponent(query)
		.trim()
		.split('+')
		.map((searchTerm) => `${searchTerm}`)}`;
}

// Export
export function openExportModal() {
	dispatchNoPayload('VEHICLES_EXPORT_MODAL_OPEN');
}

export function closeExportModal() {
	dispatchNoPayload('VEHICLES_EXPORT_MODAL_CLOSE');
}

export function formatExcelExport(response, lastLocation) {
	return response.map((vehicle) => {
		return {
			Registration: vehicle.registrationNumber,
			Organisation: vehicle.organisation,
			Device: vehicle.imei,
			Immobilizer: vehicle.immobilizer,
			Fuel: vehicle.fuelDataSource,
			InstallationDate: vehicle.installationDate,
			Status: vehicle.status,
			'Groups Count': vehicle.vehicleGroups.length,
			'Geofence Count': vehicle.geofences.length,
			'Last Data': lastLocation.hasOwn(`${vehicle.id}`) ? lastLocation[vehicle.id] : '',
		};
	});
}

export function exportData(data, fileName, extension, lastLocation) {
	if (extension === 'excel') {
		exportToExcelWithLogo(
			formatExcelExport(data, lastLocation),
			`${!fileName ? 'Vehicles' : fileName}`,
			VEHICLES_FILE_HEADER
		);
	} else {
		convertToPdf(formatExcelExport(data, lastLocation), fileName);
	}

	closeExportModal();
}

export function convertToPdf(data, fileName) {
	const doc = new jsPDF({ orientation: 'l', unit: 'mm', format: 'a4' });

	let col = [
		'Registration',
		'Organisation',
		'Device',
		'Immobilizer',
		'Fuel',
		'InstallationDate',
		'Status',
		'Groups Count',
		'Geofence Count',
		'Last Data',
	];
	let rows = [];

	data.forEach((vehicle) => {
		let temp = [];
		Object.keys(vehicle).forEach((key, index) => {
			if (col[index] === key) temp[index] = vehicle[key];
		});

		rows.push(temp);
	});

	let header = function (data) {
		doc.addImage(Logo, 'png', data.settings.margin.left, 10, 40, 14);
	};

	doc.autoTable(col, rows, {
		margin: { top: 25 },
		beforePageContent: header,
		theme: 'grid',
	});

	doc.save(`${!fileName ? 'Vehicles' : fileName}`);
}

export function exportAll(param) {
	// dispatchNoPayload('EXPORTING_VEHICLES_EXPORT_DATA');
	// axios(EXPORT_API + '/vehicles' + param, {
	// 	responseType: 'arraybuffer',
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 	},
	// })
	exportApi(EXPORT_API + '/vehicles', param)
		.then((response) => {
			// openExportModal();
			// dispatch('SET_VEHICLES_EXPORT_DATA', createVehicleDTO(response?.data?.data));
			// dispatchNoPayload('EXPORTED_VEHICLES_EXPORT_DATA');
			// let extension = response.headers['content-type'].split('/').at(-1);
			// fileDownload(response?.data, `Vehicles.${fileExtension}`);

			const contentDisposition = response.headers['content-disposition'];
			const matches = /filename="([^"]+)"/.exec(contentDisposition);

			if (matches) {
				const fileName = matches[1];
				fileDownload(response?.data, fileName);
			}
		})
		.catch((error) => {
			handleError(error);
			datadogLogs.logger.error('Vehicle export api', {}, error);
			dispatchNoPayload('EXPORTED_VEHICLES_EXPORT_DATA');
		});
}

export function openFilterModal() {
	dispatchNoPayload('VEHICLES_FILTER_MODAL_OPEN');
}

export function closeFilterModal() {
	dispatchNoPayload('VEHICLES_FILTER_MODAL_CLOSE');
}

// Accordion
export function toggleAccordion(e, titleProps, current) {
	const { index } = titleProps;
	const { activeIndex } = {
		activeIndex: current,
	};
	const newIndex = activeIndex === index ? -1 : index;
	dispatchNoPayload('VEHICLES_EDIT_MODAL_CLOSE');
	dispatch('VEHICLES_TOGGLE_ACCORDION', newIndex);
}

// Dropdown
export function getDevicesForDropdown(params = '') {
	return fetchDevices(params)
		.then((response) => {
			return createDevicesDTO(response?.data?.data).map((device, index) => {
				return {
					key: index,
					value: device.imei,
					text: device.imei,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			datadogLogs.logger.error('Device dropdown api', {}, error);
			return [];
		});
}

export function getVehicleTypesForDropdown() {
	return getData(VEHICLE_TYPES_API)
		.then((response) => {
			const vehicleTypesObj = response?.data;
			if (!vehicleTypesObj) return [];

			return Object.keys(vehicleTypesObj).map((type, index) => {
				return {
					key: index,
					value: vehicleTypesObj[type],
					text: vehicleTypesObj[type],
				};
			});
		})
		.catch((error) => {
			datadogLogs.logger.error('Vehicle types api', {}, error);
			handleError(error);
			return [];
		});
}

export function getVehicleStatusForDropdown() {
	const isSuperAdmin = localStorage.getItem('isSuperAdmin');
	return getData(VEHICLE_STATUS_API)
		.then((response) => {
			const vehicleStatusObj = response?.data;
			if (!vehicleStatusObj) return [];
			return Object.keys(vehicleStatusObj).map((type, index) => {
				if (isSuperAdmin === 'false' && (type === 'PAUSED' || type === 'TRIAL')) {
					return {
						key: index,
						value: vehicleStatusObj[type],
						text: vehicleStatusObj[type],
						disabled: true,
					};
				} else if (isSuperAdmin === 'true' && type === 'TRIAL') {
					return {
						key: index,
						value: vehicleStatusObj[type],
						text: vehicleStatusObj[type],
						disabled: true,
					};
				} else {
					return {
						key: index,
						value: vehicleStatusObj[type],
						text: vehicleStatusObj[type],
					};
				}

				// 	if(type!=="TRIAL" && !isSuperAdmin){
				// 	return {
				// 		key: index,
				// 		value: vehicleStatusObj[type],
				// 		text: vehicleStatusObj[type],
				// 	};
				// }else{
				// 	return {
				// 		key: index,
				// 		value: vehicleStatusObj[type],
				// 		text: vehicleStatusObj[type],
				// 		disabled: true
				// 	};
				// }
			});
		})
		.catch((error) => {
			datadogLogs.logger.error('Vehicle status api', {}, error);
			handleError(error);
			return [];
		});
}

export function getOrganisationsForDropdown(params = '') {
	return fetchOrganisations(params)
		.then((response) => {
			return createOrganisationDTO(response?.data?.data).map((organisation, index) => {
				return {
					key: index,
					value: organisation.id,
					text: organisation.name,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			return [];
		});
}

export function getVehicleGroupsForDropdown(params) {
	return fetchVehicleGroups(params)
		.then((response) => {
			return createVehicleGroupDTO(response?.data?.data).map((vehicleGroup, index) => {
				return {
					key: index,
					value: vehicleGroup.id,
					text: vehicleGroup.name,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			datadogLogs.logger.error('VehicleGroup dropdown api', {}, error);
			return [];
		});
}

export function getGeofenceForDropdown(params) {
	// axios(GEOFENCES_API + params, {
	// 	headers: {
	// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
	// 		'Content-Type': 'application/json',
	// 	},
	// })
	return getData(GEOFENCES_API, params)
		.then((response) => {
			return createGeoFenceDTO(response?.data?.data).map((geofence, index) => {
				return {
					key: index,
					value: geofence.id,
					text: geofence.name,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			datadogLogs.logger.error('Geofence api error', {}, error);
			return [];
		});
}

export function getVehicleForDropdown(params) {
	return fetchVehicles(params)
		.then((response) => {
			return createVehicleDTO(response?.data?.data).map((vehicle, index) => {
				return {
					key: index,
					value: vehicle.id,
					text: vehicle.registrationNumber,
				};
			});
		})
		.catch((error) => {
			handleError(error);
			datadogLogs.logger.error('Vehicle dropdown api', {}, error);
			return [];
		});
}

// Dropdowns
export function getSaasPlansForDropdown(params = '') {
	return fetchOrganisations(params)
		.then((response) => {
			let organisation = createOrganisationSaasPlanDTO(response?.data);
			if (organisation?.saasPlans?.length) {
				return organisation.saasPlans.map((plan, index) => {
					return {
						key: index,
						value: plan.id,
						text: plan.name,
					};
				});
			}

			return [];
		})
		.catch((error) => {
			handleError(error);
			datadogLogs.logger.error('Organisation dropdown api', {}, error);
			return [];
		});
}

// Connect to Store
function getPageNumberFromStore() {
	return {
		pageNumber: Store.getState().vehicles.pageNumber,
		dataSizeLimit: Store.getState().ui.dataSizeLimit,
	};
}
