import styled from 'styled-components';

export const GraphCardMain = styled.section`
	width: 100%;
	height: 25em;

	display: flex;
	align-items: center;
	justify-content: center;
`;
