import React from 'react';
import CommonModal from '../../../molecules/modal/CommonModal';
import { SWAP_DEVICE_REASON } from '../../../../../../data/constants/general';
import { Button, Dropdown, Form } from 'semantic-ui-react';

const SwapModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="tiny"
			header="Choose Swap Device"
			content={
				<Form>
					<Form.Group widths="equal">
						<Form.Field>
							<label>New Device</label>
							<Dropdown
								placeholder={`${props.placeHolder} (Current)`}
								fluid
								search
								selection
								options={props.devices}
								onChange={props.onDeviceChoose}
							/>
						</Form.Field>

						<Form.Field>
							<label>Reason</label>
							<Dropdown
								placeholder="Choose ..."
								fluid
								search
								selection
								options={SWAP_DEVICE_REASON}
								value={props.selectedReason}
								onChange={props.onReasonChoose}
							/>
						</Form.Field>
					</Form.Group>
				</Form>
			}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button color="orange" onClick={props.onSwapButtonClick}>
						Swap
					</Button>
				</>
			}
		/>
	);
};

export default SwapModal;
