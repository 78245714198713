export default function activityLogs(
	initialState = {
		loading: true,
		pageNumber: 1,
		count: 0,
		activeIndex: -1,
		activityLogs: [],
	},
	action
) {
	switch (action.type) {
		case 'FEED_ACTIVITY_LOGS':
			return {
				...initialState,
				activityLogs: action.payload,
				loading: false,
			};
		case 'ACTIVITY_LOGS_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'ACTIVITY_LOGS_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'ACTIVITY_LOGS_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};
		case 'ACTIVITY_LOGS_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};
		default:
			return initialState;
	}
}
