export default function vehicles(
	initialState = {
		vehicles: [],
		loading: true,
		pageNumber: 1,
		count: 0,
		activeIndex: -1,
		activeSort: '',
		sortOrder: '',
		deleting: false,
		deleteModalOpen: false,
		adding: false,
		addModalOpen: false,
		bulkUpdating: false,
		updating: false,
		editMode: false,
		vehicleGroupModal: false,
		swapModal: false,
		swapping: false,
		restoreModal: false,
		restoring: false,
		orgHistoryModal: false,
		serviceHistoryModal: false,
		editOrganisationModal: false,
		orgEditing: false,
		calibrationModal: false,
		calibrationEditModal: false,
		calibrationLoading: false,
		recalculationModal: false,
		newrecalculationModal: false,
		recalculating: false,
		filterModalOpen: false,
		commandsHistoryModal: false,
		filters: {
			organisations: [],
			email: null,
			phone: null,
			isFMSVehicle: null,
		},

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
		searchQuery: '',
		deletelogid: null,
	},
	action
) {
	switch (action.type) {
		case 'FEED_VEHICLES':
			return {
				...initialState,
				vehicles: action.payload,
				loading: false,
			};

		case 'VEHICLES_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'ACTIVE_FILTER':
			return {
				...initialState,
				filters: {
					organisation: action.payload.organisation,
					email: action.payload.email,
					phone: action.payload.phone,
					organisations: action.payload.organisations,
					isFMSVehicle: action.payload.isFMSVehicle,
				},
			};

		case 'VEHICLES_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'VEHICLES_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'VEHICLES_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'VEHICLES_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'VEHICLES_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'VEHICLES_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'VEHICLES_DELETING':
			return {
				...initialState,
				deleting: true,
			};

		case 'VEHICLES_DELETED':
			return {
				...initialState,
				deleting: false,
			};

		case 'VEHICLES_DELETE_MODAL_OPEN':
			return {
				...initialState,
				deleteModalOpen: true,
			};

		case 'VEHICLES_DELETE_MODAL_CLOSE':
			return {
				...initialState,
				deleteModalOpen: false,
			};

		case 'VEHICLES_ADDING':
			return {
				...initialState,
				adding: true,
			};

		case 'VEHICLES_ADDED':
			return {
				...initialState,
				adding: false,
			};

		case 'VEHICLES_BULK_UPDATING':
			return {
				...initialState,
				bulkUpdating: true,
			};

		case 'VEHICLES_BULK_UPDATED':
			return {
				...initialState,
				bulkUpdating: false,
			};

		case 'VEHICLES_ADD_MODAL_OPEN':
			return {
				...initialState,
				addModalOpen: true,
			};

		case 'VEHICLES_ADD_MODAL_CLOSE':
			return {
				...initialState,
				addModalOpen: false,
			};

		case 'VEHICLES_UPDATING':
			return {
				...initialState,
				updating: true,
			};

		case 'VEHICLES_UPDATED':
			return {
				...initialState,
				updating: false,
			};

		case 'VEHICLES_EDIT_MODAL_OPEN':
			return {
				...initialState,
				editMode: true,
			};

		case 'VEHICLES_EDIT_MODAL_CLOSE':
			return {
				...initialState,
				editMode: false,
			};

		case 'VEHICLES_VG_MODAL_OPEN':
			return {
				...initialState,
				vehicleGroupModal: true,
			};

		case 'VEHICLES_VG_MODAL_CLOSE':
			return {
				...initialState,
				vehicleGroupModal: false,
			};

		case 'VEHICLES_SWAPPING':
			return {
				...initialState,
				swapping: true,
			};

		case 'VEHICLES_SWAPPED':
			return {
				...initialState,
				swapping: false,
			};

		case 'VEHICLES_SWAP_MODAL_OPEN':
			return {
				...initialState,
				swapModal: true,
			};

		case 'VEHICLES_SWAP_MODAL_CLOSE':
			return {
				...initialState,
				swapModal: false,
			};

		case 'VEHICLES_RESTORE_MODAL_OPEN':
			return {
				...initialState,
				restoreModal: true,
			};

		case 'VEHICLES_RESTORE_MODAL_CLOSE':
			return {
				...initialState,
				restoreModal: false,
			};
		case 'VEHICLES_RESTORING':
			return {
				...initialState,
				restoring: true,
			};

		case 'VEHICLES_RESTORED':
			return {
				...initialState,
				restoring: false,
			};

		case 'VEHICLES_EDIT_ORGANISATION_MODAL_OPEN':
			return {
				...initialState,
				editOrganisationModal: true,
			};

		case 'VEHICLES_EDIT_ORGANISATION_MODAL_CLOSE':
			return {
				...initialState,
				editOrganisationModal: false,
			};

		case 'VEHICLES_ORGANISATION_EDITING':
			return {
				...initialState,
				orgEditing: true,
			};

		case 'VEHICLES_ORGANISATION_EDITED':
			return {
				...initialState,
				orgEditing: false,
			};

		case 'VEHICLES_ORGANISATION_HISTORY_MODAL_OPEN':
			return {
				...initialState,
				orgHistoryModal: true,
			};

		case 'VEHICLES_ORGANISATION_HISTORY_MODAL_CLOSE':
			return {
				...initialState,
				orgHistoryModal: false,
			};

		case 'VEHICLES_SERVICE_HISTORY_MODAL_OPEN':
			return {
				...initialState,
				serviceHistoryModal: true,
			};

		case 'VEHICLES_SERVICE_HISTORY_MODAL_CLOSE':
			return {
				...initialState,
				serviceHistoryModal: false,
			};

		case 'VEHICLES_CALIBRATION_MODAL_OPEN':
			return {
				...initialState,
				calibrationModal: true,
			};

		case 'VEHICLES_CALIBRATION_MODAL_CLOSE':
			return {
				...initialState,
				calibrationModal: false,
			};

		case 'VEHICLES_EDIT_CALIBRATION_MODAL_OPEN':
			return {
				...initialState,
				calibrationEditModal: true,
			};

		case 'VEHICLES_EDIT_CALIBRATION_MODAL_CLOSE':
			return {
				...initialState,
				calibrationEditModal: false,
			};

		case 'VEHICLES_CALIBRATION_LOADING':
			return {
				...initialState,
				calibrationLoading: true,
			};

		case 'VEHICLES_CALIBRATION_LOADED':
			return {
				...initialState,
				calibrationLoading: false,
			};

		case 'VEHICLES_RECALCULATION_MODAL_OPEN':
			return {
				...initialState,
				recalculationModal: true,
			};

		case 'VEHICLES_RECALCULATION_MODAL_CLOSE':
			return {
				...initialState,
				recalculationModal: false,
			};

		// case 'VEHICLES_RECALCULATION_MODAL_NEW_OPEN':
		// 	return {
		// 		...initialState,
		// 		newrecalculationModal: true,
		// 	};

		// case 'VEHICLES_RECALCULATION_MODAL_NEW_CLOSE':
		// 	return {
		// 		...initialState,
		// 		newrecalculationModal: false,
		// 	};

		case 'VEHICLES_FILTER_MODAL_OPEN':
			return {
				...initialState,
				filterModalOpen: true,
			};

		case 'VEHICLES_FILTER_MODAL_CLOSE':
			return {
				...initialState,
				filterModalOpen: false,
			};

		case 'VEHICLES_RECALCULATING':
			return {
				...initialState,
				recalculating: true,
			};

		case 'VEHICLES_RECALCULATED':
			return {
				...initialState,
				recalculating: false,
			};

		case 'VEHICLES_COMMANDS_HISTORY_MODAL_OPEN':
			return {
				...initialState,
				commandsHistoryModal: true,
			};

		case 'VEHICLES_COMMANDS_HISTORY_MODAL_CLOSE':
			return {
				...initialState,
			};

		case 'VEHICLES_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'VEHICLES_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_VEHICLES_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_VEHICLES_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_VEHICLES_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};
		case 'VEHICLES_UNMOUNT':
			return {
				...initialState,
				vehicles: [],
				loading: true,
				pageNumber: 1,
				count: 0,
				activeIndex: -1,
				activeSort: '',
				sortOrder: '',
				deleting: false,
				deleteModalOpen: false,
				adding: false,
				bulkUpdating: false,
				addModalOpen: false,
				updating: false,
				editMode: false,
				vehicleGroupModal: false,
				swapModal: false,
				swapping: false,
				restoreModal: false,
				restoring: false,
				orgHistoryModal: false,
				editOrganisationModal: false,
				orgEditing: false,
				calibrationModal: false,
				calibrationEditModal: false,
				calibrationLoading: false,
				recalculationModal: false,
				recalculating: false,
				filterModalOpen: false,
			};
		case 'UPDATE_SEARCH_QUERY':
			return {
				...initialState,
				searchQuery: action.payload,
			};

		default:
			return initialState;
	}
}
