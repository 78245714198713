import React from 'react';

import styled from 'styled-components';

import { Checkbox } from 'semantic-ui-react';

function ObdToggle(props) {
	return (
		<Container style={props.style}>
			<label>{props.label}</label>

			<Checkbox toggle checked={props.checked} disabled={props.disabled} onChange={props.onChange} />
		</Container>
	);
}

export default ObdToggle;

export const Container = styled.section`
	width: 100%;

	display: flex;
	justify-content: space-between;
	align-items: center;

	white-space: nowrap;

	padding: 1em 0 1em 1em;

	.ui.toggle.checkbox input:checked ~ .box:before,
	.ui.toggle.checkbox input:checked ~ label:before {
		background-color: ${(props) => props.theme.danger} !important;
	}

	.ui.toggle.checkbox .box:before,
	.ui.toggle.checkbox label:before {
		background-color: ${(props) => props.theme.positive} !important;
	}
`;
