import React from 'react';
import CommonModal from '../../../molecules/modal/CommonModal';
import PlaceHolder from '../../../molecules/place-holder/PlaceHolder';
import { getFormattedDate } from '../../../../../../logic/utilities/date';
import { Button, List, Loader, Segment } from 'semantic-ui-react';
import { Card, Circle, Date, Description } from './TimeLineViewStyles';

const TimeLineViewModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="small"
			header={props.header}
			content={
				props.loading ? (
					<Segment style={{ border: 'none', boxShadow: 'none' }}>
						<Loader inverted />
					</Segment>
				) : props.data && props.data.length !== 0 ? (
					props.data.map((service, index) => {
						return (
							<Card key={index}>
								<Date>
									<span>{getFormattedDate(service.timestamp, 'DD MMM YYYY')}</span>
								</Date>
								{service.description && service.description.split('\n').length !== 0 && (
									<Description>
										<List bulleted size="small">
											{service.description
												.split('\n')
												.filter(function (el) {
													return el !== '';
												})
												.map((datum, index) => {
													return (
														<List.Item key={index} style={{ fontWeight: 500 }}>
															{datum}
														</List.Item>
													);
												})}
										</List>
									</Description>
								)}
								<Circle />
							</Card>
						);
					})
				) : (
					<PlaceHolder />
				)
			}
			actions={<Button onClick={props.onCancelClick}>Close</Button>}
		/>
	);
};

export default TimeLineViewModal;
