import styled from 'styled-components';

export const Container = styled.section`
	width: 4em;
	height: 100%;

	display: flex;
	flex-direction: column;

	box-sizing: border-box;
	background: ${(props) => props.theme.navBackground};
`;
