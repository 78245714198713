import React from 'react';
import CommonModal from '../../../molecules/modal/CommonModal';
import { Button, Dropdown, Form, Input } from 'semantic-ui-react';

const AddModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			header="Add Vehicle Group"
			content={
				<Form>
					<Form.Group widths="equal">
						<Form.Field required>
							<label>Name</label>
							<Input fluid onChange={props.onNameChange} />
						</Form.Field>
						<Form.Field>
							<label>Description</label>
							<Input fluid placeholder="Optional" onBlur={props.onDescriptionChange} />
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field required>
							<label>Organisation</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								selection
								clearable
								options={props.organisationOptions}
								onChange={props.onOrganisationChoose}
							/>
						</Form.Field>
						<Form.Field>
							<label>Vehicles</label>
							<Dropdown
								placeholder="Optional"
								fluid
								search
								selection
								multiple
								clearable
								disabled={props.selectedOrganisation === null}
								value={props.selectedVehicles}
								options={props.vehiclesOptions}
								onChange={props.onVehicleChoose}
							/>
						</Form.Field>
						<Form.Field>
							<label>Users</label>
							<Dropdown
								placeholder="Optional"
								fluid
								search
								selection
								multiple
								clearable
								disabled={props.selectedOrganisation === null}
								value={props.selectedUser}
								options={props.usersOptions}
								onChange={props.onUserChoose}
							/>
						</Form.Field>
					</Form.Group>
				</Form>
			}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button loading={props.addButtonLoading} positive disabled={props.disabled} onClick={props.onAddButtonClick}>
						Add
					</Button>
				</>
			}
		/>
	);
};

export default AddModal;
