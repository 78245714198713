import React, { useState } from 'react';
import CommonModal from '../../../molecules/modal/CommonModal';
import { Expander } from '../../../../styles/place-holders/Expander';
import { Button, Dropdown, Form } from 'semantic-ui-react';
import styled from 'styled-components';

const FilterModal = (props) => {
	const [isDropdownExpanded, setIsDropdownExpanded] = useState(false);

	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="tiny"
			scrolling
			header="Vehicle Groups Filters"
			content={
				<div style={{ minHeight: isDropdownExpanded ? '35vh' : '100%' }}>
					<Form>
						<Form.Group widths="equal">
							<Form.Field>
								<label>Organisations</label>
								<Dropdown
									placeholder="Choose..."
									fluid
									search
									selection
									clearable
									onClick={() => {
										setIsDropdownExpanded((prev) => !prev);
									}}
									open={isDropdownExpanded}
									multiple
									value={props.organisations}
									options={props.organisationsOptions}
									onChange={props.onOrganisationFilterChange}
								/>
							</Form.Field>
						</Form.Group>
					</Form>
				</div>
			}
			actions={
				<>
					<FlexContainer>
						<Button onClick={props.onResetClick}>Reset</Button>
						<Expander />
						<Button color="black" onClick={props.onCancelClick}>
							Cancel
						</Button>
						<Button primary onClick={props.onApplyClick} disabled={!props?.organisations?.length}>
							Apply
						</Button>
					</FlexContainer>
				</>
			}
		/>
	);
};

const FlexContainer = styled.section`
	display: flex;
`;

export default FilterModal;
