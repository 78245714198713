export default function commandsPipeline(
	initialState = {
		commandsPipeline: [],
		loading: true,
		pageNumber: 1,
		count: 0,
		activeIndex: -1,
		deleting: false,
		deleteModalOpen: false,
		activeSort: '',
		sortOrder: '',
		adding: false,
		addModalOpen: false,
		filterModalOpen: false,

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
	},
	action
) {
	switch (action.type) {
		case 'FEED_COMMANDS_PIPELINE':
			return {
				...initialState,
				commandsPipeline: action.payload,
				loading: false,
			};

		case 'COMMANDS_PIPELINE_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'COMMANDS_PIPELINE_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'COMMANDS_PIPELINE_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'COMMANDS_PIPELINE_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'COMMANDS_PIPELINE_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'COMMANDS_PIPELINE_DELETING':
			return {
				...initialState,
				deleting: true,
			};

		case 'COMMANDS_PIPELINE_DELETED':
			return {
				...initialState,
				deleting: false,
			};

		case 'COMMANDS_PIPELINE_DELETE_MODAL_OPEN':
			return {
				...initialState,
				deleteModalOpen: true,
			};

		case 'COMMANDS_PIPELINE_DELETE_MODAL_CLOSE':
			return {
				...initialState,
				deleteModalOpen: false,
			};

		case 'COMMANDS_PIPELINE_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'COMMANDS_PIPELINE_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'COMMANDS_PIPELINE_ADDING':
			return {
				...initialState,
				adding: true,
			};

		case 'COMMANDS_PIPELINE_ADDED':
			return {
				...initialState,
				adding: false,
			};

		case 'COMMANDS_PIPELINE_ADD_MODAL_OPEN':
			return {
				...initialState,
				addModalOpen: true,
			};

		case 'COMMANDS_PIPELINE_ADD_MODAL_CLOSE':
			return {
				...initialState,
				addModalOpen: false,
			};

		case 'COMMANDS_PIPELINE_FILTER_MODAL_OPEN':
			return {
				...initialState,
				filterModalOpen: true,
			};

		case 'COMMANDS_PIPELINE_FILTER_MODAL_CLOSE':
			return {
				...initialState,
				filterModalOpen: false,
			};

		case 'COMMANDS_PIPELINE_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'COMMANDS_PIPELINE_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_COMMANDS_PIPELINE_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_COMMANDS_PIPELINE_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_COMMANDS_PIPELINE_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'COMMANDS_PIPELINE_UNMOUNT':
			return {
				...initialState,
				commandsPipeline: [],
				activeSort: '',
				sortOrder: '',
				activeIndex: -1,
				pageNumber: 1,
			};

		default:
			return initialState;
	}
}
