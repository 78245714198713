export default function quickbooks(
	initialState = {
		hasQuickbooksAccess: false,
	},
	action
) {
	switch (action.type) {
		case 'HAS_QUICKBOOKS_ACCESS':
			return {
				...initialState,
				hasQuickbooksAccess: true,
			};

		case 'NO_QUICKBOOKS_ACCESS':
			return {
				...initialState,
				hasQuickbooksAccess: false,
			};

		default:
			return initialState;
	}
}
