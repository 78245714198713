import styled from 'styled-components';
import { CONTENT_HEADER_HEIGHT } from '../../../../data/constants/dimensions';

export const AccordionHeader = styled.section`
	width: 100%;
	height: ${CONTENT_HEADER_HEIGHT};

	box-sizing: border-box;
	padding: 1em 1.8em 1em 1em;

	display: flex;
	align-items: center;

	font-weight: bold;

	background: ${(props) => (props.background ? '#ff647c' : '#f5f5f5')};
	color: ${(props) => (props.background ? '#fff' : '#000')};
	border-radius: 3px;
`;
