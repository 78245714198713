import React, { useEffect, useState } from 'react';
import BasicLoader from '../../../blocks/molecules/loader/BasicLoader';
import { GraphCard, GraphContainerForPie } from './PieChartsStyles';
import { NavLink } from 'react-router-dom';
import { Chart } from 'react-google-charts';

const DeactivateVehicles = (props) => {
	const [statusValue, setStatusValue] = useState({ status: {} });

	const navLinkRef = React.createRef();

	useEffect(() => {
		if (navLinkRef.current && Object.keys(statusValue.status).length > 0) {
			navLinkRef.current.click();
		}
	}, [
		props.totalVehicleLoading,
		props.trialVehicleCount,
		props.totalVehicleCount,
		props.deactivateVehicleCount,
		props.breakdownVehicles,
		statusValue,
	]);

	return (
		<GraphContainerForPie>
			<GraphCard>
				{props.totalVehicleLoading &&
				props.trialVehicleCount &&
				props.totalVehicleCount !== null &&
				props.deactivateVehicleCount !== null ? (
					<BasicLoader />
				) : (
					<Chart
						chartType="PieChart"
						data={[
							['Vehicles', 'Count'],
							[
								'Active Vehicles',
								props.totalVehicleCount -
									(props.deactivateVehicleCount + props.trialVehicleCount + props.breakdownVehicles),
							],
							['Deactivated Vehicles', props.deactivateVehicleCount],
							['Trial Vehicles', props.trialVehicleCount],
							['Breakdown Vehicles', props.breakdownVehicles],
						]}
						height="100%"
						width="100%"
						options={{
							title: 'Vehicle Status',
							// this.props.deactivateVehicleCount ? `(${this.props.deactivateVehicleCount})` : ''

							pieHole: 0.4,
							slices: {
								1: { offset: 0.1 },
								2: { offset: 0.1 },
								3: { offset: 0.1 },
							},
							pieStartAngle: -90,
							pieSliceText: 'value',
							sliceVisibilityThreshold: 0,
							chartArea: {
								right: 0, // set this to adjust the legend width
								left: 20, // set this eventually, to adjust the left margin
							},
							fontSize: 12,
							legend: {
								position: 'right',
								alignment: 'center',
							},
						}}
						chartEvents={[
							{
								eventName: 'ready',
								callback: ({ chartWrapper, google }) => {
									const chart = chartWrapper.getChart();
									google.visualization.events.addListener(chart, 'click', (e) => {
										if (e.targetID === 'slice#1') {
											setStatusValue({ status: { paused: 'Paused' } });
										}
										if (e.targetID === 'slice#2') {
											setStatusValue({ status: { trial: 'Trial' } });
										}
										if (e.targetID === 'slice#3') {
											setStatusValue({ status: { breakdown: 'Breakdown' } });
										}
										if (e.targetID === 'slice#0') {
											setStatusValue({ status: { active: 'Active' } });
										}
										if (
											e.targetID === 'slice#1' ||
											e.targetID === 'slice#0' ||
											e.targetID === 'slice#2' ||
											e.targetID === 'slice#3'
										)
											navLinkRef?.current?.click();
									});
								},
							},
						]}
					/>
				)}
			</GraphCard>
			<NavLink ref={navLinkRef} to={{ pathname: '/operations/vehicles', state: statusValue }} />
		</GraphContainerForPie>
	);
};

export default DeactivateVehicles;
