import React from 'react';
import { NavLink } from 'react-router-dom';

import { Container, IconContainer } from './SidebarListItemStyles';
import { Expander } from '../../../styles/place-holders/Expander';

// Theme
import { COLORS } from '../../../../styles/colors';

import { Icon, Label, Popup } from 'semantic-ui-react';

function SidebarListItem(props) {
	const { path, exact, iconName, name, label, labelColor, expanded, background, accordionChild } = props;

	return (
		<Container background={background} accordionChild={accordionChild} expanded={expanded} labelColor={labelColor}>
			{expanded ? (
				<NavLink
					to={path}
					exact={exact || false}
					activeStyle={{
						color: COLORS.sidebarIcon,
						background: COLORS.sidebarIconBackground,
						fontWeight: 'bold',
					}}>
					<IconContainer>
						<Icon name={iconName} />
					</IconContainer>
					{expanded && (
						<>
							<span>{name}</span>
							<Expander />
							{label && (
								<Label size="mini" style={{ marginRight: '2em' }}>
									{label}
								</Label>
							)}
						</>
					)}
				</NavLink>
			) : (
				<Popup
					trigger={
						<NavLink
							to={path}
							exact={exact || false}
							activeStyle={{ color: COLORS.sidebarIcon, background: COLORS.sidebarIconBackground }}>
							<IconContainer>
								<Icon name={iconName} />
							</IconContainer>
							{expanded && (
								<>
									<span>{name}</span>
									<Expander />
								</>
							)}
						</NavLink>
					}
					basic
					content={name}
					offset={[0, 0]}
					position="right center"
				/>
			)}
		</Container>
	);
}

export default SidebarListItem;
