import styled from 'styled-components';
import { SIDEBAR_FOOTER_HEIGHT, SIDEBAR_HEADER_HEIGHT } from '../../../../data/constants/dimensions';

export const SidebarComponentList = styled.section`
	width: 100%;
	height: calc(100% - calc(${SIDEBAR_HEADER_HEIGHT} + ${SIDEBAR_FOOTER_HEIGHT}));
	overflow-y: auto;

	::-webkit-scrollbar {
		width: 0; /* Remove scrollbar space */
		background: transparent; /* Optional: just make scrollbar invisible */
	}

	.ui.accordion:not(.styled) .accordion .title ~ .content:not(.ui),
	.ui.accordion:not(.styled) .title ~ .content:not(.ui) {
		padding: 0 !important;
	}
`;
