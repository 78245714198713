import React from 'react';

import CommonModal from '../../../molecules/modal/CommonModal';
// import { ImmobilizerTypeDropdown } from '../../../molecules/immobilizer-type-dropdown/ImmobilizerTypeDropdown';
// import { FuelDataSourceDropdown } from '../../../molecules/fuel-datasource-dropdown/fuelDataSourceDropdown';
import { ADD_VEHICLE_STATUS_OPTIONS, VEHICLE_SENSORS_OPTIONS } from '../../../../../../data/constants/dropdown-options';
import { validateNumericValue } from '../../../../../../logic/utilities/validator';
import { Button, Checkbox, Dropdown, Form, Input } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import { Container } from '../../../../pages/secured/operations/Users';

const maxDate = moment().format('DD-MM-YY');

const AddModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			header="Add Vehicle"
			scrolling={true}
			content={
				<Form>
					<Form.Group widths="equal">
						<Form.Field required={true}>
							<label>Registration Number</label>
							<Input fluid onBlur={props.onRegNumberChange} />
						</Form.Field>
						<Form.Field required={true}>
							<label>Vehicle Type</label>
							<Dropdown
								placeholder="Choose"
								fluid
								search
								selection
								clearable
								options={props.vehicleTypeOptions}
								value={props.vehicleType}
								onChange={props.onVehicleTypeChoose}
							/>
						</Form.Field>
						<Form.Field required={true}>
							<label>Device</label>
							<Dropdown
								placeholder="Choose ..."
								fluid
								search
								selection
								clearable
								options={props.devicesOptions}
								onChange={props.onDeviceModelChoose}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field>
							<label>Make</label>
							<Input fluid placeholder="Optional" onBlur={props.onMakeChange} />
						</Form.Field>
						<Form.Field>
							<label>Model</label>
							<Input fluid placeholder="Optional" onBlur={props.onModelChange} />
						</Form.Field>{' '}
						<Form.Field>
							<label>Chassis Number</label>
							<Input fluid placeholder="Optional" onBlur={props.onChassisNumberChange} />
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field
							control={Input}
							label="Odometer Reading (KM)"
							error={
								!validateNumericValue(props.odometerFA) && {
									content: 'Odometer Reading should be only digits.',
								}
							}>
							<Input type="number" fluid placeholder="Optional" onBlur={props.onOdometerChange} />
						</Form.Field>
						<Form.Field>
							<label>Speed Limit</label>
							<Input
								type="number"
								label={'km/hr'}
								labelPosition="right"
								fluid
								placeholder="Optional"
								onBlur={props.onSpeedLimitChange}
							/>
						</Form.Field>
						{/*<Form.Field>
                                <label>Note</label>
                                <Input fluid placeholder="Optional" onBlur={props.onNoteChange}/>
                            </Form.Field>*/}

						<Form.Field>
							<label>Idling Duration</label>
							<Input type="number" fluid placeholder="Optional" onBlur={props.onIdlingDuration} />
						</Form.Field>

						<Form.Field>
							<label>Parking Duration</label>
							<Input type="number" fluid placeholder="Optional" onBlur={props.onParkingDuration} />
						</Form.Field>

						{/*<Form.Field>*/}
						{/*	<label>AC</label>*/}
						{/*	<Checkbox toggle checked={props.acStatusFA} onChange={props.onACStatusChange} />*/}
						{/*</Form.Field>*/}
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field required={true}>
							<label>Organisations</label>
							<Dropdown
								placeholder="Choose ..."
								fluid
								search
								clearable
								selection
								options={props.organisationsOptions}
								onChange={props.onOrgChange}
							/>
						</Form.Field>
						<Form.Field>
							<label>Vehicle Groups</label>
							<Dropdown
								placeholder="Optional"
								fluid
								search
								selection
								multiple
								clearable
								disabled={props.organisationFA === null}
								options={props.vehicleGroups}
								value={props.vehicleGroupFA}
								onChange={props.onVGChange}
							/>
						</Form.Field>
						<Form.Field>
							<label>Geofences</label>
							<Dropdown
								placeholder="Optional"
								fluid
								search
								selection
								multiple
								clearable
								disabled={props.organisationFA === null}
								options={props.geofences}
								value={props.geofencesFA}
								onChange={props.onGeofenceChange}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field required={true}>
							<label>Installation Date</label>
							<DateInput
								placeholder="Select"
								fluid
								closable
								maxDate={maxDate}
								clearable
								value={props.installationDateFA}
								onChange={props.onInstallationDateChange}
							/>
						</Form.Field>

						<Form.Field required={true}>
							<label>Status</label>
							<Dropdown
								placeholder="Choose"
								fluid
								search
								selection
								clearable
								value={props.status}
								options={ADD_VEHICLE_STATUS_OPTIONS}
								onChange={props.onStatusChangeFA}
							/>
						</Form.Field>
						{props.status === 'Trial' && (
							<Form.Field>
								<label>Trial End Date</label>
								<DateInput
									placeholder="Select Installation Date"
									fluid
									closable
									clearable
									value={props.trialEndDate}
									onChange={props.onTrialDateChange}
								/>
							</Form.Field>
						)}
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field>
							<label>Note</label>
							<Input fluid placeholder="Optional" onBlur={props.onNoteChange} />
						</Form.Field>
						<Form.Field>
							<label>Sensors</label>
							<Dropdown
								placeholder="Choose"
								fluid
								search
								selection
								clearable
								multiple
								value={props.sensors}
								options={VEHICLE_SENSORS_OPTIONS}
								onChange={props.onSensorChangeFA}
							/>

							{/*<ImmobilizerTypeDropdown*/}
							{/*	label={'Immobilizer'}*/}
							{/*	value={props.hasImmobilizerFA}*/}
							{/*	onChange={props.onImmobilizerChoose}*/}
							{/*/>*/}

							{/*<FuelDataSourceDropdown*/}
							{/*	label={'Fuel data source'}*/}
							{/*	onChange={props.onFuelDataSourceChoose}*/}
							{/*	value={props.fuelDataSourceFA}*/}
							{/*/>*/}
						</Form.Field>
						<Form.Field>
							<label>Expected Mileage</label>
							<Input
								label={'km/l'}
								labelPosition="right"
								fluid
								placeholder="Optional"
								onBlur={props.onExpectedMileageChange}
							/>
						</Form.Field>
						<Form.Field>
							<label>FMS</label>
							<Container>
								<Checkbox
									toggle
									onChange={(e, data) => props.onisFMSVehicleFAChange(e, data)}
									checked={props.isFMSVehicleFA}
								/>
							</Container>
						</Form.Field>
					</Form.Group>
					{/*{props.fuelDataSourceFA != null &&*/}
					{/*  props.fuelDataSourceFA !== 'No Fuel' &&*/}
					{/*  props.fuelDataSourceFA !== 'OBD (Litre)' && (*/}
					{/*    <>*/}
					{/*      <Form.Group widths="equal">*/}
					{/*        <Form.Field required={true}>*/}
					{/*          <label>Number of Sensors</label>*/}
					{/*          <Dropdown*/}
					{/*            selection*/}
					{/*            options={props.fuelSensorDropdown}*/}
					{/*            value={props.countOfFuelSensor}*/}
					{/*            disabled={props.fuelDataSourceFA <= 0}*/}
					{/*            placeholder="Number of Sensors"*/}
					{/*            onChange={props.onFuelSensorChange}*/}
					{/*          />*/}
					{/*        </Form.Field>*/}
					{/*      </Form.Group>*/}
					{/*      <Text>OR</Text>*/}
					{/*      <ImportCalibrationFile>*/}
					{/*        <img src={Upload} alt="Upload" />*/}
					{/*        <Button compact style={{marginTop: '1em'}} onClick={() => props.fileInputRef.current.click()}>*/}
					{/*          Import*/}
					{/*        </Button>*/}
					{/*        <input*/}
					{/*          style={{display: 'none'}}*/}
					{/*          id="file"*/}
					{/*          ref={props.fileInputRef}*/}
					{/*          type="file"*/}
					{/*          className="form-control"*/}
					{/*          accept={props.accept}*/}
					{/*          onChange={props.handleInputFile}*/}
					{/*        />*/}
					{/*      </ImportCalibrationFile>*/}
					{/*    </>*/}
					{/*  )}*/}

					{/*{(props.fuelDataSourceFA === 1 || props.fuelDataSourceFA === 2) &&*/}
					{/*  props.countOfFuelSensor !== '' && (*/}
					{/*    <Form.Field>*/}
					{/*      <label>Calibration Table</label>*/}

					{/*      <CalibrationTable>*/}
					{/*        <CalibrationHeader>{props.calibrationHeader}</CalibrationHeader>*/}

					{/*        {props.calibrationValue &&*/}
					{/*          props.calibrationValue.map((row, index) => {*/}
					{/*            return (*/}
					{/*              <>*/}
					{/*                <ExcelDataRow>*/}
					{/*                  {props.calibrationRows(row, index)}*/}
					{/*                  <Button*/}
					{/*                    icon*/}
					{/*                    size="small"*/}
					{/*                    disabled={props.calibrationValue.length === 1}*/}
					{/*                    style={{background: 'none'}}*/}
					{/*                    onClick={() => props.onCloseButtonClick(index)}>*/}
					{/*                    <Icon name="close" />*/}
					{/*                  </Button>*/}
					{/*                </ExcelDataRow>*/}
					{/*              </>*/}
					{/*            );*/}
					{/*          })}*/}
					{/*      </CalibrationTable>*/}
					{/*    </Form.Field>*/}
					{/*  )}*/}
				</Form>
			}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button positive disabled={props.disabled} loading={props.addButtonLoading} onClick={props.onAddButtonClick}>
						Add
					</Button>
				</>
			}
		/>
	);
};

export default AddModal;
