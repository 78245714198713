import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SidebarHeader from '../../blocks/molecules/sidebar-header/SidebarHeader';
import SidebarListItem from '../../blocks/molecules/sidebar-list-item/SidebarListItem';
import SidebarToggleIcon from '../../blocks/atoms/sidebar-toggle-icon/SidebarToggleIcon';
import Company from '../../pages/secured/metrics/Company';
import Customers from '../../pages/secured/metrics/Customers';
import { SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_EXPANDED_WIDTH } from '../../../../data/constants/dimensions';
import MetricsImage from '../../../assets/images/growth.png';
import { collapseSidebar, expandSidebar } from '../../../../logic/middleware/ui';
import { connect } from 'react-redux';
import { Container } from './MetricsStyles';
import { CategorySidebar } from '../../styles/containers/CategorySidebar';
import { CustomWidth } from '../../styles/containers/CustomWidth';
import { SidebarComponentList } from '../../styles/containers/SidebarComponentList';

class Metrics extends React.Component {
	render() {
		const { sidebarExpanded } = this.props;
		const url = this.props.match.url;

		return (
			<Container>
				{/*Navigation*/}
				<CategorySidebar width={sidebarExpanded ? SIDEBAR_EXPANDED_WIDTH : SIDEBAR_COLLAPSED_WIDTH}>
					<SidebarHeader imageSource={MetricsImage} alt="Metrics" name="Metrics" expanded={sidebarExpanded} />
					<SidebarComponentList>
						<SidebarListItem
							path={`${url}`}
							exact={true}
							iconName="building"
							name="Company"
							expanded={sidebarExpanded}
						/>
						<SidebarListItem path={`${url}/customers`} iconName="users" name="Customers" expanded={sidebarExpanded} />
					</SidebarComponentList>
					<SidebarToggleIcon
						name={sidebarExpanded ? 'chevron circle left' : 'chevron circle right'}
						handleClick={sidebarExpanded ? collapseSidebar : expandSidebar}
					/>
				</CategorySidebar>

				{/*Routes*/}
				<CustomWidth width={`calc(100% - ${sidebarExpanded ? SIDEBAR_EXPANDED_WIDTH : SIDEBAR_COLLAPSED_WIDTH})`}>
					<Switch>
						<Route path={`${url}`} exact component={Company} />
						<Route path={`${url}/customers`} component={Customers} />
					</Switch>
				</CustomWidth>
			</Container>
		);
	}
}

// Connect to Store
const mapStateToProps = (state) => ({
	sidebarExpanded: state.ui.sidebarExpanded,
});

export default connect(mapStateToProps)(Metrics);
