import React, { useState } from 'react';

import CommonModal from '../../../molecules/modal/CommonModal';
import { Expander } from '../../../../styles/place-holders/Expander';

import { SIMS_STATUS_FILTER_OPTIONS } from '../../../../../../data/constants/dropdown-options';

import { Button, Dropdown, Form } from 'semantic-ui-react';
import styled from 'styled-components';

const FilterModal = (props) => {
	const [isDropdownExpanded, setIsDropdownExpanded] = useState(false);

	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="tiny"
			scrolling
			header="Sims Filters"
			content={
				<div style={{ minHeight: isDropdownExpanded ? '25vh' : '100%' }}>
					<Form>
						<Form.Group widths="equal">
							<Form.Field>
								<label>Status</label>
								<Dropdown
									placeholder="Choose..."
									fluid
									search
									multiple
									selection
									onClick={() => {
										setIsDropdownExpanded((prev) => !prev);
									}}
									open={isDropdownExpanded}
									clearable
									value={props.status}
									options={SIMS_STATUS_FILTER_OPTIONS}
									onChange={props.onStatusChange}
								/>
							</Form.Field>
						</Form.Group>
					</Form>
				</div>
			}
			actions={
				<>
					<FlexContainer>
						<Button onClick={props.onResetClick}>Reset</Button>
						<Expander />
						<Button color="black" onClick={props.onCancelClick}>
							Cancel
						</Button>
						<Button primary disabled={props.disabled} onClick={props.onApplyClick}>
							Apply
						</Button>
					</FlexContainer>
				</>
			}
		/>
	);
};

const FlexContainer = styled.section`
	display: flex;
`;

export default FilterModal;
