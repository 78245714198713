import React from 'react';
import RecentlyAddedVehiclesGraph from './trial-vehicles/TrialVehiclesGraph';
import TrialOrganisationsGraph from './trial-organisations/TrialOrganisationsGraph';
import DeactivateVehicles from './pie-charts/DeactivateVehicles';
import { Container, GraphsWrapper } from './OverviewStyles';
import { fetchDashboardVehiclesData } from '../../../../logic/middleware/vehicles';
import { fetchDashboardOrganisations } from '../../../../logic/middleware/organisations';
import { handleError } from '../../../../logic/middleware/messaging';
import TotalVehiclesGraph from './bar-graphs/TotalVehiclesGraph';
import TotalOrganisationGraph from './bar-graphs/TotalOrganisationGraph';
import { trackMixpanelEvent } from '../../../../logic/middleware/mixpanel';
import RelayGraph from './relayGraph/RelayGraph';
import TotalVehicleGroupGraph from './TotalVehicleGroup/TotalVehicleGroupGraph';
import { connect } from 'react-redux';
import { datadogLogs } from '@datadog/browser-logs';

class Overview extends React.Component {
	timer = null;

	constructor() {
		super();
		this.state = {
			totalVehicleLoading: false,
			totalVehicles: null,
			breakdownVehicle: null,
			deactivatedVehicles: null,
			trialVehicles: null,
			newVehicles: null,
			newVehiclesMonthOnMonth: null,
			statusValue: null,
			totalOrganisations: null,
			newOrganisations: null,
			newOrganisationsMonthOnMonth: null,
			mobilizedVehicles: 0,
			immobilizedVehicles: 0,
			totalOrganisationLoading: false,
			relayVehiclesLoading: false,
		};
		this.getRelayData = this.getRelayData.bind(this);
	}

	getRelayData() {
		this.timer = setInterval(() => {
			let query = '?days=60';
			if (localStorage.getItem('isSuperAdmin') !== 'true') {
				fetchDashboardVehiclesData(query)
					.then((res) => {
						this.setState({
							mobilizedVehicles: parseInt(res?.data?.metadata?.mobilizedVehicles) || 0,
							immobilizedVehicles: parseInt(res?.data?.metadata?.immobilizedVehicles) || 0,
						});
					})
					.catch((err) => handleError(err));
			} else {
				Promise.all([fetchDashboardVehiclesData(query), fetchDashboardOrganisations(query)])
					.then((res) => {
						this.setState({
							mobilizedVehicles: parseInt(res[0]?.data?.metadata?.mobilizedVehicles) || 0,
							immobilizedVehicles: parseInt(res[0]?.data?.metadata?.immobilizedVehicles) || 0,
						});
					})
					.catch((err) => handleError(err));
			}
		}, 60000);
	}

	componentDidMount() {
		trackMixpanelEvent('Overview', { Action: 'View' });
		this.setState({ totalVehicleLoading: true, deactivateVehicleLoading: true, totalOrganisationLoading: true });
		let query = '?days=60';
		this.getRelayData();
		if (localStorage.getItem('isSuperAdmin') !== 'true') {
			fetchDashboardVehiclesData(query)
				.then((res) => {
					this.setState({
						totalVehicles: res?.data?.metadata?.totalVehicles || 0,
						deactivatedVehicles: res?.data?.metadata?.deactivatedVehicles || 0,
						trialVehicles: res?.data?.metadata?.trialVehicles || 0,
						breakdownVehicle: res?.data?.metadata?.breakdownVehicles || 0,
						mobilizedVehicles: parseInt(res?.data?.metadata?.mobilizedVehicles) || 0,
						immobilizedVehicles: parseInt(res?.data?.metadata?.immobilizedVehicles) || 0,
						newVehicles: res?.data?.metadata?.newVehicles || 0,
						newVehiclesMonthOnMonth: res?.data?.metadata?.newVehiclesMonthOnMonth || 0,
						totalVehicleLoading: false,
					});
				})
				.catch((error) => {
					handleError(error);
					datadogLogs.logger.error('Dashboard api', {}, error);
				});
		} else {
			Promise.all([fetchDashboardVehiclesData(query), fetchDashboardOrganisations(query)])
				.then((res) => {
					this.setState({
						totalVehicles: res[0]?.data?.metadata?.totalVehicles || 0,
						deactivatedVehicles: res[0]?.data?.metadata?.deactivatedVehicles || 0,
						trialVehicles: res[0]?.data?.metadata?.trialVehicles || 0,
						newVehicles: res[0]?.data?.metadata?.newVehicles || 0,
						mobilizedVehicles: parseInt(res[0]?.data?.metadata?.mobilizedVehicles) || 0,
						immobilizedVehicles: parseInt(res[0]?.data?.metadata?.immobilizedVehicles) || 0,
						relayVehiclesLoading: false,
						breakdownVehicle: res[0]?.data?.metadata?.breakdownVehicles || 0,
						newVehiclesMonthOnMonth: res[0]?.data?.metadata?.newVehiclesMonthOnMonth || 0,
						totalVehicleLoading: false,
						totalOrganisations: res[1]?.data?.metadata?.totalOrganisations || 0,
						newOrganisations: res[1]?.data?.metadata?.newOrganisations || 0,
						newOrganisationsMonthOnMonth: res[1]?.data?.metadata?.newOrganisationsMonthOnMonth || 0,
						totalOrganisationLoading: false,
					});
				})
				.catch((error) => {
					datadogLogs.logger.error('Dashboard api', {}, error);
					handleError(error);
				});
		}
	}

	componentWillUnmount() {
		clearInterval(this.timer);
	}

	// componentDidUpdate(prevProps) {
	// 	if (
	// 		this.props.relayStatus?.counter &&
	// 		prevProps.relayStatus?.counter &&
	// 		prevProps.relayStatus?.counter !== this.props.relayStatus.counter
	// 	) {
	// 		if (!this.props.loading) {
	// 			this.setState({
	// 				immobilizedVehicles: this.props.relayStatus.counter.immobilized,
	// 				mobilizedVehicles: this.props.relayStatus.counter.mobilized,
	// 			});
	// 		}
	// 	}
	// }

	render() {
		return (
			<Container>
				<GraphsWrapper>
					{/*<TrialVehicles*/}
					{/*	trialVehicleCount={this.state.trialVehicles}*/}
					{/*	totalVehicleCount={this.state.totalVehicles}*/}
					{/*	totalVehicleLoading={this.state.totalVehicleLoading}*/}
					{/*/>*/}
					<DeactivateVehicles
						deactivateVehicleCount={this.state.deactivatedVehicles}
						totalVehicleCount={this.state.totalVehicles}
						trialVehicleCount={this.state.trialVehicles}
						totalVehicleLoading={this.state.totalVehicleLoading}
						breakdownVehicles={this.state.breakdownVehicle}
					/>
					<RecentlyAddedVehiclesGraph
						deactivateVehicleCount={this.state.deactivatedVehicles}
						trialVehicleCount={this.state.trialVehicles}
						breakdownVehicles={this.state.breakdownVehicle}
						newVehicleCount={this.state.newVehicles}
						totalVehicleCount={this.state.totalVehicles}
						totalVehicleLoading={this.state.totalVehicleLoading}
					/>
					{localStorage.getItem('isSuperAdmin') === 'true' && (
						<TrialOrganisationsGraph
							newOrganisationCount={this.state.newOrganisations}
							totalOrganisationCount={this.state.totalOrganisations}
							totaOrganisationLoading={this.state.totalOrganisationLoading}
						/>
					)}
				</GraphsWrapper>

				<GraphsWrapper>
					{this.state.mobilizedVehicles !== 0 && this.state.immobilizedVehicles !== 0 && (
						<RelayGraph
							mobilisedVehiclesCount={this.state.mobilizedVehicles}
							immobilisedVehiclesCount={this.state.immobilizedVehicles}
							relayVehiclesLoading={this.state.relayVehiclesLoading}
						/>
					)}
					<TotalVehicleGroupGraph />
				</GraphsWrapper>

				<GraphsWrapper>
					<TotalVehiclesGraph
						newVehiclesMonthOnMonth={this.state.newVehiclesMonthOnMonth}
						totalVehicles={this.state.totalVehicles}
						totalVehicleLoading={this.state.totalVehicleLoading}
					/>
					{localStorage.getItem('isSuperAdmin') === 'true' && (
						<TotalOrganisationGraph
							newOrganisationsMonthOnMonth={this.state.newOrganisationsMonthOnMonth}
							totalOrganisationCount={this.state.totalOrganisations}
							totaOrganisationLoading={this.state.totalOrganisationLoading}
						/>
					)}
				</GraphsWrapper>
			</Container>
		);
	}
}

const mapStateToProps = (state) => ({
	relayStatus: state.firebaseService.relayStatus,
});

export default connect(mapStateToProps)(Overview);
