import React from 'react';

import CalendarMonth from '../../../../common/calender/month/CalendarMonth';
import CLVGraph from '../../../blocks/organisms/graphs/company-metrics/CLVGraph';
import NetMRRGraph from '../../../blocks/organisms/graphs/company-metrics/NetMRRGraph';
import MRRDistribution from '../../../blocks/organisms/graphs/company-metrics/MRRDistribution';
import VehicleExpansionGraph from '../../../blocks/organisms/graphs/company-metrics/VehicleExpansionGraph';
import TotalVehiclesGraph from '../../../blocks/organisms/graphs/company-metrics/TotalVehiclesGraph';
import TotalCustomersGraph from '../../../blocks/organisms/graphs/company-metrics/TotalCustomersGraph';
import CustomerDistributionGraph from '../../../blocks/organisms/graphs/company-metrics/CustomerDistributionGraph';
import ExportModal from '../../../blocks/organisms/modals/company-metrics/ExportModal';
import { CategoryHeader } from '../../../styles/containers/CategoryHeader';
import { Expander } from '../../../styles/place-holders/Expander';
import { GraphMain } from '../../../styles/containers/GraphMain';
import { GraphScrollView } from '../../../styles/containers/GraphScrollView';
import { GraphTableView } from '../../../styles/containers/GraphTableView';
import { GraphTableMain } from '../../../styles/containers/GraphTableMain';
import { CategoryContentMembrane } from '../../../styles/containers/CategoryContentMembrane';
import {
	exportData,
	formatCLVGraphData,
	formatCustomerDistributionGraphData,
	formatNetMRRDistributionGraphData,
	formatNetMRRGraphData,
	formatTotalCustomersGraphData,
	formatTotalVehiclesGraphData,
	formatVehicleExpansionGraphData,
	loadSaasCompanyMetrics,
	openExportModal,
} from '../../../../../logic/middleware/companyMetrics';
import { switchMetricsViewMode } from '../../../../../logic/middleware/ui';

import { connect } from 'react-redux';

import { Button, Table } from 'semantic-ui-react';
import styled from 'styled-components';

class Company extends React.Component {
	constructor() {
		super();

		this.state = {
			fileName: 'Metrics',
			fileExtension: 'excel',
		};

		this.fileExtensionChange = this.fileExtensionChange.bind(this);
		this.fileNameChange = this.fileNameChange.bind(this);
	}

	componentDidMount() {
		loadSaasCompanyMetrics('?pagecount=1000&pagenumber=1');
	}

	fileNameChange(e) {
		this.setState({ fileName: e.target.value });
	}

	fileExtensionChange(e, { value }) {
		this.setState({ fileExtension: value });
	}

	componentDidUpdate(prevProps) {
		if (this.props.initialDate !== prevProps.initialDate || this.props.finalDate !== prevProps.finalDate) {
			loadSaasCompanyMetrics(`?startDate=${this.props.initialDate}&endDate=${this.props.finalDate}`);
		}
	}

	render() {
		const { company, loading, metricsViewMode } = this.props;

		return (
			<CategoryContentMembrane>
				<CategoryHeader>
					<CalendarMonth />
					<Expander />
					<Button.Group>
						<Button primary={metricsViewMode === 'graph'} onClick={() => switchMetricsViewMode('graph')}>
							Graphical
						</Button>
						<Button primary={metricsViewMode === 'table'} onClick={() => switchMetricsViewMode('table')}>
							Tabular
						</Button>
					</Button.Group>
					<Expander />
					<Button
						primary
						onClick={() => {
							openExportModal();
						}}>
						Export
					</Button>
				</CategoryHeader>

				<GraphMain>
					{metricsViewMode === 'graph' ? (
						<GraphScrollView>
							<NetMRRGraph data={formatNetMRRGraphData(company)} loading={loading} />
							<MRRDistribution data={formatNetMRRDistributionGraphData(company)} loading={loading} />
							<TotalVehiclesGraph data={formatTotalVehiclesGraphData(company)} loading={loading} />
							<VehicleExpansionGraph data={formatVehicleExpansionGraphData(company)} loading={loading} />
							<TotalCustomersGraph data={formatTotalCustomersGraphData(company)} loading={loading} />
							<CustomerDistributionGraph data={formatCustomerDistributionGraphData(company)} loading={loading} />
							<CLVGraph data={formatCLVGraphData(company)} />
						</GraphScrollView>
					) : (
						<GraphTableView>
							<GraphTableMain>
								<Table celled striped compact selectable>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell collapsing>No.</Table.HeaderCell>
											<Table.HeaderCell>Month</Table.HeaderCell>
											<Table.HeaderCell collapsing>Net Mrr</Table.HeaderCell>
											<Table.HeaderCell collapsing>New Mrr</Table.HeaderCell>
											<Table.HeaderCell collapsing>Expansion Mrr</Table.HeaderCell>
											<Table.HeaderCell collapsing>Churned Mrr</Table.HeaderCell>
											<Table.HeaderCell collapsing>Mrr Platform</Table.HeaderCell>
											<Table.HeaderCell collapsing>Mrr Product</Table.HeaderCell>
											<Table.HeaderCell collapsing>Total Customers</Table.HeaderCell>
											<Table.HeaderCell collapsing>New Customers</Table.HeaderCell>
											<Table.HeaderCell collapsing>Churned Customers</Table.HeaderCell>
											<Table.HeaderCell collapsing>Total Vehicle</Table.HeaderCell>
											<Table.HeaderCell collapsing>New Vehicles</Table.HeaderCell>
											<Table.HeaderCell collapsing>Expansion Vehicles</Table.HeaderCell>
											<Table.HeaderCell collapsing>Churned Vehicles</Table.HeaderCell>
											<Table.HeaderCell collapsing>CLV</Table.HeaderCell>
											<Table.HeaderCell collapsing>CAC</Table.HeaderCell>
											<Table.HeaderCell collapsing>CLV-CAC Ratio</Table.HeaderCell>
											<Table.HeaderCell collapsing>Cogs Platform</Table.HeaderCell>
											<Table.HeaderCell collapsing>Cogs Products</Table.HeaderCell>
											<Table.HeaderCell collapsing>Fixed Expenditure</Table.HeaderCell>
											<Table.HeaderCell collapsing>Variable Expenditure</Table.HeaderCell>
											<Table.HeaderCell collapsing>GM</Table.HeaderCell>
											<Table.HeaderCell collapsing>CM</Table.HeaderCell>
											<Table.HeaderCell collapsing>EBITDA</Table.HeaderCell>
											<Table.HeaderCell collapsing>NM</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{company &&
											company.map((metrics, index) => {
												return (
													<Table.Row key={index}>
														<Table.Cell collapsing>{index + 1}</Table.Cell>
														<Table.Cell singleLine>{metrics.date}</Table.Cell>
														<Table.Cell collapsing>{metrics.netMrr}</Table.Cell>
														<Table.Cell collapsing>{metrics.newMrr}</Table.Cell>
														<Table.Cell collapsing>{metrics.expansionMrr}</Table.Cell>
														<Table.Cell collapsing>{metrics.churnedMrr}</Table.Cell>
														<Table.Cell collapsing>{metrics.mrrPlatform}</Table.Cell>
														<Table.Cell collapsing>{metrics.mrrProduct}</Table.Cell>
														<Table.Cell collapsing>{metrics.totalCustomers}</Table.Cell>
														<Table.Cell collapsing>{metrics.newCustomers}</Table.Cell>
														<Table.Cell collapsing>{metrics.churnedCustomers}</Table.Cell>
														<Table.Cell collapsing>{metrics.totalVehicles}</Table.Cell>
														<Table.Cell collapsing>{metrics.newVehicles}</Table.Cell>
														<Table.Cell collapsing>{metrics.expansionVehicles}</Table.Cell>
														<Table.Cell collapsing>{metrics.churnedVehicles}</Table.Cell>
														<Table.Cell collapsing>{metrics.clv}</Table.Cell>
														<Table.Cell collapsing>{metrics.cac}</Table.Cell>
														<Table.Cell collapsing>{metrics.clvCacRatio}</Table.Cell>
														<Table.Cell collapsing>{metrics.cogsPlatform}</Table.Cell>
														<Table.Cell collapsing>{metrics.cogsProduct}</Table.Cell>
														<Table.Cell collapsing>{metrics.fixedExpenditure}</Table.Cell>
														<Table.Cell collapsing>{metrics.variableExpenditure}</Table.Cell>
														<Table.Cell collapsing>{metrics.gm}</Table.Cell>
														<Table.Cell collapsing>{metrics.cm}</Table.Cell>
														<Table.Cell collapsing>{metrics.ebitda}</Table.Cell>
														<Table.Cell collapsing>{metrics.nm}</Table.Cell>
													</Table.Row>
												);
											})}
									</Table.Body>
								</Table>
							</GraphTableMain>
						</GraphTableView>
					)}
				</GraphMain>

				{this.props.openExportModal && (
					<ExportModal
						open={this.props.openExportModal}
						fileName={this.state.fileName}
						fileNameChange={this.fileNameChange}
						fileExtension={this.state.fileExtension}
						fileExtensionChange={this.fileExtensionChange}
						onDownloadClick={() => exportData(company, this.state.fileName, this.state.fileExtension)}
					/>
				)}
			</CategoryContentMembrane>
		);
	}
}

// Connect to Store
const mapStateToProps = (state) => ({
	loading: state.companyMetrics.loading,
	company: state.companyMetrics.company,

	initialDate: state.calendarMonth.initialDate,
	finalDate: state.calendarMonth.finalDate,

	openExportModal: state.companyMetrics.openExportModal,

	metricsViewMode: state.ui.metricsViewMode,
});

export default connect(mapStateToProps)(Company);

export const Container = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
`;
