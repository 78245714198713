import React from 'react';

import { CHANGE_HISTORY_API, SIMS_API, SIMS_API_BULK } from '../../../../../logic/configs/api';
import BasicLoader from '../../../blocks/molecules/loader/BasicLoader';
import SearchBox from '../../../blocks/atoms/search-box/SearchBox';
import ToggleSwitch from '../../../blocks/molecules/toggle-switch/ToggleSwitch';
import FooterPagination from '../../../blocks/molecules/pagination/Pagination';
import DeleteModal from '../../../blocks/organisms/modals/sims/DeleteModal';
import RestoreModal from '../../../blocks/organisms/modals/sims/RestoreModal';
import AddModal from '../../../blocks/organisms/modals/sims/AddModal';
import ExportModal from '../../../blocks/organisms/modals/sims/ExportModal';
import { CategoryHeader } from '../../../styles/containers/CategoryHeader';
import { Expander } from '../../../styles/place-holders/Expander';
import { CategoryMain } from '../../../styles/containers/CategoryMain';
import { AccordionHeader } from '../../../styles/containers/AccordionHeader';
import { AccordionMain } from '../../../styles/containers/AccordionMain';
import { CategoryFooter } from '../../../styles/containers/CategoryFooter';
import { CategoryContentMembrane } from '../../../styles/containers/CategoryContentMembrane';
import { AccordionMainContent } from '../../../styles/containers/AccordionMainContent';
import PlaceHolder from '../../../blocks/molecules/place-holder/PlaceHolder';
import FilterModal from '../../../blocks/organisms/modals/sims/FilterModal';
import TimeLineViewModal from '../../../blocks/organisms/modals/time-line-view/TimeLineViewModal';
import TransitionLoader from '../../../blocks/molecules/loader/TransitionLoader';

import { EXCEL_FILE_TYPES, FILE_TYPE_EXTENSIONS } from '../../../../../data/constants/file-types';
import { SIM_STATUS } from '../../../../../data/constants/statuses';

import { createChangeHistoryDTO } from '../../../../../logic/dto/changeHistory';

import {
	closeFilterModal,
	exportAll,
	exportData,
	getServiceProvidersForDropdown,
	openFilterModal,
	refreshSims,
	searchSims,
	toggleAccordion,
} from '../../../../../logic/middleware/sims';
import { dispatch, dispatchNoPayload } from '../../../../../logic/middleware/middleware';

import { connect } from 'react-redux';

import {
	generateErrorMessage,
	generateSuccessMessage,
	handleError,
	handleSuccess,
} from '../../../../../logic/middleware/messaging';

// import axios from 'axios';
// import { CheckboxContainer } from '../../../styles/Containers';
import { Actions, HeaderElement, LeftActions, Row, RowElement } from '../../../styles/Accordion';
import { Accordion, Button, Dropdown, Form, Icon, Input, Label } from 'semantic-ui-react';
import accessSetter from '../../../../../logic/utilities/accessSetter';
import { searchVehicleGroups } from '../../../../../logic/middleware/vehicleGroups';
import { trackMixpanelEvent } from '../../../../../logic/middleware/mixpanel';
import { deleteData, getData, patchData, postData } from '../../../../../logic/middleware/services/apiRequest';
import Import from '../../../../common/import/SimsImport';
import { datadogLogs } from '@datadog/browser-logs';
import ImportInfo from '../../../../common/ImportInfo/ImportInfo';

class SIM extends React.Component {
	simID = '';
	simsNumber = '';
	timeoutId;

	// fileTemplate = [
	// 	{ name: 'Mobile Number', requestTitle: 'mobileNumber', type: 'number' },
	// 	{ name: 'SIM Number', requestTitle: 'simNumber', type: 'number' },
	// 	{ name: 'IMSI Number', requestTitle: 'imsiNumber', type: 'number' },
	// 	{ name: 'Service Provider', requestTitle: 'serviceProvider', type: 'string' },
	// 	{ name: 'Note', requestTitle: 'note', type: 'string' },
	// ];
	// SheetJSFT = ['xlsx', 'xls']
	// 	.map(function (x) {
	// 		return '.' + x;
	// 	})
	// 	.join(',');
	inputData;

	deletedMode = false;
	selectAllActive = false;

	sortOrder = '';
	activeSort = '';
	// Status Filter
	activeFilters = {
		status: [],
	};

	constructor(props) {
		super(props);

		this.fileInputRef = React.createRef();

		this.state = {
			initialLoading: true,
			manageAccess: false,
			readAccess: false,
			createAccess: false,
			updateAccess: false,
			deleteAccess: false,
			importInfo: false,

			serviceProvidersFA: [
				{
					key: -1,
					value: -1,
					text: 'Fetching...',
				},
			],
			// simTypesFA: [
			//   {
			//     key: -1,
			//     value: -1,
			//     text: 'Fetching...',
			//   },
			// ],

			apnFE: null,
			noteFE: null,

			mobileNoFA: null,
			simNoFA: null,
			imsiNoFA: null,
			simResponse: null,
			serviceProviderFA: null,
			// simTypeFA: null,
			noteFA: null,

			anyRowSelected: false,
			selectedRows: new Set(),

			importViewOpen: false,

			filters: {
				status: [],
			},

			// Change History
			changeHistory: null,
			changeHistoryLoading: false,

			deletedMode: false,

			fileName: 'Sims',
			fileExtension: 'pdf',
			searchQuery: '',
		};

		this.handleInputFile = this.handleInputFile.bind(this);
		this.importSims = this.importSims.bind(this);
		this.toggleDeletedMode = this.toggleDeletedMode.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.add = this.add.bind(this);
		this.cleanAddOperation = this.cleanAddOperation.bind(this);
		this.handleInputFile = this.handleInputFile.bind(this);
		this.export = this.export.bind(this);
		this.update = this.update.bind(this);
		this.cleanEditOperation = this.cleanEditOperation.bind(this);
		this.cleanDeleteOperation = this.cleanDeleteOperation.bind(this);
		this.cleanRestoreOperation = this.cleanRestoreOperation.bind(this);
		this.handleDataSizeLimitChange = this.handleDataSizeLimitChange.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.cleanSelectedRows = this.cleanSelectedRows.bind(this);

		this.getChangeHistory = this.getChangeHistory.bind(this);
		this.cleanChangeHistoryOperation = this.cleanChangeHistoryOperation.bind(this);

		this.getFilteredSims = this.getFilteredSims.bind(this);
		this.cleanImportOperation = this.cleanImportOperation.bind(this);
		this.handleExportSelection = this.handleExportSelection.bind(this);
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.deleteSim = this.deleteSim.bind(this);
		this.restoreSim = this.restoreSim.bind(this);
		this.sort = this.sort.bind(this);
		this.onStatusChange = this.onStatusChange.bind(this);

		this.onMobileNumberChange = this.onMobileNumberChange.bind(this);
		this.onSimNumberChange = this.onSimNumberChange.bind(this);
		this.onImsiNumberChange = this.onImsiNumberChange.bind(this);
		this.onServiceProviderChange = this.onServiceProviderChange.bind(this);
		// this.onSimTypeChange = this.onSimTypeChange.bind(this);
		this.onNoteChange = this.onNoteChange.bind(this);
		this.fileExtensionChange = this.fileExtensionChange.bind(this);
		this.fileNameChange = this.fileNameChange.bind(this);
		this.manageAccessSetter = this.manageAccessSetter.bind(this);
		this.handleSearchQuery = this.handleSearchQuery.bind(this);
		this.handleDoubleClick = this.handleDoubleClick.bind(this);

		this.handleOpenImportInfo = this.handleOpenImportInfo.bind(this);
		this.handleCloseImportInfo = this.handleCloseImportInfo.bind(this);
	}

	manageAccessSetter(rule, action) {
		this.setState({ [action]: rule });
	}

	componentDidMount() {
		let path = window.location.pathname.split('/');
		if (path.length > 3) {
			this.simsNumber = decodeURIComponent(path[3]);

			if (this.simsNumber) {
				this.setState({ searchQuery: this.simsNumber });
				this.handleSearch({ target: { value: this.simsNumber } });
				return;
			}
		}

		if (this.props.searchQuery) {
			dispatch('UPDATE_SEARCH_QUERY', this.props.searchQuery);
			this.handleSearch({ target: { value: this.props.searchQuery } });
			return;
		}

		trackMixpanelEvent('Sim', { Action: 'View' });
		const permissions = JSON.parse(localStorage.getItem('permissions'));
		const simsPermissions = permissions?.filter((permission) => permission.subject === 'Device And Sim');
		if (simsPermissions) {
			for (const simsPrm of simsPermissions) {
				accessSetter(simsPrm?.action, this.manageAccessSetter);
			}
		}
		refreshSims();
	}

	// Fetch
	getFilteredSims(activePage = 1, resetAccordion = true) {
		if (resetAccordion) {
			dispatch('SIMS_TOGGLE_ACCORDION', -1);
		}

		let query = '';
		if (this.state.searchQuery !== '') {
			query = query + searchSims(this.state.searchQuery);
		}

		if (this.activeSort !== '' && this.sortOrder !== '') {
			if (this.sortOrder === 'asc') {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			} else {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			}
		}

		if (this.activeFilters.status.length) {
			query = query + `&status[]=${this.activeFilters.status.join('&status[]=')}`;
		}

		if (this.deletedMode) {
			query = query + `&deleted=1`;
		}

		refreshSims(query, activePage);
	}

	handleSearch(e) {
		if (this.simsNumber) {
			this.setState({ searchQuery: this.simsNumber });
			dispatch('UPDATE_SEARCH_QUERY', this.simsNumber);
		} else {
			this.setState({ searchQuery: e.target.value });
			dispatch('UPDATE_SEARCH_QUERY', e.target.value);
		}
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}

		this.timeoutId = setTimeout(() => {
			this.getFilteredSims();
			this.cleanSelectedRows();
		}, 500);
	}

	handleInputFile(e) {
		const files = e.target.files;
		if (files && files[0] && EXCEL_FILE_TYPES.indexOf(files[0].type) !== -1) {
			this.inputData = files[0];
			dispatchNoPayload('SIMS_IMPORT_MODAL_OPEN');
		} else generateErrorMessage('Only excel file types are allowed.');
	}

	async importSims(response) {
		dispatchNoPayload('SIMS_ADDING');

		postData(SIMS_API_BULK, '', response.data)
			.then((res) => {
				this.setState({ simResponse: res.data }, () => {
					response.success();
					handleSuccess();
				});
				if (res.status === 206) {
					generateSuccessMessage(res.data, 'Partial Success');
				} else {
					generateSuccessMessage(res.data, 'Created');
				}
				// this.cleanImportOperation();
				// dispatchNoPayload('SIMS_ADDED');
			})
			.catch((error) => {
				handleError(error);
				datadogLogs.logger.error('Sim bulk import api', {}, error);
				response.error();
				dispatchNoPayload('SIMS_ADDED');
			});
	}

	cleanImportOperation() {
		dispatchNoPayload('SIMS_IMPORT_MODAL_CLOSE');
		if (this.fileInputRef.current) {
			this.fileInputRef.current.value = '';
		}
		this.setState({ simResponse: '' });
	}

	toggleDeletedMode() {
		this.deletedMode = !this.deletedMode;
		this.setState({
			deletedMode: !this.state.deletedMode,
		});

		this.getFilteredSims();
		this.cleanSelectedRows();
	}

	// Export
	fileNameChange(e) {
		this.setState({ fileName: e.target.value });
	}

	fileExtensionChange(e, { value }) {
		this.setState({ fileExtension: value });
	}

	handleExportSelection(checkedData, id) {
		let selectedRows = this.state.selectedRows;

		if (selectedRows.has(id)) {
			selectedRows.delete(id);
		} else selectedRows.add(id);
		this.setState({
			selectedRows: selectedRows,
			anyRowSelected: !!selectedRows.size,
		});
	}

	handleSelectAll(checked) {
		let selectedRows = new Set();

		if (checked) {
			this.selectAllActive = true;
			this.props.sims.forEach((sim) => selectedRows.add(sim.id));
			this.setState({
				selectedRows: selectedRows,
				anyRowSelected: !!selectedRows.size,
			});
		} else {
			this.selectAllActive = false;
			this.setState({
				selectedRows: new Set(),
				anyRowSelected: !!selectedRows.size,
			});
		}
	}

	export() {
		const { sims } = this.props;

		let simsToExport = [];
		sims.forEach((sim) => {
			if (this.state.selectedRows.has(sim.id)) {
				simsToExport.push(sim);
			}
		});

		return simsToExport;
	}

	getFilterListQuery() {
		let query = '';

		query = query + `?fileType=${this.state.fileExtension}`;

		if (this.state.searchQuery !== '') {
			query = query + searchVehicleGroups(this.state.searchQuery);
		}

		if (this.activeSort !== '' && this.sortOrder !== '') {
			if (this.sortOrder === 'asc') {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			} else {
				query = query + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			}
		}

		if (this.activeFilters.status.length) {
			// query = query + `&status[]=${this.activeFilters.status.join(',')}`;
			query = query + this.activeFilters.status.map((status) => `&status[]=${status}`).join('');
		}

		if (this.deletedMode) {
			query = query + `&deleted=1`;
		}

		return query;
	}

	// Add
	add() {
		const { mobileNoFA, simNoFA, imsiNoFA, serviceProviderFA, noteFA } = this.state;
		let query = {};

		if (mobileNoFA) query = { ...query, mobileNumber: mobileNoFA.trim() };

		if (simNoFA) query = { ...query, simNumber: simNoFA.trim() };

		if (imsiNoFA) query = { ...query, imsiNumber: imsiNoFA.trim() };

		if (serviceProviderFA) query = { ...query, serviceProvider: serviceProviderFA };

		// if (simTypeFA) query = {...query, simType: simTypeFA};

		if (noteFA) query = { ...query, note: noteFA.trim() };

		dispatchNoPayload('SIMS_ADDING');
		// axios
		// 	.post(SIMS_API, query, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 			'Content-Type': 'application/json',
		// 		},
		// 	})
		postData(SIMS_API, '', query)
			.then((response) => {
				dispatchNoPayload('SIMS_ADDED');
				generateSuccessMessage(response);
				this.getFilteredSims(this.props.pageNumber);
				this.cleanAddOperation();
			})
			.catch((error) => {
				dispatchNoPayload('SIMS_ADDED');
				datadogLogs.logger.error('Sim add api', {}, error);

				handleError(error);
			});
	}

	cleanAddOperation() {
		dispatchNoPayload('SIMS_ADD_MODAL_CLOSE');

		this.setState({
			serviceProvidersFA: [
				{
					key: -1,
					value: -1,
					text: 'Fetching...',
				},
			],
			// simTypesFA: [
			//   {
			//     key: -1,
			//     value: -1,
			//     text: 'Fetching...',
			//   },
			// ],
			mobileNoFA: null,
			simNoFA: null,
			imsiNoFA: null,
			serviceProviderFA: null,
			// simTypeFA: null,
			noteFA: null,
		});
	}

	onMobileNumberChange(e) {
		this.setState({
			mobileNoFA: e.target.value,
		});
	}

	onSimNumberChange(e) {
		this.setState({
			simNoFA: e.target.value,
		});
	}

	onImsiNumberChange(e) {
		this.setState({
			imsiNoFA: e.target.value,
		});
	}

	onServiceProviderChange(e, { value }) {
		this.setState({
			serviceProviderFA: value,
		});
	}

	// onSimTypeChange(e, {value}) {
	//   this.setState({
	//     simTypeFA: value,
	//   });
	// }

	onNoteChange(e) {
		this.setState({
			noteFA: e.target.value,
		});
	}

	// Update
	update(id) {
		const { apnFE, noteFE } = this.state;
		let query = {};

		if (apnFE) query = { ...query, apn: apnFE.trim() };

		query = noteFE === null ? query : { ...query, note: noteFE.trim() };

		dispatchNoPayload('SIMS_UPDATING');
		// axios
		// 	.patch(SIMS_API + `/${id}`, query, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		patchData(SIMS_API + `/${id}`, query)
			.then((response) => {
				dispatchNoPayload('SIMS_UPDATED');

				handleSuccess(response);
				generateSuccessMessage(response);
				this.getFilteredSims(this.props.pageNumber, false);
				this.cleanEditOperation();
			})
			.catch((error) => {
				dispatchNoPayload('SIMS_UPDATED');
				handleError(error);
			});
	}

	cleanEditOperation() {
		dispatchNoPayload('SIMS_EDIT_MODAL_CLOSE');

		this.setState({
			apnFE: null,
			noteFE: null,
			planFE: null,
		});
	}

	// Delete
	deleteSim(id) {
		dispatchNoPayload('SIMS_DELETING');
		// axios
		// 	.delete(SIMS_API + `/${id}`, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		deleteData(SIMS_API, `/${id}`)
			.then((response) => {
				dispatchNoPayload('SIMS_DELETED');
				generateSuccessMessage(response);
				this.getFilteredSims(this.props.pageNumber);
				this.cleanDeleteOperation();
			})
			.catch((error) => {
				dispatchNoPayload('SIMS_DELETED');
				handleError(error);
			});
	}

	cleanDeleteOperation() {
		dispatchNoPayload('SIMS_DELETE_MODAL_CLOSE');

		this.simID = '';
	}

	// Restore
	restoreSim(id) {
		dispatchNoPayload('SIMS_RESTORING');
		// axios
		// 	.post(
		// 		SIMS_API + `/${id}/restore`,
		// 		{},
		// 		{
		// 			headers: {
		// 				Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 			},
		// 		}
		// 	)
		postData(SIMS_API, `/${id}/restore`)
			.then((response) => {
				dispatchNoPayload('SIMS_RESTORED');
				generateSuccessMessage(response, 'Sim Restored');
				handleSuccess(response);
				this.getFilteredSims(this.props.pageNumber);
				this.cleanRestoreOperation();
			})
			.catch((error) => {
				dispatchNoPayload('SIMS_RESTORED');
				datadogLogs.logger.error('Sim restore api', {}, error);
				handleError(error);
			});
	}

	cleanRestoreOperation() {
		dispatchNoPayload('SIMS_RESTORE_MODAL_CLOSE');

		this.simID = '';
	}

	sort(param, activeSort, sortOrder) {
		if (!param) {
			this.activeSort = param;
			this.sortOrder = '';
		} else {
			if (activeSort !== param) {
				this.sortOrder = 'asc';
				this.activeSort = param;
			} else {
				if (sortOrder === 'asc') {
					this.sortOrder = 'desc';
				} else {
					this.sortOrder = 'asc';
				}
			}
		}

		dispatch('SIMS_SORT_ORDER', this.sortOrder);
		dispatch('SIMS_ACTIVE_ORDER', this.activeSort);
		this.getFilteredSims(this.props.pageNumber);
	}

	onStatusChange(e, { value }) {
		this.setState({
			filters: { ...this.state.filters, status: value },
		});
	}

	setFilterChange(status) {
		this.setState({
			filters: { status: status },
		});
	}

	isFilterActive() {
		return this.activeFilters.status.length;
	}

	// Pagination
	handleDataSizeLimitChange() {
		this.getFilteredSims();
	}

	handlePageChange(activePage) {
		this.getFilteredSims(activePage);
		this.cleanSelectedRows();
	}

	handleDoubleClick(deviceImei) {
		this.props.history.push({
			pathname: '/operations/devices',
			state: { simStatus: deviceImei },
		});
	}

	componentDidUpdate(prevProps) {
		if (prevProps.sims !== this.props.sims) {
			if (this.selectAllActive) this.handleSelectAll(true);
		}

		if (prevProps.loading !== this.props.loading) {
			if (!this.props.loading) {
				this.setState({
					initialLoading: false,
				});
			}
		}

		if (prevProps.pageNumber !== this.props.pageNumber) {
			document.getElementById('AccordionMain').scrollTo(0, 0);
		}
	}

	cleanSelectedRows() {
		this.setState({
			anyRowSelected: false,
			selectedRows: new Set(),
		});
	}

	// Change History
	getChangeHistory(id) {
		this.setState({ changeHistoryLoading: true });

		// axios(CHANGE_HISTORY_API + `/sim/${id}`, {
		// 	headers: {
		// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		'Content-Type': 'application/json',
		// 	},
		// })
		getData(CHANGE_HISTORY_API, `/sim/${id}`)
			.then((response) => {
				let history = createChangeHistoryDTO(response?.data?.data);
				this.setState({
					changeHistory: history,
					changeHistoryLoading: false,
				});
			})
			.catch((error) => {
				handleError(error);
				this.setState({ changeHistoryLoading: false });
			});
	}

	cleanChangeHistoryOperation() {
		dispatchNoPayload('SIMS_CHANGE_HISTORY_MODAL_CLOSE');

		this.simID = '';
		this.setState({
			changeHistory: null,
		});
	}

	handleOpenImportInfo() {
		this.setState({
			importInfo: true,
		});
	}

	handleCloseImportInfo() {
		this.setState({
			importInfo: false,
		});
	}

	componentWillUnmount() {
		dispatchNoPayload('SIMS_UNMOUNT');
	}

	handleSearchQuery(value) {
		this.setState({ searchQuery: value });
		dispatch('UPDATE_SEARCH_QUERY', value);
	}

	render() {
		return (
			<CategoryContentMembrane>
				<CategoryHeader>
					<SearchBox
						placeholder="Search in sims"
						searchQuery={this.state.searchQuery}
						clearSearchIconClick={() => {
							this.setState({ searchQuery: '' }, () => {
								this.simsNumber = '';
								this.getFilteredSims(this.props.pageNumber);
							});
							dispatch('UPDATE_SEARCH_QUERY', '');
						}}
						value={this.props.searchQuery}
						handleSearchQuery={this.handleSearchQuery}
						handleSearch={this.handleSearch}
						// disabled={!this.state.searchQuery && (this.props.loading || this.props.exportingData)}
					/>
					<Button
						icon
						primary
						title="Refresh Sims"
						disabled={this.props.loading || this.props.exportingData}
						onClick={() => this.getFilteredSims(this.props.pageNumber)}>
						<Icon name="refresh" />
					</Button>
					<Expander />
					{/*Add*/}
					<Button
						icon
						primary={!this.isFilterActive()}
						disabled={this.props.loading || this.props.exportingData}
						color={this.isFilterActive() ? 'orange' : null}
						title="Filter Sims"
						onClick={openFilterModal}>
						<Icon name="filter" />
					</Button>
					{!this.state.deletedMode && (
						<>
							<Button
								primary
								title="Add Sim"
								disabled={
									this.props.loading || this.props.exportingData
									// || !(this.state.manageAccess || this.state.createAccess)
								}
								onClick={() => {
									trackMixpanelEvent('Sim', { Action: 'Add' });
									dispatchNoPayload('SIMS_ADD_MODAL_OPEN');
									this.setState({
										serviceProvidersFA: getServiceProvidersForDropdown(),
										// simTypesFA: getSimTypesForDropdown(),
									});
								}}>
								Add
							</Button>

							<Button
								primary
								title="Import Sim from file"
								disabled={
									this.props.loading || this.props.exportingData
									// || !(this.state.manageAccess || this.state.createAccess)
								}
								// onClick={() => this.fileInputRef.current.click()}
								onClick={this.handleOpenImportInfo}>
								Import
							</Button>
							{/*<input*/}
							{/*	style={{ display: 'none' }}*/}
							{/*	id="file"*/}
							{/*	ref={this.fileInputRef}*/}
							{/*	type="file"*/}
							{/*	className="form-control"*/}
							{/*	accept={this.SheetJSFT}*/}
							{/*	onChange={this.handleInputFile}*/}
							{/*/>*/}

							{this.state.importInfo && (
								<ImportInfo
									sims={{
										SimsimportInfo: this.state.importInfo,
										onClose: this.handleCloseImportInfo,
										fileTemplate: [
											{ name: 'Mobile Number', requestTitle: 'mobileNumber', type: 'number' },
											{ name: 'SIM Number', requestTitle: 'simNumber', type: 'number' },
											{ name: 'IMSI Number', requestTitle: 'imsiNumber', type: 'number' },
											{ name: 'Service Provider', requestTitle: 'serviceProvider', type: 'string' },
											{ name: 'Note', requestTitle: 'note', type: 'string' },
										],
									}}
								/>
							)}
						</>
					)}
					{/*Export*/}

					{/*<Button*/}
					{/*	primary*/}
					{/*	disabled={*/}
					{/*		!this.state.anyRowSelected || this.props.loading || !this.props.sims.length || this.props.exportingData*/}
					{/*	}*/}
					{/*	title={!this.state.anyRowSelected ? 'Please select atleast one sim to export' : 'Export Sims'}*/}
					{/*	onClick={() => {*/}
					{/*		openExportModal();*/}
					{/*		dispatch('SET_SIMS_EXPORT_DATA', this.export());*/}
					{/*	}}>*/}
					{/*	Export*/}
					{/*</Button>*/}

					{/*Export All*/}
					<Button.Group>
						<Button
							primary
							title="Export All Sims"
							disabled={this.props.loading || !this.props.sims.length || this.props.exportingData}
							onClick={() => exportAll(this.getFilterListQuery())}>
							Export
						</Button>
						<Dropdown
							className="button icon"
							style={{ background: '#ffffff', color: '#1b3c71', borderColor: '#1b3c71' }}
							floating
							value={this.state.fileExtension}
							options={FILE_TYPE_EXTENSIONS}
							onChange={this.fileExtensionChange}
							trigger={<></>}
						/>
					</Button.Group>

					<ToggleSwitch
						title={this.deletedMode ? 'Hide Deleted Sims' : 'Show Deleted Sims'}
						label="Deleted Mode"
						checked={this.deletedMode}
						disabled={this.props.loading || this.props.exportingData}
						action={this.toggleDeletedMode}
					/>
				</CategoryHeader>

				<CategoryMain>
					<AccordionHeader background={this.deletedMode}>
						{/*<HeaderElement*/}
						{/*	style={{ marginRight: 0 }}*/}
						{/*	width="3%"*/}
						{/*	disabled={this.props.loading || this.props.exportingData || !this.props.sims.length}>*/}
						{/*	<CheckboxContainer>*/}
						{/*		<Checkbox*/}
						{/*			checked={this.props.sims.length !== 0 && this.state.selectedRows.size === this.props.sims.length}*/}
						{/*			indeterminate={*/}
						{/*				this.state.selectedRows.size !== 0 && this.state.selectedRows.size !== this.props.sims.length*/}
						{/*			}*/}
						{/*			onClick={(e, data) => {*/}
						{/*				this.handleSelectAll(data.checked);*/}
						{/*			}}*/}
						{/*		/>*/}
						{/*	</CheckboxContainer>*/}
						{/*</HeaderElement>*/}
						<HeaderElement
							style={{ marginRight: 0 }}
							width="7%"
							disabled={this.props.loading || this.props.exportingData || !this.props.sims.length}
							onClick={() => {
								this.sort('', '', '');
							}}>
							No.
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="20%"
							onClick={() => {
								this.sort('simNumber', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.sims.length}
							activeSort={this.activeSort === 'simNumber'}>
							{this.props.activeSort === 'simNumber' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Sim Number
						</HeaderElement>
						<HeaderElement
							width="15%"
							style={{ marginRight: 0 }}
							onClick={() => {
								this.sort('mobileNumber', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.sims.length}
							activeSort={this.activeSort === 'mobileNumber'}>
							{this.props.activeSort === 'mobileNumber' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Mobile Number
						</HeaderElement>
						<HeaderElement
							width="12%"
							style={{ marginRight: 0 }}
							onClick={() => {
								this.sort('serviceProvider', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.sims.length}
							activeSort={this.activeSort === 'serviceProvider'}>
							{this.props.activeSort === 'serviceProvider' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Provider
						</HeaderElement>
						{/*<HeaderElement*/}
						{/*  width="23%"*/}
						{/*  disabled={this.props.loading || this.props.exportingData || !this.props.sims.length}*/}
						{/*  onClick={() => {*/}
						{/*    this.sort('simPlan.planName', this.props.activeSort, this.props.sortOrder);*/}
						{/*  }}*/}
						{/*  activeSort={this.activeSort === 'simPlan.planName'}>*/}
						{/*  {this.props.activeSort === 'simPlan.planName' ? (*/}
						{/*    this.props.sortOrder === 'asc' ? (*/}
						{/*      <Icon name="sort alphabet down" />*/}
						{/*    ) : (*/}
						{/*      <Icon name="sort alphabet up" />*/}
						{/*    )*/}
						{/*  ) : (*/}
						{/*    <Icon name="sort" />*/}
						{/*  )}*/}
						{/*  Plan*/}
						{/*</HeaderElement>*/}
						{/*<HeaderElement*/}
						{/*  width="10%"*/}
						{/*  style={{textAlign: 'center'}}*/}
						{/*  disabled={this.props.loading || this.props.exportingData || !this.props.sims.length}*/}
						{/*  onClick={() => {*/}
						{/*    this.sort('simPlan.rental', this.props.activeSort, this.props.sortOrder);*/}
						{/*  }}*/}
						{/*  activeSort={this.activeSort === 'simPlan.rental'}>*/}
						{/*  {this.props.activeSort === 'simPlan.rental' ? (*/}
						{/*    this.props.sortOrder === 'asc' ? (*/}
						{/*      <Icon name="sort alphabet down" />*/}
						{/*    ) : (*/}
						{/*      <Icon name="sort alphabet up" />*/}
						{/*    )*/}
						{/*  ) : (*/}
						{/*    <Icon name="sort" />*/}
						{/*  )}*/}
						{/*  Rental*/}
						{/*</HeaderElement>*/}
						<HeaderElement
							width="12%"
							disabled={this.props.loading || this.props.exportingData || !this.props.sims.length}
							onClick={() => {
								this.sort('status', this.props.activeSort, this.props.sortOrder);
							}}
							activeSort={this.activeSort === 'status'}>
							{this.props.activeSort === 'status' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Status
						</HeaderElement>

						<HeaderElement
							width="12%"
							disabled={this.props.loading || this.props.exportingData || !this.props.sims.length}
							// onClick={() => {
							// 	this.sort('', this.props.activeSort, this.props.sortOrder);
							// }}
							// activeSort={this.activeSort === ''}
						>
							{/*{this.props.activeSort === '' ? (*/}
							{/*	this.props.sortOrder === 'asc' ? (*/}
							{/*		<Icon name="sort alphabet down" />*/}
							{/*	) : (*/}
							{/*		<Icon name="sort alphabet up" />*/}
							{/*	)*/}
							{/*) : (*/}
							{/*	<Icon name="sort" />*/}
							{/*)}*/}
							IMEI
						</HeaderElement>

						{this.deletedMode && (
							<HeaderElement
								style={{ marginRight: 0 }}
								width="10%"
								disabled={this.props.loading || this.props.exportingData || !this.props.sims.length}>
								Deleted date
							</HeaderElement>
						)}
					</AccordionHeader>

					<AccordionMain id="AccordionMain">
						{this.state.initialLoading ? (
							<BasicLoader />
						) : this.props.sims.length ? (
							<Accordion fluid>
								{this.props.sims.map((sim, index) => {
									return (
										<section key={index}>
											<Accordion.Title
												active={this.props.activeIndex === index}
												index={index}
												onClick={(event, data) => {
													toggleAccordion(event, data, this.props.activeIndex);
													this.cleanEditOperation();
												}}
												style={{ padding: 0 }}>
												<Row>
													{/*<RowElement style={{ marginRight: 0 }} width="3%">*/}
													{/*	<CheckboxContainer>*/}
													{/*		<Checkbox*/}
													{/*			checked={this.state.selectedRows.has(sim.id)}*/}
													{/*			onChange={(e, data) => {*/}
													{/*				e.stopPropagation();*/}
													{/*				this.handleExportSelection(data.checked, sim.id);*/}
													{/*			}}*/}
													{/*		/>*/}
													{/*	</CheckboxContainer>*/}
													{/*</RowElement>*/}
													<RowElement width="7%" style={{ marginRight: 0 }}>
														{1 + index + (this.props.pageNumber - 1) * this.props.dataSizeLimit}
													</RowElement>
													<RowElement
														width="20%"
														style={{
															marginRight: 0,
															color: `${sim.simNumber ? 'black' : 'grey'}`,
														}}
														title={sim.simNumber}>
														{sim.simNumber ? sim.simNumber : 'Not Available'}
													</RowElement>
													<RowElement
														width="15%"
														style={{
															marginRight: 0,
															color: `${sim.mobileNumber ? 'black' : 'grey'}`,
														}}
														title={sim.mobileNumber}>
														{sim.mobileNumber ? sim.mobileNumber : 'Not Available'}
													</RowElement>
													<RowElement
														width="12%"
														style={{
															marginRight: 0,
															color: `${sim.serviceProvider ? 'black' : 'grey'}`,
														}}
														title={sim.serviceProvider}>
														{sim.serviceProvider ? sim.serviceProvider : 'Not Available'}
													</RowElement>
													{/*<RowElement width="23%" title={sim.simPlan.planName}>*/}
													{/*  {sim.simPlan.planName}*/}
													{/*</RowElement>*/}
													{/*<RowElement width="10%" style={{textAlign: 'center'}} title={sim.rental}>*/}
													{/*  {sim.rental}*/}
													{/*</RowElement>*/}

													<div
														style={{ width: '12%' }}
														onClick={() => navigator.clipboard.writeText(sim.deviceImei)}
														onDoubleClick={() => this.handleDoubleClick(sim.deviceImei)}>
														<RowElement
															width="100%"
															style={{
																marginRight: 0,
																color: `${sim.status ? 'black' : 'grey'}`,
															}}
															title={
																sim.status === SIM_STATUS.New
																	? 'New'
																	: // : sim.status === SIM_STATUS['No Data']
																	// ? 'No Data'
																	sim.status === SIM_STATUS.Device
																	? 'Device'
																	: sim.status === SIM_STATUS.Terminated
																	? 'Terminated'
																	: ''
															}>
															{sim.status === SIM_STATUS.New && (
																<Label size="small" style={{ minWidth: '60%', textAlign: 'center' }} color="red">
																	New
																</Label>
															)}
															{/*{sim.status === SIM_STATUS['No Data'] && (*/}
															{/*  <Label size="small" color="grey">*/}
															{/*    No Data*/}
															{/*  </Label>*/}
															{/*)}*/}
															{sim.status === SIM_STATUS.Device && (
																<Label size="small" style={{ minWidth: '60%', textAlign: 'center' }} color="green">
																	Device
																</Label>
															)}
															{sim.status === SIM_STATUS.Terminated && (
																<Label size="small" style={{ minWidth: '60%', textAlign: 'center' }} color="black">
																	Terminated
																</Label>
															)}
														</RowElement>
													</div>

													<RowElement
														width="12%"
														style={{
															marginRight: 0,
															color: `${sim.deviceImei ? 'black' : 'grey'}`,
														}}
														title={sim.imei}>
														{sim?.deviceImei ? sim?.deviceImei : 'Not Available'}
													</RowElement>

													{this.deletedMode && (
														<RowElement
															width="10%"
															style={{
																marginRight: 0,
																color: `${sim.deletedDate ? 'black' : 'grey'}`,
															}}
															title={sim.deletedDate}>
															{sim.deletedDate ? sim.deletedDate : 'Not Available'}
														</RowElement>
													)}
												</Row>
											</Accordion.Title>
											{this.props.activeIndex === index && (
												<Accordion.Content active={this.props.activeIndex === index}>
													<AccordionMainContent>
														<Form>
															<Form.Group widths="equal">
																<Form.Field>
																	<label>Note</label>
																	<Input
																		fluid
																		value={
																			this.state.noteFE !== null
																				? this.state.noteFE
																				: sim.note === null
																				? ''
																				: sim.note /* === null ? '' : product.sku*/
																		}
																		disabled={!this.props.editMode}
																		onChange={(e) => this.setState({ noteFE: e.target.value })}
																	/>
																</Form.Field>
															</Form.Group>
														</Form>

														{this.state.deletedMode ? (
															<Actions>
																<Button
																	primary
																	// disabled={!(this.state.manageAccess || this.state.createAccess)}
																	onClick={() => {
																		dispatchNoPayload('SIMS_RESTORE_MODAL_OPEN');
																		this.simID = sim.mobileNumber;
																	}}>
																	Restore
																</Button>
															</Actions>
														) : this.props.editMode ? (
															<Actions>
																<Button secondary onClick={this.cleanEditOperation}>
																	Cancel
																</Button>
																<Button
																	positive
																	loading={this.props.updating}
																	onClick={() => {
																		trackMixpanelEvent('Sim', { Action: 'Update' });
																		this.update(sim.mobileNumber);
																	}}>
																	Update
																</Button>
															</Actions>
														) : (
															<LeftActions>
																<div>
																	{/*<Button*/}
																	{/*  icon*/}
																	{/*  title="See Sim Change History"*/}
																	{/*  onClick={() => {*/}
																	{/*    dispatchNoPayload('SIMS_CHANGE_HISTORY_MODAL_OPEN');*/}
																	{/*    this.simID = sim.mobileNumber;*/}
																	{/*    this.getChangeHistory(sim.id);*/}
																	{/*  }}>*/}
																	{/*  <Icon name="history" />*/}
																	{/*</Button>*/}
																</div>
																<section>
																	<Button
																		primary
																		// disabled={!(this.state.manageAccess || this.state.updateAccess)}
																		onClick={() => {
																			trackMixpanelEvent('Sim', { Action: 'Edit' });
																			dispatchNoPayload('SIMS_EDIT_MODAL_OPEN');
																		}}>
																		Edit
																	</Button>
																	{sim.status !== SIM_STATUS.Device && (
																		<Button
																			negative
																			// disabled={sim.status === SIM_STATUS.Device}
																			// disabled={!(this.state.manageAccess || this.state.deleteAccess)}
																			title={`${sim.status === SIM_STATUS.Device ? 'Sim is Mapped to Device' : ''}`}
																			onClick={() => {
																				trackMixpanelEvent('Sim', { Action: 'Delete' });
																				dispatchNoPayload('SIMS_DELETE_MODAL_OPEN');

																				this.simID = sim.mobileNumber;
																			}}>
																			Delete
																		</Button>
																	)}
																</section>
															</LeftActions>
														)}
													</AccordionMainContent>
												</Accordion.Content>
											)}
										</section>
									);
								})}
							</Accordion>
						) : (
							<PlaceHolder />
						)}
					</AccordionMain>
				</CategoryMain>

				<CategoryFooter background={this.deletedMode}>
					<FooterPagination
						handleDataSizeLimitChange={this.handleDataSizeLimitChange}
						pageNumber={this.props.pageNumber}
						handlePageChange={this.handlePageChange}
						count={this.props.count}
						disabled={this.props.loading || !this.props.sims.length || this.props.exportingData}
					/>
				</CategoryFooter>

				{this.props.loading && !this.state.initialLoading && <TransitionLoader />}

				{this.props.addModalOpen && (
					<AddModal
						onClose={this.cleanAddOperation}
						open={this.props.addModalOpen}
						mobileNumber={this.state.mobileNoFA}
						onMobileNumberChange={this.onMobileNumberChange}
						simNumber={this.state.simNoFA}
						onSimNumberChange={this.onSimNumberChange}
						imsiNumber={this.state.imsiNoFA}
						onImsiNumberChange={this.onImsiNumberChange}
						onServiceProviderChange={this.onServiceProviderChange}
						// onSimTypeChange={this.onSimTypeChange}
						onNoteChange={this.onNoteChange}
						onCancelClick={this.cleanAddOperation}
						onAddButtonClick={this.add}
						disabled={!this.state.mobileNoFA || !this.state.simNoFA || !this.state.serviceProviderFA}
						addButtonLoading={this.props.adding}
					/>
				)}

				{this.props.deleteModalOpen && (
					<DeleteModal
						onClose={this.cleanDeleteOperation}
						open={this.props.deleteModalOpen}
						onCancelClick={this.cleanDeleteOperation}
						onDeleteButtonClick={() => {
							this.deleteSim(this.simID);
						}}
						deleteButtonLoading={this.props.deleting}
					/>
				)}

				{this.props.importModalOpen && (
					<Import
						template={this.fileTemplate}
						upload={this.importSims}
						file={this.inputData}
						simsResponse={this.state.simResponse}
						closeImport={this.cleanImportOperation}
					/>
				)}

				{this.props.restoreModal && (
					<RestoreModal
						onClose={this.cleanRestoreOperation}
						open={this.props.restoreModal}
						onCancelClick={this.cleanRestoreOperation}
						onRestoreButtonClick={() => {
							this.restoreSim(this.simID);
						}}
						restoreButtonLoading={this.props.restoring}
					/>
				)}

				{/*Filter Modal*/}
				{this.props.filterModalOpen && (
					<FilterModal
						onClose={() => {
							this.setFilterChange(this.activeFilters.status);
							closeFilterModal();
						}}
						open={this.props.filterModalOpen}
						status={this.state.filters.status}
						onStatusChange={this.onStatusChange}
						onCancelClick={() => {
							this.setFilterChange(this.activeFilters.status);
							closeFilterModal();
						}}
						disabled={!this.state.filters.status.length}
						onResetClick={() => {
							this.activeFilters = {
								status: [],
							};
							this.setFilterChange([]);
							// closeFilterModal();
							this.getFilteredSims();
						}}
						onApplyClick={() => {
							this.activeFilters = this.state.filters;
							this.getFilteredSims();
							closeFilterModal();
						}}
					/>
				)}

				{this.props.openExportModal && (
					<ExportModal
						open={this.props.openExportModal}
						fileName={this.state.fileName}
						fileNameChange={this.fileNameChange}
						fileExtension={this.state.fileExtension}
						fileExtensionChange={this.fileExtensionChange}
						onDownloadClick={() => exportData(this.props.exportData, this.state.fileName, this.state.fileExtension)}
					/>
				)}

				{this.props.changeHistoryModal && (
					<TimeLineViewModal
						onClose={this.cleanChangeHistoryOperation}
						loading={this.state.changeHistoryLoading}
						open={this.props.changeHistoryModal}
						header={this.simID}
						data={this.state.changeHistory}
						onCancelClick={this.cleanChangeHistoryOperation}
					/>
				)}
			</CategoryContentMembrane>
		);
	}
}

// Connect to Store
const mapStateToProps = (state) => ({
	sims: state.sims.sims,
	loading: state.sims.loading,
	activeIndex: state.sims.activeIndex,
	count: state.sims.count,
	pageNumber: state.sims.pageNumber,
	dataSizeLimit: state.ui.dataSizeLimit,

	adding: state.sims.adding,
	addModalOpen: state.sims.addModalOpen,

	editMode: state.sims.editMode,
	updating: state.sims.updating,

	deleteModalOpen: state.sims.deleteModalOpen,
	deleting: state.sims.deleting,

	activeSort: state.sims.activeSort,
	sortOrder: state.sims.sortOrder,

	restoreModal: state.sims.restoreModal,
	restoring: state.sims.restoring,

	importModalOpen: state.sims.importModalOpen,
	filterModalOpen: state.sims.filterModalOpen,
	changeHistoryModal: state.sims.changeHistoryModal,

	openExportModal: state.sims.openExportModal,
	exportData: state.sims.exportData,
	exportingData: state.sims.exportingData,
	searchQuery: state.vehicles.searchQuery,
});

export default connect(mapStateToProps)(SIM);
