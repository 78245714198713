import React from 'react';

import { ButtonContainer, DetailsHeader, ExcelDataRow, HeaderElement, Row, RowElement } from './ImportStyles';

import * as XLSX from 'xlsx';
import stringSimilarity from 'string-similarity';

import { Button, Dropdown, Icon, Label, Modal } from 'semantic-ui-react';
import { ORGANISATIONS_API, USERS_API, VEHICLES_API } from '../../../logic/configs/api';
import { getData } from '../../../logic/middleware/services/apiRequest';
import { handleError } from '../../../logic/middleware/messaging';
import { CSVLink } from 'react-csv';
import { dispatch } from '../../../logic/middleware/middleware';
import { connect } from 'react-redux';

class StudentImport extends React.Component {
	fileTemplate = [];
	headers;
	rowData;
	formattedData = [];
	allData = [];
	// parentId;
	finalData = {};
	studentsData = [];

	vehicleDropdownData = [];
	parentsDropdownData = [];

	constructor(props) {
		super(props);
		this.errorFocusRefs = [];

		this.state = {
			loading: false,
			// submitting: false,

			templateModalOpen: false,
			excelDataModalOpen: false,
			selectedHeaders: [],
			contactUpdate: '',
			// parentID: '',
			orgDropdownData: [],

			pingState: true,
			mapping: false,
		};

		this.closeTemplateModal = this.closeTemplateModal.bind(this);
		this.getDropdownOptions = this.getDropdownOptions.bind(this);
		this.closeExcelDataModal = this.closeExcelDataModal.bind(this);
		this.pingState = this.pingState.bind(this);
		this.sendMessages = this.sendMessages.bind(this);
		this.addRow = this.addRow.bind(this);
		this.removeRow = this.removeRow.bind(this);
		this.error = this.error.bind(this);
		this.success = this.success.bind(this);
		this.isValidContactNumber = this.isValidContactNumber.bind(this);
		this.hasInvalidData = this.hasInvalidData.bind(this);
		this.isValidName = this.isValidName.bind(this);
		this.isValidVehicleNo = this.isValidVehicleNo.bind(this);
		this.isValidOrganisation = this.isValidOrganisation.bind(this);
	}

	parseFile(file) {
		this.setState({
			loading: true,
		});

		const fileReader = new FileReader();
		const readAsBinaryString = !!fileReader.readAsBinaryString;

		fileReader.onload = (e) => {
			const binaryString = e.target.result;
			const workbook = XLSX.read(binaryString, {
				type: readAsBinaryString ? 'binary' : 'array',
				// cellDates: true,
			});

			const worksheetName = workbook.SheetNames[0];
			const workSheet = workbook.Sheets[worksheetName];

			const data = XLSX.utils.sheet_to_json(workSheet, {
				header: 1,
				blankrows: false,
				defval: '',
				raw: false,
			});

			this.mapData(data);
		};

		if (readAsBinaryString) fileReader.readAsBinaryString(file);
		else fileReader.readAsArrayBuffer(file);
	}

	componentDidMount() {
		if (this.props.template) this.fileTemplate = this.props.template;

		if (this.props.file) {
			this.parseFile(this.props.file);
		}

		getData(VEHICLES_API, '?dropdown=true')
			.then((response) => {
				this.vehicleDropdownData = response?.data?.data;
			})
			.catch((err) => handleError(err));

		getData(ORGANISATIONS_API, '?dropdown=true')
			.then((response) => {
				this.orgDropdownData = response?.data?.data;
			})
			.catch((err) => handleError(err));

		getData(USERS_API, '?type[]=Parent&dropdown=true')
			.then((response) => {
				this.parentsDropdownData = response?.data?.data;
			})
			.catch((error) => {
				handleError(error);
			});
	}

	componentWillUnmount() {
		if (this.props.closeImport) {
			this.props.closeImport();
		}

		this.cleanMappedDataVariable();
		this.closeExcelDataModal();
		this.closeTemplateModal();
		this.cleanAllDataVariable();
		this.vehicleDropdownData = [];

		this.setState({
			contactUpdate: '',
			orgDropdownData: [],
		});
	}

	mapData(data) {
		let headers = data[0];
		let rowData = data.slice(1);

		this.headers = headers;
		this.rowData = rowData;
		this.setState({
			loading: false,
		});

		this.smartFill();
		this.openTemplateModal();
	}

	smartFill() {
		let selectedHeaders = [];
		this.fileTemplate.forEach((template, index) => {
			let targetIndex = 0;
			let maxWeight = 0;
			this.headers &&
				this.headers.forEach((header, localIndex) => {
					let weight = stringSimilarity.compareTwoStrings(template.name, header ? header.toString() : '');
					if (weight > maxWeight) {
						maxWeight = weight;
						targetIndex = localIndex;
					}
				});

			if (maxWeight > 0.5) {
				selectedHeaders = Object.assign([], selectedHeaders, {
					[index]: this.headers[targetIndex],
				});
			}
		});

		this.setState({ selectedHeaders });
	}

	openTemplateModal() {
		this.setState({
			templateModalOpen: true,
		});
	}

	closeTemplateModal() {
		this.setState({
			templateModalOpen: false,
		});
		// this.props.closeImport();

		if (this.props.closeImport) {
			this.props.closeImport();
		}
	}

	getDropdownOptions(origin) {
		const options = [];
		this.headers &&
			this.headers.forEach((value, index) => {
				if (value === this.state.selectedHeaders[origin] || this.state.selectedHeaders.indexOf(value) === -1) {
					options.push({ key: index, value: value, text: value });
				}
			});

		return options;
	}

	formatData() {
		this.rowData.forEach((row) => {
			let data = [];

			this.fileTemplate.forEach((column, index) => {
				data.push(row[this.headers.indexOf(this.state.selectedHeaders[index])]);
			});

			this.formattedData.push(data);
		});
		this.allData = this.formattedData;
	}

	openExcelDataModal() {
		this.setState({
			templateModalOpen: false,
			excelDataModalOpen: true,
			mapping: false,
		});
	}

	closeExcelDataModal() {
		this.setState({
			excelDataModalOpen: false,
		});
		this.formattedData = [];
		if (this.props.closeImport) {
			this.props.closeImport();
		}
	}

	isEmpty(value) {
		return value === '' || value === undefined;
	}

	pingState() {
		this.setState({
			pingState: !this.state.pingState,
		});
	}

	async convertToJson() {
		// let orgData;
		// let vehicleData;
		try {
			this.finalData = this.allData.map((value) => {
				let data = {};
				this.fileTemplate.forEach((template, localIndex) => {
					if (value[localIndex]) {
						Object.assign(data, {
							[template.requestTitle]: value[localIndex].toString(),
						});
					}
				});
				return data;
			});

			let modifiedFinalData = [];

			this.allData.map((eachRow) => {
				let orgId = this.orgDropdownData.filter((obj) => eachRow[7] === obj.name);
				let pickupBusId = this.vehicleDropdownData.filter(
					(obj) => eachRow[1].replaceAll(/\s/g, '') === obj.registrationNumber.replaceAll(/\s/g, '')
				);
				let parentId = this.parentsDropdownData.filter((obj) => eachRow[6].split(',').includes(obj.phone?.toString()));
				if (pickupBusId[0]?.id && orgId[0]?.id && parentId[0]?.id) {
					modifiedFinalData.push({
						...eachRow,
						organisation: { id: orgId[0]?.id },
						pickupBus: { id: pickupBusId[0]?.id },
						parents: [{ id: parentId[0]?.id }],
					});
				}
			});
			this.props.upload({
				data: modifiedFinalData,
				success: this.success,
				error: this.error,
			});
		} catch (error) {
			handleError(error);
		}
	}

	success() {
		if (this.props.studentsResponse?.failedObjects.length === 0) {
			this.cleanAllDataVariable();
			this.closeExcelDataModal();
		}
		// this.setState({
		// 	submitting: false,
		// });
	}

	error() {
		// this.setState({
		// 	submitting: false,
		// });
	}

	sendMessages() {
		dispatch('BULK_IMPORT_LOAD', true);
		// this.setState({
		// 	submitting: true,
		// });
		this.convertToJson();
	}

	enterManually() {
		this.allData.push(new Array(this.fileTemplate.length).fill(''));
		this.pingState();
	}

	addRow() {
		this.allData.push(new Array(this.fileTemplate.length).fill(''));
		this.pingState();
	}

	removeRow(index) {
		this.allData.splice(index, 1);
		this.pingState();
	}

	isValidContactNumber(number) {
		number = number.split(',');
		const formattedContacts = number.map((number) => number.replaceAll(/\s/g, '').toString());
		const parentId = this.parentsDropdownData.filter((obj) => {
			return number.includes(obj.phone?.toString());
		});

		this.parentOrgId = parentId[0]?.organisation.id;
		return (
			formattedContacts.every((contact) => contact.length === 10 && /^-?\d+$/.test(contact)) && parentId.length > 0
		);
	}

	isValidName(studentName) {
		return studentName !== '';
	}

	isValidVehicleNo(vehicleNo) {
		const verifiedVehicleNo = this.vehicleDropdownData.filter(
			(obj) => vehicleNo.replaceAll(/\s/g, '') === obj.registrationNumber.replaceAll(/\s/g, '')
		);
		return verifiedVehicleNo[0]?.registrationNumber !== undefined;
	}

	isValidOrganisation(orgName) {
		if (this.isSuperAdminAccess === 'false') {
			const localStorageOrg = localStorage.getItem('organisationName');
			return localStorageOrg.toLowerCase() === orgName?.toLowerCase();
		} else {
			const verifiedOrg = this.orgDropdownData.filter((obj) => orgName?.toLowerCase() === obj?.name.toLowerCase());
			// return verifiedOrg?.length > 0;
			return verifiedOrg[0]?.id === this.parentOrgId;
		}
	}

	hasInvalidData() {
		let errorIdx;
		const invalidData = this.allData.filter((row, idx) => {
			if (
				(errorIdx === undefined && !this.isValidContactNumber(row[6])) ||
				!this.isValidOrganisation(row[7]) ||
				!this.isValidName(row[2]) ||
				!this.isValidVehicleNo(row[1])
			) {
				errorIdx = idx;
			}

			return (
				!this.isValidContactNumber(row[6]) ||
				!this.isValidOrganisation(row[7]) ||
				!this.isValidName(row[2]) ||
				!this.isValidVehicleNo(row[1])
			);
		});
		if (invalidData.length > 0) {
			const firstInvalidInputRef = this.errorFocusRefs[(errorIdx + 4) * 4];
			firstInvalidInputRef?.current?.focus({ preventScroll: false });
		}
		return invalidData.length > 0;
	}

	hasDuplicatesContacts() {
		let hasInvalidContact = false;
		let seen = {};
		this.allData.flatMap((row) => {
			if (!this.isValidContactNumber(row[6])) {
				hasInvalidContact = true;
			}
			if (row[6] in seen && seen[row[6]] === row[2]) {
				hasInvalidContact = true;
			}
			seen[row[6]] = row[2];
			return row[6];
		});

		return hasInvalidContact;
	}

	// hasDuplicateContact(contactNumber, currIdx) {
	// 	const allContacts = this.allData.map((row) => row[6]);
	//
	// 	const contactNumbers = contactNumber.split(',').map((number) => number.trim());
	//
	// 	for (let i = 0; i < allContacts.length; i++) {
	// 		if (i !== currIdx) {
	// 			const rowContacts = allContacts[i].split(',').map((number) => number.trim());
	// 			if (rowContacts.some((contact) => contactNumbers.includes(contact))) {
	// 				return true;
	// 			}
	// 		}
	// 	}
	//
	// 	return false;
	// }

	hasInvalidVehicles() {
		const invalidVehicles = this.allData.filter((row) => !this.isValidVehicleNo(row[1]));
		return invalidVehicles ? invalidVehicles[0]?.length > 0 : false;
	}

	hasInvalidStudentName() {
		const invalidNames = this.allData.filter((row) => !this.isValidName(row[2]));
		return invalidNames ? invalidNames[0]?.length > 0 : false;
	}

	hasInvalidOrgNames() {
		const invalidOrgs = this.allData.filter((row) => !this.isValidOrganisation(row[7]));
		return invalidOrgs ? invalidOrgs[0]?.length > 0 : false;
	}

	cleanAllDataVariable() {
		this.rowData = [];
		this.headers = [];
		this.finalData = {};
		this.formattedData = [];
		this.allData = [];
		this.setState({
			selectedHeaders: [],
		});
	}

	cleanMappedDataVariable() {
		this.rowData = [];
		this.headers = [];
		this.setState({
			selectedHeaders: [],
		});
	}

	render() {
		return (
			<>
				{this.state.templateModalOpen && (
					<Modal
						onClose={() => {
							this.closeTemplateModal();
							this.cleanMappedDataVariable();
						}}
						open={this.state.templateModalOpen}>
						<Modal.Header>
							Map Data
							{/*<span style={{ fontSize: '1rem', color: 'red', float: 'right' }}>*/}
							{/*	Fields marked with red are mandatory*/}
							{/*</span>*/}
						</Modal.Header>
						<Modal.Content>
							{this.fileTemplate.map((name, index) => {
								const requiredFields = ['Student Name', 'Organisation', 'Pick & Drop Bus', 'Parent Contact Number'];
								const isRequired = requiredFields.includes(name.name);
								const hasError = this.state.selectedHeaders[index] !== name.name;
								const isEmpty = this.state.selectedHeaders[index] === '';

								return (
									<Row key={index}>
										{isRequired ? (
											<>
												<div style={{ color: 'red' }}>*</div>
												<RowElement style={{ color: hasError || isEmpty ? 'red' : 'black' }}>{name.name}</RowElement>
											</>
										) : (
											<RowElement style={{ color: hasError && !isEmpty ? 'red' : 'black' }}>{name.name}</RowElement>
										)}

										<RowElement>
											<Dropdown
												error={isRequired ? hasError || isEmpty : hasError && !isEmpty}
												placeholder="Choose..."
												fluid
												selection
												clearable
												options={this.getDropdownOptions(index)}
												value={this.state.selectedHeaders[index]}
												onChange={(e, { value }) => {
													this.setState({
														selectedHeaders: Object.assign([], this.state.selectedHeaders, { [index]: value }),
													});
												}}
											/>

											{hasError && !isEmpty && (
												<Label basic color="red" pointing>
													Please enter correct header
												</Label>
											)}

											{isRequired && isEmpty && (
												<Label basic color="red" pointing>
													Required field cannot be empty
												</Label>
											)}
										</RowElement>
									</Row>
								);
							})}
						</Modal.Content>
						<Modal.Actions>
							<Button
								secondary
								color="black"
								onClick={() => {
									this.closeTemplateModal();
									this.cleanMappedDataVariable();
								}}>
								Cancel
							</Button>
							<Button
								loading={this.state.mapping}
								disabled={
									!this.state.selectedHeaders.includes('Pick & Drop Bus') ||
									!this.state.selectedHeaders.includes('Student Name') ||
									!this.state.selectedHeaders.includes('Organisation') ||
									!this.state.selectedHeaders.includes('Parent Contact Number')
								}
								onClick={() => {
									this.setState({ mapping: true });
									setTimeout(() => {
										this.formatData();
										this.openExcelDataModal();
									}, 1);
								}}
								positive>
								Map
							</Button>
						</Modal.Actions>
					</Modal>
				)}

				{this.state.excelDataModalOpen && (
					<Modal
						onClose={() => {
							this.closeExcelDataModal();
							this.cleanAllDataVariable();
						}}
						open={this.state.excelDataModalOpen}>
						<Modal.Header>Delivery Details</Modal.Header>
						<DetailsHeader>
							<HeaderElement width="5%">Serial</HeaderElement>
							{this.fileTemplate.map((name, index) => {
								const isRequired = ['Student Name', 'Organisation', 'Vehicle No'].includes(name.name);
								return (
									<HeaderElement width={`${(90 / this.fileTemplate.length).toString()}%`} key={index}>
										{isRequired && <span style={{ color: 'red' }}>*</span>} {name.name}
									</HeaderElement>
								);
							})}
							<ButtonContainer />
						</DetailsHeader>
						{/*Padding*/}

						<div style={{ display: 'flex', gap: '1rem', justifyContent: 'center', textAlign: 'center' }}>
							{this.hasInvalidVehicles() ? <div style={{ color: 'red' }}>Please fill correct vehicle no</div> : ''}
							{this.hasInvalidStudentName() ? <div style={{ color: 'red' }}>Please fill correct student name</div> : ''}
							{this.hasDuplicatesContacts() ? (
								<div style={{ color: 'red' }}>
									Contact number has to be 10 digits and same number cannot to be given same student{' '}
								</div>
							) : (
								''
							)}
							{this.hasInvalidOrgNames() ? <div style={{ color: 'red' }}>Please fill correct org name</div> : ''}
						</div>
						<Modal.Content scrolling>
							<Modal.Description>
								{this.allData.map((row, index) => {
									return (
										<div key={index}>
											<ExcelDataRow>
												<>
													<label style={{ width: '5%', textAlign: 'center' }}>{index + 1}</label>
													{row.map((data, localIndex) => {
														const contactNumber = row[6];
														const studentName = row[2];
														const vehicleNo = row[1];
														const inputRef = React.createRef();
														const orgName = row[7];
														const inputField = (
															<input
																key={`${index},${localIndex},${data}`}
																defaultValue={data}
																style={{
																	width: `${(90 / this.fileTemplate.length).toString()}%`,
																	border:
																		!this.isValidContactNumber(contactNumber) ||
																		!this.isValidName(studentName) ||
																		!this.isValidOrganisation(orgName) ||
																		!this.isValidVehicleNo(vehicleNo) ||
																		// this.hasDuplicateContact(contactNumber, index) ||
																		this.hasDuplicatesContacts()
																			? `2px solid red`
																			: '',
																}}
																onChange={(e) => this.setState({ contactUpdate: e.target.value })}
																onBlur={(e) => {
																	this.allData[index][localIndex] = e.target.value;
																	this.setState({ contactUpdate: e.target.value });
																}}
															/>
														);
														this.errorFocusRefs.push(inputRef);
														return inputField;
													})}

													<ButtonContainer>
														<Button
															icon
															size="small"
															style={{ background: 'none' }}
															onClick={() => {
																this.removeRow(index);
															}}>
															<Icon name="close" />
														</Button>
													</ButtonContainer>
												</>
											</ExcelDataRow>
										</div>
									);
								})}
							</Modal.Description>
						</Modal.Content>
						<Modal.Actions
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}>
							<Button onClick={this.addRow}>Add Row</Button>
							<div style={{ display: 'flex', alignItems: 'center', gap: '.3em', fontWeight: 'bold' }}>
								{this.props.studentsResponse?.failedObjects.length > 0 ? (
									<div style={{ color: 'red' }}>{`${this.props.studentsResponse?.failedCount} Failed count`}</div>
								) : (
									''
								)}
								{this.props.studentsResponse?.failedObjects.length > 0 ? (
									<CSVLink
										data={this.props.studentsResponse?.failedObjects.map((obj) => {
											const modifiedObj = {};
											for (let key in obj) {
												if (Object.prototype.hasOwnProperty.call(obj, key)) {
													let value = obj[key];
													if (typeof value === 'object' && value !== null) {
														value = JSON.stringify(value);
													}
													const modifiedKey = key.charAt(0).toUpperCase() + key.slice(1);
													if (key === 'organisation') {
														const parsedValue = JSON.parse(value);
														value = parsedValue.organisation;
													}
													if (key === 'parents') {
														const parsedValue = JSON.parse(value);
														value = parsedValue[0].contactNumber;
													}
													if (key === 'pickupBus') {
														const parsedValue = JSON.parse(value);
														value = parsedValue.pickupBus;
													}
													modifiedObj[modifiedKey] = value;
												}
											}
											return modifiedObj;
										})}
										filename="Students.csv">
										<Button primary title={'Click to download the failed file'} icon="download" />
									</CSVLink>
								) : (
									''
								)}
							</div>
							<div>
								<Button
									color="black"
									onClick={() => {
										this.closeExcelDataModal();
										this.cleanAllDataVariable();
									}}>
									Cancel
								</Button>
								<Button
									onClick={this.sendMessages}
									positive
									loading={this.props.bulkimportLoading}
									disabled={this.hasInvalidData() || this.hasDuplicatesContacts()}>
									Submit
								</Button>
							</div>
						</Modal.Actions>
					</Modal>
				)}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		bulkimportLoading: state.students.bulkimportLoading,
	};
};

export default connect(mapStateToProps)(StudentImport);
