import React from 'react';
import CommonModal from '../../../molecules/modal/CommonModal';
import { Button, Dropdown, Form, Input } from 'semantic-ui-react';

const EditOrganisationModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="small"
			header={props.header}
			content={
				<Form>
					<Form.Group widths="equal">
						<Form.Field>
							<label>Current Organisation</label>
							<Input value={props.currentOrganisation} disabled />
						</Form.Field>
						<Form.Field>
							<label>New Organisation</label>
							<Dropdown
								placeholder="Choose ..."
								fluid
								search
								selection
								options={props.organisationOptions}
								value={props.selectedOrganisation}
								onChange={props.onOrganisationChange}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field required>
							<label>Vehicle Groups</label>
							<Dropdown
								placeholder="Choose ..."
								fluid
								search
								selection
								multiple
								disabled={!props.selectedOrganisation}
								options={props.vehicleGroupsOptions}
								value={props.selectedVehicleGroup}
								onChange={props.onVehicleGroupChange}
							/>
						</Form.Field>
					</Form.Group>
				</Form>
			}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button
						primary
						disabled={props.disabled}
						loading={props.changeButtonLoading}
						onClick={props.onChangeButtonClick}>
						Change
					</Button>
				</>
			}
		/>
	);
};

export default EditOrganisationModal;
