import React from 'react';
import {
	CHANGE_HISTORY_API,
	COMMANDS_PIPELINE_API,
	DEVICES_API,
	DEVICES_API_BULK,
} from '../../../../../logic/configs/api';

import SearchBox from '../../../blocks/atoms/search-box/SearchBox';
import BasicLoader from '../../../blocks/molecules/loader/BasicLoader';
import FooterPagination from '../../../blocks/molecules/pagination/Pagination';
import ToggleSwitch from '../../../blocks/molecules/toggle-switch/ToggleSwitch';
import AddModal from '../../../blocks/organisms/modals/devices/AddModal';
import DeleteModal from '../../../blocks/organisms/modals/devices/DeleteModal';
import RestoreModal from '../../../blocks/organisms/modals/devices/RestoreModal';
import RemoveSimModal from '../../../blocks/organisms/modals/devices/RemoveSimModal';
import SwapSimModal from '../../../blocks/organisms/modals/devices/SwapSimModal';
import ExportModal from '../../../blocks/organisms/modals/devices/ExportModal';
import PlaceHolder from '../../../blocks/molecules/place-holder/PlaceHolder';
// import VehicleLogs from '../../diagnostic-and-logs/VehicleLogs';
import FilterModal from '../../../blocks/organisms/modals/devices/FilterModal';
import { AccordionMainContent } from '../../../styles/containers/AccordionMainContent';
import { CategoryContentMembrane } from '../../../styles/containers/CategoryContentMembrane';
import { CategoryHeader } from '../../../styles/containers/CategoryHeader';
import { Expander } from '../../../styles/place-holders/Expander';
import { CategoryMain } from '../../../styles/containers/CategoryMain';
import { AccordionHeader } from '../../../styles/containers/AccordionHeader';
import { AccordionMain } from '../../../styles/containers/AccordionMain';
import { CategoryFooter } from '../../../styles/containers/CategoryFooter';
import TimeLineViewModal from '../../../blocks/organisms/modals/time-line-view/TimeLineViewModal';
import MessageTimeLine from '../../../blocks/organisms/modals/time-line/MessageTimeLine';
import TransitionLoader from '../../../blocks/molecules/loader/TransitionLoader';
import { EXCEL_FILE_TYPES, FILE_TYPE_EXTENSIONS } from '../../../../../data/constants/file-types';
import { DEVICE_STATUS_COLOR } from '../../../../../data/constants/statuses';
import { createChangeHistoryDTO } from '../../../../../logic/dto/changeHistory';
import { createCommandsPipelineDTO } from '../../../../../logic/dto/commandsPipeline';
import {
	closeFilterModal,
	exportAll,
	exportData,
	getDeviceModelsForDropdown,
	getSimsForDropdown,
	openFilterModal,
	refreshDevices,
	searchDevices,
	toggleAccordion,
} from '../../../../../logic/middleware/devices';
import { dispatch, dispatchNoPayload } from '../../../../../logic/middleware/middleware';
import { connect } from 'react-redux';
import { generateErrorMessage, generateSuccessMessage, handleError } from '../../../../../logic/middleware/messaging';
import { isoToHumanReadableAlternate } from '../../../../../logic/utilities/date';
import { validateMobileNumber } from '../../../../../logic/utilities/validator';
// import axios from 'axios';
// import { CheckboxContainer } from '../../../styles/Containers';
import { Actions, HeaderElement, LeftActions, Row, RowElement } from '../../../styles/Accordion';
import { Accordion, Button, Dropdown, Form, Icon, Input, Label } from 'semantic-ui-react';
import accessSetter, { entityAccessSetter } from '../../../../../logic/utilities/accessSetter';
import { get, getDatabase, onChildChanged, ref } from 'firebase/database';
import { searchVehicleGroups } from '../../../../../logic/middleware/vehicleGroups';
import { trackMixpanelEvent } from '../../../../../logic/middleware/mixpanel';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import { deleteData, getData, patchData, postData } from '../../../../../logic/middleware/services/apiRequest';

import { datadogLogs } from '@datadog/browser-logs';
import DeviceImport from '../../../../common/import/DeviceImport';
import { DateInput } from 'semantic-ui-calendar-react';
import ImportInfo from '../../../../common/ImportInfo/ImportInfo';

class Devices extends React.Component {
	// Firebase
	firebaseReferences = [];

	timeoutId;
	deviceNumber = '';

	fileTemplate = [
		{ name: 'Device Imei', requestTitle: 'imei', type: 'number' },
		{ name: 'Note', requestTitle: 'Note', type: 'string' },
		{ name: 'Device Manufacturer', requestTitle: 'manufacturer', type: 'string' },
		{ name: 'Serial Number', requestTitle: 'serialNo', type: 'number' },
		{ name: 'Invoice Number', requestTitle: 'purchaseInvoice', type: 'string' },
		{ name: 'Invoice Date', requestTitle: 'invoiceDate', type: 'date' },
	];
	SheetJSFT = ['xlsx', 'xls']
		.map(function (x) {
			return '.' + x;
		})
		.join(',');
	inputData;

	deletedMode = false;

	deviceId = '';
	selectAllActive = false;

	sortOrder = '';
	activeSort = '';
	// Status Filters
	activeStatus = {};
	// Change History
	deviceImei = '';
	simStatus = '';

	constructor(props) {
		super(props);

		// this.fileInputRef = React.createRef();

		this.state = {
			initialLoading: true,
			manageAccess: false,
			readAccess: false,
			createAccess: false,
			updateAccess: false,
			deleteAccess: false,
			readDiagnosticsAccess: false,
			manageDiagnosticsAccess: false,
			deleteDiagnosticsAccess: false,
			createDiagnosticsAccess: false,
			updateDiagnosticsAccess: false,
			importInfo: false,

			// Firebase
			lastLocation: {},

			// Export
			anyRowSelected: false,
			selectedRows: new Set(),

			deletedMode: false,

			imeiFA: null,
			deviceModels: [],
			deviceModelIdFA: null,
			serialNoFA: null,
			invoiceNumberFA: null,
			invoiceDateFA: null,
			// dateOfPurchaseFA: null,
			//supplierNameFA: null,
			noteFA: null,

			serialNo: null,
			invoiceNumber: null,
			//  dateOfPurchase: null,
			// supplierName: null,
			note: null,
			sim1: null,
			sim2: null,
			addSimMode: false,
			//SwapSIM
			simSwapImei: null,
			sim1SwapId: null,
			sim2SwapId: null,
			sim1OriginalID: null,
			sim2OriginalID: null,
			swapReason: null,
			simSwapTarget: 'sim1',
			sims: null,
			deviceResponse: null,

			//Remove
			simRemoveImei: null,
			sim1RemoveId: null,
			sim2RemoveId: null,
			simRemoveTarget: null,

			// Delete
			deleteDeviceImei: '',

			// Delete
			restoreDeviceImei: '',

			// Logs
			showLogsComponent: false,
			deviceDetails: null,

			// Change History
			changeHistory: null,
			changeHistoryLoading: false,

			commandsHistory: null,
			commandsHistoryLoading: false,

			unActiveStatus: [],
			invoiceStartFilter: '',
			invoiceEndFilter: '',

			fileName: 'Devices',
			fileExtension: 'pdf',
			searchQuery: '',
		};

		this.handleSearch = this.handleSearch.bind(this);
		this.cleanImportOperation = this.cleanImportOperation.bind(this);
		this.add = this.add.bind(this);
		this.cleanAddOperation = this.cleanAddOperation.bind(this);
		this.handleInputFile = this.handleInputFile.bind(this);
		this.importDevices = this.importDevices.bind(this);
		this.export = this.export.bind(this);
		this.toggleDeletedMode = this.toggleDeletedMode.bind(this);
		this.getFilteredDevices = this.getFilteredDevices.bind(this);
		this.update = this.update.bind(this);
		this.cleanEditOperation = this.cleanEditOperation.bind(this);
		this.swapSims = this.swapSims.bind(this);
		this.cleanSwapSimOperation = this.cleanSwapSimOperation.bind(this);
		this.removeSim = this.removeSim.bind(this);
		this.cleanRemoveSimOperation = this.cleanRemoveSimOperation.bind(this);
		this.deleteDevice = this.deleteDevice.bind(this);
		this.cleanDeleteOperation = this.cleanDeleteOperation.bind(this);
		this.restoreDevice = this.restoreDevice.bind(this);
		this.cleanRestoreOperation = this.cleanRestoreOperation.bind(this);
		this.handleDataSizeLimitChange = this.handleDataSizeLimitChange.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleExportSelection = this.handleExportSelection.bind(this);
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.handleSwapClick = this.handleSwapClick.bind(this);
		this.handleRemoveClick = this.handleRemoveClick.bind(this);
		this.sort = this.sort.bind(this);
		this.cleanSelectedRows = this.cleanSelectedRows.bind(this);
		this.closeLogsComponent = this.closeLogsComponent.bind(this);

		this.onStatusFilterChange = this.onStatusFilterChange.bind(this);
		this.setUnActiveFilterValue = this.setUnActiveFilterValue.bind(this);
		this.onStartDateFilterChange = this.onStartDateFilterChange.bind(this);
		this.onEndDateFilterChange = this.onEndDateFilterChange.bind(this);

		this.onImeiChangeFA = this.onImeiChangeFA.bind(this);
		this.onSerialNoChangeFA = this.onSerialNoChangeFA.bind(this);
		this.onDeviceModelChooseFA = this.onDeviceModelChooseFA.bind(this);
		this.onInvoiceNumberChangeFA = this.onInvoiceNumberChangeFA.bind(this);
		this.onInvoiceDateChangeFA = this.onInvoiceDateChangeFA.bind(this);
		// this.onDateOfPurchaseChange = this.onDateOfPurchaseChange.bind(this);
		// this.onSupplierNameChangeFA = this.onSupplierNameChangeFA.bind(this);
		this.onNoteChangeFA = this.onNoteChangeFA.bind(this);

		this.onSelectRemoveSim = this.onSelectRemoveSim.bind(this);

		this.getChangeHistory = this.getChangeHistory.bind(this);
		this.cleanChangeHistoryOperation = this.cleanChangeHistoryOperation.bind(this);
		this.cleanCommandsHistoryOperation = this.cleanCommandsHistoryOperation.bind(this);

		this.onSim1Select = this.onSim1Select.bind(this);
		this.onSim2Select = this.onSim2Select.bind(this);
		this.onSwapSimReasonSelect = this.onSwapSimReasonSelect.bind(this);

		this.fileExtensionChange = this.fileExtensionChange.bind(this);
		this.fileNameChange = this.fileNameChange.bind(this);
		this.manageAccessSetter = this.manageAccessSetter.bind(this);
		this.handleSearchQuery = this.handleSearchQuery.bind(this);
		this.handleDoubleClick = this.handleDoubleClick.bind(this);
		this.handleDeviceStatus = this.handleDeviceStatus.bind(this);
		this.handleClearDate = this.handleClearDate.bind(this);

		this.handleOpenImportInfo = this.handleOpenImportInfo.bind(this);
		this.handleCloseImportInfo = this.handleCloseImportInfo.bind(this);
	}

	manageAccessSetter(rule, action) {
		this.setState({ [action]: rule });
	}

	componentDidMount() {
		this.deviceId = this.props.location?.state?.deviceID;
		this.simStatus = this.props.location?.state?.simStatus;

		// if (this.props.location?.state?.deviceID) {
		// 	this.setState({ searchQuery: this.props.location?.state?.deviceID });
		// 	this.handleSearch({ target: { value: this.props.location?.state?.deviceID } });
		// }
		if (this.props.searchQuery) {
			dispatch('UPDATE_SEARCH_QUERY', this.props.searchQuery);
			this.handleSearch({ target: { value: this.props.searchQuery } });
			return;
		}

		if (this.deviceId) {
			this.setState({ searchQuery: this.deviceId });
			this.handleSearch({ target: { value: this.deviceId } });
			return;
		}
		if (this.simStatus) {
			this.setState({ searchQuery: this.simStatus });
			this.handleSearch({ target: { value: this.simStatus } });
			return;
		}

		let path = window.location.pathname.split('/');
		if (path.length > 3) {
			this.deviceNumber = decodeURIComponent(path[3]);

			if (this.deviceNumber) {
				this.setState({ searchQuery: this.deviceNumber });
				this.handleSearch({ target: { value: this.deviceNumber } });
				return;
			}
		}
		trackMixpanelEvent('Devices', { Action: 'View' });
		const permissions = JSON.parse(localStorage.getItem('permissions'));
		const devicesPermissions = permissions?.filter((permission) => permission.subject === 'Device And Sim');
		if (devicesPermissions) {
			for (const devicePrm of devicesPermissions) {
				accessSetter(devicePrm?.action, this.manageAccessSetter);
			}
		}
		const logsPermissions = permissions?.filter((permission) => permission.subject === 'Diagnostics');
		if (logsPermissions) {
			for (const logsPrm of logsPermissions) {
				entityAccessSetter(logsPrm?.action, 'Diagnostics', this.manageAccessSetter);
			}
		}

		refreshDevices();
		this.subscribeToFirebase(this.props.devices);
		window.history.replaceState(null, null, window.location.pathname);
	}

	// Fetch
	getFilteredDevices(activePage = 1, resetAccordion = true) {
		if (resetAccordion) {
			dispatch('DEVICES_TOGGLE_ACCORDION', -1);
		}

		let payload = '';

		if (this.state.searchQuery) payload = payload + searchDevices(this.state.searchQuery);

		if (this.activeSort !== '' && this.sortOrder !== '') {
			if (this.sortOrder === 'asc') {
				payload = payload + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			} else {
				payload = payload + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			}
		}

		if (this.activeStatus?.status?.length) {
			let query = this.activeStatus.status.map((status) => `&status[]=${status}`).join('');
			payload = payload + query;
		}

		if (this.activeStatus?.invoiceStartDate && this.activeStatus?.invoiceEndDate) {
			payload = payload + `&invoiceStartDate=${moment(this.activeStatus.invoiceStartDate, 'DD-MM-YYYY').toISOString()}`;
			payload =
				payload +
				`&invoiceEndDate=${moment(this.activeStatus.invoiceEndDate, 'DD-MM-YYYY').endOf('day').toISOString()}`;
		}

		if (this.deletedMode) payload = payload + `&deleted=1`;

		refreshDevices(payload, activePage);
	}

	subscribeToFirebase(devices) {
		//this.unsubscribeFromFirebase();
		let firebaseReference = null;
		devices.forEach((device) => {
			const db = getDatabase();
			firebaseReference = ref(
				db,
				`/${device.vehicle ? `${device.vehicle.id}-${device.vehicle.registrationNumber}` : `${device.imei}`}/location`
			);
			get(firebaseReference).then((snapshot) => {
				if (snapshot.exists()) {
					let localState = this.state.lastLocation;
					localState[device.imei] = snapshot.val().timestamp ? snapshot.val().timestamp : null;
					this.setState({
						lastLocation: localState,
					});
				}
			});
			onChildChanged(firebaseReference, (data) => {
				if (data.key === 'timestamp') {
					let localState = this.state.lastLocation;
					localState[device.imei] = data.val();
					this.setState({
						lastLocation: localState,
					});
				}
			});
			// onValue(firebaseReference, (snapshot) => {
			// 	this.setState({
			// 		lastLocation: {
			// 			...this.state.lastLocation,
			// 			[device.imei]: snapshot.val() !== null ? isoToHumanReadableAlternate(snapshot.val().timestamp) : null,
			// 		},
			// 	});
			// });
			this.firebaseReferences.push(firebaseReference);
		});
	}

	unsubscribeFromFirebase() {
		if (this.state.firebaseReferences.length) {
			this.state.firebaseReferences.forEach((reference) => {
				reference.off();
			});

			this.firebaseReferences = [];
		}
	}

	handleSearch(e) {
		if (this.deviceNumber) this.setState({ searchQuery: this.deviceNumber });
		if (this.simStatus) {
			this.setState({ searchQuery: this.simStatus });
		}
		if (this.deviceId) {
			this.setState({ searchQuery: this.deviceId });
		} else {
			this.setState({ searchQuery: e.target.value });
		}
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}

		this.timeoutId = setTimeout(() => {
			this.getFilteredDevices();
			this.cleanSelectedRows();
		}, 500);
	}

	handleInputFile(e) {
		const files = e.target.files;
		if (files && files[0] && EXCEL_FILE_TYPES.indexOf(files[0].type) !== -1) {
			this.inputData = files[0];
			dispatch('DEVICES_IMPORT_MODAL_OPEN');
		} else generateErrorMessage('Only excel file types are allowed.');
	}

	async importDevices(response) {
		const devicesData = response.data;
		const body = devicesData.map((device) => {
			return {
				imei: device.imei.toString(),
				deviceModel: {
					id: device.deviceModel?.id,
				},
				manufacturer: device.manufacturer,
				purchaseInvoice: device.purchaseInvoice.toString(),
				invoiceDate: moment(device.invoiceDate, 'DD-MM-YYYY').toISOString(),
			};
		});

		postData(DEVICES_API_BULK, '', body)
			.then((res) => {
				this.setState({ deviceResponse: res.data });
				response.success();
				if (res.status === 206) {
					generateSuccessMessage(res.data, 'Partial Success');
				} else {
					generateSuccessMessage(res.data, 'Created');
				}
			})
			.catch((err) => {
				handleError(err);
				datadogLogs.logger.error('Device bulk import api', {}, err);
				response.error();
			});
	}

	cleanImportOperation() {
		dispatch('DEVICES_IMPORT_MODAL_CLOSE');
		// this.fileInputRef.current.value = '';
		// this.setState({ deviceResponse: null });
	}

	toggleDeletedMode() {
		this.deletedMode = !this.deletedMode;
		this.setState({
			deletedMode: !this.state.deletedMode,
		});

		this.getFilteredDevices();
		this.cleanSelectedRows();
	}

	// Export
	fileNameChange(e) {
		this.setState({ fileName: e.target.value });
	}

	fileExtensionChange(e, { value }) {
		this.setState({ fileExtension: value });
	}

	handleExportSelection(checkedData, imei) {
		let selectedRows = this.state.selectedRows;

		if (selectedRows.has(imei)) {
			selectedRows.delete(imei);
		} else selectedRows.add(imei);

		this.setState({
			selectedRows: selectedRows,
		});

		this.setState({
			anyRowSelected: !!selectedRows.size,
		});
	}

	handleSelectAll(checked) {
		let selectedRows = new Set();

		if (checked) {
			this.selectAllActive = true;
			this.props.devices.forEach((device) => selectedRows.add(device.imei));
			this.setState({
				selectedRows: selectedRows,
			});
		} else {
			this.selectAllActive = false;
			this.setState({
				selectedRows: new Set(),
			});
		}

		this.setState({
			anyRowSelected: !!selectedRows.size,
		});
	}

	export() {
		const { devices } = this.props;

		let devicesToExport = [];
		devices.forEach((device) => {
			if (this.state.selectedRows.has(device.imei)) {
				devicesToExport.push(device);
			}
		});

		return devicesToExport;
	}

	getFilterListQuery() {
		let payload = '';
		payload = payload + `?fileType=${this.state.fileExtension}`;

		if (this.activeStatus?.status?.length) {
			payload = payload + this.activeStatus.status.map((status) => `&status[]=${status}`).join('');
		}

		if (this.activeStatus?.invoiceStartDate && this.activeStatus?.invoiceEndDate) {
			payload = payload + `&invoiceStartDate=${moment(this.activeStatus.invoiceStartDate, 'DD-MM-YYYY').toISOString()}`;
			payload =
				payload +
				`&invoiceEndDate=${moment(this.activeStatus.invoiceEndDate, 'DD-MM-YYYY').endOf('day').toISOString()}`;
		}

		if (this.state.searchQuery !== '') {
			payload = payload + searchVehicleGroups(this.state.searchQuery);
		}

		if (this.activeSort !== '' && this.sortOrder !== '') {
			if (this.sortOrder === 'asc') {
				payload = payload + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			} else {
				payload = payload + `&sortBy=${this.activeSort}&orderBy=${this.sortOrder}`;
			}
		}

		if (this.deletedMode) payload = payload + `&deleted=1`;

		return payload;
	}

	// Add
	add() {
		const { imeiFA, serialNoFA, deviceModelIdFA, invoiceNumberFA, invoiceDateFA } = this.state;

		let payload = {};

		if (imeiFA)
			payload = {
				...payload,
				imei: imeiFA.trim(),
			};

		if (serialNoFA)
			payload = {
				...payload,
				serial: serialNoFA.trim(),
			};

		if (invoiceNumberFA) payload = { ...payload, purchaseInvoice: invoiceNumberFA.trim() };

		if (invoiceDateFA) payload = { ...payload, invoiceDate: moment(invoiceDateFA, 'DD-MM-YYYY').toISOString() };

		// if (dateOfPurchaseFA) payload = {...payload, dateOfPurchase: moment(dateOfPurchaseFA, 'DD-MM-YYYY').toISOString()};

		// if (supplierNameFA) payload = {...payload, supplierName: supplierNameFA.trim()};

		// if (noteFA) payload = {...payload, note: noteFA.trim()};

		if (deviceModelIdFA) {
			payload = {
				...payload,
				deviceModel: { id: deviceModelIdFA },
			};
		}

		dispatchNoPayload('DEVICES_ADDING');
		// axios
		// 	.post(DEVICES_API, payload, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 			'Content-Type': 'application/json',
		// 		},
		// 	})
		postData(DEVICES_API, '', payload)
			.then((response) => {
				dispatchNoPayload('DEVICES_ADDED');
				generateSuccessMessage(response);
				this.getFilteredDevices(this.props.pageNumber);
				this.cleanAddOperation();
			})
			.catch((error) => {
				dispatchNoPayload('DEVICES_ADDED');
				datadogLogs.logger.error('Device add api', {}, error);
				handleError(error);
			});
	}

	cleanAddOperation() {
		dispatchNoPayload('DEVICES_ADD_MODAL_CLOSE');

		this.setState({
			imeiFA: null,
			serialNoFA: null,
			deviceModelIdFA: null,
			invoiceNumberFA: null,
			invoiceDateFA: null,
			// dateOfPurchaseFA: null,
			//supplierNameFA: null,
			noteFA: null,
		});
	}

	onImeiChangeFA(e) {
		this.setState({
			imeiFA: e.target.value,
		});
	}

	onSerialNoChangeFA(e) {
		this.setState({
			serialNoFA: e.target.value,
		});
	}

	onDeviceModelChooseFA(e, { value }) {
		this.setState({
			deviceModelIdFA: value,
		});
	}

	onInvoiceNumberChangeFA(e) {
		this.setState({
			invoiceNumberFA: e.target.value,
		});
	}

	onInvoiceDateChangeFA(e, data) {
		this.setState({
			invoiceDateFA: data.value,
		});
	}

	/*
  onDateOfPurchaseChange(e, data) {
    this.setState({dateOfPurchaseFA: data.value});
  }

  onSupplierNameChangeFA(e) {
    this.setState({
      supplierNameFA: e.target.value,
    });
  }
 */
	onNoteChangeFA(e) {
		this.setState({
			noteFA: e.target.value,
		});
	}

	// Edit
	update(imei) {
		const { serial, note, sim1, sim2, invoiceNumber } = this.state;
		let payload = {};
		dispatchNoPayload('DEVICES_UPDATING');
		if (this.state.addSimMode) {
			if (sim1) payload = { sims: [{ mobileNumber: sim1 }] };
			if (sim2) payload = { sims: [{ mobileNumber: sim2 }] };
			// axios
			// 	.post(DEVICES_API + `/${imei}/sims`, payload, {
			// 		headers: {
			// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
			// 		},
			// 	})'
			postData(DEVICES_API, `/${imei}/sims`, payload)
				.then((response) => {
					dispatchNoPayload('DEVICES_UPDATED');
					generateSuccessMessage(response);
					this.getFilteredDevices(this.props.pageNumber, false);
					this.cleanEditOperation();
				})
				.catch((error) => {
					dispatchNoPayload('DEVICES_UPDATED');
					datadogLogs.logger.error('Device update api', {}, error);
					handleError(error);
				});
			return;
		}

		if (serial) payload = { ...payload, serialNo: serial.trim() };

		if (invoiceNumber) payload = { ...payload, purchaseInvoice: invoiceNumber.trim() };

		// if (dateOfPurchase) payload = {...payload, dateOfPurchase: moment(dateOfPurchase, 'DD-MM-YYYY').toISOString()};

		//  if (supplierName) payload = {...payload, supplierName: supplierName.trim()};

		payload = note === null ? payload : { ...payload, note: note.trim() };

		// if (sim1) payload = {...payload, sims: [{mobileNumber: sim1}]};
		// if (sim2) payload = {...payload, sims: [...payload.sims, {mobileNumber: sim2}]};

		// axios
		// 	.patch(DEVICES_API + `/${imei}`, payload, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		patchData(DEVICES_API + `/${imei}`, payload)
			.then((response) => {
				dispatchNoPayload('DEVICES_UPDATED');
				generateSuccessMessage(response);
				this.getFilteredDevices(this.props.pageNumber, false);
				this.cleanEditOperation();
			})
			.catch((error) => {
				dispatchNoPayload('DEVICES_UPDATED');
				handleError(error);
			});
	}

	cleanEditOperation() {
		dispatchNoPayload('DEVICES_EDIT_MODE_CLOSE');

		this.setState({
			serialNo: null,
			invoiceNumber: null,
			// dateOfPurchase: null,
			// supplierName: null,
			note: null,
			sim1: null,
			sim2: null,
			sims: [],
			addSimMode: false,
		});
	}

	// Swap Sim
	handleSwapClick(device) {
		dispatchNoPayload('DEVICES_SWAP_MODAL_OPEN');

		getSimsForDropdown().then((response) => {
			this.setState({
				sims: response,
				sim1OriginalID: device.sims[0]?.mobileNumber,
				sim2OriginalID: device.sims[1]?.mobileNumber,
				simSwapImei: device.imei,
				sim1SwapId: device.sims[0]?.mobileNumber,
				sim2SwapId: device.sims[1]?.mobileNumber,
			});
		});
	}

	swapSims() {
		const { simSwapImei, sim1SwapId, sim2SwapId, swapReason, simSwapTarget } = this.state;

		let payload = {};

		if (simSwapTarget === 'sim1')
			payload = {
				oldSim: {
					mobileNumber: this.state.sim1OriginalID,
				},
				newSim: {
					mobileNumber: sim1SwapId,
				},
				reason: swapReason,
			};
		if (simSwapTarget === 'sim2')
			payload = {
				oldSim: {
					mobileNumber: this.state.sim2OriginalID,
				},
				newSim: {
					mobileNumber: sim2SwapId,
				},
				reason: swapReason,
			};

		// payload = {...payload, sims: simdata};
		// if (swapReason) payload = {...payload, reason: swapReason};

		dispatchNoPayload('DEVICES_SWAPPING');
		// axios
		// 	.patch(DEVICES_API + `/${simSwapImei}/sims`, payload, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		patchData(DEVICES_API + `/${simSwapImei}/sims`, payload)
			.then((response) => {
				dispatchNoPayload('DEVICES_SWAPPED');
				dispatchNoPayload('DEVICES_SWAP_MODAL_CLOSE');
				generateSuccessMessage(response);
				this.getFilteredDevices(this.props.pageNumber, false);
				this.cleanSwapSimOperation();
			})
			.catch((error) => {
				dispatchNoPayload('DEVICES_SWAPPED');
				handleError(error);
			});
	}

	// onReasonSelect(e, {value}) {
	//   this.setState({removeReason: value});
	// }

	cleanSwapSimOperation() {
		dispatchNoPayload('DEVICES_SWAP_MODAL_CLOSE');

		this.setState({
			simSwapImei: '',
			sim1SwapId: null,
			sim2SwapId: null,
			swapReason: null,
			sims: [],
			simSwapTarget: 'sim1',
			sim1OriginalID: null,
			sim2OriginalID: null,
		});
	}

	onSim1Select(e, { value }) {
		this.setState({ sim1SwapId: value });
	}

	onSim2Select(e, { value }) {
		this.setState({ sim2SwapId: value });
	}

	onSwapSimReasonSelect(e, { value }) {
		this.setState({ swapReason: value });
	}

	// Remove Sim
	handleRemoveClick(device) {
		dispatchNoPayload('DEVICES_SIM_REMOVE_MODAL_OPEN');

		this.setState({
			simRemoveImei: device.imei,
			sim1RemoveId: device.sims[0]?.mobileNumber,
			sim2RemoveId: device.sims[1]?.mobileNumber,
		});
	}

	removeSim() {
		const { simRemoveImei, sim1RemoveId, sim2RemoveId, simRemoveTarget } = this.state;

		let payload = {};
		// if (simRemoveTarget === 'sim1' && !sim2RemoveId) payload = {sims: []};

		if (simRemoveTarget === 'sim1') payload = { sims: [{ mobileNumber: sim1RemoveId }] };

		if (simRemoveTarget === 'sim2') payload = { sims: [{ mobileNumber: sim2RemoveId }] };

		dispatchNoPayload('DEVICES_SIM_REMOVING');
		// axios
		// 	.delete(DEVICES_API + `/${simRemoveImei}/sims`, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 		data: payload,
		// 	})
		deleteData(DEVICES_API, `/${simRemoveImei}/sims`, payload)
			.then((response) => {
				dispatchNoPayload('DEVICES_SIM_REMOVED');
				dispatchNoPayload('DEVICES_SIM_REMOVE_MODAL_CLOSE');
				generateSuccessMessage(response);
				this.getFilteredDevices(this.props.pageNumber, false);
				this.cleanRemoveSimOperation();
			})
			.catch((error) => {
				dispatchNoPayload('DEVICES_SIM_REMOVED');
				handleError(error);
			});
	}

	cleanRemoveSimOperation() {
		dispatchNoPayload('DEVICES_SIM_REMOVE_MODAL_CLOSE');

		this.setState({
			simRemoveImei: '',
			sim1RemoveId: null,
			sim2RemoveId: null,
			simRemoveTarget: '',
		});
	}

	onSelectRemoveSim(e, { value }) {
		this.setState({ simRemoveTarget: value });
	}

	// Delete
	deleteDevice() {
		dispatchNoPayload('DEVICES_DELETING');
		// axios
		// 	.delete(DEVICES_API + `/${this.state.deleteDeviceImei}`, {
		// 		headers: {
		// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		},
		// 	})
		deleteData(DEVICES_API + `/${this.state.deleteDeviceImei}`)
			.then((response) => {
				dispatchNoPayload('DEVICES_DELETED');
				generateSuccessMessage(response);
				this.getFilteredDevices(this.props.pageNumber);
				this.cleanDeleteOperation();
			})
			.catch((error) => {
				dispatchNoPayload('DEVICES_DELETED');
				handleError(error);
			});
	}

	cleanDeleteOperation() {
		dispatchNoPayload('DEVICES_DELETE_MODAL_CLOSE');

		this.setState({ deleteDeviceImei: '' });
	}

	// Restore
	restoreDevice() {
		dispatchNoPayload('DEVICES_RESTORING');
		// axios
		// 	.post(
		// 		DEVICES_API + `/${this.state.restoreDeviceImei}/restore`,
		// 		{},
		// 		{
		// 			headers: {
		// 				Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 			},
		// 		}
		// 	)
		return postData(DEVICES_API, `/${this.state.restoreDeviceImei}/restore`)
			.then((response) => {
				dispatchNoPayload('DEVICES_RESTORED');
				generateSuccessMessage(response, 'Device Restored');
				this.getFilteredDevices(this.props.pageNumber);
				this.cleanRestoreOperation();
			})
			.catch((error) => {
				dispatchNoPayload('DEVICES_RESTORED');
				datadogLogs.logger.error('Device restore api', {}, error);
				handleError(error);
			});
	}

	cleanRestoreOperation() {
		dispatchNoPayload('DEVICES_RESTORE_MODAL_CLOSE');

		this.setState({ restoreDeviceImei: '' });
	}

	sort(param, activeSort, sortOrder) {
		if (!param) {
			this.activeSort = param;
			this.sortOrder = '';
		} else {
			if (activeSort !== param) {
				this.sortOrder = 'asc';
				this.activeSort = param;
			} else {
				if (sortOrder === 'asc') {
					this.sortOrder = 'desc';
				} else {
					this.sortOrder = 'asc';
				}
			}
		}

		dispatch('DEVICES_ACTIVE_ORDER', this.activeSort);
		dispatch('DEVICES_SORT_ORDER', this.sortOrder);
		this.getFilteredDevices(this.props.pageNumber);
	}

	onStatusFilterChange(e, { value }) {
		this.setState({ unActiveStatus: value });
	}

	onStartDateFilterChange(e, data) {
		this.setState({
			invoiceStartFilter: data.value,
		});
	}

	handleClearDate() {
		this.setState({
			invoiceStartFilter: '',
		});
	}

	onEndDateFilterChange(e, data) {
		this.setState({
			invoiceEndFilter: data.value,
		});
	}

	setUnActiveFilterValue(data) {
		this.setState({ unActiveStatus: data });
	}

	getChangeHistory(imei) {
		this.setState({ changeHistoryLoading: true });
		//
		// axios(CHANGE_HISTORY_API + `/device/${imei}`, {
		// 	headers: {
		// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		'Content-Type': 'application/json',
		// 	},
		// })
		getData(CHANGE_HISTORY_API, `/device/${imei}`)
			.then((response) => {
				let history = createChangeHistoryDTO(response?.data?.data);
				this.setState({
					changeHistory: history,
					changeHistoryLoading: false,
				});
			})
			.catch((error) => {
				handleError(error);
				this.setState({ changeHistoryLoading: false });
			});
	}

	cleanChangeHistoryOperation() {
		dispatchNoPayload('DEVICES_CHANGE_HISTORY_MODAL_CLOSE');

		this.deviceImei = '';
		this.setState({
			changeHistory: null,
		});
	}

	getConfigCommandsHistory(imei) {
		this.setState({ commandsHistoryLoading: true });
		// axios(COMMANDS_PIPELINE_API + `?pagination=0&device=${imei}`, {
		// 	headers: {
		// 		Authorization: 'Bearer ' + localStorage.getItem('token'),
		// 		'Content-Type': 'application/json',
		// 	},
		// })
		getData(COMMANDS_PIPELINE_API, `?pagination=0&device=${imei}`)
			.then((response) => {
				let history = createCommandsPipelineDTO(response?.data?.data);
				this.setState({
					commandsHistory: history,
					commandsHistoryLoading: false,
				});
			})
			.catch((error) => {
				handleError(error);
				this.setState({ commandsHistoryLoading: false });
			});
	}

	cleanCommandsHistoryOperation() {
		dispatchNoPayload('DEVICES_COMMANDS_HISTORY_MODAL_CLOSE');

		this.deviceImei = '';
		this.setState({
			commandsHistory: null,
		});
	}

	// Pagination
	handleDataSizeLimitChange() {
		this.getFilteredDevices();
	}

	handlePageChange(activePage) {
		this.getFilteredDevices(activePage);
		this.cleanSelectedRows();
	}

	cleanSelectedRows() {
		this.setState({
			anyRowSelected: false,
			selectedRows: new Set(),
		});
	}

	closeLogsComponent() {
		this.setState({ showLogsComponent: false });
	}

	componentDidUpdate(prevProps) {
		if (prevProps.devices !== this.props.devices) {
			this.subscribeToFirebase(this.props.devices);
			if (this.selectAllActive) this.handleSelectAll(true);
		}

		if (prevProps.loading !== this.props.loading) {
			if (!this.props.loading) {
				this.setState({
					initialLoading: false,
				});
			}
		}

		if (prevProps.pageNumber !== this.props.pageNumber) {
			document.getElementById('AccordionMain').scrollTo(0, 0);
		}
	}

	componentWillUnmount() {
		//this.unsubscribeFromFirebase();
		dispatchNoPayload('DEVICES_UNMOUNT');
	}

	handleSearchQuery(value) {
		this.setState({ searchQuery: value });
	}

	handleDoubleClick(imei) {
		this.props.history.push({
			pathname: '/operations/vehicles',
			state: { vehicleImei: imei },
		});
	}

	handleDeviceStatus(status) {
		this.props.history.push({
			pathname: '/operations/vehicles',
			state: { deviceStatus: status },
		});
	}

	handleOpenImportInfo() {
		this.setState({
			importInfo: true,
		});
	}

	handleCloseImportInfo() {
		this.setState({
			importInfo: false,
		});
	}

	render() {
		return (
			<CategoryContentMembrane>
				<CategoryHeader>
					<SearchBox
						placeholder="Search in devices"
						searchQuery={this.state.searchQuery}
						clearSearchIconClick={() => {
							// this.searchQuery = '';
							this.setState({ searchQuery: '' }, () => {
								this.deviceNumber = '';
								this.deviceId = '';
								this.simStatus = '';
								this.getFilteredDevices(this.props.pageNumber);
							});
						}}
						value={this.state.searchQuery}
						handleSearchQuery={this.handleSearchQuery}
						handleSearch={this.handleSearch}
						// disabled={!this.state.searchQuery && (this.props.loading || this.props.exportingData)}
					/>
					<Button
						icon
						primary
						disabled={this.props.loading || this.props.exportingData}
						title="Refresh Devices"
						onClick={() => this.getFilteredDevices(this.props.pageNumber)}>
						<Icon name="refresh" />
					</Button>
					<Expander />
					<Button
						primary={!this.activeStatus || !Object.keys(this.activeStatus).length}
						icon
						color={!this.activeStatus || Object.keys(this.activeStatus).length ? 'orange' : null}
						disabled={this.props.loading || this.props.exportingData}
						title="Filters Devices"
						onClick={openFilterModal}>
						<Icon name="filter" />
					</Button>
					{!this.state.deletedMode && (
						<>
							{/*Add*/}
							<Button
								primary
								title="Add Device"
								disabled={
									this.props.loading || this.props.exportingData
									// !(this.state.manageAccess || this.state.createAccess)
								}
								onClick={() => {
									trackMixpanelEvent('Devices', { Action: 'Add' });
									dispatchNoPayload('DEVICES_ADD_MODAL_OPEN');
									getDeviceModelsForDropdown().then((response) => {
										this.setState({ deviceModels: response });
									});
								}}>
								Add
							</Button>
							{/*Import*/}

							<Button
								primary
								title="Import Device from file"
								disabled={
									this.props.loading || this.props.exportingData
									// !(this.state.manageAccess || this.state.createAccess)
								}
								// onClick={() => this.fileInputRef.current.click()}
								onClick={this.handleOpenImportInfo}>
								Import
							</Button>

							{this.state.importInfo && (
								<ImportInfo
									devices={{
										DeviceimportInfo: this.state.importInfo,
										onClose: this.handleCloseImportInfo,
										fileTemplate: [
											{ name: 'Device Imei', requestTitle: 'imei', type: 'number' },
											{ name: 'Note', requestTitle: 'Note', type: 'string' },
											{ name: 'Device Manufacturer', requestTitle: 'manufacturer', type: 'string' },
											{ name: 'Serial Number', requestTitle: 'serialNo', type: 'number' },
											{ name: 'Invoice Number', requestTitle: 'purchaseInvoice', type: 'string' },
											{ name: 'Invoice Date', requestTitle: 'invoiceDate', type: 'date' },
										],
									}}
								/>
							)}

							{/*<input*/}
							{/*	style={{ display: 'none' }}*/}
							{/*	id="file"*/}
							{/*	ref={this.fileInputRef}*/}
							{/*	type="file"*/}
							{/*	className="form-control"*/}
							{/*	accept={this.SheetJSFT}*/}
							{/*	onChange={this.handleInputFile}*/}
							{/*/>*/}
						</>
					)}

					{/*Export*/}
					{/*<Button*/}
					{/*	primary*/}
					{/*	disabled={*/}
					{/*		!this.state.anyRowSelected || this.props.loading || !this.props.devices.length || this.props.exportingData*/}
					{/*	}*/}
					{/*	title={!this.state.anyRowSelected ? 'Please select atleast one device to export' : 'Export Devices'}*/}
					{/*	onClick={() => {*/}
					{/*		openExportModal();*/}
					{/*		dispatch('SET_DEVICES_EXPORT_DATA', this.export());*/}
					{/*	}}>*/}
					{/*	Export*/}
					{/*</Button>*/}

					{/*Export All*/}
					<Button.Group>
						<Button
							primary
							loading={this.props.exportingData}
							title="Export All Devices"
							disabled={this.props.loading || !this.props.devices.length || this.props.exportingData}
							onClick={() => exportAll(this.getFilterListQuery())}>
							Export
						</Button>
						<Dropdown
							className="button icon"
							style={{ background: '#ffffff', color: '#1b3c71', borderColor: '#1b3c71' }}
							floating
							value={this.state.fileExtension}
							options={FILE_TYPE_EXTENSIONS}
							onChange={this.fileExtensionChange}
							trigger={<></>}
						/>
					</Button.Group>

					{/*Deleted Mode*/}
					<ToggleSwitch
						title={this.deletedMode ? 'Hide Deleted Devices' : 'Show Deleted Devices'}
						label="Deleted Mode"
						checked={this.deletedMode}
						action={this.toggleDeletedMode}
						disabled={this.props.loading || this.props.exportingData}
					/>
				</CategoryHeader>
				<CategoryMain>
					<AccordionHeader background={this.deletedMode}>
						{/*{!this.state.deletedMode && (*/}
						{/*	<HeaderElement*/}
						{/*		style={{ marginRight: 0 }}*/}
						{/*		width="3%"*/}
						{/*		disabled={this.props.loading || this.props.exportingData || !this.props.devices.length}>*/}
						{/*		<CheckboxContainer>*/}
						{/*			<Checkbox*/}
						{/*				checked={*/}
						{/*					this.props.devices.length !== 0 && this.state.selectedRows.size === this.props.devices.length*/}
						{/*				}*/}
						{/*				indeterminate={*/}
						{/*					this.state.selectedRows.size !== 0 && this.state.selectedRows.size !== this.props.devices.length*/}
						{/*				}*/}
						{/*				onClick={(e, data) => {*/}
						{/*					this.handleSelectAll(data.checked);*/}
						{/*				}}*/}
						{/*			/>*/}
						{/*		</CheckboxContainer>*/}
						{/*	</HeaderElement>*/}
						{/*)}*/}

						<HeaderElement
							style={{ marginRight: 0 }}
							width="3%"
							disabled={this.props.loading || this.props.exportingData || !this.props.devices.length}
							onClick={() => this.sort('', '', '')}>
							No.
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="13%"
							disabled={this.props.loading || this.props.exportingData || !this.props.devices.length}
							onClick={() => {
								this.sort('imei', this.props.activeSort, this.props.sortOrder);
							}}
							activeSort={this.activeSort === 'imei'}>
							{this.props.activeSort === 'imei' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							IMEI
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="16%"
							onClick={() => {
								this.sort('manufacturer', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.devices.length}
							activeSort={this.activeSort === 'manufacturer'}>
							{this.props.activeSort === 'manufacturer' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Manufacturer
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="8%"
							onClick={() => {
								this.sort('status', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.devices.length}
							activeSort={this.activeSort === 'status'}>
							{this.props.activeSort === 'status' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Status
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="12%"
							onClick={() => {
								this.sort('mobileNumber', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.devices.length}
							activeSort={this.activeSort === 'mobileNumber'}>
							{this.props.activeSort === 'mobileNumber' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Sim1
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="12%"
							onClick={() => {
								this.sort('mobileNumber', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.devices.length}
							activeSort={this.activeSort === 'mobileNumber'}>
							{this.props.activeSort === 'mobileNumber' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Sim 2
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="12%"
							onClick={() => {
								this.sort('purchaseInvoice', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.devices.length}
							activeSort={this.activeSort === 'purchaseInvoice'}>
							{this.props.activeSort === 'purchaseInvoice' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Invoice Number
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="12%"
							inActive={true}
							disabled={this.props.loading || this.props.exportingData || !this.props.devices.length}>
							Invoice Date
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="17%"
							inActive={true}
							disabled={this.props.loading || this.props.exportingData || !this.props.devices.length}>
							Last Data
						</HeaderElement>
						<HeaderElement
							style={{ marginRight: 0 }}
							width="10%"
							onClick={() => {
								this.sort('note', this.props.activeSort, this.props.sortOrder);
							}}
							disabled={this.props.loading || this.props.exportingData || !this.props.devices.length}
							activeSort={this.activeSort === 'note'}>
							{this.props.activeSort === 'note' ? (
								this.props.sortOrder === 'asc' ? (
									<Icon name="sort alphabet down" />
								) : (
									<Icon name="sort alphabet up" />
								)
							) : (
								<Icon name="sort" />
							)}
							Note
						</HeaderElement>
						{this.deletedMode && (
							<HeaderElement
								style={{ marginRight: 0 }}
								width="9%"
								disabled={this.props.loading || this.props.exportingData || !this.props.devices.length}>
								Deleted Date
							</HeaderElement>
						)}
					</AccordionHeader>

					<AccordionMain id="AccordionMain">
						{this.state.initialLoading ? (
							<BasicLoader />
						) : this.props.devices.length ? (
							<Accordion fluid>
								{this.props.devices.map((device, index) => {
									return (
										<section key={index}>
											<Accordion.Title
												active={this.props.activeIndex === index}
												index={index}
												onClick={(event, data) => {
													toggleAccordion(event, data, this.props.activeIndex);
													this.cleanEditOperation();
												}}
												style={{ padding: 0 }}>
												<Row>
													{/*{!this.state.deletedMode && (*/}
													{/*	<RowElement width="3%" style={{ marginRight: 0 }}>*/}
													{/*		<CheckboxContainer>*/}
													{/*			<Checkbox*/}
													{/*				checked={this.state.selectedRows.has(device.imei)}*/}
													{/*				onChange={(e, data) => {*/}
													{/*					e.stopPropagation();*/}
													{/*					this.handleExportSelection(data.checked, device.imei);*/}
													{/*				}}*/}
													{/*			/>*/}
													{/*		</CheckboxContainer>*/}
													{/*	</RowElement>*/}
													{/*)}*/}

													<RowElement width="3%" style={{ marginRight: 0 }}>
														{1 + index + (this.props.pageNumber - 1) * this.props.dataSizeLimit}
													</RowElement>

													<div style={{ width: '13%' }}>
														<RowElement width="100%" title={device.imei ? device.imei : 'Not Available'}>
															{device.imei ? device.imei : 'Not Available'}
														</RowElement>
													</div>
													<RowElement
														/*width="16%"
                            style={{ marginRight: 0, fontStyle: `${device.manufacturer ? 'inherit' : 'italic'}` }}*/
														width="16%"
														style={{ marginRight: 0 }}
														title={`${device.manufacturer} (${device.model})`}>
														{device.manufacturer} ({device.model})
													</RowElement>

													{device.status === 'New' || device.status === 'Vehicle' ? (
														<div
															style={{ width: '8%' }}
															onClick={() => navigator.clipboard.writeText(device.imei)}
															onDoubleClick={() => this.handleDeviceStatus(device.imei)}>
															<RowElement
																/*width="8%"
                                style={{ marginRight: 0, fontStyle: `${device.status ? 'inherit' : 'italic'}` }}*/
																width="100%"
																style={{ marginRight: 0 }}
																title={device.status ? device.status : 'Not Available'}>
																<Label size="small" color={`${DEVICE_STATUS_COLOR[device.status]}`}>
																	{device.status ? device.status : 'Not Available'}
																</Label>
															</RowElement>
														</div>
													) : (
														<RowElement
															/*width="8%"
                            style={{ marginRight: 0, fontStyle: `${device.status ? 'inherit' : 'italic'}` }}*/
															width="8%"
															style={{ marginRight: 0 }}
															title={device.status ? device.status : 'Not Available'}>
															<Label size="small" color={`${DEVICE_STATUS_COLOR[device.status]}`}>
																{device.status ? device.status : 'Not Available'}
															</Label>
														</RowElement>
													)}

													<RowElement
														width="12%"
														style={{
															marginRight: 0,
															color: `${device?.sims[0]?.mobileNumber ? 'black' : 'grey'}`,
														}}
														title="Sim1">
														{device?.sims[0]?.mobileNumber ? device?.sims[0]?.mobileNumber : 'Not Available'}
													</RowElement>
													<RowElement
														width="12%"
														style={{
															marginRight: 0,
															color: `${device?.sims[1]?.mobileNumber ? 'black' : 'grey'}`,
														}}
														title="Sim2">
														{device?.sims[1]?.mobileNumber ? device?.sims[1]?.mobileNumber : 'Not Available'}
													</RowElement>
													<RowElement
														width="11%"
														style={{
															marginRight: 0,
															color: `${device.invoiceNumber ? 'black' : 'grey'}`,
														}}
														title={device.invoiceNumber ? device.invoiceNumber : 'Not Available'}>
														{device.invoiceNumber ? device.invoiceNumber : 'Not Available'}
													</RowElement>
													<RowElement
														width="11%"
														style={{
															marginRight: 0,
															color: `${device.invoiceDate ? 'black' : 'grey'}`,
														}}
														title={device.invoiceDate ? device.invoiceDate : 'Not Available'}>
														{device.invoiceDate ? device.invoiceDate : 'Not Available'}
													</RowElement>
													<RowElement
														width="17%"
														// style={{this.state.last}}
														title={`${
															!this.state.lastLocation[device.imei]
																? 'Data not available'
																: moment().diff(moment(this.state.lastLocation[device.imei]), 'seconds') >= 3600
																? 'No data since 1 hour'
																: moment().diff(moment(this.state.lastLocation[device.imei]), 'seconds') >= 300
																? 'Data arrived within 1 hour'
																: moment().diff(moment(this.state.lastLocation[device.imei]), 'seconds') >= 0
																? 'Data arrived within 5 minutes'
																: 'Faulty data'
														}`}>
														<Label
															size={'small'}
															style={{ width: '100%', textAlign: 'center' }}
															color={`${
																!this.state.lastLocation[device.imei]
																	? 'grey'
																	: moment().diff(moment(this.state.lastLocation[device.imei]), 'seconds') >= 3600
																	? 'red'
																	: moment().diff(moment(this.state.lastLocation[device.imei]), 'seconds') >= 300
																	? 'orange'
																	: moment().diff(moment(this.state.lastLocation[device.imei]), 'seconds') >= 0
																	? 'green'
																	: 'black'
															}`}>
															{this.state.lastLocation[device.imei]
																? isoToHumanReadableAlternate(this.state.lastLocation[device.imei])
																: 'Not Available'}
														</Label>
													</RowElement>
													{/*<RowElement*/}
													{/*	style={{*/}
													{/*		marginRight: 0,*/}
													{/*		color: `${this.state.lastLocation[device.imei] ? 'black' : 'gray'}`,*/}
													{/*	}}*/}
													{/*	title={`${*/}
													{/*		this.state.lastLocation[device.imei]*/}
													{/*			? this.state.lastLocation[device.imei]*/}
													{/*			: 'Not Available'*/}
													{/*	}`}*/}
													{/*	width="10%">*/}
													{/*	{this.state.lastLocation[device.imei]*/}
													{/*		? this.state.lastLocation[device.imei]*/}
													{/*		: 'Not Available'}*/}
													{/*</RowElement>*/}
													<RowElement
														width="10%"
														style={{
															marginLeft: '5px',
															color: `${device.note ? 'black' : 'grey'}`,
														}}
														title={device.note}>
														{device.note ? device.note : 'Not Available'}
													</RowElement>
													{this.deletedMode && (
														<RowElement
															width="9%"
															style={{
																marginRight: 0,
																color: `${device.deletedDate ? 'black' : 'grey'}`,
															}}
															title={device.deletedDate}>
															{device.deletedDate ? device.deletedDate : 'Not Available'}
														</RowElement>
													)}
												</Row>
											</Accordion.Title>

											{this.props.activeIndex === index && (
												<Accordion.Content active={this.props.activeIndex === index}>
													<AccordionMainContent>
														<Form>
															<Form.Group widths="equal">
																<Form.Field
																	control={Input}
																	label="Serial No."
																	error={
																		!validateMobileNumber(this.state.serialNo) && {
																			content: 'Serial number should be 10 digit.',
																		}
																	}>
																	<Input
																		fluid
																		value={
																			this.state.serialNo !== null
																				? this.state.serialNo
																				: device.serial === null
																				? ''
																				: device.serial
																		}
																		disabled={!this.props.editMode || this.state.addSimMode}
																		onChange={(e) =>
																			this.setState({
																				serialNo: e.target.value,
																			})
																		}
																	/>
																</Form.Field>

																<Form.Field>
																	<label>Sim 1</label>
																	{this.state.addSimMode && device.sims?.length === 0 ? (
																		<>
																			<Dropdown
																				fluid
																				search
																				selection
																				value={this.state.sim1 ? this.state.sim1 : ''}
																				disabled={false}
																				options={this.state.sims}
																				onChange={(e, { value }) => this.setState({ sim1: value })}
																			/>
																		</>
																	) : (
																		<Input
																			fluid
																			disabled
																			value={
																				this.state.sim1 !== null
																					? this.state.sim1
																					: device.sims[0]?.mobileNumber === null
																					? 'No Sim Added'
																					: device.sims[0]?.mobileNumber
																			}
																		/>
																	)}
																</Form.Field>
																<Form.Field>
																	<label>Sim 2</label>
																	{this.state.addSimMode && device.sims?.length === 1 ? (
																		<>
																			<Dropdown
																				fluid
																				search
																				selection
																				clearable
																				value={this.state.sim2 ? this.state.sim2 : ''}
																				disabled={false}
																				options={this.state.sims}
																				onChange={(e, { value }) =>
																					this.setState({
																						sim1: device.sims[0].mobileNumber,
																						sim2: value,
																					})
																				}
																			/>
																		</>
																	) : (
																		<Input
																			fluid
																			disabled
																			value={
																				this.state.sim2 !== null
																					? this.state.sim2
																					: device.sims[1]?.mobileNumber === null
																					? 'No Sim Added'
																					: device.sims[1]?.mobileNumber
																			}
																		/>
																	)}
																</Form.Field>
															</Form.Group>
															{/* <Form.Group widths="equal">
                                <Form.Field>
                                  <label>Invoice Number</label>
                                  <Input
                                    fluid
                                    value={
                                      this.state.invoiceNumber !== null
                                        ? this.state.invoiceNumber
                                        : device.invoiceNumber === null
                                        ? ''
                                        : device.invoiceNumber
                                    }
                                    disabled={!this.props.editMode || this.state.addSimMode}
                                    onChange={(e) =>
                                      this.setState({
                                        invoiceNumber: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <label>Purchase Date</label>
                                  <DateInput
                                    placeholder="Select"
                                    fluid
                                    closable
                                    disabled={!this.props.editMode || this.state.addSimMode}
                                    value={
                                      this.state.dateOfPurchase !== null
                                        ? this.state.dateOfPurchase
                                        : device.dateOfPurchase === null
                                        ? ''
                                        : getFormattedDate(device.dateOfPurchase, 'DD-MM-YYYY')
                                    }
                                    onChange={(e, data) =>
                                      this.setState({
                                        dateOfPurchase: data.value,
                                      })
                                    }
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <label>Supplier Name</label>
                                  <Input
                                    fluid
                                    value={
                                      this.state.supplierName !== null
                                        ? this.state.supplierName
                                        : device.supplierName === null
                                        ? ''
                                        : device.supplierName
                                    }
                                    disabled={!this.props.editMode || this.state.addSimMode}
                                    onChange={(e) =>
                                      this.setState({
                                        supplierName: e.target.value,
                                      })
                                    }
                                  />
                                </Form.Field>
                              </Form.Group> */}
															<Form.Group widths="equal">
																<Form.Field>
																	<label>Note</label>
																	<Input
																		fluid
																		value={
																			this.state.note !== null
																				? this.state.note
																				: device.note === null
																				? ''
																				: device.note
																		}
																		disabled={!this.props.editMode || this.state.addSimMode}
																		onChange={(e) =>
																			this.setState({
																				note: e.target.value,
																			})
																		}
																	/>
																</Form.Field>
																<Form.Field>
																	<label>Invoice Number</label>
																	<Input
																		fluid
																		value={
																			this.state.invoiceNumber !== null
																				? this.state.c
																				: device.invoiceNumber === null
																				? ''
																				: device.invoiceNumber
																		}
																		disabled={device.invoiceNumber || !this.props.editMode}
																		onChange={(e) =>
																			this.setState({
																				invoiceNumber: e.target.value,
																			})
																		}
																	/>
																</Form.Field>
																<Form.Field required>
																	<label>Invoice Date</label>
																	<DateInput
																		placeholder="Select"
																		fluid
																		clearable
																		closable
																		maxDate={new Date()}
																		disabled={device.invoiceDate || !this.props.editMode}
																		value={
																			this.state.invoiceDateFA !== null
																				? this.state.invoiceDateFA
																				: device.invoiceDate == null
																				? ''
																				: device.invoiceDate
																		}
																		onChange={(e, data) => {
																			this.setState({
																				invoiceDateFA: data.value,
																			});
																		}}
																	/>
																</Form.Field>
															</Form.Group>
														</Form>

														{this.state.deletedMode ? (
															<Actions>
																<Button
																	primary
																	disabled={!(this.state.manageAccess || this.state.createAccess)}
																	onClick={() => {
																		trackMixpanelEvent('Devices', { Action: 'Restore' });
																		dispatchNoPayload('DEVICES_RESTORE_MODAL_OPEN');
																		this.setState({ restoreDeviceImei: device.imei });
																	}}>
																	Restore
																</Button>
															</Actions>
														) : this.props.editMode ? (
															<Actions>
																<Button secondary onClick={this.cleanEditOperation}>
																	Cancel
																</Button>
																<Button
																	positive
																	loading={this.props.updating}
																	onClick={() => {
																		trackMixpanelEvent('Devices', { Action: 'Update' });
																		this.update(device.imei);
																	}}>
																	Update
																</Button>
															</Actions>
														) : (
															<LeftActions>
																<div>
																	<Link
																		to={{
																			pathname: '/diagnostic-and-logs/logs',
																			state: { vehicleId: device?.imei },
																		}}>
																		<Button
																			style={{
																				display: `${
																					!this.state.manageDiagnosticsAccess || this.state.readDiagnosticsAccess
																						? 'none'
																						: ''
																				}`,
																			}}
																			disabled={
																				!(this.state.manageDiagnosticsAccess || this.state.readDiagnosticsAccess)
																			}
																			icon
																			title="See Device Logs"
																			onClick={() => {
																				trackMixpanelEvent('Devices', { Action: 'Device Logs' });
																				// this.setState({
																				// 	showLogsComponent: true,
																				// 	deviceDetails: device,
																				// });
																			}}>
																			<Icon name="file outline" />
																		</Button>
																	</Link>
																	{/*<Button*/}
																	{/*  icon*/}
																	{/*  title="See Device Change History"*/}
																	{/*  onClick={() => {*/}
																	{/*    dispatchNoPayload('DEVICES_CHANGE_HISTORY_MODAL_OPEN');*/}
																	{/*    this.deviceImei = device.imei;*/}
																	{/*    this.getChangeHistory(device.imei);*/}
																	{/*  }}>*/}
																	{/*  <Icon name="history" />*/}
																	{/*</Button>*/}
																	{/*<Button*/}
																	{/*  icon*/}
																	{/*  title="See Config Commands History"*/}
																	{/*  onClick={() => {*/}
																	{/*    dispatchNoPayload('DEVICES_COMMANDS_HISTORY_MODAL_OPEN');*/}
																	{/*    this.deviceImei = device.imei;*/}
																	{/*    this.getConfigCommandsHistory(device.imei);*/}
																	{/*  }}>*/}
																	{/*  <Icon name="terminal" />*/}
																	{/*</Button>*/}
																</div>
																<section>
																	<Button
																		primary
																		// disabled={!(this.state.manageAccess || this.state.updateAccess)}
																		onClick={() => {
																			trackMixpanelEvent('Devices', { Action: 'Edit' });
																			dispatchNoPayload('DEVICES_EDIT_MODE_OPEN');
																		}}>
																		Edit
																	</Button>
																	{/*Swap Sims*/}
																	{device.sims?.length > 0 && (
																		<Button
																			color="teal"
																			title="Swap Sim in Device"
																			// disabled={!(this.state.manageAccess || this.state.updateAccess)}
																			onClick={() => {
																				this.handleSwapClick(device);
																				trackMixpanelEvent('Devices', { Action: 'Swap sim' });
																			}}>
																			Swap Sim
																		</Button>
																	)}
																	{device.sims?.length < 2 && (
																		<Button
																			color="teal"
																			// disabled={!(this.state.manageAccess || this.state.updateAccess)}
																			title="Add Sim in Device"
																			onClick={() => {
																				this.setState({ addSimMode: true });
																				dispatchNoPayload('DEVICES_EDIT_MODE_OPEN');
																				getSimsForDropdown().then((response) => {
																					this.setState({ sims: response });
																				});
																			}}>
																			{device.sims?.length === 0 ? 'Add Sim 1' : 'Add Sim 2'}
																		</Button>
																	)}

																	{device.sims?.length > 0 && device.status !== 'Vehicle' && (
																		<Button
																			color="orange"
																			title="Remove Sim from Device"
																			// disabled={!(this.state.manageAccess || this.state.deleteAccess)}
																			onClick={() => {
																				this.handleRemoveClick(device);
																				trackMixpanelEvent('Devices', { Action: 'Remove sims' });
																			}}>
																			Remove Sims
																		</Button>
																	)}
																	<Button
																		negative
																		// disabled={!(this.state.manageAccess || this.state.deleteAccess)}
																		onClick={() => {
																			trackMixpanelEvent('Device', { Action: 'Delete' });
																			dispatchNoPayload('DEVICES_DELETE_MODAL_OPEN');
																			this.setState({
																				deleteDeviceImei: device.imei,
																			});
																		}}>
																		Delete
																	</Button>
																</section>
															</LeftActions>
														)}
													</AccordionMainContent>
												</Accordion.Content>
											)}
										</section>
									);
								})}
							</Accordion>
						) : (
							<PlaceHolder />
						)}
					</AccordionMain>
				</CategoryMain>
				<CategoryFooter background={this.deletedMode}>
					<FooterPagination
						handleDataSizeLimitChange={this.handleDataSizeLimitChange}
						pageNumber={this.props.pageNumber}
						handlePageChange={this.handlePageChange}
						count={this.props.count}
						disabled={this.props.loading || !this.props.devices.length || this.props.exportingData}
					/>
				</CategoryFooter>
				{this.props.loading && !this.state.initialLoading && <TransitionLoader />}
				{/*{this.state.showLogsComponent && (*/}
				{/*	<VehicleLogs device={this.state.deviceDetails} closeLogsComponent={this.closeLogsComponent} />*/}
				{/*)}*/}
				{/*Add Modal*/}
				{this.props.addModalOpen && (
					<AddModal
						onClose={this.cleanAddOperation}
						open={this.props.addModalOpen}
						imei={this.state.imeiFA}
						onImeiChange={this.onImeiChangeFA}
						serialNo={this.state.serialNoFA}
						onSerialNoChange={this.onSerialNoChangeFA}
						deviceModels={this.state.deviceModels}
						selectedDeviceModel={this.state.deviceModelIdFA}
						onDeviceModelChoose={this.onDeviceModelChooseFA}
						invoiceNumberFA={this.state.invoiceNumberFA}
						invoiceDateFA={this.state.invoiceDateFA}
						onInvoiceNumberChangeFA={this.onInvoiceNumberChangeFA}
						onInvoiceDateChangeFA={this.onInvoiceDateChangeFA}
						//dateOfPurchaseFA={this.state.dateOfPurchaseFA}
						//onDateOfPurchaseChange={this.onDateOfPurchaseChange}
						//supplierNameFA={this.state.supplierNameFA}
						//onSupplierNameChangeFA={this.onSupplierNameChangeFA}
						disabled={
							!this.state.imeiFA ||
							!this.state.deviceModelIdFA ||
							!this.state.invoiceNumberFA ||
							!this.state.invoiceNumberFA
						}
						onNoteChange={this.onNoteChangeFA}
						onCancelClick={this.cleanAddOperation}
						onAddButtonClick={this.add}
						addButtonLoading={this.props.adding}
					/>
				)}

				{/*Import Modal*/}

				{this.props.importModalOpen && (
					<DeviceImport
						template={this.fileTemplate}
						file={this.inputData}
						upload={this.importDevices}
						deviceResponse={this.state.deviceResponse}
						closeImport={this.cleanImportOperation}
					/>
				)}
				{/*Swap Sim Modal*/}
				{this.props.swapModalOpen && (
					<SwapSimModal
						onClose={this.cleanSwapSimOperation}
						open={this.props.swapModalOpen}
						sim1SwapId={this.state.sim1SwapId}
						sim1SwapChange={this.onSim1Select}
						sim2SwapId={this.state.sim2SwapId}
						sim2SwapChange={this.onSim2Select}
						sims={this.state.sims}
						reason={this.state.swapReason}
						reasonSelect={this.onSwapSimReasonSelect}
						onCancelClick={this.cleanSwapSimOperation}
						onSwapButtonClick={this.swapSims}
						swapButtonLoading={this.props.swapping}
						onSelectSwapSim={(e, { value }) => this.setState({ simSwapTarget: value })}
						selectedSim={this.state.simSwapTarget}
					/>
				)}
				{/*Remove Sim Modal*/}
				{this.props.removeSimModal && (
					<RemoveSimModal
						onClose={this.cleanRemoveSimOperation}
						open={this.props.removeSimModal}
						sim1Id={this.state.sim1RemoveId}
						sim2Id={this.state.sim2RemoveId}
						selectedSim={this.state.simRemoveTarget}
						onSelectRemoveSim={this.onSelectRemoveSim}
						// reason={this.state.removeReason}
						onReasonSelect={this.onReasonSelect}
						onCancelClick={this.cleanRemoveSimOperation}
						onRemoveButtonClick={this.removeSim}
						RemoveButtonLoading={this.props.simRemoving}
					/>
				)}
				{/*Delete Modal*/}
				{this.props.deleteModalOpen && (
					<DeleteModal
						onClose={this.cleanDeleteOperation}
						open={this.props.deleteModalOpen}
						onCancelClick={this.cleanDeleteOperation}
						onDeleteButtonClick={this.deleteDevice}
						deleteButtonLoading={this.props.deleting}
					/>
				)}
				{/*Restore Modal*/}
				{this.props.restoreModal && (
					<RestoreModal
						onClose={this.cleanRestoreOperation}
						open={this.props.restoreModal}
						onCancelClick={this.cleanRestoreOperation}
						onRestoreButtonClick={this.restoreDevice}
						restoreButtonLoading={this.props.restoring}
					/>
				)}
				{/*Filter Modal*/}
				{this.props.filterModalOpen && (
					<FilterModal
						onClose={() => {
							this.setUnActiveFilterValue(this.activeStatus);
							closeFilterModal();
						}}
						open={this.props.filterModalOpen}
						statusFilters={this.state.unActiveStatus}
						onStatusFilterChange={this.onStatusFilterChange}
						invoiceStartDate={this.state.invoiceStartFilter}
						oninvoiceStartDateChange={this.onStartDateFilterChange}
						handleClearDate={this.handleClearDate}
						invoiceEndDate={this.state.invoiceEndFilter}
						oninvoiceEndDateChange={this.onEndDateFilterChange}
						onCancelClick={() => {
							this.setUnActiveFilterValue(this.activeStatus);
							this.setState({
								invoiceStartFilter: '',
								invoiceEndFilter: '',
							});
							closeFilterModal();
						}}
						onResetClick={() => {
							this.activeStatus = {};
							this.setUnActiveFilterValue([]);
							this.setState({
								invoiceStartFilter: '',
								invoiceEndFilter: '',
							});
							this.getFilteredDevices();
						}}
						onApplyClick={() => {
							trackMixpanelEvent('Devices', { Action: 'Filter' });
							this.activeStatus = {
								status: this.state.unActiveStatus,
								invoiceStartDate: this.state.invoiceStartFilter,
								invoiceEndDate: this.state.invoiceEndFilter,
							};
							this.getFilteredDevices();
							closeFilterModal();
						}}
					/>
				)}
				{this.props.openExportModal && (
					<ExportModal
						open={this.props.openExportModal}
						onClose={() => dispatchNoPayload('DEVICES_EXPORT_MODAL_CLOSE')}
						fileName={this.state.fileName}
						fileNameChange={this.fileNameChange}
						fileExtension={this.state.fileExtension}
						fileExtensionChange={this.fileExtensionChange}
						onDownloadClick={() => exportData(this.props.exportData, this.state.fileName, this.state.fileExtension)}
					/>
				)}
				{this.props.changeHistoryModal && (
					<TimeLineViewModal
						onClose={this.cleanChangeHistoryOperation}
						loading={this.state.changeHistoryLoading}
						open={this.props.changeHistoryModal}
						header={this.deviceImei}
						data={this.state.changeHistory}
						onCancelClick={this.cleanChangeHistoryOperation}
					/>
				)}
				{this.props.commandsHistoryModal && (
					<MessageTimeLine
						onClose={this.cleanCommandsHistoryOperation}
						loading={this.state.commandsHistoryLoading}
						open={this.props.commandsHistoryModal}
						header={this.deviceImei}
						data={this.state.commandsHistory}
						onCancelClick={this.cleanCommandsHistoryOperation}
					/>
				)}
			</CategoryContentMembrane>
		);
	}
}

// Connect to Store
const mapStateToProps = (state) => ({
	devices: state.devices.devices,
	loading: state.devices.loading,
	count: state.devices.count,
	pageNumber: state.devices.pageNumber,
	activeIndex: state.devices.activeIndex,
	dataSizeLimit: state.ui.dataSizeLimit,

	adding: state.devices.adding,
	addModalOpen: state.devices.addModalOpen,

	editMode: state.devices.editMode,
	updating: state.devices.updating,

	swapModalOpen: state.devices.swapModalOpen,
	swapping: state.devices.swapping,

	removeSimModal: state.devices.simRemoveSimModal,
	simRemoving: state.devices.simRemoving,

	configuring: state.devices.configuring,
	changeHistoryModal: state.devices.changeHistoryModal,

	deleteModalOpen: state.devices.deleteModalOpen,
	deleting: state.devices.deleting,

	activeSort: state.devices.activeSort,
	sortOrder: state.devices.sortOrder,

	restoreModal: state.devices.restoreModal,
	restoring: state.devices.restoring,

	importModalOpen: state.devices.importModalOpen,
	filterModalOpen: state.devices.filterModalOpen,
	commandsHistoryModal: state.devices.commandsHistoryModal,

	openExportModal: state.devices.openExportModal,
	exportData: state.devices.exportData,
	exportingData: state.devices.exportingData,
});

export default connect(mapStateToProps)(Devices);
