import React from 'react';
import CommonModal from '../../../molecules/modal/CommonModal';
import { Button, Checkbox, Dropdown, Label, Message } from 'semantic-ui-react';
import { DeactivateOrgBlock, LabelContainer } from '../../../../styles/Containers';
import { DEACTIVATE_ORGANISATION_REASONS } from '../../../../../../data/constants/general';

const DeactivateModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="tiny"
			header="Deactivate Organisation"
			content={
				<>
					<Message negative>
						<Message.Header>Caution</Message.Header>
						<p>Deleting the organisation will also delete the following things.</p>
					</Message>
					{props.users.length !== 0 && (
						<DeactivateOrgBlock>
							<label style={{ fontWeight: 'bold' }}>Users</label>
							<LabelContainer backgroundColor="#fde380">
								{props.users.map((user, index) => {
									return <Label key={index}>{user.name}</Label>;
								})}
							</LabelContainer>
						</DeactivateOrgBlock>
					)}
					{props.vehicles.length !== 0 && (
						<DeactivateOrgBlock>
							<label style={{ fontWeight: 'bold' }}>Vehicles</label>
							<LabelContainer backgroundColor="#79f2c0">
								{props.vehicles.map((vehicle, index) => {
									return <Label key={index}>{vehicle.registrationNumber}</Label>;
								})}
							</LabelContainer>
						</DeactivateOrgBlock>
					)}
					{props.vehicleGroups.length !== 0 && (
						<DeactivateOrgBlock>
							<label style={{ fontWeight: 'bold' }}>Vehicle Groups</label>
							<LabelContainer backgroundColor="#79f2c0">
								{props.vehicleGroups.map((group, index) => {
									return <Label key={index}>{group.name}</Label>;
								})}
							</LabelContainer>
						</DeactivateOrgBlock>
					)}
					<DeactivateOrgBlock>
						<label style={{ fontWeight: 'bold' }}>Reason</label>
						<Dropdown
							placeholder="Choose ..."
							fluid
							search
							selection
							options={DEACTIVATE_ORGANISATION_REASONS}
							value={props.reason}
							style={{ marginTop: '0.2em' }}
							onChange={props.onReasonChoose}
						/>
					</DeactivateOrgBlock>
					<Message negative>
						<Message.Header>Sims</Message.Header>
						<p style={{ display: 'flex', justifyContent: 'space-between' }}>
							Select the checkbox to terminate the sims in all the vehicles of this organisation. This action cannot be
							undone.
							<Checkbox checked={props.deleteSims} onChange={props.onCheckDeleteSims} />
						</p>
					</Message>
					<Message warning>
						<Message.Header>Notifications</Message.Header>
						<p>All your notification will be deleted.</p>
					</Message>
				</>
			}
			scrolling={true}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button negative onClick={props.onDeactivateButtonClick}>
						Deactivate
					</Button>
				</>
			}
		/>
	);
};

export default DeactivateModal;
