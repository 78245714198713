import styled from 'styled-components';
import { NAV_BAR_SETTINGS_MENU_HEIGHT } from '../../../../../data/constants/dimensions';

export const Container = styled.section`
  width: 100%;
  height: ${NAV_BAR_SETTINGS_MENU_HEIGHT};

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  padding: 1em .8em 1em .5em;

  //.ui.circular.label {
  //  background-color: #dc3911;
  //  color: #fff;
  //}
}
`;
// export const Card = styled.div`
// 	box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
// `;
