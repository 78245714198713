import React from 'react';

import NetMRRGraph from '../../../blocks/organisms/graphs/customer-metrics/NetMRRGraph';
import MRRDistribution from '../../../blocks/organisms/graphs/customer-metrics/MRRDistribution';
import ARPAGraph from '../../../blocks/organisms/graphs/customer-metrics/ARPAGraph';
import VehicleExpansionGraph from '../../../blocks/organisms/graphs/customer-metrics/VehicleExpansionGraph';
import NetPromoterScoreGraph from '../../../blocks/organisms/graphs/customer-metrics/NetPromoterScoreGraph';
import ExportModal from '../../../blocks/organisms/modals/customers-metrics/ExportModal';
import CalendarMonth from '../../../../common/calender/month/CalendarMonth';
import CLVGraphMultiple from '../../../blocks/organisms/graphs/customer-metrics/CLVGraphMultiple';
import ARPAGraphMultiple from '../../../blocks/organisms/graphs/customer-metrics/ARPAGraphMultiple';
import NetPromoterScoreGraphMultiple from '../../../blocks/organisms/graphs/customer-metrics/NetPromoterScoreGraphMultiple';
import VehicleExpansionGraphMultiple from '../../../blocks/organisms/graphs/customer-metrics/VehicleExpansionGraphMultiple';
import ChurnedVehicleGraphMultiple from '../../../blocks/organisms/graphs/customer-metrics/ChurnedVehicleGraphMultiple';
import NetMRRGraphMultiple from '../../../blocks/organisms/graphs/customer-metrics/NetMRRGraphMultiple';
import ChurnedMRRGraphMultiple from '../../../blocks/organisms/graphs/customer-metrics/ChurnedMRRGraphMultiple';
import ExpansionMRRGraphMultiple from '../../../blocks/organisms/graphs/customer-metrics/ExpansionMRRGraphMultiple';
import { GraphMain } from '../../../styles/containers/GraphMain';
import { List } from '../../../styles/containers/List';
import { ListHeader } from '../../../styles/containers/ListHeader';
import { ListMain } from '../../../styles/containers/ListMain';
import CustomerMetricsSearchBox from '../../../blocks/atoms/customer-metrics-search/CustomerMetricsSearchBox';
import { ListItem } from '../../../styles/cards/ListItem';
import { Expander } from '../../../styles/place-holders/Expander';
import BasicLoader from '../../../blocks/molecules/loader/BasicLoader';
import CLVGraph from '../../../blocks/organisms/graphs/customer-metrics/CLVGraph';
import { GraphScrollView } from '../../../styles/containers/GraphScrollView';
import { CategoryHeader } from '../../../styles/containers/CategoryHeader';
import { CustomWidth } from '../../../styles/containers/CustomWidth';
import TotalVehiclesGraph from '../../../blocks/organisms/graphs/customer-metrics/TotalVehiclesGraph';
import TotalVehicleGraphMultiple from '../../../blocks/organisms/graphs/customer-metrics/TotalVehicleGraphMultiple';
import { CategoryContentMembrane } from '../../../styles/containers/CategoryContentMembrane';
import { GraphTableView } from '../../../styles/containers/GraphTableView';
import { GraphTableMain } from '../../../styles/containers/GraphTableMain';
import PlaceHolder from '../../../blocks/molecules/place-holder/PlaceHolder';

import { LAZY_LOAD_OFFSET, LIST_WIDTH } from '../../../../../data/constants/dimensions';
import {
	exportAll,
	exportData,
	formatARPAGraphData,
	formatCLVGraphData,
	formatMultipleARPAGraphData,
	formatMultipleChurnedMRRGraphData,
	formatMultipleCLVGraphData,
	formatMultipleExpansionMRRGraphData,
	formatMultipleNetMRRGraphData,
	formatMultipleNetPromoterScoreGraphData,
	formatMultipleTotalVehicleGraphData,
	formatMultipleVehicleChurnedGraphData,
	formatMultipleVehicleExpansionGraphData,
	formatNetMRRDistributionGraphData,
	formatNetMRRGraphData,
	formatNetPromoterScoreGraphData,
	formatTotalVehiclesGraphData,
	formatVehicleExpansionGraphData,
	handleCardClick,
	handleCheckboxClick,
	loadCustomersList,
	loadSaasMetrics,
	openExportModal,
	search,
} from '../../../../../logic/middleware/customersMetrics';
import { dispatch, dispatchNoPayload } from '../../../../../logic/middleware/middleware';
import { switchMetricsViewMode } from '../../../../../logic/middleware/ui';

import { connect } from 'react-redux';

// Utilities Packages
import LazyLoad from 'react-lazy-load';

import { Button, Checkbox, Table } from 'semantic-ui-react';
import styled from 'styled-components';

class Customers extends React.Component {
	constructor() {
		super();

		this.state = {
			fileName: 'Metrics',
			fileExtension: 'excel',
		};

		this.fileExtensionChange = this.fileExtensionChange.bind(this);
		this.fileNameChange = this.fileNameChange.bind(this);
	}

	componentDidMount() {
		// Load customer list.
		loadCustomersList('?pagecount=1000&pagenumber=1');
	}

	fileNameChange(e) {
		this.setState({ fileName: e.target.value });
	}

	fileExtensionChange(e, { value }) {
		this.setState({ fileExtension: value });
	}

	renderListItems() {
		const { customers, selectedCustomers, initialDate, finalDate } = this.props;

		return customers.map((customer, index) => {
			return (
				<LazyLoad height="2.5em" overflow unmountIfInvisible key={index} offset={LAZY_LOAD_OFFSET}>
					<ListItem onClick={() => handleCardClick(customer.id, initialDate, finalDate)}>
						<Checkbox
							checked={selectedCustomers.has(customer.id)}
							onClick={(e) => {
								e.stopPropagation();
								handleCheckboxClick(customer.id, selectedCustomers, initialDate, finalDate);
							}}
						/>
						{customer.name}
						<Expander />
					</ListItem>
				</LazyLoad>
			);
		});
	}

	componentDidUpdate(prevProps) {
		if (
			(this.props.initialDate !== prevProps.initialDate || this.props.finalDate !== prevProps.finalDate) &&
			this.props.selectedCustomers.size
		) {
			loadSaasMetrics(
				Array.from(this.props.selectedCustomers).join(','),
				`&startDate=${this.props.initialDate}&endDate=${this.props.finalDate}`
			);
		}
	}

	componentWillUnmount() {
		dispatchNoPayload('CUSTOMERS_METRICS_CLEAR_SELECTED_CUSTOMERS');
	}

	render() {
		const {
			customers,
			customersBackup,
			metricsViewMode,
			selectedCustomers,
			selectedCustomersMetrics,
			loadingMetricsData,
		} = this.props;

		return this.props.loading ? (
			<BasicLoader />
		) : (
			<CategoryContentMembrane>
				<CategoryHeader>
					<CalendarMonth />
					<Expander />
					<Button.Group>
						<Button primary={metricsViewMode === 'graph'} onClick={() => switchMetricsViewMode('graph')}>
							Graphical
						</Button>
						<Button primary={metricsViewMode === 'table'} onClick={() => switchMetricsViewMode('table')}>
							Tabular
						</Button>
					</Button.Group>
					<Expander />
					<Button
						primary
						onClick={() => {
							openExportModal();
							dispatch('SET_CUSTOMER_METRICS_EXPORT_DATA', selectedCustomersMetrics);
						}}>
						Export
					</Button>
					<Button
						primary
						loading={this.props.exportingData}
						onClick={() => {
							exportAll('?pagination=0');
						}}>
						Export All
					</Button>
				</CategoryHeader>

				<GraphMain>
					<CustomWidth width={`calc(100% - ${LIST_WIDTH})`}>
						{metricsViewMode === 'graph' ? (
							<GraphScrollView>
								{selectedCustomersMetrics.length ? (
									selectedCustomers.size === 1 ? (
										<>
											<NetMRRGraph
												data={formatNetMRRGraphData(selectedCustomersMetrics)}
												loading={loadingMetricsData}
											/>
											<MRRDistribution
												data={formatNetMRRDistributionGraphData(selectedCustomersMetrics)}
												loading={loadingMetricsData}
											/>
											<TotalVehiclesGraph
												data={formatTotalVehiclesGraphData(selectedCustomersMetrics)}
												loading={loadingMetricsData}
											/>
											<VehicleExpansionGraph
												data={formatVehicleExpansionGraphData(selectedCustomersMetrics)}
												loading={loadingMetricsData}
											/>
											<CLVGraph data={formatCLVGraphData(selectedCustomersMetrics)} loading={loadingMetricsData} />
											<ARPAGraph data={formatARPAGraphData(selectedCustomersMetrics)} loading={loadingMetricsData} />
											<NetPromoterScoreGraph
												data={formatNetPromoterScoreGraphData(selectedCustomersMetrics)}
												loading={loadingMetricsData}
											/>
										</>
									) : (
										<>
											<NetMRRGraphMultiple
												data={formatMultipleNetMRRGraphData(selectedCustomersMetrics)}
												loading={loadingMetricsData}
											/>
											<TotalVehicleGraphMultiple
												data={formatMultipleTotalVehicleGraphData(selectedCustomersMetrics)}
												loading={loadingMetricsData}
											/>
											<VehicleExpansionGraphMultiple
												data={formatMultipleVehicleExpansionGraphData(selectedCustomersMetrics)}
												loading={loadingMetricsData}
											/>
											<ExpansionMRRGraphMultiple
												data={formatMultipleExpansionMRRGraphData(selectedCustomersMetrics)}
												loading={loadingMetricsData}
											/>
											<ChurnedMRRGraphMultiple
												data={formatMultipleChurnedMRRGraphData(selectedCustomersMetrics)}
												loading={loadingMetricsData}
											/>
											<ChurnedVehicleGraphMultiple
												data={formatMultipleVehicleChurnedGraphData(selectedCustomersMetrics)}
												loading={loadingMetricsData}
											/>
											<CLVGraphMultiple
												data={formatMultipleCLVGraphData(selectedCustomersMetrics)}
												loading={loadingMetricsData}
											/>
											<ARPAGraphMultiple
												data={formatMultipleARPAGraphData(selectedCustomersMetrics)}
												loading={loadingMetricsData}
											/>
											<NetPromoterScoreGraphMultiple
												data={formatMultipleNetPromoterScoreGraphData(selectedCustomersMetrics)}
												loading={loadingMetricsData}
											/>
										</>
									)
								) : loadingMetricsData ? (
									<BasicLoader />
								) : (
									<PlaceHolder />
								)}
							</GraphScrollView>
						) : (
							<GraphTableView>
								{loadingMetricsData ? (
									<BasicLoader />
								) : selectedCustomersMetrics.length ? (
									<>
										<GraphTableMain>
											<Table celled striped compact selectable>
												<Table.Header>
													<Table.Row>
														<Table.HeaderCell collapsing>No.</Table.HeaderCell>
														<Table.HeaderCell>Name</Table.HeaderCell>
														<Table.HeaderCell collapsing>Date</Table.HeaderCell>
														<Table.HeaderCell collapsing>Net Mrr</Table.HeaderCell>
														<Table.HeaderCell collapsing>Expansion Vehicle</Table.HeaderCell>
														<Table.HeaderCell collapsing>Expansion Mrr</Table.HeaderCell>
														<Table.HeaderCell collapsing>Churned Vehicle</Table.HeaderCell>
														<Table.HeaderCell collapsing>Churned Mrr</Table.HeaderCell>
														<Table.HeaderCell collapsing>CLV</Table.HeaderCell>
														<Table.HeaderCell collapsing>ARPA</Table.HeaderCell>
														<Table.HeaderCell collapsing>Score</Table.HeaderCell>
													</Table.Row>
												</Table.Header>
												<Table.Body>
													{selectedCustomersMetrics &&
														selectedCustomersMetrics.map((metrics, index) => {
															return (
																<Table.Row key={index}>
																	<Table.Cell collapsing>{index + 1}</Table.Cell>
																	<Table.Cell singleLine>{metrics.organisation.name}</Table.Cell>
																	<Table.Cell collapsing>{metrics.date}</Table.Cell>
																	<Table.Cell collapsing>{metrics.netMrr}</Table.Cell>
																	<Table.Cell collapsing>{metrics.expansionVehicles}</Table.Cell>
																	<Table.Cell collapsing>{metrics.expansionMrr}</Table.Cell>
																	<Table.Cell collapsing>{metrics.churnedVehicles}</Table.Cell>
																	<Table.Cell collapsing>{metrics.churnedMrr}</Table.Cell>
																	<Table.Cell collapsing>{metrics.clv}</Table.Cell>
																	<Table.Cell collapsing>{metrics.arpa}</Table.Cell>
																	<Table.Cell collapsing>{metrics.netPromoterScore}</Table.Cell>
																</Table.Row>
															);
														})}
												</Table.Body>
											</Table>
										</GraphTableMain>
									</>
								) : (
									<PlaceHolder />
								)}
							</GraphTableView>
						)}
					</CustomWidth>

					<List>
						<ListHeader>
							<CustomerMetricsSearchBox
								clearSearchIconClick={() => {
									dispatch('CUSTOMERS_METRICS_RESET_CUSTOMERS_LIST', customersBackup);
								}}
								handleSearch={(e) => search(e, customers, customersBackup)}
							/>
						</ListHeader>
						<ListMain>{this.renderListItems()}</ListMain>
					</List>
				</GraphMain>

				{this.props.openExportModal && (
					<ExportModal
						open={this.props.openExportModal}
						fileName={this.state.fileName}
						fileNameChange={this.fileNameChange}
						fileExtension={this.state.fileExtension}
						fileExtensionChange={this.fileExtensionChange}
						onDownloadClick={() => exportData(this.props.exportData, this.state.fileName, this.state.fileExtension)}
					/>
				)}
			</CategoryContentMembrane>
		);
	}
}

// Connect to Store
const mapStateToProps = (state) => ({
	loading: state.customersMetrics.loading,
	loadingMetricsData: state.customersMetrics.loadingMetricsData,

	customers: state.customersMetrics.customers,
	customersBackup: state.customersMetrics.customersBackup,

	selectedCustomers: state.customersMetrics.selectedCustomers,
	selectedCustomersMetrics: state.customersMetrics.selectedCustomersMetrics,

	openExportModal: state.customersMetrics.openExportModal,
	exportData: state.customersMetrics.exportData,
	exportingData: state.customersMetrics.exportingData,

	initialDate: state.calendarMonth.initialDate,
	finalDate: state.calendarMonth.finalDate,

	metricsViewMode: state.ui.metricsViewMode,
});

export default connect(mapStateToProps)(Customers);

export const Container = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
`;
