import React from 'react';

import { Container } from './GraphLoaderStyles';
import { Loader } from 'semantic-ui-react';

function GraphLoader() {
	return (
		<Container>
			<Loader active inline />
		</Container>
	);
}

export default GraphLoader;
