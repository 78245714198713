export default function companyMetrics(
	initialState = {
		// Initial loading.
		loading: false,

		// List of all customers.
		company: [],

		// Export
		openExportModal: false,
	},
	action
) {
	switch (action.type) {
		case 'FETCHING_COMPANY_METRICS_DATA':
			return {
				...initialState,
				loading: true,
			};

		case 'FETCHED_COMPANY_METRICS_DATA':
			return {
				...initialState,
				loading: false,
			};

		case 'FEED_COMPANY_METRICS_DATA':
			return {
				...initialState,
				company: action.payload,
				loading: false,
			};

		case 'COMPANY_METRICS_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'COMPANY_METRICS_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		default:
			return initialState;
	}
}
