import React from 'react';

import { Container } from './CommonModalStyles';

import { Modal } from 'semantic-ui-react';

function CommonModal(props) {
	return (
		<Container>
			<Modal dimmer="inverted" size={props.size} open={props.open} onClose={props.onClose} closeOnEscape={false}>
				<Modal.Header style={{ display: 'flex', alignItems: 'baseline', gap: '1rem' }}>
					{props.header}
					<Modal.Header style={{ color: 'grey', fontWeight: '400' }} sub="true" as="h5">
						{props.subHeader}
					</Modal.Header>
				</Modal.Header>
				<Modal.Content scrolling={props.scrolling || false}>{props.content}</Modal.Content>
				<Modal.Actions>{props.actions}</Modal.Actions>
			</Modal>
		</Container>
	);
}

export default CommonModal;
