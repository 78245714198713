import axios from 'axios';
import { performanceRef } from '../firebase';
import { trace } from 'firebase/performance';

export function getData(URL, params = '') {
	const t = trace(performanceRef, `GET_${URL}`);
	t.start();
	const response = axios.get(URL + params, {
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json',
		},
	});
	t.stop();
	return response;
}

export function exportApi(URL, params = '') {
	const t = trace(performanceRef, `GET_${URL}`);
	t.start();
	const response = axios.get(URL + params, {
		responseType: 'arraybuffer',
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json',
		},
	});
	t.stop();
	return response;
}

export function postData(URL, params = '', body = {}) {
	const t = trace(performanceRef, `POST_${URL}`);
	t.start();
	const response = axios.post(URL + params, body, {
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json',
		},
	});
	t.stop();
	return response;
}

export function patchData(URL, body = {}) {
	const t = trace(performanceRef, `PATCH_${URL}`);
	t.start();
	const response = axios.patch(URL, body, {
		headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json',
		},
	});
	t.stop();
	return response;
}

// export function deleteData(URL, params = '') {
// 	const t = trace(performanceRef, `DELETE_${URL}`);
// 	t.start();
// 	const response = axios.delete(URL + params, {
// 		headers: {
// 			Authorization: 'Bearer ' + localStorage.getItem('token'),
// 			'Content-Type': 'application/json',
// 		},
// 	});
// 	t.stop();
// 	return response;
// }
export function deleteData(URL, params = '', payload = null) {
	const t = trace(performanceRef, `DELETE_${URL}`);
	t.start();

	const headers = {
		Authorization: 'Bearer ' + localStorage.getItem('token'),
		'Content-Type': 'application/json',
	};

	let config = {
		headers,
	};

	if (payload !== null) {
		config.data = payload;
	}

	const response = axios.delete(URL + params, config);

	t.stop();
	return response;
}
