export default function peopleUsers(
	initialState = {
		peopleUsers: [],
		loading: true,
		activeIndex: -1,
		pageNumber: 1,
		count: 0,
		deleting: false,
		deleteModalOpen: false,
		activeSort: '',
		sortOrder: '',
		adding: false,
		addModalOpen: false,
		importing: false,
		importModalOpen: false,
		updating: false,
		editMode: false,
		passwordModalOpen: false,
		submiting: false,
		passwordVisible: false,
		restoreModal: false,
		restoring: false,

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
	},
	action
) {
	switch (action.type) {
		case 'FEED_PEOPLE_USERS':
			return {
				...initialState,
				peopleUsers: action.payload,
				loading: false,
			};

		case 'PEOPLE_USERS_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'PEOPLE_USERS_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'PEOPLE_USERS_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'PEOPLE_USERS_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'PEOPLE_USERS_DELETING':
			return {
				...initialState,
				deleting: true,
			};

		case 'PEOPLE_USERS_DELETED':
			return {
				...initialState,
				deleting: false,
			};

		case 'PEOPLE_USERS_DELETE_MODAL_OPEN':
			return {
				...initialState,
				deleteModalOpen: true,
			};

		case 'PEOPLE_USERS_DELETE_MODAL_CLOSE':
			return {
				...initialState,
				deleteModalOpen: false,
			};

		case 'PEOPLE_USERS_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'PEOPLE_USERS_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'PEOPLE_USERS_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'PEOPLE_USERS_ADDING':
			return {
				...initialState,
				adding: true,
			};

		case 'PEOPLE_USERS_ADDED':
			return {
				...initialState,
				adding: false,
			};

		case 'PEOPLE_USERS_ADD_MODAL_OPEN':
			return {
				...initialState,
				addModalOpen: true,
			};

		case 'PEOPLE_USERS_IMPORTING':
			return {
				...initialState,
				importing: true,
			};

		case 'PEOPLE_USERS_IMPORTED':
			return {
				...initialState,
				importing: false,
			};

		case 'PEOPLE_USERS_IMPORT_MODAL_OPEN':
			return {
				...initialState,
				importModalOpen: true,
			};

		case 'PEOPLE_USERS_IMPORT_MODAL_CLOSE':
			return {
				...initialState,
				importModalOpen: false,
			};

		case 'PEOPLE_USERS_ADD_MODAL_CLOSE':
			return {
				...initialState,
				addModalOpen: false,
			};

		case 'PEOPLE_USERS_UPDATING':
			return {
				...initialState,
				updating: true,
			};

		case 'PEOPLE_USERS_UPDATED':
			return {
				...initialState,
				updating: false,
			};

		case 'PEOPLE_USERS_EDIT_MODAL_OPEN':
			return {
				...initialState,
				editMode: true,
			};

		case 'PEOPLE_USERS_EDIT_MODAL_CLOSE':
			return {
				...initialState,
				editMode: false,
			};

		case 'PEOPLE_USERS_PASSWORD_SUBMITING':
			return {
				...initialState,
				submiting: true,
			};

		case 'PEOPLE_USERS_PASSWORD_SUBMITED':
			return {
				...initialState,
				submiting: false,
			};

		case 'PEOPLE_USERS_PASSWORD_MODAL_OPEN':
			return {
				...initialState,
				passwordModalOpen: true,
			};

		case 'PEOPLE_USERS_PASSWORD_MODAL_CLOSE':
			return {
				...initialState,
				passwordModalOpen: false,
			};

		case 'PEOPLE_USERS_PASSWORD_VISIBLE':
			return {
				...initialState,
				passwordVisible: true,
			};

		case 'PEOPLE_USERS_PASSWORD_NOT_VISIBLE':
			return {
				...initialState,
				passwordVisible: false,
			};

		case 'PEOPLE_USERS_RESTORE_MODAL_OPEN':
			return {
				...initialState,
				restoreModal: true,
			};

		case 'PEOPLE_USERS_RESTORE_MODAL_CLOSE':
			return {
				...initialState,
				restoreModal: false,
			};

		case 'PEOPLE_USERS_RESTORING':
			return {
				...initialState,
				restoring: true,
			};

		case 'PEOPLE_USERS_RESTORED':
			return {
				...initialState,
				restoring: false,
			};

		case 'PEOPLE_USERS_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'PEOPLE_USERS_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_PEOPLE_USERS_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_PEOPLE_USERS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_PEOPLE_USERS_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'PEOPLE_USERS_UNMOUNT':
			return {
				...initialState,
				peopleUsers: [],
				activeSort: '',
				sortOrder: '',
				activeIndex: -1,
				pageNumber: 1,
			};

		default:
			return initialState;
	}
}
