export function createRolesDTO(data) {
	return data.map((role) => {
		return {
			id: role.id,
			name: role.name,
			description: role.description,
			isSuperAdmin: role.isSuperAdmin,
			permissions: role.permissions,
			count: role.users?.length,
		};
	});
}
