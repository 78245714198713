import React, { useEffect } from 'react';

import Auth from './ui/components/pages/public/Auth';
import Dashboard from './ui/components/layouts/dashboard/Dashboard';
import { handleUserSession } from './logic/middleware/auth';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

const AppGateway = () => {
	// Note: Required for state update.

	// Mount
	useEffect(() => {
		handleUserSession();
	}, []);

	return localStorage.getItem('token') ? (
		<BrowserRouter>
			<Switch>
				<Route path="/" component={Dashboard} />
			</Switch>
		</BrowserRouter>
	) : (
		<BrowserRouter>
			<Switch>
				<Route path="/" component={Auth} />
			</Switch>
		</BrowserRouter>
	);
};

export default AppGateway;
