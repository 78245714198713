import styled from 'styled-components';

export const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	margin: 1em;
`;

export const RowElement = styled.div`
	width: 50%;
	max-width: 320px;
`;

export const DetailsHeader = styled.div`
	width: 100%;
	height: 2em;

	box-sizing: border-box;
	padding: 1.5em 36px 1.5em 1.5em;

	display: flex;
	align-items: center;

	font-size: 12px;

	border-bottom: 1px solid lightgray;
`;

export const HeaderElement = styled.span`
	width: ${(props) => props.width};

	display: inline-block;

	text-align: center;
`;

export const ExcelDataRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-evenly;

	input {
		margin: 0.25em;
	}
`;

export const ErrorMessage = styled.span`
	font-size: 0.8em;
	color: red;

	display: inline-block;
	margin-left: 5%;
`;

export const ButtonContainer = styled.span`
	width: 5%;

	display: flex;
	align-items: center;
	justify-content: center;
`;
