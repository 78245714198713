import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SidebarHeader from '../../blocks/molecules/sidebar-header/SidebarHeader';
import SidebarListItem from '../../blocks/molecules/sidebar-list-item/SidebarListItem';
import SidebarToggleIcon from '../../blocks/atoms/sidebar-toggle-icon/SidebarToggleIcon';
import { CategorySidebar } from '../../styles/containers/CategorySidebar';
import { CategoryContent } from '../../styles/containers/CategoryContent';
import { SidebarComponentList } from '../../styles/containers/SidebarComponentList';
import Roles from '../../pages/secured/user-management-system/Roles';
import { SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_EXPANDED_WIDTH } from '../../../../data/constants/dimensions';
import UMSImage from '../../../assets/images/ums.png';
import { collapseSidebar, expandSidebar } from '../../../../logic/middleware/ui';
import { connect } from 'react-redux';
import { Container } from './UserManagementSystemStyles';

class UserManagementSystem extends React.Component {
	// constructor() {
	//     super();
	//
	//     this.state = {
	//         manageRoleAccess: false,
	//         readRoleAccess: false,
	//         createRoleAccess: false,
	//         updateRoleAccess: false,
	//         deleteRoleAccess: false,
	//     };
	//
	//     this.manageAccessSetter = this.manageAccessSetter.bind(this);
	// }

	// manageAccessSetter(rule, action) {
	//     this.setState({[action]: rule});
	// }

	// componentDidMount() {
	//     const permissions = JSON.parse(localStorage.getItem('permissions'));
	//     const rolePermissions = permissions?.filter((permission) => permission.subject === 'Role');
	//     if (rolePermissions) {
	//         for (const rolePrm of rolePermissions) {
	//             entityAccessSetter(rolePrm?.action, 'Role', this.manageAccessSetter);
	//         }
	//     }
	// }

	render() {
		const { sidebarExpanded } = this.props;
		const url = this.props.match.url;

		return (
			<Container>
				{/*Navigation*/}
				<CategorySidebar width={sidebarExpanded ? SIDEBAR_EXPANDED_WIDTH : SIDEBAR_COLLAPSED_WIDTH}>
					<SidebarHeader imageSource={UMSImage} alt="UMS" name="UMS" expanded={sidebarExpanded} />
					<SidebarComponentList>
						<div
						// style={{
						//     opacity: `${!(this.state.manageRoleAccess || this.state.readRoleAccess) ? '.4' : '1'}`,
						//     pointerEvents: `${!(this.state.manageRoleAccess || this.state.readRoleAccess) ? 'none' : ''}`,
						// }}
						>
							<SidebarListItem
								path={`${url}`}
								exact={true}
								iconName="user plus"
								name="Roles"
								expanded={sidebarExpanded}
							/>
						</div>
						{/*<SidebarListItem*/}
						{/*  path={`${url}/access-modules`}*/}
						{/*  exact={true}*/}
						{/*  iconName="recycle"*/}
						{/*  name="Access Modules"*/}
						{/*  expanded={sidebarExpanded}*/}
						{/*/>*/}
					</SidebarComponentList>
					<SidebarToggleIcon
						name={sidebarExpanded ? 'chevron circle left' : 'chevron circle right'}
						handleClick={sidebarExpanded ? collapseSidebar : expandSidebar}
					/>
				</CategorySidebar>

				<CategoryContent
					width={
						sidebarExpanded ? `calc(100% - ${SIDEBAR_EXPANDED_WIDTH})` : `calc(100% - ${SIDEBAR_COLLAPSED_WIDTH})`
					}>
					<Switch>
						<Route path={`${url}`} exact component={Roles} />
						{/*<Route path={`${url}/access-modules`} exact component={AccessModule} />*/}
					</Switch>
				</CategoryContent>
			</Container>
		);
	}
}

// Connect to Store
const mapStateToProps = (state) => ({
	sidebarExpanded: state.ui.sidebarExpanded,
});

export default connect(mapStateToProps)(UserManagementSystem);
