import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SidebarHeader from '../../blocks/molecules/sidebar-header/SidebarHeader';
import SidebarListItem from '../../blocks/molecules/sidebar-list-item/SidebarListItem';
import SidebarToggleIcon from '../../blocks/atoms/sidebar-toggle-icon/SidebarToggleIcon';
import { CategorySidebar } from '../../styles/containers/CategorySidebar';
import { CategoryContent } from '../../styles/containers/CategoryContent';
import { SidebarComponentList } from '../../styles/containers/SidebarComponentList';
// import Health from '../../pages/secured/service-and-maintenance/Service';
import { SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_EXPANDED_WIDTH } from '../../../../data/constants/dimensions';
import ServiceImage from '../../../assets/images/service.png';
import { collapseSidebar, expandSidebar } from '../../../../logic/middleware/ui';
import { connect } from 'react-redux';
import { Container } from './ServiceAndMaintenanceStyles';
import FuelLogs from '../../pages/secured/service-and-maintenance/FuelLogs';
import { entityAccessSetter } from '../../../../logic/utilities/accessSetter';
import { dispatchNoPayload } from '../../../../logic/middleware/middleware';

class ServiceAndMaintenance extends React.Component {
	constructor() {
		super();
		this.state = {
			manageFuelLogsAccess: false,
			readFuelLogsAccess: false,
			createFuelLogsAccess: false,
			updateFuelLogsAccess: false,
			deleteFuelLogsAccess: false,
		};
		this.manageAccessSetter = this.manageAccessSetter.bind(this);
	}

	manageAccessSetter(rule, action) {
		this.setState({ [action]: rule });
	}

	componentDidMount() {
		const permissions = JSON.parse(localStorage.getItem('permissions'));
		const fuelLogsPermissions = permissions?.filter((permission) => permission.subject === 'Service And Maintenance');
		if (fuelLogsPermissions) {
			for (const fuelLogsPrm of fuelLogsPermissions) {
				entityAccessSetter(fuelLogsPrm?.action, 'FuelLogs', this.manageAccessSetter);
			}
		}
		dispatchNoPayload('RESET_DATES');
	}

	render() {
		const { sidebarExpanded } = this.props;
		const url = this.props.match.url;

		return (
			<Container>
				{/*Navigation*/}
				<CategorySidebar width={sidebarExpanded ? SIDEBAR_EXPANDED_WIDTH : SIDEBAR_COLLAPSED_WIDTH}>
					<SidebarHeader
						imageSource={ServiceImage}
						alt="Service & Maintenance"
						name="Service & Maintenance"
						expanded={sidebarExpanded}
					/>
					<SidebarComponentList>
						<div
							style={{
								opacity: `${!(this.state.manageFuelLogsAccess || this.state.readFuelLogsAccess) ? '.4' : '1'}`,
								pointerEvents: `${!(this.state.manageFuelLogsAccess || this.state.readFuelLogsAccess) ? 'none' : ''}`,
							}}>
							<SidebarListItem
								path={`${url}`}
								exact={true}
								iconName="fire"
								name="Fuel Logs"
								expanded={sidebarExpanded}
							/>
						</div>
					</SidebarComponentList>
					<SidebarToggleIcon
						name={sidebarExpanded ? 'chevron circle left' : 'chevron circle right'}
						handleClick={sidebarExpanded ? collapseSidebar : expandSidebar}
					/>
				</CategorySidebar>

				<CategoryContent
					width={
						sidebarExpanded ? `calc(100% - ${SIDEBAR_EXPANDED_WIDTH})` : `calc(100% - ${SIDEBAR_COLLAPSED_WIDTH})`
					}>
					<Switch>
						<Route path={`${url}`} exact component={FuelLogs} />
					</Switch>
				</CategoryContent>
			</Container>
		);
	}
}

// Connect to Store
const mapStateToProps = (state) => ({
	sidebarExpanded: state.ui.sidebarExpanded,
});

export default connect(mapStateToProps)(ServiceAndMaintenance);
