import styled from 'styled-components';
import { COLORS } from '../../styles/colors';

export const AccordionHeader = styled.section`
	width: calc(100% - 1.75em);
	height: 3em;

	box-sizing: border-box;
	padding: 1em;

	display: flex;
	align-items: center;

	font-weight: bold;

	background: #f5f5f5;
	border-radius: 3px;
`;

export const HeaderElement = styled.span`
	width: ${(props) => props.width};
	display: inline-block;

	//text-align: left;
	padding: 0px 5px;
	pointer-events: ${(props) => (props.disabled ? 'none' : 'default')};
	opacity: ${(props) => (props.disabled ? '0.4' : '1')};

	cursor: ${(props) => (props.inActive ? 'default' : 'pointer')};

	color: ${(props) => (props.activeSort ? COLORS.activeSortColor : '')};

	&:hover {
		color: ${(props) => (props.inActive ? '#000' : COLORS.activeSortColor)};
	}

	.ui.dropdown .menu > .item {
		padding: 0.5em 1.5em !important;
	}
`;

export const Row = styled.section`
	display: flex;
	align-items: center;

	box-sizing: border-box;
	padding: 1em;

	background: #fff;
	border-top: 1px solid #efefef;
	//border-bottom: 1px solid #efefef;
`;

export const RowElement = styled.section`
	width: ${(props) => props.width};
	text-align: ${(props) => props.align};
	text-overflow: ellipsis;
	overflow: hidden;
	//text-align: left;
	padding: 0 5px;
	white-space: ${(props) => (props.wrap ? 'pre-wrap' : 'nowrap')};

	a span {
		display: inline-block;
	}

	img {
		height: 2em;
		padding-right: 0.5em;
	}
`;

export const UnVerifiedContent = styled.section`
	button {
		//font-style: italic;
		font-weight: bold;
		background: #fff;
		color: darkgray;
		border: none;
		cursor: pointer;

		padding: 0;
	}
`;

export const Content = styled.section`
	box-sizing: border-box;
	padding: 1em;
	margin-bottom: 0.75em;

	background: whitesmoke;
	border: 1px solid #efefef;
	border-radius: 5px;
`;

export const Actions = styled.section`
	width: 100%;

	display: flex;
	justify-content: flex-end;

	margin-top: 3em;
`;

export const LeftActions = styled.section`
	width: 100%;

	display: flex;
	justify-content: space-between;

	margin-top: 3em;
`;

export const LogsHeader = styled.section`
	display: flex;
	align-items: center;
`;

export const InvoiceTableContainer = styled.section`
	max-height: 25em;

	/* Table */

	.ui.table {
		margin: 0 !important;
		border-top: none !important;
	}

	.ui.table thead tr:first-child > th {
		top: 0;

		box-sizing: border-box;
		border-top: 1px solid rgba(34, 36, 38, 0.15);
	}

	overflow-y: auto;
`;

export const TableContainer = styled.section`
	.ui.table thead tr:first-child > th {
		position: relative;
		top: 0;
		z-index: 1;
	}
`;
export const ChronoContainer = styled.section`
	//.ui.table thead tr:first-child > th {
	//	position: relative;
	//	top: 0;
	//	z-index: 1;
	//}
`;

export const Asterisk = styled.span`
	color: red;
`;
