// Excel File Types
export const EXCEL_FILE_TYPES = [
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.ms-excel.sheet.macroenabled.12',
	'application/vnd.ms-excel.sheet.binary.macroenabled.12',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
	'application/vnd.ms-excel.template.macroenabled.12',
	'text/xml',
	'application/vnd.ms-excel',
];

// Dropdown Options
export const FILE_TYPE_EXTENSIONS = [
	{
		key: 1,
		text: 'Excel',
		value: 'xlsx',
	},
	{
		key: 2,
		text: 'PDF',
		value: 'pdf',
	},
];
