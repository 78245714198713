import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import saveAs from 'file-saver';
import { Workbook } from 'exceljs';
import Logo from '../../ui/assets/images/logo.png';

function exportToExcel(data, fileName) {
	const fileType = 'logic/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	const fileExtension = '.xlsx';

	const importedData = XLSX.utils.json_to_sheet(data);
	const dataSheet = { Sheets: { data: importedData }, SheetNames: ['data'] };
	const excelBuffer = XLSX.write(dataSheet, { bookType: 'xlsx', type: 'array' });
	const payLoad = new Blob([excelBuffer], { type: fileType });
	FileSaver.saveAs(payLoad, fileName + fileExtension);
}

export default exportToExcel;

export function exportToExcelWithLogo(data, fileName, fileHeader) {
	const workbook = new Workbook();
	const worksheet = workbook.addWorksheet('data');
	worksheet.columns = fileHeader;

	const myBase64Image = Logo;
	const imageId2 = workbook.addImage({
		base64: myBase64Image,
		extension: 'png',
	});

	worksheet.mergeCells('A1:C4');
	worksheet.addImage(imageId2, 'A1:C4');
	worksheet.getRow(6).values = fileHeader.map((value) => value.key);

	let startWithIndex = 7;
	data.forEach((row) => {
		worksheet.insertRow(startWithIndex++, row);
	});

	workbook.xlsx.writeBuffer().then(function (buffer) {
		const blob = new Blob([buffer], { type: 'applicationi/xlsx' });
		saveAs(blob, `${fileName}.xlsx`);
	});
}
