import { combineReducers } from 'redux';
import auth from './reducers/auth';
import devices from './reducers/devices';
import deviceModels from './reducers/deviceModels';
import organisations from './reducers/organisations';
import sims from './reducers/sims';
import simPlans from './reducers/simPlans';
import smsReport from './reducers/smsReport';
import users from './reducers/users';
import vehicleGroups from './reducers/vehicleGroups';
import vehicles from './reducers/vehicles';
import pobs from './reducers/pobs';
import items from './reducers/items';
import subscriptions from './reducers/subscriptions';
import dates from './reducers/calender';
import calendarMonth from './reducers/calendarMonth';
import message from './reducers/messaging';
import sidebar from './reducers/sidebar';
import logs from './reducers/vehicleLogs';
import simBills from './reducers/simBills';
import documentTypes from './reducers/documentTypes';
import deviceCommands from './reducers/deviceCommands';
import commandsPipeline from './reducers/commandsPipeline';
import roles from './reducers/roles';
import customers from './reducers/customers';
import ui from './reducers/ui';
import quickbooks from './reducers/quickbooks';
import estimates from './reducers/estimates';
import invoices from './reducers/invoices';
import service from './reducers/service';
import accessModules from './reducers/accessModules';
import firebaseService from './reducers/firebase';
import customersMetrics from './reducers/customersMetrics';
import companyMetrics from './reducers/companyMetrics';
import saasPlans from './reducers/saasPlans';
import authPeople from './reducers/authPeople';
import peopleOrganisations from './reducers/peopleOrganisations';
import peopleUsers from './reducers/peopleUsers';
import trialVehicles from './reducers/trialVehicles';
import trialOrganisations from './reducers/trialOrganisations';
import accountOverview from './reducers/accountOverview';
import customersCB from './reducers/customersCB';
import subscriptionsCB from './reducers/subscriptionsCB';
import plansCB from './reducers/plansCB';
import alerts from './reducers/alerts';
import students from './reducers/students';
import fuelLogs from './reducers/fuelLogs';
import activityLogs from './reducers/activityLogs';

export default combineReducers({
	auth,
	accountOverview,
	devices,
	deviceModels,
	organisations,
	sims,
	simPlans,
	smsReport,
	users,
	vehicleGroups,
	vehicles,
	pobs,
	items,
	subscriptions,
	dates,
	calendarMonth,
	message,
	sidebar,
	logs,
	fuelLogs,
	activityLogs,
	simBills,
	documentTypes,
	deviceCommands,
	commandsPipeline,
	customers,
	ui,
	estimates,
	roles,
	service,
	accessModules,
	firebaseService,
	quickbooks,
	invoices,
	customersMetrics,
	companyMetrics,
	saasPlans,
	authPeople,
	peopleOrganisations,
	peopleUsers,
	trialVehicles,
	trialOrganisations,
	customersCB,
	subscriptionsCB,
	plansCB,
	alerts,
	students,
});
