import React from 'react';
import { Container } from './operations/OperationsView';

class Operations extends React.Component {
	constructor() {
		super();
	}

	render() {
		return <Container>You Dont Have Access To Dashboard</Container>;
	}
}

export default Operations;
