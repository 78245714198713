import React from 'react';

import { ButtonContainer, DetailsHeader, ExcelDataRow, HeaderElement, Row, RowElement } from './ImportStyles';

import * as XLSX from 'xlsx';
import stringSimilarity from 'string-similarity';
import { CSVLink } from 'react-csv';

import { Button, Dropdown, Icon, Label, Modal } from 'semantic-ui-react';
import { getData } from '../../../logic/middleware/services/apiRequest';
import { DEVICE_MODELS_API } from '../../../logic/configs/api';
import { handleError } from '../../../logic/middleware/messaging';
import { connect } from 'react-redux';
import { dispatch } from '../../../logic/middleware/middleware';

class DeviceImport extends React.Component {
	fileTemplate = [];
	headers;
	rowData;
	formattedData = [];
	allData = [];
	finalData = {};

	constructor(props) {
		super(props);
		this.errorFocusRefs = [];

		this.state = {
			loading: false,
			submitting: false,

			templateModalOpen: false,
			excelDataModalOpen: false,
			selectedHeaders: [],

			pingState: true,
			mapping: false,

			deviceModalDropdown: [],
		};

		this.closeTemplateModal = this.closeTemplateModal.bind(this);
		this.getDropdownOptions = this.getDropdownOptions.bind(this);
		this.closeExcelDataModal = this.closeExcelDataModal.bind(this);
		this.pingState = this.pingState.bind(this);
		this.sendMessages = this.sendMessages.bind(this);
		this.addRow = this.addRow.bind(this);
		this.removeRow = this.removeRow.bind(this);
		this.error = this.error.bind(this);
		this.success = this.success.bind(this);
		this.isValidManufacturer = this.isValidManufacturer.bind(this);
		this.hasInvalidManufacturer = this.hasInvalidManufacturer.bind(this);
		this.hasInvalidImei = this.hasInvalidImei.bind(this);
	}

	parseFile(file) {
		this.setState({
			loading: true,
		});

		const fileReader = new FileReader();
		const readAsBinaryString = !!fileReader.readAsBinaryString;

		fileReader.onload = (e) => {
			const binaryString = e.target.result;
			const workbook = XLSX.read(binaryString, {
				type: readAsBinaryString ? 'binary' : 'array',
				// cellDates: true,
			});

			const worksheetName = workbook.SheetNames[0];
			const workSheet = workbook.Sheets[worksheetName];

			const data = XLSX.utils.sheet_to_json(workSheet, {
				header: 1,
				blankrows: false,
				defval: '',
				raw: false,
			});

			this.mapData(data);
		};

		if (readAsBinaryString) fileReader.readAsBinaryString(file);
		else fileReader.readAsArrayBuffer(file);
	}

	componentDidMount() {
		if (this.props.template) this.fileTemplate = this.props.template;

		if (this.props.file) {
			this.parseFile(this.props.file);
		}
	}

	componentWillUnmount() {
		this.closeExcelDataModal();
		this.closeTemplateModal();
		this.cleanAllDataVariable();
		this.errorFocusRefs = [];

		if (this.props.closeImport) {
			this.props.closeImport();
		}
		this.fileTemplate = [];
	}

	mapData(data) {
		let headers = data[0];
		let rowData = data.slice(1);

		this.headers = headers;
		this.rowData = rowData;
		this.setState({
			loading: false,
		});

		this.smartFill();
		this.openTemplateModal();
	}

	smartFill() {
		let selectedHeaders = [];
		this.fileTemplate.forEach((template, index) => {
			let targetIndex = 0;
			let maxWeight = 0;
			this.headers &&
				this.headers.forEach((header, localIndex) => {
					let weight = stringSimilarity.compareTwoStrings(template.name, header ? header.toString() : '');
					if (weight > maxWeight) {
						maxWeight = weight;
						targetIndex = localIndex;
					}
				});

			if (maxWeight > 0.5) {
				selectedHeaders = Object.assign([], selectedHeaders, {
					[index]: this.headers[targetIndex],
				});
			}
		});

		this.setState({ selectedHeaders });
	}

	openTemplateModal() {
		this.setState({
			templateModalOpen: true,
		});
	}

	closeTemplateModal() {
		this.setState({
			templateModalOpen: false,
		});

		if (this.props.closeImport) {
			this.props.closeImport();
		}
	}

	getDropdownOptions(origin) {
		const options = [];
		this.headers &&
			this.headers.forEach((value, index) => {
				if (value === this.state.selectedHeaders[origin] || this.state.selectedHeaders.indexOf(value) === -1) {
					options.push({ key: index, value: value, text: value });
				}
			});

		return options;
	}

	formatData() {
		this.rowData.forEach((row) => {
			let data = [];

			this.fileTemplate.forEach((column, index) => {
				data.push(row[this.headers.indexOf(this.state.selectedHeaders[index])]);
			});

			this.formattedData.push(data);
		});
		this.allData = this.formattedData;
	}

	openExcelDataModal() {
		this.setState({
			templateModalOpen: false,
			excelDataModalOpen: true,
			mapping: false,
		});
	}

	closeExcelDataModal() {
		this.setState({
			excelDataModalOpen: false,
		});
		this.formattedData = [];

		if (this.props.closeImport) {
			this.props.closeImport();
		}
	}

	isEmpty(value) {
		return value === '' || value === undefined;
	}

	pingState() {
		this.setState({
			pingState: !this.state.pingState,
		});
	}

	convertToJson() {
		let deviceData;
		getData(DEVICE_MODELS_API, '?dropdown=true')
			.then((res) => {
				deviceData = res.data.data;
				this.finalData = this.allData.map((value) => {
					let data = {};
					this.fileTemplate.forEach((template, localIndex) => {
						if (value[localIndex])
							return Object.assign(data, {
								[template.requestTitle]: value[localIndex].toString(),
							});
					});
					return data;
				});

				let arrayToBeReturned = [];

				this.finalData.map((oneRow) => {
					let finder = deviceData.filter((obj) => oneRow.manufacturer.includes(obj.model));
					// let finder = deviceData.filter((obj) => oneRow.manufacturer.includes(obj.manufacturer));
					arrayToBeReturned.push({
						...oneRow,
						deviceModel: { id: finder[0]?.id },
					});
				});
				this.props.upload({ data: arrayToBeReturned, success: this.success, error: this.error });
			})
			.catch((error) => {
				handleError(error);
			});
	}

	success() {
		if (this.props.deviceResponse?.failedObjects.length === 0) {
			this.cleanAllDataVariable();
			this.closeExcelDataModal();
		}
		// this.setState({
		// 	submitting: false,
		// 	loading: false,
		// });
	}

	error() {
		// this.setState({
		// 	submitting: false,
		// 	loading: false,
		// });
	}

	sendMessages() {
		dispatch('BULK_IMPORT_LOAD', true);
		// this.setState({
		// 	submitting: true,
		// });
		this.convertToJson();
	}

	enterManually() {
		this.allData.push(new Array(this.fileTemplate.length).fill(''));
		this.pingState();
	}

	addRow() {
		this.allData.push(new Array(this.fileTemplate.length).fill(''));
		this.pingState();
	}

	removeRow(index) {
		this.allData.splice(index, 1);
		this.pingState();
	}

	cleanAllDataVariable() {
		this.rowData = [];
		this.headers = [];
		this.finalData = {};
		this.formattedData = [];
		this.allData = [];
		this.setState({
			selectedHeaders: [],
		});
	}

	cleanMappedDataVariable() {
		this.rowData = [];
		this.headers = [];
		this.setState({
			selectedHeaders: [],
		});
	}

	isValidImei(imei) {
		const removeSpaceImei = imei.replaceAll(/\s/g, '');
		return removeSpaceImei.toString().length === 15 && /^-?\d+$/.test(removeSpaceImei);
	}

	isValidManufacturer(manufacturerName) {
		return manufacturerName !== '';
	}

	hasInvalidImei() {
		const invalidImei = this.allData.filter((row) => !this.isValidImei(row[0]));
		return invalidImei ? invalidImei[0]?.length > 0 : false;
	}

	hasInvalidManufacturer() {
		const invalidManufacturer = this.allData.filter((row) => !this.isValidManufacturer(row[2]));
		return invalidManufacturer ? invalidManufacturer[0]?.length > 0 : false;
	}

	hasInvalidData() {
		let errorIdx;
		const invalidData = this.allData.filter((row, idx) => {
			if ((errorIdx === undefined && !this.isValidImei(row[0])) || !this.isValidManufacturer(row[2])) {
				errorIdx = idx;
			}
			return !this.isValidImei(row[0]) || !this.isValidManufacturer(row[2]);
		});

		if (invalidData.length > 0) {
			const firstInvalidInputRef = this.errorFocusRefs[(errorIdx + 4) * 4];
			firstInvalidInputRef?.current?.focus({ preventScroll: false });
		}
		return invalidData.length > 0;
	}

	render() {
		return (
			<>
				{this.state.templateModalOpen && (
					<Modal
						onClose={() => {
							this.closeTemplateModal();
							this.cleanMappedDataVariable();
						}}
						open={this.state.templateModalOpen}>
						{/*<Modal.Header>*/}
						{/*	Map Data*/}
						{/*	<span style={{ fontSize: '1rem', color: 'red', float: 'right' }}>*/}
						{/*		Fields marked with red are mandatory*/}
						{/*	</span>*/}
						{/*</Modal.Header>*/}
						<Modal.Content>
							{this.fileTemplate.map((name, index) => {
								const requiredFields = ['Device Imei', 'Device Manufacturer', 'Invoice Number', 'Invoice Date'];
								const isRequired = requiredFields.includes(name.name);
								const hasError = this.state.selectedHeaders[index] !== name.name;
								const isEmpty = this.state.selectedHeaders[index] === '';

								return (
									<Row key={index}>
										{isRequired ? (
											<>
												<div style={{ color: 'red' }}>*</div>
												<RowElement style={{ color: hasError || isEmpty ? 'red' : 'black' }}>{name.name}</RowElement>
											</>
										) : (
											<RowElement style={{ color: hasError && !isEmpty ? 'red' : 'black' }}>{name.name}</RowElement>
										)}

										<RowElement>
											<Dropdown
												error={isRequired ? hasError || isEmpty : hasError && !isEmpty}
												placeholder="Choose..."
												fluid
												selection
												clearable
												options={this.getDropdownOptions(index)}
												value={this.state.selectedHeaders[index]}
												onChange={(e, { value }) => {
													this.setState({
														selectedHeaders: Object.assign([], this.state.selectedHeaders, { [index]: value }),
													});
												}}
											/>

											{hasError && !isEmpty && (
												<Label basic color="red" pointing>
													Please enter correct header
												</Label>
											)}

											{isRequired && isEmpty && (
												<Label basic color="red" pointing>
													Required field cannot be empty
												</Label>
											)}
										</RowElement>
									</Row>
								);
							})}
						</Modal.Content>
						<Modal.Actions>
							<Button
								secondary
								color="black"
								onClick={() => {
									this.closeTemplateModal();
									this.cleanMappedDataVariable();
								}}>
								Cancel
							</Button>
							<Button
								loading={this.state.mapping}
								disabled={
									!this.state.selectedHeaders.includes('Device Imei') ||
									!this.state.selectedHeaders.includes('Device Manufacturer') ||
									!this.state.selectedHeaders.includes('Invoice Number') ||
									!this.state.selectedHeaders.includes('Invoice Date')
								}
								onClick={() => {
									this.setState({ mapping: true });
									setTimeout(() => {
										this.formatData();
										this.openExcelDataModal();
									}, 1);
								}}
								positive>
								Map
							</Button>
						</Modal.Actions>
					</Modal>
				)}

				{this.state.excelDataModalOpen && (
					<Modal
						onClose={() => {
							this.closeExcelDataModal();
							this.cleanAllDataVariable();
						}}
						open={this.state.excelDataModalOpen}>
						<Modal.Header>Delivery Details</Modal.Header>
						<DetailsHeader>
							<HeaderElement width="5%">Serial</HeaderElement>
							{this.fileTemplate.map((name, index) => {
								const isRequired = ['Device Imei', 'Device Manufacturer', 'Invoice Number', 'Invoice Date'].includes(
									name.name
								);
								return (
									<HeaderElement width={`${(90 / this.fileTemplate.length).toString()}%`} key={index}>
										{isRequired && <span style={{ color: 'red' }}>*</span>} {name.name}
									</HeaderElement>
								);
							})}
							<ButtonContainer />
						</DetailsHeader>
						{/*Padding*/}
						<div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
							{this.hasInvalidManufacturer() ? (
								<div style={{ color: 'red' }}>Please fill correct Manufacturer name</div>
							) : (
								''
							)}
							{this.hasInvalidImei() ? <div style={{ color: 'red' }}>Please fill correct Imei number</div> : ''}
						</div>
						<Modal.Content scrolling>
							<Modal.Description>
								{this.allData.map((row, index) => {
									return (
										<div key={index}>
											<ExcelDataRow>
												<>
													<label style={{ width: '5%', textAlign: 'center' }}>{index + 1}</label>
													{row.map((data, localIndex) => {
														const imei = row[0];
														const manufacturerName = row[2];
														const inputRef = React.createRef();
														const inputField = (
															<input
																key={`${index},${localIndex},${data}`}
																defaultValue={data}
																ref={inputRef}
																style={{
																	width: `${(90 / this.fileTemplate.length).toString()}%`,
																	border:
																		!this.isValidImei(imei) || !this.isValidManufacturer(manufacturerName)
																			? `2px solid red`
																			: '',
																}}
																onBlur={(e) => {
																	this.allData[index][localIndex] = e.target.value;
																}}
															/>
														);
														this.errorFocusRefs.push(inputRef);
														return inputField;
													})}
													<ButtonContainer>
														<Button
															icon
															size="small"
															style={{ background: 'none' }}
															onClick={() => {
																this.removeRow(index);
															}}>
															<Icon name="close" />
														</Button>
													</ButtonContainer>
												</>
											</ExcelDataRow>
										</div>
									);
								})}
							</Modal.Description>
						</Modal.Content>
						<Modal.Actions
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}>
							<Button onClick={this.addRow}>Add Row</Button>

							<div style={{ display: 'flex', alignItems: 'center', gap: '.3em', fontWeight: 'bold' }}>
								{this.props.deviceResponse?.failedObjects.length > 0 ? (
									<div style={{ color: 'red' }}>{`${this.props.deviceResponse?.failedCount} Failed count`}</div>
								) : (
									''
								)}

								{this.props.deviceResponse?.failedObjects.length > 0 ? (
									<CSVLink
										data={this.props.deviceResponse?.failedObjects.map((obj) => {
											const modifiedObj = {};
											for (let key in obj) {
												if (Object.prototype.hasOwnProperty.call(obj, key)) {
													let value = obj[key];
													if (typeof value === 'object' && value !== null) {
														value = JSON.stringify(value);
													}
													const modifiedKey = key.charAt(0).toUpperCase() + key.slice(1);
													if (key === 'deviceModel') {
														const parsedValue = JSON.parse(value);
														value = parsedValue.id;
													}
													modifiedObj[modifiedKey] = value;
												}
											}
											return modifiedObj;
										})}
										filename="Devices.csv">
										<Button primary title={'Click to download the failed file'} icon="download" />
									</CSVLink>
								) : (
									''
								)}
							</div>

							<div>
								<Button
									color="black"
									onClick={() => {
										this.closeExcelDataModal();
										this.cleanAllDataVariable();
									}}>
									Cancel
								</Button>
								<Button
									onClick={this.sendMessages}
									positive
									loading={this.state.bulkimportLoading}
									disabled={this.hasInvalidData()}>
									Submit
								</Button>
							</div>
						</Modal.Actions>
					</Modal>
				)}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		bulkimportLoading: state.devices.bulkimportLoading,
	};
};

export default connect(mapStateToProps)(DeviceImport);
