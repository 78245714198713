import styled from 'styled-components';

export const Container = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	img {
		width: 500px;
	}

	h4 {
		color: #aaa;
	}
`;
