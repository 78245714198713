import React from 'react';

import LoginForm from '../../blocks/organisms/LoginForm';

import { ParticleOptions } from '../../../../data/constants/particle-options';

import { Redirect } from 'react-router-dom';

import Particles from 'react-tsparticles';
import styled from 'styled-components';

const Auth = (props) => {
	const particlesInit = () => {};
	const particlesLoaded = () => {};

	if (props.location.pathname !== '/') return <Redirect to="/" />;

	return (
		<Container>
			<LoginForm />
			<Particles
				id="tsparticles"
				init={particlesInit}
				loaded={particlesLoaded}
				className="particle-container"
				options={ParticleOptions}
			/>
		</Container>
	);
};

export default Auth;

export const Container = styled.section`
	width: 100%;
	height: 100%;
	min-height: 100vh;

	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;

	.particle-container {
		position: absolute !important;
		z-index: -1;
	}
`;
