import { GraphCard, GraphContainerForBar } from './comboChartStyle';
import BasicLoader from '../../../blocks/molecules/loader/BasicLoader';
import React, { useEffect, useState } from 'react';
// import {ResponsiveBar} from '@nivo/bar';
import moment from 'moment/moment';
import { Chart } from 'react-google-charts';

function TotalOrganisationGraph(props) {
	const [organisationData, setOrganisationData] = useState([]);
	useEffect(() => {
		let totalOrgData = [];
		let { newOrganisationsMonthOnMonth, totalOrganisationCount } = props;
		if (totalOrganisationCount) {
			let totalneworganisation = newOrganisationsMonthOnMonth.reduce((total, curr) => total + parseInt(curr.count), 0);
			let currTotal = parseInt(totalOrganisationCount) - totalneworganisation;
			newOrganisationsMonthOnMonth.forEach((element) => {
				currTotal += parseInt(element.count);
				let currMonth = moment(element.month, 'YYYY-MM').format('MMM-YYYY');
				totalOrgData.push({
					Date: currMonth,
					'New Organisations': parseInt(element.count),
					'Total Organisations': currTotal,
					GrowthPercentage: `${((element.count / (currTotal - element.count)) * 100).toFixed(1)}%`,
				});
			});
			setOrganisationData(totalOrgData);
		}
	}, [props]);

	const keys = ['Date', 'New Organisations', 'Total Organisations'];

	const graphData = [
		keys,
		...organisationData.map((item) => [item.Date, item['New Organisations'], item['Total Organisations']]),
	];

	const options = {
		title: 'New Organisations (M-o-M)',
		seriesType: 'bars',
		series: {
			0: {
				targetAxisIndex: 0,
			},
			1: {
				type: 'line',
				targetAxisIndex: 1,
			},
		},
		vAxes: {
			0: {
				title: 'New Organisations',
				gridlines: { color: 'transparent' },
				titleTextStyle: {
					color: 'black',
					fontName: 'Arial',
					fontSize: 13,
					bold: true,
					italic: false,
				},
			},
			1: {
				title: 'Total Organisations',
				gridlines: { color: 'transparent' },
				titleTextStyle: {
					color: 'black',
					fontName: 'Arial',
					fontSize: 13,
					bold: true,
					italic: false,
				},
			},
		},
		legend: 'bottom',
		colors: ['#DC3A2F', '#3366CC'],
	};
	return (
		<GraphContainerForBar>
			{props.totaOrganisationLoading ? (
				<BasicLoader />
			) : (
				<GraphCard>
					{/* <GraphHeader>
                        <h1>New Organisations (M-o-M)</h1>
                    </GraphHeader>*/}
					<Chart chartType="ComboChart" width="100%" height="300px" data={graphData} options={options} />
				</GraphCard>
			)}
		</GraphContainerForBar>
	);
}

export default TotalOrganisationGraph;
