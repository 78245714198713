import { isoToDate } from '../utilities/date';

export function createVehicleDTO(data) {
	if (data && data.length !== 0) {
		return data.map((vehicle) => {
			return {
				id: vehicle?.id ? vehicle.id : null,
				make: vehicle?.make ? vehicle.make : null,
				model: vehicle?.model ? vehicle.model : null,
				registrationNumber: vehicle?.registrationNumber ? vehicle.registrationNumber : null,
				installationDate: vehicle?.installationDate ? isoToDate(vehicle.installationDate) : null,
				createdDate: vehicle?.createdDate ? isoToDate(vehicle.createdDate) : null,
				note: vehicle?.note ? vehicle.note : null,
				expectedMileage: vehicle?.expectedMileage,
				type: vehicle?.type ? vehicle.type : 1,
				chassisNumber: vehicle?.chassisNumber,
				acStatus: vehicle?.acStatus,
				speedLimit: vehicle?.speedLimit,
				idlingDuration: vehicle?.idlingDuration ? vehicle.idlingDuration : null,
				parkingDuration: vehicle?.parkingDuration ? vehicle.parkingDuration : null,
				status: vehicle?.status ? vehicle.status : 'Active',
				trialEndDate: vehicle?.trialEndDate ? isoToDate(vehicle.trialEndDate) : null,
				// hasFuelSensor: vehicle?.hasFuelSensor ? vehicle.hasFuelSensor : false,
				deletedDate: vehicle?.deletedDate ? isoToDate(vehicle.deletedDate) : null,
				// immobilizer: vehicle.immobilizer,
				isOBD: vehicle.isOBD,
				// fuelDataSource: vehicle.fuelDataSource,
				sensors: vehicle.sensors,
				odometerReading: vehicle?.odometerReading ? vehicle.odometerReading : null,
				imei: vehicle?.device?.imei ? vehicle.device.imei : null,
				latestData: {
					timestamp: vehicle?.latestData?.timestamp ? vehicle.latestData?.timestamp : null,
					relayStatus: vehicle.latestData?.relayStatus,
					currentStatus: vehicle?.latestData?.status ? vehicle.latestData.status : null,
				},
				organisationId: vehicle?.organisation?.id ? vehicle.organisation.id : null,
				organisationPhone: vehicle?.organisation?.phone ? vehicle.organisation.phone : null,
				organisation: vehicle?.organisation?.name ? vehicle.organisation.name : null,
				sim1: vehicle?.device?.sim1?.mobileNumber ? vehicle.device.sim1.mobileNumber : null,
				serviceProvider1: vehicle?.device?.sim1?.serviceProvider ? vehicle.device.sim1.serviceProvider : null,
				sim2: vehicle?.device?.sim2?.mobileNumber ? vehicle.device.sim2.mobileNumber : null,
				serviceProvider2: vehicle?.device?.sim2?.serviceProvider ? vehicle.device.sim2.serviceProvider : null,
				manufacturer: vehicle?.device?.deviceModel?.manufacturer ? vehicle.device.deviceModel.manufacturer : null,
				deviceModel: vehicle?.device?.deviceModel?.model ? vehicle.device.deviceModel.model : null,
				saasPlan: vehicle?.saasPlan?.id ? vehicle.saasPlan.id : null,
				saasPlanName: vehicle?.saasPlan?.name ? vehicle.saasPlan.name : null,
				fuelCalibration: vehicle?.fuelCalibration ? vehicle.fuelCalibration : [],
				tankCapacity: vehicle?.tankCapacity ? vehicle.tankCapacity : null,
				actualTankCapacity: vehicle?.actualTankCapacity ? vehicle.actualTankCapacity : null,
				llsCount: vehicle?.llsCount ? vehicle.llsCount : null,
				isFMSVehicle: vehicle?.isFMSVehicle ? vehicle.isFMSVehicle : false,
				deviceModifiedDate: vehicle?.device ? vehicle.device.modifiedDate : null,
				driver: vehicle?.driver
					? {
							id: vehicle.driver.id,
							name: vehicle.driver.name,
					  }
					: null,
				vehicleGroups:
					vehicle?.vehicleGroups && vehicle.vehicleGroups.length !== 0
						? vehicle.vehicleGroups.map((group) => {
								return {
									id: group.id,
									name: group.name,
								};
						  })
						: [],
				geofences:
					vehicle?.geofences && vehicle.geofences.length !== 0
						? vehicle.geofences.map((feofence) => {
								return {
									id: feofence.id,
									name: feofence.name,
								};
						  })
						: [],
			};
		});
	} else return [];
}

export function createSingleVehicleDTO(data) {
	if (data !== undefined) {
		return {
			id: data?.id ? data.id : null,
			make: data?.make ? data.make : null,
			model: data?.model ? data.model : null,
			registrationNumber: data?.registrationNumber ? data.registrationNumber : null,
			installationDate: data?.installationDate ? isoToDate(data.installationDate) : null,
			note: data?.note ? data.note : null,
			type: data?.type ? data.type : 1,
			hasFuelSensor: data?.hasFuelSensor ? data.hasFuelSensor : false,
			acStatus: data?.acStatus ? data.acStatus : false,
			deletedDate: data?.deletedDate ? isoToDate(data.deletedDate) : null,
			immobilizer: data?.immobilizer ? data.immobilizer : false,
			odometer: data?.odometer ? data.odometer : null,
			imei: data?.device?.imei ? data.device.imei : null,
			organisationId: data?.organisation?.id ? data.organisation.id : null,
			organisationPhone: data?.organisation?.phone ? data.organisation.phone : null,
			organisation: data?.organisation?.name ? data.organisation.name : null,
			sim1: data?.device?.sim1?.mobileNumber ? data.device.sim1.mobileNumber : null,
			serviceProvider1: data?.device?.sim1?.serviceProvider ? data.device.sim1.serviceProvider : null,
			sim2: data?.device?.sim2?.mobileNumber ? data.device.sim2.mobileNumber : null,
			serviceProvider2: data?.device?.sim2?.serviceProvider ? data.device.sim2.serviceProvider : null,
			manufacturer: data?.device?.deviceModel?.manufacturer ? data.device.deviceModel.manufacturer : null,
			deviceModel: data?.device?.deviceModel?.model ? data.device.deviceModel.model : null,
			saasPlan: data?.saasPlan?.id ? data.saasPlan.id : null,
			saasPlanName: data?.saasPlan?.name ? data.saasPlan.name : null,
			fuelCalibration: data?.fuelCalibration ? data.fuelCalibration : [],
			tankCapacity: data?.tankCapacity ? data.tankCapacity : null,
			actualTankCapacity: data?.actualTankCapacity ? data.actualTankCapacity : null,
			llsCount: data?.llsCount ? data.llsCount : null,
			vehicleGroups:
				data?.vehicleGroups && data.vehicleGroups.length !== 0
					? data.vehicleGroups.map((group) => {
							return {
								id: group.id,
								name: group.name,
							};
					  })
					: [],
		};
	} else return {};
}
