import styled from 'styled-components';

export const Container = styled.section`
	width: 100%;

	display: flex;
	justify-content: space-between;
	align-items: center;

	white-space: nowrap;

	padding: 1em 0 1em 1em;

	.ui.toggle.checkbox input:checked ~ .box:before,
	.ui.toggle.checkbox input:checked ~ label:before {
		background-color: ${(props) => props.theme.theme} !important;
	}

	.ui.toggle.checkbox input:focus:checked ~ .box:before,
	.ui.toggle.checkbox input:focus:checked ~ label:before {
		background-color: ${(props) => props.theme.theme} !important;
	}
`;
