import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
	closeFilterModal,
	exportAllForVehicles,
	openFilterModal,
	refreshDeviceLogs,
} from '../../../../../logic/middleware/vehicleLogs';
import { dispatch, dispatchNoPayload } from '../../../../../logic/middleware/middleware';
import moment from 'moment';
// import Calendar from '../../../common/calender/standard/Calender';
import { Button, Checkbox, Dropdown, Icon, Loader, Segment } from 'semantic-ui-react';
import { AutoRefresh, TableContainer } from '../../../styles/Containers';

import { COLORS } from '../../../../styles/colors';

import { connect } from 'react-redux';
import { CategoryContentMembrane } from '../../../styles/containers/CategoryContentMembrane';
import { CategoryHeader } from '../../../styles/containers/CategoryHeader';
// import BasicLoader from '../../blocks/molecules/loader/BasicLoader';
// import { timeDifference } from '../../../../../logic/utilities/date';
// import { isoToHumanReadable } from '../../../../../logic/utilities/date';
import PlaceHolder from '../../../blocks/molecules/place-holder/PlaceHolder';

import { CategoryFooter } from '../../../styles/containers/CategoryFooter';
import FooterPagination from '../../../blocks/molecules/pagination/Pagination';

import FilterModal from '../../../blocks/organisms/modals/vehicle-logs/FilterModal';
import { trackMixpanelEvent } from '../../../../../logic/middleware/mixpanel';
import Calendar from '../../../../common/calender/standard/Calender';
import { Container } from '../../../blocks/molecules/place-holder/PlaceHolderStyles';
import { FILE_TYPE_EXTENSIONS } from '../../../../../data/constants/file-types';
// import styled from 'styled-components';
import { RolesToggle } from '../../../blocks/molecules/toggle-switch/ToggleSwitchStyles';
import BasicLoader from '../../../blocks/molecules/loader/BasicLoader';
import './../../../styles/VehicleLogs.css';
// import DeleteModal from '../../../blocks/organisms/modals/vehicle-logs/DeleteModal';
import { document } from 'mixpanel-browser/src/utils';
//Ag Grid
// import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
// import 'ag-grid-community/styles/ag-grid.css';
// import 'ag-grid-community/styles/ag-theme-material.css';
// import 'ag-custom-loading-cell.css';
//Battery Icons
import BatteryGauge from 'react-battery-gauge';
//React Icons
import { TiTickOutline, TiTimesOutline } from 'react-icons/ti';
import { isoToHumanReadable, timeDifference } from '../../../../../logic/utilities/date';
//css
import './VehcileLogs.css';
import Store from '../../../../../data/store/store';

const CustomLinkRenderer = (params) => {
	const { value } = params;

	const openGoogleMap = () => {
		const [latitude, longitude] = value.split(',').map(Number);
		if (!isNaN(latitude) && !isNaN(longitude)) {
			const url = `https://www.google.com/maps/place/${parseFloat(latitude)},${parseFloat(longitude)}`;
			window.open(url, '_blank');
		}
	};

	return (
		<span style={{ cursor: 'pointer', color: '#1B3C71' }} onClick={openGoogleMap}>
			{value}
		</span>
	);
};

const CustomBatteryRenderer = (param) => {
	return (
		<div title={`Battery level is ${param.value}%`}>
			<BatteryGauge animated={true} size={80} aspectRatio={10 / 22} value={param.value} />
		</div>
	);
};

// const CustomDeleteRenderer = ({ value }) => {
// 	const handleDeleteClick = () => {
// 		dispatchNoPayload('LOGS_DELETE_MODAL_OPEN');
// 		dispatch('DELETE_LOGS', value);
// 	};
//
// 	return (
// 		<Button color="red" onClick={handleDeleteClick} size="medium">
// 			Delete
// 		</Button>
// 	);
// };

const CheckboxCellRenderer = ({ value }) => {
	if (typeof value === 'boolean') {
		const Icon = value ? TiTickOutline : TiTimesOutline;
		const color = value ? 'green' : 'red';

		return (
			<span style={{ color, fontSize: '25px', display: 'inline-block' }}>
				<Icon />
			</span>
		);
	} else {
		return <span>{value}</span>;
	}
};

const CustomCellRender = ({ value }) => {
	const color = value > 0 ? 'green' : 'red';

	return <span style={{ color }}>{value?.toLocaleString()}</span>;
};

const CustomGsmSignalRender = ({ value }) => {
	return (
		<div title={`GSM Signal strength is ${value}%`}>
			<span>{value === 'No Signal' ? 'No Signal' : `${value}%`}</span>
		</div>
	);
};

const VehicleLogs = (props) => {
	let selectAllActive = false;
	let vehicleId = '';

	//Disable filter field
	// let acStatus = null;
	let ignition = null;
	let GPS = null;
	// let panicButton = null;
	// let deleteId = '';
	let externalPowerStatus = null;
	// let updatePoint=0;

	let vehicleNumber = '';

	// let activeFilters = {
	// 	ignition: null,
	// 	gps: null,
	// 	externalPowerStatus: null,
	// 	// acStatus: null,
	// 	// panicButton: null,
	// 	immobilizer: [],
	// };

	let isSuperAdminAccess = JSON.parse(localStorage.getItem('isSuperAdmin'));
	let currentTimestamp = null;
	const ref = useRef();

	//filtering state
	const [filters, setFilters] = useState({
		ignition: null,
		gps: null,
		externalPowerStatus: null,
		relayStatus: null,
		// acStatus: null,
		// panicButton: null,
		// immobilizer: [],
	});

	const [initialLoading, setInitialLoading] = useState(false);
	const [fileExtension, setFileExtension] = useState('pdf');
	const [autoRefresh, setAutoRefresh] = useState(false);
	const [autoRefreshIntervalId, setAutoRefreshIntervalId] = useState(null);
	const [prevProps, setPrevProps] = useState(null);
	const [disableExportButton, setDisableExportButton] = useState(false);

	//sorting state
	const [activeSort, setActiveSort] = useState('timestamp');
	const [sortOrder, setSortOrder] = useState('desc');

	// toggling state
	const [initialDate, setInitialDate] = useState();
	const [finalDate, setFinalDate] = useState();
	const [logs, setLogs] = useState();
	const [pageNumber, setpageNumber] = useState();
	const [hasRelay, setHasRelay] = useState(true);

	// const LogId = useSelector((state) => state.logs.DeleteLogId);
	let updatePoint = 0;

	//Ag-grid variables and hook
	const [columnDefs, setColumnDefs] = useState([]);
	//Data grid column data
	const columnDefsBase = [
		{
			field: 'Time',
			sortable: true,
			headerName: 'Time',
			width: 220,
			colId: 'timestamp',
			filter: 'agTimeColumnFilter',
			pinned: 'left',
			lockPinned: true,
			icons: {
				sortAscending: '<i class="fa fa-sort-alpha-down" style="font-size: 15px"/>',
				sortDescending: '<i class="fa fa-sort-alpha-up" style="font-size: 15px" />',
			},
			comparator: (date1, date2) => {
				// Convert the formatted time strings back to Date objects for comparison
				const dateObj1 = moment(date1, 'DD-MM-YYYY, hh:mm:ss A').toDate();
				const dateObj2 = moment(date2, 'DD-MM-YYYY, hh:mm:ss A').toDate();

				// Compare the Date objects for sorting
				if (dateObj1 < dateObj2) {
					return -1;
				} else if (dateObj1 > dateObj2) {
					return 1;
				} else {
					return 0;
				}
			},
			cellStyle: { borderRightColor: '#e2e2e2', textAlign: 'center' },
		},
		{
			field: 'Created Date',
			headerName: 'Created Date',
			colId: 'createdDate',
			sortable: true,
			icons: {
				sortAscending: '<i class="fa fa-sort-alpha-down" style="font-size: 15px"/>',
				sortDescending: '<i class="fa fa-sort-alpha-up" style="font-size: 15px" />',
			},
			comparator: (date1, date2) => {
				// Convert the formatted time strings back to Date objects for comparison
				const dateObj1 = moment(date1, 'DD-MM-YYYY, hh:mm:ss A').toDate();
				const dateObj2 = moment(date2, 'DD-MM-YYYY, hh:mm:ss A').toDate();

				// Compare the Date objects for sorting
				if (dateObj1 < dateObj2) {
					return -1;
				} else if (dateObj1 > dateObj2) {
					return 1;
				} else {
					return 0;
				}
			},
			// flex: 3,
			cellStyle: { borderRightColor: '#e2e2e2', textAlign: 'center' },
		},
		{
			field: 'Coordinates',
			cellRenderer: CustomLinkRenderer,
			headerName: 'Coordinates',
			colId: 'coordinates',
			// flex: 4,
			cellStyle: { borderRightColor: '#e2e2e2', textAlign: 'center' },
		},
		{
			field: 'Ignition',
			headerName: 'Ignition',
			sortable: true,
			colId: 'ignitionStatus',
			// flex: 2,
			width: 100,
			icons: {
				sortAscending: '<i class="fa fa-sort-alpha-down" style="font-size: 15px"/>',
				sortDescending: '<i class="fa fa-sort-alpha-up" style="font-size: 15px" />',
			},
			cellRenderer: CheckboxCellRenderer,
			cellStyle: { borderRightColor: '#e2e2e2', textAlign: 'center' },
		},
		{
			field: 'GPS',
			headerName: 'GPS',
			sortable: true,
			// flex: 2,
			width: 90,
			icons: {
				sortAscending: '<i class="fa fa-sort-alpha-down" style="font-size: 15px"/>',
				sortDescending: '<i class="fa fa-sort-alpha-up" style="font-size: 15px" />',
			},
			colId: 'gnssStatus',
			cellRenderer: CheckboxCellRenderer,
			cellStyle: { borderRightColor: '#e2e2e2', textAlign: 'center' },
		},
		{
			field: 'Relay',
			headerName: 'Relay',
			sortable: true,
			// flex: 2,
			width: 100,
			icons: {
				sortAscending: '<i class="fa fa-sort-alpha-down" style="font-size: 15px"/>',
				sortDescending: '<i class="fa fa-sort-alpha-up" style="font-size: 15px" />',
			},
			colId: 'relayStatus',
			cellRenderer: CheckboxCellRenderer,
			cellStyle: { borderRightColor: '#e2e2e2', textAlign: 'center' },
		},
		{
			field: 'Panic',
			headerName: 'SOS',
			//sortable: true,
			// flex: 2,
			width: 75,
			/*icons: {
				sortAscending: '<i class="fa fa-sort-alpha-down" style="font-size: 15px"/>',
				sortDescending: '<i class="fa fa-sort-alpha-up" style="font-size: 15px" />',
			},*/
			colId: 'panicButton',
			cellRenderer: CheckboxCellRenderer,
			cellStyle: { borderRightColor: '#e2e2e2', textAlign: 'center' },
		},
		{
			field: 'Altitude',
			filter: 'agNumberColumnFilter',
			headerName: 'Altitude',
			// flex: 2,
			width: 100,
			sortable: true,
			icons: {
				sortAscending: '<i class="fa fa-sort-alpha-down" style="font-size: 15px"/>',
				sortDescending: '<i class="fa fa-sort-alpha-up" style="font-size: 15px" />',
			},
			colId: 'altitude',
			cellRenderer: CustomCellRender,
			cellStyle: { borderRightColor: '#e2e2e2', textAlign: 'center' },
		},
		{
			field: 'Angle',
			filter: 'agNumberColumnFilter',
			headerName: 'Angle',
			sortable: true,
			icons: {
				sortAscending: '<i class="fa fa-sort-alpha-down" style="font-size: 15px"/>',
				sortDescending: '<i class="fa fa-sort-alpha-up" style="font-size: 15px" />',
			},
			// flex: 2,
			width: 100,
			colId: 'angle',
			cellRenderer: CustomCellRender,
			cellStyle: { borderRightColor: '#e2e2e2', textAlign: 'center' },
		},
		{
			field: 'Satellites',
			filter: 'agNumberColumnFilter',
			headerName: 'Satellites',
			sortable: true,
			// flex: 2,
			width: 100,
			icons: {
				sortAscending: '<i class="fa fa-sort-alpha-down" style="font-size: 15px"/>',
				sortDescending: '<i class="fa fa-sort-alpha-up" style="font-size: 15px" />',
			},
			colId: 'satellites',
			cellRenderer: CustomCellRender,
			cellStyle: { borderRightColor: '#e2e2e2', textAlign: 'center' },
		},
		{
			field: 'Power',
			headerName: 'Power',
			colId: 'externalPowerVoltage',
			sortable: true,
			icons: {
				sortAscending: '<i class="fa fa-sort-alpha-down" style="font-size: 15px"/>',
				sortDescending: '<i class="fa fa-sort-alpha-up" style="font-size: 15px" />',
			},
			// flex: 2,
			width: 100,
			cellRenderer: CustomCellRender,
			cellStyle: { borderRightColor: '#e2e2e2', textAlign: 'center' },
		},
		{
			field: 'External Power',
			headerName: 'External Power',
			colId: 'externalPowerStatus',
			// flex: 2.5,
			width: 120,
			cellRenderer: CheckboxCellRenderer,
			cellStyle: { borderRightColor: '#e2e2e2', textAlign: 'center' },
		},
		{
			field: 'Speed',
			headerName: 'Speed',
			sortable: true,
			colId: 'speed',
			// flex: 2,
			width: 100,
			icons: {
				sortAscending: '<i class="fa fa-sort-alpha-down" style="font-size: 15px"/>',
				sortDescending: '<i class="fa fa-sort-alpha-up" style="font-size: 15px" />',
			},
			cellRenderer: CustomCellRender,
			cellStyle: { borderRightColor: '#e2e2e2', textAlign: 'center' },
		},
		{
			field: 'Distance',
			headerName: 'Distance',
			sortable: true,
			colId: 'distance',
			// flex: 2,
			width: 100,
			icons: {
				sortAscending: '<i class="fa fa-sort-alpha-down" style="font-size: 15px"/>',
				sortDescending: '<i class="fa fa-sort-alpha-up" style="font-size: 15px" />',
			},
			cellRenderer: CustomCellRender,
			cellStyle: { borderRightColor: '#e2e2e2', textAlign: 'center' },
		},
		{
			field: 'Total Odometer',
			headerName: 'Total Odometer',
			colId: 'totalOdometer',
			sortable: true,
			icons: {
				sortAscending: '<i class="fa fa-sort-alpha-down" style="font-size: 15px"/>',
				sortDescending: '<i class="fa fa-sort-alpha-up" style="font-size: 15px" />',
			},
			// flex: 2.5,
			width: 130,
			cellStyle: { borderRightColor: '#e2e2e2', textAlign: 'center' },
		},
		// {
		// 	field: 'AC Status',
		// 	headerName: 'AC Status',
		// 	colId: 'acStatus',
		// 	// flex: 2,
		// 	width: 100,
		// 	cellStyle: (param) => {
		// 		if (param.value === 'Not Available') {
		// 			return { color: 'grey', borderRightColor: '#e2e2e2', textAlign: 'center' };
		// 		}
		// 		return { borderRightColor: '#e2e2e2', textAlign: 'center' };
		// 	},
		// },
		// {
		// 	field: 'Panic Button',
		// 	headerName: 'Panic Button',
		// 	colId: 'panicButton',
		// 	// flex: 2.5,
		// 	width: 110,
		// 	cellStyle: (param) => {
		// 		if (param.value === 'Not Available') {
		// 			return { color: 'grey', borderRightColor: '#e2e2e2', textAlign: 'center' };
		// 		}
		// 		return { borderRightColor: '#e2e2e2', textAlign: 'center' };
		// 	},
		// 	cellRenderer: CheckboxCellRenderer,
		// },
		{
			field: 'Movement Status',
			headerName: 'Movement Status',
			width: 135,
			colId: 'movementStatus',
			sortable: true,
			icons: {
				sortAscending: '<i class="fa fa-sort-alpha-down" style="font-size: 15px"/>',
				sortDescending: '<i class="fa fa-sort-alpha-up" style="font-size: 15px" />',
			},
			cellStyle: (param) => {
				if (param.value === 'Not Available') {
					return { color: 'grey', borderRightColor: '#e2e2e2', textAlign: 'center' };
				}
				return { borderRightColor: '#e2e2e2', textAlign: 'center' };
			},
			cellRenderer: CheckboxCellRenderer,
		},
		{
			field: 'Trip Odometer',
			headerName: 'Trip Odometer',
			width: 120,
			colId: 'tripOdometer',
			sortable: true,
			icons: {
				sortAscending: '<i class="fa fa-sort-alpha-down" style="font-size: 15px"/>',
				sortDescending: '<i class="fa fa-sort-alpha-up" style="font-size: 15px" />',
			},
			cellStyle: { borderRightColor: '#e2e2e2', textAlign: 'center' },
			cellRenderer: CustomCellRender,
		},
		{
			field: 'Vehicle Status',
			headerName: 'Vehicle Status',
			width: 120,
			colId: 'status',
			sortable: true,
			icons: {
				sortAscending: '<i class="fa fa-sort-alpha-down" style="font-size: 15px"/>',
				sortDescending: '<i class="fa fa-sort-alpha-up" style="font-size: 15px" />',
			},
			cellStyle: (param) => {
				if (param.value === 'Not Available') {
					return { color: 'grey', borderRightColor: '#e2e2e2', textAlign: 'center' };
				}
				return { borderRightColor: '#e2e2e2', textAlign: 'center' };
			},
		},
		{
			field: 'DTE',
			headerName: 'DTE',
			colId: 'dte',
			sortable: true,
			icons: {
				sortAscending: '<i class="fa fa-sort-alpha-down" style="font-size: 15px"/>',
				sortDescending: '<i class="fa fa-sort-alpha-up" style="font-size: 15px" />',
			},
			// flex: 1.5,
			width: 80,
			cellRenderer: CustomCellRender,
			cellStyle: { borderRightColor: '#e2e2e2', textAlign: 'center' },
		},
		{
			field: 'SOC',
			headerName: 'SOC',
			colId: 'soc',
			sortable: true,
			icons: {
				sortAscending: '<i class="fa fa-sort-alpha-down" style="font-size: 15px"/>',
				sortDescending: '<i class="fa fa-sort-alpha-up" style="font-size: 15px" />',
			},
			// flex: 1.5,
			width: 80,
			cellRenderer: CustomCellRender,
			cellStyle: { borderRightColor: '#e2e2e2', textAlign: 'center' },
		},
		{
			field: 'HDOP',
			headerName: 'HDOP',
			colId: 'gnssHDOP',
			sortable: true,
			// flex: 1.5,
			width: 80,
			cellRenderer: CustomCellRender,
			icons: {
				sortAscending: '<i class="fa fa-sort-alpha-down" style="font-size: 15px"/>',
				sortDescending: '<i class="fa fa-sort-alpha-up" style="font-size: 15px" />',
			},
			cellStyle: { borderRightColor: '#e2e2e2', textAlign: 'center' },
		},
		{
			field: 'PDOP',
			headerName: 'PDOP',
			colId: 'gnssPDOP',
			// flex: 1.5,
			sortable: true,
			width: 80,
			cellRenderer: CustomCellRender,
			icons: {
				sortAscending: '<i class="fa fa-sort-alpha-down" style="font-size: 15px"/>',
				sortDescending: '<i class="fa fa-sort-alpha-up" style="font-size: 15px" />',
			},
			cellStyle: { borderRightColor: '#e2e2e2', textAlign: 'center' },
		},
		{
			field: 'GSMSignal',
			headerName: 'GSM Signal',
			colId: 'gsmSignal',
			// flex: 2,
			width: 100,
			sortable: true,
			icons: {
				sortAscending: '<i class="fa fa-sort-alpha-down" style="font-size: 15px"/>',
				sortDescending: '<i class="fa fa-sort-alpha-up" style="font-size: 15px" />',
			},
			cellRenderer: CustomGsmSignalRender,
			cellStyle: { borderRightColor: '#e2e2e2', textAlign: 'center' },
		},
		{
			field: 'Battery level',
			cellRenderer: CustomBatteryRenderer,
			headerName: 'Battery level',
			colId: 'batteryLevel',
			sortable: true,
			icons: {
				sortAscending: '<i class="fa fa-sort-alpha-down" style="font-size: 15px"/>',
				sortDescending: '<i class="fa fa-sort-alpha-up" style="font-size: 15px" />',
			},
			// flex: 2.5,
			width: 120,
			cellStyle: { borderRightColor: '#e2e2e2', textAlign: 'center', height: '100vh', padding: '0px' },
		},
		// {
		// 	field: 'Delete',
		// 	cellRenderer: CustomDeleteRenderer,
		// 	hide: !isSuperAdminAccess,
		// 	// flex: 2.5,
		// 	width: 100,
		// 	cellStyle: { borderRightColor: '#e2e2e2', textAlign: 'center', height: '100vh', padding: '0px' },
		// },
	];
	//Data grid body data
	const [rowData, setRowData] = useState({});
	const [urlDates, setUrlDates] = useState({
		startDate: null,
		endDate: null,
	});

	// On top layout will run when component mounts
	useEffect(() => {
		vehicleId = props.location?.state?.vehicleId;

		if (vehicleId) {
			refreshDeviceLogs(vehicleId, `&sortBy=timestamp&orderBy=desc`);
		}

		if (props.dropdownValue) {
			refreshDeviceLogs(props.dropdownValue, `&sortBy=timestamp&orderBy=desc`);
		}

		if (vehicleId) {
			props.onChangeValue(vehicleId);
		}

		let path = window.location.pathname.split('/');

		if (path.length > 3) {
			if (path[4] !== undefined && path[5] !== undefined) {
				Store.dispatch({
					type: 'SET_DATES',
					payload: {
						initialDate: path[4],
						finalDate: path[5],
					},
				});
				setUrlDates({
					startDate: path[4],
					endDate: path[5],
				});
			}
			vehicleNumber = decodeURIComponent(path[3]);

			if (vehicleNumber) {
				refreshDeviceLogs(vehicleNumber, `&sortBy=timestamp&orderBy=desc`);
				props.onChangeValue(vehicleNumber);
			}
		}
		setActiveSort('timestamp');
		setSortOrder('desc');

		return () => {
			// dispatchNoPayload('RESET_DATES');
			const width = ref.current?.clientWidth;
			localStorage.setItem('width', JSON.stringify(width + 18) + 'px');
			dispatchNoPayload('LOGS_UNMOUNT');
			if (autoRefreshIntervalId) clearInterval(autoRefreshIntervalId);
		};
	}, []);

	//will run when any dependency changes
	useEffect(() => {
		setInitialDate(props.initialDate);
		setFinalDate(props.finalDate);

		const difference = moment.duration(moment(finalDate).diff(moment(initialDate))).asDays();
		setDisableExportButton(() => difference >= 1);

		setLogs(props.logs);
		setpageNumber(props.pageNumber);

		if (props.initialDate !== initialDate || props.finalDate !== finalDate) {
			getFilteredLogs();
		}

		if (logs !== props.logs) {
			if (selectAllActive) handleSelectAll(true);
		}

		if (pageNumber !== props.pageNumber) {
			document.getElementById('TableContainer').scrollTo(0, 0);
		}

		if (!currentTimestamp) {
			currentTimestamp = props.logs[0]?.timestamp;
		}

		if (!props.loading) {
			setInitialLoading(false);
		}

		if (autoRefresh && props.logs !== prevProps) {
			updatePoint = changeDataCount(prevProps.logs, props.logs);
		}
	}, [props.loading, props.logs, props.pageNumber, props.initialDate, props.finalDate, filters]);

	//This useEffect is used for Ag-grid integration
	useEffect(() => {
		setColumnDefs(columnDefsBase);

		if (props.logs) {
			const formattedLogs = props.logs.map((log, index) => {
				const formattedTime = `${isoToHumanReadable(log.timestamp)} (${timeDifference(
					moment(log.createdDate).diff(moment(log.timestamp), 'seconds')
				)})`;
				const formattedDate = `${isoToHumanReadable(log.createdDate)}`;

				if (log.relayStatus === 'No Relay') {
					setHasRelay(false);
				}

				const row = {
					id: index,
					Time: formattedTime,
					'Created Date': formattedDate,
					Coordinates: `${log.latitude} , ${log.longitude}`,
					Ignition: log.ignitionStatus,
					GPS: renderGps(log.gnssStatus),
					Relay: log?.relayStatus,
					Altitude: Number(log.altitude),
					Angle: Number(log.angle),
					Satellites: Number(log.satellites),
					Power: renderPower(log.externalPowerVoltage, log.internalPowerVoltage),
					'External Power': log.externalPowerStatus === 'True',
					Speed: log.speed,
					Distance: log.distance,
					'Total Odometer': log.totalOdometer,
					'Movement Status': log?.movementStatus,
					'Trip Odometer': log.tripOdometer / 1000,
					'Vehicle Status': log.vehicleStatus || 'Not Available',
					// 'AC Status': log.acStatus || 'Not Available',
					Panic: log?.panicButton,
					SOC: log.soc,
					HDOP: log?.gnssHDOP,
					PDOP: log?.gnssPDOP,
					GSMSignal: log.gsmSignal,
					'Battery level': log.batteryLevel,
				};
				// if (isSuperAdminAccess) {
				// 	row.Delete = log.id;
				// }
				return row;
			});
			const uniqueFormattedLogs = Array.from(new Set(formattedLogs.map(JSON.stringify))).map(JSON.parse);
			// setUniqueData(uniqueFormattedLogs);
			setRowData(uniqueFormattedLogs);
		}
	}, [isSuperAdminAccess, props.logs]);

	//This useEffect is used for setting previous value of props
	useEffect(() => {
		setPrevProps(props);
	}, [props]);

	const changeDataCount = (prevData, newData) => {
		if (!prevData?.length) return newData.length;

		const target = prevData[0];
		for (let i = 0; i < newData.length; i++) {
			if (moment(target.timestamp, 'DD-MM-YYYY HH:mm:ss').isSame(moment(newData[i].timestamp, 'DD-MM-YYYY HH:mm:ss'))) {
				return i;
			}
		}
	};

	const shouldRowHighlight = (param) => {
		if (activeSort === 'timestamp') {
			if (sortOrder === 'desc' && param.data.id < updatePoint) {
				return {
					color: '#f37400',
					backgroundColor: '#feefe3',
					fontWeight: 'bold',
				};
			}
		}
		return {};
	};

	const getFilteredLogs = (activePage = 1, isLiveRequest, currentDate, endDate) => {
		let query = '';

		if (activeSort !== '' && sortOrder !== '') {
			query = query + `&sortBy=${activeSort}&orderBy=${sortOrder}`;
		}

		if (filters.ignition != null && Number.isInteger(filters.ignition)) {
			if (filters.ignition === 1) {
				query = query + `&ignitionStatus=true`;
			} else {
				query = query + `&ignitionStatus`;
			}
		}
		if (filters.gps != null && Number.isInteger(filters.gps)) {
			if (filters.gps === 0) {
				query = query + `&gnssStatus[]=0&gnssStatus[]=2&gnssStatus[]=3&gnssStatus[]=4`;
			}
			if (filters.gps === 1) {
				query = query + `&gnssStatus[]= 1`;
			}
		}

		if (filters.externalPowerStatus !== null && Number.isInteger(filters.externalPowerStatus)) {
			if (filters.externalPowerStatus === 1) {
				query = query + `&externalPowerStatus=true`;
			} else {
				query = query + `&externalPowerStatus`;
			}
		}

		// if (activeFilters.acStatus !== null && Number.isInteger(activeFilters.acStatus)) {
		// 	if (activeFilters.acStatus === 1) {
		// 		query = query + `&acStatus=true`;
		// 	} else {
		// 		query = query + `&acStatus`;
		// 	}
		// }
		if (filters.relayStatus !== null && Number.isInteger(filters.relayStatus)) {
			if (filters.relayStatus === 1) {
				query = query + `&relayStatus=true`;
			} else {
				query = query + `&relayStatus`;
			}
		}

		// if (activeFilters.panicButton !== null && Number.isInteger(activeFilters.panicButton)) {
		// 	if (activeFilters.panicButton === 1) {
		// 		query = query + `&panicButton=true`;
		// 	} else {
		// 		query = query + `&panicButton`;
		// 	}
		// }

		// if (filters.immobilizer.length && filters.immobilizer.length < 2) {
		// 	query = query + `&immobilizer=${filters.immobilizer.join(',')}`;
		// }
		if (props.dropdownValue && !isLiveRequest) {
			refreshDeviceLogs(props.dropdownValue, query, activePage);
		} else if (props.dropdownValue && isLiveRequest && prevProps !== props.logs[0]) {
			refreshDeviceLogs(props.dropdownValue, '', 1, false, isLiveRequest, currentDate, endDate, props.logs[0]);
			currentTimestamp = props.logs[0]?.timestamp;
		} else if (vehicleId) {
			refreshDeviceLogs(vehicleId, query, activePage);
		}
	};

	const sort = (activeSort, sortOrder) => {
		dispatch('VEHICLE_LOGS_SORT_ORDER', sortOrder);
		dispatch('VEHICLE_LOGS_ACTIVE_ORDER', activeSort);
		getFilteredLogs(props.pageNumber);
	};

	const fileExtensionChange = (e, { value }) => {
		setFileExtension(value);
	};

	// const cleanDeleteOperation = () => {
	// 	dispatchNoPayload('LOGS_DELETE_MODAL_CLOSE');
	// };

	const handleSelectAll = (checked) => {
		let selectedRows = new Set();

		if (checked) {
			selectAllActive = true;
			props.logs.forEach((log) => selectedRows.add(log.id));
		} else {
			selectAllActive = false;
		}
	};

	const getFilterListQuery = () => {
		let query = '';

		query = query + `&fileType=${fileExtension}`;

		if (activeSort !== '' && sortOrder !== '') {
			if (sortOrder === 'asc') {
				query = query + `&sortBy=${activeSort}`;
			} else {
				query = query + `&sortBy=${activeSort}`;
			}
		}

		if (filters.ignition != null && Number.isInteger(filters.ignition)) {
			if (filters.ignition === 1) {
				query = query + `&ignitionStatus=true`;
			} else {
				query = query + `&ignitionStatus`;
			}
		}
		if (filters.gps !== null && filters.gps.length < 2) {
			if (filters.gps.includes(0)) {
				query = query + `&gnssStatus[]=&gnssStatus[]=0&gnssStatus[]=2&gnssStatus[]=3&gnssStatus[]=4`;
			} else {
				query = query + `&gnssStatus[]=${filters.gps.join(',')}`;
			}
		}

		if (filters.externalPowerStatus !== null && Number.isInteger(filters.externalPowerStatus)) {
			if (filters.externalPowerStatus === 1) {
				query = query + `&externalPowerStatus=true`;
			} else {
				query = query + `&externalPowerStatus`;
			}
		}

		// if (filters.acStatus !== null && Number.isInteger(filters.acStatus)) {
		// 	if (filters.acStatus === 1) {
		// 		query = query + `&acStatus=true`;
		// 	} else {
		// 		query = query + `&acStatus`;
		// 	}
		// }

		// if (filters.panicButton !== null && Number.isInteger(filters.panicButton)) {
		// 	if (filters.panicButton === 1) {
		// 		query = query + `&panicButton=true`;
		// 	} else {
		// 		query = query + `&panicButton`;
		// 	}
		// }

		// if (filters.immobilizer.length && filters.immobilizer.length < 2) {
		// 	query = query + `&immobilizer=${filters.immobilizer.join(',')}`;
		// }

		return query;
	};

	const onIgnitionFilterChange = (e, { value }) => {
		setFilters((prevFilters) => ({
			...prevFilters,
			ignition: value,
		}));
	};

	const onGPSFilterChange = (e, { value }) => {
		setFilters((prevFilters) => ({
			...prevFilters,
			gps: value,
		}));
	};

	const onExternalFilterChange = (e, { value }) => {
		setFilters((prevFilters) => ({
			...prevFilters,
			externalPowerStatus: value,
		}));
	};

	// const onAcStatusFilterChange = (e, { value }) => {
	// 	setFilters((prevFilters) => ({
	// 		...prevFilters,
	// 		acStatus: value,
	// 	}));
	// };

	// const onPanicButtonFilterChange = (e, { value }) => {
	// 	// this.setState({
	// 	// 	filters: { ...this.state.filters, panicButton: value },
	// 	// });
	// 	setFilters((prevFilters) => ({
	// 		...prevFilters,
	// 		panicButton: value,
	// 	}));
	// };

	const onRelayStatusFilterChange = (e, { value }) => {
		setFilters((prevFilters) => ({
			...prevFilters,
			relayStatus: value,
		}));
	};

	// const onImmobilizerFilterChange = (e, { value }) => {
	// 	setFilters((prevFilters) => ({
	// 		...prevFilters,
	// 		immobilizer: value,
	// 	}));
	// };

	const setFilterChange = (ignition, gps, externalPowerStatus, relayStatus) => {
		setFilters((prevFilters) => ({
			...prevFilters,
			ignition,
			gps,
			// immobilizer,
			externalPowerStatus,
			relayStatus,
			// acStatus,
			// panicButton,
			// immobilizer
		}));
	};

	const isFilterActive = () => {
		return (
			(filters.ignition !== null && Number.isInteger(filters.ignition)) ||
			(filters.gps !== null && Number.isInteger(filters.gps)) ||
			(filters.externalPowerStatus !== null && Number.isInteger(filters.externalPowerStatus)) ||
			// || (filters.acStatus !== null && Number.isInteger(filters.acStatus))
			// || (filters.panicButton !== null && Number.isInteger(filters.panicButton))
			(filters.relayStatus !== null && Number.isInteger(filters.relayStatus))
			// || filters.immobilizer.length
		);
	};

	// Pagination
	const handleDataSizeLimitChange = () => {
		getFilteredLogs();
	};
	const handlePageChange = (activePage) => {
		getFilteredLogs(activePage);
	};

	const toggleAutoRefresh = (e, { checked }) => {
		const endDate = moment().endOf('day').toISOString();

		if (!(props.dropdownValue || vehicleId)) return;

		setAutoRefresh(checked);

		if (checked) {
			const intervalId = setInterval(() => {
				if (currentTimestamp) {
					getFilteredLogs(props.pageNumber, checked, currentTimestamp, endDate);
				}
			}, 5000);
			setAutoRefreshIntervalId(intervalId);
		} else {
			if (autoRefreshIntervalId) {
				clearInterval(autoRefreshIntervalId);
			}
			getFilteredLogs(props.pageNumber, checked, currentTimestamp, endDate);
		}
	};

	// const handleDeleteLogs = (imei, logId) => {
	// 	deleteData(DEVICES_API, `/${imei}/logs/${logId}`)
	// 		.then((response) => {
	// 			generateSuccessMessage(response);
	// 			getFilteredLogs(props.pageNumber);
	// 			cleanDeleteOperation();
	// 		})
	// 		.catch((error) => handleError(error));
	// };

	const getLogs = (e, { value }) => {
		if (value) {
			setInitialLoading(true);
			refreshDeviceLogs(value, `&sortBy=timestamp&orderBy=desc`);
			props.onChangeValue(value);
			setInitialLoading((prev) => !prev);
			return;
		}
		props.onChangeValue('');
		dispatch('LOGS_UNMOUNT');
	};

	if (!currentTimestamp) {
		currentTimestamp = props.logs[0]?.timestamp;
	}

	const renderGps = (data) => {
		if (data === 0) {
			return false;
		} else if (data === 1) {
			return true;
		} else if (data === 2) {
			return 'No Fix';
		} else if (data === 3) {
			return 'Sleep State';
		} else if (data === 4) {
			return 'Manual GNSS';
		} else {
			return 'N/A';
		}
	};

	const renderPower = (externalPowerVoltage, internalPowerVoltage) => {
		const parsedExternalPower = parseFloat(externalPowerVoltage);
		const parsedInternalPower = parseFloat(internalPowerVoltage);

		if (!isNaN(parsedExternalPower) && parsedExternalPower !== 0) {
			return parsedExternalPower.toFixed(2);
		} else if (!isNaN(parsedInternalPower)) {
			return parsedInternalPower.toFixed(2);
		} else {
			return 'N/A';
		}
	};

	//This will be applicable for all cells
	const defaultColDef = useMemo(
		() => ({
			resizable: true,
			filter: true,
		}),
		[]
	);

	const handleSortChanged = (event) => {
		const sortedColumns = event.columnApi.getColumns().filter((col) => col.isSortAscending() || col.isSortDescending());

		if (sortedColumns.length === 1) {
			const clickedColumnId = sortedColumns[0].getColId();
			const newSortOrder = sortedColumns[0].isSortAscending() ? 'asc' : 'desc';
			setActiveSort(clickedColumnId);
			setSortOrder(newSortOrder);
		}
	};

	useEffect(() => {
		sort(activeSort, sortOrder);
	}, [activeSort, sortOrder, props.pageNumber]);

	return (
		<CategoryContentMembrane>
			<CategoryHeader style={{ justifyContent: 'space-between', height: '5em' }}>
				<div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
					<Calendar disabled={props.loading || props.exportingData || autoRefresh} urlDates={urlDates} />
					<Dropdown
						style={{ minWidth: '23em', marginLeft: '.50em' }}
						fluid
						selection
						search
						clearable
						value={props.dropdownValue || vehicleId || vehicleNumber}
						options={props.vehicleDropdownOption}
						onChange={getLogs}
						placeholder="Select Vehicle Logs"
					/>
				</div>
				<div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
					{props.loading && autoRefresh && (
						<Segment
							style={{
								background: COLORS.headerBackground,
								border: 'none',
								boxShadow: 'none',
								marginRight: '0.5em',
							}}>
							<Loader active size="small" />
						</Segment>
					)}
					<AutoRefresh title={autoRefresh ? 'Disable Auto Refresh' : 'Enable Auto Refresh (Every 5 Seconds)'}>
						<RolesToggle>
							<Checkbox
								toggle
								disabled={props.loading || !props.dropdownValue || props.exportingData || !currentTimestamp}
								label="Live"
								onClick={toggleAutoRefresh}
							/>
						</RolesToggle>
					</AutoRefresh>
					<Button
						icon
						primary
						title={autoRefresh ? 'Auto Refresh Enabled' : 'Refresh Logs'}
						disabled={autoRefresh || props.loading || props.exportingData}
						onClick={() => getFilteredLogs(props.pageNumber)}>
						<Icon name="refresh" />
					</Button>
					<Button
						icon
						primary={!isFilterActive()}
						disabled={props.loading || props.exportingData}
						color={isFilterActive() ? 'orange' : ''}
						title="Filter Logs"
						onClick={openFilterModal}>
						<Icon name="filter" />
					</Button>

					<Button.Group>
						<Button
							primary
							title={disableExportButton ? 'Please select only 1 day to export' : 'Export All Device Commands'}
							loading={props.exportingData}
							disabled={props.loading || !props.logs.length || props.exportingData || disableExportButton}
							onClick={() =>
								exportAllForVehicles(getFilterListQuery(), props.dropdownValue || vehicleId, fileExtension)
							}>
							Export
						</Button>
						<Dropdown
							className="button icon"
							style={{ background: '#ffffff', color: '#1b3c71', borderColor: '#1b3c71' }}
							disabled={props.loading || !props.logs.length || props.exportingData || disableExportButton}
							floating
							value={fileExtension}
							options={FILE_TYPE_EXTENSIONS}
							onChange={fileExtensionChange}
							trigger={<></>}
						/>
					</Button.Group>
				</div>
			</CategoryHeader>

			<TableContainer id="TableContainer">
				{!props.dropdownValue ? (
					<Container>
						<h4>Please Select Device</h4>
					</Container>
				) : initialLoading ? (
					<BasicLoader />
				) : props.logs.length && rowData.length ? (
					<div style={{ height: '100%', width: '100%' }} className="ag-theme-balham">
						<AgGridReact
							columnDefs={columnDefs}
							rowData={rowData}
							defaultColDef={defaultColDef}
							animateRows={true}
							getRowStyle={shouldRowHighlight}
							// gridOptions={gridOptions}
							onSortChanged={handleSortChanged}
							overlayNoRowsTemplate={"<div class='ag-overlay-loading-center'>Loading...</div>"}
						/>
					</div>
				) : (
					<PlaceHolder />
				)}
			</TableContainer>

			{/*{!this.state.autoRefresh && (*/}
			<CategoryFooter>
				<FooterPagination
					handleDataSizeLimitChange={handleDataSizeLimitChange}
					pageNumber={props.pageNumber}
					autoRefresh={autoRefresh}
					handlePageChange={(e) => handlePageChange(e)}
					count={props.count}
					disabled={props.loading || !props.logs.length || props.exportingData}
				/>
			</CategoryFooter>

			{/*{props.deleteModalOpen && (*/}
			{/*	<DeleteModal*/}
			{/*		onClose={cleanDeleteOperation}*/}
			{/*		open={props.deleteModalOpen}*/}
			{/*		onCancelClick={cleanDeleteOperation}*/}
			{/*		onDeleteButtonClick={() => {*/}
			{/*			handleDeleteLogs(props.dropdownValue, Number(LogId));*/}
			{/*		}}*/}
			{/*		deleteButtonLoading={props.deleting}*/}
			{/*	/>*/}
			{/*)}*/}

			{props.filterModalOpen && (
				<FilterModal
					onClose={() => {
						setFilterChange(
							filters.ignition,
							filters.gps,
							// filters.immobilizer,
							filters.externalPowerStatus,
							filters.relayStatus
							// activeFilters.acStatus,
							// activeFilters.panicButton
						);
						closeFilterModal();
					}}
					// acStatusDisable={acStatus}
					gpsDisable={GPS}
					// panicButtonDisable={panicButton}
					ignitionDisable={ignition}
					externalPowerDisable={externalPowerStatus}
					open={props.filterModalOpen}
					ignition={filters.ignition}
					relayStatus={filters.relayStatus}
					externalPowerStatus={filters.externalPowerStatus}
					hasRelay={hasRelay}
					// acStatus={filters.acStatus}
					// panicButton={filters.panicButton}
					// onPanicButtonFilterClear={this.onPanicButtonFilterClear}
					// onPanicButtonFilterChange={onPanicButtonFilterChange}
					onRelayStatusFilterChange={onRelayStatusFilterChange}
					onIgnitionFilterChange={onIgnitionFilterChange}
					onExternalFilterChange={onExternalFilterChange}
					// onIgnitionFilterClear={this.onIgnitionFilterClear}
					// onAcStatusFilterClear={this.onAcStatusFilterClear}
					// onAcStatusFilterChange={onAcStatusFilterChange}
					// onExternalPowerStatusFilterClear={this.onExternalPowerStatusFilterClear}
					gps={filters.gps}
					onGPSFilterChange={onGPSFilterChange}
					// onGPSFilterClear={this.onGPSFilterClear}
					// immobilizer={filters.immobilizer}
					hasImmobilizer={props?.vehicle?.hasImmobilizer || props?.device?.vehicle?.hasImmobilizer}
					// onImmobilizerFilterChange={onImmobilizerFilterChange}
					onCancelClick={() => {
						setFilterChange(
							filters.ignition,
							filters.gps,
							// filters.immobilizer,
							filters.externalPowerStatus,
							filters.relayStatus
							// activeFilters.acStatus,
							// activeFilters.panicButton
						);
						closeFilterModal();
					}}
					onResetClick={() => {
						setFilters({
							ignition: null,
							gps: null,
							externalPowerStatus: null,
							relayStatus: null,
							// acStatus: null,
							// panicButton: null,
							// immobilizer: [],
						});
						refreshDeviceLogs(props.dropdownValue, '', props.pageNumber);
						closeFilterModal();
					}}
					disabled={
						filters.ignition === null &&
						filters.externalPowerStatus === null &&
						filters.relayStatus === null &&
						// filters.acStatus === null &&
						// filters.panicButton === null &&
						filters.gps === null
					}
					onApplyClick={() => {
						trackMixpanelEvent('Vehicle Logs', { Action: 'Filter' });
						// activeFilters = filters;
						getFilteredLogs(props.pageNumber);
						closeFilterModal();
					}}
				/>
			)}
		</CategoryContentMembrane>
	);
};

// Connect to Store
const mapStateToProps = (state) => ({
	loading: state.logs.loading,
	initialDate: state.dates.initialDate,
	finalDate: state.dates.finalDate,
	// deleteModalOpen: state.logs.deleteModalOpen,

	logs: state.logs.logs,
	count: state.logs.count,
	pageNumber: state.logs.pageNumber,
	dataSizeLimit: state.ui.dataSizeLimit,
	activeSort: state.logs.activeSort,
	sortOrder: state.logs.sortOrder,
	filterModalOpen: state.logs.filterModalOpen,
	openExportModal: state.logs.openExportModal,
	exportData: state.logs.exportData,
	exportingData: state.logs.exportingData,
	vehicles: state.logs.vehicles,
});

export default connect(mapStateToProps)(VehicleLogs);
