import { SIDEBAR_LIST_ITEMS_HEIGHT } from '../../../../data/constants/dimensions';
import { COLORS } from '../../../styles/colors';
import styled from 'styled-components';

export const Container = styled.section`
	width: 100%;
	height: calc(100vh - 4em);

	display: flex;
	align-items: center;
	justify-content: center;
`;

export const SidebarList = styled.section`
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	white-space: nowrap;
`;

export const IconContainer = styled.section`
	width: 4em;
	min-width: 4em;
	height: ${SIDEBAR_LIST_ITEMS_HEIGHT};

	display: flex;
	align-items: center;
	justify-content: center;

	color: ${(props) => (props.active ? COLORS.sidebarIcon : '')};
	background: ${(props) =>
		props.active ? COLORS.sidebarIconBackground : props.expanded ? `${COLORS.sidebarAccordionBackground}` : ''};

	i.icon,
	i.icons {
		margin: 0 !important;
	}
`;
