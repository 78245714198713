import styled from 'styled-components';

export const AccordionMainContent = styled.section`
	box-sizing: border-box;
	padding: 1em;
	margin: 0.5em;

	background: #fafbfc;
	border: 1px solid #ddd;
	border-radius: 5px;

	.ui.table thead tr:first-child > th {
		position: relative;
		top: 0;
		z-index: 1;
	}
`;
