export default function authPeople(
	initialState = {
		loading: false,
		authPeopleLoggedIn: false,
		authPeopleOtpSend: false,
	},
	action
) {
	switch (action.type) {
		case 'AUTH_PEOPLE_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'AUTH_PEOPLE_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'AUTH_PEOPLE_LOGIN':
			return {
				...initialState,
				authPeopleLoggedIn: true,
			};

		case 'AUTH_PEOPLE_LOGOUT':
			return {
				...initialState,
				authPeopleLoggedIn: false,
			};

		case 'AUTH_PEOPLE_OTP_SENDING':
			return {
				...initialState,
				authPeopleOtpSend: true,
			};

		case 'AUTH_PEOPLE_OTP_SEND':
			return {
				...initialState,
				authPeopleOtpSend: false,
			};

		default:
			return initialState;
	}
}
