export default function smsReport(
	initialState = {
		smsReport: [],
		loading: false,
		pageNumber: 1,
		count: 0,
		activeIndex: -1,
		activeSort: '',
		sortOrder: '',
		filterModalOpen: false,

		// Export
		openExportModal: false,
		exportData: [],
		exportingData: false,
	},
	action
) {
	switch (action.type) {
		case 'FEED_SMS_REPORT':
			return {
				...initialState,
				smsReport: action.payload,
				loading: false,
			};

		case 'SMS_REPORT_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'SMS_REPORT_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'SMS_REPORT_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'SMS_REPORT_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'SMS_REPORT_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'SMS_REPORT_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'SMS_REPORT_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'SMS_REPORT_FILTER_MODAL_OPEN':
			return {
				...initialState,
				filterModalOpen: true,
			};

		case 'SMS_REPORT_FILTER_MODAL_CLOSE':
			return {
				...initialState,
				filterModalOpen: false,
			};

		case 'SMS_REPORT_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				openExportModal: true,
			};

		case 'SMS_REPORT_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				openExportModal: false,
			};

		case 'SET_SMS_REPORT_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'EXPORTING_SMS_REPORT_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'EXPORTED_SMS_REPORT_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'SMS_REPORT_UNMOUNT':
			return {
				...initialState,
				smsReport: [],
				activeSort: '',
				sortOrder: '',
				activeIndex: -1,
				pageNumber: 1,
			};

		default:
			return initialState;
	}
}
