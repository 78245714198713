import React from 'react';
import CommonModal from '../../../molecules/modal/CommonModal';
import { Expander } from '../../../../styles/place-holders/Expander';
import {
	IS_FMSVEHICLE_OPTIONS,
	RELAY_STATUS_OPTIONS,
	VEHICLE_SENSORS_OPTIONS,
	VEHICLE_STATUS_OPTIONS,
	VEHICLES_TYPE_FILTER_OPTIONS,
} from '../../../../../../data/constants/dropdown-options';
import { Button, Dropdown, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import moment from 'moment';
import { DateInput } from 'semantic-ui-calendar-react';

const FilterModal = (props) => {
	const isSuperAdmin = localStorage.getItem('isSuperAdmin');
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="tiny"
			header="Vehicles Filters"
			scrolling
			content={
				<Form>
					{
						isSuperAdmin=='true' && (
							<Form.Group widths="equal">
								<Form.Field>
									<label>Organisations</label>
									<Dropdown
										placeholder="Choose..."
										fluid
										search
										selection
										multiple
										clearable
										value={props.organisations}
										options={props.organisationsOptions}
										onChange={props.onOrganisationFilterChange}
									/>
								</Form.Field>
							</Form.Group>
						)
					}
					<Form.Group widths="equal">
						<Form.Field>
							<label>Type</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								multiple
								selection
								clearable
								value={props.type}
								options={VEHICLES_TYPE_FILTER_OPTIONS}
								onChange={props.onTypeFilterChange}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						{/*<Form.Field>*/}
						{/*  <label>Immobilizer</label>*/}
						{/*  <Dropdown*/}
						{/*    placeholder="Choose..."*/}
						{/*    fluid*/}
						{/*    search*/}
						{/*    multiple*/}
						{/*    selection*/}
						{/*    value={props.immobilizer}*/}
						{/*    options={VEHICLES_IMMOBILIZER_FILTER_OPTIONS}*/}
						{/*    onChange={props.onImmobilizerFilterChange}*/}
						{/*  />*/}
						{/*</Form.Field>*/}
					</Form.Group>
					{/*<Form.Group widths="equal">*/}
					{/*	<FormField>*/}
					{/*		<ImmobilizerTypeDropdownforFilter*/}
					{/*			label={'Immobilizer'}*/}
					{/*			value={props.immobilizer}*/}
					{/*			onChange={props.onImmobilizerFilterChange}*/}
					{/*		/>*/}
					{/*	</FormField>*/}
					{/*</Form.Group>*/}
					{/*<Form.Group widths="equal">*/}
					{/*	<FormField>*/}
					{/*		<FuelDataSourceDropdownforFilter*/}
					{/*			label={'Fuel data source'}*/}
					{/*			value={props.fuel}*/}
					{/*			onChange={props.onFuelSensorFilterChange}*/}
					{/*		/>*/}
					{/*	</FormField>*/}
					{/*</Form.Group>*/}

					<Form.Group widths="equal">
						<Form.Field>
							<label>Status</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								multiple
								selection
								clearable
								value={props.status}
								options={VEHICLE_STATUS_OPTIONS}
								onChange={props.onStatusFilterChange}
							/>
						</Form.Field>
					</Form.Group>

					<Form.Group widths="equal">
						<Form.Field>
							<label>Relay status</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								multiple
								selection
								clearable
								options={RELAY_STATUS_OPTIONS}
								value={props.relayStatus}
								onChange={props.onRelayFilterChange}
							/>
						</Form.Field>
					</Form.Group>

					<Form.Group widths="equal">
						<Form.Field>
							<label>Sensors</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								multiple
								selection
								clearable
								value={props.sensors}
								options={VEHICLE_SENSORS_OPTIONS}
								onChange={props.onSensorsFilterChange}
							/>
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field>
							<label>FMS</label>
							<Dropdown
								placeholder="Choose..."
								fluid
								search
								selection
								multiple
								clearable
								value={props.isFMSVehicle}
								options={IS_FMSVEHICLE_OPTIONS}
								onChange={props.onIsFMSFilterChange}
							/>
						</Form.Field>
					</Form.Group>

					<Form.Group widths="equal">
						<Form.Field>
							<label>Installation Start Date</label>
							<DateInput
								name="startDate"
								placeholder="Start Date"
								value={props.startDate}
								dateFormat="DD-MM-YYYY"
								maxDate={moment()}
								icon="calendar alternate outline"
								iconPosition="left"
								onChange={props.handleChangeDate}
								closable
								clearable
								onClear={() => {
									props.handleClearDate();
								}}
							/>
						</Form.Field>
						<Form.Field>
							<label>Installation End Date</label>
							<DateInput
								name="endDate"
								placeholder="End Date"
								value={props.endDate}
								disabled={!props.startDate}
								dateFormat="DD-MM-YYYY"
								maxDate={moment()}
								minDate={props.startDate}
								icon="calendar alternate outline"
								iconPosition="left"
								onChange={props.handleChangeDate}
								closable
								clearable
							/>
						</Form.Field>
					</Form.Group>
				</Form>
			}
			actions={
				<>
					<FlexContainer>
						<Button onClick={props.onResetClick}>Reset</Button>
						<Expander />
						<Button color="black" onClick={props.onCancelClick}>
							Cancel
						</Button>
						<Button
							primary
							disabled={
								!props.organisations?.length &&
								!props.type?.length &&
								!props.status?.length &&
								!props.relayStatus?.length &&
								!props.sensors?.length &&
								!props.isFMSVehicle?.length &&
								(!props?.startDate || !props?.endDate)
							}
							onClick={props.onApplyClick}>
							Apply
						</Button>
					</FlexContainer>
				</>
			}
		/>
	);
};

const FlexContainer = styled.section`
	display: flex;
`;

export default FilterModal;
