import { isoToDate } from '../utilities/date';

export function createDevicesDTO(data) {
	if (data && data.length) {
		return data.map((device) => {
			return {
				imei: device?.imei ? device.imei : null,
				serial: device?.serial ? device.serial : null,
				status: device?.status ? device.status : null,
				manufacturer: device?.deviceModel?.manufacturer ? device.deviceModel.manufacturer : null,
				// invoiceNumber: device?.invoiceNumber ? device.invoiceNumber : null,
				dateOfPurchase: device?.dateOfPurchase ? device.dateOfPurchase : null,
				supplierName: device?.supplierName ? device.supplierName : null,
				model: device?.deviceModel?.model ? device.deviceModel.model : null,
				sims: device?.sims ? device.sims : null,
				note: device?.note ? device.note : null,
				invoiceNumber: device?.purchaseInvoice ? device.purchaseInvoice : null,
				invoiceDate: device?.invoiceDate ? isoToDate(device.invoiceDate) : null,
				deletedDate: device?.deletedDate ? isoToDate(device.deletedDate) : null,
				vehicle: device?.vehicle
					? {
							id: device?.vehicle?.id ? device.vehicle.id : null,
							registrationNumber: device?.vehicle?.registrationNumber ? device.vehicle.registrationNumber : null,
							hasImmobilizer: device?.vehicle?.hasImmobilizer ? device.vehicle.hasImmobilizer : null,
					  }
					: null,
			};
		});
	} else return [];
}
