export default function roles(
	initialState = {
		roles: [],
		loading: true,
		pageNumber: 1,
		count: 0,
		activeIndex: -1,
		deleting: false,
		deleteModalOpen: false,
		activeSort: '',
		sortOrder: '',
		adding: false,
		addModalOpen: false,
		updating: false,
		editMode: false,
		restoreModal: false,
		restoring: false,
		filterModalOpen: false,

		exportModal: false,
		exportingData: false,
		exportData: [],
	},
	action
) {
	switch (action.type) {
		case 'FEED_ROLES':
			return {
				...initialState,
				roles: action.payload,
				loading: false,
			};

		case 'ROLES_LOADING':
			return {
				...initialState,
				loading: true,
			};

		case 'ROLES_LOADED':
			return {
				...initialState,
				loading: false,
			};

		case 'ROLES_SET_PAGE_NUMBER':
			return {
				...initialState,
				pageNumber: action.payload,
			};

		case 'ROLES_SET_PAGE_COUNT':
			return {
				...initialState,
				count: action.payload,
			};

		case 'ROLES_TOGGLE_ACCORDION':
			return {
				...initialState,
				activeIndex: action.payload,
			};

		case 'ROLES_DELETING':
			return {
				...initialState,
				deleting: true,
			};

		case 'ROLES_DELETED':
			return {
				...initialState,
				deleting: false,
			};

		case 'ROLES_DELETE_MODAL_OPEN':
			return {
				...initialState,
				deleteModalOpen: true,
			};

		case 'ROLES_DELETE_MODAL_CLOSE':
			return {
				...initialState,
				deleteModalOpen: false,
			};

		case 'ROLES_SORT_ORDER':
			return {
				...initialState,
				sortOrder: action.payload,
			};

		case 'ROLES_ACTIVE_ORDER':
			return {
				...initialState,
				activeSort: action.payload,
			};

		case 'ROLES_ADDING':
			return {
				...initialState,
				adding: true,
			};

		case 'ROLES_ADDED':
			return {
				...initialState,
				adding: false,
			};

		case 'ROLES_ADD_MODAL_OPEN':
			return {
				...initialState,
				addModalOpen: true,
			};

		case 'ROLES_ADD_MODAL_CLOSE':
			return {
				...initialState,
				addModalOpen: false,
			};

		case 'ROLES_FILTER_MODAL_OPEN':
			return {
				...initialState,
				filterModalOpen: true,
			};

		case 'ROLES_FILTER_MODAL_CLOSE':
			return {
				...initialState,
				filterModalOpen: false,
			};

		case 'ROLES_UPDATING':
			return {
				...initialState,
				updating: true,
			};

		case 'ROLES_UPDATED':
			return {
				...initialState,
				updating: false,
			};

		case 'ROLES_EDIT_MODAL_OPEN':
			return {
				...initialState,
				editMode: true,
			};

		case 'ROLES_EDIT_MODAL_CLOSE':
			return {
				...initialState,
				editMode: false,
			};

		case 'ROLES_RESTORE_MODAL_OPEN':
			return {
				...initialState,
				restoreModal: true,
			};

		case 'ROLES_RESTORE_MODAL_CLOSE':
			return {
				...initialState,
				restoreModal: false,
			};

		case 'ROLES_RESTORING':
			return {
				...initialState,
				restoring: true,
			};

		case 'ROLES_RESTORED':
			return {
				...initialState,
				restoring: false,
			};

		case 'ROLES_EXPORT_MODAL_OPEN':
			return {
				...initialState,
				exportModal: true,
			};

		case 'ROLES_EXPORT_MODAL_CLOSE':
			return {
				...initialState,
				exportModal: false,
			};

		case 'FETCHING_ROLES_EXPORT_DATA':
			return {
				...initialState,
				exportingData: true,
			};

		case 'FETCHED_ROLES_EXPORT_DATA':
			return {
				...initialState,
				exportingData: false,
			};

		case 'SET_ROLES_EXPORT_DATA':
			return {
				...initialState,
				exportData: action.payload,
			};

		case 'CLEAR_ROLES_EXPORT_DATA':
			return {
				...initialState,
				exportData: [],
			};

		case 'ROLES_UNMOUNT':
			return {
				...initialState,
				roles: [],
				loading: true,
				activeSort: '',
				sortOrder: '',
				activeIndex: -1,
				pageNumber: 1,
				deleting: false,
				deleteModalOpen: false,
				adding: false,
				addModalOpen: false,
				updating: false,
				editMode: false,
				restoreModal: false,
				restoring: false,
				filterModalOpen: false,
				exportModal: false,
				exportingData: false,
				exportData: [],
			};

		default:
			return initialState;
	}
}
