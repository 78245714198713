import styled from 'styled-components';
import { COLORS } from '../../../../styles/colors';

export const Container = styled.section`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	box-sizing: border-box;
	padding: 0.5em 0.65em 0.5em 0.5em;

	a {
		width: 100%;

		display: flex;
		align-items: center;
		justify-content: center;

		box-sizing: border-box;
		padding: 0.5em;

		color: ${COLORS.dashboardLinkColor};
	}

	i {
		margin: 0;
	}
`;
