import React from 'react';

import { VEHICLES_API } from '../../../../../logic/configs/api';

import { CategoryHeader } from '../../../styles/containers/CategoryHeader';
import { Expander } from '../../../styles/place-holders/Expander';
import { AccordionHeader } from '../../../styles/containers/AccordionHeader';
import PlaceHolder from '../../../blocks/molecules/place-holder/PlaceHolder';
import { AccordionMain } from '../../../styles/containers/AccordionMain';
import BasicLoader from '../../../blocks/molecules/loader/BasicLoader';
import ExportModal from '../../../blocks/organisms/modals/health/ExportModal';
import SearchBox from '../../../blocks/atoms/search-box/SearchBox';

import { VEHICLE_TYPE } from '../../../../../data/constants/general';

import { createVehicleDTO } from '../../../../../logic/dto/vehicles';

import { connect } from 'react-redux';

import { HeaderElement, RowElement } from '../../../styles/Accordion';
import { CheckboxContainer } from '../../../styles/Containers';
import { Accordion, Button, Checkbox, Icon, Label } from 'semantic-ui-react';

import { dispatch, dispatchNoPayload } from '../../../../../logic/middleware/middleware';
import { handleError } from '../../../../../logic/middleware/messaging';
import { getElapsedTime, getHumanReadableElapsedTime, isoToHumanReadable } from '../../../../../logic/utilities/date';
import { searchVehicles } from '../../../../../logic/middleware/vehicles';
import { exportData, openExportModal } from '../../../../../logic/middleware/service';

import axios from 'axios';
import moment from 'moment';
import LazyLoad from 'react-lazy-load';
import styled from 'styled-components';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '../../../../../data/constants/dimensions';

class Service extends React.Component {
	searchQuery = '';
	timeoutId;
	selectAllActive = false;

	constructor() {
		super();

		this.state = {
			offlineFilter: new Set(),

			offlineTime: {},

			anyRowSelected: false,
			selectedRows: new Set(),

			fileName: 'Service',
			fileExtension: 'excel',
		};

		this.fileExtensionChange = this.fileExtensionChange.bind(this);
		this.fileNameChange = this.fileNameChange.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData(params = '') {
		dispatchNoPayload('SERVICE_LOADING');
		axios(VEHICLES_API + '?pagecount=10000&pagenumber=1' + params, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
				'Content-Type': 'application/json',
			},
		})
			.then((response) => {
				let vehicles = createVehicleDTO(response?.data?.data);
				dispatch('FEED_SERVICE_VEHICLES_BACKUP', vehicles);
				this.updateData(vehicles);
			})
			.catch((error) => {
				handleError(error);
			});
	}

	handleSearch(e) {
		this.searchQuery = e.target.value;
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}

		this.timeoutId = setTimeout(() => {
			this.fetchData(e.target.value ? searchVehicles(e.target.value) : '');
		}, 500);
	}

	// Export
	fileNameChange(e) {
		this.setState({ fileName: e.target.value });
	}

	fileExtensionChange(e, { value }) {
		this.setState({ fileExtension: value });
	}

	handleExportSelection(checkedData, id) {
		let selectedRows = this.state.selectedRows;

		if (selectedRows.has(id)) {
			selectedRows.delete(id);
		} else selectedRows.add(id);

		this.setState({
			selectedRows: selectedRows,
			anyRowSelected: !!selectedRows.size,
		});
	}

	handleSelectAll(checked) {
		let selectedRows = new Set();

		if (checked) {
			this.selectAllActive = true;
			this.props.vehicles.forEach((vehicle) => selectedRows.add(vehicle.id));
			this.setState({
				selectedRows: selectedRows,
				anyRowSelected: !!selectedRows.size,
			});
		} else {
			this.selectAllActive = false;
			this.setState({
				selectedRows: new Set(),
				anyRowSelected: !!selectedRows.size,
			});
		}
	}

	export() {
		const { vehicles } = this.props;

		let vehiclesToExport = [];
		vehicles.forEach((vehicle) => {
			if (this.state.selectedRows.has(vehicle.id)) {
				vehiclesToExport.push(vehicle);
			}
		});

		return vehiclesToExport;
	}

	updateData(data) {
		let vehicles = [];
		let vehiclesObj = {};
		let offlineVehicles = [];
		data.forEach((vehicle) => {
			if (
				this.props.timestampStatus?.[vehicle.id] &&
				moment.duration(getElapsedTime(this.props.timestampStatus[vehicle.id])).asMinutes() > 30
			) {
				vehiclesObj = {
					...vehiclesObj,
					[this.props.timestampStatus?.[vehicle.id] ? getElapsedTime(this.props.timestampStatus[vehicle.id]) : '']: {
						...vehicle,
						offline: this.props.timestampStatus?.[vehicle.id]
							? getElapsedTime(this.props.timestampStatus[vehicle.id])
							: '',
						lastData: this.props.timestampStatus?.[vehicle.id] ? this.props.timestampStatus?.[vehicle.id] : '',
					},
				};
			}

			if (!this.props.timestampStatus[vehicle.id]) {
				offlineVehicles.push(vehicle);
			}
		});

		let sorted = Object.keys(vehiclesObj).sort((a, b) => {
			return b - a;
		});

		sorted.forEach((offline) => vehicles.push(vehiclesObj[offline]));
		offlineVehicles.forEach((vehicle) => vehicles.push(vehicle));
		setTimeout(() => dispatch('FILTER_SERVICE_VEHICLES', vehicles), 500);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.timestampStatus !== this.props.timestampStatus) {
			this.updateData(this.props.vehiclesBackup);
		}
	}

	render() {
		return (
			<CategoryContentMembrane>
				<CategoryHeader>
					<SearchBox
						placeholder="Search in services"
						searchQuery={this.searchQuery}
						clearSearchIconClick={() => {
							this.searchQuery = '';
							this.fetchData(this.searchQuery ? searchVehicles(this.searchQuery) : '');
						}}
						handleSearch={this.handleSearch}
					/>
					<Button
						icon
						primary
						title="Refresh Service Vehicle List"
						onClick={() => this.fetchData(this.searchQuery ? searchVehicles(this.searchQuery) : '')}>
						<Icon name="refresh" />
					</Button>
					<Label color="blue">Total Vehicles ({`${this.props.vehicles.length}`})</Label>
					<Expander />
					<Button
						disabled={!this.state.anyRowSelected}
						title={
							!this.state.anyRowSelected
								? 'Please select atleast one service vehicle to export'
								: 'Export Service Vehicles'
						}
						onClick={() => {
							openExportModal();
							dispatch('SET_SERVICE_EXPORT_DATA', this.export());
						}}>
						Export
					</Button>
					{/*Export All*/}
					<Button
						loading={this.props.exportingData}
						title="Export All Service Vehicles"
						onClick={() => {
							openExportModal();
							dispatch('SET_SERVICE_EXPORT_DATA', this.props.vehicles);
						}}>
						Export All
					</Button>
				</CategoryHeader>

				<CategoryMain>
					<AccordionHeader>
						<HeaderElement width="3%">
							<CheckboxContainer>
								<Checkbox
									checked={
										this.props.vehicles.length !== 0 && this.state.selectedRows.size === this.props.vehicles.length
									}
									indeterminate={
										this.state.selectedRows.size !== 0 && this.state.selectedRows.size !== this.props.vehicles.length
									}
									onClick={(e, data) => {
										this.handleSelectAll(data.checked);
									}}
								/>
							</CheckboxContainer>
						</HeaderElement>
						<HeaderElement width="7%">No.</HeaderElement>
						<HeaderElement width="15%">Registration</HeaderElement>
						<HeaderElement width="9%" style={{ textAlign: 'center' }}>
							Offline
						</HeaderElement>
						<HeaderElement width="16%">Last Data</HeaderElement>
						<HeaderElement width="15%">Organisation</HeaderElement>
						<HeaderElement width="15%">Phone (Organisation)</HeaderElement>
						<HeaderElement width="8%">Type</HeaderElement>
						<HeaderElement width="12%">Installation Date</HeaderElement>
					</AccordionHeader>
					<AccordionMain>
						{this.props.loading ? (
							<BasicLoader />
						) : this.props.vehicles.length ? (
							<Accordion fluid>
								{this.props.vehicles.map((vehicle, index) => {
									return (
										<LazyLoad height="2.5em" overflow unmountIfInvisible key={index} offset={10}>
											<Card>
												<RowElement width="3%">
													<CheckboxContainer>
														<CheckboxContainer>
															<Checkbox
																checked={this.state.selectedRows.has(vehicle.id)}
																onChange={(e, data) => {
																	e.stopPropagation();
																	this.handleExportSelection(data.checked, vehicle.id);
																}}
															/>
														</CheckboxContainer>
													</CheckboxContainer>
												</RowElement>
												<RowElement width="7%">{1 + index}</RowElement>
												<RowElement width="15%" title={vehicle.registrationNumber}>
													{vehicle.registrationNumber}
												</RowElement>
												<RowElement
													width="9%"
													style={{ textAlign: 'center' }}
													title={
														this.props.timestampStatus?.[vehicle.id]
															? getHumanReadableElapsedTime(getElapsedTime(this.props.timestampStatus[vehicle.id]))
															: ''
													}>
													{this.props.timestampStatus?.[vehicle.id]
														? getHumanReadableElapsedTime(getElapsedTime(this.props.timestampStatus[vehicle.id]))
														: ''}
												</RowElement>
												<RowElement
													width="16%"
													title={
														this.props.timestampStatus?.[vehicle.id]
															? isoToHumanReadable(this.props.timestampStatus?.[vehicle.id])
															: ''
													}>
													{this.props.timestampStatus?.[vehicle.id]
														? isoToHumanReadable(this.props.timestampStatus?.[vehicle.id])
														: ''}
												</RowElement>
												<RowElement width="15%" title={vehicle.organisation}>
													{vehicle.organisation}
												</RowElement>
												<RowElement width="15%" title={vehicle.organisationPhone}>
													{vehicle.organisationPhone}
												</RowElement>
												<RowElement
													width="8%"
													title={
														vehicle.type === VEHICLE_TYPE.Car
															? 'Car'
															: vehicle.type === VEHICLE_TYPE.Bike
															? 'Bike'
															: vehicle.type === VEHICLE_TYPE.Truck
															? 'Truck'
															: vehicle.type === VEHICLE_TYPE.Construction
															? 'Construction'
															: vehicle.type === VEHICLE_TYPE['School Bus']
															? 'School Bus'
															: vehicle.type === VEHICLE_TYPE.Generator
															? 'Generator'
															: vehicle.type === VEHICLE_TYPE.Container
															? 'Container'
															: vehicle.type === VEHICLE_TYPE['Mini Truck']
															? 'Mini Truck'
															: vehicle.type === VEHICLE_TYPE['Auto Rickshaw']
															? 'Auto Rickshaw'
															: ''
													}>
													{vehicle.type === VEHICLE_TYPE.Car
														? 'Car'
														: vehicle.type === VEHICLE_TYPE.Bike
														? 'Bike'
														: vehicle.type === VEHICLE_TYPE.Truck
														? 'Truck'
														: vehicle.type === VEHICLE_TYPE.Construction
														? 'Construction'
														: vehicle.type === VEHICLE_TYPE['School Bus']
														? 'School Bus'
														: vehicle.type === VEHICLE_TYPE.Generator
														? 'Generator'
														: vehicle.type === VEHICLE_TYPE.Container
														? 'Container'
														: vehicle.type === VEHICLE_TYPE['Mini Truck']
														? 'Mini Truck'
														: vehicle.type === VEHICLE_TYPE['Auto Rickshaw']
														? 'Auto Rickshaw'
														: ''}
												</RowElement>
												<RowElement width="12%" title={vehicle.installationDate}>
													{vehicle.installationDate}
												</RowElement>
											</Card>
										</LazyLoad>
									);
								})}
							</Accordion>
						) : (
							<PlaceHolder />
						)}
					</AccordionMain>
				</CategoryMain>

				{this.props.openExportModal && (
					<ExportModal
						open={this.props.openExportModal}
						fileName={this.state.fileName}
						fileNameChange={this.fileNameChange}
						fileExtension={this.state.fileExtension}
						fileExtensionChange={this.fileExtensionChange}
						onDownloadClick={() =>
							exportData(
								this.props.exportData.map((vehicle) => ({
									...vehicle,
									offlineTime:
										Math.floor(
											moment.duration(getElapsedTime(this.props.timestampStatus[vehicle.id]), 'milliseconds').asHours()
										) + moment.utc(getElapsedTime(this.props.timestampStatus[vehicle.id])).format(':mm:ss'),
								})),
								this.state.fileName,
								this.state.fileExtension
							)
						}
					/>
				)}
			</CategoryContentMembrane>
		);
	}
}

// Connect to Store
const mapStateToProps = (state) => ({
	vehicles: state.service.vehicles,
	vehiclesBackup: state.service.vehiclesBackup,
	loading: state.service.loading,
	timestampStatus: state.firebaseService.timestampStatus,

	openExportModal: state.service.openExportModal,
	exportData: state.service.exportData,
	exportingData: state.service.exportingData,
});

export default connect(mapStateToProps)(Service);

export const CategoryContentMembrane = styled.section`
	width: 100%;
	min-width: 1000px;
	height: 100%;
`;

export const CategoryMain = styled.section`
	width: 100%;
	height: calc(100% - calc(${HEADER_HEIGHT} + ${FOOTER_HEIGHT}));

	overflow: auto;
`;

export const Card = styled.section`
	display: flex;
	align-items: center;

	box-sizing: border-box;
	padding: 1em;

	background: #fff;
	border-top: 1px solid #efefef;

	font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
`;
