import styled from 'styled-components';

export const CategorySidebar = styled.section`
	width: ${(props) => props.width};
	min-width: ${(props) => props.width};
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;

	position: relative;

	border-right: 1px solid ${(props) => props.theme.border};

	transition: width 0.25s ease, min-width 0.25s ease;

	a {
		width: 100%;

		color: ${(props) => props.theme.defaultLink};

		&:hover {
			color: ${(props) => props.theme.sidebarIcon};
		}
	}
`;
