import React from 'react';

import CommonModal from '../../../molecules/modal/CommonModal';

import { Button, Dropdown, Form } from 'semantic-ui-react';

const VehicleGroupModal = (props) => {
	return (
		<CommonModal
			open={props.open}
			onClose={props.onClose}
			size="small"
			header="Edit Vehicle Groups"
			content={
				<Form>
					<Form.Group widths="equal">
						<Form.Field>
							<label>Vehicle Groups</label>
							<Dropdown
								placeholder="Choose ..."
								fluid
								search
								selection
								multiple
								options={props.vehicleGroupOptions}
								value={props.selectedVehicleGroup}
								onChange={props.onVehicleGroupUpdate}
							/>
						</Form.Field>
					</Form.Group>
				</Form>
			}
			actions={
				<>
					<Button color="black" onClick={props.onCancelClick}>
						Cancel
					</Button>
					<Button positive loading={props.updateButtonLoading} onClick={props.onUpdateButtonClick}>
						Update
					</Button>
				</>
			}
		/>
	);
};

export default VehicleGroupModal;
