import React, { useRef, useState } from 'react';
import { Button, Label, List, Modal } from 'semantic-ui-react';
import { EXCEL_FILE_TYPES } from '../../../data/constants/file-types';
import { dispatch } from '../../../logic/middleware/middleware';
import { generateErrorMessage, generateSuccessMessage, handleError } from '../../../logic/middleware/messaging';
import UserImport from '../import/UserImport';
import { postData } from '../../../logic/middleware/services/apiRequest';
import { DEVICES_API_BULK, SIMS_API_BULK, STUDENTS_API_BULK, USERS_API_BULK } from '../../../logic/configs/api';
import { datadogLogs } from '@datadog/browser-logs';
import { useSelector } from 'react-redux';
import StudentImport from '../import/StudentImport';
import DeviceImport from '../import/DeviceImport';
import moment from 'moment';
import SimsImport from '../import/SimsImport';

const ImportInfo = (props) => {
	let infoData = [];

	if (props.users) {
		infoData.push(
			{
				component: () => (
					<List.Item>
						<List.Icon name="heading" color={'blue'} />
						<List.Content>
							Header name should contain{' '}
							<span style={{ color: 'red' }}>
								User Name, User Type, User Contact Number, User Email, Organisation, User Role
							</span>{' '}
							alphabetically.
						</List.Content>
					</List.Item>
				),
			},
			{
				component: () => (
					<List.Item>
						<List.Icon name="user" color={'blue'} />
						<List.Content>
							User Type should be as per CRM <span style={{ color: 'red' }}>(Default,Driver,Parent)</span>{' '}
							alphabetically.
						</List.Content>
					</List.Item>
				),
			},
			{
				component: () => (
					<List.Item>
						<List.Icon name="phone" color={'blue'} />
						<List.Content>
							User Contact Should have a <span style={{ color: 'red' }}>valid 10 digits number.</span>
						</List.Content>
					</List.Item>
				),
			},
			{
				component: () => (
					<List.Item>
						<List.Icon name="mail" color={'blue'} />
						<List.Content>
							User Email should be a <span style={{ color: 'red' }}>valid email-id.</span>
						</List.Content>
					</List.Item>
				),
			},
			{
				component: () => (
					<List.Item>
						<List.Icon name="user plus" color={'blue'} />
						<List.Content>
							User Role should be as per CRM{' '}
							<span style={{ color: 'red' }}>(Driver Admin,Account Admin,Demo Admin,Trainee Admin,Parent Admin)</span>{' '}
							alphabetically.
						</List.Content>
					</List.Item>
				),
			}
		);
	} else if (props.students) {
		infoData.push(
			{
				component: () => (
					<List.Item>
						<List.Icon name="heading" color={'blue'} />
						<List.Content>
							Header name should contain{' '}
							<span style={{ color: 'red' }}>Pick & Drop Bus, Student Name ,Parent Contact Number, Organisation</span>{' '}
							alphabetically other fields like Route No, Location, Area, Parent email are optional.
						</List.Content>
					</List.Item>
				),
			},
			{
				component: () => (
					<List.Item>
						<List.Icon name="bus" color={'blue'} />
						<List.Content>
							Pick & Drop Bus Should match with the vehicle <span style={{ color: 'red' }}>available in CRM.</span>
						</List.Content>
					</List.Item>
				),
			},
			{
				component: () => (
					<List.Item>
						<List.Icon name="location arrow" color={'blue'} />
						<List.Content>
							Location and Area must be a <span style={{ color: 'red' }}>name (coordinates not accepted).</span>
						</List.Content>
					</List.Item>
				),
			},
			{
				component: () => (
					<List.Item>
						<List.Icon name="mail" color={'blue'} />
						<List.Content>
							If providing Parent email, it should match with the parent{' '}
							<span style={{ color: 'red' }}>already created in user section of CRM.</span>
						</List.Content>
					</List.Item>
				),
			},
			{
				component: () => (
					<List.Item>
						<List.Icon name="phone" color={'blue'} />
						<List.Content>
							Parent Contact Number should match with the parent{' '}
							<span style={{ color: 'red' }}>already created in user section in CRM.</span>
						</List.Content>
					</List.Item>
				),
			},
			{
				component: () => (
					<List.Item>
						<List.Icon name="users" color={'blue'} />
						<List.Content>
							Organisation name should <span style={{ color: 'red' }}>match with CRM.</span>
						</List.Content>
					</List.Item>
				),
			}
		);
	} else if (props.devices) {
		infoData.push(
			{
				component: () => (
					<List.Item>
						<List.Icon name="heading" color={'blue'} />
						<List.Content>
							Header name should contain{' '}
							<span style={{ color: 'red' }}>Device Imei, Device Manufacturer, Invoice Number, Invoice Date</span>{' '}
							alphabetically other fields like Note and Serial Number are optional.
						</List.Content>
					</List.Item>
				),
			},
			{
				component: () => (
					<List.Item>
						<List.Icon name="signal" color={'blue'} />
						<List.Content>
							Device Imei should be of <span style={{ color: 'red' }}>15 digit.</span>
						</List.Content>
					</List.Item>
				),
			},
			{
				component: () => (
					<List.Item>
						<List.Icon name="factory" color={'blue'} />
						<List.Content>
							Device Manufacturer name should be <span style={{ color: 'red' }}>as per CRM.</span>
						</List.Content>
					</List.Item>
				),
			},
			{
				component: () => (
					<List.Item>
						<List.Icon name="money bill alternate" color={'blue'} />
						<List.Content>
							Invoice Number can be maximum of <span style={{ color: 'red' }}>20 characters.</span>
						</List.Content>
					</List.Item>
				),
			},
			{
				component: () => (
					<List.Item>
						<List.Icon name="time" color={'blue'} />
						<List.Content>
							Date format should be <span style={{ color: 'red' }}>DD/MM/YYYY</span>
						</List.Content>
					</List.Item>
				),
			}
		);
	} else if (props.sims) {
		infoData.push(
			{
				component: () => (
					<List.Item>
						<List.Icon name="heading" color={'blue'} />
						<List.Content>
							Header name should contain{' '}
							<span style={{ color: 'red' }}>Mobile Number, SIM Number, Service Provider </span> alphabetically other
							fields like IMSI Number and Note are optional.
						</List.Content>
					</List.Item>
				),
			},
			{
				component: () => (
					<List.Item>
						<List.Icon name="mobile alternate" color={'blue'} />
						<List.Content>
							Mobile Number should be of <span style={{ color: 'red' }}>13 digit.</span>
						</List.Content>
					</List.Item>
				),
			},
			{
				component: () => (
					<List.Item>
						<List.Icon name="signal" color={'blue'} />
						<List.Content>
							SIM Number should be of <span style={{ color: 'red' }}>20 digit.</span>
						</List.Content>
					</List.Item>
				),
			},
			{
				component: () => (
					<List.Item>
						<List.Icon name="server" color={'blue'} />
						<List.Content>
							Service Provider should be <span style={{ color: 'red' }}>Vodafone, Airtel, Bsnl.</span>
						</List.Content>
					</List.Item>
				),
			}
		);
	} else {
		infoData = [];
	}

	let fileInputRef = useRef();

	const [usersResponse, setUsersResponse] = useState(null);
	const [inputData, setinputData] = useState(null);

	const userImportModalOpen = useSelector((state) => state.users.importModalOpen);
	const studentImportModalOpen = useSelector((state) => state.students.importModalOpen);
	const deviceImportModalOpen = useSelector((state) => state.devices.importModalOpen);
	const simsImportModalOpen = useSelector((state) => state.sims.importModalOpen);

	let SheetJSFT = ['xlsx', 'xls']
		.map(function (x) {
			return '.' + x;
		})
		.join(',');

	const handleInputFile = (e) => {
		const files = e.target.files;
		if (files && files[0] && EXCEL_FILE_TYPES.indexOf(files[0].type) !== -1) {
			setinputData(files[0]);

			if (props.users) {
				dispatch('USERS_IMPORT_MODAL_OPEN');
			}

			if (props.students) {
				dispatch('STUDENTS_IMPORT_MODAL_OPEN');
			}

			if (props.devices) {
				dispatch('DEVICES_IMPORT_MODAL_OPEN');
			}

			if (props.sims) {
				dispatch('SIMS_IMPORT_MODAL_OPEN');
			}
		} else generateErrorMessage('Only excel file types are allowed.');
	};

	const importUsers = async (response) => {
		const usersData = response.data;

		const body = usersData.map((data) => {
			return {
				name: data[0],
				type: data[1],
				organisation: {
					id: data.organisation.id,
					organisation: data[4],
				},
				phone: data[2],
				role: {
					id: data.role.id,
					role: data[5],
				},
				email: data[3],
			};
		});

		try {
			const res = await postData(USERS_API_BULK, '', body);

			setUsersResponse(res.data);

			if (res.status === 206) {
				generateSuccessMessage(res.data, 'Partial Success');
				dispatch('BULK_IMPORT_LOAD', false);
			} else {
				props.users.onClose();
				generateSuccessMessage(res.data, 'Created');
				dispatch('BULK_IMPORT_LOAD', false);
			}
		} catch (err) {
			handleError(err);
			dispatch('BULK_IMPORT_LOAD', false);
			datadogLogs.logger.error('User import api', {}, err);
			response.error();
		}
	};

	const importStudents = async (response) => {
		const studentsData = response.data;

		const body = studentsData.map((data) => {
			return {
				organisation: {
					id: data.organisation.id,
					organisation: data[7],
				},
				pickupBus: {
					pickupBus: data[1],
					id: parseInt(data.pickupBus?.id),
				},
				parents: [
					{
						id: data.parents[0]?.id,
						contactNumber: data[6],
					},
				],
				location: data[4],
				routeNo: data[0],
				email: data[5],
				name: data[2],
			};
		});

		try {
			const res = await postData(STUDENTS_API_BULK, '', body);

			setUsersResponse(res.data);

			if (res.status === 206) {
				generateSuccessMessage(res.data, 'Partial Success');
				dispatch('BULK_IMPORT_LOAD', false);
			} else {
				props.students.onClose();
				generateSuccessMessage(res.data, 'Created');
				dispatch('BULK_IMPORT_LOAD', false);
			}
		} catch (err) {
			handleError(err);
			dispatch('BULK_IMPORT_LOAD', false);
			datadogLogs.logger.error('User import api', {}, err);
			response.error();
		}
	};

	const importDevices = async (response) => {
		const devicesData = response.data;

		const body = devicesData.map((device) => {
			return {
				imei: device.imei.toString(),
				deviceModel: {
					id: device.deviceModel?.id,
				},
				manufacturer: device.manufacturer,
				purchaseInvoice: device.purchaseInvoice.toString(),
				invoiceDate: moment(device.invoiceDate, 'DD-MM-YYYY').toISOString(),
			};
		});

		try {
			const res = await postData(DEVICES_API_BULK, '', body);

			setUsersResponse(res.data);

			if (res.status === 206) {
				generateSuccessMessage(res.data, 'Partial Success');
				dispatch('BULK_IMPORT_LOAD', false);
			} else {
				props.students.onClose();
				generateSuccessMessage(res.data, 'Created');
				dispatch('BULK_IMPORT_LOAD', false);
			}
		} catch (err) {
			handleError(err);
			dispatch('BULK_IMPORT_LOAD', false);
			datadogLogs.logger.error('User import api', {}, err);
			response.error();
		}

		postData(DEVICES_API_BULK, '', body)
			.then((res) => {
				this.setState({ deviceResponse: res.data });
				response.success();
				if (res.status === 206) {
					generateSuccessMessage(res.data, 'Partial Success');
				} else {
					generateSuccessMessage(res.data, 'Created');
				}
			})
			.catch((err) => {
				handleError(err);
				datadogLogs.logger.error('Device bulk import api', {}, err);
				response.error();
			});
	};

	const importSims = async (response) => {
		// dispatchNoPayload('SIMS_ADDING');

		// const body = simsData.map((device) => {
		//     return {
		//         imei: device.imei.toString(),
		//         deviceModel: {
		//             id: device.deviceModel?.id,
		//         },
		//         manufacturer: device.manufacturer,
		//         purchaseInvoice:device.purchaseInvoice.toString(),
		//         invoiceDate: moment(device.invoiceDate, 'DD-MM-YYYY').toISOString(),
		//     };
		// });

		postData(SIMS_API_BULK, '', response.data)
			.then((res) => {
				setUsersResponse(res.data);

				if (res.status === 206) {
					generateSuccessMessage(res.data, 'Partial Success');
					dispatch('BULK_IMPORT_LOAD', false);
				} else {
					props.sims.onClose();
					generateSuccessMessage(res.data, 'Created');
					dispatch('BULK_IMPORT_LOAD', false);
				}
			})
			.catch((error) => {
				handleError(error);
				dispatch('BULK_IMPORT_LOAD', false);
				datadogLogs.logger.error('Sim bulk import api', {}, error);
				response.error();
			});
	};

	const cleanUserImportOperation = () => {
		dispatch('USERS_IMPORT_MODAL_CLOSE');

		if (fileInputRef && fileInputRef.current) {
			fileInputRef.current.value = '';
		}

		setUsersResponse(null);

		props.users.onClose();
	};

	const cleanStudentImportOperation = () => {
		dispatch('STUDENTS_IMPORT_MODAL_CLOSE');

		if (fileInputRef && fileInputRef.current) {
			fileInputRef.current.value = '';
		}

		setUsersResponse(null);

		props.students.onClose();
	};

	const cleanDeviceImportOperation = () => {
		dispatch('DEVICES_IMPORT_MODAL_CLOSE');

		if (fileInputRef && fileInputRef.current) {
			fileInputRef.current.value = '';
		}

		setUsersResponse(null);

		props.devices.onClose();
	};

	const cleanImportOperation = () => {
		dispatch('SIMS_IMPORT_MODAL_CLOSE');

		if (fileInputRef && fileInputRef.current) {
			fileInputRef.current.value = '';
		}
		setUsersResponse(null);

		props.sims.onClose();
	};

	return (
		<div>
			<Modal
				size={'large'}
				closeIcon
				open={
					props.users
						? props.users.UserimportInfo
						: props.students
						? props.students.StudentimportInfo
						: props.devices
						? props.devices.DeviceimportInfo
						: props.sims
						? props.sims.SimsimportInfo
						: null
				}
				onClose={
					props.users
						? props.users.onClose
						: props.students
						? props.students.onClose
						: props.devices
						? props.devices.onClose
						: props.sims
						? props.sims.onClose
						: () => {}
				}>
				<Label as="div" size={'large'} color="blue" ribbon>
					Guidelines
				</Label>
				<Modal.Header>Follow the guidelines before importing</Modal.Header>
				<Modal.Content>
					<List animated verticalAlign="middle">
						{infoData.map((data, key) => (
							<React.Fragment key={key}>{data.component && data.component()}</React.Fragment>
						))}
					</List>
				</Modal.Content>
				<Modal.Actions>
					<Button
						positive
						title="Import Users from file"
						onClick={() => {
							fileInputRef.current.click();
						}}>
						Import
					</Button>
				</Modal.Actions>

				<input
					style={{ display: 'none' }}
					id="file"
					ref={fileInputRef}
					type="file"
					className="form-control"
					accept={SheetJSFT}
					onChange={handleInputFile}
				/>

				{userImportModalOpen && (
					<UserImport
						template={props.users.fileTemplate}
						file={inputData}
						upload={importUsers}
						usersResponse={usersResponse}
						closeImport={cleanUserImportOperation}
					/>
				)}

				{studentImportModalOpen && (
					<StudentImport
						template={props.students.fileTemplate}
						file={inputData}
						upload={importStudents}
						studentsResponse={usersResponse}
						closeImport={cleanStudentImportOperation}
					/>
				)}

				{deviceImportModalOpen && (
					<DeviceImport
						template={props.devices.fileTemplate}
						file={inputData}
						upload={importDevices}
						deviceResponse={usersResponse}
						closeImport={cleanDeviceImportOperation}
					/>
				)}

				{simsImportModalOpen && (
					<SimsImport
						template={props.sims.fileTemplate}
						upload={importSims}
						file={inputData}
						simsResponse={usersResponse}
						closeImport={cleanImportOperation}
					/>
				)}
			</Modal>
		</div>
	);
};

export default ImportInfo;
